import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'

export default function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      style={{ width: 360, marginTop: 5 }}
      {...props}
    />
  )
}
