import { deleteIn, getIn, setIn, toCamelCase } from 'utils'
import { fromJS, List, Map, Set } from 'immutable'
import * as CONSTANTS from './constants'
// import { resetState } from '../reducer'
import {
  resetState,
  mapValuesOverFields,
  toggleFetch,
  updateValuesAndFields,
  trimmedCameled,
  extractIndexSearchFieldsFromMeta
} from '../utils'

export default {
  [CONSTANTS.GET_ENTITY.REQUEST]: state => {
    let result = state
    result = toggleFetch(result, true)
    return result
  },

  [CONSTANTS.GET_ENTITY.SUCCESS]: (state, { payload }) => {
    let result = state
    let values = payload.data ? payload.data : payload
    // debugger
    const { newMode, groupNames = [], ...rest } = values
    // values = fromJS(rest)
    let trackedTabs = getIn(result, 'masterOptions.trackedTabs')

    groupNames.forEach(name => {
      trackedTabs = trackedTabs.add(toCamelCase(name))
    })
    const editedFields = getIn(result, 'editedFields') || Set()

    values = Object.keys(values).reduce((acc, next) => {
      if (!editedFields.has(next) && values[next] !== null) {
        acc[next] = values[next]
      }

      return acc
    }, {})

    result = setIn(result, 'masterOptions.trackedTabs', trackedTabs)
    if (newMode) {
      result = setIn(result, 'newMode', true)
    }
    // debugger
    result = setIn(result, 'hasRecord', true)
    result = updateValuesAndFields(result, values)
    result = toggleFetch(result, false)
    return result
  },

  [CONSTANTS.GET_ENTITY.FAILURE]: state => toggleFetch(state),
  [CONSTANTS.LOCK_FOR_EDIT.REQUEST]: state => {
    let result = state
    const isEditing = getIn(state, 'isEditing')
    const hasRecord = getIn(state, 'hasRecord')
    if (hasRecord && !isEditing) {
      // isEditing = true
      result = setIn(result, 'isEditing', true)
    }
    return result
  },
  [CONSTANTS.LOCK_FOR_EDIT.SUCCESS]: (state, { payload }) => {
    let result = state
    let fields = getIn(state, 'fields') // .toJS()

    fields = fields.map(a => {
      if (getIn(a, 'isPending')) {
        a = setIn(a, 'isPending', false)
      }
      return a
    }, fields)
    result = setIn(result, 'fields', fields)
    fields = mapValuesOverFields(result, payload)
    result = setIn(result, 'fields', fields)
    // current groupNames?
    result = setIn(result, 'masterOptions.trackedTabs', Set())
    return result
  },
  [CONSTANTS.PRE_CANCEL_EDIT]: state => {
    let result = state
    result = setIn(result, 'isEditing', false)
    const values = getIn(result, 'values')
    const fields = mapValuesOverFields(result, values)
    result = setIn(result, 'fields', fields)
    result = setIn(result, 'editedFields', Set())
    return result
  },
  [CONSTANTS.CANCEL_EDIT.SUCCESS]: (state, { payload }) => {
    let result = state

    // const fields = mapValuesOverFields(result, fromJS(payload))
    // result = setIn(result, 'fields', fields)
    // console.log(payload)
    // reset fields to the "values"
    result = updateValuesAndFields(result, payload)
    return result
  },
  [CONSTANTS.LOCK_FOR_EDIT.FAILURE]: state => {
    let result = state
    result = setIn(result, 'isEditing', false)
    return result
  },
  [CONSTANTS.RESET_MASTER_FIELDS]: (state, { payload: fields = [] } = {}) => {
    let result = state
    //

    let masterOptions = getIn(result, 'masterOptions')
    let ignoreResetFields
    if (fields.length) {
      ignoreResetFields = fields
    } else {
      ignoreResetFields = getIn(masterOptions, 'ignoreResetFields') || Set()
    }
    result = resetState(result, ignoreResetFields)

    let selectedPrimaryTab = getIn(masterOptions, 'selectedPrimaryTab')
    let selectedSecondaryTab = getIn(masterOptions, 'selectedSecondaryTab')
    const tabComponents = getIn(masterOptions, 'tabComponents')
    const defaultPrimaryTabOrder = getIn(
      masterOptions,
      'defaultPrimaryTabOrder'
    )
    const defaultSecondaryTabMap = getIn(
      masterOptions,
      'defaultSecondaryTabMap'
    )
    const selectedTabId = selectedSecondaryTab
      ? `${selectedPrimaryTab}-${selectedSecondaryTab}`
      : selectedPrimaryTab

    const selectedTab = getIn(tabComponents, selectedTabId)

    if (getIn(selectedTab, 'isHidden')) {
      // find first thing we can replace with...
      const test = getIn(selectedTab, 'isHidden')(result)
      if (test) {
        const defaultTitle = trimmedCameled(
          defaultPrimaryTabOrder.first().get('title')
        )
        selectedPrimaryTab = defaultTitle
        masterOptions = setIn(
          masterOptions,
          'selectedPrimaryTab',
          selectedPrimaryTab
        )
        if (getIn(defaultSecondaryTabMap, defaultTitle)) {
          selectedSecondaryTab = trimmedCameled(
            getIn(defaultSecondaryTabMap, defaultTitle).first()
          )
          masterOptions = setIn(
            masterOptions,
            'selectedSecondaryTab',
            selectedSecondaryTab
          )
        }

        result = setIn(result, 'masterOptions', masterOptions)
      }
    }
    result = setIn(result, 'notesDisplayed', false)
    return result
  },
  [CONSTANTS.GET_ALTERNATE_META.SUCCESS]: (state, action) => {
    console.log(state, action)
    // debugger
    let result = state
    let meta = getIn(result, 'meta')
    const newMeta = extractIndexSearchFieldsFromMeta(action.payload)
    // result = setIn(
    //   result,
    //   'meta',
    //   fromJS(extractIndexSearchFieldsFromMeta(action.payload.meta || {}))
    // )
    // debugger
    meta = meta.mergeDeep(fromJS(newMeta))
    result = deleteIn(result, 'alternateEntry')
    result = setIn(result, 'meta', meta)
    return result
  }
}
