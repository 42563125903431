import { createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const uploadAttachments = createAsyncFormAction(
  CONSTANTS.UPLOAD_ATTACHMENTS
)

export const deleteAttachment = createAsyncFormAction(
  CONSTANTS.DELETE_ATTACHMENT
)

export const downloadAttachment = createAsyncFormAction(
  CONSTANTS.DOWNLOAD_ATTACHMENT
)

export const getAttachments = createAsyncFormAction(CONSTANTS.GET_ATTACHMENTS)
