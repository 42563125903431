import { createAsyncRequestTypes } from 'utils'

export const DASHBOARD_DATA_PROVIDER = '@@DASHBOARD_DATA_PROVIDER'
export const SAVE_SCREEN_OPEN_DATA = `${DASHBOARD_DATA_PROVIDER}/SAVE_SCREEN_OPEN_DATA`
export const UPDATE_SCREEN_OPEN_DATA = `${DASHBOARD_DATA_PROVIDER}/UPDATE_SCREEN_OPEN_DATA`

export const EXECUTE_INQUIRY = createAsyncRequestTypes(`${DASHBOARD_DATA_PROVIDER}/EXECUTE_INQUIRY`)
export const INIT_EXECUTE_INQUIRY = `${DASHBOARD_DATA_PROVIDER}/INIT_EXECUTE_INQUIRY`

export const CHANGE_INVENTORY_FILTER = createAsyncRequestTypes(`${DASHBOARD_DATA_PROVIDER}/CHANGE_INVENTORY_FILTER`)
export const TOGGLE_GRID_SELECTION = `${DASHBOARD_DATA_PROVIDER}/TOGGLE_GRID_SELECTION`


export const INIT_REPORT_GRAPHIC_DATA_PROCESS = `${DASHBOARD_DATA_PROVIDER}/INIT_REPORT_GRAPHIC_DATA_PROCESS`
export const SAVE_REPORT_GRAPHIC_DATA = `${DASHBOARD_DATA_PROVIDER}/SAVE_REPORT_GRAPHIC_DATA`

/* new change filter */
export const CHANGE_FILTER = createAsyncRequestTypes(`${DASHBOARD_DATA_PROVIDER}/CHANGE_FILTER`)

/* dashboard report */
export const OPEN_DASHBOARD_REPORT = createAsyncRequestTypes(`${DASHBOARD_DATA_PROVIDER}/OPEN_DASHBOARD_REPORT`)
export const CLOSE_DASHBOARD_REPORT = createAsyncRequestTypes(`${DASHBOARD_DATA_PROVIDER}/CLOSE_DASHBOARD_REPORT`)

/* retrieve cached data */
export const LOAD_CACHED_DATA = `${DASHBOARD_DATA_PROVIDER}/LOAD_CACHED_DATA`

/* adjusted 'Exit' method */
export const EXIT_DASHBOARD_SCREEN = `${DASHBOARD_DATA_PROVIDER}/EXIT_DASHBOARD_SCREEN`

export const OPEN_SCREEN_FROM_DASHBOARD = createAsyncRequestTypes(`${DASHBOARD_DATA_PROVIDER}/OPEN_SCREEN_FROM_DASHBOARD`)
