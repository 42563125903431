import { useRef, useEffect } from 'react'
import { withRouter } from 'react-router'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const ScrollToTop = props => {
  const { location } = props
  const prevLocation = usePrevious(location)
  useEffect(() => {
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }, [location, prevLocation])

  return props.children
}

export default withRouter(ScrollToTop)
