import callFetch from 'access/callFetch'
import { toJSON, createAuthHeader } from 'utils'
import { omit } from 'lodash'
import {
  // CANCEL_SHIPPING_CONFIRMATION_URL,
  BACK_ORDER_ALL_URL,
  SHIP_ALL_URL,
  // FETCH_SHIPPING_CONFIRMATION_URL,
  UPDATE_NOTES_URL,
  // SHIP_CONFIRMATION_PROPERTY_CHANGE_URL,
  DELETE_SHIPPING_CONFIRMATION_URL,
  // PARTIAL_MATCH_SEARCH_URL, EXACT_MATCH_SEARCH_URL,
  SAVE_SHIPPING_CONFIRMATION_URL,
} from '../constants'

// export const getShippingConfirmation = ({ token, dataId, override = '' }) => {
//   const options = createApiOptions({
//     body: { dataId, override },
//     method: 'post',
//     token
//   })
//   return callFetch(FETCH_SHIPPING_CONFIRMATION_URL, options)
// }

export const saveInternalNotes = ({
 token, notes, dataId
}) => {
  const options = {
    body: toJSON({ Notes: notes, dataId }),
    credentials: 'include',
    headers: createAuthHeader(token),
    method: 'post',
  }
  return callFetch(UPDATE_NOTES_URL, options)
}

// export const cancelShippingConfirmation = ({ token, dataId }) => {
//   console.log(token, dataId)
//   const options = {
//     method: 'get',
//     headers: createAuthHeader(token),
//     credentials: 'include',
//   }
//   return callFetch(`${CANCEL_SHIPPING_CONFIRMATION_URL}/${encodeString(dataId)}`, options)
// }

export const backOrderAll = ({
 token, dataId, override = ''
}) => {
  const options = {
    body: toJSON({ dataId, override }),
    credentials: 'include',
    headers: createAuthHeader(token),
    method: 'post',
  }
  return callFetch(BACK_ORDER_ALL_URL, options)
}

export const shipAll = ({
 token, dataId, override = ''
}) => {
  const options = {
    body: toJSON({ dataId, override }),
    credentials: 'include',
    headers: createAuthHeader(token),
    method: 'post',
  }
  return callFetch(SHIP_ALL_URL, options)
}

export const deleteShippingConfirmation = ({ token, dataId }) => {
  const options = {
    body: toJSON({ dataId }),
    credentials: 'include',
    headers: createAuthHeader(token),
    method: 'post'
  }
  return callFetch(DELETE_SHIPPING_CONFIRMATION_URL, options)
}
// export const validatePropertyChange = ({ token, dataId, propertyName, propertyValue }) => {
//   const options = {
//     method: 'post',
//     headers: createAuthHeader(token),
//     credentials: 'include',
//     body: toJSON({ dataId, propertyName, propertyValue })
//   }
//   return callFetch(SHIP_CONFIRMATION_PROPERTY_CHANGE_URL, options)
// }

// export const getInputSearchResults = ({ token, indexSearchType, keyword, pageNumber }) => {
//   const options = {
//     method: 'post',
//     headers: createAuthHeader(token),
//     credentials: 'include',
//     body: toJSON({ indexSearchType, keyword, pageNumber })
//   }
//   return callFetch(PARTIAL_MATCH_SEARCH_URL, options)
// }

// export const getExactMatchInputSearchResults = ({ token, indexSearchType, keyword, pageNumber }) => {
//   const options = {
//     method: 'post',
//     headers: createAuthHeader(token),
//     credentials: 'include',
//     body: toJSON({ indexSearchType, keyword, pageNumber })
//   }
//   return callFetch(EXACT_MATCH_SEARCH_URL, options)
// }

// export const saveShippingConfirmation = ({ token, dataId, isNoQuantitiesOK, IsIncompleteQuantitiesOK, isSerialEditorOK, isLotEditorOK, isSignatureCaptureOK, isNoSignatureOK }) => {
export const saveShippingConfirmation = (apiParams) => {
  const { token } = apiParams
  apiParams = omit(apiParams, 'token')
  const options = {
    body: toJSON({ ...apiParams }),
    credentials: 'include',
    headers: createAuthHeader(token),
    method: 'post',
  }
  return callFetch(SAVE_SHIPPING_CONFIRMATION_URL, options)
}

// export const cancelBackorder = ({ token, dataId, cancelUserInitials, cancelReason }) => {
//   console.log(dataId, cancelUserInitials)
//   const options = {
//     method: 'post',
//     headers: createAuthHeader(token),
//     credentials: 'include',
//     body: toJSON({ dataId, cancelUserInitials, cancelReason })
//   }
//   return callFetch(CANCEL_BACKORDER_URL, options)
// }
