import { getIn } from 'utils'
import { setField } from 'ddiForm/actions'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { exactMatchSearch } from './actions'
import { blur, clearSearch, clearForm } from '../IndexSearch/actions'

import baseBehaviors from '../baseBehaviors'

export default {
  ...baseBehaviors,
  blur(value) {
    if (!value) return
    this.props.dispatch(
      blur(this.form, {
        propertyName: this.props.propertyName,
        value,
        isSet: this.state.isSet
      })
    )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        this.props.onChange('')
      }
    }
  },
  clearSearch() {
    if (!this.props.getFormState) return
    this.props.dispatch(
      clearSearch(this.props.form, { propertyName: this.props.propertyName })
    )
  },
  // TODO
  contextMenuFn(e, data) {
    // debugger
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut: () => {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select: () => {
        target.select()
      },
      paste: () => {
        // Chrome does not allow retrieval of clipboard data
        // document.addEventListener('paste', function (evt) {
        //   const data = evt.clipboardData.getData('text')
        //   console.log('DATA', data)
        // })
        // target.focus()
        // document.execCommand('paste')
      },
      delete: () => {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        this.props.dispatch(
          openScreenAction({
            name: 'salesOrderEntry',
            title: 'Sales Order Entry',
            dataId: this.props.value
          })
        )
      }
    }
    return (action[data.type] || action.select)()
  },

  focusField() {
    return (
      this.textField &&
      this.setState(
        { isFocused: true },
        () => ((this.autofocused = true), this.textField.focus())
      )
    )
  },
  // getButtonStyle() {
  //   let { buttonStyle } = this.props
  //   if (this.props.disabled) {
  //     buttonStyle = {
  //       ...buttonStyle,
  //       background: '#000',
  //       opacity: 0.12
  //     }
  //   }
  //   return buttonStyle
  // },
  // getDisplayValue() {
  //   if (this.props.displayDescription && !this.state.isFocused) {
  //     return this.state.description
  //   }
  //   return this.state.value || ''
  // },
  // // what is this? put this somewhere else...
  // getValue() {
  //   return this.state.value
  // },
  // async onBlur(event) {
  //   if (event) event.persist()
  //   if (this.state.isOpen || this.props.isFetching) return
  //   await this.setState({ isFocused: false })
  //   if (!this.state.isSet && this.state.isDirty) {
  //     this.setState(
  //       {
  //         value: '',
  //         description: '',
  //         isDirty: false
  //       },
  //       () => this.blur(this.state.value)
  //     )
  //   } else {
  //     this.blur(this.state.value)
  //   }
  // },

  async onChange(event) {
    event.persist()
    // console.log('onChange', this)
    // debugger

    const v = event.target.value
    await this.setState({
      isDirty: true,
      value: v,
      // lastSearch: event.target.value,
      isSet: false
    })

    if (this.props.leader && this.props.hasRecord) {
      await this.props.dispatch(clearForm(this.form))
    }

    if (v.length >= 1) {
      this.setState({
        value: event.target.value
      })
    } else {
      this.clearSearch()
      this.setField('', true)
    }
  },
  onKeyDown(event) {
    event.persist()
    const val = event.target.value
    /* this is for New Event Type popover */
    if (this.props.disableBaseOnTabFunctionality && this.props.onKeyDown) {
      this.props.onKeyDown(event)
      return
    }

    // check if tooltip is open and keys match etc.. great :-(
    if (event.key === 'Tab') {
      if (this.state.isSet || !this.state.value) {
        return
      }
      // debugger
      event.preventDefault()
      // event.preventDefault()
      // event.target.blur()
      const {
        indexSearchType,
        propertyName,
        moreInfo,
        includeParent
      } = this.props
      this.setState(
        {
          // isOpen: false,
          value: val,
          lastSearch: val
        },
        async () => {
          try {
            const res = await this.props.dispatch(
              exactMatchSearch(this.form, {
                propertyName: this.props.propertyName,
                keyword: val
              })
            )
            if (res) {
              this.setState(
                {
                  value: res.recordName,
                  description: res.description || '',
                  isSet: true,
                  isDirty: false,
                  isOpen: false
                },
                () => {
                  this.setField(res.recordName, true, res)
                  this.blur(this.state.value)
                }
              )
            } else {
              this.setState({
                value: '',
                isSet: false,
                isDirty: false,
                isOpen: false
              })
            }
          } catch (e) {
            this.setState({
              value: '',
              isSet: false,
              isDirty: false,
              isOpen: false
            })
          }
        }
      )
    }
  }
  // onKeyPress = e => console.log(e.charCode, e.keyCode)
  // setField(val, clear, results) {
  //   const { clearFieldOnSet, leader, setField, onSetField } = this.props
  //   // debugger
  //   setField(val, clear, leader, results)
  //   if (onSetField) {
  //     onSetField(val, clear, leader, results)
  //   }
  //   if (clearFieldOnSet) {
  //     setField('', clear)
  //   }
  // }
}
