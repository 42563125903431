import { BASE_INFORM_API_URL } from 'services/constants'
import { createAsyncRequestTypes } from 'utils'

export const CUSTOMER_ORDER_PAD = '@@CUSTOMER_ORDER_PAD/'
export const customerOrderPad = 'customerOrderPad'

export const OPEN_SCREEN = `${CUSTOMER_ORDER_PAD}OPEN_SCREEN`

export const EXECUTE_INQUIRY = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}EXECUTE_INQUIRY`
)

export const CUSTOMER_ORDER_PAD_INQUIRY_URL = `${BASE_INFORM_API_URL}/customerorderpad/search`

export const GET_PRICE_DETAIL_URL = `${BASE_INFORM_API_URL}/customerorderpad/pricedetail/open`
// export const SHOW_PRICE_DETAIL = `${CUSTOMER_ORDER_PAD}SHOW_PRICE_DETAIL`

export const SHOW_PRICE_DETAIL = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}SHOW_PRICE_DETAIL`
)

export const SAVE_PRICE_DETAIL = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}SAVE_PRICE_DETAIL`
)
export const SAVE_PRICE_DETAIL_URL = `${BASE_INFORM_API_URL}/customerorderpad/pricedetail/save`

export const GET_ADJUSTED_PRICE_URL = `${BASE_INFORM_API_URL}/customerorderpad/adjustedprice`
// export const SHOW_ADJUSTED_PRICE = `${CUSTOMER_ORDER_PAD}SHOW_ADJUSTED_PRICE`

export const SHOW_ADJUSTED_PRICE = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}SHOW_ADJUSTED_PRICE`
)

export const GET_CUSTOMER_STOCK_MINIMUMS_URL = `${BASE_INFORM_API_URL}/customerorderpad/stockminimums/open`
export const SHOW_CUSTOMER_STOCK_MINIMUMS = `${CUSTOMER_ORDER_PAD}SHOW_CUSTOMER_STOCK_MINIMUMS`
export const SAVE_CUSTOMER_STOCK_MINIMUMS = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}SAVE_CUSTOMER_STOCK_MINIMUMS`
)
export const SAVE_CUSTOMER_STOCK_MINIMUMS_URL = `${BASE_INFORM_API_URL}/customerorderpad/stockminimums/save`
export const DELETE_CUSTOMER_STOCK_MINIMUMS = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}DELETE_CUSTOMER_STOCK_MINIMUMS`
)
export const DELETE_CUSTOMER_STOCK_MINIMUMS_URL = `${BASE_INFORM_API_URL}/customerorderpad/stockminimums/delete`

export const SHOW_REPORT_OPTIONS_MODAL = `${CUSTOMER_ORDER_PAD}SHOW_REPORT_OPTIONS_MODAL`
export const UPDATE_LINE_ITEM_QUANTITY = `${CUSTOMER_ORDER_PAD}UPDATE_LINE_ITEM_QUANTITY`

export const RUN_CUSTOMER_ORDER_PAD_REPORT = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}RUN_CUSTOMER_ORDER_PAD_REPORT`
)
export const RUN_CUSTOMER_ORDER_PAD_REPORT_URL = `${BASE_INFORM_API_URL}/customerorderpad/report`

export const VIEW_CUSTOMER_ORDER_PAD_REPORT = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}VIEW_CUSTOMER_ORDER_PAD_REPORT`
)
export const VIEW_CUSTOMER_ORDER_PAD_REPORT_URL = `${BASE_INFORM_API_URL}/printdocument/view`

export const CALCULATE_ORDER_PAD_PRICE = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}CALCULATE_ORDER_PAD_PRICE`
)
export const CALCULATE_ORDER_PAD_PRICE_URL = `${BASE_INFORM_API_URL}/customerorderpad/calculateprice`

export const SET_EDITABLE_PRICE = `${CUSTOMER_ORDER_PAD}SET_EDITABLE_PRICE`
export const SAVE_ADJUSTED_PRICE = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}SAVE_ADJUSTED_PRICE`
)
export const SAVE_ADJUSTED_PRICE_URL = `${BASE_INFORM_API_URL}/customerorderpad/adjustedprice/save`
export const SET_PRICE_DETAIL_SAVE_PERMISSIONS = `${CUSTOMER_ORDER_PAD}SET_PRICE_DETAIL_SAVE_PERMISSIONS`

export const SET_FILTERED_GRID_MODEL_ID = `${CUSTOMER_ORDER_PAD}SET_FILTERED_GRID_MODEL_ID`
export const GET_FILTERED_GRID_MODEL = `${CUSTOMER_ORDER_PAD}GET_FILTERED_GRID_MODEL`

export const SHOW_WARNING_MODAL = `${CUSTOMER_ORDER_PAD}SHOW_WARNING_MODAL`
export const REMOVE_GRID_EDITED_FLAG = `${CUSTOMER_ORDER_PAD}REMOVE_GRID_EDITED_FLAG`

export const SHOW_ERROR_MESSAGE = `${CUSTOMER_ORDER_PAD}SHOW_ERROR_MESSAGE`
export const DISMISS_APC_ERROR_MESSAGE = `${CUSTOMER_ORDER_PAD}DISMISS_APC_ERROR_MESSAGE`

export const UPDATE_LINE_ITEM_PRICE = `${CUSTOMER_ORDER_PAD}UPDATE_LINE_ITEM_PRICE`

export const CALL_ACCESS_OVERRIDE_MODAL = `${CUSTOMER_ORDER_PAD}CALL_ACCESS_OVERRIDE_MODAL`

export const GET_GRID_CELL_TOOLTIP = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}GET_GRID_CELL_TOOLTIP`
)

export const LOAD_CUSTOMER_RECORD = `${CUSTOMER_ORDER_PAD}LOAD_CUSTOMER_RECORD`

export const SET_INITIAL_EDITABLE_PRICE = `${CUSTOMER_ORDER_PAD}SET_INITIAL_EDITABLE_PRICE`

export const SET_QUOTED_PRICE = createAsyncRequestTypes(
  `${CUSTOMER_ORDER_PAD}SET_QUOTED_PRICE`
)

export const OPEN_SALES_ORDER_SCREEN = `${CUSTOMER_ORDER_PAD}OPEN_SALES_ORDER_SCREEN`

export const COMPILE_DATA_FOR_SALES_ORDER = `${CUSTOMER_ORDER_PAD}COMPILE_DATA_FOR_SALES_ORDER`

export const ADD_TO_SALES_ORDER = `${CUSTOMER_ORDER_PAD}ADD_TO_SALES_ORDER`

export const CLEAR_INQUIRY_RESULTS = `${CUSTOMER_ORDER_PAD}CLEAR_INQUIRY_RESULTS`

export const REVERT_SEARCH_CONTROL_SELECTION = `${CUSTOMER_ORDER_PAD}REVERT_SEARCH_CONTROL_SELECTION`
