import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'
import { CUSTOMER_MASTER } from 'pages/CustomerMaster/constants'

/* open Customer Ledger */
export const GET_CUSTOMER_LEDGER_META = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}GET_CUSTOMER_LEDGER_META`
)

export const READ_CUSTOMER_LEDGER_BY_TAB = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}READ_CUSTOMER_LEDGER_BY_TAB`
)

export const SET_SUMMARY_EXPANSION_PANEL_STATE = `${CUSTOMER_MASTER}SET_SUMMARY_EXPANSION_PANEL_STATE`
export const SET_AGING_SUMMARY_DEFAULT_VIEW = `${CUSTOMER_MASTER}SET_AGING_SUMMARY_DEFAULT_VIEW`

export const CLEAR_CUSTOMER_LEDGER_GRIDS = `${CUSTOMER_MASTER}CLEAR_CUSTOMER_LEDGER_GRIDS`
export const CLEAR_AUXILIARY_GRIDS = `${CUSTOMER_MASTER}CLEAR_AUXILIARY_GRIDS`

export const OPEN_LEDGER_NOTES_MODAL = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}OPEN_LEDGER_NOTES_MODAL`
)

/* disputed notes modal NEW */
export const LOCK_LEDGER_NOTES = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}LOCK_LEDGER_NOTES`
)

export const UNLOCK_LEDGER_NOTES = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}UNLOCK_LEDGER_NOTES`
)

export const SAVE_LEDGER_NOTES = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}SAVE_LEDGER_NOTES`
)
/* end disputed notes modal NEW */

/* get ledger docs NEW */
export const GET_LEDGER_DOC = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}GET_LEDGER_DOC`
)

/* Customer Ledger Reports & Printing */
export const PRINT_INVOICES = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}PRINT_INVOICES`
)

export const PRINT_CREDIT_REF_LETTER = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}PRINT_CREDIT_REF_LETTER`
)

export const SET_GRID_PRINT_SELECTIONS = `${CUSTOMER_MASTER}SET_GRID_PRINT_SELECTIONS`

export const OPEN_SALES_HISTORY_REPORT_OPTIONS = `${CUSTOMER_MASTER}OPEN_SALES_HISTORY_REPORT_OPTIONS`

export const CLOSE_SALES_HISTORY_REPORT_MODAL = `${CUSTOMER_MASTER}CLOSE_SALES_HISTORY_REPORT_MODAL`

export const GET_SALES_HISTORY_REPORT = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}GET_SALES_HISTORY_REPORT`
)

export const OPEN_AR_NOTES = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}OPEN_AR_NOTES`
)

export const TOGGLE_ALL_PAYMENT_HISTORY = `${CUSTOMER_MASTER}TOGGLE_ALL_PAYMENT_HISTORY`
export const CLEAR_CUSTOMER_LEDGER = `${CUSTOMER_MASTER}CLEAR_CUSTOMER_LEDGER`

export const SAVE_AGING_VIEW = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}SAVE_AGING_VIEW`
)

export const TOGGLE_ALL_GRID_PRINT_SELECTIONS = `${CUSTOMER_MASTER}TOGGLE_ALL_GRID_PRINT_SELECTIONS`
export const TOGGLE_GRID_PRINT_SELECTION = `${CUSTOMER_MASTER}TOGGLE_GRID_PRINT_SELECTION`

export const GET_NEXT_AR_NOTE = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}GET_NEXT_AR_NOTE`
)

export const SET_SALES_HISTORY_SORT_FIELD = `${CUSTOMER_MASTER}SET_SALES_HISTORY_SORT_FIELD`

export const LOAD_LEDGER_RECORD = `${CUSTOMER_MASTER}LOAD_LEDGER_RECORD`
export const CORRECT_LEDGER_META_DATA = `${CUSTOMER_MASTER}CORRECT_LEDGER_META_DATA`

export const GET_SUMMARY_DATA = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}GET_SUMMARY_DATA`
)

export const CHECK_UPDATE_SUMMARY_DATA = `${CUSTOMER_MASTER}CHECK_UPDATE_SUMMARY_DATA`

export const SAVE_EXPANDED_LEDGER_GRID_ROWS = `${CUSTOMER_MASTER}SAVE_EXPANDED_LEDGER_GRID_ROWS`

export const SAVE_SELECTED_LEDGER_GRID_ROWS = `${CUSTOMER_MASTER}SAVE_SELECTED_LEDGER_GRID_ROWS`

export const SAVE_FILTERED_GRID_ROWS = `${CUSTOMER_MASTER}SAVE_FILTERED_GRID_ROWS`
