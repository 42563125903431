/* eslint react/jsx-no-duplicate-props: 0 */
/* dear eslint, inputProps are not the same as InputProps! */
import React, { Component, Fragment } from 'react'
import { Icon, TextField } from '@material-ui/core'
import invariant from 'invariant'
/* eslint-disable no-useless-escape */

export default class RegexCellEditor extends Component {
  static defaultProps = {
    required: true
  }

  constructor(...args) {
    super(...args)
    invariant(this.props.regex, 'must provide a regex!')

    const value = this.props.value || ''
    this.state = {
      open: false,
      value,
      valid: this.props.regex.test(value)
    }
  }

  componentWillUnmount() {
    if (this.state.isDirty && this.state.valid && this.props.onChange) {
      this.props.onChange(this.props.field, this.state.value, this.props.node)
    }
  }

  onChange = e => {
    const { value } = e.target
    this.setState({ value, valid: this.props.regex.test(value), isDirty: true })
  }

  onClick = () => this.setState({ open: !this.state.open })

  onClose = () => this.setState({ open: false })

  onRowClicked = e =>
    this.setState({
      value: e.data.description,
      open: false
    })

  bindInputRef = c => (this.input = c)

  input = null

  refresh = params => {
    console.log(params)
    return false
  }

  getValue = () => this.state.value

  render() {
    console.log(this.props, this.state)
    return (
      <div style={{ width: '100%', height: 27, position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: 27
          }}
        >
          <TextField
            value={this.state.value}
            onChange={this.onChange}
            InputProps={{
              style: { height: 26, padding: 0 },
              endAdornment: !this.state.valid ? (
                <Icon style={{ color: '#d9534f', fontSize: 16 }}>
                  error_outline
                </Icon>
              ) : (
                undefined
              )
            }}
            style={{ height: 26, padding: 0 }}
            inputProps={{
              style: { height: 26, padding: 0 }
            }}
          />
        </div>
      </div>
    )
  }
}
