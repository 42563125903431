import {
  setIn,
  getIn,
  isGrid,
  empty,
  emptyList,
  deleteIn,
  noop,
  toCamelCase,
  trimNonWord,
  fromJS,
  is
} from 'utils'
import { Set } from 'immutable'
import { hasIn, intersection /* omit */ } from 'lodash'
import { modalBehaviors } from 'modals/reducer'
import { compose } from 'redux'
import {
  AUTHENTICATION_ERROR,
  SUBMIT_LOGOUT_USER,
  CLEAR_SELECTED_BRANCH
} from 'auth/constants'
import indexSearchReducer from 'components/Search/IndexSearch/reducer'
import { DISPLAY_INTERNAL_NOTES } from 'modals/NotesModal/constants'
import * as CONSTANTS from './constants'
import gridReducer from './gridReducer'

import masterBehaviors from './MasterScreen/behaviors'
import {
  resetState,
  changePrimaryTab,
  changeFormTab,
  validateField,
  mapValuesOverFields,
  trimmedCameled,
  updateValuesAndFields,
  createMasterOptions,
  extractIndexSearchFieldsFromMeta
} from './utils'

let customBehaviors = {}
const grid = {
  rowData: [],
  columnDefs: [],
  meta: {}
}

const mapTitle = x => x.title

const createField = (
  state,
  propertyName,
  indexSearchType,
  type = 'text',
  rest
) => {
  const hasInitial = hasIn(state, `initial.${propertyName}`)
  const v = getIn(state, `values.${propertyName}`)
  let fld
  if (type === 'grid') {
    fld = {
      // ...gridDuck(),
      ...grid,
      grid: true
    }
    Object.keys(rest).forEach(k => {
      if (rest[k] != null) {
        fld[k] = rest[k]
      }
    })

    if (v) {
      let rowData = getIn(state, `values.${propertyName}`)
      rowData = rowData.toJS ? rowData.toJS() : rowData
      fld = {
        ...fld,
        rowData
      }
    }
    return fromJS(fld)
  }
  let value
  if (hasInitial) {
    value = getIn(state, `initial.${propertyName}`)
  } else if (v !== undefined) {
    value = v
  } else {
    value = type === 'bool' ? false : ''
  }
  if (type === 'sendInfoSearch') {
    value = []
    // what if it was populated with some shit? let's see...
  }
  /* eslint-disable no-nested-ternary */
  fld = {
    isFetching: false,
    isInvalid: false,
    value,
    disabled: false,
    ...rest
  }
  // debugger
  if (indexSearchType || type === 'indexSearch') {
    fld = indexSearchReducer()
    fld = setIn(fld, 'indexSearchType', indexSearchType)
    if (v) {
      fld = setIn(fld, 'value', v)
    }
  }
  if (type === 'grid-new') {
    const { _ddiForm, ...newArgs } = rest
    // check for a value
    const v = getIn(state, 'values.lineItems')
    let a = { ...newArgs, isPending: getIn(state, 'isPending') }
    if (v) {
      a = { ...a, rowData: v }
    }
    fld = gridReducer(gridReducer(), {
      type: 'BOOTSTRAP',
      payload: a
    })
  }

  return fromJS(fld)
}

const registerField = (
  state,
  { payload: { propertyName, searchType, type, initialValue, leader, ...rest } }
) => {
  let result = state
  if (getIn(result, `fields.${propertyName}`)) return result

  let field = createField(state, propertyName, searchType, type, rest)
  if (leader) {
    field = setIn(field, 'leader', true)
  }
  if (initialValue) {
    field = setIn(field, 'value', initialValue)
  }
  result = setIn(result, `fields.${propertyName}`, field)

  return result
}

export const extractValuesFromPayload = (state, payload = {}) => {
  let values = getIn(state, 'values') || empty
  const { meta, ...rest } = payload
  for (const prop in rest) {
    values = setIn(
      values,
      prop,
      typeof rest[prop] === 'object' ? fromJS(rest[prop]) : rest[prop]
    )
  }
  return values
}

const specialTypeMap = {
  IndexSearch: (state, action) => indexSearchReducer(state, action),
  Grid: (state, action) => gridReducer(state, action)
}

const re = new RegExp('@@([^]+)/')
// const checkForSpecial = actionType => {
//   const type = actionType.split(re)[1]
//   const match = specialTypeMap[type]
//   if (match) debugger
//   return match
// }

const checkForSpecial = meta => {
  const type = meta.reducer // actionType.split(re)[1]
  if (!type) return false
  const match = specialTypeMap[type]
  return match
}
const initializeSagas = sagas => {
  const {
    onCancel,
    onBeforeDestroy,
    onDestroy,
    onExit,
    onInit,
    onSave,
    onSuccess,
    propertyValidations,
    beforeClose
  } = sagas
  return fromJS({
    onBeforeDestroy: onBeforeDestroy || noop,
    onCancel: onCancel || noop,
    onDestroy: onDestroy || noop,
    onExit: onExit || noop,
    onInit: onInit || noop,
    onSave: onSave || noop,
    onSuccess: onSuccess || noop,
    propertyValidations: propertyValidations || {},
    beforeClose
  })
}

const setField = (state, propertyName, value, deleteMeta, results) => {
  let result = state
  let field = getIn(result, `fields.${propertyName}`)
  if (field) {
    // result = setIn(result, `fields.${propertyName}.prevValue`, getIn(field, 'value'))
    field = setIn(field, 'prevValue', getIn(field, 'value'))
    field = setIn(field, 'value', value)
    if (deleteMeta) {
      field = deleteIn(field, 'grid')
      field = deleteIn(field, 'description')
      field = deleteIn(field, 'recordName')
      field = deleteIn(field, 'filtersGrid')
    }
    if (!value) {
      field = deleteIn(field, 'isSet')
    }
    if (results && results.description) {
      field = setIn(field, 'description', results.description)
    }
    result = setIn(result, `fields.${propertyName}`, field)
    try {
      result = setIn(result, `values.${propertyName}`, value) // test
    } catch (e) {
      // console.log(e)
      const oldV = getIn(result, `values.${propertyName}`)
      const spl = propertyName.split('.')
      if (spl.length > 1) {
        const prop = spl[spl.length - 1]
        const parent = spl[spl.length - 2]
      }
      // console.log(spl, prop, parent, getIn(result, `values.${parent}`))
      // debugger
    }
  } else {
    result = setIn(result, `values.${propertyName}`, value)
  }
  if (propertyName !== 'dataId') {
    let editedFields = getIn(result, 'editedFields')
    if (editedFields && !getIn(field, 'untracked')) {
      editedFields = editedFields.add(propertyName)
      result = setIn(result, 'editedFields', editedFields)
    }
  }

  // console.log(result.toJS())
  const validate = getIn(field, 'validate')
  let isInvalid
  let errorText
  if (validate) {
    errorText = validateField(field, state, getIn(field, 'meta') || {})
    isInvalid = !!errorText
    result = setIn(result, `fields.${propertyName}.isInvalid`, isInvalid)
    if (errorText) {
      result = setIn(result, `fields.${propertyName}.errorText`, errorText)
    } else {
      result = deleteIn(result, `fields.${propertyName}.errorText`)
    }
  }
  return result
}

export const behaviors = {
  // [CONSTANTS.ADD_BLANK_ROW]: (state, { payload: { propertyName } }) => {
  //   let result = state
  //   let grid = getIn(result, `fields.${propertyName}`)
  //   if (grid && getIn(grid, 'emptyRow')) {
  //     const isPending = getIn(grid, 'isPending') // TODO we are going to need to deal with the removal of isPending
  //     if (!isPending) {
  //       grid = setIn(grid, 'isPending', true)
  //     } else {
  //       return result
  //     }
  //     const emptyRow = getIn(grid, 'emptyRow')
  //     let rowData = getIn(grid, 'rowData') || fromJS([])
  //     // console.log(rowData)
  //     rowData = rowData.push(fromJS(emptyRow))
  //     // console.log(rowData)
  //     // debugger
  //     grid = setIn(grid, 'rowData', rowData)
  //     result = setIn(result, `fields.${propertyName}`, grid)
  //   }
  //   return result
  // },
  [CONSTANTS.BEFORE_DESTROY]: (state, payload) => {
    let result = state
    if (state.size) {
      result = setIn(result, 'destroyed', true)
    }
    return result
  },
  [CONSTANTS.BLUR]: (state, { payload: { propertyName, value } }) => {
    let result = state
    // const field = omit(getIn(result, `fields.${propertyName}`), 'grid')
    let field = getIn(result, `fields.${propertyName}`) // omit(getIn(result, `fields.${propertyName}`), 'grid')
    field = deleteIn(field, 'grid')
    field = deleteIn(field, 'isActive')
    // console.log(field)
    result = setIn(result, `fields.${propertyName}`, field)
    if (value !== getIn(result, `fields.${propertyName}.value`)) {
      result = setField(result, propertyName, value)
      result = setIn(result, `values.${propertyName}`, value)
    }
    result = deleteIn(result, 'active')

    return result
  },
  [CONSTANTS.CHANGE_DISPLAY_TABS]: (state, { payload: { tabNames } }) => {
    // debugger
    let result = state
    console.log(tabNames)
    let primaryTabOrder = getIn(result, 'masterOptions.defaultPrimaryTabOrder')
    const firstTitle = primaryTabOrder.first().get('title')
    const title = firstTitle
    // reset
    if (!tabNames.length) {
      result = setIn(
        result,
        'masterOptions.primaryTabOrder',
        getIn(result, 'masterOptions.defaultPrimaryTabOrder')
      )
      result = setIn(
        result,
        'masterOptions.secondaryTabMap',
        getIn(result, 'masterOptions.defaultSecondaryTabMap')
      )
      // debugger
      result = changePrimaryTab(result, title)
      // go to proper secondary tab if exists...
    } else {
      const primaryTabNames = []
      let secondaryTabMap = getIn(result, 'masterOptions.secondaryTabMap')
      tabNames.forEach(tab => {
        if (typeof tab === 'string') {
          primaryTabNames.push(tab)
        } else {
          primaryTabNames.push(tab.name)
          // secondaryTabMap[tab.name] = tab.tabs
          secondaryTabMap = setIn(secondaryTabMap, tab.name, fromJS(tab.tabs))
        }
      })
      primaryTabOrder = primaryTabOrder.filter(x => {
        console.log(x)
        return primaryTabNames.includes(x.get('title'))
      })
      result = setIn(result, 'masterOptions.primaryTabOrder', primaryTabOrder)
      result = setIn(
        result,
        'masterOptions.secondaryTabMap',
        fromJS(secondaryTabMap)
      )
      result = changePrimaryTab(
        result,
        trimmedCameled(primaryTabOrder.first().get('title'))
      )
    }
    return result
  },
  [CONSTANTS.CHANGE_FORM_TAB]: (state, action) => {
    console.log(action)
    let result = state
    result = changeFormTab(
      result,
      action.payload.selectedPrimaryTab,
      action.payload.selectedSecondaryTab
    )
    return result
  },
  [CONSTANTS.CHANGE_PRIMARY_TAB]: (state, { payload: { tab } }) => {
    let result = state
    result = changePrimaryTab(result, tab)
    return result
  },
  [CONSTANTS.DESTROY_FIELD]: (state, { payload: { propertyName } }) => {
    let result = state
    result = deleteIn(result, `fields.${propertyName}`)
    return result
  },
  [CONSTANTS.CHANGE_SECONDARY_TAB]: (
    state,
    { payload: { primaryTab, value } }
  ) => {
    let result = state
    let masterOptions = getIn(result, 'masterOptions')
    if (masterOptions) {
      masterOptions = setIn(masterOptions, 'selectedSecondaryTab', value)
      result = setIn(result, 'masterOptions', masterOptions)
    }
    return result
  },
  DESTROY_FIELD: (state, { payload: { propertyName } }) => {
    let result = state

    result = deleteIn(result, `values.${propertyName}`)
    result = deleteIn(result, `fields.${propertyName}`)
    return result
  },
  [CONSTANTS.DISABLE_FIELDS]: (state, action) => {
    console.log(state, action)
    console.log(state, action)
    let result = state
    const fieldsToDisable = action.payload.fields
    let fields = getIn(result, 'fields')
    fieldsToDisable.forEach(field => {
      let f = getIn(fields, field)
      f = setIn(f, 'disabled', true)
      fields = setIn(fields, field, f)
    })
    result = setIn(result, 'fields', fields)
    return result
  },
  [DISPLAY_INTERNAL_NOTES]: (state, action) => {
    let result = state
    result = setIn(result, 'notesDisplayed', true)
    return result
  },
  [CONSTANTS.ENABLE_FIELDS]: (state, action) => {
    console.log(state, action)
    console.log(state, action)
    let result = state
    const fieldsToEnable = action.payload.fields
    let fields = getIn(result, 'fields')
    fieldsToEnable.forEach(field => {
      let f = getIn(fields, field)
      f = setIn(f, 'disabled', false)
      fields = setIn(fields, field, f)
    })
    result = setIn(result, 'fields', fields)
    return result
  },
  [CONSTANTS.ENTER_NEW_MODE]: state => {
    let result = state
    result = setIn(result, 'newMode', true)
    result = setIn(result, 'isEditing', true)
    return result
  },

  // DEPRECATED
  // [CONSTANTS.EDIT_GRID_CELL]: (
  //   state,
  //   { payload: { propertyName, colId, rowId, value } }
  // ) => {
  //   let result = state
  //   const cells = getIn(result, `fields.${propertyName}.body.${rowId}.cells`)
  //   const index = cells.findIndex(c => c.get('id') === colId)
  //   const prevValue = getIn(cells, `${index}.value`)
  //   // console.log(cells.toJS(), prevValue)
  //   result = result.setIn(
  //     ['fields', propertyName, 'body', rowId, 'cells', index, 'value'],
  //     value
  //   )
  //   result = result.setIn(
  //     ['fields', propertyName, 'body', rowId, 'cells', index, 'prevValue'],
  //     prevValue
  //   )
  //   return result
  // },

  [CONSTANTS.FOCUS]: (state, { payload: { propertyName } }) => {
    let result = state
    const previouslyActive = getIn(result, 'active')
    result = deleteIn(state, `fields.${previouslyActive}.isActive`)
    result = setIn(result, 'active', propertyName)
    result = setIn(result, `fields.${propertyName}.isActive`, true)
    return result
  },

  [CONSTANTS.GET_ENTITY.FAILURE]: (state, action) => {
    let result = state
    if (action?.payload?.error === 'canceled') {
      return result
    }
    result = resetState(state)
    return result
  },
  [CONSTANTS.GET_ENTITY_SUCCESS_COMPLETE]: (state, action) => {
    const result = state
    // result = setIn(result, 'isEntitySuccessComplete', true)
    return result
  },
  [CONSTANTS.INITIALIZE]: (state, action) => {
    // debugger
    let result = state
    if (result.size > 2) return result
    result = result.merge(...fromJS(action.payload.initialState))
    const { sagas } = action.payload

    result = setIn(result, 'apiAlias', action.payload.apiAlias)
    result = setIn(result, 'actions', action.payload.actions)
    result = setIn(result, 'disableMeta', action.payload.disableMeta || false)
    result = setIn(result, 'displayName', action.payload.title || '')
    result = setIn(result, 'initialized', true)

    result = setIn(result, 'sagas', initializeSagas(sagas))
    result = setIn(result, 'title', action.payload.title || '')
    result = setIn(result, 'originalTitle', action.payload.title || '')
    result = setIn(
      result,
      'noAPIForCancelConfirm',
      !!action.payload.noAPIForCancelConfirm
    )
    result = setIn(result, 'modals', emptyList)
    result = setIn(
      result,
      'apiAlias',
      action.payload.apiAlias || action.payload.route
    )
    // if there's transformmeta fn fire it...
    // if there's a transformmeta
    // debugger
    let meta = action?.payload?.meta?.toJS
      ? action.payload.meta.toJS()
      : action?.payload?.meta
      ? action.payload.meta
      : {}
    if (action.payload.transformMeta && is.fn(action.payload.transformMeta)) {
      meta = action.payload.transformMeta(meta)
    }
    result = setIn(
      result,
      'meta',
      fromJS(extractIndexSearchFieldsFromMeta(meta))
    )
    result = setIn(
      result,
      'updateTitleMethod',
      action.payload.updateTitleMethod
    )
    result = setIn(
      result,
      'noCancelEditProcess',
      action.payload.noCancelEditProcess
    )
    result = setIn(result, 'getEntityApi', action.payload.getEntityApi)
    if (action.payload.openedFrom) {
      result = setIn(result, 'openedFrom', action.payload.openedFrom)
      result = setIn(result, 'alternateEntry', true)
    }
    // result = setIn(result, 'meta', extractIndexSearchFieldsFromMeta(action.payload.meta)), // action.payload.meta || {})
    // TODO
    if (action.payload.asModal) {
      result = setIn(result, 'modal', true)
    }

    if (action.payload.masterOptions) {
      // const { primaryTabOrder, secondaryTabOrder, selectedTab, selectedSecondaryTab, tabs } =
      const masterOptions = createMasterOptions(action.payload.masterOptions)

      if (
        action.payload.masterOptions.selectedPrimaryTab ||
        action.payload.masterOptions.selectedSecondaryTab
      ) {
        masterOptions.selectedPrimaryTab =
          action.payload.masterOptions.selectedPrimaryTab ||
          masterOptions.selectedPrimaryTab
        masterOptions.selectedSecondaryTab =
          action.payload.masterOptions.selectedSecondaryTab ||
          masterOptions.selectedSecondaryTab
      }
      masterOptions.trackedTabs = masterOptions.trackedTabs.add(
        masterOptions.selectedPrimaryTab
      )
      if (masterOptions.selectedSecondaryTab) {
        masterOptions.trackedTabs = masterOptions.trackedTabs.add(
          masterOptions.selectedSecondaryTab
        )
      }

      masterOptions.secondaryTabMap = masterOptions.defaultSecondaryTabMap
      // if (action.payload.masterOptions.ignoreResetFields)
      masterOptions.ignoreResetFields =
        action.payload.masterOptions.ignoreResetFields || Set()

      // check out selectedPrimary/selectedSecondary tabs.
      // if they have access bool/fn, add flag to the "tab".
      // accessed: true..
      const { tabComponents } = masterOptions
      const primaryTab = tabComponents[masterOptions.selectedPrimaryTab]
      const secondaryTab = masterOptions.selectedSecondaryTab
        ? tabComponents[
            `${masterOptions.selectedPrimaryTab}-${masterOptions.selectedSecondaryTab}`
          ]
        : null

      if (primaryTab) {
        primaryTab.accessed = true
        // tabComponents[masterOptions.selectedPrimaryTab] = primaryTab
      }

      if (secondaryTab) {
        secondaryTab.accessed = true
        // tabComponents[masterOptions.selectedSecondaryTab] = secondaryTab
      }

      if (action.payload.meta && action.payload.meta.guid) {
        result = setIn(result, 'guid', action.payload.meta.guid)
      }
      // console.log(primaryTab, secondaryTab)

      result = setIn(result, 'masterOptions', fromJS(masterOptions))
      // todo.. this will prob need to be looked at when we start loading from a link
      result = setIn(result, 'hasRecord', false)
    }
    customBehaviors = {
      ...customBehaviors,
      [action.meta.form]: action.payload.masterOptions
        ? { ...masterBehaviors, ...action.payload.behaviors }
        : action.payload.behaviors
    }
    console.log(customBehaviors)
    if (!getIn(result, 'values')) {
      result = setIn(result, 'values', fromJS({}))
    }
    if (!getIn(result, 'fields')) {
      result = setIn(result, 'fields', fromJS({}))
    }
    result = setIn(result, 'editedFields', Set())
    return result
  },
  // [CONSTANTS.REGISTER_FIELD]: (state, action) => registerField(state, action),
  [CONSTANTS.REGISTER_FIELD]: registerField,
  [CONSTANTS.SAVE.SUCCESS]: (state, action) => {
    const { payload } = action
    console.log(payload, state.toJS())
    let result = state
    let values = payload.data ? payload.data : payload
    const { newMode, ...rest } = values
    values = fromJS(rest)
    console.log(newMode)
    if (newMode) {
      result = setIn(result, 'newMode', true)
    }
    // result = setIn(result, 'values', values)
    result = setIn(result, 'hasRecord', true)
    // const fields = mapValuesOverFields(result, values)
    // result = setIn(result, 'fields', fields)
    result = updateValuesAndFields(result, values)
    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'editedFields', Set())
    return result
  },
  [CONSTANTS.SET_FIELD]: (
    state,
    { payload: { propertyName, value, deleteMeta, results } }
  ) => setField(state, propertyName, value, deleteMeta, results),
  [CONSTANTS.SET_FIELDS]: (state, { payload: { fields } }) => {
    let result = state
    for (const field of fields) {
      const temp = result
      try {
        result = setField(
          result,
          field.propertyName,
          field.value,
          field.deleteMeta
        )
      } catch (e) {
        console.log(e)
        result = temp
      }
    }
    return result
  },
  [CONSTANTS.SET_FORM_META]: (state, { payload }) =>
    setIn(
      state,
      'meta',
      fromJS(extractIndexSearchFieldsFromMeta(payload || {}))
    ),
  [CONSTANTS.SET_VALUE]: (state, { payload: { propertyName, value } }) => {
    let result = state
    result = setIn(result, `values.${propertyName}`, value)
    return result
  },
  // DEPRECATED
  // [CONSTANTS.EDIT_GRID_CELL]: (
  //   state,
  //   { payload: { propertyName, colId, rowId, value } }
  // ) => {
  //   let result = state
  //   const cells = getIn(result, `fields.${propertyName}.body.${rowId}.cells`)
  //   const index = cells.findIndex(c => c.get('id') === colId)
  //   const prevValue = getIn(cells, `${index}.value`)
  //   // console.log(cells.toJS(), prevValue)
  //   result = result.setIn(
  //     ['fields', propertyName, 'body', rowId, 'cells', index, 'value'],
  //     value
  //   )
  //   result = result.setIn(
  //     ['fields', propertyName, 'body', rowId, 'cells', index, 'prevValue'],
  //     prevValue
  //   )
  //   return result
  // },
  // // TODO

  // ns out these actions in another way.

  [CONSTANTS.UPDATE_FORM_TITLE]: (state, { payload: { title } }) => {
    let result = state
    result = setIn(result, 'title', title)
    return result
  },

  // }

  [CONSTANTS.UNLOCK_ENTITY.SUCCESS]: (state, action) => {
    const result = resetState(state)
    return result
  }
}
const ddiFormReducer = () => {
  const getBehaviors = form => ({
    ...behaviors,
    ...customBehaviors[form]
  })

  const reducer = (state = empty, action, form) => {
    const b = getBehaviors(form)
    let result = state
    if (action.payload && action.payload.propertyName) {
      // const special = checkForSpecial(action.type)
      // debugger
      const special = checkForSpecial(action.meta)
      if (special) {
        let field = getIn(state, `fields.${action.payload.propertyName}`)
        field = special(field, action, form)
        result = setIn(result, `fields.${action.payload.propertyName}`, field)
      }
    }
    if (
      customBehaviors[form] &&
      behaviors[action.type] &&
      customBehaviors[form][action.type]
    ) {
      result = behaviors[action.type](result, action)

      result = customBehaviors[form][action.type](result, action)
      return result
    }

    if (action.meta.modal) {
      let modals = getIn(state, 'modals')
      const modalFn = modalBehaviors[action.type]
      if (modalFn) {
        modals = modalFn(modals, action)
        result = setIn(result, 'modals', modals)
      }

      return result
    }
    const behavior = b[action.type]
    return behavior ? behavior(result, action) : result
  }

  const enhancer = duck => (state = empty, action = {}) => {
    if (
      action.type === SUBMIT_LOGOUT_USER.REQUEST ||
      action.type === SUBMIT_LOGOUT_USER.SUCCESS ||
      action.type === SUBMIT_LOGOUT_USER.FAILURE ||
      action.type === AUTHENTICATION_ERROR ||
      action.type === CLEAR_SELECTED_BRANCH
    ) {
      return fromJS({})
    }
    const form = action?.meta?.form
    const mobileAction = action?.meta?.mobile
    if (mobileAction) {
      if (action.meta.customer != null) {
        state = setIn(state, 'mobileGlobal.customer.id', action.meta.customer)
        state = setIn(
          state,
          'mobileGlobal.customer.description',
          action.meta.description
        )
      }
    }
    if (!form) return state
    if (action.type === CONSTANTS.DESTROY) {
      return deleteIn(state, action.meta.form)
    }

    const formState = getIn(state, form)
    if (
      action.type === CONSTANTS.BEFORE_DESTROY &&
      ((formState && !formState.size) || !formState)
    ) {
      return state
    }
    let result

    result = duck(formState, action, form)
    if (result === formState) {
      return state
    }
    result = setIn(state, form, result)
    return result
  }

  return enhancer(reducer)
}

export default ddiFormReducer

// import { Map } from 'immutable'
// export default function(state = Map(), action) {
//   console.log(action, state)
//   return state
// }
