import behaviors from './behaviors'
import createSearch from '../createSearch'
import DropDownComponent from './DropDownComponent'
import TextInput from './TextInput'

const SendInfoSearch = createSearch({
  DropDownComponent,
  TextInput,
  behaviors,
  name: 'SendInfoSearch',
  popoverStyle: { width: 700 },
  disableAutoFocus: true,
  disableEnforceFocus: true,
  disableRestoreFocus: true,
  disableShouldUpdateWrapper: true
})

export default SendInfoSearch
