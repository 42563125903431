/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getIn } from 'utils'
import { connect } from 'react-redux'
import { DDISearchDropdown, DDITextField, DDIToggle } from 'ddiForm/wrapped'

export const userColumnDefs = [
  {
    field: 'dataId',
    headerName: 'code', // 'chk',
    filter: false,
    width: 50
    // width: 70
  },
  {
    field: 'description',
    headerName: 'description',
    width: 100
  }
]
export const getRowNodeId = data => data.dataId
export const required = value => (value ? undefined : 'Required')

export const mapStateToProps = (state, ownProps) => ({
  users: getIn(state, 'auth.usersWithDepartments').toJS()
})
export class Mail extends Component {
  constructor(...args) {
    super(...args)
    let disabled
    if (this.props.data.newMail) {
      disabled = false
    } else if (this.props.data.openMail) {
      disabled = true
    }
    this.state = {
      disabled,
      description: this.props.data.description
    }
  }

  userDropDown = React.createRef()

  render() {
    const {
      dataId,
      details,
      highPriority,
      fromUserName,
      fromUserInitials,
      guId,
      mailNumber,
      userName,
      sentOn
    } = this.props.data

    return (
      <div>
        <Row style={{ marginBottom: 15 }}>
          <Col sm={2} md={2} lg={2}>
            <div style={{ display: 'flex', alignItems: 'center' }}>From:</div>
          </Col>
          <Col sm={10} md={10} lg={10}>
            <strong>{this.props.data.userName}</strong>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col sm={2} md={2} lg={2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              To:
            </div>
          </Col>
          <Col sm={7} md={7} lg={7}>
            <DDISearchDropdown
              disabled={this.state.disabled}
              propertyName="toUserIds"
              required
              fullWidth
              floatingLabelText="Users"
              rowData={this.props.users}
              hintText="<No Users>"
              identifier="dataId"
              validate={[required]}
              // ref={c => (this.userDropDown = c)}
              inputRef={this.userDropDown}
              gridOptions={{
                className: 'ag-fresh secondary-grid',
                columnDefs: userColumnDefs,
                enableSorting: true,
                getRowNodeId,
                height: 200
              }}
            />
          </Col>
          <Col sm={3} md={3} lg={3}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              High Priority{' '}
              <DDIToggle
                propertyName="highPriority"
                disabled={this.state.disabled}
                initialValue={highPriority || false}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col sm={2} md={2} lg={2}>
            Subject:
          </Col>
          <Col sm={10} md={10} lg={10}>
            <DDITextField
              propertyName="subject"
              fullWidth
              initialValue={this.state.description}
              disabled={this.state.disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={2} md={2} lg={2}>
            Message:
          </Col>
          <Col sm={10} md={10} lg={10}>
            <DDITextField
              propertyName="details"
              multiline
              rows={15}
              fullWidth
              disabled={this.state.disabled}
              initialValue={details}
              variant="outlined"
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(Mail)
