import { openScreen as openScreenAction } from 'pages/Main/actions'
import { noop } from 'utils'

export default {
  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut: () => {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select() {
        target.select()
      },
      paste() {},
      delete: () => {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        const { openScreenData } = this.props
        this.props.dispatch(openScreenAction(openScreenData))
      }
    }
    return (action[data.type] || action.select)()
  },
  getButtonStyle() {
    let { buttonStyle } = this.props
    if (this.props.disabled) {
      buttonStyle = {
        ...buttonStyle,
        background: '#000',
        opacity: 0.12
      }
    }
    return buttonStyle
  },
  getDisplayValue() {
    if (this.props.displayDescription && !this.state.isFocused) {
      return this.state.description || this.props.description || ''
    }
    return this.state.value || ''
  },
  getValue() {
    return this.state.value
  },
  async onBlur(event) {
    // debugger
    if (event && event.persist) event.persist()
    if (this.state.isOpen || this.props.isFetching) return
    if (this._isMounted) {
      await this.setState({ isFocused: false })
    }
    if (!this.state.isSet && this.state.isDirty && this._isMounted) {
      this.setState(
        {
          value: '',
          description: '',
          isDirty: false
        },
        () => this.blur(this.state.value)
      )
    } else {
      this.blur(this.state.value)
    }
  },
  async onChange(event) {
    this.timeout = this.timeout || 0
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (event && event.persist) event.persist()
    const v = event.target.value
    const callPartial = () => {
      this.timeout = setTimeout(
        () => this.partialMatchSearch(v),
        this.props.timer || 500
      )
    }

    const {
      meta: { allowInstantSearch, minimumKeywordLength }
    } = this.props
    if (this._isMounted) {
      await this.setState({
        isDirty: true,
        value: v,
        // lastSearch: event.target.value,
        isSet: false
      })
    }
    if (allowInstantSearch && v.length >= (minimumKeywordLength || 2)) {
      if (this._isMounted) {
        this.setState(
          {
            lastSearch: event.target.value,
            value: event.target.value
          },
          () => callPartial()
        )
      }
    } else if (v.length === 0) {
      this.clearSearch()
      this.setField('', true)
    }
  },
  onFocus(event) {
    event.persist()
    if (this._isMounted) {
      this.setState(
        {
          isFocused: true,
          mouseover: false
        },
        () => {
          // console.log(this, this.props)
          // if (this.state.isSet) {
          if (this.textField) this.textField.select()
          // }
          if (this.props.onFocus) {
            this.props.onFocus(event)
          }
        }
      )
    }
  },
  onSearchClick(event) {
    if (this._isMounted) {
      this.setState({ lastSearch: '', isSet: false, searchClicked: true }, () =>
        this.partialMatchSearch(this.state.value, true)
      )
    }
  },
  setField(val, clear, results) {
    const { clearFieldOnSet, leader, setField, onSetField } = this.props

    setField(val, clear, leader, results)
    if (onSetField) {
      onSetField(val, clear, leader, results)
    }
    if (clearFieldOnSet) {
      setField('', clear)
    }
  },
  showQuickEntityClick: noop
}
