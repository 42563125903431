import React, { Component } from 'react'
import { noop } from 'utils'
import { TextField } from '@material-ui/core'

export default class TextArea extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.isDirty ||
      nextProps.value === undefined ||
      nextProps.value === prevState.value
    ) {
      return null
    }

    return { value: nextProps.value, isDirty: false }
  }

  static defaultProps = {
    onChange: noop,
    onDoubleClick: noop,
    style: {}
  }

  state = { value: '', isDirty: false }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.disabled && !this.props.disabled && this.notesField) {
      setTimeout(() => {
        this.notesField.focus()
      }, 50)
    }
  }

  onChange = e => this.setState({ value: e.target.value, isDirty: true })

  onBlur = e => {
    if (this.state.isDirty) {
      const value = e.target.value
      this.setState({ isDirty: false }, () => this.props.onBlur(value))
    }
  }
  textArea = null

  bindTextArea = c => (this.textArea = c)

  render() {
    const style = this.props.disabled
      ? {
        ...this.props.style,
        // border: '1px dashed #ccc',
        color: 'rgba(0, 0, 0, 0.38)'
      }
      : { width: '100%' }
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          margin: '0 auto'
        }}
      >
        <TextField
          disabled={this.props.disabled}
          onBlur={this.onBlur}
          onChange={this.onChange}
          value={this.state.value}
          style={style}
          ref={this.bindTextArea}
          multiline
          variant="outlined"
          id="notes-modal-note"
          inputRef={el => (this.notesField = el)}
        />
      </div>
    )
  }
}
