import React, { Component } from 'react'
import { numToDollar } from 'utils'

export default class PinnedRowRenderer extends Component {
  render() {
    if (this.props.colDef.field === 'dataId') {
      return (
        <span>
          <strong>Totals:</strong>
        </span>
      )
    } else if (this.props.colDef.field === 'orderValue') {
      return (
        <span>
          <strong>{numToDollar(this.props.value)}</strong>
        </span>
      )
    }
    return null
  }
}
