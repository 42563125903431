import { getDisplayName, deepEqual } from 'utils'
import invariant from 'invariant'
import { isValidElementType } from 'react-is'

export default ({ blacklist = [], whitelist = [] }) => WrappedComponent => {
  invariant(
    isValidElementType(WrappedComponent),
    'shouldUpdate requires a class component'
  )
  const bLength = blacklist.length
  const wLength = whitelist.length

  invariant(
    !(!bLength && !wLength) || ((bLength && wLength) || (wLength && bLength)),
    'args are bad mkay'
  )
  function shouldComponentUpdateBlacklist(nextProps, nextState) {
    return (
      this.props.children ||
      nextProps.children ||
      Object.keys(nextProps).some(
        prop =>
          !blacklist.includes(prop) &&
          !deepEqual(this.props[prop], nextProps[prop])
      ) ||
      !deepEqual(this.state, nextState)
    )
  }
  function shouldComponentUpdateWhitelist(nextProps, nextState) {
    return (
      this.props.children ||
      nextProps.children ||
      Object.keys(nextProps).some(
        prop =>
          whitelist.includes(prop) &&
          !deepEqual(this.props[prop], nextProps[prop])
      ) ||
      !deepEqual(this.state, nextState)
    )
  }
  return class ShouldUpdate extends WrappedComponent {
    static displayName = `ShouldUpdate(${getDisplayName(WrappedComponent)})`
    constructor(...args) {
      super(...args)
      this.shouldComponentUpdate = blacklist.length
        ? shouldComponentUpdateBlacklist.bind(this)
        : shouldComponentUpdateWhitelist.bind(this)
    }
  }
}
