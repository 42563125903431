import { createAction, createAsyncAction } from 'utils'
import * as CONSTANTS from './constants'

export const signup = createAsyncAction(CONSTANTS.SIGNUP, null, null, true)
export const resetPassword = createAsyncAction(
  CONSTANTS.RESET_PASSWORD,
  null,
  null,
  true
)
export const changePassword = createAsyncAction(
  CONSTANTS.CHANGE_PASSWORD,
  null,
  null,
  true
)

export const changeEmail = createAsyncAction(
  CONSTANTS.CHANGE_EMAIL,
  null,
  null,
  true
)
// export const signup = () => {
//   const { SIGNUP } = CONSTANTS
//   return {
//     request: ({ username, password, confirmPassword, company, email, userKey }) => createAction(SIGNUP.REQUEST, { username, password, confirmPassword, company, email, userKey }),
//     success: response => createAction(SIGNUP.SUCCESS, response),
//     failure: error => createAction(SIGNUP.FAILURE, { ...error }, true)
//   }
// }

export const validateField = ({ field, valid }) =>
  createAction(CONSTANTS.VALIDATE_FIELD, { field, valid })

export const validatePasswordStrength = passwordValidation =>
  createAction(CONSTANTS.VALIDATE_PASSWORD_STRENGTH, passwordValidation)
export const validatePasswordConfirm = passwordsMatch =>
  createAction(CONSTANTS.VALIDATE_PASSWORD_CONFIRM, { passwordsMatch })

export const resetStatus = () => createAction(CONSTANTS.RESET_STATUS, {})
