import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { deepEqual, /* isComponent */ is } from 'utils'
import memoize from 'memoize-one'
import shouldUpdate from 'hoc/shouldUpdate'
import { withDDIForm } from 'ddiForm/DDIFormContext'
import { isValidElementType } from 'react-is'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Icon
} from '@material-ui/core'
import { removeModal } from './actions'

import ConnectedActions from './ConnectedActions'
import PaperComponent from './PaperComponent'
// import Measure from 'react-measure'

const mapStateToProps = state => ({
  modals: (state.get('modals') && state.get('modals').toJS()) || []
})
const mapDispatchToProps = dispatch => ({
  dispatch
  // removeModal: (form, id) => dispatch(removeModal(form, id))
})

const whitelist = ['modals', 'formState']

const modalEl = document.getElementById('modals')
// function classComponent(component) {
//   return (
//     typeof component === 'function' &&
//     component.prototype &&
//     !!component.prototype.isReactComponent
//   )
// }

// Ensure compatability with transformed code
function functionComponent(component) {
  return (
    typeof component === 'function' &&
    String(component).includes('return') &&
    (String(component).includes('React.') ||
      String(component).includes('react.')) &&
    String(component).includes('.createElement')
  )
}

export class MuiModal extends Component {
  static displayName = 'MuiModal'

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modals: PropTypes.array.isRequired
  }
  // static contextTypes = {
  //   store: PropTypes.object.isRequired,
  //   _ddiForm: PropTypes.object
  // }

  constructor(props) {
    super(props)
    this.modals = []
    this.state = {
      modalEl,
      renderedModals: []
    }
  }

  onBackdropClick = e => e.preventDefault()

  onExited = id => () => {
    this.props.dispatch(removeModal(this.props.form, id))
    // remove
  }

  bindRef = c => (this.modalRef = c)

  renderIcon = iconType => {
    const style = {
      color: '#fff',
      fill: '#fff',
      width: 16
    }
    const icons = {
      cancel: () => <Icon style={style}>close</Icon>,
      default: () => null,
      print: () => <Icon style={style}>print</Icon>,
      save: () => <Icon style={style}>save</Icon>,
      send: () => <Icon style={style}>send</Icon>,
      view: () => <Icon style={style}>zoom_in</Icon>
    }
    return (icons[iconType] || icons.default)()
  }

  renderModals = memoize(modals => {
    const ret = modals.map(modal => {
      if (!modal.options && modal.component && modal.props) {
        const dialog = (
          <modal.component
            {...modal.props}
            open={modal.show}
            onExiting={() => console.log('exiting')}
            onExited={() => this.onExited(modal.id)}
            disablePortal
            id={modal.id}
            key={modal.id}
          />
        )

        return dialog // modal.show ? dialog : null
      }
      const opts = (modal && modal.options) || {}
      console.log(
        isValidElementType(opts.title),
        isValidElementType(opts.actions)
      )
      const style = {
        maxHeight: opts.height ? opts.height : opts.maxHeight || 400,
        maxWidth: opts.width ? 'none' : 768,
        width: opts.width || '75%',
        marginTop: opts.marginTop || -100,
        height: opts.height,
        overflow: opts.overflow
      }

      if (opts.maxSize) {
        style.maxHeight = '95%'
        style.maxWidth = '95%'
        style.width = '90%'
        delete style.marginTop
      }

      let title
      if (is.string(opts.title)) {
        title = opts.title
      } else if (isValidElementType(opts.title)) {
        title = (
          <opts.title
            {...this.props}
            modalRef={this}
            modal={modal}
            ref={c => (this[`${modal.id}-title`] = c)}
          />
        )
      }
      const actions = () => {
        if (
          opts.actions === false ||
          (opts.data && opts.data.actions === false)
        ) {
          return null
        }
        return isValidElementType(
          opts.actions || (opts.data && opts.data.actions)
        ) ? (
          <opts.actions
            renderIcon={this.renderIcon}
            {...this.props}
            modal={modal}
            modalRef={this}
          />
        ) : (
          <ConnectedActions
            renderIcon={this.renderIcon}
            {...this.props}
            modal={modal}
            modalRef={this}
          />
        )
      }

      const dialog =
        modal && modal.show ? (
          <Dialog
            open={modal.show}
            key={modal.id}
            // aria-labelledby="alert-dialog-title"
            // aria-describedby="alert-dialog-description"
            fullScreen={opts.fullScreen || false}
            width
            PaperProps={{ style }}
            PaperComponent={opts.draggable ? PaperComponent : undefined}
            onBackdropClick={this.onBackdropClick}
            className={
              opts.modalOverrideClass
                ? `ignore-drag ddi-modal ${opts.modalOverrideClass}`
                : 'ignore-drag ddi-modal'
            }
            ref={c => (this[`${modal.id}-container`] = c)}
            // transitionDuration={2500}
            onExiting={() => console.log('exiting')}
            onExited={this.onExited(modal.id)}
            disableEnforceFocus={opts.disableEnforceFocus}
            style={opts.modalContainerStyle || {}}
            // style={{ zIndex: 99999 }}
            // disablePortal
          >
            <DialogTitle
              style={
                opts.headingStyle || (opts.maxSize && { padding: '20 24 12' })
              }
              id="alert-dialog-title"
            >
              {title}
            </DialogTitle>

            <DialogContent
              style={opts.maxSize && { height: '95%', padding: '0 12 12' }}
            >
              <div>
                <modal.component
                  data={opts.data}
                  id={modal.id}
                  ref={c => (this[modal.id] = c)}
                />
              </div>
            </DialogContent>

            {!opts.hideActions ? (
              <DialogActions
                style={opts.actionBarStyle ? opts.actionBarStyle : null}
              >
                {actions()}
              </DialogActions>
            ) : null}
          </Dialog>
        ) : null
      return dialog
    })

    return ret
  })

  render() {
    const { modals } = this.props
    return <div ref={this.bindRef}>{this.renderModals(modals)}</div>
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
  // { forwardRef: true }
)(withDDIForm(shouldUpdate({ whitelist })(MuiModal)))
