import { BASE_INFORM_API_URL } from 'services/constants'
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'

export const shiptoOpportunitiesInquiry = params => {
  const options = createApiOptions({
    body: { ...params },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/shiptoopportunities/search`, options)
}

export const closeShipToOpportunities = guid => {
  const options = createApiOptions({
    body: { guid },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/shiptoopportunities/close`, options)
}