import behaviors from './behaviors'
import createSearch from '../createSearch'
import DropDownComponent from './DropDownComponent'
import TextInput from './components/TextInput'
import BaseTooltipTemplate from 'components/BaseTooltipTemplate'
import DocSearchTooltipTemplate from './components/DocSearchTooltipTemplate'
import { getTooltip } from './actions'

const DocumentSearch = createSearch({
  name: 'DocumentSearch',
  type: 'documentSearch',
  behaviors,
  popoverStyle: { width: 500 },
  DropDownComponent,
  TextInput,
  meta: {
    allowDownload: true,
    allowInstantSearch: true,
    allowSearchAll: true,
    hasUpload: true,
    minimumKeywordLength: 2
  },
  tooltip: {
    component: DocSearchTooltipTemplate,
    // keyToActionMap,

    networkRequestPromise: getTooltip,
    style: { width: 400 },
    requestArgs() {
      return {
        // indexSearchType:
        //   this.props.indexSearchType ||
        //   (this.props.meta && this.props.meta.searchType),
        documentType: this.props.documentType,
        propertyName: this.props.propertyName,
        recordName: this.props.agGridReact
          ? this.props.data.recordName
          : this.props.recordName || this.props.value
      }
    }
  }
})

export default DocumentSearch
