import numToDollar from 'utils/numToDollar'

const formatDollarFields = (params, decimalScale = 2, maxDecimalScale = 10) => {
  if (params.value || params.value === 0) {
    return numToDollar(
      params.value,
      'en-US',
      'USD',
      decimalScale,
      maxDecimalScale
    )
  }
  return null
}

export default formatDollarFields
