import React, { Component } from 'react'

// import { Link } from 'react-router'
// import { requireAuthentication } from 'utils'

/* export default requireAuthentication(() => (
  <div style={{ textAlign: 'center' }}>
    <h1>Page Not Found.</h1>
    <p>Go to <Link to="/">Home Page</Link></p>
  </div>
)) */

/* Hey, eslint shut up! This needs to be a component, not a stateless function */
export default class NotFoundPage extends Component { // eslint-disable-line
  render() {
    return (
      <div className="not-found-page" style={{ textAlign: 'center' }}>
        <h1>Page Not Found.</h1>
        {/* <p>Go to <Link to="/">Home Page</Link></p> */ }
      </div>
    )
  }
}
