import callFetch from 'access/callFetch'
import { createApiOptions } from 'utils'
import { BASE_INFORM_API_URL } from '../constants'

export const refreshActivity = ({
  activeDate,
  userIds,
  eventTypes,
  tasksType,
  mode = 'M',
  ...rest
}) => {
  const options = createApiOptions({
    body: {
      activeDate,
      eventTypes,
      userIds,
      tasksType,
      mode,
      ...rest
    },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/dashboard/calendar/refreshactivities`,
    options
  )
}

export const taskCompleted = ({ id }) => {
  const options = createApiOptions({
    body: { dataId: id },
    method: 'post'
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/dashboard/calendar/taskcompleted`,
    options
  )
}

// export const updateActivity = ({  dataId, description, startDate, endDate, userIds, activeDate, selectedEventTypes, userTasksType }) => {
export const updateActivity = ({ dataId, startDate, endDate, activeDate }) => {
  const options = createApiOptions({
    body: {
      dataId,
      startDate,
      endDate,
      activeDate
    },
    method: 'post'
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/dashboard/calendar/updateactivity`,
    options
  )
}
// public Credentials OverrideCredentials { get; set; }
// public string userIDs { get; set; }
// public DateTime activeDate { get; set; }
// public string selectedEventTypes { get; set; }
// public string userTasksType { get; set; }
export const deleteActivity = ({ dataId, removeMe = false, credentials }) => {
  const body = credentials
    ? { credentials, dataId, removeMe }
    : { dataId, removeMe }
  const options = createApiOptions({
    body,
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/activity/delete`, options)
}

export const lockActivity = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/activity/editlock`, options)
}

export const unlockActivity = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/activity/canceledit`, options)
}

/* see VSO task 1378 in Epics > Common Functionality > Activities > Save an Activity */
export const saveActivity = ({
  dataId,
  eventTypeId,
  taskTypeId,
  referenceType,
  referenceId,
  startDate,
  endDate,
  subject,
  users,
  location,
  notes,
  reminderValue,
  reminderType,
  completed,
  ...rest
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      eventTypeId,
      taskTypeId,
      referenceType,
      referenceId,
      startDate,
      endDate,
      subject,
      users,
      location,
      notes,
      reminderValue,
      reminderType,
      completed,
      ...rest
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/activity/save`, options)
}

export const addNewActivity = ({ referenceId, referenceType }) => {
  const options = createApiOptions({
    body: { referenceId, referenceType },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/activity/new`, options)
}

export const getActivityMeta = ({ pin, username, password } = {}) => {
  const options = createApiOptions({
    body: {
      credentials: {
        pin,
        username,
        password
      }
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/activity/meta`, options)
}

export const addEventType = (/* { credentials } */) => {
  // const body = credentials ? { credentials } : {}
  const options = createApiOptions({
    body: {},
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/eventtype/meta`, options)
}

export const getEventTypes = ({
  indexSearchType,
  keyword = null,
  pageNumber = 1
}) => {
  const options = createApiOptions({
    body: {
      indexSearchType,
      keyword,
      pageNumber
    },
    method: 'post'
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/search/indexsearch/partialmatch`,
    options
  )
}

export const validateEventType = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/eventtype/read`, options)
}

/* updated 10/17/18 recordName newly added required param */
export const createEventType = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId, recordName: dataId },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/eventtype/create`, options)
}

export const saveEventType = ({ dataId, color }) => {
  const options = createApiOptions({
    body: {
      updateRecord: {
        dataId,
        color
      }
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/eventtype/save`, options)
}

export const deleteEventType = ({ dataId, credentials }) => {
  const body = credentials ? { credentials, dataId } : { dataId }
  const options = createApiOptions({
    body,
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/eventtype/delete`, options)
}

export const lockEventType = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/eventtype/editlock`, options)
}

export const unlockEventType = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/eventtype/canceledit`, options)
}

export const getServerTime = () => {
  const options = createApiOptions({
    // body: {},
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/activity/serverclock`, options)
}

export const getActivityTooltip = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/activity/summary`, options)
}

// var body = "{ userIds: '" + GUser90.UserID + "', activeDate: '" + date1 + "',
// selectedEventTypes: '', userTasksType: 'C' }";
// const foo = '69,79,89,99,ADL,ATL,B1,B2,B3,B90,B91,B92,B99,BA,BB,BCB,BER,BS,BZ,CF,CFJ,CJ,DD,
// DDI,DH,DJH,DM,DN,DP,DSH,ES,FB,FEN,FLE,FOO,GG,GJ,GL,JB,JE,JH,JM,
// JMC,JS,JSM,KT,LC,LC2,LR,LW,MA,MD,MG,MS,NQ,PW,QK,RG,RL,RS,SD,STM,SV,TA,TH,TI,TK,WEB,XJ,
// ZP,ZRP,*AP,*AR,*BJ,*CJ,*CO,*EV,*EX,*FJ,*PO,*SD,*SM,*ST,*W1,*W2,*W3,*W4,*W5,*WH'
