import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import invariant from 'invariant'
import uuid from 'uuid'
import ConnectedButton from './ConnectedButton'
import { withDDIForm } from './DDIFormContext'

class DDIButton extends Component {
  static propTypes = {
    id: PropTypes.string
    // required: PropTypes.bool,
  }

  static defaultProps = { id: null }

  constructor(...args) {
    super(...args)
    invariant(
      this.props.label || this.props.children,
      'label or children is required'
    )
    this.id = this.props.id || uuid.v1()
  }

  getLabel = memoize(label => {
    return label === 'function' ? label(this.props) : label
  })

  ref = React.createRef()

  render() {
    const interactionLabel = this.getLabel(this.props.label)
    return (
      <ConnectedButton {...this.props} ref={this.ref} id={this.id}>
        {interactionLabel || this.props.children}
      </ConnectedButton>
    )
  }
}

export default withDDIForm(DDIButton)
