import {
  all,
  take,
  call,
  select,
  put,
  spawn,
  fork,
  cancel
} from 'redux-saga/effects'
import { getIn, toCamelCase } from 'utils'

import { addModal, hideModal, removeModal } from 'modals/actions'
import { warningModal, confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'

import { api } from 'services'
import * as LEDGER_CONSTANTS from 'pages/CustomerMaster/tabs/Ledger/lib/constants'
import * as notesModalApi from './api'
import * as actions from './actions'
import * as CONSTANTS from './constants'
import NotesModal from '.'
import NotesModalActions from './NotesModalActions'

let ledgerNotesModalId
let internalNotesModalId

const titlePrefixes = {
  customerMaster: 'Customer - ',
  productMaster: 'Product - ',
  salesOrder: 'Customer - '
}

/* start internal notes stuff */
export function* showInternalNotesModal(notes, form, response) {
  // debugger
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId =
    (response && response.dataId) ||
    getIn(formState, 'fields.dataId.value') ||
    getIn(formState, 'values.dataId')
  const description =
    (response && response.description) ||
    getIn(formState, 'fields.description.value') ||
    getIn(formState, 'values.description')

  const titlePrefix = titlePrefixes[form] ? titlePrefixes[form] : ''
  const title =
    form && form.match(/salesOrder-/)
      ? `Sales Order - ${dataId} Internal Notes`
      : `${titlePrefix}${dataId} "${description}" Internal Notes`

  const modalOpts = {
    component: NotesModal,
    options: {
      data: { form, notes, type: 'internalNotes' },
      maxHeight: 600,
      title,
      width: 600,
      actions: NotesModalActions
    }
  }
  // debugger
  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  internalNotesModalId = modal.payload.id
  return internalNotesModalId
}

export function* launchNotesModalListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.LAUNCH_NOTES_MODAL)
    // debugger
    const formState = yield select(state => getIn(state, `ddiForm.${form}`))
    const internalNotes =
      getIn(formState, 'fields.internalNotes.value') ||
      getIn(formState, 'values.internalNotes')

    if (form === formListener) {
      yield fork(showInternalNotesModal, internalNotes, form)
    }
  }
}

export function* lockInternalNotesProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const guid = getIn(formState, 'guid') || null

  yield put({
    type: CONSTANTS.LOCK_INTERNAL_NOTES.REQUEST,
    meta: { form, apiRequest: true }
  })

  // debugger
  const { response, error } = yield call(notesModalApi.lockCMInternalNotes, {
    dataId,
    form,
    recordName: dataId,
    guid
  })

  if (response) {
    yield put(actions.lockInternalNotes.success(response, { form }))
  } else {
    yield put(actions.lockInternalNotes.failure(error, { form }))
  }
}

export function* lockInternalNotesListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.LOCK_INTERNAL_NOTES.TRY)
    // debugger
    if (form === formListener) {
      yield call(lockInternalNotesProcess, form)
    }
  }
}

export function* unlockInternalNotesProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const guid = getIn(formState, 'guid') || null

  yield put({
    type: CONSTANTS.UNLOCK_INTERNAL_NOTES.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(notesModalApi.unlockCMInternalNotes, {
    dataId,
    form,
    recordName: dataId,
    guid
  })

  if (response) {
    yield put(removeModal(form, internalNotesModalId))
    yield put(actions.unlockInternalNotes.success(response, { form }))
  } else {
    yield put(actions.unlockInternalNotes.failure(error, { form }))
  }
}

export function* unlockInternalNotesListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.UNLOCK_INTERNAL_NOTES.TRY)
    if (form === formListener) {
      yield call(unlockInternalNotesProcess, form)
    }
  }
}

export function* saveInternalNotesListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { action }
    } = yield take(CONSTANTS.SAVE_INTERNAL_NOTES.TRY)
    if (form === formListener) {
      if (action === 'delete') {
        yield call(deleteInternalNotesProcess, form)
      } else {
        yield call(saveInternalNotesProcess, form)
      }
    }
  }
}

export function* saveInternalNotesProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const note =
    getIn(formState, 'fields.internalNotes.value') ||
    getIn(formState, 'values.internalNotes')
  const guid = getIn(formState, 'guid') || null

  yield put({
    type: CONSTANTS.SAVE_INTERNAL_NOTES.REQUEST,
    meta: { form, apiRequest: true }
  })

  // console.log('noteBefore', note, note.match(/\n/))
  // note = note ? note.replace(/\n/g, '\r\n') : ''
  // console.log('noteAfter', note)

  const { response, error } = yield call(notesModalApi.saveCMInternalNotes, {
    dataId,
    form,
    recordName: dataId,
    retainLock: false,
    note: note ? note.replace(/\n/g, '\r\n') : '',
    guid
  })

  if (response) {
    yield put(removeModal(form, internalNotesModalId))

    yield put(
      actions.saveInternalNotes.success(
        {
          ...response,
          note
        },
        { form }
      )
    )
  } else {
    yield put(actions.saveInternalNotes.failure(error, { form }))
  }
}

export function* deleteInternalNotesProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')

  yield call(
    confirmationModal,
    'Do you wish to delete this note?',
    'Internal Notes'
  )
  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CONFIRMED) {
    yield put({
      type: CONSTANTS.SAVE_INTERNAL_NOTES.REQUEST,
      meta: { form, apiRequest: true }
    })

    const { response, error } = yield call(notesModalApi.saveCMInternalNotes, {
      dataId,
      form,
      recordName: dataId,
      retainLock: false,
      note: ''
    })

    if (response) {
      yield put(removeModal(form, internalNotesModalId))

      yield put(
        actions.saveInternalNotes.success(
          {
            ...response,
            note: ''
          },
          { form }
        )
      )
    } else {
      yield put(actions.saveInternalNotes.failure(error, { form }))
    }
  }
}

/* end internal notes stuff */

/* START disputed notes modal */
export function* showDisputedNotesModal(payload, form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )
  const invoicesFilter = getIn(
    formState,
    'fields.customerLedger.invoicesFilter.value'
  )
  const invoiceType =
    selectedSecondaryTab === 'paymentHistory' ||
    (selectedSecondaryTab === 'invoices' && invoicesFilter === 'P')
      ? 'Paid'
      : 'Open'

  const {
    appendToFinanceCharge,
    dataId,
    detailGrid,
    gridName,
    id,
    note,
    parentId,
    type
  } = payload

  yield put({
    type: LEDGER_CONSTANTS.OPEN_LEDGER_NOTES_MODAL.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.openLedgerNote, {
    dataId,
    route: type === 'disputedNotes' ? 'disputed' : 'onaccount'
  })

  if (response) {
    yield put({
      type: LEDGER_CONSTANTS.OPEN_LEDGER_NOTES_MODAL.SUCCESS,
      payload: response,
      meta: { form }
    })

    // debugger
    const modalOpts = {
      component: NotesModal,
      options: {
        // data: { appendToFinanceCharge, dataId, detailGrid, gridName, id, invoiceType, note, parentId, type: 'disputedNotes' },
        data: {
          ...response,
          form,
          invoiceType,
          parentId,
          gridName,
          id,
          detailGrid,
          type
        },
        maxHeight: 600,
        title: response.notesDisplay
          ? response.notesDisplay
          : `${invoiceType} Invoice - ${dataId} Disputed Notes`,
        width: 600,
        actions: NotesModalActions
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    ledgerNotesModalId = modal.payload.id
  } else {
    yield put({
      type: LEDGER_CONSTANTS.OPEN_LEDGER_NOTES_MODAL.FAILURE,
      payload: error,
      meta: { form }
    })

    ledgerNotesModalId = null
  }

  return ledgerNotesModalId
}

export function* showDisputedNotesModalListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(LEDGER_CONSTANTS.OPEN_LEDGER_NOTES_MODAL.TRY)
    if (form === formListener) {
      yield call(showDisputedNotesModal, payload, form)
    }
  }
}

export function* lockLedgerNotesProcess(payload, form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )
  const invoicesFilter = getIn(
    formState,
    'fields.customerLedger.invoicesFilter.value'
  )

  yield put({
    type: LEDGER_CONSTANTS.LOCK_LEDGER_NOTES.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.lockLedgerNote, {
    dataId: payload.dataId,
    route: payload.type === 'disputedNotes' ? 'disputed' : 'onaccount',
    // recordName: payload.dataId,
    paid:
      selectedSecondaryTab === 'paymentHistory' ||
      (selectedSecondaryTab === 'invoices' && invoicesFilter === 'P') ||
      false
  })

  if (response) {
    yield put({
      type: LEDGER_CONSTANTS.LOCK_LEDGER_NOTES.SUCCESS,
      meta: { form },
      payload: response
    })
  } else {
    yield put({
      type: LEDGER_CONSTANTS.LOCK_LEDGER_NOTES.FAILURE,
      meta: { form },
      payload: error
    })
  }
}

export function* lockLedgerNotesListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(LEDGER_CONSTANTS.LOCK_LEDGER_NOTES.TRY)
    if (form === formListener) {
      yield call(lockLedgerNotesProcess, payload, form)
    }
  }
}

export function* saveLedgerNotesProcess(payload, form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )
  const invoicesFilter = getIn(
    formState,
    'fields.customerLedger.invoicesFilter.value'
  )
  const isPaidInvoice =
    selectedSecondaryTab === 'paymentHistory' ||
    (selectedSecondaryTab === 'invoices' && invoicesFilter === 'P') ||
    false

  yield put({
    type: LEDGER_CONSTANTS.SAVE_LEDGER_NOTES.REQUEST,
    meta: { form, apiRequest: true }
  })

  const apiParams = Object.keys(payload).includes('appendToFinanceCharge')
    ? {
        dataId: payload.dataId,
        route: payload.type === 'disputedNotes' ? 'disputed' : 'onaccount',
        properties: {
          appendToFinanceCharge: payload.appendToFinanceCharge,
          note: payload.note ? payload.note.replace(/\n/g, '\r\n') : ''
        }
      }
    : {
        dataId: payload.dataId,
        route: payload.type === 'disputedNotes' ? 'disputed' : 'onaccount',
        properties: {
          note: payload.note ? payload.note.replace(/\n/g, '\r\n') : ''
        }
      }

  const { response, error } = yield call(api.saveLedgerNote, apiParams)

  if (response) {
    yield put(removeModal(form, ledgerNotesModalId))
    yield put({
      type: LEDGER_CONSTANTS.SAVE_LEDGER_NOTES.SUCCESS,
      meta: { form },
      payload: {
        ...response,
        ...payload
      }
    })
  } else {
    yield put({
      type: LEDGER_CONSTANTS.SAVE_LEDGER_NOTES.FAILURE,
      meta: { form },
      payload: error
    })
  }
}

export function* saveLedgerNotesListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(LEDGER_CONSTANTS.SAVE_LEDGER_NOTES.TRY)

    if (form === formListener) {
      if (payload.action === 'delete') {
        yield call(
          confirmationModal,
          'Do you wish to delete this note?',
          'Disputed Note'
        )
        const action = yield take([CONFIRMED, CANCELED])
        if (action.type === CONFIRMED) {
          yield call(saveLedgerNotesProcess, payload, form)
        }
      } else {
        yield call(saveLedgerNotesProcess, payload, form)
      }
    }
  }
}

export function* unlockLedgerNotesProcess(payload, form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )
  const invoicesFilter = getIn(
    formState,
    'fields.customerLedger.invoicesFilter.value'
  )
  const isPaidInvoice =
    selectedSecondaryTab === 'paymentHistory' ||
    (selectedSecondaryTab === 'invoices' && invoicesFilter === 'P') ||
    false

  yield put({
    type: LEDGER_CONSTANTS.UNLOCK_LEDGER_NOTES.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.unlockLedgerNote, {
    dataId: payload.dataId,
    route: payload.type === 'disputedNotes' ? 'disputed' : 'onaccount',
    paid: isPaidInvoice,
    recordName: payload.dataId
  })

  if (response) {
    yield put(removeModal(form, ledgerNotesModalId))
    yield put({
      type: LEDGER_CONSTANTS.UNLOCK_LEDGER_NOTES.SUCCESS,
      meta: { form },
      payload: response
    })
  } else {
    yield put({
      type: LEDGER_CONSTANTS.UNLOCK_LEDGER_NOTES.FAILURE,
      meta: { form },
      payload: error
    })
  }
}

export function* unlockLedgerNotesListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(LEDGER_CONSTANTS.UNLOCK_LEDGER_NOTES.TRY)
    if (form === formListener) {
      yield call(unlockLedgerNotesProcess, payload, form)
    }
  }
}

/* END disputed notes modal */

export default function* notesModalSagas(form) {
  yield all([
    fork(lockInternalNotesListener, form),
    fork(unlockInternalNotesListener, form),
    fork(saveInternalNotesListener, form),
    fork(launchNotesModalListener, form),
    fork(saveLedgerNotesListener, form),
    fork(lockLedgerNotesListener, form),
    fork(showDisputedNotesModalListener, form),
    fork(unlockLedgerNotesListener, form)
  ])
}
