import { component, options } from 'pages/PurchaseOrderInquiry'
import PurchaseOrderSearch from 'components/Search/PurchaseOrderSearch'
import createWrappedField from './createWrappedField'

import ddiForm from '../ddiForm'
// const DDIIndexSearch = props => <Field component={IS} {...props} />
const meta = {
  allowInstantSearch: false,
  allowSearchAll: true,
  hasFilters: false
}

export default createWrappedField({
  Component: PurchaseOrderSearch,
  name: 'DDIPurchaseOrderSearch',
  meta,
  DropDownComponent: ddiForm(options)(component)
})
