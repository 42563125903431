import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  DateTimePicker as MuiDTP,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import dayjs from 'dayjs'
import DateFnsUtils from '@date-io/dayjs'
import useState from 'hooks/useStateWithCallback'
import MomentUtils from '@date-io/moment'
import { noop } from 'utils'
import moment from 'moment'
import { isValidDate } from './utils'
import {
  darkGrey,
  ddiBlue,
  informBlue,
  mediumGrey,
  white
} from '../theme/colors'

const DateTimePicker = props => {
  const {
    disabled,
    label,
    tabIndex,
    value,
    disableFuture,
    maxDate,
    setField,
    onBlur,
    initialValue,
    hintStyle,
    format,
    inputRef
  } = props

  const [state, setState] = useState({
    value: initialValue || value,
    isDirty: false
  })

  const onChange = date => {
    if (date) {
      setState(
        prev => ({ labelDisplayCSS: 'none', value: date, dirty: true }),
        () => setField(date.format(props.format))
      )
    }
  }

  useEffect(() => {
    if (!state.dirty) {
      setState(prev => ({ ...prev, value: value ? dayjs(value) : null }))
    }
  }, [value])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div
        className="ddi-picker-with-label"
        style={{ position: 'relative' }}
        tabIndex={tabIndex}
      >
        <MuiDTP
          // className="ddi-picker"
          hintStyle={hintStyle}
          label={label}
          placeholder="Please Select a Date"
          format={format}
          // locale={locale}
          // id={id}
          value={state.value}
          disabled={disabled}
          onChange={onChange}
          // style={style}
          inputRef={props.inputRef}
          fullWidth
          onBlur={noop}
          maxDate={maxDate}
          onDoubleClick={props.onDoubleClick}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}
DateTimePicker.defaultProps = {
  format: 'MM/DD/YY hh:mm a'
}
export default DateTimePicker
