/* start INFORM custom colors */
export const ddiBlue = '#006699'
export const informBlue = '#517b9c'
export const turquoise = '#1abcf7'
export const flatGrey = '#cecece'
export const mediumGrey = '#999999'
export const darkGrey = '#444444'
export const disabledFieldColor = '#f5f5f5'
export const disabledButtonColor = '#7faaca'
export const errorRed = '#d9534f'
export const successGreen = '#389123'

export const altTableRowColor = '#f0f9ff'
export const selectedTableRowColor = '#d5edd5'

export const white = '#ffffff'

export const transparent = 'rgba(0, 0, 0, 0)'
export const fullBlack = 'rgba(0, 0, 0, 1)'
export const darkBlack = 'rgba(0, 0, 0, 0.87)'
export const lightBlack = 'rgba(0, 0, 0, 0.54)'
export const minBlack = 'rgba(0, 0, 0, 0.26)'
export const faintBlack = 'rgba(0, 0, 0, 0.12)'
export const fullWhite = 'rgba(255, 255, 255, 1)'
export const darkWhite = 'rgba(255, 255, 255, 0.87)'
export const lightWhite = 'rgba(255, 255, 255, 0.54)'
