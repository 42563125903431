import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { startsWith, endsWith } from 'lodash'
import { stringOrNull } from 'utils'

const filters = [
  {
    icon: 61,
    label: 'Equals',
    value: 'EQUALS'
  },
  {
    icon: 8800,
    label: 'Does not equal',
    value: 'NOT_EQUAL'
  },
  {
    icon: 60,
    label: 'Less than',
    value: 'LESS_THAN'
  },
  {
    icon: 8804,
    label: 'Less than or equal to',
    value: 'LESS_THAN_OR_EQUAL'
  },
  {
    icon: 62,
    label: 'Greater than',
    value: 'GREATER_THAN'
  },
  {
    icon: 8805,
    label: 'Greater than or equal to',
    value: 'GREATER_THAN_OR_EQUAL'
  },
  {
    icon: 42,
    label: 'Like',
    value: 'LIKE'
  },
  {
    label: 'Matches Regular Expression',
    value: 'REGEX'
  },
  {
    label: 'Starts with',
    value: 'STARTS_WITH'
  },
  {
    label: 'Contains',
    value: 'CONTAINS'
  },
  {
    label: 'Ends with',
    value: 'ENDS_WITH'
  },
  {
    label: 'Does not start with',
    value: 'DOES_NOT_START_WITH'
  },
  {
    label: 'Does not contain',
    value: 'DOES_NOT_CONTAIN'
  },
  {
    label: 'Does not end with',
    value: 'DOES_NOT_END_WITH'
  },
  {
    label: 'Does not match',
    value: 'DOES_NOT_MATCH'
  },
  {
    label: 'Not like',
    value: 'NOT_LIKE'
  }
]

const filterMap = {
  CONTAINS: (value, filterText) => value.match(new RegExp(filterText, 'gi')),
  DOES_NOT_CONTAIN: (value, filterText) =>
    !value.match(new RegExp(filterText, 'gi')),
  DOES_NOT_END_WITH: (value, filterText) =>
    !endsWith(value, filterText.toLowerCase()) ||
    !endsWith(value, filterText.toUpperCase()) ||
    !endsWith(value, filterText),
  DOES_NOT_MATCH: (value, filterText) =>
    value !== filterText.toLowerCase() ||
    value !== filterText.toUpperCase() ||
    value !== filterText,
  DOES_NOT_START_WITH: (value, filterText) =>
    !startsWith(value, filterText.toLowerCase()) ||
    !startsWith(value, filterText.toUpperCase()) ||
    !startsWith(value, filterText),
  ENDS_WITH: (value, filterText) =>
    endsWith(value, filterText.toLowerCase()) ||
    endsWith(value, filterText.toUpperCase()) ||
    endsWith(value, filterText),
  EQUALS: (value, filterText) => value === filterText,
  GREATER_THAN: (value, filterText) => value > filterText,
  GREATER_THAN_OR_EQUAL: (value, filterText) => value >= filterText,
  LESS_THAN: (value, filterText) => value < filterText,
  LESS_THAN_OR_EQUAL: (value, filterText) => value <= filterText,
  LIKE: (value, filterText) => value === filterText,
  NOT_EQUAL: (value, filterText) => value !== filterText,
  NOT_LIKE: (value, filterText) => !value.match(new RegExp(filterText, 'gi')),
  REGEX: (value, filterText) => value.match(new RegExp(filterText, 'gi')),
  STARTS_WITH: (value, filterText) =>
    startsWith(value, filterText.toLowerCase()) ||
    startsWith(value, filterText.toUpperCase()) ||
    startsWith(value, filterText)
}

export default class TextFilter extends Component {
  static defaultProps = {
    filterMap,
    filters
  }
  static propTypes = {
    filterMap: PropTypes.object,
    filters: PropTypes.array
  }
  constructor(params) {
    super(params)
    this.filterType = 'STARTS_WITH'
    this.filterText = null

    this.filterChangedCallback = params.filterChangedCallback
    this.filterModifiedCallback = params.filterModifiedCallback
    this.valueGetter = params.valueGetter
    this.filterParams = params.filterParams
    this.applyActive = this.filterParams && this.filterParams.apply === true
  }
  componentDidMount() {
    document.addEventListener('keyup', this.onKeyUp)
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp)
  }
  onFilterTextChanged = event => {
    let filterText = stringOrNull(event.target.value)

    if (filterText && filterText.trim() === '') {
      filterText = null
    }
    let newFilter
    if (filterText !== null && filterText !== undefined) {
      newFilter = filterText
    } else {
      newFilter = null
    }
    if (this.filterText !== newFilter) {
      this.filterText = newFilter
      this.filterChanged()
    }
  }
  onKeyUp = event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.handleClick()
    }
  }
  onFilterTypeChanged = event => {
    console.log('text filter changed')
    this.filterType = event.target.value
    this.filterChanged()
  }

  filterChanged = () => {
    this.filterModifiedCallback()
    if (!this.applyActive) {
      this.filterChangedCallback()
    }
    this.forceUpdate()
    // do soemthing
  }
  doesFilterPass(params) {
    const filterText = this.filterText
    if (filterText === null) {
      return true
    }
    const value = this.valueGetter(params)
    if (!value && value !== 0) {
      return false
    }
    const filterType = this.filterType
    if (typeof this.props.filterMap[filterType] === 'function') {
      return this.props.filterMap[filterType](value, filterText)
    }
    console.warn(`invalid filter type ${filterType}`)
    return false
  }

  /* init function appears to be deprecated in the ag-grid API */
  /* moved all this stuff into the constructor function SVE 10-17-16 */
  // init(params) {
  //   this.filterChangedCallback = params.filterChangedCallback
  //   this.filterModifiedCallback = params.filterModifiedCallback
  //   this.valueGetter = params.valueGetter
  //   this.filterParams = params.filterParams
  //   this.applyActive = this.filterParams && this.filterParams.apply === true
  // }

  handleClick = () => {
    this.filterChangedCallback()
    setTimeout(this.hidePopup.bind(this), 100)
  }

  afterGuiAttached = params => {
    this.hidePopup = params.hidePopup
    this.filterTextField.focus()
  }
  // called by agGrid
  isFilterActive() {
    return this.filterText !== null
  }

  render() {
    return (
      <div>
        <div>
          <select
            className="ag-filter-select"
            id="filterType"
            value={this.filterType.toString()}
            onChange={this.onFilterTypeChanged}
          >
            {this.props.filters.map((filter, index) => {
              const icon = String.fromCharCode(filter.icon)
              const label = `${icon} ${filter.label}`
              return (
                <option value={filter.value} key={filter.value}>
                  {label}
                </option>
              )
            })}
          </select>
        </div>
        <div>
          <input
            className="ag-filter-filter"
            ref={c => (this.filterTextField = c)}
            id="filterText"
            onChange={this.onFilterTextChanged}
            type="text"
            placeholder="Filter..."
          />
        </div>
        {/* <div className="ag-filter-apply-panel" id="applyPanel">
          <button type="button" id="applyButton" onClick={this.handleClick}>
            APPLY
          </button>
        </div> */}
      </div>
    )
  }
}
