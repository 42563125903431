import { createReducer, deleteIn, getIn, setIn, toCamelCase } from 'utils'
import { fromJS } from 'immutable'
// import Dashboard from 'pages/Dashboard'
import { SUBMIT_SELECTED_BRANCH, CLEAR_SELECTED_BRANCH } from 'auth/constants'
import uuid from 'uuid'
import * as CONSTANTS from './constants'

const initialState = fromJS({
  added: null, // index of new screen in openScreens array..
  openScreens: {},
  removed: null,
  screens: {}
})

const behaviors = {
  [CONSTANTS.ADD]: (
    state,
    { payload: { component, image, title, data, form }, payload }
  ) => {
    let result = state
    // debugger
    // const id = allowMultiple ? uuid.v1() : form || title
    const id = form || toCamelCase(title)
    const screen = getIn(result, `openScreens.${id}`)
    const screenData = data
      ? {
          component,
          data,
          image,
          title
        }
      : {
          component,
          image,
          title
        }
    // change logic allow for multiple entries if in config...
    // debugger
    if (!screen) {
      result = setIn(result, `screens.${id}`, fromJS(screenData))
      result = setIn(result, `openScreens.${id}`, id)
      // result = setIn(result, `openScreens.${title}`, fromJS({ component, image, title: title }))
      result = setIn(result, 'added', fromJS([id]))
      result = deleteIn(result, 'removed')
    } else {
      result = deleteIn(result, 'added')
      result = setIn(result, 'focus', id)
    }
    return result
  },
  [CONSTANTS.ON_ADD]: (state, { payload: { name }, payload }) => {
    let result = state
    // debugger
    result = deleteIn(result, 'added')
    return result
  },
  [CONSTANTS.ON_FOCUS]: (state, { payload: { name } }) => {
    let result = state
    result = deleteIn(result, 'focus')
    return result
  },
  [CONSTANTS.ON_RE_INIT]: (state, { payload: { components } }) => {
    let result = state
    result = result
      .get('openScreens')
      .merge(fromJS({ openScreens: components }))
    result = setIn(result, 'added', fromJS(Object.keys(components)))
    // result = setIn(result, 'openScreens', fromJS)
    return result
  },
  [CONSTANTS.REMOVE]: (state, { payload: { name } }) => {
    // debugger
    let result = state
    /* accessOverrrideMiddleware looks for the screens count */
    result = deleteIn(result, `screens.${name}`)
    result = deleteIn(result, `openScreens.${name}`)
    result = setIn(result, 'removed', name)
    return result
  },
  [CONSTANTS.ON_REMOVE]: (state, { payload: { name } }) => {
    // debugger
    let result = state
    console.log(result.toJS())
    result = deleteIn(result, 'removed')
    console.log(result.toJS())
    result = deleteIn(result, 'title')
    return result
  },
  [CONSTANTS.SET_PAGE_TITLE]: (state, { payload: { title } }) => {
    let result = state
    result = setIn(result, 'title', title)
    return result
  },
  [SUBMIT_SELECTED_BRANCH.SUCCESS]: (state, { payload }) => {
    let result = state
    console.log('SUBMIT BRANCH SUCCESS', result.toJS())
    result = setIn(result, 'cleared', true)
    return result
  },
  [CONSTANTS.CLEAR_LAYOUT_SCREEN_DATA]: (state, { payload: { name } }) => {
    let result = state
    result = setIn(result, `screens.${name}.data`, null)
    return result
  },
  [CONSTANTS.CLEAR_ORDER_PAD_DATA]: (state, action) => {
    let result = state
    result = setIn(result, 'screens.orderPad.data', null)
    return result
  },
  [CONSTANTS.CHANGE_TAB]: (state, { payload: { screen } }) => {
    let result = state
    const hasScreen = getIn(state, `openScreens.${screen}`)
    if (hasScreen) {
      result = setIn(result, 'focus', screen)
    }
    return result
  },
  [CLEAR_SELECTED_BRANCH]: state => {
    let newState = initialState
    newState = setIn(newState, 'clearAll', true)
    return newState
  },
  [CONSTANTS.LAYOUT_CLEARED]: state => {
    let result = state
    result = deleteIn(result, 'clearAll')
    return result
  }
}

export default createReducer(initialState, behaviors)
