import { isFunction } from 'lodash'
import createAsyncRequestTypes from './createAsyncRequestTypes'
import createAction from './createAction'

/**
 * creates an aysnchronous Redux action
 * @param {object|function} type - accepts an object or function
 * @param {string} form - ddiFormName
 * @returns {object} a triplet object (failure, request, success)
 */
const createAsyncAction = (
  type,
  form = null,
  withAccess,
  thunk = undefined
) => {
  type = isFunction(type) ? type() : type
  const TYPE =
    type.REQUEST && type.SUCCESS && type.FAILURE
      ? type
      : createAsyncRequestTypes(type)
  return {
    failure: (error, thunk) =>
      createAction(TYPE.FAILURE, { ...error }, null, true, { form, thunk }),
    request: args =>
      createAction(TYPE.REQUEST, { ...args }, form, false, {
        apiRequest: true,
        withAccess: !!withAccess,
        thunk
      }),
    success: (response, meta) =>
      createAction(TYPE.SUCCESS, response, null, false, meta || {}),
    type: TYPE
  }
}

export default createAsyncAction
