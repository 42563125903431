/**
 * creates an object for processing Async requests
 * @param {string} base - request name, e.g. @@auth/HEARTBEAT
 * @returns {object} a triplet object (TYPE, REQUEST, SUCCESS, FAILURE)
 */
const createAsyncRequestTypes = base =>
  ['REQUEST', 'SUCCESS', 'FAILURE', 'TRY'].reduce(
    (acc, type) => {
      acc[type] = `${base}_${type}`
      return acc
    },
    { TYPE: base }
  )

export default createAsyncRequestTypes
