import { createAsyncRequestTypes } from 'utils'

export const MOBILE_CALENDAR = '@@mobileCalendar/'

export const GET_CALENDAR_DATA = createAsyncRequestTypes(
  `${MOBILE_CALENDAR}GET_CALENDAR_DATA`
)

export const GET_CALENDAR_META = createAsyncRequestTypes(
  `${MOBILE_CALENDAR}GET_CALENDAR_META`
)

export const REFRESH_CALENDAR = createAsyncRequestTypes(
  `${MOBILE_CALENDAR}REFRESH_CALENDAR`
)

export const SIMPLE_CONSTANT = `${MOBILE_CALENDAR}SIMPLE_CONSTANT`

export const SELECT_CALENDAR_USERS = `${MOBILE_CALENDAR}SELECT_CALENDAR_USERS`
export const SELECT_CALENDAR_EVENT_TYPES = `${MOBILE_CALENDAR}SELECT_CALENDAR_EVENT_TYPES`

export const CHANGE_ACTIVE_DATE = `${MOBILE_CALENDAR}CHANGE_ACTIVE_DATE`
export const CHANGE_CALENDAR_VIEW = `${MOBILE_CALENDAR}CHANGE_CALENDAR_VIEW`

export const GET_CUSTOMER_CONTACTS = createAsyncRequestTypes(
  `${MOBILE_CALENDAR}GET_CUSTOMER_CONTACTS`
)

export const CLEAR_ACTIVITIES_DATA = `${MOBILE_CALENDAR}CLEAR_ACTIVITIES_DATA`

export const TOGGLE_INTERFACE_VIEW = `${MOBILE_CALENDAR}TOGGLE_INTERFACE_VIEW`

export const TASK_COMPLETED = createAsyncRequestTypes(
  `${MOBILE_CALENDAR}TASK_COMPLETED`
)
