import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
// import { getIn } from 'utils'
import getIn from 'utils/getIn'

const userIsAuthDefaults = {
  authenticatedSelector: state => state.get('auth').get('isAuthenticated'),
  authenticatingSelector: state => state.get('auth').get('isAuthenticating')
}
// const requireAuthentication = connectedRouterRedirect({
//   ...userIsAuthDefaults,
//   redirectPath: (state, ownProps) =>
//     getIn(state, 'router.location.pathname') === '/signup'
//       ? '/signup'
//       : '/login',
//   allowRedirectBack: state => getIn(state, 'location.pathname') !== '/signup'
// })

const requireAuthentication = connectedRouterRedirect({
  ...userIsAuthDefaults,
  redirectPath: (state, ownProps) => '/login',
  // redirectPath: (state, ownProps) =>
  //   getIn(state, 'router.location.pathname') === '/signup'
  //     ? '/signup'
  //     : '/login',
  allowRedirectBack: true
  // allowRedirectBack: true
  // allowRedirectBack: state => {
  //   debugger
  //   const ret = getIn(state, 'location.pathname') !== '/signup'
  //   return ret
  // }
})

export default requireAuthentication
