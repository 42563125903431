import ARInvoiceSearch from 'components/Search/ARInvoiceSearch'
import createWrappedField from './createWrappedField'

const meta = {
  allowInstantSearch: false,
  allowSearchAll: false,
  hasFilters: false
}

export default createWrappedField({
  Component: ARInvoiceSearch,
  name: 'DDIARInvoiceSearch',
  meta
})
