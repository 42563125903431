import { getIn } from 'utils'
import { setField } from 'ddiForm/actions'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { exactMatch } from 'pages/PurchaseOrderInquiry/actions'
import { CLOSE_SEARCH } from '../IndexSearch/constants'
import { screenDropDownOpen } from '../actions'
import { blur, clearSearch } from '../IndexSearch/actions'
import baseBehaviors from '../baseBehaviors'

const onRowSelected = async function onRowSelected(params) {
  if (params.node.selected) {
    // debugger
    try {
      await this.exactMatchSearch(params.node.data.dataId)
      this.props.dispatch(
        setField(
          this.form.split('.')[0],
          this.props.propertyName,
          params.data.dataId
        )
      )
    } finally {
      this.onRequestClose()
    }
  }
}
export default {
  ...baseBehaviors,
  blur(value) {
    if (!value) return
    this.props.dispatch(
      blur(this.form, {
        propertyName: this.props.propertyName,
        value,
        isSet: this.state.isSet
      })
    )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        this.props.onChange('')
      }
    }
  },
  clearSearch() {
    if (!this.props.getFormState) return
    this.props.dispatch(
      clearSearch(this.form, { propertyName: this.props.propertyName })
    )
  },
  // TODO
  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut: () => {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select: () => {
        target.select()
      },
      paste: () => {
        // Chrome does not allow retrieval of clipboard data
        // document.addEventListener('paste', function (evt) {
        //   const data = evt.clipboardData.getData('text')
        //   console.log('DATA', data)
        // })
        // target.focus()
        // document.execCommand('paste')
      },
      delete: () => {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        this.props.dispatch(
          openScreenAction({
            name: 'salesOrderEntry',
            title: 'Sales Order Entry',
            dataId: this.props.value
          })
        )
      }
    }
    return (action[data.type] || action.select)()
  },
  async exactMatchSearch(val) {
    try {
      const res = await this.props.dispatch(
        exactMatch(this.form, {
          propertyName: this.props.propertyName,
          keyword: val,
          moreInfo: true
        })
      )
      if (res) {
        this.setState(
          {
            value: res.recordName,
            description: res.description || '',
            isSet: true,
            isDirty: false,
            isOpen: false
          },
          () => {
            this.setField(res.recordName, true, res)
            this.blur(this.state.value)
          }
        )
      } else {
        this.setState({
          value: '',
          isSet: false,
          isDirty: false,
          isOpen: false
        })
      }
    } catch (e) {
      this.setState({
        value: '',
        isSet: false,
        isDirty: false,
        isOpen: false
      })
    }
  },

  focusField() {
    return (
      this.textField &&
      this.setState(
        { isFocused: true },
        () => ((this.autofocused = true), this.textField.focus())
      )
    )
  },
  async onChange(event) {
    event.persist()

    const v = event.target.value
    await this.setState({
      isDirty: true,
      value: v,
      // lastSearch: event.target.value,
      isSet: false
    })
    if (v.length >= 1) {
      this.setState({
        value: event.target.value
      })
    } else {
      this.clearSearch()
      this.setField('', true)
    }
  },

  onKeyDown(event) {
    event.persist()
    const val = event.target.value
    /* this is for New Event Type popover */
    if (this.props.disableBaseOnTabFunctionality && this.props.onKeyDown) {
      this.props.onKeyDown(event)
      return
    }

    // check if tooltip is open and keys match etc.. great :-(
    if (event.key === 'Tab') {
      if (this.state.isSet || !this.state.value) {
        return
      }
      event.preventDefault()
      // event.preventDefault()
      // event.target.blur()
      this.setState(
        {
          // isOpen: false,
          value: val,
          lastSearch: val
        },
        () => this.exactMatchSearch(val)
      )
    }
  },
  onRequestClose() {
    // console.log('request close', this.state, this.props)
    this.setState({ isOpen: false, mouseover: false }, () => {
      // this.onBlur()
      if (this.props.getFormState) {
        this.props.dispatch({
          type: CLOSE_SEARCH,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
      if (this.props.onRequestClose) {
        this.props.onRequestClose()
      }
      // console.log(this.state)
    })
  },
  async onSearchClick(event) {
    try {
      await this.props.dispatch(
        screenDropDownOpen({
          component: this.component,
          form: this.form,
          options: this.options
        })
      )
      this.setState({
        isOpen: true,
        dropDownProps: {
          onRowSelected: onRowSelected.bind(this),
          propertyName: this.props.propertyName,
          onRequestClose: this.onRequestClose,
          retainData: true
        }
      })
    } catch (e) {
      console.log(e)
    }
    // setField onRowSelected...
  }
  // onKeyPress = e => console.log(e.charCode, e.keyCode)
  // setField(val, clear, results) {
  //   const { clearFieldOnSet, leader, setField, onSetField } = this.props
  //   // debugger
  //   setField(val, clear, leader, results)
  //   if (onSetField) {
  //     onSetField(val, clear, leader, results)
  //   }
  //   if (clearFieldOnSet) {
  //     setField('', clear)
  //   }
  // }
}
