import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Popover, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import Grid from 'grid'
import {
  setTextFilterField as setTextFilterFieldAction,
  resetFilters as resetFiltersAction
} from '../../IndexSearch/actions'

// export default memo(

// const onCellValueChanged = (...args) => console.log(...args)
const onCellEditingStopped = (...args) => console.log(...args)

const Filters = props => {
  const {
    dispatch,
    textField,
    filtersGridIsOpen,
    asModal,
    filtersGrid,
    gridHeight,
    indexSearchType,
    meta = {},
    onClose,
    popoverStyle,
    onChange,
    // resetFilters,
    propertyName,
    buttonRef,
    form,

    setTextFilterField = setTextFilterFieldAction
    // setTextFilterField
  } = props

  const onCellValueChanged = ({ data }) => {
    const {
      description,
      fileAssociationSearchType: { parentField, value, dataId }
    } = data
    const filterType = dataId ? dataId.replace('.', '-') : description
    // const propertyName = `${parentField}.filters.${filterType}`
    // if (value != null)
    //   dispatch(
    //     setTextFilterField({
    //       parentField,
    //       value,
    //       form,
    //       filterType,
    //       propertyName
    //     })
    //   )
  }
  const resetFilters = () =>
    dispatch(resetFiltersAction(form, { propertyName }))
  // console.log(...args, setTextFilterFieldAction)
  const mapColumns = (...args) => {
    return {
      ...args[0],
      onCellValueChanged,
      singleClickEdit: true,
      onCellEditingStopped
    }
  }

  const [columnDefs, setColumnDefs] = useState(filtersGrid?.columnDefs ?? [])
  const [rowData, setRowData] = useState(filtersGrid?.rowData ?? [])
  const [api, setApi] = useState(null)
  useEffect(() => {
    if (filtersGrid?.columnDefs)
      setColumnDefs(filtersGrid.columnDefs.map(mapColumns))
    if (filtersGrid?.rowData) setRowData(filtersGrid?.rowData)
  }, [filtersGrid?.columnDefs, filtersGrid?.rowData])
  console.log(props)

  const onGridReady = (...args) => {
    console.log(...args)
  }
  console.log(columnDefs)
  return (
    <Popover
      anchorEl={textField}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}
      open={filtersGridIsOpen || false}
      style={asModal ? { zIndex: 2000 } : popoverStyle}
    >
      <div style={{ width: 500 }}>
        {filtersGrid && (
          <div>
            <Grid
              height={gridHeight || 300}
              columnDefs={columnDefs}
              meta={filtersGrid.meta}
              rowData={rowData}
              // indexSearchType={indexSearchType || meta.searchType}
              // searchType={indexSearchType || meta.searchType} // redundant?
              onChange={onChange}
              getRowNodeId={data => data.description}
              // autoSize
              // onCellEditingStopped={onCellEditingStopped}
              onGridReady={onGridReady}
            />
            <div className="text-center" style={{ padding: '10px 0' }}>
              <Button
                variant="contained"
                onClick={resetFilters}
                buttonRef={buttonRef}
                style={{ marginTop: 10 }}
              >
                Reset
              </Button>
            </div>
          </div>
        )}
      </div>
    </Popover>
  )
}
export default connect()(Filters)
