import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import invariant from 'invariant'
// import hoistStatics from 'hoist-non-react-statics'
// import { getDisplayName } from './index'
import { plainDeepEqual, getIn, shallowCompare, noop } from 'utils'
import uuid from 'uuid'
import { withDDIForm } from './DDIFormContext'
import ConnectedSelectField from './ConnectedSelectField'

const specialFields = ['SalesOrderSearch']

class SelectField extends Component {
  static propTypes = {
    disableRegister: PropTypes.bool,
    enablePropChange: PropTypes.bool,
    id: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    // required: PropTypes.bool,
    searchType: PropTypes.string,
    type: PropTypes.string
  }

  static defaultProps = {
    disableRegister: false,
    id: null,
    searchType: null,
    type: null,
    autoFocus: false,
    enablePropChange: false
    // required: false
  }

  constructor(...args) {
    super(...args)
    this.id = this.props.id || uuid.v1()
    // invariant(this.context.formState && this.context.propertyChange, 'This Field must be used inside of a ddiForm connected container.')
    const { disableRegister, propertyName, initialValue, meta } = this.props
    if (!disableRegister && propertyName) {
      this.props.registerField({
        propertyName,
        // searchType: this.props.searchType,
        // type: null,
        initialValue,
        meta
      })
    }
  }

  ref = React.createRef()

  render() {
    const { propertyName } = this.props
    // console.log(this.props)
    return (
      <ConnectedSelectField
        {...this.props}
        propertyName={propertyName}
        ref={this.ref}
        id={this.id}
      />
    )
  }
}

export default withDDIForm(SelectField)
