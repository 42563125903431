import { createReducer, noop, setIn } from 'utils'
import { fromJS, Map } from 'immutable'
import shortid from 'shortid'
import MailNotification from './components/MailNotification'
import Alert from './components/Alert'
import * as CONSTANTS from './constants'

const makeAlert = severity => props => Alert({ ...props, severity })
const typeMap = {
  mail: MailNotification,
  info: makeAlert('info'),
  success: makeAlert('success'),
  warning: makeAlert('warning'),
  error: makeAlert('error')
}
const initialData = fromJS({
  messages: [],
  onActionTouchTap: noop,
  onRequestClose: noop,
  open: false,
  style: {},
  batch: null,
  removed: Map(),
  added: Map()
})

const behaviors = {
  [CONSTANTS.SHOW]: (state, { payload: { message } }) => {
    // debugger
    let result = state

    if (!message?.component) {
      if (message?.type) {
        message.component = typeMap[message.type]
      }
    }

    message.id = message?.id ?? shortid.generate()

    result = setIn(
      result,
      'messages',
      result.get('messages').push(fromJS(message))
    )
    result = setIn(
      result,
      'added',
      fromJS({ id: message.id, type: message.type })
    )

    return result
  },
  [CONSTANTS.REMOVE]: (state, { payload: { id, type } }) => {
    let result = state
    let messages = result.get('messages')
    // debugger
    const idBy = 'id'
    const identifier = id

    messages = messages.filter(x => x.get(idBy) !== identifier)
    console.log(messages.toJS())
    result = setIn(result, 'messages', messages)
    // debugger
    if (id) {
      result = setIn(result, 'removed', fromJS({ id, type }))
    }
    return result
  }
}

export default createReducer(initialData, behaviors)
