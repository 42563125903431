import numeral from 'numeral'

/**
 * Safe conversion to numeral object. Numeral crashes
 * with the value is object or function.
 * @param {any} value - object to convert
 * @returns {Number|null} number or null
*/
export default function toNumeral(value) {
  const type = typeof value

  if (type === 'object' || type === 'function' || type === 'boolean') {
    return null
  }

  const n = numeral(value)

  // numeral.js converts empty strings into 0 for no reason, so if given
  // value was not '0' or 0, treat it as null.
  if (n.value() === 0 && (value !== 0 && value !== '0')) {
    return null
  }

  // numeral.js can sometimes convert values (like '4.5.2') into NaN
  // and we would rather null than NaN.
  if (isNaN(n.value())) {
    return null
  }

  return n
}
