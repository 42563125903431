import {
  take,
  call,
  put,
  cancel,
  fork,
  actionChannel
} from 'redux-saga/effects'

import { api } from 'services'
import { formatDateFields } from 'utils'
import {
  EXACT_MATCH_SEARCH,
  GET_TOOLTIP,
  PARTIAL_MATCH_SEARCH
} from './constants'

import GridCell from './GridCell'

export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(exactMatchProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* exactMatchProcess({
  form,
  keyword,
  customerId,
  propertyName,
  warehouseId,
  productId,
  uomId,
  thunk
}) {
  const params = {
    keyword,
    customerId,
    warehouseId,
    productId,
    uomId
  }

  const { response, error } = yield call(
    api.serialNumberExactMatchSearch,
    params
  )

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchProcess({
  form,
  thunk,
  keyword,
  onCellSelected,
  customerId,
  productId,
  propertyName,
  uomId,
  warehouseId
}) {
  const params = {
    keyword,
    customerId,
    productId,
    propertyName,
    uomId,
    warehouseId
  }

  const { response, error } = yield call(
    api.serialNumberPartialMatchSearch,
    params
  )
  // debugger

  if (response) {
    const columnDefs = [
      {
        cellRendererFramework: GridCell,
        cellRendererParams: { onCellSelected },

        field: 'number',
        headerName: 'Serial Number'
      },
      {
        editable: false,
        field: 'dateEntered',
        headerName: 'Date',
        valueFormatter: formatDateFields
      },
      {
        editable: false,
        field: 'repairProductNumber',
        headerName: 'Repair Product No'
      },
      {
        editable: false,
        field: 'repairManufacturer',
        headerName: 'Repair Manufacturer'
      },
      {
        editable: false,
        field: 'repairModel',
        headerName: 'Repair Model'
      }
    ]
    const rowData = response.results || []
    const grid = {
      meta: {},
      rowData,
      columnDefs
    }
    yield put({
      // meta: { form, propertyName },
      meta: { form, thunk },
      payload: {
        grid,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchSearchListener() {
  const channel = yield actionChannel(PARTIAL_MATCH_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)

    const {
      payload: {
        keyword,
        customerId,
        productId,
        propertyName,
        uomId,
        warehouseId,
        onCellSelected
      },
      meta: { form, thunk }
    } = action

    if (task) yield cancel(task)

    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield call(partialMatchProcess, {
      form,
      thunk,
      customerId,
      keyword,
      productId,
      propertyName,
      uomId,
      warehouseId,
      onCellSelected
    })
  }
}

export function* getTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_TOOLTIP.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getTooltipProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* getTooltipProcess({
  form,
  thunk,
  propertyName,
  apiName = 'getIndexSearchTooltip',
  ...rest
}) {
  const { response, error } = yield call(api[apiName], {
    propertyName,
    ...rest
  })

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: { ...response, propertyName },
      type: GET_TOOLTIP.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: { ...error, propertyName },
      error: true,
      type: GET_TOOLTIP.FAILURE
    })
  }
}

export default function* serialNumberSearchSagas() {
  yield fork(partialMatchSearchListener)
  yield fork(exactMatchSearchListener)
  yield fork(getTooltipListener)
}
