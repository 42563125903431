/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'

import {
  InputLabel,
  Icon,
  NativeSelect,
  FormControl,
  Button
} from '@material-ui/core'
// import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearchMobile'
import { mobileStateSelector } from 'mobile/selectors'
import IndexSearch from 'mobile/components/IndexSearchMobile'
import Grid from 'mobile/grid'

import {
  partialMatchSearch,
  exactMatchSearch
} from 'mobile/components/IndexSearchMobile/actions'
import { mapColumnHeaders } from 'utils'
import { mapSOInquiryHeaders } from 'pages/SalesOrderInquiry/behaviors'
import DatePicker from './components/DatePicker'
import { executeInquiry } from './actions'

const getRowNodeId = x => x.dataId
const types = [
  {
    value: 'O',
    description: 'Open'
  },
  {
    value: 'C',
    description: 'Completed'
  },
  {
    value: 'B',
    description: 'Backorders'
  },
  {
    value: 'Q',
    description: 'Quotes'
  },
  {
    value: 'E',
    description: 'Expired Quotes'
  },
  {
    value: 'R',
    description: 'Recurring'
  },
  {
    value: 'A',
    description: 'All'
  }
]

// const searchDisabled =
// (!customerId && !productId) ||
// fetching ||
// ((startDate && !endDate) || (endDate && !startDate | (startDate > endDate)))

const isSearchDisabled = memoize(
  (startDate, endDate, customerId, productId, fetching) => {
    if (fetching) {
      return true
    }

    if (startDate && endDate && startDate <= endDate) {
      return false
    }

    if (
      (startDate && !endDate) ||
      (endDate && !startDate | (startDate > endDate))
    ) {
      return true
    }

    if (!customerId && !productId) {
      return true
    }

    return false
  }
)

const DropDownComponent = props => {
  const searchRef = React.createRef()
  console.log(searchRef)
  const partialMatch = args =>
    props.dispatch(partialMatchSearch(null, { ...args }))
  const exactMatch = args => props.dispatch(exactMatchSearch(null, { ...args }))
  const [description, setDesc] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [productId, setProductId] = useState('')
  const [productDescription, setProductDescription] = useState('')

  const [orderType, setOrderType] = useState(types[0].value)

  const [startDate, startDateChange] = useState(null)
  const [endDate, endDateChange] = useState(null)

  const [showGrid, setShowGrid] = useState(false)
  const [height, setHeight] = useState(265)
  const [fetching, setFetching] = useState(false)
  const [columnDefs, setColumnDefs] = useState([])
  const [rowData, setRowData] = useState([])
  const [counter, setCounter] = useState(0)
  const setData = (data = {}) => {
    setDesc(data.description)
    setCustomerId(data.dataId)
  }

  const setProductData = (data = {}) => {
    setProductDescription(data?.description)
    setProductId(data?.dataId)
  }

  // const onRowSelected = params => {
  //   if (this.props.onRowSelected) {
  //     // debugger
  //     // this.props.onRowSelected.call(this, params)
  //     this.props.onRowSelected(params)
  //   }
  // }
  const changeOrderType = e => setOrderType(e.target.value)
  const changeDate = mod => date => mod(date)

  const inquiry = async () => {
    setFetching(true)
    try {
      const r = await props.dispatch(
        executeInquiry(props.form, {
          customerId,
          startDate,
          endDate,
          orderType,
          productId
        })
      )
      setFetching(false)
      if (r) {
        if (r?.salesOrders?.data?.length) {
          setColumnDefs(
            r.salesOrders.meta.columns
              .map(mapColumnHeaders)
              .map(mapSOInquiryHeaders)
              .map(x => {
                const { pinned, ...rest } = x
                return rest
              })
          )
          setRowData(r.salesOrders.data)
        }
        setCounter(r.salesOrders.data.length)

        const mobState = props?.mobileState?.toJS
          ? props.mobileState.toJS()
          : {}
        const gridWrapperHeight = mobState?.isTablet ? 700 : 520

        setHeight(gridWrapperHeight)

        setShowGrid(true)
      }
    } catch (e) {
      setFetching(false)
      setShowGrid(false)
    }
    // else {
    //   setFetching(false)
    //   setShowGrid(false)
    // }
  }
  console.log(columnDefs, rowData)
  const changeEnd = changeDate(endDateChange)
  const changeStart = changeDate(startDateChange)

  const mobileState = props?.mobileState?.toJS ? props.mobileState.toJS() : {}
  const gridHeight = mobileState?.isTablet ? 400 : 225
  const inputPadding = mobileState?.isTablet ? 20 : 0

  /*
  const searchDisabled =
    (!customerId && !productId) ||
    fetching ||
    ((startDate && !endDate) || (endDate && !startDate | (startDate > endDate)))
  */

  const searchDisabled = isSearchDisabled(
    startDate,
    endDate,
    customerId,
    productId,
    fetching
  )

  return (
    <div
      style={{
        height,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden'
      }}
    >
      <div style={{ padding: '0 10px 10px 10px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingLeft: inputPadding,
            paddingRight: inputPadding
          }}
        >
          <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
            <IndexSearch
              label="Customer"
              placeholder="Select Customer"
              indexSearchType="customer"
              // label="Customer"
              setField={(...args) => {
                setData(args[3])
              }}
              disableLastSearchButton
              fullWidth
              preventAutoDisable
              displayDescription
              exactMatchSearch={exactMatch}
              partialMatchSearch={partialMatch}
              moreInfo
              forwardRef
              description={description}
              value={customerId}
            />
          </div>
          <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
            <DatePicker
              propertyName="startDate"
              placeholder="Start Date"
              label="Start Date"
              dialogContainerStyle={{ zIndex: 5000 }}
              inputStyle={{ marginTop: 5 }}
              wrapperStyle={null}
              initialValue={new Date()}
              onChange={changeStart}
              value={startDate}
              // meta={indexSearchFields.customer}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingLeft: inputPadding,
            paddingRight: inputPadding
          }}
        >
          <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
            <FormControl>
              <InputLabel
                style={{
                  color: '#517b9c',
                  fontSize: 12,
                  margin: 0
                }}
                htmlFor="order-type"
              >
                Type
              </InputLabel>
              <NativeSelect
                native
                // value={types[0].value}
                value={orderType}
                onChange={changeOrderType}
                inputProps={{
                  name: 'order-type',
                  id: 'order-type'
                }}
              >
                {types.map(x => (
                  <option value={x.value}>{x.description}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
            <DatePicker
              propertyName="endDate"
              placeholder="End Date"
              label="End Date"
              dialogContainerStyle={{ zIndex: 5000 }}
              inputStyle={{ marginTop: 5 }}
              wrapperStyle={null}
              // initialValue={null}
              value={endDate}
              onChange={changeEnd}
              // meta={indexSearchFields.customer}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingLeft: inputPadding,
            paddingRight: inputPadding
          }}
        >
          <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
            <IndexSearch
              label="Product"
              placeholder="Select Product"
              indexSearchType="product"
              setField={(...args) => {
                setProductData(args[3])
              }}
              disableLastSearchButton
              fullWidth
              preventAutoDisable
              displayDescription
              exactMatchSearch={exactMatch}
              partialMatchSearch={partialMatch}
              moreInfo
              forwardRef
              description={productDescription}
              value={productId}
            />
          </div>
        </div>

        <div />
      </div>
      {showGrid ? (
        <div id="mobile-sales-order-search" style={{ padding: '0 10px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10px'
            }}
          >
            <h4 style={{ color: '#404040', fontSize: 14 }}>Search Results</h4>
            {counter ? (
              <div style={{ color: 'rgb(81, 123, 156)', fontSize: 11 }}>
                showing results 1-{counter} (tap to select)
              </div>
            ) : null}
          </div>
          <Grid
            height={gridHeight}
            rowData={rowData}
            columnDefs={columnDefs}
            getRowNodeId={getRowNodeId}
            onRowSelected={props.onRowSelected}
            rowSelection="single"
            rowDeselection
            autoSize
          />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 10
        }}
      >
        <Button
          variant="contained"
          size="large"
          startIcon={<Icon>search</Icon>}
          disabled={searchDisabled}
          // onCLick={}
          onClick={inquiry}
        >
          Search Orders
        </Button>
      </div>
    </div>
  )
}

const mapState = state => ({ mobileState: mobileStateSelector(state) })
export default connect(mapState)(DropDownComponent)
