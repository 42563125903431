import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const saveScreenOpenData = (form, { screenOpenData }) => {
  console.log('saveScreenOpenData', form)
  return createAction(CONSTANTS.SAVE_SCREEN_OPEN_DATA, { screenOpenData }, form)
}

export const updateScreenOpenData = (form, { screenOpenData }) =>
  createAction(CONSTANTS.UPDATE_SCREEN_OPEN_DATA, { screenOpenData }, form)

// export const executeInquiry = createAsyncFormAction(CONSTANTS.EXECUTE_INQUIRY)
export const executeInquiry = form => ({
  meta: { form },
  type: CONSTANTS.EXECUTE_INQUIRY.TRY
})
export const initExecuteInquiry = form => ({
  meta: { form },
  type: CONSTANTS.INIT_EXECUTE_INQUIRY
})

export const exitDashboardScreen = form => ({
  meta: { form },
  type: CONSTANTS.EXIT_DASHBOARD_SCREEN
})

export const changeInventoryFilter = (
  form,
  { filterType, gridName, selectedRows }
) => ({
  meta: { form },
  payload: {
    filterType,
    gridName,
    selectedRows
  },
  type: CONSTANTS.CHANGE_INVENTORY_FILTER.TRY
})

// export const changeFilter = (form, { filterType, gridName, selectedRows }) => (
//   {
//     meta: { form },
//     payload: { filterType, gridName, selectedRows },
//     type: CONSTANTS.CHANGE_FILTER.TRY
//   }
// )

// export const toggleGridSelection = createAsyncFormAction(CONSTANTS.TOGGLE_GRID_SELECTION)
export const toggleGridSelection = (
  form,
  { filterType, gridName, selectedRows }
) =>
  createAction(
    CONSTANTS.TOGGLE_GRID_SELECTION,
    {
      filterType,
      gridName,
      selectedRows
    },
    form
  )

/* for the report generation features */
export const initReportGraphicDataProcess = (
  form,
  { reportGraphicId, reportGraphicType }
) =>
  createAction(
    CONSTANTS.INIT_REPORT_GRAPHIC_DATA_PROCESS,
    { reportGraphicId, reportGraphicType },
    form
  )

export const saveReportGraphicData = (form, { data }) =>
  createAction(CONSTANTS.SAVE_REPORT_GRAPHIC_DATA, { data }, form)

export const openDashboardReport = (form, imageData) => ({
  meta: { form },
  payload: { imageData },
  type: CONSTANTS.OPEN_DASHBOARD_REPORT.TRY
})

export const closeDashboardReport = (form, { id }) => ({
  meta: { form },
  payload: { id },
  type: CONSTANTS.CLOSE_DASHBOARD_REPORT.TRY
})

export const openScreenFromDashboard = createAsyncFormAction(CONSTANTS.OPEN_SCREEN_FROM_DASHBOARD)