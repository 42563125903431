import { createAsyncRequestTypes } from 'utils'

export const ATTACHMENTS = '@@ATTACHMENTS/'

export const UPLOAD_ATTACHMENTS = createAsyncRequestTypes(
  `${ATTACHMENTS}UPLOAD_ATTACHMENTS`
)

export const DELETE_ATTACHMENT = createAsyncRequestTypes(
  `${ATTACHMENTS}DELETE_ATTACHMENT`
)

export const DOWNLOAD_ATTACHMENT = createAsyncRequestTypes(
  `${ATTACHMENTS}DOWNLOAD_ATTACHMENT`
)

export const GET_ATTACHMENTS = createAsyncRequestTypes(
  `${ATTACHMENTS}GET_ATTACHMENTS`
)
