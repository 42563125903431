import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Paper } from '@material-ui/core'


export const LoginSkeleton = () => (
  <Paper style={{ margin: 20, padding: 20 }}>
    <div style={{ padding: 20 }}>
      <div style={{ maxWidth: 300, margin: '0 auto 15px' }}>
        <Skeleton animation="wave" variant="rect" width="100%" height="100" />
      </div>

      <div style={{ padding: 10 }}>
        <Skeleton animation="wave" variant="rect" width="100%" height="25" />
      </div>
      <div style={{ padding: 10 }}>
        <Skeleton animation="wave" variant="rect" width="100%" height="25" />
      </div>
      <div style={{ padding: 10, margin: '0 auto 15px', maxWidth: 150 }}>
        <Skeleton animation="wave" variant="rect" width="100%" height="50" />
      </div>

    </div>

  </Paper>
)


export default LoginSkeleton