/* eslint import/prefer-default-export: 0 */

export const readFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    // Read the file data via FileReader API
    reader.onload = function readData(e) {
      const dataURL = e.target.result || ''
      const content = dataURL.split(',')[1]
      resolve({ file, dataURL, content })
    }

    reader.readAsDataURL(file)
  })

export const mimeTypes = {
  bmp: 'image/bmp',
  csv: 'text/csv',
  doc: 'application/msword',
  docx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',

  odt: 'application/vnd.oasis.opendocument.text',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odp: 'application/vnd.oasis.opendocument.presentation',
  odg: 'application/vnd.oasis.opendocument.graphics',
  odc: 'application/vnd.oasis.opendocument.chart',
  odf: 'application/vnd.oasis.opendocument.formula',
  odm: 'application/vnd.oasis.opendocument.text-master',

  ppt: 'application/vnd.ms-powerpoint',
  pptx:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',

  pdf: 'application/pdf',
  png: 'image/png',
  rtf: 'application/rtf',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip'
}

export const targetTypes = ['invoice', 'order', 'payment']

export const handleAdditionalAttachmentsDataCallback = (
  additionalAttachmentsData,
  response
) => {
  if (
    additionalAttachmentsData?.handleAttachmentsCallback &&
    typeof additionalAttachmentsData.handleAttachmentsCallback === 'function'
  ) {
    additionalAttachmentsData.handleAttachmentsCallback(response.attachments)
  }
}

export const buildLedgerApiParams = (
  targetType,
  apiParams = {},
  modalData = {}
) => {
  if (!targetType) {
    return apiParams
  }

  if (targetType === 'invoice') {
    apiParams = {
      ...apiParams,
      targetType,
      uniqueKey: modalData?.uniqueKey,
      invoiceType: modalData?.invoiceType,
      invoiceYear: modalData?.invoiceYear,
      shipToId: modalData?.shipToId
    }
  } else if (targetType === 'payment') {
    apiParams = {
      ...apiParams,
      targetType,
      uniqueKey: modalData?.uniqueKey,
      shipToId: modalData?.shipToId,
      paymentHistoryYear: modalData?.paymentHistoryYear
    }
  } else if (targetType === 'order') {
    apiParams = {
      ...apiParams,
      targetType,
      uniqueKey: modalData?.uniqueKey,
      orderType: modalData?.orderType
    }
  }

  return apiParams
}
