/* eslint no-else-return: 0 */
import React from 'react'
import { numToDollar, is } from 'utils'

const PinnedRowRenderer = ({ colDef, value }) => {
  const { field = '' } = colDef
  const lineItemsDataCols = [
    'quantityOrdered',
    'quantityShipped',
    'quantityBO',
    'quantityCommitted'
  ]

  if (field === 'dataId') {
    return (
      <span>
        <strong>Totals:</strong>
      </span>
    )
  } else if (field === 'total') {
    return (
      <span>
        <strong>{numToDollar(value)}</strong>
      </span>
    )
  } else if (field && lineItemsDataCols.includes(field)) {
    return (
      <span>
        <strong>{is.number(value) ? value.toLocaleString() : value}</strong>
      </span>
    )
  } else if (typeof value === 'number') {
    return (
      <span>
        <strong>{value}</strong>
      </span>
    )
  }

  return ''
}

export default PinnedRowRenderer
