import React from 'react'
import TooltipTemplate from 'components/BaseTooltipTemplate'
import behaviors from './behaviors'
// import TooltipTemplate from './TooltipTemplate'
// import ContextMenu from './ContextMenu'
import { getTooltip } from '../IndexSearch/actions'
import createSearch from '../createSearch'

const ARInvoiceSearch = createSearch({
  name: 'ARInvoiceSearch',
  behaviors,
  popoverStyle: {
    width: 700
  }
})

export default ARInvoiceSearch
