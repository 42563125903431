/**
 * returns the name of a wrapped component
 * @param {object} WrappedComponent - component
 * @returns {string} name of the component
 */
export default function getDisplayName(WrappedComponent) {
  return WrappedComponent
    ? WrappedComponent.displayName || WrappedComponent.name || 'Component'
    : 'Component'
}
