import { createSelector } from 'reselect'
import { getIn, getRgb } from 'utils'
// import { calSelector } from 'pages/Dashboard/selectors'

// export const eventModalSelector = createSelector(calSelector, calendar => getIn(calendar, 'EventModal'))
export const ddiFormSelector = state => state.get('ddiForm')

export const eventModalSelector = createSelector(ddiFormSelector, ddiForm =>
  ddiForm.get('eventModal')
)

// export const eventModalSelector = createSelector(state => getIn(state, 'ddiForm.eventModal'))

// export const activitySelector = createSelector(
//   eventModalSelector,
//   eventModal => {
//     console.log(eventModal)
//     return getIn(eventModal, 'eventModal')
//   }
// )

export const eventTypeOptionsSelector = createSelector(
  eventModalSelector,
  eventModal => getIn(eventModal, 'eventTypeOptions')
)
export const participantsSelector = createSelector(
  eventModalSelector,
  eventModal => getIn(eventModal, 'participants')
)
export const referenceOptionsSelector = createSelector(
  eventModalSelector,
  eventModal => getIn(eventModal, 'referenceOptions')
)
export const reminderIntervalOptionsSelector = createSelector(
  eventModalSelector,
  eventModal => getIn(eventModal, 'reminderIntervalOptions')
)
export const taskTypeOptionsSelector = createSelector(
  eventModalSelector,
  eventModal => getIn(eventModal, 'taskTypeOptions')
)

// export const dateDefaultsSelector = createSelector(eventModalSelector, eventModal => getIn(eventModal, 'dateDefaults'))

/* these selectors all deal with the event type editor only */
export const eventTypeEditorSelector = createSelector(
  eventModalSelector,
  eventModal => getIn(eventModal, 'eventTypeEditor')
)

export const openModalSelector = createSelector(
  eventTypeEditorSelector,
  eventTypeEditor => getIn(eventTypeEditor, 'open')
)

export const colorOptionsSelector = createSelector(
  eventTypeEditorSelector,
  eventTypeEditor => getIn(eventTypeEditor, 'meta.colorOptions')
)

export const eventTypeEditorEventTypesSelector = createSelector(
  eventTypeEditorSelector,
  eventTypeEditor => getIn(eventTypeEditor, 'eventTypes')
)
export const eventTypeEditorGridVisibleSelector = createSelector(
  eventTypeEditorSelector,
  eventTypeEditor => getIn(eventTypeEditor, 'gridVisible')
)
export const selectedEventSelector = createSelector(
  eventTypeEditorSelector,
  eventTypeEditor => getIn(eventTypeEditor, 'selectedEvent')
)
export const eventTypeEditorOpenSelector = createSelector(
  eventTypeEditorSelector,
  eventTypeEditor => getIn(eventTypeEditor, 'open')
)
export const eventTypeLockedSelector = createSelector(
  eventTypeEditorSelector,
  eventTypeEditor => getIn(eventTypeEditor, 'eventTypeLocked')
)
