import moment from 'moment'

/**
 * converts a string to a formatted date
 * @param {string} dateStr - date string
 * @param {string} format - date format
 * @returns {string} correctly formatted date string
*/
const formatDate = (dateStr, format = 'MM/DD/YYYY, h:mm:ss a') => {
  if (!dateStr) return null
  return moment(dateStr).format(format)
}

export default formatDate
