import React, { forwardRef } from 'react'
import { connect } from 'react-redux'
import { remove as removeAction } from 'snackbar/actions'
// import { openMail } from 'pages/UserMail/actions'
// import PropTypes from 'prop-types'
/* eslint-disable react/prefer-stateless-function */
import { Icon, ButtonBase } from '@material-ui/core'
import { linkToScreen } from 'pages/Main/actions'

export function shorten(text, maxLength = 200) {
  if (text.length > maxLength) {
    text = `${text.substr(0, maxLength - 3)}...`
  }
  return text
}

export const MailNotification = forwardRef((props, ref) => {
  const {
    referenceId,
    referenceType,
    details,
    description,
    linkToScreen,
    open,
    remove
  } = props
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: 300
      }}
      ref={ref}
    >
      <div
        style={{
          flex: 0.15,
          width: 50,
          flexDirection: 'column'
        }}
      >
        <Icon style={{ marginBottom: 10 }}>mail</Icon>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ButtonBase
            disabled={!referenceId && !referenceType}
            onClick={linkToScreen}
          >
            <Icon
              style={{
                marginBottom: 10,
                fontSize: 15,
                marginRight: 2
              }}
            >
              link
            </Icon>
          </ButtonBase>
          <ButtonBase onClick={open}>
            <Icon style={{ marginBottom: 10, fontSize: 15 }}>reply</Icon>
          </ButtonBase>
        </div>
      </div>
      <div
        style={{
          flex: 0.85,
          marginLeft: 5,
          paddingTop: 2,
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            marginBottom: 5,
            fontWeight: 400,
            fontSize: 18,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div>{shorten(description, 30)}</div>
          <ButtonBase style={{ alignSelf: 'flex-start' }} onClick={remove}>
            <Icon style={{ fontSize: 15 }}>close</Icon>
          </ButtonBase>
        </div>
        <div style={{ textAlign: 'justify', marginTop: 10 }}>
          {shorten(details)}
        </div>
      </div>
    </div>
  )
})
export const mapDispatchToProps = (dispatch, ownProps) => ({
  linkToScreen: () =>
    dispatch(
      linkToScreen(null, {
        referenceId: ownProps.data.referenceId,
        referenceType: ownProps.data.referenceTypes
      })
    ),
  open: () => {
    // const {
    //   dataId,
    //   mailNumber,
    //   guId,
    //   userName,
    //   referenceId,
    //   referenceType
    // } = ownProps
    // BROKEN need to fix this when I have time
    // dispatch(
    //   openMail.try(null, {
    //     dataId,
    //     mailNumber,
    //     guId,
    //     userName,
    //     referenceId,
    //     referenceType
    //   })
    // )
    dispatch(removeAction({ id: ownProps.mailNumber }))
    // dispatch(ownProps.remove())
  }
})
export default connect(
  null,
  mapDispatchToProps
)(MailNotification)
