import React, { createElement, Component } from 'react'
import DDIButton from 'ddiForm/Button'
import invariant from 'invariant'
import { isValidElementType } from 'react-is'
import { Paper } from '@material-ui/core'
import memoize from 'memoize-one'
import { getIn, isComponent } from 'utils'
/* eslint-disable react/no-did-mount-set-state, no-nested-ternary */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '5px 5px 0 5px',
    background: 'rgb(206, 206, 206)',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4
  },
  left: {
    display: 'flex',
    flex: 1,
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: 5
  },
  marginRight: { marginRight: 5 },
  right: {
    display: 'flex',
    flex: 1,
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 5
  },
  inner: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%'
  }
}
const getFooter = dir => props => {
  const {
    masterOptions: { footer }
  } = props
  invariant(footer, 'Must provide a footer property')
  const direction = footer && footer[dir]

  return footer && direction && Array.isArray(direction)
    ? direction.map(
      (comp, idx, arr) =>
        idx === arr.length - 1 ? (
          comp
        ) : (
          <div style={{ marginRight: 5 }}>{comp}</div>
        )
    )
    : // typeof direction === 'function'
    isValidElementType(direction)
      ? createElement(direction, props)
      : // : direction(props)
      null
}
const getLeft = memoize(getFooter('left'))
const getRight = memoize(getFooter('right'))

class Footer extends Component {
  getLeft = memoize(getLeft)

  getRight = memoize(getRight)

  disabledReport = (field, formState) => {
    const isEditing = getIn(formState, 'isEditing')
    const isPosting = getIn(formState, 'isPosting')
    if (isEditing || isPosting) {
      return true
    }

    return false
  }

  render() {
    const { props } = this
    return (
      <Paper
        style={{
          ...styles.container,
          ...props.style,
        }}
      >
        <div
          className="master-page-footer-inner-wrapper"
          style={styles.inner}
        >
          <div style={styles.left}>
            {this.props.masterOptions &&
              !this.props.masterOptions.hideReportButton && (
              <DDIButton
                variant="contained"
                label="Report"
                actionName="getReport"
                bindTo="onClick"
                tabIndex={0}
                style={styles.marginRight}
                disabled={this.disabledReport}
              />
            )}
            {getLeft(this.props)}
          </div>
          <div style={styles.right}>{getRight(this.props)}</div>
        </div>
      </Paper>
    )
  }
}
export default Footer
