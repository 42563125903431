import { fromJS, Map } from 'immutable'
import {
  createReducer,
  deleteIn,
  getIn,
  setIn /* size, splice, empty */
} from 'utils'
import * as AUTH from 'auth/constants'
import { eventModal as EVENT_MODAL } from 'modals/EventModal/constants'
import eventModalReducer from 'modals/EventModal/reducer'
import * as CONSTANTS from './constants'
import { calendar as CALENDAR } from './components/Calendar/constants'
import calendarReducer from './components/Calendar/reducer'
import { LAYOUT_CLEARED } from '../Layout/constants'

const enhancer = reducer => {
  let newState
  let slice
  return (state, action) => {
    const { type } = action
    newState = state
    const isInitializationCall = newState === undefined
    if (~type.indexOf(CALENDAR) && !isInitializationCall) {
      slice = getIn(newState, 'tiles.Calendar')
      newState = setIn(
        newState,
        'tiles.Calendar',
        calendarReducer(slice, action)
      )
      // return newState
    }
    return reducer(newState, action)
  }
}

const initialState = fromJS({
  categories: {},
  categoryIds: [],
  categoryTiles: {},
  checked: false,
  layouts: null,
  tileIds: {},
  tiles: {}
})

const behaviors = {
  [CONSTANTS.INIT]: (state, { payload: { dashboards, layouts } }) => {
    let result = state
    // dashboards = fromJS(dashboards)

    result = setIn(
      result,
      'categories',
      fromJS(
        dashboards.reduce((acc, next) => {
          acc[next.id] = {
            description: next.description,
            id: next.id
          }
          return acc
        }, {})
      )
    )

    result = setIn(result, 'categoryIds', getIn(result, 'categories').keySeq())

    result = setIn(
      result,
      'tiles',
      fromJS(
        dashboards
          .reduce((acc, next) => acc.concat(next.items), [])
          .reduce((acc, next) => {
            acc[String(next.id)] = next
            return acc
          }, {})
      )
    )

    result = setIn(
      result,
      'tileIds',
      Object.keys(getIn(result, 'tiles').toJS())
    )
    result = setIn(
      result,
      'categoryTiles',
      getIn(result, 'categoryIds').reduce((acc, next) => {
        const ids = Object.keys(
          getIn(result, 'tiles')
            .filter(x => x.get('category') === next)
            .toJS()
        )
        acc = acc.set(next, fromJS(ids))
        return acc
      }, new Map())
    )

    result = setIn(result, 'layouts', layouts ? fromJS(layouts) : null)
    return result
  },

  [CONSTANTS.ADD_TILE.SUCCESS]: (
    state,
    {
      payload: {
        dashboard: { id },
        dashboard
      }
    }
  ) => {
    let result = state
    let tile = getIn(result, `tiles.${id}`)
    tile = tile.mergeDeep(fromJS(dashboard))
    result = deleteIn(result, `tiles.${id}`)
    result = result.setIn(['tiles', id], tile) // bug occurs with % characters in setIn method...
    return result
  },
  [CONSTANTS.ADD_TILE.FAILURE]: (state, { payload }) => {
    const result = state.toJS()
    console.log(result, payload)
    // const id = getIn(result, 'lastToggled')

    // let tile = getIn(result, `tiles.${id}`)
    // console.log(tile)
    // tile = setIn(tile, 'isSelected', false)
    // result = result.setIn(['tiles', id], tile)
    // result = deleteIn(result, 'lastToggled')
    return state
  },

  [CONSTANTS.REMOVE_TILE.SUCCESS]: (
    state,
    {
      payload: {
        dashboard: { id },
        dashboard
      }
    }
  ) => {
    let result = state.toJS()

    let tile = result.tiles[id]
    tile = {
      ...tile,
      isSelected: false,
      tile: null
    }
    result = {
      ...result,
      tiles: {
        ...result.tiles,
        [id]: tile
      }
    }
    return fromJS(result)
  },
  [CONSTANTS.REMOVE_TILE.FAILURE]: (
    state,
    {
      payload: {
        dashboard: { id },
        dashboard = {},
        status
      }
    }
  ) => {
    let result = state.toJS()
    if (status && status === 496) return initialState
    let tile = result.tiles[id]
    tile = {
      ...tile,
      isSelected: false,
      tile: null
    }
    result = {
      ...result,
      tiles: {
        ...result.tiles,
        [id]: tile
      }
    }
    return fromJS(result)
  },
  [CONSTANTS.STORE_LAYOUTS]: (state, { payload: { layouts } }) => {
    let result = state
    // result = setIn(result, 'layouts', fromJS(payload.layouts))
    if (layouts) {
      result = setIn(result, 'layouts', fromJS(layouts))
    }
    return result
  },
  [CONSTANTS.TOGGLE_CHECK]: (state, { payload }) => {
    let result = state
    const isChecked = getIn(state, 'checked')
    result = setIn(result, 'checked', !isChecked)
    return result
  },
  [LAYOUT_CLEARED]: () => initialState
}

export default enhancer(createReducer(initialState, behaviors))
