const layoutFlex = (
  flexBasis = '120rem',
  flexDirection = 'row',
  flexWrap = 'wrap',
  justifyContent = null
) => ({
  display: 'flex',
  flex: `0.1 1 ${flexBasis}`,
  flexDirection,
  flexWrap,
  maxWidth: '100%',
  margin: '0 auto',
  justifyContent
})

export default layoutFlex
