import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import MobileScreen from 'mobile/components/MobileScreen'

import SearchFields from './SearchFields'

const OrderPad = () => {
  return (
    <MobileScreen
      title="Fast Product"
      titleIcon="library_add"
      speedDialActions={[]}
      CollapsableSearchComponent={SearchFields}
    >
      <Skeleton animation="wave" variant="rect" width="100%" height="250" />
      <Skeleton animation="wave" variant="rect" width="100%" height="250" />
    </MobileScreen>
  )
}

export default OrderPad
