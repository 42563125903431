import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const USER_MAIL = '@@USER_MAIL@@/'
export const USER_MAIL_URL = `${BASE_INFORM_API_URL}/usermail/`
export const DELETE_MAIL = createAsyncRequestTypes(`${USER_MAIL}DELETE_MAIL`)
export const OPEN_MAIL = createAsyncRequestTypes(`${USER_MAIL}OPEN_MAIL`)
export const NEW_MAIL = createAsyncRequestTypes(`${USER_MAIL}NEW_MAIL`)
export const RESPOND_MAIL = createAsyncRequestTypes(`${USER_MAIL}RESPOND_MAIL`)
export const EDIT_MAIL = createAsyncRequestTypes(`${USER_MAIL}EDIT_MAIL`)
export const CLOSE_MAIL = `${USER_MAIL}CLOSE_MAIL`
export const CREATE_USER_MAIL = createAsyncRequestTypes(
  `${USER_MAIL}CREATE_USER_MAIL`
)
export const REFRESH_OPEN_MAIL = createAsyncRequestTypes(
  `${USER_MAIL}REFRESH_OPEN_MAIL`
)
export const SYNC_MAIL = createAsyncRequestTypes(`${USER_MAIL}SYNC_MAIL`)

export const PRINT_USER_MAIL = createAsyncRequestTypes(
  `${USER_MAIL}PRINT_USER_MAIL`
)

export const EXIT_USER_MAIL_SCREEN = createAsyncRequestTypes(
  `${USER_MAIL}EXIT_USER_MAIL_SCREEN`
)

export const GET_EMAIL_FAX_LOG = createAsyncRequestTypes(
  `${USER_MAIL}GET_EMAIL_FAX_LOG`
)

export const RESEND_EMAIL_FAX_ITEM = createAsyncRequestTypes(
  `${USER_MAIL}RESEND_EMAIL_FAX_ITEM`
)

export const DELETE_EMAIL_FAX_ITEM = createAsyncRequestTypes(
  `${USER_MAIL}DELETE_EMAIL_FAX_ITEM`
)

// export const REMOVE_DELETED_EMAILS = `${USER_MAIL}REMOVE_DELETED_EMAILS`
