/* eslint-disable sort-keys */
// import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import { ddiFormStyles } from '../ddiForm/ddiFormStyles'
import {
  informBlue,
  ddiBlue,
  turquoise,
  flatGrey,
  mediumGrey,
  darkGrey,
  errorRed,
  successGreen,
  // disabledFieldColor,
  disabledButtonColor,
  altTableRowColor,
  selectedTableRowColor,
  fullBlack,
  white
} from './colors'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: turquoise,
      main: turquoise,
      dark: ddiBlue,
      constrastText: white
    },
    secondary: {
      light: '#00ff00',
      main: turquoise,
      dark: ddiBlue,
      contrastText: white
    },
    text: {
      disabled: '#737373'
    }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#c1d5e5'
      },
      // elevation4: {
      //   boxShadow: 'none'
      // },
      root: {
        height: 35,
        minHeight: 35
      }
    },
    MuiButton: {
      root: {
        fontSize: '1em',
        lineHeight: '1em'
      },
      sizeLarge: {
        fontSize: '1em',
        lineHeight: '1em',
        minWidth: 75,
        padding: '8px 15px',
        '@media (max-width: 480px)': {
          fontSize: '0.75em',
          lineHeight: '1em',
          minWidth: 50,
          padding: '8px 10px'
        }
      },
      contained: {
        backgroundColor: informBlue,
        color: white,
        '&:hover': {
          backgroundColor: '#3a586f'
        }
      },
      containedPrimary: {
        backgroundColor: informBlue,
        color: white
      },
      containedSecondary: {
        backgroundColor: '#e1e3e4',
        color: darkGrey,
        '&:hover': {
          backgroundColor: '#d4d7d8'
        }
      },
      textSecondary: {
        color: informBlue,
        '&:hover': {
          backgroundColor: 'rgba(81, 123, 156, 0.08)'
        }
      },
      sizeSmall: {
        fontSize: '1rem',
        minHeight: 24
      }
    },
    MuiInputBase: {
      /* fixes a minor issue with custom input components */
      inputType: {
        height: 'auto'
      }
    },
    MuiInputLabel: {
      shrink: {
        margin: 0,
        transform: 'translate(0, 7px) scale(0.75)'
      }
    },
    MuiList: {
      padding: {
        paddingBottom: 0,
        paddingTop: 0
      }
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: informBlue,
          transform: 'none'
        }
      }
    },
    MuiPopover: {
      /* this fixes IndexSearch popover square bug */
      paper: {
        minHeight: 0,
        minWidth: 0
      }
    },
    MuiSelect: {
      selectMenu: {
        minHeight: '1em'
      }
    },
    MuiSwitchBase: {
      root: {
        '&$checked': {
          color: informBlue
        }
      }
    },
    MuiInput: {
      input: {
        fontSize: '1em'
      }
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
        position: 'relative',
        left: 3
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.85em'
      }
    },
    MuiRadio: {
      root: {
        '&$checked': {
          color: informBlue
        }
      }
    },
    MuiTabIndicator: {
      root: {
        height: 4,
        backgroundColor: turquoise
      },
      colorPrimary: {
        backgroundColor: turquoise,
        height: 4
      },
      colorSecondary: {
        backgroundColor: turquoise,
        height: 4
      }
    },
    MuiPrivateTabsScrollButton: {
      root: {
        height: 30,
        maxHeight: 30,
        '&:empty': {
          display: 'none !important'
        },
        '@media (max-width: 599.95px)': {
          display: 'block !important'
        }
      }
    },
    MuiTabsScrollButtons: {
      root: {
        backgroundColor: '#c2d6e5',
        // maxHeight: 35,
        maxHeight: '100%',
        '&:empty': {
          display: 'none !important'
        },
        '@media (max-width: 599.95px)': {
          display: 'block !important'
        }
      }
    },
    MuiFormControl: {
      fullWidth: {
        maxWidth: '100%'
      },
      root: {
        display: 'flex',
        minWidth: 100
        // base style of 'inline-flex' causes weird rendering bugs
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1em',
        lineHeight: '1em'
      },
      root: {
        fontSize: '0.875em',
        lineHeight: '1em'
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: 'transparent'
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: 20
      }
    },
    MuiTab: {
      textColorInherit: {
        backgroundColor: '#dbe5ec',
        color: darkGrey
      },
      root: {
        paddingLeft: 10,
        paddingRight: 10,
        // height: 35,
        minHeight: 35,
        fontSize: '0.85em',
        fontWeight: 400,
        lineHeight: '0.85rem',
        marginRight: 2,
        textTransform: 'none',
        '@media (min-width: 960px)': {
          fontSize: '0.85em',
          minWidth: 80
        },
        '&$selected': {
          backgroundColor: informBlue,
          color: white
        }
      },
      label: {
        fontSize: 'inherit',
        textTransform: 'none'
        // '@media (min-width: 960px)': {
        //   fontSize: 'inherit',
        //   textTransform: 'none'
        // }
      },
      labelContainer: {
        paddingTop: 0,
        verticalAlign: 'middle',
        paddingBottom: 'inherit',
        paddingLeft: 10,
        paddingRight: 10,
        // '@media (min-width: 960px)': {
        //   paddingTop: 0,
        //   paddingBottom: 'inherit',
        //   paddingLeft: 10,
        //   paddingRight: 10
        // },
        margin: 0
      },
      labelIcon: {
        height: 35,
        maxHeight: 35,
        minHeight: 35,
        paddingTop: 0,
        paddingBottom: 0
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 0,
        paddingTop: 2
      }
    },
    MuiTabs: {
      indicator: {
        height: 4
      },
      root: {
        height: 35,
        minHeight: 35
      },
      scrollButtons: {
        backgroundColor: '#c2d6e5',
        // maxHeight: 35,
        maxHeight: '100%',
        '&:empty': {
          display: 'none !important'
        }
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '0.75em',
        padding: 10,
        '&:last-child': {
          padding: 10,
          paddingRight: 10
        }
      },
      head: {
        fontSize: '0.75em'
      },
      body: {
        fontSize: '0.75em'
      }
    },
    MuiTableRow: {
      root: {
        height: 24,
        '&:nth-child(odd)': {
          backgroundColor: '#f6f6f6'
        },
        '&$selected': {
          backgroundColor: '#d5edd5',
          '&:nth-child(odd)': {
            backgroundColor: '#d5edd5'
          }
        }
      }
    },
    MuiToolbar: {
      root: {
        minHeight: 0,
        '@media (min-width: 600px)': {
          minHeight: 0
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 13
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '1em',
        lineHeight: '1em'
      },
      root: {
        fontSize: '1rem',
        lineHeight: '1.5rem'
      },
      subheading: {
        fontSize: '1em',
        lineHeight: '1.75rem'
      },
      title: {
        fontSize: '1.2em',
        lineHeight: '1.75em'
      }
    },
    MuiFab: {
      primary: {
        backgroundColor: informBlue,
        color: white
      }
    },
    typography: {
      useNextVariants: true,
      body: {
        fontSize: '1rem'
      },
      button: {
        color: '#fff'
      }
    },
    fontFamily: 'Roboto, sans-serif'
  }
})

export default theme
// /* eslint-disable sort-keys */
// import { ddiFormStyles } from 'ddiForm/ddiFormStyles'
// import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

// import {
//   informBlue,
//   ddiBlue,
//   turquoise,
//   flatGrey,
//   mediumGrey,
//   darkGrey,
//   errorRed,
//   successGreen,
//   // disabledFieldColor,
//   disabledButtonColor,
//   altTableRowColor,
//   selectedTableRowColor,
//   fullBlack,
//   white
// } from './colors'

// const informTheme = createMuiTheme({
//   palette: {
//     type: 'light',
//     primary: {
//       light: turquoise,
//       main: turquoise,
//       dark: ddiBlue,
//       constrastText: white
//     },
//     secondary: {
//       light: '#00ff00',
//       main: turquoise,
//       dark: ddiBlue,
//       contrastText: white
//     }
//   },
//   overrides: {
//   },
//   typography: {
//     useNextVariants: true,
//     body: {
//       fontSize: '1rem'
//     },
//     button: {
//       color: '#fff'
//     }
//   },
//   fontFamily: 'Roboto, sans-serif'
// })

// export default informTheme
