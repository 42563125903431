import React, { memo } from 'react'
import PropTypes from 'prop-types'
import withContext from 'ddiForm/withContext'
import { ContextMenu, MenuItem } from 'react-contextmenu'
import { Icon } from '@material-ui/core'

const defaultIconStyle = {
  color: '#444',
  fontSize: 16,
  verticalAlign: 'middle'
}

const DefaultContextMenu = ({
  contextMenuFn,
  id,
  customContextMenuItems,
  contextMenuIconStyle,
  contextMenuItemStyle,
  openScreenMenuText,
  openScreenData,
  value,
  ...rest
}) => {
  return (
    <ContextMenu id={id}>
      <MenuItem
        data={{ type: 'cut' }}
        onClick={contextMenuFn}
        key="cut"
        disabled={!value}
      >
        <Icon style={contextMenuIconStyle}>content_cut</Icon>{' '}
        <span /* style={contextMenuItemStyle} */>Cut</span>
      </MenuItem>
      <MenuItem
        data={{ type: 'copy' }}
        onClick={contextMenuFn}
        key="copy"
        disabled={!value}
      >
        <Icon style={contextMenuIconStyle}>content_copy</Icon>{' '}
        <span /* style={contextMenuItemStyle} */>Copy</span>
      </MenuItem>

      <MenuItem data={{ type: 'delete' }} onClick={contextMenuFn} key="delete">
        <Icon style={contextMenuIconStyle}>highlight_off</Icon>{' '}
        <span /* style={contextMenuItemStyle} */>Delete</span>
      </MenuItem>
      <MenuItem divider />
      <MenuItem data={{ type: 'select' }} onClick={contextMenuFn} key="select">
        <Icon style={contextMenuIconStyle}>select_all</Icon>{' '}
        <span /* style={contextMenuItemStyle} */>Select All</span>
      </MenuItem>
      {customContextMenuItems &&
        customContextMenuItems
          .reduce((acc, next) => acc.concat(next), [
            { divider: true, enabled: true, key: 'divider' }
          ])
          .map((item, idx) => {
            if (item.enabled) {
              return (
                <MenuItem
                  onClick={item.onClick}
                  key={item.text || item.key}
                  divider={item.divider}
                >
                  {item.icon ? (
                    <Icon style={item.iconStyle || defaultIconStyle}>
                      {item.icon}
                    </Icon>
                  ) : null}
                  <span style={item.style}>
                    {`${item.icon ? ' ' : ''}${item.text}`}
                  </span>
                </MenuItem>
              )
            }

            return null
          })}
    </ContextMenu>
  )
}
// const DefaultContextMenu = ({
//   contextMenuFn,
//   id,
//   customContextMenuItems,
//   contextMenuIconStyle,
//   contextMenuItemStyle,
//   openScreenMenuText,
//   openScreenData,
//   value
// }) => (
//   <ContextMenu id={id}>
//     <MenuItem
//       data={{ type: 'cut' }}
//       onClick={contextMenuFn}
//       key="cut"
//       disabled={!value}
//     >
//       <Icon style={contextMenuIconStyle}>content_cut</Icon>{' '}
//       <span /* style={contextMenuItemStyle} */>Cut</span>
//     </MenuItem>
//     <MenuItem
//       data={{ type: 'copy' }}
//       onClick={contextMenuFn}
//       key="copy"
//       disabled={!value}
//     >
//       <Icon style={contextMenuIconStyle}>content_copy</Icon>{' '}
//       <span /* style={contextMenuItemStyle} */>Copy</span>
//     </MenuItem>

//     <MenuItem data={{ type: 'delete' }} onClick={contextMenuFn} key="delete">
//       <Icon style={contextMenuIconStyle}>highlight_off</Icon>{' '}
//       <span /* style={contextMenuItemStyle} */>Delete</span>
//     </MenuItem>
//     <MenuItem divider />
//     <MenuItem data={{ type: 'select' }} onClick={contextMenuFn} key="select">
//       <Icon style={contextMenuIconStyle}>select_all</Icon>{' '}
//       <span /* style={contextMenuItemStyle} */>Select All</span>
//     </MenuItem>
//     {customContextMenuItems &&
//       customContextMenuItems
//         .reduce((acc, next) => acc.concat(next), [
//           { divider: true, enabled: true, key: 'divider' }
//         ])
//         .map((item, idx) => {
//           if (item.enabled) {
//             return (
//               <MenuItem
//                 onClick={item.onClick}
//                 key={item.text || item.key}
//                 divider={item.divider}
//               >
//                 {item.icon ? (
//                   <Icon style={item.iconStyle || defaultIconStyle}>
//                     {item.icon}
//                   </Icon>
//                 ) : null}
//                 <span style={item.style}>{`${item.icon ? ' ' : ''}${
//                   item.text
//                 }`}
//                 </span>
//               </MenuItem>
//             )
//           }

//           return null
//         })}
//   </ContextMenu>
// )

DefaultContextMenu.defaultProps = {
  // customContextMenuItems: [],
  contextMenuFn: () => {}
}
DefaultContextMenu.propTypes = {
  customContextMenuItems: PropTypes.element
}
export default memo(DefaultContextMenu)
// export default withContext({
//   value: (form, ownProps) =>
//     form.fields[ownProps.propertyName] &&
//     form.fields[ownProps.propertyName].value
// })(memo(ContextMenu))
