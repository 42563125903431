/* eslint import/prefer-default-export: 0 */
import React from 'react'
import { Icon } from '@material-ui/core'
import { getIn, makeRowDataUnique } from 'utils'
import {
  clearGridRow as clearGridRowAction,
  deleteGridRow as deleteGridRowAction,
  notifyExitTextCell as notifyExitTextCellAction,
  updateGridCellData as updateGridCellDataAction
} from './actions'

export function updateGridCellData(
  valKey = 'value',
  lastCell = false,
  requiresAdditionalValidation = false
) {
  /* this method gets called by individual components */
  /* e.g. TextFieldGridCell, UOMGridCell, DynamicDropdown */

  const {
    form,
    rowIndex,
    data: { rowId },
    propertyName,
    colDef: { field, generateDataId }
  } = this.props

  // debugger
  if (form && propertyName && this.props.dispatch) {
    /* leave rowIndex for grids and existing listeners that rely on that */
    this.props.dispatch(
      updateGridCellDataAction(form, {
        propertyName,
        rowIndex,
        rowId,
        field,
        value: this.state[valKey],
        generateDataId,
        lastCell,
        requiresAdditionalValidation
      })
    )
  }
}

export function deleteGridRow() {
  const {
    colDef: { form, propertyName },
    data: { rowId },
    rowIndex
  } = this.props
  // debugger
  if (form && propertyName && this.props.dispatch) {
    this.props.dispatch(
      deleteGridRowAction(form, {
        propertyName,
        rowId,
        rowIndex
      })
    )
  }
}
/* ^^ this method is going to get removed in favor of the one below it */

export function deleteGridRowNew() {
  const {
    colDef: {
      cellRendererParams: { form, propertyName }
    },
    data: { rowId },
    rowIndex
  } = this.props
  // debugger
  if (form && propertyName && this.props.dispatch) {
    this.props.dispatch(
      deleteGridRowAction(form, {
        propertyName,
        rowId,
        rowIndex
      })
    )
  }
}

export function isDisabled() {
  const {
    colDef: {
      cellRendererParams: { editable, allowEditWithoutDataId },
      field
    },
    disabled,
    rowData,
    rowIndex
  } = this.props

  // debugger
  /* handle explicitly set editable conditions */
  if (typeof editable !== 'undefined' && typeof editable === 'boolean') {
    // debugger
    return !editable
  }

  /* handle disabling fields before an index search selection is made */
  if (
    rowData &&
    rowData[rowIndex] &&
    Object.keys(rowData[rowIndex]).indexOf('dataId') !== -1 &&
    (!rowData[rowIndex].dataId || rowData[rowIndex].dataId === null) &&
    field !== 'dataId' &&
    !allowEditWithoutDataId
  ) {
    return true
  }

  return disabled
}

export function clearGridRow() {
  const {
    colDef: {
      cellRendererParams: {
        form,
        propertyName,
        skipFields = [],
        defaultResets = {}
      }
    },
    data: { rowId },
    rowIndex
  } = this.props
  // debugger
  if (form && propertyName && this.props.dispatch) {
    this.props.dispatch(
      clearGridRowAction(form, {
        defaultResets,
        propertyName,
        rowIndex,
        rowId,
        skipFields
      })
    )
  }
}

export const getErrorIcon = (errorMessage = 'This field is required') => (
  <span title={errorMessage || ''}>
    <Icon
      style={{
        color: '#ff0000',
        fontSize: 16,
        marginRight: 5,
        verticalAlign: 'middle'
      }}
    >
      error
    </Icon>
  </span>
)

export function showRequiredIcon() {
  const {
    colDef: {
      cellRendererParams: { isRequired, requiredFn, errorMessage }
    },
    data,
    value
  } = this.props

  const requiredIconPosition = { position: 'absolute', bottom: 5, left: 0 }

  if (requiredFn) {
    return requiredFn(data) ? (
      <div style={requiredIconPosition}>{getErrorIcon(errorMessage)}</div>
    ) : null
  }

  if (isRequired && !value) {
    return <div style={requiredIconPosition}>{getErrorIcon(errorMessage)}</div>
  }

  return null
}

export function notifyExitTextCellFn() {
  /* this method gets called by individual components */
  /* e.g. TextFieldGridCell */

  const {
    form,
    data: { rowId },
    propertyName,
    colDef: { field }
  } = this.props

  if (form && propertyName && this.props.dispatch) {
    this.props.dispatch(
      notifyExitTextCellAction(form, {
        propertyName,
        rowId,
        field
      })
    )
  }
}

export const handleEditableGridAPIData = (formState, response, next, prop) => {
  let defaultData = getIn(formState, `fields.${prop}.rowData`)
  defaultData = defaultData && defaultData?.toJS ? defaultData.toJS() : []

  return response[next][prop] && Array.isArray(response[next][prop])
    ? makeRowDataUnique(response[next][prop], 'rowId')
    : makeRowDataUnique(defaultData, 'rowId')
}
