import { createAction, createAsyncAction } from 'utils'
import * as CONSTANTS from './constants'

export const initDashboard = (dashboards, layouts) =>
  createAction(CONSTANTS.INIT, { dashboards, layouts })
export const addTile = createAsyncAction(CONSTANTS.ADD_TILE)
export const onAddTile = () => createAction(CONSTANTS.ON_ADD_TILE)
export const onRemoveTile = () => createAction(CONSTANTS.ON_REMOVE_TILE)
export const removeTile = createAsyncAction(CONSTANTS.REMOVE_TILE)
export const storeLayouts = layouts =>
  createAction(CONSTANTS.STORE_LAYOUTS, { layouts })
export const hideTips = createAsyncAction(CONSTANTS.HIDE_TIPS)
export const showTipsModal = () => createAction(CONSTANTS.SHOW_TIPS_MODAL)
export const toggleCheck = () => createAction(CONSTANTS.TOGGLE_CHECK)
export const accessRequestCanceled = () =>
  createAction(CONSTANTS.ACCESS_REQUEST_CANCELED)
export const toggleTile = (item, checked) =>
  createAction(CONSTANTS.TOGGLE_TILE.REQUEST, { checked, item }, null, false, {
    withAccess: true,
    thunk: true,
    apiMethod: true
  })
export const tileMounted = (id, sagas, actions = []) =>
  createAction(CONSTANTS.TILE_MOUNTED, {
    actions,
    id,
    sagas
  })
export const tileUnmounted = id =>
  createAction(CONSTANTS.TILE_UNMOUNTED, { id })

/* dashboard details */
export const dashboardDetailsOpen = createAsyncAction(
  CONSTANTS.DASHBOARD_DETAILS_OPEN,
  null,
  true
)
