import React, { useState, useEffect } from 'react'
import { DDIButton } from 'ddiForm/wrapped'
// import { confirm, canceled, removeModal } from 'modals/actions'
import { Switch as Toggle, FormControlLabel } from '@material-ui/core'
import {
  acceptDuplicateProduct,
  rejectDuplicateProduct
} from 'pages/SalesOrder/actions'

export const DuplicateProductsModalActions = ({ dispatch, form, modal }) => {
  const [applyForAll, setApplyForAll] = useState(false)
  const [modalId, setModalId] = useState('')

  useEffect(() => {
    if (modal && modal.id) {
      setModalId(modal.id)
    }
  }, [modal])

  function handleCancel() {
    // dispatch(canceled(form, modalId, null, false, { applyForAll }))
    dispatch(rejectDuplicateProduct(form, { applyForAll, modalId }))
  }

  function handleConfirm() {
    // dispatch(confirm(form, modalId, { applyForAll }))
    dispatch(acceptDuplicateProduct(form, { applyForAll, modalId }))
  }

  function handleToggle(e) {
    const {
      target: { checked }
    } = e
    // const checked = e.target.checked
    setApplyForAll(checked)
  }

  function getToggle() {
    return (
      <Toggle checked={applyForAll} id={modal.id} onChange={handleToggle} />
    )
  }

  return (
    <>
      <FormControlLabel
        control={getToggle()}
        label="Apply For All"
        labelPlacement="start"
        style={{ marginRight: 'auto' }}
      />
      {/* <DDIToggle
        preventAutoDisable
        propertyName="copyProducts.applyForAllDuplicates"
        label="Apply For All"
      />         */}
      <DDIButton
        onClick={handleConfirm}
        confirm
        variant="contained"
        label="OK"
        preventAutoDisable
      />
      <DDIButton
        onClick={handleCancel}
        variant="contained"
        label="Cancel"
        preventAutoDisable
      />
    </>
  )
}

export default DuplicateProductsModalActions
