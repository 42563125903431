import { openScreen as openScreenAction } from 'pages/Main/actions'
import baseBehaviors from 'components/Search/baseBehaviors'
import { CLOSE_SEARCH, CLOSE_FILTERS_GRID } from './constants'

import {
  blur,
  exactMatchSearch,
  partialMatchSearch,
  resetFilters as resetFiltersAction,
  clearSearch
} from './actions'

export default {
  ...baseBehaviors,
  async onBlur(event) {
    // debugger
    if (event && event.persist) event.persist()
    if (this.state.isOpen || this.props.isFetching) return
    if (this._isMounted) {
      await this.setState({ isFocused: false })
    }
    if (!this.state.isSet && this.state.isDirty && this._isMounted) {
      /* 
        added allowInvalidValueOnBlur logic and onBlur
        callback to enable entering a new Serial Number
        for VSO item #8318. Prop-based update so other
        existing searches won't be effected -- SVE 12/2/2020
      */
      this.setState(
        prevState => {
          return {
            value: this.props.allowInvalidValueOnBlur ? prevState.value : '',
            description: '',
            isDirty: false
          }
        },
        () => {
          this.blur(this.state.value)
          if (this.props.onBlur && typeof this.props.onBlur === 'function') {
            this.props.onBlur(this.state.value)
          }
        }
      )
    } else {
      this.blur(this.state.value)
    }
  },
  blur(value) {
    this.props.dispatch(
      blur(this.form, {
        propertyName: this.props.propertyName,
        value,
        isSet: this.state.isSet
      })
    )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        this.props.onChange('')
      }
    }
  },
  clearSearch() {
    if (!this.props._ddiForm) return
    this.props.dispatch(
      clearSearch(this.props._ddiForm.form, {
        propertyName: this.props.propertyName
      })
    )
  },
  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select() {
        target.select()
      },
      paste() {
        // Chrome does not allow retrieval of clipboard data
        // document.addEventListener('paste', function (evt) {
        //   const data = evt.clipboardData.getData('text')
        //   console.log('DATA', data)
        // })
        // target.focus()
        // document.execCommand('paste')
      },
      delete() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        const { openScreenData } = this.props
        this.props.dispatch(openScreenAction(openScreenData))
      }
    }
    return (action[data.type] || action.select)()
  },
  focusField() {
    return (
      this.textField &&
      this.setState(
        { isFocused: true },
        () => ((this.autofocused = true), this.textField.focus())
      )
    )
  },
  getRowNodeId(data) {
    return data.recordName
  },
  getGridHeight() {
    const { grid } = this.props
    return grid && grid.rowData && grid.rowData.length
      ? grid.rowData.length * 27 + 52
      : 150
  },
  async onCellSelected(event) {
    const {
      propertyName,
      customerId,
      warehouseId,
      productId,
      uomId
    } = this.props

    // console.log('onCellSelected', event)
    // debugger
    try {
      const p = await this.props.dispatch(
        exactMatchSearch(this.form, {
          keyword: event.data.number,
          customerId,
          propertyName,
          warehouseId,
          productId,
          uomId
        })
      )

      if (p.matchFound) {
        this.setState(
          {
            value: p.result.dataId,
            description: p.result.description || '',
            isSet: true,
            isDirty: false,
            isOpen: false,
            tab: false
          },
          () => {
            this.setField(p.result.dataId)
            this.blur(this.state.value)
          }
        )
      } else {
        this.setState({
          value: '',
          isSet: false,
          isDirty: false,
          isOpen: false,
          tab: false
        })
      }
    } catch (e) {
      this.onRequestClose()
    }
  },
  onCloseFiltersGrid() {
    this.setState({ mouseover: false }, () => {
      if (this.props._ddiForm) {
        this.props.dispatch({
          type: CLOSE_FILTERS_GRID,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
    })
  },
  onKeyDown(event) {
    event.persist()
    const val = event.target.value
    /* this is for New Event Type popover */
    if (this.props.disableBaseOnTabFunctionality && this.props.onKeyDown) {
      this.props.onKeyDown(event)
      return
    }

    // check if tooltip is open and keys match etc.. great :-(
    if (event.keyCode === 13) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (this.state.isSet || !this.state.value) {
        return
      }
      event.preventDefault()
      // event.preventDefault()
      // event.target.blur()

      const {
        propertyName,
        warehouseId,
        customerId,
        productId,
        uomId
      } = this.props

      this.setState(
        {
          isOpen: false,
          value: val,
          lastSearch: val,
          tab: true
        },
        async () => {
          try {
            const p = await this.props.dispatch(
              exactMatchSearch(this.form, {
                keyword: this.state.value,
                propertyName,
                customerId,
                warehouseId,
                productId,
                uomId
              })
            )

            if (p.matchFound) {
              this.setState(
                {
                  value: p.result.dataId,
                  description: p.result.description || '',
                  isSet: true,
                  isDirty: false,
                  isOpen: false,
                  tab: false
                },
                () => {
                  this.setField(p.result.dataId)
                  this.blur(this.state.value)
                }
              )
            } else {
              this.setState({
                value: '',
                isSet: false,
                isDirty: false,
                isOpen: false,
                tab: false
              })
            }
          } catch (e) {
            this.setState({ isSet: false, isDirty: true, tab: false })
          }
        }
      )
    }
  },

  onMouseLeave(event) {
    this.setState({ mouseover: false })
  },

  onMouseOver(event) {
    this.setState({ mouseover: true })
  },
  onRequestClose() {
    // console.log('request close', this.state, this.props)
    this.setState({ isOpen: false, mouseover: false }, () => {
      if (this.props._ddiForm) {
        this.props.dispatch({
          type: CLOSE_SEARCH,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
      if (this.props.onRequestClose) {
        this.props.onRequestClose()
      }
    })
  },
  onSearchClick(event) {
    this.setState(
      {
        isSet: false
      },
      () => this.partialMatchSearch(this.state.value, true)
    )
  },

  partialMatchSearch(value, bool, args, isFiltered = false) {
    let searchAll = false
    if (typeof value === 'boolean') {
      searchAll = value
      value = ''
    }
    // const { partialMatc}
    const { keyword, productId, propertyName, uomId, warehouseId, customerId } =
      args || this.props

    if (args) {
      value = args.keyword
    }

    if (this.state.isSet || this.props.isFetching) return
    let results
    this.setState({ lastSearch: value, isSearching: true }, async () => {
      try {
        results = await this.props.dispatch(
          partialMatchSearch(this.props.form, {
            keyword: bool ? '' : value,
            productId,
            propertyName,
            uomId,
            warehouseId,
            customerId,
            onCellSelected: this.onCellSelected
          })
        )

        this.setState({
          dropDownProps: {
            ...results,
            ...results.grid,
            getRowNodeId: this.getRowNodeId,
            paginationCb: this.paginationCb,
            height: this.getGridHeight(),
            isFiltered
          },
          isOpen: true
        })
      } finally {
        this.setState({ isSearching: false })
      }
    })
  },
  redoSearch() {
    this.setState(
      { isSet: false, value: this.state.lastSearch, isDirty: true },
      () => this.partialMatchSearch(this.state.lastSearch)
    )
  },
  resetFilters() {
    this.props.dispatch(
      resetFiltersAction(this.form, { propertyName: this.props.propertyName })
    )
  }
}
