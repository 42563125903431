import { createSelector } from 'reselect'
import { getIn } from 'utils'
import { Map } from 'immutable'

export const dashboardSelector = state => state.get('dashboard')

export const tilesSelector = createSelector(dashboardSelector, dash => dash.get('tiles'))
// export const activeIdsSelector = createSelector(dashboardSelector, dash => dash.get('active'))
export const activeTilesSelector = createSelector(tilesSelector, tiles =>
  tiles
    .filter(x => x.get('isSelected')))

export const activeIdsSelector = createSelector(activeTilesSelector, tiles =>
  tiles
    .keySeq())
export const layoutsSelector = createSelector(dashboardSelector, dash => dash.get('layouts'))

export const checkedSelector = createSelector(dashboardSelector, dash => dash.get('checked'))
export const groupedTilesSelector = createSelector(dashboardSelector, dash =>
  dash
    .get('categoryTiles')
    .map((val, key) =>
      val.sort().map((v, k) => {
        const tile = getIn(dash, `tiles.${v}`)
        return new Map({
          description: tile.get('description'),
          id: tile.get('id'),
          imageFilename: tile.get('imageFilename'),
          isSelected: tile.get('isSelected')
        })
      })))
