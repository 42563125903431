import TG from '../ThreeWayToggle'
import createWrappedField from './createWrappedField'

const exp = createWrappedField({
  Component: TG,
  name: 'DDIThreeWayToggle'
})

exp.ddiType = 'ThreeWayToggle'
export default exp
