const isValidJSON = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

/*
  meant to handle unusual scenario,
  where we may or may NOT be getting valid JSON
  back from the API. We might get a blob of text
  back, but we also might get JSON and this
  variation on the main fetch process handles that
*/
export default function fetchProcessBlob(route, options) {
  return fetch(route, options)
    .then(response => response.blob().then(text => ({ text, response })))
    .then(({ text, response }) => {
      if (!response.ok) {
        text.status = response.status
        return Promise.reject(text)
      }
      return text
    })
    .then(
      blob => {
        // debugger
        return { response: blob }
      },
      error => {
        const err = {
          error: {
            ...error,
            message: error.error_description || error.message
          }
        }
        return err
      }
    )
}
