import createSearch from 'components/Search/createSearch'
import behaviors from './behaviors'
import TextInput from './TextInput'
import MobileGrid from '../../grid'

// import DropDownComponent from './BinSearch'

const SerialNumberSearchMobile = createSearch({
  name: 'MobileSerialNumberSearch',
  behaviors,
  popoverStyle: { width: 500 },
  // DropDownComponent,
  meta: {
    allowInstantSearch: true,
    allowSearchAll: true,
    minimumKeywordLength: 1,
    allowNavigation: false,
    allowSpecialProducts: false,
    hasFilters: false,
    hideToggle: true
  },
  DropDownComponent: MobileGrid,
  TextInput,
  mobile: true
})

export default SerialNumberSearchMobile
