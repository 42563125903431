import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const indexSearchInputStyle = { margin: '0 30px', width: 150 }

const SearchFields = props => {

  return (
    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: 20 }}>
      <div style={indexSearchInputStyle}>
        <Skeleton variant="text" width="50" height="100%" />
        <Skeleton animation="wave" varint="rect" width="100" />

      </div>
      <div style={indexSearchInputStyle}>
        <Skeleton variant="text" width="50" height="100%" />
        <Skeleton animation="wave" variant="rect" width="100" />
      </div>
      
      <div style={indexSearchInputStyle}>
        <Skeleton variant="text" width="50" height="100%" />
        <Skeleton animation="wave" varint="rect" width="100" />

      </div>
      <div style={indexSearchInputStyle}>
        <Skeleton variant="text" width="50" height="100%" />
        <Skeleton animation="wave" variant="rect" width="100" />
      </div>


    </div>
  )
}

export default SearchFields