import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

/* start grid add/edit stuff here */
export const onPrimaryGridDataPosted = (form, {
  rowIndex,
  rowId,
  newData,
  propertyName,
  allowDuplicates = false,
  allowDuplicatesRequiresModal = '',
  entityType = '',
  gridApi
}) =>
  createAction(
    CONSTANTS.ON_PRIMARY_GRID_DATA_POSTED,
    {
      rowIndex,
      rowId,
      newData,
      propertyName,
      allowDuplicates,
      allowDuplicatesRequiresModal,
      entityType,
      gridApi
    },
    form
  )

export const onPrimaryGridDataValidated = (
  form,
  { rowIndex, newData, propertyName }
) =>
  createAction(
    CONSTANTS.ON_PRIMARY_GRID_DATA_VALIDATED,
    {
      rowIndex,
      newData,
      propertyName
    },
    form
  )

export const deleteGridRow = (form, { propertyName, rowIndex, rowId }) =>
  createAction(CONSTANTS.DELETE_GRID_ROW, { propertyName, rowIndex, rowId }, form)

export const updateGridCellData = (
  form,
  {
    rowId,
    rowIndex,
    propertyName,
    field,
    value,
    generateDataId = false,
    lastCell = false,
    requiresAdditionalValidation = false
  }
) =>
  createAction(
    CONSTANTS.UPDATE_GRID_CELL_DATA,
    {
      rowId,
      rowIndex,
      propertyName,
      field,
      value,
      generateDataId,
      lastCell,
      requiresAdditionalValidation
    },
    form
  )

export const setRequiredGridCols = (form, { requiredCols, propertyName }) =>
  createAction(
    CONSTANTS.SET_REQUIRED_GRID_COLS,
    { requiredCols, propertyName },
    form
  )

export const initializeEditableGrid = (form, { requiredCols, propertyName }) =>
  createAction(
    CONSTANTS.INITIALIZE_EDITABLE_GRID,
    { requiredCols, propertyName },
    form
  )


/* added additional flags for executing row insertion */
export const validateGridData = (
  form,
  { propertyName, preInsertRow = false, rowIndex = null }
) =>
  createAction(
    CONSTANTS.VALIDATE_GRID_DATA,
    { propertyName, preInsertRow, rowIndex },
    form
  )

export const setFocusedCell = (form, { rowIndex, field, propertyName }) =>
  createAction(
    CONSTANTS.SET_FOCUSED_CELL,
    { rowIndex, field, propertyName },
    form
  )

export const additionalValidationSuccess = (
  form,
  { propertyName, rowIndex, validated }
) =>
  createAction(
    CONSTANTS.ADDITIONAL_VALIDATION_SUCCESS,
    { propertyName, rowIndex, validated },
    form
  )

export const clearGridRow = (
  form,
  { propertyName, rowIndex, rowId, skipFields, defaultResets }
) =>
  createAction(
    CONSTANTS.CLEAR_GRID_ROW,
    { propertyName, rowIndex, rowId, skipFields, defaultResets },
    form
  )

export const resetGridIndexSearch = (form, { propertyName }) =>
  createAction(CONSTANTS.RESET_GRID_INDEX_SEARCH, { propertyName }, form)

export const requestInsertGridRow = (form, { propertyName, rowIndex }) =>
  createAction(
    CONSTANTS.REQUEST_INSERT_GRID_ROW,
    { propertyName, rowIndex },
    form
  )

export const insertGridRow = (form, { propertyName, rowIndex }) =>
  createAction(CONSTANTS.INSERT_GRID_ROW, { propertyName, rowIndex }, form)

export const saveSortedRowData = (
  form,
  { propertyName, rowData }
) =>
  createAction(
    CONSTANTS.SAVED_SORTED_ROW_DATA,
    {
      propertyName,
      rowData
    },
    form
  )

export const setBlankRowId = (
  form,
  { propertyName }
) =>
  createAction(
    CONSTANTS.SET_BLANK_ROW_ID,
    {
      propertyName
    },
    form
  )
  
export const ensureRowIdsSet = (form, { propertyName }) =>
  createAction(
    CONSTANTS.ENSURE_ROW_IDS_SET,
    { propertyName },
    form
  )

export const notifyExitTextCell = (
  form,
  {
    rowId,
    propertyName,
    field
  }
) =>
  createAction(
    CONSTANTS.NOTIFY_EXIT_TEXT_CELL,
    {
      rowId,
      propertyName,
      field
    },
    form
  )
  
export const openPartNumbersNotesEditor = (form, { field, note, rowId, rowIndex, propertyName, description, dataId, isEditing }) =>
  createAction(
    CONSTANTS.OPEN_PART_NUMBERS_NOTES_EDITOR,
    { field, note, rowId, rowIndex, propertyName, description, dataId, isEditing },
    form
  )
