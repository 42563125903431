/* eslint no-useless-escape: 0 */
/* listen eslint, phone validation regex escape is required */
import { is } from 'utils'

export const required = (value, _) => ((value || value === 0) ? undefined : 'Required')

export const stringify = value => (is.number(value) ? value.toString() : value)
export const exactLength = len => value => {
  // debugger
  value = stringify(value)
  return value && value.length !== len
    ? `Must be exactly ${len} characters.`
    : undefined
}
// export const exactLength = len => value =>
//   value && value.length !== len
//     ? `Must be exactly ${len} characters.`
//     : undefined

export const minLength = min => value => {
  value = stringify(value)

  return value && value.length < min
    ? `Must be at least ${min} characters.`
    : undefined
}
export const maxLength = max => value => {
  value = stringify(value)

  return value && value.length > max ? `Must be less than ${max}.` : undefined
}
export const minValue = min => value => {
  value = stringify(value)
  return (value || is.number(value)) && value < min
    ? `Must be more than ${min}.`
    : undefined
}

export const maxValue = max => value => {
  value = stringify(value)
  return (value || is.number(value)) && value > max
    ? `Must be less than ${max}.`
    : undefined
}

export const emailAddress = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const phoneNumber = value =>
  value && !/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/i.test(value)
    ? 'Invalid phone number'
    : undefined
