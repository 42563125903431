import * as localService from 'services/local'
import { deepEqual } from 'utils'

export const extractFormName = form =>
  form?.includes('-') ? form?.split('-')?.[0] || form : form

export const extractProps = props => ({
  propertyName: props.propertyName || props.id,
  form: extractFormName(props.form)
})
const localKey = 'grids'
const getKey = props => `${localKey}-${props.userId}`

export const extractHiddenFieldsFromProps = props => {
  let hiddenFields = []
  const { columnDefs } = props
  const hidden = columnDefs.filter(x => x.hide).map(x => x.field)

  hiddenFields = hidden.length ? hidden : hiddenFields
  return hiddenFields
}

export const fetchFormGridsFromLocal = props => {
  // check for grids..
  const key = getKey(props)
  let grids = localService.get(key)
  if (!grids) {
    // !grids namespace. create it
    localService.set(key, JSON.stringify({}))
  }
  const form = extractFormName(props.form)
  grids = grids ? JSON.parse(grids) : {}
  if (grids[form]) {
    return grids[form]
  }
  // !grids[form]. create it. then check to see if there are default hidden columns..
  grids[form] = {}
  localService.set(key, JSON.stringify(grids))

  return null
}
export const setGridColsInLocal = (props, gridCols) => {
  const grid = fetchFormGridsFromLocal(props)
  if (!grid) {
    /* bulletproof this function a bit or it causes mad errors */
    return
  }

  const { propertyName, form } = extractProps(props)
  grid[propertyName] = gridCols

  const key = getKey(props)
  let grids = localService.get(key)
  if (!grids) {
    throw new Error('there should be grids at this point!!')
  }
  grids = JSON.parse(grids)
  const newGrids = {
    ...grids,
    [form]: {
      ...grids[form],
      [propertyName]: gridCols
    }
  }
  if (!deepEqual(newGrids, grid)) {
    localService.set(key, JSON.stringify(newGrids))
  }
}

export const getHiddenFields = props => {
  const hiddenFields = extractHiddenFieldsFromProps(props)

  if (hiddenFields?.length) {
    setGridColsInLocal(props, hiddenFields)
  }
  return hiddenFields?.length ? hiddenFields : null
}
export const fetchGridColsFromLocal = props => {
  const grid = fetchFormGridsFromLocal(props)

  const { propertyName, id } = props
  let hiddenFields
  if (!grid) {
    // maybe a good place to check the hiddenColumns from col def...
    hiddenFields = getHiddenFields(props)

    return hiddenFields?.length ? hiddenFields : null
  }
  if (grid[propertyName || id]) {
    return grid[propertyName || id]
  }
  hiddenFields = extractHiddenFieldsFromProps(props)

  if (hiddenFields?.length) {
    setGridColsInLocal(props, hiddenFields)
  }
  return (hiddenFields = getHiddenFields(props))
}

export const toggleFieldInLocal = (props, colId, visible) => {
  const { propertyName, form } = extractProps(props)
  let gridCols = fetchGridColsFromLocal(props)

  let formGrids
  if (!gridCols) {
    // create the entry..
    // no columns.. let's see if there's a grid id in there

    formGrids = fetchFormGridsFromLocal(props)
    if (!formGrids) {
      // create the entry for the form and grid.
      // also look at the colDefs and find the hidden ones...
      // const { columnDefs } = props
      // const hidden = columnDefs.filter(x => x.hide).map(x => x.field)

      // hiddenFields = hidden.length ? hidden : hiddenFields
      // const hiddenFields = extractHiddenFieldsFromProps(props)
      const key = getKey(props)
      const hiddenFields = []
      const grids = JSON.parse(localService.get(key)) || {}
      grids[form] = {}
      grids[form][propertyName] = hiddenFields
      localService.set(key, JSON.stringify(grids))
      gridCols = hiddenFields
    }
  }
  // there's an entry for the grid in storage...
  // find if the gridCols has our colId
  // if colId === visible remove from list otherwise add if its not visible

  /*
    Marc, there was an error here when using
    in allowFieldChooser, using the plain
    Grid component (not GridField) -- SVE 1/24/2020
  */
  if (gridCols && Array.isArray(gridCols)) {
    if (visible) {
      gridCols = gridCols.filter(x => x !== colId)
    } else if (!gridCols.includes(colId)) {
      gridCols = gridCols.concat(colId)
    }

    setGridColsInLocal(props, gridCols)
  }
}
