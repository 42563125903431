/* eslint-disable eqeqeq */
/* eslint-disable react/no-did-update-set-state */
import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import * as actions from './actions'
import MailStacks from './components/MailStacks'
import OtherMessages from './components/OtherMessages'

const mapDispatchToProps = dispatch => ({
  remove: (...args) => dispatch(actions.remove(...args))
})

const SnackbarService = ({ anchorOrigin }) => {
  const [height, setHeight] = useState(window.innerWidth)
  const [width, setWidth] = useState(window.innerHeight)
  const resize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  })
  return (
    <>
      <MailStacks anchorOrigin={anchorOrigin} height={height} width={width} />
      <OtherMessages
        anchorOrigin={anchorOrigin}
        height={height}
        width={width}
      />
    </>
  )
}
SnackbarService.defaultProps = {
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom'
  }
}

export default connect(
  null,
  mapDispatchToProps
)(SnackbarService)
