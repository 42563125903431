import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const INDEX_SEARCH = '@@BinSearch/'
export const POPULATE_FIELD = `${INDEX_SEARCH}POPULATE_FIELD`
export const BLUR = `${INDEX_SEARCH}BLUR`
export const FOCUS = `${INDEX_SEARCH}FOCUS`
export const CLOSE_SEARCH = `${INDEX_SEARCH}CLOSE_SEARCH`
export const EXACT_MATCH_SEARCH = createAsyncRequestTypes(
  `${INDEX_SEARCH}EXACT_MATCH_SEARCH`
)
export const PARTIAL_MATCH_SEARCH = createAsyncRequestTypes(
  `${INDEX_SEARCH}PARTIAL_MATCH_SEARCH`
)
export const FIND_NEXT = createAsyncRequestTypes(`${INDEX_SEARCH}FIND_NEXT`)
export const FIND_PREV = createAsyncRequestTypes(`${INDEX_SEARCH}FIND_PREV`)
export const TOGGLE_FILTER = `${INDEX_SEARCH}TOGGLE_FILTER`
export const GET_TOOLTIP = createAsyncRequestTypes(`${INDEX_SEARCH}GET_TOOLTIP`)
export const GET_SEARCH_FILTERS = createAsyncRequestTypes(
  `${INDEX_SEARCH}GET_SEARCH_FILTERS`
)

// export const ORDER_SEARCH_URL = `${BASE_INFORM_API_URL}/search/salesordernumber` // dup with lowere one..
export const BIN_PARTIAL_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/binpartialmatch`
export const BIN_EXACT_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/binexactmatch`
// export const FIND_NEXT_SEARCH_URL = `${BASE_INFORM_API_URL}/search/findnext`
// export const FIND_PREV_SEARCH_URL = `${BASE_INFORM_API_URL}/search/findprevious`
// export const GET_TOOLTIP_URL = `${BASE_INFORM_API_URL}/search/indexsearch/summary`
// export const GET_SEARCH_FILTER_URL = `${BASE_INFORM_API_URL}/search/indexsearch/filters`

export const OPEN_FILTERS_GRID = `${INDEX_SEARCH}OPEN_FILTERS_GRID`
export const CLOSE_FILTERS_GRID = `${INDEX_SEARCH}CLOSE_FILTERS_GRID`
export const CLEAR_FILTER_FIELD = `${INDEX_SEARCH}CLEAR_FILTER_FIELD`
export const SET_TEXT_FILTER_FIELD = `${INDEX_SEARCH}SET_TEXT_FILTER_FIELD`
export const RESET_FILTERS = `${INDEX_SEARCH}RESET_FILTERS`
export const CLEAR_SEARCH = `${INDEX_SEARCH}CLEAR_SEARCH`
