import React, { Component } from 'react'
// import memoize from 'memoize-one'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import shouldUpdate from 'hoc/shouldUpdate'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import { compose } from 'redux'
// import deep from 'deep-diff'
import { getIn, plainDeepEqual } from 'utils'
import HoverIntent from 'react-hoverintent'
import { Tooltip } from 'react-tippy'
import * as CONSTANTS from './constants'

const propsToNotUpdateFor = ['_ddiForm']
const whitelist = ['hoverIntended', 'value']

class ConnectedLabel extends Component {
  static propTypes = {
    icon: PropTypes.object,
    iconName: PropTypes.string,
    iconStyle: PropTypes.object,
    includeIcon: PropTypes.bool,
    initialValue: PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.any
      }),
      PropTypes.string
    ]), // PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    labelStyle: PropTypes.object,
    propertyName: PropTypes.string.isRequired
  }

  static defaultProps = {
    icon: null,
    iconName: 'info',
    iconStyle: {},
    includeIcon: true,
    initialValue: null,
    labelStyle: {},
    forwardRef: false
  }

  constructor(props) {
    super(props)
    this.state = {
      hoverIntended: false,
      value: props.initialValue || props.value || ''
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState.hoverIntended !== this.state.hoverIntended) {
  //     return true
  //   }

  //   return Object.keys(nextProps).some(
  //     prop =>
  //       !~propsToNotUpdateFor.indexOf(prop) &&
  //       !plainDeepEqual(this.props[prop], nextProps[prop])
  //   )
  // }

  hoverIntended = () => this.setState({ hoverIntended: true })

  hoverNotIntended = () => this.setState({ hoverIntended: false })

  renderLabel = value =>
    value ? (
      <Tooltip
        open={this.state.hoverIntended}
        // ref={el => (this.tooltip = el)}
        html={
          <div
            className="inform-tooltip"
            style={{ padding: 10, borderRadius: 4 }}
          >
            <p style={{ color: '#fff', fontSize: 12, marginBottom: 0 }}>
              {value}
            </p>
          </div>
        }
        unmountHTMLWhenHide
        position="top"
        popperOptions={{
          modifiers: { preventOverflow: { boundariesElement: 'window' } }
        }}
      >
        <div style={{ cursor: 'alias' }}>
          <HoverIntent
            onMouseOver={this.hoverIntended}
            onMouseOut={this.hoverNotIntended}
            sensitivity={20}
            interval={500}
          >
            <div
              className="ddi-data-label"
              style={{
                background: '#eaeef0',
                borderRadius: 4,
                color: '#517b9c',
                display: 'inline-block',
                fontSize: 12,
                lineHeight: '16px',
                maxWidth: '100%',
                overflow: 'hidden',
                padding: 5,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: 'auto',
                ...this.props.labelStyle
              }}
            >
              {this.props.includeIcon ? (
                <Icon
                  style={{
                    color: '#517b9c',
                    fontSize: 16,
                    marginRight: 5,
                    verticalAlign: 'middle',
                    ...this.props.iconStyle
                  }}
                >
                  {this.props.iconName}
                </Icon>
              ) : null}
              {value}
            </div>
          </HoverIntent>
        </div>
      </Tooltip>
    ) : null

  render() {
    console.log('ConnectedLabel', this.props)
    const { formatting, value } = this.props
    const v =
      formatting && typeof formatting === 'function' ? formatting(value) : value

    return this.renderLabel(v)
  }
}

const connector = connect(
  (state, ownProps) => {
    let value
    let { initialValue } = ownProps
    const { propertyName, getFormState } = ownProps

    if (initialValue && !(typeof initialValue === 'string')) {
      debugger
      initialValue =
        initialValue.type === CONSTANTS.DEFAULT_VALUE_TYPES.VALUE
          ? initialValue.value
          : getIn(ownProps._ddiForm, initialValue.value)
    }
    const formState = getFormState(state)
    const field = getIn(formState, `fields.${propertyName}`)

    /*
      Marc -- adjusted for pre-existing instances of the Label 
      component that have propertyNames like customer.description 
      in SOI/components/InquiryTab. This was causing a screen
      crash in multiple places --- SVE 4/20/2021    
    */
    value =
      field && field?.get
        ? field.get('value')
        : typeof field === 'string'
        ? field
        : ''

    if (!value) {
      if (typeof field === 'string') {
        // field = { value: field }
        value = field
      } else if (!field) {
        value = getIn(formState, `values.${propertyName}`)
      } else if (initialValue && !field?.get('value')) {
        value = initialValue
      }
    }
    return {
      initialValue,
      // setField,
      // field
      value
    }
  },
  undefined,
  undefined,
  { forwardRef: true }
)

// const connector = connect(
//   (state, ownProps) => {
//     let { initialValue } = ownProps
//     const { propertyName, getFormState } = ownProps

//     if (initialValue && !(typeof initialValue === 'string')) {
//       debugger
//       initialValue =
//         initialValue.type === CONSTANTS.DEFAULT_VALUE_TYPES.VALUE
//           ? initialValue.value
//           : getIn(ownProps._ddiForm, initialValue.value)
//     }
//     const formState = getFormState(state)
//     let field = getIn(formState, `fields.${propertyName}`)
//     // field = field ? field.toJS() : field
//     if (typeof field === 'string') {
//       field = { value: field }
//     }
//     if (!field) {
//       field = { value: '' }
//       const value = getIn(formState, `values.${propertyName}`)
//       if (value) {
//         field = { value }
//       }
//     }
//     if (initialValue && !field.get(value)) {
//       field.value = initialValue
//     }
// if (field.toJS) {

// }
//     return {
//       initialValue,
//       // setField,
//       // field
//     }
//   },
//   undefined,
//   undefined,
//   { forwardRef: true }
// )
export default compose(
  connector,
  shouldUpdate({ whitelist })
)(ConnectedLabel)
