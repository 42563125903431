/* eslint prefer-destructuring: 0, func-names: 0, prefer-template: 0 */
function CustomTooltip() {}

function nl2br(str) {
  if (typeof str === 'undefined' || typeof str !== 'string' || str === null) {
    return ''
  }
  const breakTag = '<br>'
  return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

CustomTooltip.prototype.init = function(params) {
  this.eGui = document.createElement('div')
  const eGui = this.eGui
  const { value } = params

  eGui.classList.add('custom-tooltip')
  eGui.classList.add('ag-tooltip')
  eGui.style.minWidth = '125px'
  eGui.innerHTML = `<div>${nl2br(value)}</div>`
}

CustomTooltip.prototype.getGui = function() {
  return this.eGui
}

export default CustomTooltip
