import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const deleteMail = createAsyncFormAction(CONSTANTS.DELETE_MAIL)
export const openMail = createAsyncFormAction(CONSTANTS.OPEN_MAIL)
export const newMail = createAsyncFormAction(CONSTANTS.NEW_MAIL)
export const respondMail = createAsyncFormAction(CONSTANTS.RESPOND_MAIL)
export const closeMail = form => createAction(CONSTANTS.CLOSE_MAIL, {}, form)

export const editMail = createAsyncFormAction(CONSTANTS.EDIT_MAIL)
export const createUserMail = createAsyncFormAction(CONSTANTS.CREATE_USER_MAIL)
export const refreshOpenMail = createAsyncFormAction(
  CONSTANTS.REFRESH_OPEN_MAIL
)
export const syncMail = createAsyncFormAction(CONSTANTS.SYNC_MAIL)

export const printUserMail = createAsyncFormAction(CONSTANTS.PRINT_USER_MAIL)

export const exitUserMailScreen = createAsyncFormAction(
  CONSTANTS.EXIT_USER_MAIL_SCREEN
)

export const getEmailFaxLog = createAsyncFormAction(CONSTANTS.GET_EMAIL_FAX_LOG)

export const resendEmailFaxItem = createAsyncFormAction(
  CONSTANTS.RESEND_EMAIL_FAX_ITEM
)

export const deleteEmailFaxItem = createAsyncFormAction(
  CONSTANTS.DELETE_EMAIL_FAX_ITEM
)

// export const removeDeletedEmails = (form, { data }) =>
//   createAction(CONSTANTS.REMOVE_DELETED_EMAILS, { data }, form)
