/**
 * replaces return characters with BR tags
 * @param {string} str - HTML string
 * @returns {string} a string with break tags
*/
const replaceCarriageReturns = str => {
  if (!str) return ''
  return str.replace(/\r\n|\r|\n/g, '<br/>')
}

export default replaceCarriageReturns
