import {
  call,
  put,
  fork,
  take,
  select,
  actionChannel
} from 'redux-saga/effects'
import { addModal } from 'modals/actions'
import { CANCELED } from 'modals/constants'
import { destroyModal, noop, toCamelCase, getIn } from 'utils'
import { TRY_REQUEST_ACCESS } from 'auth/constants'
import AccessOverride from './AccessOverride'
import { accessRejected } from './actions'

/**
 * access override modal
 * @param {Number} id - access ID
 * @param {string} name - page/screen to access
 * @param {function} process - process to initialize
 * @returns {addModal} - shows the access override modal with options
 */
const cases = [TRY_REQUEST_ACCESS, CANCELED]

export function* showAccessOverrideModal({
  id,
  name,
  proc,
  accessPath,
  requiredLevel,
  message
}) {
  const options = {
    component: AccessOverride,
    props: {
      accessId: id,
      name,
      accessPath,
      requiredLevel,
      message
    }
  }
  const modal = yield call(addModal, options)
  yield put(modal)
  return modal.payload.id
}
export const overrideListener = function* overrideListener({
  modalId,
  apiMethod,
  proc,
  meta,
  ...rest
}) {
  let action
  const channel = yield actionChannel(cases)
  while (true) {
    action = yield take(channel)
    // action = yield take(cases)
    if (action.type === TRY_REQUEST_ACCESS) {
      //
      const { pin, password, username } = action.payload
      const { meta } = action

      const { response, error } = yield call(apiMethod, {
        pin,
        password,
        username,
        ...rest
      })

      if (response) {
        yield fork(destroyModal, modalId)
        yield fork(proc, response)
        return
      }
      yield put(accessRejected(meta))
    }
    if (action.type === CANCELED) {
      yield fork(destroyModal, modalId)
      if (meta && meta.thunk) {
        yield put({
          type: 'REJECTED',
          meta: { thunk: meta && meta.thunk ? meta.thunk : undefined },
          error: true
        })
      }
      return
    }
  }
}
export const grantAccessProcess = function* grantAccessProcess({
  name,
  image,
  title,
  proc = noop,
  id,
  data = {},
  accessPath,
  requiredLevel,
  message,
  apiMethod,
  form,
  meta,
  ...rest
}) {
  const modalId = yield call(showAccessOverrideModal, {
    id,
    name,
    accessPath,
    requiredLevel,
    message
  })
  yield fork(overrideListener, {
    modalId,
    apiMethod,
    proc,
    name,
    meta,
    ...rest
  })
}
