import { transform, isEqual, isObject } from 'lodash'

const difference = (object, base) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value
    }
  })

  export default difference
  