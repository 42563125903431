/* eslint react/sort-comp: 0, react/jsx-no-duplicate-props: 0, react/no-find-dom-node: 0, react/no-did-mount-set-state: 0, consistent-return: 0 */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HotKeys } from 'react-hotkeys'
// import { EventEmitter } from 'fbemitter'
import { eventChannel } from 'redux-saga'
import { getIn, plainDeepEqual, toNumeral } from 'utils'
// import { delay, throttle, debounce } from 'lodash'
import NumberFormat from 'react-number-format'
import { InputAdornment, Icon, Input, TextField } from '@material-ui/core'
import {
  getErrorIcon,
  // isDisabled,
  notifyExitTextCellFn
  // updateGridCellData
} from 'components/EditableGrid/utils'
import { debounce, once } from 'lodash'

import { setTextFilterField as setTextFilterFieldAction } from '../../IndexSearch/actions'

const TAB = 9
const ENTER = 13
const ARROW_RIGHT = 39
const ARROW_LEFT = 37
const ARROW_DOWN = 40
const ARROW_UP = 38

const keyMap = {
  ALT_S: 'alt'
}
const ignoreTags = ['select', 'textarea']

export class TextFieldEditorCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired
  }

  _isMounted = false

  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props)
    // console.log(props)
    //
    const { value } = this.props
    // let value
    // if (props.charPress) {
    //   value = formatter === 'number' ? Number(props.charPress) : props.charPress
    //   console.log(value)
    // }
    const state = {
      isEditing: false,
      focusSet: false,
      lastCellTabbed: false,
      rowIndex: props.rowIndex,
      textFieldVal: value || value === 0 ? value : ''
    }
    // if (formatter === 'number' && value === 0) {
    //   state.textFieldVal = 0
    // }

    this.state = state
    // this.emitter = new EventEmitter()
    // this.emitter.addListener('Tab', this.test)
  }

  componentDidMount() {
    this._isMounted = true
    this._isCancelAfterEndEventFired = false
  }

  afterGuiAttached() {
    const input = this.textField // props.eGridCell
    //
    if (input) {
      input.addEventListener('keydown', this.onKeyDown)
    }
    setTimeout(() => {
      if (input) {
        input.focus()
        // input.select()
        if (!this.props.charPress) {
          input.select()
        } else if (input.setSelectionRange) {
          input.setSelectionRange(1, 1)
        }
      }
    }, 0)
  }

  componentDidUpdate() {
    const input = this.textField // props.eGridCell
    // if (input) {
    //   input.addEventListener('keydown', this.onKeyDown)
    // }
    if (!this.edited && input) {
      //
      input.focus()
      input.select()
    }
  }

  componentWillUnmount() {
    //
    const input = this.textField // props.eGridCell
    this._isMounted = false
    if (input) {
      input.removeEventListener('keydown', this.onKeyDown)
    }
  }

  edited = false

  setEdited = once(() => (this.edited = true))

  onChange = event => {
    if (this._isMounted) {
      this.setState({
        focusSet: false,
        isEditing: true,
        lastCellTabbed: false,
        textFieldVal: event.target.value || ''
      })
    }
  }

  dispatchTab = (shiftKey, arrowKey) => {
    this.props.dispatch({
      type: 'GRID_TAB_ATTEMPT',
      payload: {
        ...this.props,
        shiftKey,
        arrowKey
      },
      meta: {
        form: this.props.form
      }
    })
    this.props.api.stopEditing()
  }

  onKeyDown = event => {
    if (event.persist) {
      return
    }
    const { onCellKeyDown } = this.props
    if (onCellKeyDown) {
      onCellKeyDown.call(this, event)
    } else {
      const { keyCode } = event
      if (this._isMounted) {
        const {
          target: { selectionStart, selectionEnd },
          target
        } = event
        if (keyCode === ENTER) {
          event.preventDefault()
          event.stopPropagation()
        } else if (keyCode === TAB) {
          event.preventDefault()
          event.stopPropagation()
          //
          this.dispatchTab(event.shiftKey)
        } else if (
          keyCode === ARROW_LEFT &&
          selectionStart === 0 &&
          selectionEnd === 0
        ) {
          this.dispatchTab(true, 'left')
        } else if (
          keyCode === ARROW_RIGHT &&
          selectionStart === selectionEnd &&
          target.value.length === selectionStart
        ) {
          this.dispatchTab(false, 'right')
        } else if (keyCode === ARROW_DOWN) {
          this.dispatchTab(event.shiftKey, 'down')
        } else if (keyCode === ARROW_UP) {
          this.dispatchTab(event.shiftKey, 'up')
        } else {
          this.setEdited()
        }
      }
    }
  }

  isCancelAfterEnd = p => {
    if (!this.props.modals && this.state.textFieldVal != null) {
      try {
        this._isCancelAfterEndFired = true
        const {
          data: {
            description,
            fileAssociationSearchType: { parentField, dataId }
          },
          form,
          propertyName
        } = this.props
        // debugger
        const filterType = dataId || description // ? dataId.replace('.', '-') : description
        this.props.dispatch(
          setTextFilterFieldAction({
            parentField,
            value: this.state.textFieldVal,
            form,
            filterType,
            propertyName
          })
        )
        // const newValue = await this.props.dispatch({
        //   type: 'TRY_CELL_CHANGED_REQUEST',
        //   meta: { form: this.props.form, thunk: true, reducer: 'Grid' },
        //   payload: {
        //     value: this.state.textFieldVal,
        //     propertyName: this.props.propertyName,
        //     field: this.props.column.colDef.field,
        //     rowIndex: this.props.node.childIndex,
        //     data: this.props.data,
        //     node: this.props.node
        //   }
        // })

        // if (newValue != null) {
        //   this.props.node.setDataValue(this.props.column.colId, newValue)
        // }

        return false
      } catch (e) {
        return true
      } finally {
        this._isCancelAfterEndFired = false
      }
    }
  }

  onBlur = e => {
    const { data } = this.props

    /* 
      allow for a blur callback if necessary,
      mainly for in case the box quantity has not been triggered
      by the isCancelAfterEndFired function. fixes #8571, see
      implmentation in Order/index ('quantityOrdered') -- SVE 10/5/2020
    */
    if (this.props.onBlur && typeof this.props.onBlur === 'function') {
      this.props.onBlur(e, data, this._isCancelAfterEndFired)
    }
  }

  showRequiredIcon = () => {
    const {
      data,
      value,
      valueFormatted,
      colDef: {
        cellEditorParams: {
          errorMessage,
          isRequired,
          requiredFn,
          requiredIf,
          requiredLessThan
        },
        field
      },
      rowData,
      rowIndex
    } = this.props

    const errorIcon = getErrorIcon(errorMessage)
    //
    // prob makes sense to send all the props into the fn...
    // also prob makes BETTER sense to not have 3 named ways of required
    // you could easily find the type and use it.. this way you can have
    // multiple avenues to pass in and all can be evaluated..
    // this is called polymorphism

    if (requiredFn && typeof requiredFn === 'function') {
      return requiredFn(data, this.props) ? errorIcon : null
    }

    if (isRequired) {
      return value || valueFormatted ? null : errorIcon
    }
    // why? just use a fn...
    if (requiredIf) {
      // console.log('requiredIf', rowData[rowIndex][field])
      if (
        Array.isArray(rowData) &&
        rowData[rowIndex] &&
        rowData[rowIndex][requiredIf] &&
        !rowData[rowIndex][field]
      ) {
        return errorIcon
      }
    }

    if (requiredLessThan) {
      if (
        Array.isArray(rowData) &&
        rowData[rowIndex] &&
        rowData[rowIndex][requiredLessThan]
      ) {
        if (
          toNumeral(rowData[rowIndex][field]) >
          toNumeral(rowData[rowIndex][requiredLessThan])
        ) {
          return errorIcon
        }
      }
    }

    return null
  }

  getValue() {
    return this.state.textFieldVal
  }

  // onFocus = () => {
  //   if (this.textField) {
  //
  //     this.textField.select()
  //   }
  // }

  render() {
    const {
      colDef: { field },
      form,
      rowIndex
    } = this.props

    // debugger
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          width: '100%'
        }}
      >
        <Input
          disableUnderline
          fullWidth
          // forwardRef={!formatter}
          id={`text-field-${form}-${field}-${rowIndex}`}
          value={this.state.textFieldVal}
          // disabled={this.isFieldDisabled()}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          style={{
            height: '100%',
            width: '100%'
          }}
          inputRef={el => (this.textField = el)}
          // inputRef={el => (this.textField = el)}
          inputProps={{
            style: {
              height: '100%',
              padding: 0,
              width: '100%',
              textAlign: 'left'
            }
          }}
        />
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(TextFieldEditorCell)
