import {
  all,
  take,
  put,
  call,
  cancel,
  fork,
  select,
  delay,
  actionChannel
} from 'redux-saga/effects'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { confirmationModal, warningModal } from 'modals/sagas'
import { api } from 'services'
import { createApiListener, destroyModal } from 'utils'
import { isAuthenticatedSelector } from 'auth/selectors'
import { isMobileSelector } from 'mobile/selectors'
import {
  LOGIN_COMPLETE,
  LOGOUT_USER,
  SUBMIT_SELECTED_CARD_TERMINAL
} from 'auth/constants'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import { showCardTerminalModal } from 'auth/sagas'
import { getIn } from 'immutable'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import {
  newsFeed,
  refreshCompanyMaster,
  resetFastClick,
  toggleFastClick,
  openScreenAttempt,
  toggleMail
} from './actions'
import {
  OPEN_EXTERNAL_URL,
  TRY_RESET_FAST_CLICK,
  SELECT_CARD_TERMINAL,
  TRY_REFRESH_COMPANY_MASTER,
  TRY_OPEN_SCREEN,
  TOGGLE_MAIL
} from './constants'

import { getFormSelector } from '../../ddiForm/utils'

export const toggleFastClickListener = createApiListener.bind(
  null,
  toggleFastClick,
  api.toggleFastClick
)

export const resetFastClickListener = createApiListener.bind(
  null,
  resetFastClick,
  api.resetFastClick
)

// export function* newsFeedListener() {
//   while (true) {
//     yield take(LOGIN_COMPLETE)
//     const task = yield call(newsFeedTask)
//     yield take(LOGOUT_USER)
//     yield cancel(task)
//   }
// }

export function* newsFeedListener() {
  while (true) {
    yield take(LOGIN_COMPLETE)
    const isMobile = yield select(isMobileSelector)
    if (!isMobile) {
      const task = yield fork(newsFeedTask)
      yield take(LOGOUT_USER)
      yield cancel(task)
    }
  }
}
export function* newsFeedTask() {
  let task
  let shouldNotRun
  while (true) {
    shouldNotRun = yield select(state => state.get('main').newsFeedDisabled)
    if (!shouldNotRun) {
      const isAuth = yield select(isAuthenticatedSelector)
      if (task) yield cancel(task)
      if (isAuth) {
        task = yield fork(newsFeedProcess)
      }
    }
    yield delay(2400000)
  }
}

export function* newsFeedProcess() {
  const { response, error } = yield call(api.newsFeed)
  if (response) {

    yield put(newsFeed.success(response))
  } else {
    yield put(newsFeed.failure(error))
  }
}

export function* tryResetFastClickListener() {
  while (true) {
    let action = yield take(TRY_RESET_FAST_CLICK)

    yield call(
      confirmationModal,
      'All changes to Fast Click Menu will be lost and the default restored. Continue?',
      'Reset To Default'
    )
    action = yield take([CONFIRMED, CANCELED])
    if (action.type === CONFIRMED) {
      yield put(resetFastClick.request())
    }
  }
}
export function* openExternalLinkListener() {
  while (true) {
    const action = yield take(OPEN_EXTERNAL_URL)
    const {
      payload: { url }
    } = action
    // console.log(url)
    yield call(window.open, url)
  }
}

export function* openSelectCardTerminalListener() {
  while (true) {
    const action = yield take(SELECT_CARD_TERMINAL)

    const { response, error } = yield call(api.getTerminals, { dataId: null })

    if (response && response.length) {
      yield fork(selectTerminalProcess, response, true)
    }
  }
}

export function* selectTerminalProcess(cardTerminals, onChange = false) {
  const id = yield call(showCardTerminalModal, onChange)
  yield take(SUBMIT_SELECTED_CARD_TERMINAL.SUCCESS)
  yield call(destroyModal, id)
}

export function* refreshCompanyMasterListener() {
  while (true) {
    yield take(TRY_REFRESH_COMPANY_MASTER)

    yield put(refreshCompanyMaster.request())

    const { response } = yield call(api.refreshCompanyMaster, true)

    if (response) {
      yield put(refreshCompanyMaster.success())
      yield call(
        warningModal,
        'Company Master has been refreshed.',
        'Attention!'
      )
    } else {
      yield put(refreshCompanyMaster.failure())
    }
  }
}

export function* screenOpenListener() {
  const channel = yield actionChannel(TRY_OPEN_SCREEN)
  while (true) {
    const action = yield take(channel)
    const {
      payload: { referrer }
    } = action
    let cancelled
    if (referrer) {
      const formState = yield select(getFormSelector(referrer))
      if (formState && formState.toJS) {
        const isEditing = formState.get('isEditing')
        if (isEditing) {
          yield put(masterActions.cancelEditAsync.try(referrer))
          cancelled = yield take([
            MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS,
            MASTER_CONSTANTS.CANCEL_EDIT.FAILURE
          ])
          // debugger
          if (cancelled.type === MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS) {
            yield put(openScreenAttempt(action.payload))
          }
        } else {
          yield put(openScreenAttempt(action.payload))
        }
      }
    } else {
      yield put(openScreenAttempt(action.payload))
    }
  }
}

export function* toggleMailProcess(action) {
  const { mailEnabled } = action.payload

  yield console.log(mailEnabled)
  const args = { mode: mailEnabled ? 'start' : 'stop' }
  const { response, error } = yield call(api.toggleMail, args)
  if (response) {
    yield put(toggleMail.success(response))
  } else {
    yield put(toggleMail.failure(error))
  }
}

export function* toggleMailListener() {
  while (true) {
    const action = yield take(TOGGLE_MAIL.TRY)
    // const auth = yield select(state => state.get('auth'))
    yield fork(toggleMailProcess, action)

    // debugger
  }
}
export default function* appSagas() {
  yield all([
    fork(newsFeedListener),
    fork(toggleFastClickListener),
    fork(resetFastClickListener),
    fork(tryResetFastClickListener),
    fork(openExternalLinkListener),
    fork(openSelectCardTerminalListener),
    fork(refreshCompanyMasterListener),
    fork(screenOpenListener),
    fork(toggleMailListener)
  ])
}
