/* eslint react/prefer-stateless-function: 0 */
import React from 'react'
import ActivityTooltipTemplate from 'pages/Dashboard/components/Calendar/components/ActivityTooltipTemplate'
import { withTooltip } from 'hoc'
import EventColorCell from 'grid/EventColorCell'
import {
  getActivityTooltip as getActivityTooltipAction,
  lockActivity as lockActivityAction
} from 'modals/EventModal/actions'

export default withTooltip({
  anchorToGridCellText: true,
  component: ActivityTooltipTemplate,
  keyToActionMap: {
    m: args => lockActivityAction(args.form, { dataId: args.dataId })
  },
  networkRequestPromise: getActivityTooltipAction,
  popperOptions: {
    modifiers: { preventOverflow: { boundariesElement: 'window' } }
  },
  position: 'right-end',
  sensitivity: 100,
  style: { width: 400 },
  timeout: 1000,
  inputComponent: false,
  requestArgs() {
    return { dataId: this.props.data.dataId, form: 'calendar' }
  }
})(EventColorCell)
