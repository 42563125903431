import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Danger from 'components/Danger'
import { fromJS, getIn, formatDate } from 'utils'
import ConnectedLabel from './ConnectedLabel'
import { withDDIForm } from './DDIFormContext'

const Label = props => (
  <ConnectedLabel {...props} _ddiForm={props._ddiForm} />
)

export default withDDIForm(Label)
