import React, { Component } from 'react'
import Measure from 'react-measure'

const withDimensions = (style = { width: '100%' }) => WrappedComponent => {
  class WithDimensions extends Component {
    constructor(...args) {
      super(...args)
      this.state = {
        dimensions: {
          width: -1,
          height: -1
        }
      }
    }

    onResize = contentRect => this.setState({ dimensions: contentRect.bounds })

    render() {
      const { forwardedRef, ...rest } = this.props
      return (
        <Measure bounds onResize={this.onResize}>
          {({ measureRef }) => {
            const height = this.state.dimensions?.height
            return (
              <div ref={measureRef} style={style}>
                <WrappedComponent
                  ref={forwardedRef}
                  {...rest}
                  height={height}
                  dimensions={this.state.dimensions}
                />
              </div>
            )
          }}
        </Measure>
      )
    }
  }

  return React.forwardRef((props, ref) => (
    <WithDimensions {...props} forwardedRef={ref} />
  ))
}

export default withDimensions
