const EDITABLE_GRID = '@@EDITABLE_GRID@@/'

/* grid change stuff */
export const ON_PRIMARY_GRID_DATA_POSTED = `${EDITABLE_GRID}ON_PRIMARY_GRID_DATA_POSTED`
export const ON_PRIMARY_GRID_DATA_VALIDATED = `${EDITABLE_GRID}ON_PRIMARY_GRID_DATA_VALIDATED`
export const DELETE_GRID_ROW = `${EDITABLE_GRID}DELETE_GRID_ROW`
export const UPDATE_GRID_CELL_DATA = `${EDITABLE_GRID}UPDATE_GRID_CELL_DATA`
export const SET_REQUIRED_GRID_COLS = `${EDITABLE_GRID}SET_REQUIRED_GRID_COLS`
export const INITIALIZE_EDITABLE_GRID = `${EDITABLE_GRID}INITIALIZE_EDITABLE_GRID`
export const VALIDATE_GRID_DATA = `${EDITABLE_GRID}VALIDATE_GRID_DATA`
export const SET_FOCUSED_CELL = `${EDITABLE_GRID}SET_FOCUSED_CELL`
export const ADDITIONAL_VALIDATION_SUCCESS = `${EDITABLE_GRID}ADDITIONAL_VALIDATION_SUCCESS`
export const CLEAR_GRID_ROW = `${EDITABLE_GRID}CLEAR_GRID_ROW`

export const RESET_GRID_INDEX_SEARCH = `${EDITABLE_GRID}RESET_GRID_INDEX_SEARCH`

export const REQUEST_INSERT_GRID_ROW = `${EDITABLE_GRID}REQUEST_INSERT_GRID_ROW`
export const INSERT_GRID_ROW = `${EDITABLE_GRID}INSERT_GRID_ROW`

export const SAVED_SORTED_ROW_DATA = `${EDITABLE_GRID}SAVED_SORTED_ROW_DATA`
export const SET_BLANK_ROW_ID = `${EDITABLE_GRID}SET_BLANK_ROW_ID`
export const ENSURE_ROW_IDS_SET = `${EDITABLE_GRID}ENSURE_ROW_IDS_SET`

export const NOTIFY_EXIT_TEXT_CELL = `${EDITABLE_GRID}NOTIFY_EXIT_TEXT_CELL`

export const OPEN_PART_NUMBERS_NOTES_EDITOR = `${EDITABLE_GRID}OPEN_PART_NUMBERS_NOTES_EDITOR`

