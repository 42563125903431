export default function mockFetchProcess(route, options) {
  let timer
  let response
  if (options) {
    timer = options.timer
    response = options.response
  } else {
    timer = Math.floor(Math.random() * 500 + 500)
    response = {}
  }
  return new Promise(resolve => setTimeout(() => resolve({ response }), timer))
}
