import { Iterable } from 'immutable'
import { isEqualWith } from 'lodash'

/**
 * customizer function used in conjunction with plainDeepEqual
 * @param {object} obj - object to compare
 * @param {object} other - object for comparision
 * @returns {Boolean|undefined} can return true/false/undefined
*/
const customizer = (obj, other) => {
  if (obj === other) return true
  if ((obj == null || obj === '' || obj === false) &&
    (other == null || other === '' || other === false)) return true

  if (Iterable.isIterable(obj) && Iterable.isIterable(other)) {
    return obj.count() === other.count() && obj.every((value, key) =>
      isEqualWith(value, other.get(key), customizer)
    )
  }

  return undefined
}

export default customizer
