import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ConnectedAddress from './ConnectedAddress'
import { withDDIForm } from './DDIFormContext'

class AddressLabel extends Component {
  componentDidMount() {
    this.props.registerField({
      propertyName: this.props.propertyName
    })
  }

  render() {
    return <ConnectedAddress {...this.props} />
  }
}
export default withDDIForm(AddressLabel)
