import { take, fork, call, select, put, delay } from 'redux-saga/effects'
import { api } from 'services'
import { setField } from 'ddiForm/actions'
import { BLUR, CLEAR_SEARCH } from 'components/Search/IndexSearch/constants'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, fromJS } from 'utils'
import eventModalSagas from 'modals/EventModal/sagas'
import * as EVENT_MODAL_CONSTANTS from 'modals/EventModal/constants'
import { SET_FIELD, OPEN_SCREEN, REGISTER_FIELD } from 'ddiForm/constants'

import * as CONSTANTS from './constants'
import * as actions from './actions'

export function* getCalendarMetaProcess(form) {
  yield put(actions.getCalendarMeta.request(form))
  const { response, error } = yield call(api.addDashboardItem, {
    dataId: 'Calendar',
    isMobile: true
  })

  if (response) {
    yield put(actions.getCalendarMeta.success(response, form))
  } else {
    yield put(actions.getCalendarMeta.failure(error, form))
  }
}

export function* getCalendarMetaListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_CALENDAR_META.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(getCalendarMetaProcess, form)
    }
  }
}

export function* refreshCalendarProcess(form, propertyName = '') {
  if (propertyName === 'customerId') {
    yield put(setField(form, 'contactId', ''))
  }

  const formState = yield select(getFormSelector(form))
  const activeDate = getIn(formState, 'activeDate') || new Date()
  const customerId = getIn(formState, 'fields.customerId.value') || null
  const contactId = getIn(formState, 'fields.contactId.value') || null

  const filterType = getIn(formState, 'fields.filterType.value') || 'U'
  const tasksType = getIn(formState, 'fields.tasksType.value') || 'C'

  const userIds = getIn(formState, 'fields.userIds.value') || ''
  const prevUserIds = getIn(formState, 'fields.userIds.prevValue') || ''

  const eventTypes = getIn(formState, 'fields.eventTypes.value') || ''
  const prevEventTypes = getIn(formState, 'fields.eventTypes.prevValue') || ''

  if (filterType === 'C' && !customerId) {
    yield put(actions.clearActivitiesData(form))
    return
  }

  // debugger

  if (
    (propertyName === 'userIds' && userIds === prevUserIds) ||
    (propertyName === 'eventTypes' && eventTypes === prevEventTypes)
  ) {
    return
  }

  yield put(actions.refreshCalendar.request(form))

  const { response, error } = yield call(api.refreshActivity, {
    activeDate,
    eventTypes,
    userIds: filterType === 'U' ? userIds : '',
    mode: 'M',
    tasksType,
    customerId: filterType === 'C' && customerId ? customerId : null,
    contactId: filterType === 'C' && customerId ? contactId : null
  })

  // debugger
  if (response) {
    yield put(actions.refreshCalendar.success(response, form))
  } else {
    yield put(actions.refreshCalendar.failure(error, form))
  }
}

export function* getCustomerContactsProcess(form) {
  const formState = yield select(getFormSelector(form))
  const customerId = getIn(formState, 'fields.customerId.value') || null

  if (!customerId) {
    return
  }

  yield put(actions.getCustomerContacts.request(form))

  const { response, error } = yield call(api.read, {
    name: 'customerMaster',
    dataId: customerId,
    groupNames: ['contacts']
  })
  // debugger

  if (response) {
    yield put(
      actions.getCustomerContacts.success(
        {
          contacts: response?.contacts?.contacts || []
        },
        form
      )
    )
  } else {
    yield put(actions.getCustomerContacts.failure(error, form))
  }
}

export function* refreshCalendarListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value },
      type
    } = yield take([
      SET_FIELD,
      CONSTANTS.CHANGE_ACTIVE_DATE,
      EVENT_MODAL_CONSTANTS.SAVE_ACTIVITY.SUCCESS,
      EVENT_MODAL_CONSTANTS.DELETE_ACTIVITY.SUCCESS,
      BLUR,
      CLEAR_SEARCH
    ])

    // debugger
    const propTriggersRefresh = [
      'userIds',
      'eventTypes',
      'customerId',
      'filterType',
      'contactId',
      'tasksType'
    ]

    if (form === formListener && type === SET_FIELD) {
      if (propertyName && propTriggersRefresh.includes(propertyName)) {
        yield fork(refreshCalendarProcess, form, propertyName)
      }
    }

    if (
      form === formListener &&
      type === CLEAR_SEARCH &&
      (propertyName === 'customerId' || propertyName === 'contactId')
    ) {
      yield fork(refreshCalendarProcess, form, propertyName)
    }

    if (
      form === formListener &&
      type === BLUR &&
      !value &&
      (propertyName === 'customerId' || propertyName === 'contactId')
    ) {
      yield fork(refreshCalendarProcess, form, propertyName)
    }

    if (
      form === formListener &&
      (type === CONSTANTS.CHANGE_ACTIVE_DATE ||
        type === EVENT_MODAL_CONSTANTS.SAVE_ACTIVITY.SUCCESS ||
        type === EVENT_MODAL_CONSTANTS.DELETE_ACTIVITY.SUCCESS)
    ) {
      yield fork(refreshCalendarProcess, form, '')
    }
  }
}

export function* openScreenListener(formListener) {
  const mobileGlobal = yield select(state =>
    getIn(state, 'ddiForm.mobileGlobal')
  ) || fromJS({})
  const globalCustomer =
    getIn(mobileGlobal, 'customer') || fromJS({ id: null, description: null })
  // debugger
  const customerId = globalCustomer.get('id') || null
  const customerDescription = globalCustomer.get('description') || null
  if (customerId) {
    yield take(act => {
      return (
        act.type === REGISTER_FIELD &&
        act?.payload?.propertyName === 'filterType'
      )
    })
    yield put(setField(formListener, 'filterType', 'C'))
    yield put({
      type: SET_FIELD,
      meta: {
        form: formListener,
        customer: customerId,
        description: customerDescription
      },
      payload: {
        propertyName: 'customerId',
        value: customerId,
        results: { description: customerDescription }
      }
    })
  }
}

export function* taskCompletedTask(form, id) {
  if (!id) {
    return
  }
  const { response, error } = yield call(api.taskCompleted, { id })
  yield put(actions.taskCompleted.request(form))

  if (response) {
    yield put(actions.taskCompleted.success(response, form))
  } else {
    yield put(actions.taskCompleted.failure(error, form))
  }
}

export function* taskCompletedListener(formListener) {
  while (true) {
    const {
      payload: { id },
      meta: { form }
    } = yield take(CONSTANTS.TASK_COMPLETED.TRY)

    if (form === formListener) {
      yield fork(taskCompletedTask, form, id)
    }
  }
}

export default function* mobileCalendarSagas(form) {
  yield fork(getCalendarMetaListener, form)
  yield fork(refreshCalendarListener, form)
  yield fork(eventModalSagas, form)
  yield put(actions.getCalendarMeta.try('calendar'))
  yield fork(openScreenListener, form)
  yield fork(taskCompletedListener, form)
}
