/**
 * returns an object with the authorization headers for API options
 * @param {string} token - auth token
 * @returns {object} formatted for API auth headers
 */
const createAuthHeader = () => ({
  'Content-Type': 'application/json',
  authorization: null
})

export default createAuthHeader
