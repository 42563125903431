import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { openScreen } from 'pages/Main/actions'
import { connect } from 'react-redux'
/* eslint-disable react/no-multi-comp */
class GridCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
  }
  onClick = e => {
    e.preventDefault()

    this.props.onCellSelected({
      api: this.props.api,
      columnApi: this.props.columnApi,
      data: this.props.data,
      node: this.props.node,
      rowIndex: this.props.rowIndex
    })
  }
  render() {
    // console.log(this)
    return (
      <div>
        <a onClick={this.onClick}>
          <strong>{this.props.value}</strong>
        </a>
      </div>
    )
  }
}
class GridCellWrapper extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
  }
  constructor(...args) {
    super(...args)
    // debugger
    this.GridCell = this.props.withTooltip(GridCell)
  }
  render() {
    //
    return React.createElement(this.GridCell, this.props)
  }
}

export default connect(null, null, null, { forwardRef: true })(GridCellWrapper)
