import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import MobileScreen from 'mobile/components/MobileScreen'

const searchStyle = { margin: '0 30px', width: 150 }

const SalesOpportunitiesSkeleton = () => {

  return (
    <MobileScreen
      title="Sales Opportunities"
      titleIcon="monetization_on"
    >

      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: 20 }}>
        <div style={searchStyle}>
          <Skeleton variant="text" width="50" height="100%" />
          <Skeleton animation="wave" varint="rect" width="100" />

        </div>
        <div style={searchStyle}>
          <Skeleton variant="text" width="50" height="100%" />
          <Skeleton animation="wave" variant="rect" width="100" />
        </div>

        <div style={searchStyle}>
          <Skeleton variant="text" width="50" height="100%" />
          <Skeleton animation="wave" varint="rect" width="100" />

        </div>

      </div>

      <div style={{ width: '100%' }}>
        <Skeleton animation="wave" variant="rect" width="100%" height="250" />
      </div>

    </MobileScreen>
  )
}

export default SalesOpportunitiesSkeleton