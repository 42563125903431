import React, { Component, createElement } from 'react'
import { debounce as db } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
// import { deepEqual, isGrid, getIn, plainGetIn } from 'utils'
import getIn from 'utils/getIn'
import { BASE_INFORM_URL } from 'services/constants'
import * as CONSTANTS from './constants'
import { isDisabled } from './utils'

const loadingIndicator = `${BASE_INFORM_URL}/resources/text-loading.gif`

const propsToNotUpdateFor = [
  // '_ddiForm',
  'dispatch',
  'dataId',
  'blur',
  'change',
  'meta',
  'onChange',
  'onFocus',
  'focus',
  'getFormState',
  // 'propertyChange',
  'setField',
  'style'
]
/* eslint-disable react/prefer-stateless-function */

class ConnectedButton extends Component {
  render() {
    const {
      buttonRef,
      onClick,
      disabled,
      style,
      variant,
      autoFocus,
      size,
      children,
      // onDoubleClick
      ...rest
    } = this.props

    return (
      <Button
        onClick={onClick}
        variant={variant}
        disabled={disabled}
        style={style}
        buttonRef={buttonRef}
        autoFocus={autoFocus}
        size={size}
      >
        {children}
      </Button>
    )
  }
}
ConnectedButton.propTypes = {
  actionName: PropTypes.string,
  size: PropTypes.string
}

ConnectedButton.defaultProps = {
  actionName: null,
  initialValue: null,
  forwardRef: false,
  size: 'large'
}

const connector = connect(
  (state, ownProps) => {
    let {
      actions,
      actionName,
      onClick,
      debounceTime = 200,
      args = {},
      getFormState,
      dispatch,
      form
    } = ownProps
    const formState = getFormState(state)

    onClick =
      onClick ||
      function $onClick(event, ...rest) {
        console.log(ownProps)
        if (event && event.persist) {
          event.persist()
        }
        if (!actionName.includes('.')) {
          let b = getIn(actions, actionName)

          if (b) {
            return dispatch(b(form, { ...rest, ...args }))
          }
          b = getIn(ownProps, actionName)
          if (b) return b({ ...rest, ...args })
        } else {
          const act = getIn(actions, actionName)(event, rest)
          // console.log(act)
          if (act) {
            return dispatch(act)
          }
        }
        return false
      }
    onClick = db(onClick, debounceTime, { leading: true })
    let label
    if (typeof ownProps.label === 'function') {
      label = ownProps.label(formState)
    } else {
      ;({ label } = ownProps)
    }

    const props = {
      _value: ownProps.value, // save value passed in (for checkboxes)
      onClick,
      disabled: isDisabled(ownProps, {}, formState),
      label
    }

    return props
  },
  undefined,
  undefined,
  { forwardRef: true }
)
export default connector(ConnectedButton)
