import React from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { noop } from 'utils'
import { Icon, IconButton } from '@material-ui/core'
import { errorRed } from 'theme/colors'
import { deleteEmailFaxItem } from '../actions'

const DeleteEmailFaxLogButton = ({ value, colDef }) => {
  const dispatch = useDispatch()
  const onAfterDeleteRow = colDef?.cellRendererParams?.onAfterDeleteRow || noop
  const form = colDef?.cellRendererParams?.form

  console.log('DeleteEmailFaxLogButton', colDef, value)

  const deleteEmailFaxLogItem = debounce(e => {
    dispatch(
      deleteEmailFaxItem.try(form, {
        dataId: value,
        onAfterDeleteRow
      })
    )
  }, 300)

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <IconButton onClick={deleteEmailFaxLogItem}>
        <Icon style={{ color: errorRed }}>delete_forever</Icon>
      </IconButton>
    </div>
  )
}

export default DeleteEmailFaxLogButton
