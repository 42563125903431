import { createAction, createAsyncAction } from 'utils'
import { OPEN_SCREEN, TRY_OPEN_SCREEN } from './constants'

export const openScreenAttempt = payload =>
  createAction(OPEN_SCREEN, payload, null, false, {
    withAccess: true,
    apiRequest: true,
    thunk: true
  })
export const openScreen = (...args) => {
  return createAction(TRY_OPEN_SCREEN, ...args)
}
