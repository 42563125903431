import { actionChannel, take, fork, put } from 'redux-saga/effects'
import { warningModal } from 'modals/sagas'
import { /* submitLogoutUser, */ authenticationError } from 'auth/actions'

export const err = (message = { message: 'Authentication Failed' }) =>
  put(authenticationError(message))
const badMsg =
  'Connection to the database has been lost.  Check that this machine has an active network connection and try again.'

export function* apiErrorHandlerListener() {
  // eslint-disable-line import/prefer-default-export
  const actionChan = yield actionChannel(act => act.type.endsWith('_FAILURE'))

  while (true) {
    const action = yield take(actionChan)
    // debugger
    if (action?.payload?.message === 'Failed to fetch') {
      yield err({
        message: 'Failed to fetch from the server.'
      })
    }
    const errors = {
      // 401: () => put(logoutUser('Authentication failed.', 'Error')),
      401: err,
      495: err({ message: 'Your session has timed out.' }),
      // eslint-disable-next-line no-loop-func
      *496() {
        yield fork(
          warningModal,
          action.payload.message,
          action.payload?.modalTitle
        )
      },
      500: err
    }
    if (
      action?.payload?.status &&
      typeof errors[action.payload.status] === 'function'
    ) {
      yield errors[action.payload.status]({ message: action?.payload?.message })
    }
  }
}
