import createAsyncRequestTypes from 'utils/createAsyncRequestTypes'

const auth = '@@auth/'

// async triples.. => TOKEN.REQUEST/ TOKEN.SUCCESS /TOKEN.FAILURE
export const TOKEN = createAsyncRequestTypes(`${auth}TOKEN`)
export const CREATE_SYSTEM_USER = createAsyncRequestTypes(
  `${auth}CREATE_SYSTEM_USER`
)
export const SUBMIT_SELECTED_BRANCH = createAsyncRequestTypes(
  `${auth}SUBMIT_SELECTED_BRANCH`
)
export const SUBMIT_SELECTED_REGISTER = createAsyncRequestTypes(
  `${auth}SUBMIT_SELECTED_REGISTER`
)
export const SUBMIT_LOGOUT_USER = createAsyncRequestTypes(
  `${auth}SUBMIT_LOGOUT_USER`
)
export const HEARTBEAT = createAsyncRequestTypes(`${auth}HEARTBEAT`)
export const REQUEST_ACCESS = createAsyncRequestTypes(`${auth}REQUEST_ACCESS`)

export const CLEAR_SELECTED_BRANCH = `${auth}CLEAR_SELECTED_BRANCH`
export const LOGIN_REQUEST = `${auth}LOGIN_REQUEST`
export const SELECT_BRANCH = `${auth}SELECT_BRANCH`
export const LOGOUT_USER = `${auth}LOGOUT_USER`
export const LOGIN_COMPLETE = `${auth}LOGIN_COMPLETE`
export const SET_POS_REGISTER_NUM = `${auth}SET_POS_REGISTER_NUM`
export const CHECK_TOKEN_EXPIRATION = `${auth}CHECK_TOKEN_EXPIRATION`
export const NO_TOKEN = `${auth}NO_TOKEN`
export const REMOVE_TOKEN = `${auth}REMOVE_TOKEN`
export const REVOKE_ONE_TIME_ACCESS = `${auth}REVOKE_ONE_TIME_ACCESS`
export const AUTHENTICATION_ERROR = `${auth}AUTHENTICATION_ERROR`
export const TRY_REQUEST_ACCESS = `${auth}TRY_REQUEST_ACCESS`
export const ACCESS_GRANTED = `${auth}ACCESS_GRANTED`
export const ACCESS_DENIED = `${auth}ACCESS_DENIED`

export const SUBMIT_SELECTED_CARD_TERMINAL = createAsyncRequestTypes(
  `${auth}SUBMIT_SELECTED_CARD_TERMINAL`
)
export const SET_CARD_TERMINAL = `${auth}/SET_CARD_TERMINAL`
export const CANCEL_BRANCH_SELECTION = `${auth}CANCEL_BRANCH_SELECTION`
export const FORGOT_PASSWORD = createAsyncRequestTypes(`${auth}FORGOT_PASSWORD`)
export const CLEAR_STATUS = `${auth}/CLEAR_STATUS`
export const SHOW_CHANGE_PASSWORD_MODAL = `${auth}/SHOW_CHANGE_PASSWORD_MODAL`
export const FORGOT_USER_NAME = createAsyncRequestTypes(
  `${auth}FORGOT_USER_NAME`
)
export const SHOW_CHANGE_EMAIL_MODAL = `${auth}SHOW_CHANGE_EMAIL_MODAL`

export const LOGIN_OTP = createAsyncRequestTypes(`${auth}/LOGIN_OTP`)
export const STOP_AUTH = `${auth}STOP_AUTH`
