import React, { Component, forwardRef } from 'react'
import { getDisplayName, deepEqual } from 'utils'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import shouldUpdate from 'hoc/shouldUpdate'
import hoistStatics from 'hoist-non-react-statics'
import memoize from 'memoize-one'
import { withDDIForm } from './DDIFormContext'

const propsToNotUpdateFor = ['getFormState']

/* eslint-disable react/no-multi-comp */

export default (selectors = {}) => WrappedComponent => {
  Object.keys(selectors).forEach(selector => {
    selectors[selector] = memoize(selectors[selector])
  })
  // upddate to only memoize if fn's...
  class WithContext extends Component {
    static displayName = `withContext(${getDisplayName(WrappedComponent)})`

    constructor(...args) {
      super(...args)
      this.keys = Object.keys(selectors || this.props._ddiForm)
    }

    shouldComponentUpdate(nextProps) {
      const ret = Object.keys(nextProps).some(prop => {
        // console.log(prop)
        /* const ret = */ return (
          !propsToNotUpdateFor.includes(prop) &&
          !deepEqual(this.props[prop], nextProps[prop])
        )
      })

      // console.log(ret)
      return ret
    }

    render() {
      // debugger
      const { _ddiForm, forwardedRef, ...rest } = this.props // eslint-disable-line no-unused-vars
      return (
        <WrappedComponent {...rest} _ddiForm={_ddiForm} ref={forwardedRef} />
      )
    }
  }

  const connector = connect(
    (state, ownProps) => {
      const { getFormState } = ownProps
      if (getFormState) {
        let ret = {}

        let formState = getFormState(state)

        formState =
          formState && formState.toJS
            ? formState
            : Map({ fields: {}, values: {} })
        const keys = Object.keys(selectors)

        ret = !keys.length
          ? formState
          : keys.reduce((acc, next) => {
              if (typeof selectors[next] === 'function') {
                acc[next] = selectors[next](formState, ownProps)
              } else {
                acc[next] = formState[next]
              }
              return acc
            }, {})

        return ret
      }
      return {}
    },
    undefined,
    undefined,
    { forwardRef: true }
  )

  const ConnectedContext = hoistStatics(
    connector(WithContext),
    WrappedComponent
  )

  const ContextWrapper = forwardRef((props, ref) => (
    <ConnectedContext {...props} forwardedRef={ref} />
  ))
  return withDDIForm(ContextWrapper)
}
