import { List, Map } from 'immutable'
import 'isomorphic-fetch'

// export const DEFAULT_CURRENCY_FORMAT = '$0,0.00'

/* individual functions */
// export fetchProcess from './fetchProcess'
export { default as fetchProcess } from './fetchProcess'
export { default as withinRange } from './withinRange'
export { default as mockFetchProcess } from './mockFetchProcess'
export { default as isMultiInstance } from './isMultiInstance'
export accessPathMapper from './accessPathMapper'
export createApiOptions from './createApiOptions'
// export callFetch from './callFetch'
export createAction from './createAction'
export createApiListener from './createApiListener'

export createAsyncAction from './createAsyncAction'
export createAsyncRequestTypes from './createAsyncRequestTypes'
export createAuthHeader from './createAuthHeader'
export createDangerousMarkup from './createDangerousMarkup'
export createReducer from './createReducer'
export customizer from './customizer'
export dataURItoBlob from './dataURItoBlob'
export dateFilterComparator from './dateFilterComparator'
export deepEqual from './deepEqual'
export deleteIn from './deleteIn'
export destroyModal from './destroyModal'
export detectIE from './detectIE'
export difference from './difference'
export encodeString from './encodeString'
export formatDate from './formatDate'
export formatDateFields from './formatDateFields'
export formatDollarFields from './formatDollarFields'
export formatNumber, { DEFAULT_CURRENCY_FORMAT } from './formatNumber'
export fromJS from './fromJS'
export generateTabs from './generateTabs'
export getContainerDimensions from './getContainerDimensions'
export getDisplayName from './getDisplayName'
export getBaseGridContextMenuItems from './getBaseGridContextMenuItems'
export getField from './getField'
export { default as getIn } from './getIn'
export getMeta from './getMeta'
export getPrevNextGridNumber from './getPrevNextGridNumber'
export getSelectedRowHelper from './getSelectedRowHelper'
export getValue from './getValue'
export is from './is'
export isAny from './isAny'
export isForeignCountry from './isForeignCountry'
export isGrid from './isGrid'
export isNumber from './isNumber'
export isTokenExpired from './isTokenExpired'
export layoutFlex from './layoutFlex'
export makeRowDataUnique from './makeRowDataUnique'
export mergeLists from './mergeLists'
export noDataMessage from './noDataMessage'
export noop from './noop'
export numToDollar from './numToDollar'
export parseNumber from './parseNumber'
export plainDeepEqual from './plainDeepEqual'
export { default as plainGetIn } from './plainGetIn'
export plainJScustomizer from './plainJScustomizer'
export removeObjectKeys from './removeObjectKeys'
export replaceCarriageReturns from './replaceCarriageReturns'
export requestProcessingMessage from './requestProcessingMessage'
export requireAuthentication from './requireAuthentication'
export setIn from './setIn'
export shallowCompare from './shallowCompare'
export size from './size'
export some from './some'
export splice from './splice'
export stringOrNull from './stringOrNull'
export strToUpperCase from './strToUpperCase'
export timestampFormatter from './timestampFormatter'
export toCamelCase from './toCamelCase'
export toUpper from './toUpper'
export toLower from './toLower'
export toDate from './toDate'
export toJSON from './toJSON'
export toNumeral from './toNumeral'
export undefinedArrayMerge from './undefinedArrayMerge'
export getRgb from './getRgb'
export mapColumnHeaders from './mapColumnHeaders'
export createAsyncFormAction from './createAsyncFormAction'
export isComponent from './isComponent'
// immutable stuff
export const empty = Map()
export const emptyList = List()
export runApiForTest from './runApiForTest'
export toJS from './toJS'
export isReactComponent from './isReactComponent'
export trimNonWord from './trimNonWord'
export usePrevious from './usePrevious'
