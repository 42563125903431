import { createAsyncRequestTypes } from 'utils'

export const dashboard = '@@dashboard/'
export const INIT = `${dashboard}INIT`
export const ADD_TILE = createAsyncRequestTypes(`${dashboard}ADD_TILE`)
export const REMOVE_TILE = createAsyncRequestTypes(`${dashboard}REMOVE_TILE`)
export const ON_ADD_TILE = `${dashboard}ON_ADD_TILE`
export const ON_REMOVE_TILE = `${dashboard}ON_REMOVE_TILE`
export const STORE_LAYOUTS = `${dashboard}STORE_LAYOUTS`
export const HIDE_TIPS = createAsyncRequestTypes(`${dashboard}HIDE_TIPS`)
export const SHOW_TIPS_MODAL = `${dashboard}SHOW_TIPS_MODAL`
export const TOGGLE_CHECK = `${dashboard}TOGGLE_CHECK`
export const ACCESS_REQUEST_CANCELED = `${dashboard}ACCESS_REQUEST_CANCELED`
export const TOGGLE_TILE = createAsyncRequestTypes(`${dashboard}TOGGLE_TILE`)
export const TILE_MOUNTED = `${dashboard}TILE_MOUNTED`
export const TILE_UNMOUNTED = `${dashboard}TILE_UNMOUNTED`

/* dashboard details */
export const DASHBOARD_DETAILS_OPEN = createAsyncRequestTypes(
  `${dashboard}DASHBOARD_DETAILS_OPEN`
)
