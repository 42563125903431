import {
  createReducer,
  getIn,
  setIn,
  deleteIn,
  mapColumnHeaders,
  fromJS
} from 'utils'

import * as DDICONSTANTS from 'ddiForm/constants'
import ClearFilterCell from 'components/Search/SearchBase/components/ClearFilterCell'
import FieldSwitcher from 'components/Search/SearchBase/components/FieldSwitcher'
import * as CONSTANTS from './constants'

export const clearRecordAndDescription = state => {
  let result = state
  result = deleteIn(result, 'recordName')
  result = deleteIn(result, 'description')
  result = deleteIn(result, 'creditStatus')
  result = deleteIn(result, 'creditStatusColor')
  result = setIn(result, 'isSet', false)
  return result
}

const setPartialMatchResults = (state, partialMatchResults) => {
  let result = state
  // result = clearRecordAndDescription(result)
  const { columnHeaders, results: rowData, ...meta } = partialMatchResults
  const columnDefs = columnHeaders ? columnHeaders.map(mapColumnHeaders) : []
  const partialMatchGrid = {
    columnDefs,
    meta,
    rowData: rowData || []
  }
  result = setIn(result, 'grid', fromJS(partialMatchGrid))
  return result
}

export const behaviors = {
  [CONSTANTS.CLOSE_SEARCH]: state => {
    // debugger
    let result = state
    result = deleteIn(result, 'grid')
    const isSet = getIn(result, 'isSet')
    if (!isSet) {
      // result = clearRecordAndDescription(result)
      // result = setIn(result, 'value', '') this might need change
      result = setIn(result, 'isFiltered', false)
    }
    result = setIn(result, 'isFetching', false)
    // debugger
    return result
  },
  [CONSTANTS.PARTIAL_MATCH_SEARCH.REQUEST]: (
    state,
    { payload: { searchAll, keyword } }
  ) => {
    let result = state
    if (!searchAll) {
      clearRecordAndDescription(state)
    } else {
      result = setIn(result, 'value', keyword)
    }
    result = setIn(result, 'isFetching', true)
    result = setIn(result, 'isSet', false)
    return result
  },

  [CONSTANTS.PARTIAL_MATCH_SEARCH.SUCCESS]: (
    state,
    { payload: { grid, searchAll } }
  ) => {
    let result = state // setPartialMatchResults(state, partialMatchResults)
    // debugger
    result = setIn(result, 'grid', grid)
    if (!searchAll) {
      result = clearRecordAndDescription(result)
    }
    result = deleteIn(result, 'isFetching')
    return result
  },
  [CONSTANTS.EXACT_MATCH_SEARCH.REQUEST]: (state, { payload: { keyword } }) => {
    // let result = clearRecordAndDescription(state)
    let result = state
    result = setIn(result, 'isFetching', true)
    result = deleteIn(result, 'grid')
    result = setIn(result, 'value', keyword)
    return result
  },
  [CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS]: (
    state,
    { payload: { exactMatchResults, partialMatchResults } }
  ) => {
    // console.log(state.toJS(), meh)
    // let result = state
    // debugger
    let result = clearRecordAndDescription(state)
    // result = setIn(result, fromJS({ description, value, recordName }))
    if (exactMatchResults) {
      result = setIn(result, 'description', exactMatchResults.description)
      result = setIn(result, 'value', exactMatchResults.name)
      result = setIn(result, 'recordName', exactMatchResults.recordName)
      result = setIn(result, 'isSet', true)
      result = deleteIn(result, 'grid')
      if (exactMatchResults.moreInfo) {
        Object.keys(exactMatchResults.moreInfo).forEach(prop => {
          result = setIn(result, prop, fromJS(exactMatchResults.moreInfo[prop]))
        })
        // result = setIn(result, fromJS)
      }
    } else {
      result = setPartialMatchResults(state, partialMatchResults)
      result = setIn(result, 'value', '')
    }
    result = deleteIn(result, 'isFetching')

    return result
  },
  [CONSTANTS.EXACT_MATCH_SEARCH.FAILURE]: state => {
    // let result = state
    let result = clearRecordAndDescription(state)
    result = setIn(result, 'isFetching', false)
    return result
  },
  [CONSTANTS.POPULATE_FIELD]: (
    state,
    { payload: { description, indexSearchType, value } }
  ) => {
    let result = state
    result = setIn(result, 'description', description)
    result = setIn(result, 'value', value)
    result = setIn(result, 'indexSearchType', indexSearchType)
    return result
  },
  [CONSTANTS.FIND_PREV.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isFetching', true)
    return result
  },
  [CONSTANTS.FIND_PREV.SUCCESS]: (
    state,
    { payload: { description, name, recordName } }
  ) => {
    let result = state
    result = deleteIn(result, 'isFetching')
    result = setIn(result, 'description', description)
    result = setIn(result, 'value', name)
    result = setIn(result, 'recordName', recordName)
    return result
  },
  [CONSTANTS.FIND_NEXT.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isFetching', true)
    return result
  },
  [CONSTANTS.FIND_NEXT.SUCCESS]: (
    state,
    { payload: { description, name, recordName } }
  ) => {
    let result = state
    result = deleteIn(result, 'isFetching')
    result = setIn(result, 'description', description)
    result = setIn(result, 'value', name)
    result = setIn(result, 'recordName', recordName)
    return result
  },
  [CONSTANTS.BLUR]: (state, { payload: { isSet, value } }) => {
    let result = state
    if (!isSet && !value) {
      result = clearRecordAndDescription(result)
      result = setIn(result, 'value', '')
      result = setIn(result, 'isFiltered', false)
    }
    return result
  },
  [CONSTANTS.TOGGLE_FILTER]: state => {
    let result = state
    result = setIn(result, 'isFiltered', !getIn(result, 'isFiltered'))
    return result
  },
  [CONSTANTS.GET_SEARCH_FILTERS.REQUEST]: state => {
    // console.log('I hear the request')
    return state
  },
  [CONSTANTS.GET_SEARCH_FILTERS.SUCCESS]: (
    state,
    { payload: { grid: { headers, meta } = {}, filters, propertyName, form } }
  ) => {
    let result = state
    // debugger
    if (!getIn(result, 'filtersGrid')) {
      //
      const mapAgain = (col, i) => {
        // debugger
        if (col.field === 'fileAssociationSearchType') {
          col = {
            ...col,
            // width: 150,
            // maxWidth: 150,
            cellEditorParams: {
              form,
              propertyName
            },
            cellEditorFramework: FieldSwitcher,
            editable: true,
            valueGetter: ({ data }) => {
              return data?.fileAssociationSearchType?.value ?? ''
            },
            // onCellValueChanged(...args) {
            //   console.log(...args, this)
            // }
            valueSetter: params => {
              console.log(params)
              params.data.fileAssociationSearchType.value = params.newValue
              return true
            }
            // cellRendererFramework: IndexSearchGridCell
          }
        } else if (col.field === '') {
          col = {
            ...col,
            field: 'clear',
            cellRendererFramework: ClearFilterCell,
            width: 50,
            maxWidth: 50,
            headerName: 'x'
          }
        }
        // else {
        //   col = { ...col, maxWidth: 90 }
        // }
        col = { ...col, suppressMenu: true, sortable: false }

        return col
      }

      const columnDefs = headers
        ? headers.map(mapColumnHeaders).map(mapAgain)
        : []

      const rowData = filters
        ? filters.reduce((acc, next) => {
            // debugger
            acc = acc.concat({
              description: next.field,
              critOperatorDescription: next.operator,
              fileAssociationSearchType: {
                ...next.indexSearchField,
                dataId: next.dataId,
                form,
                parentField: propertyName,
                value: ''
              },
              clear: {
                ...next.indexSearchField,
                dataId: next.dataId,
                form,
                parentField: propertyName
              }
            })
            return acc
          }, [])
        : []

      const filtersGrid = {
        columnDefs,
        rowData: rowData || [],
        meta: meta || []
      }
      result = setIn(result, 'filtersGrid', fromJS(filtersGrid))
    }
    result = setIn(result, 'filtersGridIsOpen', true)

    return result
  },
  [CONSTANTS.CLEAR_FILTER_FIELD]: (state, action) => {
    let result = state
    let filtersGrid = getIn(result, 'filtersGrid.rowData')
    const rowIdx = filtersGrid?.findIndex(
      x => {
        return (
          getIn(x, 'fileAssociationSearchType.dataId') ===
          action.payload.filterType
        )
      }

      // getIn(x, 'fileAssociationSearchType.dataId') ===
      // action.payload.filterType
    )

    if (rowIdx != null) {
      filtersGrid = setIn(
        filtersGrid,
        rowIdx,
        setIn(
          filtersGrid.find((x, i) => i === rowIdx),
          'fileAssociationSearchType.value',
          ''
        )
      )
      result = setIn(result, 'filtersGrid.rowData', filtersGrid)
    }

    // debugger
    return result
  },
  [CONSTANTS.RESET_FILTERS]: state => {
    let result = state
    let filtersGrid = getIn(result, 'filtersGrid.rowData')
    filtersGrid = filtersGrid.map(el => {
      return setIn(el, 'fileAssociationSearchType.value', '')
    })
    // let filters = getIn(result, 'filters')
    // const filterFields = filters ? filters.toJS() : null

    // if (filterFields) {
    //   Object.keys(filterFields).forEach(filter => {
    //     filters = setIn(filters, `${filter}.value`, '')
    //   })
    //   result = setIn(result, 'filters', filters)
    // }
    result = setIn(result, 'filtersGrid.rowData', filtersGrid)
    return result
  },
  [CONSTANTS.SET_TEXT_FILTER_FIELD]: (state, action) => {
    let result = state

    let filtersGrid = getIn(result, 'filtersGrid.rowData')
    const rowIdx = filtersGrid?.findIndex(
      x => {
        return (
          getIn(x, 'fileAssociationSearchType.dataId') ===
          action.payload.filterType
        )
      }

      // getIn(x, 'fileAssociationSearchType.dataId') ===
      // action.payload.filterType
    )

    if (rowIdx != null) {
      filtersGrid = setIn(
        filtersGrid,
        rowIdx,
        setIn(
          filtersGrid.find((x, i) => i === rowIdx),
          'fileAssociationSearchType.value',
          action.payload.value
        )
      )
      result = setIn(result, 'filtersGrid.rowData', filtersGrid)
    }

    // debugger
    return result
  },
  [CONSTANTS.OPEN_FILTERS_GRID]: (state, payload) => {
    let result = state
    result = setIn(result, 'filtersGridIsOpen', true)
    return result
  },
  [CONSTANTS.CLOSE_FILTERS_GRID]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'filtersGridIsOpen', false)
    return result
  },
  [CONSTANTS.CLEAR_SEARCH]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'value', '')
    result = setIn(result, 'description', '')
    result = setIn(result, 'isSet', false)
    return result
  }
}

const initialState = fromJS({
  description: null,
  recordName: null,
  recordType: null,
  indexSearchType: null,
  isFetching: false,
  isFiltered: false,
  isInvalid: false,
  isSet: false,
  tooltip: null,
  type: 'IndexSearch',
  value: ''
})

export default createReducer(initialState, behaviors)
