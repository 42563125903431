import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import {
  SALES_ORDER_NUMBER_SEARCH_URL,
  SALES_ORDER_NUMBER_FIND_URL,
  SALES_ORDER_EXACT_MATCH_URL,
  SALES_ORDER_INQUIRY_URL,
  SALES_ORDER_OPEN_URL,
  SALES_ORDER_TOOLTIP_URL
} from './constants'

export const salesOrderTooltip = ({ dataId }) => {
  const options = createApiOptions({
    body: { recordName: dataId },
    method: 'post'
  })
  return callFetch(SALES_ORDER_TOOLTIP_URL, options)
}

export const salesOrderNumberSearch = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })
  return callFetch(SALES_ORDER_NUMBER_SEARCH_URL, options)
}

export const salesOrderFind = ({ keyword }) => {
  const options = createApiOptions({
    body: { description: keyword },
    method: 'post'
  })
  return callFetch(SALES_ORDER_NUMBER_FIND_URL, options)
}

export const salesOrderOpen = ({ credentials }) => {
  const options = createApiOptions({
    body: { credentials },
    method: 'post'
  })
  return callFetch(SALES_ORDER_OPEN_URL, options)
}

export const salesOrderExactMatch = ({ keyword }) => {
  const options = createApiOptions({
    body: { description: keyword },
    method: 'post'
  })
  return callFetch(SALES_ORDER_EXACT_MATCH_URL, options)
}

export const salesOrderInquiry = ({
  dataId,
  status,
  dateType,
  startDate,
  endDate,
  branchId,
  salesmanId,
  writer,
  productId,
  shipViaId,
  orderTypeId,
  customerId,
  poNumber,
  jobName,
  manualShipToInfo,
  overrideFilters,
  rangeFilters,
  ...rest
}) => {
  // if (dateType) {
  //   body.dateType = dateType
  // }
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      status,
      dateType,
      startDate,
      endDate,
      branchId,
      salesmanId,
      writer,
      productId,
      shipViaId,
      orderTypeId,
      customerId,
      poNumber,
      jobName,
      manualShipToInfo,
      overrideFilters,
      rangeFilters,
      ...rest
    }
  })
  return callFetch(SALES_ORDER_INQUIRY_URL, options)
}

export const printSalesOrderDocuments = ({
  status,
  printType = 'SO',
  dataIds
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { status, printType, dataIds }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorderinquiry/print`, options)
}

export const salesOrderInquiryReport = ({
  dataIds
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataIds }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorderinquiry/report`, options)
}