// import { extractIndexSearchFieldsFromMeta } from 'ddiForm/reducer'
import {
  getIn,
  setIn,
  mapColumnHeaders,
  fromJS,
  deleteIn,
  numToDollar
} from 'utils'
import moment from 'moment'
import * as DDIFORM_CONSTANTS from 'ddiForm/constants'
import { GET_SEARCH_FILTERS } from 'components/Search/IndexSearch/constants'

import * as CONSTANTS from './constants'
import PinnedRowRenderer from './components/PinnedRowRenderer'
import OrderIdCell from './components/OrderIdCell'

const fixedCols = [
  'dataId',
  'numberOfReceivings',
  'orderDate',
  'vendorName',
  'estimatedDeliveryDateDescription'
]
const tooltipFields = [
  'branchId',
  'buyerId',
  'shipViaId',
  'status',
  'termsId',
  'warehouseId'
]
const dateFields = ['orderDate', 'requestDate']

export const mapGridHeaders = (col, i) => {
  /* create pinned bottom TOTALS row */
  col = {
    ...col,
    pinnedRowCellRendererFramework: PinnedRowRenderer
  }

  if (col.field === 'dataId') {
    col = {
      ...col,
      cellRendererFramework: OrderIdCell,
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      tooltipField: 'vendorName'
    }
  }

  /* specify pinned columns */
  if (fixedCols.indexOf(col.field) !== -1) {
    col = {
      ...col,
      pinned: 'left',
      lockPinned: true
    }
  }

  /* parse and display tooltips */
  if (tooltipFields.indexOf(col.field) !== -1) {
    col = {
      ...col,
      tooltipField: `${col.field}.tooltip`,
      valueGetter: params =>
        params.data[col.field] && params.data[col.field].value
    }
  }

  /* parse the dollar field */
  if (col.field === 'orderValue') {
    col = {
      ...col,
      valueFormatter: params => numToDollar(params.value)
    }
  }

  /* format any date fields */
  if (dateFields.indexOf(col.field) !== -1) {
    col = {
      ...col,
      valueFormatter: params =>
        params.value
          ? moment(new Date(params.value)).format('M/D/YY')
          : params.value
    }
  }

  return col
}

export default {
  [CONSTANTS.EXACT_MATCH.SUCCESS]: (
    state,
    { payload: { propertyName, recordName, ...rest }, meta: { form } }
  ) => {
    // debugger
    let result = state
    result = setIn(result, `fields.${propertyName}.value`, recordName)

    return result
  },
  [CONSTANTS.EXACT_MATCH.FAILURE]: (
    state,
    { payload: { propertyName }, meta: { form } }
  ) => {
    let result = state
    result = setIn(result, `fields.${propertyName}.value`, '')

    return result
  },
  [CONSTANTS.FLAGGED]: (state, { payload: { bool } }) => {
    let result = state
    result = setIn(result, 'flagged', bool)
    result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    return result
  },
  [CONSTANTS.RESET]: (state, { payload }) => {
    let result = state
    let fields = getIn(state, 'fields')
    fields.forEach((field, key) => {
      if (getIn(field, 'type') === 'IndexSearch') {
        field = deleteIn(field, 'recordName')
        field = deleteIn(field, 'description')
        field = deleteIn(field, 'grid')
        field = deleteIn(field, 'creditStatus')
        field = deleteIn(field, 'creditStatusColor')
        field = setIn(field, 'value', '')
        field = setIn(field, 'disabled', false)
      } else if (key === 'status') {
        field = setIn(field, 'value', 'O')
      } else {
        field = setIn(field, 'value', '')
        field = setIn(field, 'disabled', false)
      }
      fields = setIn(fields, key, field)
    })
    fields = setIn(fields, 'inquiryResults.columnDefs', fromJS([]))
    fields = setIn(fields, 'inquiryResults.rowData', fromJS([]))

    result = setIn(result, 'fields', fields)
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.FAILURE]: state => {
    let result = state
    result = setIn(result, 'fields.inquiryResults.isFetching', false)
    result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.inquiryResults.isFetching', true)
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.SUCCESS]: (
    state,
    { payload: { purchaseOrders, onSelected } }
  ) => {
    let result = state
    const isModal = getIn(state, 'modal')
    const {
      data: rowData,
      meta: { columns: columnHeaders }
    } = purchaseOrders
    // debugger
    const columnDefs = columnHeaders
      ? columnHeaders.map(mapColumnHeaders).map(mapGridHeaders)
      : []
    if (columnDefs.length) {
      // console.log(columnDefs, onSuccess)
      const dataIdField = columnDefs.find(x => x.field === 'dataId')
      // dataIdField.cellRendererFramework =
      if (dataIdField) {
        dataIdField.cellRendererParams = {
          onSelect: onSelected,
          isModal
        }
      }
    }

    // debugger

    const grid = isModal
      ? {
        columnDefs,
        rowData: rowData || []
      }
      : {
        columnDefs: [
          {
            checkboxSelection: true,
            field: 'isSelected',
            headerCheckboxSelection: true,
            headerName: '',
            pinned: 'left'
          },
          ...columnDefs
        ],
        rowData: rowData || []
      }

    result = setIn(result, 'fields.inquiryResults', fromJS(grid))
    result = setIn(result, 'fields.inquiryResults.isFetching', false)
    return result
  },
  [CONSTANTS.OPEN_DROPDOWN.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'asModal', true)
    return result
  },
  [GET_SEARCH_FILTERS.SUCCESS]: (state, action) => {
    const result = state
    // console.log('poInquiryGetSearchFilters')
    return result
  },
  [DDIFORM_CONSTANTS.SET_FIELD]: (
    state,
    { payload: { propertyName, value }, meta }
  ) => {
    let result = state
    // debugger
    const prevValue = getIn(result, 'fields.product.prevValue')
    const status = getIn(result, 'fields.status.value')
    if (
      propertyName === 'product' &&
      value &&
      status !== 'O' &&
      status !== 'R'
    ) {
      result = setIn(
        result,
        'fields.status.prevValue',
        getIn(result, 'fields.status.value')
      )
      result = setIn(result, 'fields.status.value', 'O')
    }

    // const clearDescriptions = ['poNumber', 'product', 'vendor', 'job']

    // if (!value && clearDescriptions.includes(propertyName)) {
    //   result = setIn(result, `fields.${propertyName}.description`, '')
    // }

    return result
  },
  [CONSTANTS.CLEAR_INQUIRY_RESULTS]: (state, action) => {
    let result = state

    result = setIn(result, 'fields.inquiryResults.columnDefs', fromJS([]))
    result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    result = setIn(result, 'fields.inquiryResults.isFetching', false)
    return result
  }
}
