import React from 'react'
import { CustomFields } from 'components/MasterScreen/CustomFields'
import DDIExpansionPanel from 'components/DDIExpansionPanel'

const ActivityCustomFields = ({
  form = 'eventModal',
  customFieldsData,
  hasCustomFields
}) => (
  <DDIExpansionPanel
    title="Custom Fields"
    expansionPanelContentStyle={{ padding: 0, width: '100%' }}
    expansionPanelDetailsStyle={{ padding: 10, width: '100%' }}
    defaultExpanded={false}
  >
    <CustomFields
      form={form}
      customFieldsData={customFieldsData}
      hasCustomFields={hasCustomFields}
      isActivityScreen
    />
  </DDIExpansionPanel>
)

export default ActivityCustomFields
