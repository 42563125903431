import { call, fork, put, select, take, delay } from 'redux-saga/effects'
import * as actions from 'pages/SalesOrder/actions'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import { getFormSelector } from 'ddiForm/utils'
import { addModal, removeModal } from 'modals/actions'
import { api } from 'services'
import { getIn } from 'utils'
import { REMOVE_MODAL } from 'modals/constants'
import ProductNotesModal from 'pages/SalesOrder/tabs/Order/components/ProductNotesModal'
import ProductNotesModalActions from 'pages/SalesOrder/tabs/Order/components/ProductNotesModalActions'
import notesModalSagas from 'modals/NotesModal/sagas'

export function* displayProductNotesModal(form, record) {
  const modalOpts = {
    component: ProductNotesModal,
    options: {
      actions: ProductNotesModalActions,
      data: {
        form,
        lineNumber: record.lineNumber,
        note: record.note
      },
      width: 600,
      title: `Product - ${record.dataId} "${record.description}"`
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* displayProductNotesHandler(form, record) {
  yield call(displayProductNotesModal, form, record)
  yield take(REMOVE_MODAL)
  // debugger
}

export function* handleEditNotes(
  form,
  lineNumber,
  notesName = 'ProductNotes',
  action = 'lockProductNote',
  modalId = null,
  note = ''
) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const guid = getIn(formState, 'guid')

  yield put(actions[action].request(form))

  const params =
    action === 'saveProductNote'
      ? {
          dataId,
          notesName,
          lineNumber,
          guid,
          note
        }
      : {
          dataId,
          notesName,
          lineNumber,
          guid
        }

  const { response, error } = yield call(api[action], params)

  if (response) {
    yield put(actions[action].success(response, form))

    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    yield put(actions[action].failure(error, form))
  }
}

export function* lockProductNoteModalListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber }
    } = yield take(CONSTANTS.LOCK_PRODUCT_NOTE.TRY)

    if (form === formListener) {
      yield fork(handleEditNotes, form, lineNumber)
    }
  }
}

export function* unlockProductNoteModalListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber, modalId }
    } = yield take(CONSTANTS.UNLOCK_PRODUCT_NOTE.TRY)

    if (form === formListener) {
      yield fork(
        handleEditNotes,
        form,
        lineNumber,
        'ProductNotes',
        'unlockProductNote',
        modalId
      )
    }
  }
}

export function* saveProductNoteModalListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber, modalId, note }
    } = yield take(CONSTANTS.SAVE_PRODUCT_NOTE.TRY)

    if (form === formListener) {
      yield fork(
        handleEditNotes,
        form,
        lineNumber,
        'ProductNotes',
        'saveProductNote',
        modalId,
        note
      )
    }
  }
}

export default function* notesHandlerSagas(form) {
  yield fork(lockProductNoteModalListener, form)
  yield fork(unlockProductNoteModalListener, form)
  yield fork(saveProductNoteModalListener, form)
  yield fork(notesModalSagas, form)
}
