import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Tabs } from '@material-ui/core'
import MobileScreen from 'mobile/components/MobileScreen'
import { MobileAppBar, MobileTab, TabPanel } from 'mobile/utils'

import SearchFields from '../components/SearchFieldSkeleton'

const SalesOrderSkeleton = () => {

  return (
    <MobileScreen
      speedDialActions={[]}
      title="Product Analysis"
      titleIcon="multiline_chart"
      CollapsableSearchComponent={SearchFields}
    >
      <div style={{ width: '100%' }}>
        <MobileAppBar position="static">
          <Tabs
            value={0}
            variant="fullWidth"
          >
            <MobileTab label="Overview" />
            <MobileTab label="Transfers" />
            <MobileTab label="Sales Orders" />
          </Tabs>
        </MobileAppBar>
        <div style={{ width: '100%' }}>
          <TabPanel value={0} index={0}>
            <Skeleton animation="wave" variant="rect" width="100%" height="250" />
          </TabPanel>
          <TabPanel value={0} index={0}>
            <Skeleton animation="wave" variant="rect" width="100%" height="250" />
          </TabPanel>
        </div>

      </div>
    </MobileScreen>
  )
}

export default SalesOrderSkeleton