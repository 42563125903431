const isForeignCountry = (country = '', isCanadianCompany = false) => {
  if (
    (country !== '' &&
      !country.match(
        /UNITED STATES|UNITED STATES OF AMERICA|US|USA|U\.S\.A\.|U\.S\./gi
      )) ||
    isCanadianCompany
  ) {
    return true
  }

  return false
}

export default isForeignCountry
