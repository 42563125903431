/**
 * returns width or height of an element
 * @param {object} el - DOM node to measure
 * @param {string} dimension - width or height
 * @param {number} offset - percentage of area to calculate
 * @returns {number} returns the width or height of the element
 */
const getContainerDimensions = (el, dimension, offset) => (
  // Math.round(document.getElementById('ddi-card-content-calendar').getBoundingClientRect().height * 0.8)
  Math.round(el.getBoundingClientRect()[dimension] * offset)
)

export default getContainerDimensions

