import { createAction, createAsyncAction, createAsyncRequestTypes } from 'utils'
import {
  ADD,
  // AFTER_REMOVE,
  BEFORE_REMOVE,
  CHANGE_TAB,
  CLEAR_LAYOUT_SCREEN_DATA,
  CLEAR_ORDER_PAD_DATA,
  CLEAR_SALES_ORDER_INQUIRY_DATA,
  ON_ADD,
  ON_FOCUS,
  ON_RE_INIT,
  ON_REMOVE,
  ON_LAYOUT_TAB_CHANGE,
  RE_INIT,
  REMOVE,
  SET_PAGE_TITLE,
  LAYOUT_CLEARED
} from './constants'

export const add = ({
  component,
  image,
  name,
  title,
  data,
  form,
  allowMultiple = false
}) =>
  createAction(ADD, {
    allowMultiple,
    component,
    data,
    form,
    image,
    name,
    title
  })
export const layoutCleared = items => createAction(LAYOUT_CLEARED, { items })
export const onAdd = name => createAction(ON_ADD, { name })
export const onFocus = name => createAction(ON_FOCUS, { name })
export const onReInit = components => createAction(ON_RE_INIT, { components })
export const beforeRemove = (name, layout) =>
  createAction(BEFORE_REMOVE, { layout, name })
export const onRemove = name => createAction(ON_REMOVE, { name })
export const remove = name => createAction(REMOVE, { name })
export const reInit = names => createAction(RE_INIT, { names })
export const setPageTitle = title => createAction(SET_PAGE_TITLE, { title })
export const clearLayoutScreenData = name =>
  createAction(CLEAR_LAYOUT_SCREEN_DATA, { name })

// export const openScreen = createAsyncAction(
//   createAsyncRequestTypes('OPEN_SCREEN', null, )
// )
export const onLayoutTabChange = args =>
  createAction(ON_LAYOUT_TAB_CHANGE, { ...args })

export const clearOrderPadData = () => createAction(CLEAR_ORDER_PAD_DATA, {})
export const changeTab = args => createAction(CHANGE_TAB, args)
