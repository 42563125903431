import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import {
  CALCULATE_ORDER_PAD_PRICE_URL,
  // CUSTOMER_ORDER_PAD_OPEN_URL,
  CUSTOMER_ORDER_PAD_INQUIRY_URL,
  DELETE_CUSTOMER_STOCK_MINIMUMS_URL,
  GET_CUSTOMER_STOCK_MINIMUMS_URL,
  GET_PRICE_DETAIL_URL,
  GET_ADJUSTED_PRICE_URL,
  SAVE_CUSTOMER_STOCK_MINIMUMS_URL,
  SAVE_PRICE_DETAIL_URL,
  SAVE_ADJUSTED_PRICE_URL,
  RUN_CUSTOMER_ORDER_PAD_REPORT_URL,
  VIEW_CUSTOMER_ORDER_PAD_REPORT_URL
} from './constants'

export const orderPadInquiry = ({
  customerId,
  cutOffDate = null,
  shipToId = null
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      customerId,
      cutOffDate,
      shipToId
    }
  })

  return callFetch(CUSTOMER_ORDER_PAD_INQUIRY_URL, options)
}

export const getPriceDetail = ({ dataId, UOMId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      UOMId,
      dataId
    }
  })

  return callFetch(GET_PRICE_DETAIL_URL, options)
}

export const savePriceDetail = ({
  dataId,
  UOMId,
  isHidden,
  isMarginPriceDisabled
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      isHidden,
      isMarginPriceDisabled,
      UOMId
    }
  })

  return callFetch(SAVE_PRICE_DETAIL_URL, options)
}

export const getAdjustedPrice = ({ dataId, UOMId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      UOMId,
      dataId
    }
  })

  return callFetch(GET_ADJUSTED_PRICE_URL, options)
}

export const getCustomerStockMinimums = ({
  dataId,
  UOMId,
  customerId,
  shipToId
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      UOMId,
      customerId,
      dataId,
      shipToId
    }
  })

  return callFetch(GET_CUSTOMER_STOCK_MINIMUMS_URL, options)
}

export const saveCustomerStockMinimums = ({
  dataId,
  UOMId,
  customerId,
  maximumQuantity,
  minimumQuantity,
  shipToId
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      UOMId,
      customerId,
      dataId,
      maximumQuantity: maximumQuantity === 0 ? 0 : maximumQuantity,
      minimumQuantity: minimumQuantity === 0 ? 0 : minimumQuantity,
      shipToId
    }
  })

  return callFetch(SAVE_CUSTOMER_STOCK_MINIMUMS_URL, options)
}

export const deleteCustomerStockMinimums = ({
  dataId,
  UOMId,
  customerId,
  shipToId
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      customerId,
      dataId,
      shipToId,
      UOMId
    }
  })

  return callFetch(DELETE_CUSTOMER_STOCK_MINIMUMS_URL, options)
}
// not used? -MS 11.13
// export const customerOrderPadOpen = ({ credentials }) => {
//   const options = createApiOptions({
//     body: { credentials },
//     method: 'post'
//   })
//   return callFetch(CUSTOMER_ORDER_PAD_OPEN_URL, options)
// }

export const runCustomerOrderPadReport = ({
  customerId,
  shipToId,
  cutOffDate,
  filterById,
  groupById,
  reportType,
  printOption,
  saveLineSeqNo,
  printShipToDetail
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      customerId,
      cutOffDate,
      filterById,
      groupById,
      printOption,
      printShipToDetail,
      reportType,
      saveLineSeqNo,
      shipToId
    }
  })

  return callFetch(RUN_CUSTOMER_ORDER_PAD_REPORT_URL, options)
}

export const viewCustomerOrderPadReport = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId }
  })

  return callFetch(VIEW_CUSTOMER_ORDER_PAD_REPORT_URL, options)
}

export const calculateOrderPadPrice = ({ cost, gpPercent }) => {
  const options = createApiOptions({
    method: 'post',
    body: { cost, gpPercent }
  })

  return callFetch(CALCULATE_ORDER_PAD_PRICE_URL, options)
}

export const saveAdjustedPrice = ({
  lineItems,
  effectiveDate,
  adjustedPrice,
  percentIncrease
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      lineItems,
      effectiveDate,
      adjustedPrice,
      percentIncrease
    }
  })

  return callFetch(SAVE_ADJUSTED_PRICE_URL, options)
}

export const savePriceQuote = ({ dataId, uomId, quotedPrice }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      uomId,
      quotedPrice
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customerorderpad/quote/save`,
    options
  )
}
