import { createAction, createAsyncAction } from 'utils'
import * as CONSTANTS from './constants'

export const heartBeat = createAsyncAction(CONSTANTS.HEARTBEAT)
export const requestAccess = createAsyncAction(CONSTANTS.REQUEST_ACCESS)
export const submitSelectedBranch = createAsyncAction(
  CONSTANTS.SUBMIT_SELECTED_BRANCH
)
export const submitSelectedRegister = createAsyncAction(
  CONSTANTS.SUBMIT_SELECTED_REGISTER
)
export const submitLogoutUser = createAsyncAction(CONSTANTS.SUBMIT_LOGOUT_USER)
export const systemUser = createAsyncAction(CONSTANTS.CREATE_SYSTEM_USER)
export const token = createAsyncAction(CONSTANTS.TOKEN)

// sync
export const loginComplete = () => createAction(CONSTANTS.LOGIN_COMPLETE)
export const loginRequest = (username, password, redirectRoute) =>
  createAction(CONSTANTS.LOGIN_REQUEST, {
    password,
    redirectRoute,
    username
  })
export const logoutUser = (
  opt = { status: null, statusText: 'You have been successfully logged out.' }
) =>
  createAction(CONSTANTS.LOGOUT_USER, {
    status: opt.status,
    statusText: opt.statusText
  })
export const selectBranch = dataId =>
  createAction(CONSTANTS.SELECT_BRANCH, { dataId })
export const clearSelectedBranch = () =>
  createAction(CONSTANTS.CLEAR_SELECTED_BRANCH)
export const setPOSRegisterNum = registerNum =>
  createAction(CONSTANTS.SET_POS_REGISTER_NUM, { registerNum })
export const checkTokenExpiration = () =>
  createAction(CONSTANTS.CHECK_TOKEN_EXPIRATION)
export const noToken = () => createAction(CONSTANTS.NO_TOKEN)
export const removeToken = () => createAction(CONSTANTS.REMOVE_TOKEN)
export const revokeOneTimeAccess = ({ name }) =>
  createAction(CONSTANTS.REVOKE_ONE_TIME_ACCESS, { name })
export const authenticationError = ({ message }) =>
  createAction(CONSTANTS.AUTHENTICATION_ERROR, { message })
export const tryRequestAccess = args =>
  createAction(CONSTANTS.TRY_REQUEST_ACCESS, { ...args }, null, null, {
    thunk: true
  })
// export const accessGranted = name => createAction(CONSTANTS.ACCESS_GRANTED, { name })
export const accessGranted = (name, response) =>
  createAction(CONSTANTS.ACCESS_GRANTED, { name, response })

export const accessDenied = name =>
  createAction(CONSTANTS.ACCESS_DENIED, { name })

export const submitSelectedCardTerminal = createAsyncAction(
  CONSTANTS.SUBMIT_SELECTED_CARD_TERMINAL
)
export const selectCardTerminal = dataId =>
  createAction(CONSTANTS.SET_CARD_TERMINAL, { dataId })

export const cancelBranchSelection = () =>
  createAction(CONSTANTS.CANCEL_BRANCH_SELECTION)
export const forgotPassword = createAsyncAction(
  CONSTANTS.FORGOT_PASSWORD,
  null,
  null,
  true
)
export const clearStatus = () => createAction(CONSTANTS.CLEAR_STATUS)
export const showChangePasswordModal = () =>
  createAction(CONSTANTS.SHOW_CHANGE_PASSWORD_MODAL)

export const showChangeEmailModal = () =>
  createAction(CONSTANTS.SHOW_CHANGE_EMAIL_MODAL)
export const forgotUserName = createAsyncAction(
  CONSTANTS.FORGOT_USER_NAME,
  null,
  null,
  true
)

export const loginOtp = createAsyncAction(CONSTANTS.LOGIN_OTP, null, null, true)
export const stopAuth = createAction(CONSTANTS.STOP_AUTH)
