/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import fetchProcessBlob from './fetchProcessBlob'
import fetchProcessText from './fetchProcessText'

// export const sendDocument = ({ dataId }) => {
//   const options = createApiOptions({
//     method: 'post',
//     body: { dataId }
//   })

//   return callFetch(`${BASE_INFORM_API_URL}/printdocument/send`, options)
// }

export const viewDocument = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId }
  })

  return callFetch(`${BASE_INFORM_API_URL}/printdocument/view`, options)
}

export const printDocument = ({
  dataId,
  printerId,
  copies,
  collate,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, printerId, copies, collate, credentials }
  })

  return callFetch(`${BASE_INFORM_API_URL}/printdocument/print`, options)
}

export const saveDocument = ({ credentials, dataId, filename, fileType }) => {
  const options = createApiOptions({
    method: 'post',
    body: { credentials, dataId, filename, fileType }
  })

  if (fileType === 'xls') {
    return callFetch(
      `${BASE_INFORM_API_URL}/printdocument/save`,
      options,
      false,
      fetchProcessBlob
    )
  }

  return callFetch(
    `${BASE_INFORM_API_URL}/printdocument/save`,
    options,
    false,
    fetchProcessText
  )
}

export const cancelPrintDocument = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId }
  })

  return callFetch(`${BASE_INFORM_API_URL}/printdocument/cancel`, options)
}

export const getAllPrinters = ({ allPrinters, dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: { allPrinters, dataId }
  })

  return callFetch(`${BASE_INFORM_API_URL}/printdocument/allprinters`, options)
}

export const setSessionDefault = ({ dataId, printerId, sessionDefault }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, printerId, sessionDefault }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/printdocument/setsessiondefault`,
    options
  )
}

// export const sendDocument = ({ dataId, printerId, sessionDefault }) => {
//   const options = createApiOptions({
//     method: 'post',
//     body: { dataId, printerId, sessionDefault }
//   })

//   return callFetch(
//     `${BASE_INFORM_API_URL}/printdocument/setsessiondefault`,
//     options
//   )
// }
