const dataURItoBlob = (
  dataURI,
  type = { type: 'data:application/pdf' },
  callback
) => {
  // convert base64 to raw binary data held in a string
  const data = dataURI.split(',')
  if (!data || !data[1]) {
    return null
  }

  const byteString = atob(data[1])

  // separate out the mime component
  // const mimeString = dataURI
  //   .split(',')[0]
  //   .split(':')[1]
  //   .split('')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const bb = new Blob([ab], type)
  return bb
}

export default dataURItoBlob
