/* eslint import/prefer-default-export: 0 */
import { createAsyncRequestTypes } from 'utils'

const PRINT_DOCUMENT_MODAL = '@@PRINT_DOCUMENT_MODAL@@/'

export const VIEW_DOCUMENT = createAsyncRequestTypes(
  `${PRINT_DOCUMENT_MODAL}VIEW_DOCUMENT`
)
export const SEND_DOCUMENT = createAsyncRequestTypes(
  `${PRINT_DOCUMENT_MODAL}SEND_DOCUMENT`
)

export const PRINT_DOCUMENT = createAsyncRequestTypes(
  `${PRINT_DOCUMENT_MODAL}PRINT_DOCUMENT`
)

export const SAVE_DOCUMENT = createAsyncRequestTypes(
  `${PRINT_DOCUMENT_MODAL}SAVE_DOCUMENT`
)

export const CANCEL_PRINT_DOCUMENT = createAsyncRequestTypes(
  `${PRINT_DOCUMENT_MODAL}CANCEL_PRINT_DOCUMENT`
)

export const GET_ALL_PRINTERS = createAsyncRequestTypes(
  `${PRINT_DOCUMENT_MODAL}GET_ALL_PRINTERS`
)

export const SET_SESSION_DEFAULT = createAsyncRequestTypes(
  `${PRINT_DOCUMENT_MODAL}SET_SESSION_DEFAULT`
)

export const CLOSE_REPORT_VIEWER = `${PRINT_DOCUMENT_MODAL}CLOSE_REPORT_VIEWER`

export const PRINT_DOCUMENT_ROUTINE_COMPLETED = `${PRINT_DOCUMENT_MODAL}PRINT_DOCUMENT_ROUTINE_COMPLETED`
