/**
 * Takes a number and converts to dollar format
 * @param {number} num - number
 * @param {string} currency - type of currency
 * @returns {string} returns a formatted dollar value
 */
// const numToDollar = (num, currency = '$') => {
//   if (!num) return '$0.00'
//   return `${Number.parseFloat(num)
//     .toFixed(2)
//     .replace(/(\d)(?=(\d{3})+\.)/g, `${currency}1,`)}`
//   // return `${currency}${Number.parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, `${currency}1,`)}`
// }
const numToDollar = (
  num,
  locales = 'en-US',
  currency = 'USD',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) =>
  new Intl.NumberFormat(locales, {
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
    style: 'currency'
  }).format(num)

export default numToDollar
