import React, { Component } from 'react'

export default class CheckboxRenderer extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
  }

  handleCheckboxChange = event => {
    event.persist()
    this.setState({ value: !this.state.value }, () => {
      if (this.props.onClick) {
        this.props.onClick(this)
      }
    })
  }

  render() {
    return (
      <input
        type="checkbox"
        checked={this.state.value}
        onChange={this.handleCheckboxChange}
        disabled={this.props.disabled}
      />
    )
  }
}
