export const BASE_INFORM_URL =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:49495/'
export const BASE_INFORM_API_URL = `${BASE_INFORM_URL}/api`
export const SYSTEM_USER_URL = `${BASE_INFORM_API_URL}/systemuser`
export const SYSTEM_USER_LOGIN_URL = `${SYSTEM_USER_URL}/login`
export const SYSTEM_USER_LOGOUT_URL = `${SYSTEM_USER_URL}/logout`
export const HEARTBEAT_URL = `${BASE_INFORM_API_URL}/systemuser/heartbeat`

export const TOKEN_URL = `${BASE_INFORM_URL}/token`
export const SYSTEM_USER_UPDATE_BRANCH_URL = `${SYSTEM_USER_URL}/update/branch`
export const FETCH_SHIPPING_CONFIRMATION_URL = `${BASE_INFORM_API_URL}/shippingconfirmation/editlock`
export const CANCEL_SHIPPING_CONFIRMATION_URL = `${BASE_INFORM_API_URL}/shippingconfirmation/canceledit`
export const BACK_ORDER_ALL_URL = `${BASE_INFORM_API_URL}/shippingconfirmation/backorderall`
export const SHIP_ALL_URL = `${BASE_INFORM_API_URL}/shippingconfirmation/shipall`

export const UPDATE_NOTES_URL = `${BASE_INFORM_API_URL}/shippingconfirmation/updatenotes`
export const REGISTER_URL = `${BASE_INFORM_API_URL}/account/register`

export const ORDER_SEARCH_URL = `${BASE_INFORM_API_URL}/search/salesordernumber` // dup with lowere one..
export const PARTIAL_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/indexsearch/partialmatch`
export const EXACT_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/indexsearch/exactmatch`
export const SALES_ORDER_NUMBER_SEARCH_URL = `${BASE_INFORM_API_URL}/search/salesordernumber` // dup
export const SALES_ORDER_COMPLETE_SEARCH_URL = `${BASE_INFORM_API_URL}/search/salesorders`
export const FIND_NEXT_SEARCH_URL = `${BASE_INFORM_API_URL}/search/findnext`
export const FIND_PREV_SEARCH_URL = `${BASE_INFORM_API_URL}/search/findprevious`

export const SHIP_CONFIRMATION_PROPERTY_CHANGE_URL = `${BASE_INFORM_API_URL}/shippingconfirmation/propertychange`
export const DELETE_SHIPPING_CONFIRMATION_URL = `${BASE_INFORM_API_URL}/shippingconfirmation/deletesc`

export const AUTHORIZATION_ACCESS_URL = `${BASE_INFORM_API_URL}/menu/access`

export const SAVE_SHIPPING_CONFIRMATION_URL = `${BASE_INFORM_API_URL}/shippingconfirmation/save`
export const TRUCK_MANIFEST_URL = `${BASE_INFORM_API_URL}/truckmanifest/`
