import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DDITextField } from 'ddiForm/wrapped'

import '../styles/master-style.scss'

export const CustomTextField = ({ fieldType, value, ...props }) => {
  const [isMultiline, setIsMultiline] = useState(false)

  useEffect(() => {
    setIsMultiline(fieldType === 'TXTN')
  }, [fieldType])

  return (
    <div className="custom-fields-wrapper">
      <DDITextField
        {...props}
        multiline={isMultiline}
        rows={isMultiline ? 5 : null}
        mask={fieldType === 'MD' ? 'numeric' : null}
        initialValue={value}
        variant={isMultiline ? 'outlined' : 'standard'}
      />
    </div>
  )
}

CustomTextField.propTypes = {
  fieldType: PropTypes.string.isRequired,
  value: PropTypes.string
}

CustomTextField.defaultProps = {
  value: ''
}

export default CustomTextField
