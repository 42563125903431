import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import nl2br from 'react-newline-to-break'
import { connect } from 'react-redux'
import { confirm } from './actions'

const Dialog = props => {
  const { data } = props
  return (
    <Row style={{ alignItems: 'center', display: 'flex' }}>
      <Col sm={2} style={{ textAlign: 'center' }}>
        {data.type === 'alert' ? (
          <Icon style={{ fontSize: 40 }}>error_outline</Icon>
        ) : (
          <Icon style={{ fontSize: 40 }}>info_outline</Icon>
        )}
      </Col>
      <Col sm={10} style={{ paddingLeft: 0 }}>
        {nl2br(data.message)}
      </Col>
    </Row>
  )
}

export default connect()(Dialog)
