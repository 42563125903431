import { call, fork, put, select, take, delay } from 'redux-saga/effects'
import * as actions from 'pages/SalesOrder/actions'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import { getGroupNames } from 'pages/SalesOrder/utils'
import * as api from 'pages/SalesOrder/api'
import { setField, updateFormTitle } from 'ddiForm/actions'
import { SET_FIELD, BLUR } from 'ddiForm/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import { getIn, fromJS, is } from 'utils'
import { isValidDate, getFormSelector, getMapResponse } from 'ddiForm/utils'
// import NotesModal, { NotesModalActions } from 'modals/NotesModal'
// import ProductNotesModal from 'pages/SalesOrder/tabs/Order/components/ProductNotesModal'
import NotesModalContent from 'pages/SalesOrder/components/NotesModalContent'
import NotesModalActions from 'pages/SalesOrder/components/NotesModalActions'
import { getUserBranchInfo } from 'auth/sagas'
import { local } from 'services'
import { CANCELED, CONFIRMED, REMOVE_MODAL } from 'modals/constants'
import { confirmationModal, warningModal } from 'modals/sagas'
import { addModal } from 'modals/actions'
import { NOTIFY_INTERNAL_NOTES_MODAL_CLOSED } from 'modals/NotesModal/constants'
import ModalContent from 'pages/SalesOrder/components/ModalContent'
import { SAVE_SHIP_TO } from 'pages/CustomerShipTo/constants'
import { validateGridData } from 'components/EditableGrid/actions'
import moment from 'moment'

import {
  cancelNewSalesOrderEditProcess,
  cancelSalesOrderEditProcess,
  handleRepairItemModalProcess,
  cancelEditAfterClear
} from './commonSagas'

import { displayProductNotesHandler } from './notesHandlerSagas'

const searchAreaFieldLabelMapper = {
  customerId: 'Customer',
  shipToId: 'Ship To',
  orderedById: 'Ordered By',
  writerId: 'Writer',
  shipViaId: 'Ship Via',
  shipDate: 'Ship Date',
  dataId: 'Order',
  branchId: 'Branch',
  warehouseId: 'Warehouse',
  poNumber: 'Customer P/O'
}

const searchAreaFields = [
  'customerId',
  'shipToId',
  'orderedById',
  'writerId',
  'shipViaId',
  'shipDate',
  'dataId',
  'branchId',
  'warehouseId',
  'poNumber',
  'orderDate'
]

const descriptionFields = {
  branchId: 'branchDescription',
  customerId: 'customerName',
  orderedById: 'orderedByName',
  shipToId: 'shipToDescription',
  shipViaId: 'shipViaDescription',
  warehouseId: 'warehouseDescription',
  writerId: 'writerDescription'
}

export function* displayPropertyChangeModalNotifications(
  form,
  propertyName,
  response
) {
  const formState = yield select(getFormSelector(form))
  const label =
    searchAreaFieldLabelMapper && searchAreaFieldLabelMapper[propertyName]
      ? searchAreaFieldLabelMapper[propertyName]
      : 'field'
  const val = getIn(formState, `fields.${propertyName}.value`)
  const modalHeading = `Changing the ${label} to ${val} resulted in the following changes:`

  const modalMessage = searchAreaFields.reduce((acc, next) => {
    const field = next
    const messageSuppressed =
      propertyName === 'customerId' && field === 'shipViaId'

    if (field !== propertyName && !messageSuppressed) {
      const fieldLabel =
        searchAreaFieldLabelMapper && searchAreaFieldLabelMapper[field]
          ? searchAreaFieldLabelMapper[field]
          : ''
      const value = getIn(formState, `fields.${field}.value`)
      const prevValue = getIn(formState, `fields.${field}.prevValue`)

      /* format the shipDate values for display */
      const displayValue =
        field === 'shipDate' && value && isValidDate(value)
          ? moment(new Date(value)).format('M/D/YY')
          : value
      const displayPrevValue =
        field === 'shipDate' && prevValue && isValidDate(prevValue)
          ? moment(new Date(prevValue)).format('M/D/YY')
          : prevValue

      if (fieldLabel && (prevValue || value) && prevValue !== value) {
        const msg =
          value && prevValue
            ? `${fieldLabel} has been updated from ${displayPrevValue} to ${displayValue}\n`
            : value
            ? `${fieldLabel} has been updated to ${displayValue}\n`
            : `${fieldLabel} has been cleared\n`

        acc = acc.concat(msg)
      }
    }
    return acc
  }, '')

  if (modalMessage) {
    /*
      needed to change this to a custom modal
      wait for the dismissal of the modal -- SVE 1/14/2020
    */
    // const modal = yield call(warningModal, modalMessage, modalHeading)
    const modalOpts = {
      component: ModalContent,
      options: {
        form,
        title: modalHeading,
        width: 500,
        maxHeight: '100%',
        data: {
          message: modalMessage,
          form,
          actions: [
            {
              title: 'OK',
              async clickEvent(args, fs, cb) {
                try {
                  await this.props.modalRoutineCompleted(form)
                } catch (e) {
                  console.log(e)
                } finally {
                  if (cb && typeof cb === 'function') {
                    cb()
                  }
                }
              }
            }
          ]
        }
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    yield take(act => {
      return (
        act.type === REMOVE_MODAL && act?.payload?.id === modal?.payload?.id
      )
    })
  } else {
    yield put(actions.modalRoutineCompleted(form))
  }
}

export function* displayInternalNotesModal(form, record, propertyName) {
  const formState = yield select(getFormSelector(form))
  const internalNotesViewed = getIn(formState, 'values.internalNotesViewed')

  if (
    record &&
    !internalNotesViewed &&
    ((propertyName === 'customerId' && record.customerNotes) ||
      (propertyName === 'dataId' && record.internalNotes))
  ) {
    const notesResponse = {
      ...record,
      dataId: propertyName === 'dataId' ? record.dataId : record.customerId,
      description: record.customerName,
      type: propertyName === 'dataId' ? 'internalNotes' : 'customerNotes'
    }

    yield put(actions.setInternalNotes(form, { record: notesResponse }))

    const notes =
      propertyName === 'dataId' ? record.internalNotes : record.customerNotes
    yield fork(showNotesModal, notes, form, notesResponse, propertyName)
  }
}

export function* showNotesModal(notes, form, response, propertyName) {
  const dataId = response && response.dataId ? response.dataId : null
  const description =
    response && response.description ? response.description : ''
  const type = response && response.type

  const title =
    propertyName === 'dataId'
      ? `Sales Order - ${response.dataId} Internal Notes`
      : `Customer - ${dataId} "${description}" Internal Notes`

  const modalOpts = {
    component: NotesModalContent,
    options: {
      data: { form, notes, type },
      maxHeight: '100%',
      marginTop: '0px',
      title,
      width: 600,
      actions: NotesModalActions
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  return modal.payload.id
}

export function* handleAPIResponse({
  response,
  formState,
  groupNames,
  propertyName,
  form,
  error
}) {
  if (response) {
    const mapResponse = yield getMapResponse({ formState, tabIds: groupNames })
    const R = mapResponse({
      response,
      tabIds: groupNames,
      formState,
      groupNames
    })

    if (propertyName === 'dataId' && R?.record?.dataId) {
      // set page title when its a read
      yield fork(updateFormTitleProcess, form, R?.record)
    }

    /*
      searchFieldInteraction flag is the key here
      in order to differentiate this action in order
      to invoke the handleInteractiveModalMessages function
    */
    yield put({
      type: CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS,
      meta: {
        form,
        searchFieldInteraction: searchAreaFields.includes(propertyName) || false
      },
      payload: {
        ...R,
        propertyChanged: propertyName
      }
    })
  } else if (error === 'canceled') {
    /* handle a 497 cancellation, and get the data back from the API */
    yield put(actions.onPropertyChange.failure({ error }, form))
    const descriptionField = descriptionFields[propertyName]
      ? descriptionFields[propertyName]
      : ''
    const propertiesToRefresh = descriptionField
      ? [propertyName, descriptionField]
      : [propertyName]
    yield fork(propertyRefreshProcess, form, propertiesToRefresh)
  } else {
    yield put(actions.onPropertyChange.failure(error, form))

    if (error.status === 496 && error.data) {
      // const propName = toCamelCase(error.data)
      if (descriptionFields[propertyName]) {
        yield put(
          actions.updateFieldAndDescription(form, {
            propertyName,
            value: '',
            descriptionField: descriptionFields[propertyName],
            description: ''
          })
        )
      } else {
        yield put(setField(form, propertyName, ''))
      }
    }
  }
}

export function* propertyChangeProcess(
  form,
  propertyName,
  value,
  isModalInteraction,
  cancelledChanges = {},
  exactMatchResults = {}
) {
  const formState = yield select(getFormSelector(form))
  const notesModalEnabled = getIn(formState, 'notesModalEnabled') || false
  const customerId = getIn(formState, 'fields.customerId.value') || ''
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  if (propertyName === 'internalNotes' && notesModalEnabled) {
    return
  }

  const groupNames = isMobile
    ? ['header', 'detail', 'final']
    : getGroupNames(formState)

  yield put(actions.onPropertyChange.request(form))

  const guid = getIn(formState, 'guid')

  const propParams =
    propertyName === 'shipToId'
      ? {
          customerId: exactMatchResults.parentId,
          [propertyName]: value
        }
      : {
          [propertyName]: value,
          customerId: exactMatchResults.parentId
        }

  let params = isModalInteraction
    ? {
        properties: {
          ...cancelledChanges
        },
        responses: {
          [propertyName]: value
        },
        groupNames
      }
    : {
        /*
        order matters here per Geza,
        customerId has to come first
        in the properties object
        ¯\_(ツ)_/¯ -- SVE 10/1/2019

        and weirdly, this decision
        was reversed to have the customerId
        come second
        ¯\_(ツ)_/¯ -- SVE 10/16/2019

        he changed it agian, if its shipTo,
        customerId comes first
        ¯\_(ツ)_/¯  -- LL 10/18/2019
      */
        properties:
          !customerId && exactMatchResults && exactMatchResults.parentId
            ? propParams
            : {
                [propertyName]: value
              },
        groupNames
      }

  params.guid = guid

  if (propertyName === 'warehouseId') {
    const lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
    const selectedRowIndex = getIn(
      formState,
      'fields.lineItems.selectedRowIndex'
    )
    const activeLineNumber =
      is.number(selectedRowIndex) &&
      lineItems.get(selectedRowIndex) &&
      lineItems.get(selectedRowIndex).get('dataId') &&
      lineItems.get(selectedRowIndex).get('dataId') !== 'blankrow'
        ? lineItems.get(selectedRowIndex).get('lineNumber')
        : null

    const warehouseSelectionsAligned = lineItems.every(
      x => !x.get('warehouseId') || x.get('warehouseId') === value
    )

    if (!warehouseSelectionsAligned) {
      yield call(
        confirmationModal,
        `Do you want to change the selling warehouse on all line items to ${value}?`,
        'Warning',
        { form },
        { title: 'Yes' },
        { title: 'No' }
      )

      const modalAction = yield take([CONFIRMED, CANCELED])
      if (modalAction.type === CONFIRMED) {
        params = {
          ...params,
          properties: {
            ...params.properties,
            resetwarehouses: null
          }
        }
      }
    }

    if (lineItems.size && activeLineNumber) {
      /*
        if we are sitting on an active line item and the user has changed the warehouseId,
        we need to update the Options & Accessories and Substitutes for that product
      */
      params = {
        ...params,
        additionalData: ['lineitemoptionsaccessories', 'lineitemsubstitutes'],
        activeLineNumber
      }
    }
  }
  // debugger

  const { response, error } = yield call(api.propertyChange, params)

  yield fork(handleAPIResponse, {
    response,
    error,
    formState,
    form,
    groupNames,
    propertyName
  })
}

export function* readSalesOrderProcess({ value, form }) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false
  const groupNames = isMobile
    ? ['header', 'detail', 'final']
    : getGroupNames(formState)
  // debugger

  yield put(actions.onPropertyChange.request(form))

  const { response, error } = yield call(api.readSalesOrder, {
    guid,
    dataId: value,
    groupNames
  })

  yield fork(handleAPIResponse, {
    response: response ? { record: response } : undefined,
    error,
    formState,
    form,
    groupNames,
    propertyName: 'dataId'
  })
}

export function* searchAreaPropertyChangeProcess(formListener, action) {
  /*
      we basically need to listen for ALL fields
      EXCEPT for customerId, because that is handled by ddiForm
    */
  const {
    meta: { form },
    payload: { propertyName, value, results = {} },
    type
  } = action

  if (form === formListener) {
    /* clear the internal notes on a change to the customer */
    if (propertyName === 'customerId') {
      yield put(actions.unsetInternalNotes(form))
    }
    if (type === SET_FIELD && propertyName === 'dataId') {
      // read the order...
      if (value) {
        /*
          clearLineItemsGrid action is temp until the grid
          parsing routine in updateRecordFromAPI is stabilized
          -- SVE 11/26/2019
        */
        //  debugger
        yield fork(handleSalesOrderCancellation, form, value)
      } else {
        yield put(actions.clearOrder(form))
        yield call(cancelEditAfterClear, form)
        // debugger
        // yield put(updateFormTitle(form, 'Sales Order'))
      }
      return
    }

    if (
      searchAreaFields.includes(propertyName) ||
      (propertyName === 'dataId' && !value)
    ) {
      const formState = yield select(getFormSelector(form))
      if (
        type === SET_FIELD ||
        (type === INDEX_SEARCH_CONSTANTS.BLUR && !value)
      ) {
        yield fork(
          propertyChangeProcess,
          form,
          propertyName,
          value,
          false,
          null,
          results
        )
      } else {
        const prevValue = getIn(formState, `fields.${propertyName}.prevValue`)
        if (
          prevValue !== value &&
          (typeof value === 'string' || isValidDate(value))
        ) {
          yield fork(propertyChangeProcess, form, propertyName, value)
        }
      }
    }
  }
}

export function* propertyChangeTriggerListener(formListener) {
  const noPropertyChangeAPICallRequired = [
    'layoutId',
    'printOption',
    'miscChargeSpinner',
    'quotePrintGP',
    'quoteSuppressUnitPrices',
    'quotePrintListAndDiscount',
    'quoteSuppressProductNumber',
    'quoteSuppressExtensions',
    'quoteSuppressTotals',
    'quotePrintQuoteDescription',
    'quoteSuppressTaxes',
    'reason',
    'userId',
    'sendInfoSearch',
    'activeShipmentCycle',
    'userDescription',
    'showAllAudits',
    'groups.printItemGroupDetails',
    'groups.printItemGroupPrices',
    'signature.signedBy',
    'sigContact',
    'tempSignedBy',
    'tendered',
    'manualDataId',
    'checkoutContact',
    'checkoutContactDescription',
    'refundInvoices',
    'refundPayments',
    'mobileSalesOrderProductId',
    'mobileSalesOrderProductIdDescription',
    'comment',
    'addedItemGroupId',
    'selectedCustomerPartNumber',
    'multiSelectPromisedDate',
    'productDetails.purchaseOrderId',
    'lineItems',
    'recalculateType',
    'includeOverrides',
    'customerCategoryId'
  ]

  while (true) {
    const action = yield take([SET_FIELD, BLUR, INDEX_SEARCH_CONSTANTS.BLUR])
    const formState = yield select(getFormSelector(action.meta.form))
    const storedValue = getIn(
      formState,
      `values.${action.payload.propertyName}`
    )

    if (
      action.meta.form === formListener &&
      (action.type === SET_FIELD ||
        action.type === BLUR ||
        (action.type === INDEX_SEARCH_CONSTANTS.BLUR &&
          storedValue &&
          !action.payload.value))
    ) {
      if (searchAreaFields.includes(action.payload.propertyName)) {
        yield fork(searchAreaPropertyChangeProcess, formListener, action)
      } else if (
        !noPropertyChangeAPICallRequired.includes(
          action.payload.propertyName
        ) &&
        !action.payload.propertyName.match(/copyProducts/) &&
        !action.payload.propertyName.match(/fastProduct/) &&
        !action.payload.propertyName.match(/fastCustomer/) &&
        !action.payload.propertyName.match(/fastContact/) &&
        !action.payload.propertyName.match(/manualShipTo/) &&
        !action.payload.propertyName.match(/shipToAddress/) &&
        !action.payload.propertyName.match(/changeShipTo/) &&
        !action.payload.propertyName.match(/productDetails\./)
      ) {
        yield fork(
          propertyChangeProcess,
          action.meta.form,
          action.payload.propertyName,
          action.payload.value
        )
      }
    }
  }
}

/* using custom button handlers */

/* this still needs unit testing, maybe changes, its a weird one -- SVE 12/22/2020 */
export function* handleInteractiveModalMessages(
  form,
  messages = [],
  cancelledChanges = {},
  record = {},
  propertyName,
  skipGeneratedNotifications = false
) {
  const formState = yield select(getFormSelector(form))
  const customerId = getIn(formState, 'fields.customerId.value')
  const internalNotesViewed = getIn(formState, 'values.internalNotesViewed')
  const salesOrderInitialized = getIn(formState, 'values.salesOrderInitialized')

  if (messages && messages.length) {
    //
    for (let i = 0; i < messages.length; i++) {
      const msg = messages[i]
      const {
        allowCancel,
        allowNo,
        allowOk,
        allowYes,
        defaultDialogResponse,
        id,
        message,
        modalTitle,
        responses = [],
        title,
        type
      } = msg

      if (type === 'Warning') {
        const modalActions = responses.reduce((acc, next) => {
          const button =
            next.dataId === 'Yes'
              ? {
                  title: next.description,
                  primary: true,
                  disabled: !allowYes,
                  async clickEvent(args, fs, cb) {
                    try {
                      await this.props.handleModalInteraction(form, {
                        guid: id,
                        value: next.dataId,
                        cancelledChanges
                      })
                    } finally {
                      if (cb) {
                        cb()
                      }
                    }
                  }
                }
              : {
                  title: next.description,
                  primary: true,
                  disabled: !allowNo,
                  async clickEvent(args, fs, cb) {
                    try {
                      await this.props.handleModalInteraction(form, {
                        guid: id,
                        value: next.dataId
                      })
                    } finally {
                      if (cb) {
                        cb()
                      }
                    }
                  }
                }
          acc = acc.concat(button)

          return acc
        }, [])
        const modalOpts = {
          component: ModalContent,
          options: {
            form,
            title: modalTitle,
            width: 500,
            maxHeight: '100%',
            data: {
              message,
              guid: id,
              cancelledChanges,
              form,
              actions: modalActions
            }
          }
        }

        const modal = yield call(addModal, form, modalOpts)
        yield put(modal)
        yield take(CONSTANTS.HANDLE_MODAL_INTERACTION)
      }

      if (type === 'Message' || type === 'Information' || !type) {
        yield call(warningModal, message, modalTitle)
        const action = yield take([CONFIRMED, CANCELED])

        if (action.type === CONFIRMED) {
          if (modalTitle === 'Not Authorized') {
            yield put(actions.unsetInternalNotes(form))
            yield put(actions.setSalesOrderInitialized(form))
          }
        }
      }
    }
  }

  /* only show messages generated by the server */
  if (skipGeneratedNotifications) {
    /*
      however if we are looking at new order, we do need to show the order internal notes -- SVE 4/17/20
    */
    if (
      !internalNotesViewed &&
      record.internalNotes &&
      propertyName === 'dataId'
    ) {
      yield fork(displayInternalNotesModal, form, record, propertyName)
    }

    return
  }

  if (
    (!internalNotesViewed &&
      record.customerNotes &&
      propertyName === 'customerId') ||
    (!internalNotesViewed && record.internalNotes && propertyName === 'dataId')
  ) {
    /* NOTE: have to use calls here instead of fork
       because it has to block the rest of the flow -- SVE 8/21/19
    */
    yield call(displayInternalNotesModal, form, record, propertyName)
    yield take(NOTIFY_INTERNAL_NOTES_MODAL_CLOSED)
    if (salesOrderInitialized) {
      yield call(displayPropertyChangeModalNotifications, form, propertyName, {
        record
      })
      yield call(determineTerminalCheckProcessRequired, form, propertyName)
    } else {
      yield put(actions.setSalesOrderInitialized(form))
    }
  } else if (customerId) {
    if (salesOrderInitialized) {
      yield call(displayPropertyChangeModalNotifications, form, propertyName, {
        record
      })
      yield call(determineTerminalCheckProcessRequired, form, propertyName)
    } else {
      yield put(actions.setSalesOrderInitialized(form))
    }
  }
}

export function* determineTerminalCheckProcessRequired(form, propertyChanged) {
  const formState = yield select(getFormSelector(form))
  if (propertyChanged === 'customerId') {
    const cardTerminalChecked =
      getIn(formState, 'ui.cardTerminalChecked') || false
    const { userName, selectedBranchId } = yield call(getUserBranchInfo)
    const cardTerminal = yield call(
      local.get,
      `${userName}-${selectedBranchId}-selectedTerminal`
    )
    const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
      false

    if (!cardTerminalChecked && !!cardTerminal && !isMobile) {
      yield call(checkTerminalProcess, form)
    }
  }
}

export function* handleModalInteractionListener(formListener) {
  /* disabled/deprecated */
  /* DELETE ME when I get back */
  while (true) {
    const {
      meta: { form },
      payload: { guid, value, cancelledChanges }
    } = yield take(CONSTANTS.HANDLE_MODAL_INTERACTION)

    if (form === formListener) {
      yield fork(
        propertyChangeProcess,
        form,
        guid,
        value,
        true,
        cancelledChanges
      )
    }
  }
}

export function* propertyChangeModalHandlerListener(formListener) {
  while (true) {
    const {
      meta: { form, searchFieldInteraction, addedNewLineItem, addedLineNumber },
      payload: {
        propertyChanged = 'customerId',
        messages = [],
        cancelledChanges = {},
        validationMessages = [],
        record = {}
      },
      type
    } = yield take(CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS)

    if (form === formListener && searchFieldInteraction) {
      const skipGeneratedNotifications = propertyChanged === 'dataId' || false
      yield call(
        handleInteractiveModalMessages,
        form,
        messages,
        cancelledChanges,
        record,
        propertyChanged,
        skipGeneratedNotifications
      )
    }

    if (form === formListener && addedNewLineItem) {
      yield fork(handleLineItemModals, form, record, addedLineNumber, messages)
    }
  }
}

export function* onBlurListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take([INDEX_SEARCH_CONSTANTS.BLUR, BLUR])

    const fieldsToValidate = [
      'customerId',
      'shipToId',
      'orderedById',
      'writerId',
      'shipViaId',
      'shipDate',
      'dataId',
      'branchId',
      'warehouseId',
      'poNumber',
      'jobId'
    ]
    //

    if (form === formListener && fieldsToValidate.includes(propertyName)) {
      const formState = yield select(getFormSelector(form))
      const prevValue = getIn(formState, `fields.${propertyName}.prevValue`)
      if (value && prevValue !== value) {
        yield put(actions.updateValidationMessages(form, { propertyName }))
      }
    }
  }
}

export function* saveShipToListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(SAVE_SHIP_TO.SUCCESS)

    /* listens for save.success on Customer Ship To if its related to THIS form */
    if (form === formListener) {
      yield fork(propertyRefreshProcess, form, ['shipToId'])
    }
  }
}

export function* propertyRefreshProcess(form, propertyNames = []) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  let activeTab = getIn(formState, 'masterOptions.selectedPrimaryTab')

  const activeTabMap = {
    checkout: 'final',
    order: 'header'
  }

  if (activeTab && activeTabMap[activeTab]) {
    activeTab = activeTabMap[activeTab]
  }

  yield put(actions.propertyRefresh.request(form))

  const { response, error } = yield call(api.propertyRefresh, {
    guid,
    activeKey: activeTab,
    groupNames: [activeTab],
    properties: propertyNames
  })

  if (response) {
    yield put(actions.propertyRefresh.success(response, form))
  } else {
    yield put(actions.propertyRefresh.failure(error, form))
  }
}

// export function* accessOverrideCancellationListener(formListener) {
//   while (true) {
//     const {
//       meta: { form }
//     } = yield take(CONSTANTS.ON_PROPERTY_CHANGE.FAILURE)

//     if (form === formListener) {
//       const formState = yield select(getFormSelector(form))
//       let propChangePostData = getIn(formState, 'values.propChangePostData')
//       propChangePostData =
//         propChangePostData && propChangePostData.toJS
//           ? propChangePostData.toJS()
//           : {}

//       const {
//         propertyName,
//         value,
//         descriptionField,
//         description
//       } = propChangePostData

//       /*
//         revert values after an access override cancellation SVE 8/13/19
//       */
//       if (propertyName && value) {
//         yield put(
//           actions.updateFieldAndDescription(form, {
//             propertyName,
//             value,
//             descriptionField,
//             description
//           })
//         )
//       }
//     }
//   }
// }

export function* handleLineItemModals(form, record, lineNumber, messages) {
  if (messages && messages.length) {
    for (let i = 0; i < messages.length; i++) {
      const msg = messages[i]

      if (msg.type === 'Warning') {
        yield call(warningModal, msg.message, msg.modalTitle)
      }

      yield take(REMOVE_MODAL)
    }
  }

  if (
    record &&
    record.detail &&
    record.detail.lineItems &&
    record.detail.lineItems.length
  ) {
    const formState = yield select(getFormSelector(form))
    const hasItemGroups = getIn(formState, 'values.hasItemGroups') || false
    const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
      false

    const newLineItem = record.detail.lineItems.find(
      x => x.lineNumber === lineNumber
    )

    if (newLineItem && newLineItem.productInternalNotes) {
      const notesResponse = {
        dataId: newLineItem.dataId,
        description: newLineItem.description,
        note: newLineItem.productInternalNotes,
        lineNumber
      }

      yield call(displayProductNotesHandler, form, notesResponse)
    }

    if (newLineItem && newLineItem.repair) {
      yield fork(handleRepairItemModalProcess, form, lineNumber, newLineItem)
    }

    if (isMobile && !hasItemGroups) {
      /* IMPORTANT: Item groups needs to wait for another API call */
      yield put(actions.flagNewlyAddedLineNumber(form, { lineNumber }))
    }
  }

  return null
}

export function* checkTerminalProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const cardTerminal = yield select(state => getIn(state, 'auth.cardTerminal'))

  yield put(
    actions.storeUIFeatureState(form, {
      feature: 'cardTerminalChecked',
      featureState: true
    })
  )

  const { response } = yield call(api.readSalesOrder, {
    guid,
    action: 'terminalstatus'
  })

  if (!response?.isIdle) {
    yield call(warningModal, 'Device is currently in use', cardTerminal)
    yield take(REMOVE_MODAL)
  }

  return null
}

/* this function definitely needs another look */
export function* handleSalesOrderCancellation(form, dataId) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false
  // debugger

  if (!dataId) {
    return
  }

  if (isEditing) {
    let action
    if (dataId) {
      yield fork(cancelSalesOrderEditProcess, form, true)
      action = yield take([CANCELED, CONSTANTS.CANCEL_SALES_ORDER_EDIT.SUCCESS])
    } else {
      yield fork(cancelNewSalesOrderEditProcess, form, true)
      action = yield take([
        CANCELED,
        CONSTANTS.CANCEL_NEW_SALES_ORDER_EDIT.SUCCESS
      ])
    }
    // debugger

    if (
      action.type === CONSTANTS.CANCEL_SALES_ORDER_EDIT.SUCCESS ||
      action.type === CONSTANTS.CANCEL_NEW_SALES_ORDER_EDIT.SUCCESS
    ) {
      // debugger
      yield put(actions.clearOrder(form, true))
      yield put(updateFormTitle(form, 'Sales Order'))
      yield fork(readSalesOrderProcess, { value: dataId, form })
    } else if (action.type === CANCELED) {
      if (dataId !== getIn(formState, 'fields.dataId.prevValue')) {
        /* using new action revertDataId here in order to prevent SET_FIELD listeners from getting triggered -- SVE 6/29/20 */
        yield put(
          actions.revertDataId(form, {
            dataId: getIn(formState, 'fields.dataId.prevValue')
          })
        )
      }
    }
  } else {
    // debugger
    yield put(actions.clearOrder(form, true))
    // yield call(cancelEditAfterClear, form)
    // debugger
    yield put(updateFormTitle(form, 'Sales Order'))
    yield fork(readSalesOrderProcess, { value: dataId, form })
  }
}

export function* updateFormTitleProcess(form, payload) {
  const { isQuote = false, dataId = null } = payload

  if (dataId) {
    const title = isQuote
      ? `Price Quote - ${dataId}`
      : `Sales Order - ${dataId}`
    yield put(updateFormTitle(form, title))
  }

  return null
}

export function* initializeFormTitleListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.INITIALIZE_FORM_TITLE)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(updateFormTitleProcess, form, payload)
    }
  }
}

export default function* searchAreaSagas(form) {
  yield fork(propertyChangeTriggerListener, form)
  yield fork(propertyChangeModalHandlerListener, form)
  yield fork(onBlurListener, form)
  yield fork(saveShipToListener, form)
  yield fork(initializeFormTitleListener, form)
  // yield fork(accessOverrideCancellationListener, form)
}
