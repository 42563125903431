/* eslint import/prefer-default-export: 0 */
import { createAsyncRequestTypes } from 'utils'

export const OPEN_PO_RECEIVING_TICKET = '@@OPEN_PO_RECEIVING_TICKET@@'

export const RUN_PURCHASE_ORDER_PRINT_JOB = createAsyncRequestTypes(
  `${OPEN_PO_RECEIVING_TICKET}RUN_PURCHASE_ORDER_PRINT_JOB`
)

export const READ_PURCHASE_ORDER = createAsyncRequestTypes(
  `${OPEN_PO_RECEIVING_TICKET}READ_PURCHASE_ORDER`
)

export const CLOSE_OPEN_PO_RECEIVING_SCREEN = createAsyncRequestTypes(
  `${OPEN_PO_RECEIVING_TICKET}CLOSE_OPEN_PO_RECEIVING_SCREEN`
)

export const SET_NEXT_TAB = `${OPEN_PO_RECEIVING_TICKET}SET_NEXT_TAB`
