import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is, numToDollar, parseNumber } from 'utils'
import memoize from 'memoize-one'
import shortid from 'shortid'
import { startCase } from 'lodash'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

export default class PivotTable extends Component {
  static defaultProps = {
    // blackList: [],
    columnHeaderProp: 'columnHeader',
    data: [],
    highlightedColumn: 'All Warehouses',
    rowFormattedToDollar: [],
    meta: { columns: [] },
    cellStyle: { padding: 5, fontSize: 11, lineHeight: '11px' },
    headerStyle: { padding: '10px 5px', fontSize: 13, lineHeight: '13px' }
  }

  static propTypes = {
    // blackList: PropTypes.array,
    cellStyle: PropTypes.object,
    headerStyle: PropTypes.object,
    columnHeaderProp: PropTypes.string,
    data: PropTypes.array,
    highlightedColumn: PropTypes.string,
    rowFormattedToDollar: PropTypes.array,
    meta: PropTypes.shape({ columns: PropTypes.array })
  }

  getData = () => {
    const { meta, data } = this.props
    const oCols = meta.columns
    const oRows = data
    const responseKeys = Object.keys(data)

    const nRows = oCols
      .filter(x => !x.hidden)
      .sort((a, b) => a.position - b.position)
      .map(x =>
        responseKeys.reduce(
          (acc, next) => {
            let d = data[next][x.propertyName]
            switch (typeof d) {
              case 'number': {
                d = d.toLocaleString()
                break
              }
              case 'object': {
                if (is.null(d)) break
                d =
                  d && typeof d.value === 'number'
                    ? { ...d, value: d.value.toLocaleString() }
                    : { ...d, value: d.value }
                break
              }
              default: {
                d = d || ''
                break
              }
            }
            return acc.concat(d)
          },
          // [x.columnHeader]
          [{ ...x, value: x.columnHeader }]
        )
      )

    const header = oRows.reduce(
      (acc, next, i) => ((acc[i] = next[this.props.columnHeaderProp]), acc),
      { header: '' }
    )
    nRows.unshift(header)
    return nRows
  }

  highlightedColumnIndex = null

  getTable = memoize(
    data =>
      data.length > 1 && (
        <Table>
          <TableHead>
            <TableRow style={{ background: '#f5f7f7', height: 'auto' }}>
              <TableCell />
              {Object.keys(data[0])
                .filter(x => x !== 'header')
                .map((x, i) => {
                  if (data[0][x] === this.props.highlightedColumn) {
                    this.highlightedColumnIndex = i
                  }
                  // console.log('headerRow', data[0])
                  return (
                    <TableCell key={data[0][x]} style={this.props.headerStyle}>
                      {data[0][x]}
                    </TableCell>
                  )
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(1).map(x => (
              <TableRow key={shortid.generate()}>
                {x.map((y, i) => {
                  // console.log(x[i], data.slice(i))

                  /* handle the tooltips */
                  const val =
                    typeof y === 'object' &&
                    y !== null &&
                    Object.hasOwnProperty.call(y, 'value')
                      ? y.value
                      : y

                  let tooltip =
                    typeof y === 'object' &&
                    y !== null &&
                    Object.hasOwnProperty.call(y, 'tooltip')
                      ? y.tooltip
                      : ''

                  tooltip =
                    typeof tooltip === 'object' &&
                    tooltip !== null &&
                    Object.hasOwnProperty.call(tooltip, 'title')
                      ? Object.keys(tooltip).reduce((acc, next) => {
                        if (next !== 'title') {
                          acc = acc.concat(
                            `${startCase(next)}: ${tooltip[next]}\n`
                          )
                        }
                        return acc
                      }, `${tooltip.title}\n`)
                      : tooltip

                  // console.log('val', val, tooltip)
                  return (
                    <TableCell
                      style={
                        i === this.highlightedColumnIndex + 1
                          ? {
                            fontWeight: 'bold',
                            color: val < 0 ? 'red' : '',
                            padding: 5,
                            ...this.props.cellStyle
                          }
                          : {
                            color: val < 0 ? 'red' : '',
                            ...this.props.cellStyle
                          }
                      }
                      key={shortid.generate()}
                      title={tooltip}
                    >
                      {this.props.rowFormattedToDollar.includes(x[0].columnHeader) &&
                      i !== 0 &&
                      val !== null
                        ? numToDollar(parseNumber(val))
                        : val}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
  )

  render() {
    return this.getTable(this.getData())
  }
}
