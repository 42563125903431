import { isFunction } from 'lodash'
import createAsyncRequestTypes from './createAsyncRequestTypes'
import createAction from './createAction'

export default function(type, form) {
  type = isFunction(type) ? type() : type
  const TYPE =
    type.REQUEST && type.SUCCESS && type.FAILURE
      ? type
      : createAsyncRequestTypes(type)
  return {
    failure: (error, meta) => {
      if (typeof meta === 'string') {
        meta = { form: meta }
      } // formName...
      if (!meta) {
        meta = {
          form
        }
      }
      const errorArgs =
        typeof error === 'string'
          ? {
              error
            }
          : {
              ...error
            }
      return createAction(TYPE.FAILURE, errorArgs, null, true, meta || {})
    },
    request: (f, args, ...rest) => {
      if (typeof f === 'object') {
        f = form
      }
      return createAction(TYPE.REQUEST, { ...args, ...rest }, null, null, {
        apiRequest: true,
        form: f || form,
        thunk: true
      })
    },
    success: (response, meta) => {
      // debugger
      // console.log(f)
      // return createAction(TYPE.SUCCESS, response, f || form)
      if (typeof meta === 'string') {
        meta = { form: meta }
      } // formName...
      if (!meta) {
        meta = {
          form
        }
      }
      return createAction(TYPE.SUCCESS, response, null, false, meta || {})
    },
    try: (f, args, ...meta) => {
      if (typeof f === 'object') {
        f = form
      }
      // return createAction(TYPE.TRY, { ...args, ...rest }, f)
      return createAction(TYPE.TRY, { ...args }, null, false, {
        form: f,
        ...meta,
        thunk: true
      })
    },
    type: TYPE
  }
}
