import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import MobileScreen from 'mobile/components/MobileScreen'

const Dashboards = () => (
  <MobileScreen
    title="Dashboards"
    titleIcon="assessment"
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        padding: 5,
      }}
    >
      <div style={{ flex: '1', padding: 5, minWidth: 325 }}>
        <Skeleton animation="wave" variant="rect" width="350" height="250" />
      </div>
      <div style={{ flex: '1', padding: 5, minWidth: 325 }}>
        <Skeleton animation="wave" variant="rect" width="350" height="250" />
      </div>
      <div style={{ flex: '1', padding: 5, minWidth: 325 }}>
        <Skeleton animation="wave" variant="rect" width="350" height="250" />
      </div>
      <div style={{ flex: '1', padding: 5, minWidth: 325 }}>
        <Skeleton animation="wave" variant="rect" width="350" height="250" />
      </div>
    </div>
  </MobileScreen>
)

export default Dashboards