import moment from 'moment'

/**
 * converts a date string to a date object
 * @param {string} dateStr - string to transform
 * @returns {Date} date object
*/
const toDate = dateStr => {
  if (!dateStr) return undefined
  return moment(dateStr).toDate()
}

export default toDate
