import { component, options } from 'pages/PurchaseOrderInquiry'
import SendInfoSearch from 'components/Search/SendInfoSearch'
import createWrappedField from './createWrappedField'
import ConnectedSendInfoSearch from '../ConnectedSendInfoSearch'

export default createWrappedField({
  Component: SendInfoSearch,
  name: 'DDISendInfoSearch',
  ConnectedField: ConnectedSendInfoSearch,
  type: 'sendInfoSearch'
})
