/* eslint import/prefer-default-export: 0 */
import { createApiOptions, mockFetchProcess } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
// import * as CONSTANTS from './constants'

export const closeSalesOrder = guid => {
  const options = createApiOptions({
    method: 'post',
    body: { guid }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/close`, options)
}

export const propertyChange = ({ properties, groupNames, ...rest }) => {
  const options = createApiOptions({
    body: {
      properties,
      groupNames,
      ...rest
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/propertychange`, options)
}

export const removeLineItem = ({ lineNumber, guid }) => {
  const options = createApiOptions({
    method: 'post',
    body: { lineNumber, guid }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/removelineitem`, options)
}

export const propertyRefresh = ({ properties, activeKey, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      properties,
      activeKey,
      ...rest
    }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/propertyrefresh`, options)
}

export const changeLineItem = ({ lineNumber, properties, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      properties,
      lineNumber,
      ...rest
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/changelineitem`, options)
}

export const saveSalesOrder = ({
  dataId,
  generateId = false,
  guid,
  groupNames,
  suppressWarnings = false,
  keepEditing = true,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      generateId,
      guid,
      groupNames,
      suppressWarnings,
      keepEditing,
      ...rest
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/update`, options)
}

export const readSalesOrder = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/read`, options)
}

export const validateSalesOrder = ({
  dataId,
  generateId = false,
  guid,
  groupNames,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      generateId,
      guid,
      groupNames,
      ...rest
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/validateupdate`, options)
}

export const changeGridItem = ({
  lineNumber,
  properties,
  gridName,
  groupNames,
  ...rest
}) => {
  //
  const options = createApiOptions({
    method: 'post',
    body: {
      properties,
      lineNumber,
      gridName,
      groupNames,
      ...rest
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/changegriditem`, options)
}

export const removeGridItem = ({ lineNumber, guid, gridName, groupNames }) => {
  const options = createApiOptions({
    method: 'post',
    body: { lineNumber, guid, gridName, groupNames }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/removegriditem`, options)
}

export const validatePrintDocument = ({ guid, dataId, layoutId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, dataId, layoutId, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/validateprint`, options)
}

export const printSalesOrder = ({ guid, dataId, layoutId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, dataId, layoutId, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/print`, options)
}

export const printWorksheet = ({ guid, dataId, groupNames }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, dataId, groupNames }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/printworksheet`, options)
}

export const printOrderRecap = ({ guid, dataId, groupNames }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, dataId, groupNames }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/printorderrecap`, options)
}

export const printWarehousePick = ({ guid, dataId, groupNames = [] }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, dataId, groupNames }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/salesorder/printwarehousepick`,
    options
  )
}

export const getComment = ({ lineNumber, commentName, guid }) => {
  const options = createApiOptions({
    method: 'post',
    body: { lineNumber, commentName, guid }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorder/getcomment`, options)
}

export const setComment = ({
  lineNumber,
  commentName,
  comment,
  guid,
  storePermanently
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { lineNumber, commentName, comment, guid, storePermanently }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorder/setcomment`, options)
}

export const saveSalesOrderNotes = ({ dataId, notesName, note, guid }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, notesName, note, guid }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorder/notes/save`, options)
}

export const lockSalesOrderNotes = ({ dataId, guid, notesName }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, guid, notesName }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorder/notes/editlock`, options)
}

export const cancelSalesOrderNotesEdit = ({ dataId, guid, notesName }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, guid, notesName }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/salesorder/notes/canceledit`,
    options
  )
}

export const cancelSalesOrderEdit = ({
  dataId,
  guid,
  groupNames,
  customerId,
  createNew = false
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, guid, groupNames, customerId, createNew }
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/canceledit`, options)
}

export const sendSalesOrderDocument = ({ dataId, guid, layoutId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, guid, layoutId, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/send`, options)
}

/*
  deprecated, removed in favor of
  massChange API with lineitembulkadd action
  see addOptionsProcess in detailSagas -- SVE 10/31/19
*/
// export const lineItemBulkAdd = ({ bulkProperties, groupNames, guid, }) => {
//   const options = createApiOptions({
//     method: 'post',
//     body: { bulkProperties, groupNames, guid }
//   })
//   //
//   return callFetch(`${BASE_INFORM_API_URL}/salesorder/lineitembulkadd`, options)
// }

export const readLineItem = ({ dataId, lineNumber, guid, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, lineNumber, guid, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/readlineitem`, options)
}

export const massChange = ({
  action,
  properties,
  groupNames = ['header', 'detail'],
  guid,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { action, properties, groupNames, guid, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/masschange`, options)
}

export const getFastProductMeta = ({ newInstance = true, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { newInstance, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/fastproduct/meta`, options)
}

export const getFastProductDefaults = ({ warehouseId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { warehouseId, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/fastproduct/create`, options)
}

export const fastProductPropertyChange = ({
  properties = {},
  warehouseId,
  guid,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { properties, warehouseId, guid, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/fastproduct/propertychange`, options)
}

export const cancelFastProductEntry = ({ ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/fastproduct/close`, options)
}

export const saveFastProduct = ({
  dataId,
  warehouseId,
  properties = {},
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, warehouseId, properties, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/fastproduct/update`, options)
}

export const getFastProductCopyFromMeta = ({ dataId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/fastproduct/copyfrom/meta`, options)
}

export const getFastProductCopyFromDescriptions = ({ dataId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, ...rest }
  })
  //
  return callFetch(
    `${BASE_INFORM_API_URL}/fastproduct/copyfrom/searchdescriptions`,
    options
  )
}

export const fastProductCopyFromExecute = ({
  dataId,
  fromId,
  warehouseId,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, fromId, warehouseId, ...rest }
  })
  //
  return callFetch(`${BASE_INFORM_API_URL}/fastproduct/copyfrom/set`, options)
}

export const fastProductCopyFromCancel = ({ dataId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, ...rest }
  })
  //
  return callFetch(
    `${BASE_INFORM_API_URL}/fastproduct/copyfrom/cancel`,
    options
  )
}

export const changeItemGroup = ({
  gridName = 'itemGroups',
  lineNumber,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { gridName, lineNumber, ...rest }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/salesorder/itemgroups/changegriditem`,
    options
  )
}

export const cancelItemGroups = ({ guid }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/salesorder/itemgroups/cancel`,
    options
  )
}

export const itemGroupsPropertyChange = data => {
  const options = createApiOptions({
    method: 'post',
    body: { ...data }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/salesorder/itemgroups/propertyChange`,
    options
  )
}

export const makePayment = data => {
  const options = {
    response: {
      response: 'ok',
      error: null
    },
    timer: 500
  }

  return mockFetchProcess('/salesorder/payment', options)
}

export const voidPayment = data => {
  const options = {
    response: {
      response: 'voided',
      error: null
    },
    timer: 500
  }

  return mockFetchProcess('/salesorder/void/payment', options)
}

export const lockProductNote = ({ guid, dataId, notesName, lineNumber }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, dataId, notesName, lineNumber }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorder/notes/editlock`, options)
}

export const unlockProductNote = ({ guid, dataId, notesName, lineNumber }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, dataId, notesName, lineNumber }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/salesorder/notes/canceledit`,
    options
  )
}

export const saveProductNote = ({
  guid,
  dataId,
  lineNumber,
  notesName = 'ProductNotes',
  note,
  retainLockResolved = false
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, dataId, lineNumber, notesName, note, retainLockResolved }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorder/notes/save`, options)
}

export const partNumberPropertyChange = ({
  guid,
  action,
  uniqueKey,
  partNumber
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      guid,
      action,
      uniqueKey,
      partNumber
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorder/partnumber`, options)
}

export const saveLayoutForUserAuthentication = ({ guid, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      guid,
      ...rest
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/salesorder/saveuserlayoutaccess`,
    options
  )
}

export const recalculatePrices = data => {
  const options = createApiOptions({
    method: 'post',
    body: { ...data }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/salesorder/recalculateprices`,
    options
  )
}

export const creditInvoice = ({
  guid,
  dataId,
  groupNames = ['header', 'detail'],
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      guid,
      dataId,
      groupNames,
      ...rest
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesorder/creditinvoice`, options)
}

export const cancelEditAfterClear = ({ guid = null }) => {
  const options = createApiOptions({
    method: 'post',
    body: { guid, action: 'clear' }
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/canceledit`, options)
}
