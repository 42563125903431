import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { openScreen } from 'pages/Main/actions'
import { connect } from 'react-redux'

class OrderIdCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
  }

  onClick = e => {
    e.preventDefault()
    console.log(this.props)
    if (this.props.isModal) {
      /* 
        this.props.onSelect does not seem to exist,
        so this actually causes a silent error without
        this if condition -- SVE 3/23/2021
      */
      if (this.props.onSelect) {
        this.props.onSelect({
          api: this.props.api,
          columnApi: this.props.columnApi,
          data: this.props.data,
          node: this.props.node,
          rowIndex: this.props.rowIndex
        })
      }
    } else {
      //
      this.props.node.setSelected(false)
      this.props.dispatch(
        openScreen({
          name: 'purchaseOrderEntry',
          title: 'Purchase Order Entry',
          dataId: this.props.value
        })
      )
    }
    //  this.props.context.container.props.linkToScreen('salesOrder', { dataId: this.props.data.dataId })
  }

  render() {
    console.log(this)
    return (
      <div>
        <a onClick={this.onClick}>
          <strong>{this.props.value}</strong>
        </a>
      </div>
    )
  }
}

export default connect()(OrderIdCell)
