/* eslint import/prefer-default-export: 0 */
import moment from 'moment'

export const isFormValid = props => {
  const eventType = props?.fields?.eventType?.value || ''
  const subject = props?.fields?.subject?.value || ''
  const startDate = props?.fields?.startDate?.value || null
  const endDate =
    props?.fields?.endDate && props?.fields?.endDate?.value
      ? props?.fields?.endDate?.value
      : null
  const referenceType = props?.fields?.referenceType?.value || ''
  const referenceId =
    props?.fields?.referenceId && props?.fields?.referenceId?.value
      ? props.fields.referenceId.value
      : null
  const timeSelectionsValid = moment(endDate) >= moment(startDate)

  if (
    (eventType && startDate && endDate && subject && timeSelectionsValid) ||
    (eventType && eventType === 'T' && startDate && subject) ||
    (eventType &&
      startDate &&
      endDate &&
      subject &&
      referenceType &&
      referenceId &&
      timeSelectionsValid) ||
    (eventType &&
      eventType === 'T' &&
      startDate &&
      referenceType &&
      referenceId &&
      subject)
  ) {
    return true
  }
  return false
}
