/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const runPurchaseOrderPrintJob = createAsyncFormAction(
  CONSTANTS.RUN_PURCHASE_ORDER_PRINT_JOB
)

export const readPurchaseOrder = createAsyncFormAction(
  CONSTANTS.READ_PURCHASE_ORDER
)

export const closeOpenPOReceivingScreen = createAsyncFormAction(
  CONSTANTS.CLOSE_OPEN_PO_RECEIVING_SCREEN
)

export const setNextTab = (form, { tab }) =>
  createAction(CONSTANTS.SET_NEXT_TAB, { tab }, form)
