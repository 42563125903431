import createSearch from 'components/Search/createSearch'
import behaviors from './behaviors'
import DropDownComponent from './DropDownComponent'
import TextInput from './TextInput'
import * as actions from './actions'
/* 
  removed popoverStyle width: 500, better to let the contents decide the width.
  works better on both iPad and iPhone this way -- SVE 6/24/20
*/
const PurchaseOrderSearch = createSearch({
  // actions: {
  //   executeInquiry: actions.executeInquiry.try,
  //   exactMatch: actions.exactMatch,
  //   exactMatchFromForm: actions.exactMatchFromForm,
  //   reset: actions.reset,
  //   rowSelected: actions.rowSelected
  // },
  name: 'PurchaseOrderSearch',
  type: 'PurchaseOrderSearch',
  behaviors,
  DropDownComponent,
  TextInput
})

export default PurchaseOrderSearch
