// /**
//  * pulls a value from a state object and returns a bool
//  * @param {object} val - state object
//  * @param {function} discriminator - function that grabs state value
//  * @returns {boolean} - returns true or false
//  */
// const is = (val, discriminator) => discriminator(val) // discriminator should return bool
// export default is

export default {
  array: value => Array.isArray(value),
  bool: value => typeof value === 'boolean',
  decimal: value => Number.isFinite(value) && Math.ceil(value) !== value,
  falsy: value => !value,
  fn: value => value && typeof value === 'function',
  integer: value => Number.isInteger(value),
  null: value => value === null,
  number: value => Number.isFinite(value),
  string: value => typeof value === 'string',
  truthy: value => !!value,
  undefined: value => typeof value === 'undefined'
}
