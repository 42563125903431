import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  TimePicker as MuiTP,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import { IconButton, Typography, Icon, InputAdornment } from '@material-ui/core'
import { noop, toDate, plainDeepEqual } from 'utils'
import moment from 'moment'
// import './styles/DatePicker.css'

import {
  darkGrey,
  ddiBlue,
  informBlue,
  mediumGrey,
  white
} from '../theme/colors'

// function isValidDate(date) {
//   return (
//     date &&
//     Object.prototype.toString.call(date) === '[object Date]' &&
//     !isNaN(date)
//   )
// }

function isValidDate(date) {
  return date && !isNaN(Date.parse(date))
}

export default class TimePicker extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    displayFormat: PropTypes.string,
    displayLabel: PropTypes.bool,
    disablePropChange: PropTypes.bool,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func,
    format: PropTypes.string,
    hintStyle: PropTypes.object,
    hintText: PropTypes.string,
    iconStyles: PropTypes.object,
    inputStyle: PropTypes.object,
    id: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFound: PropTypes.bool,
    label: PropTypes.string,
    labelStyles: PropTypes.object,
    loadingIndicator: PropTypes.string,
    locale: PropTypes.string,
    placeholder: PropTypes.string,
    // propertyChange: PropTypes.func.isRequired,
    propertyName: PropTypes.string.isRequired,
    style: PropTypes.object,
    textFieldStyle: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }

  static defaultProps = {
    initialValue: null,
    disabled: false,
    disablePropChange: false,
    displayFormat: 'hh:mm a',
    displayLabel: true,
    dispatch: noop,
    format: 'MM/DD/YY hh:mm a',
    hintStyle: { display: 'none', fontSize: 12 },
    hintText: 'Select a Time',
    iconStyles: {},
    inputStyle: { marginTop: 0 },
    isFetching: false,
    isFound: false,
    label: '',
    labelStyles: {},
    loadingIndicator: '',
    locale: 'en-US',
    placeholder: 'Select a Time',
    // textFieldStyle: { height: 26, marginTop: 8, width: '100%' },
    style: {},
    textFieldStyle: {
      fontSize: 11,
      width: '100%',
      height: 48
    },
    type: 'Field',
    value: null
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.dirty && isValidDate(nextProps.value)) {
      return {
        value: nextProps.value,
        dirty: false
      }
    }
    return null
  }

  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      labelDisplayCSS: 'none',
      value: props.initialValue ? props.initialValue : props.value ? props.value : null
    }
  }

  // componentDidMount() {
  //   const { value } = this.state
  //   if (value) {
  //     this.setState({ labelDisplayCSS: 'none' })
  //   }
  // }

  onChange = date => {
    // console.log('timePickerOnChange', date, moment(date), this.props)
    const value = date.format(this.props.format)
    if (date) {
      this.setState(
        { labelDisplayCSS: 'none', value, dirty: true },
        () => {
          // this.props.setField(new Date(moment(date).format(this.props.format)))
          this.props.setField(value)
        }
      )
    }
  }
  // onFocus = e => {
  //   // e.preventDefault()
  //   //  this.dp.focus()
  // }

  onBlur = e => {
    e.persist()
    this.setState({ dirty: false }, () => {
      if (this.state.value) {
        this.props.onBlur(this.state.value)
      } else {
        this.props.onBlur(null)
      }
    })
  }

  render() {
    const {
      displayFormat,
      initialValue,
      disabled,
      id,
      format,
      value,
      isFound,
      displayLabel,
      dispatch,
      hintStyle,
      hintText,
      label,
      locale,
      placeholder,
      textFieldStyle,
      // propertyChange,
      propertyName,
      dataId,
      isFetching,
      loadingIndicator,
      labelStyles,
      inputStyle,
      style
    } = this.props
    // const newValue = typeof value !== 'object' ? toDate(value) : value
    // console.log(value, initialValue, newValue)
    // console.log(newValue, typeof newValue, value)
    // console.log(newValue, this.state.value)
    const baseIconStyles = {
      height: 20,
      pointerEvents: 'none',
      position: 'absolute',
      right: 5,
      top: 15,
      width: 20
    }

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div
          className="ddi-picker-with-label"
          style={{ position: 'relative' }}
          tabIndex={0}
          onFocus={this.onFocus}
        >
          <MuiTP
            className="ddi-picker"
            {...this.props}
            hintStyle={hintStyle}
            label={label}
            placeholder="Please Select a Time"
            format={displayFormat}
            locale={locale}
            id={id}
            value={this.state.value}
            disabled={disabled}
            onChange={this.onChange}
            style={style}
            ref={c => (this.dp = c)}
            fullWidth
            onBlur={this.onBlur}
          />
          {isFetching && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 5
              }}
            >
              <img alt="loading" src={loadingIndicator} />
            </div>
          )}
        </div>
      </MuiPickersUtilsProvider>
    )
  }
}
