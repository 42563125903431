import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const inquireMyLocks = () => {
  const options = createApiOptions({
    method: 'post',
    body: {}
  })

  return callFetch(`${BASE_INFORM_API_URL}/mylocks/inquire`, options)
}

export const unlockMyLocks = ({ ids }) => {
  const options = createApiOptions({
    method: 'post',
    body: { ids }
  })

  return callFetch(`${BASE_INFORM_API_URL}/mylocks/unlock`, options)
}