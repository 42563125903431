import behaviors from './behaviors'
import createSearch from '../createSearch'
// import DropDownComponent from './BinSearch'

const SerialNumberSearch = createSearch({
  name: 'SerialNumberSearch',
  behaviors,
  popoverStyle: { width: 500 },
  // DropDownComponent,
  meta: {
    allowInstantSearch: true,
    allowSearchAll: true,
    minimumKeywordLength: 1,
    allowNavigation: false,
    allowSpecialProducts: false,
    hasFilters: false,
    hideToggle: true
  }
})

export default SerialNumberSearch
