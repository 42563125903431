import MaskedInput from 'react-text-mask'
import React from 'react'
import { trimNonWord } from 'utils'
/* eslint-disable no-nested-ternary */
const mask = input => {
  input = trimNonWord(input)
  const fiveDigit = [/\d/, /\d/, /\d/, /\d/, /\d/]
  const rest = ['-', /\d/, /\d/, /\d/, /\d/]
  return input.length <= 5 ? fiveDigit : fiveDigit.concat(rest)
}

export default function ZipCodeTextField(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={!!props.value}
    />
  )
}
