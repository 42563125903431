import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Checkbox, Icon, FormControlLabel } from '@material-ui/core'
import { getIn } from 'utils'
import { toggleCheck } from '../actions'

// import { confirm } from './actions'
export const mapStateToProps = state => ({
  checked: getIn(state, 'dashboard.checked')
})
export const mapDispatchToProps = dispatch => ({
  // hideTips: () => dispatch(hideTips.request())
  toggleCheck: () => dispatch(toggleCheck())
})

export class Tips extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    toggleCheck: PropTypes.func.isRequired
  }
  static defaultProps = { checked: false }
  componentDidMount() {
    // window.addEventListener('keyup', this.handleKeyUp)
  }
  componentWillUnmount() {
    // window.removeEventListener('keyup', this.handleKeyUp)
  }
  // handleKeyUp = (event) => {
  //   if (event.key === 'Enter') {
  //     const { dispatch, id } = this.props
  //     dispatch(confirm(id))
  //   }
  // }
  render() {
    const { checked } = this.props
    return (
      <div className="row">
        <div className="col-sm-1">
          <Icon style={{ marginRight: 50 }}>info_outline</Icon>
        </div>
        <div className="col-sm-11">
          <p style={{ fontSize: 14 }}>
            Key Performance Indicators (KPIs) from the tab on the left can be
            added to the Dashboard by drag and drop or double-clicking.<br />
            View details for each KPI by clicking on the respective summary
            chart.<br />
            Right click on a summary chart to view additional options.
          </p>
          <FormControlLabel
            label="Do not show again"
            control={
              <Checkbox
                checked={checked}
                onChange={(_, isInputChecked) => {
                  this.props.toggleCheck()
                }}
              />
            }
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tips)
