import toNumeral from './toNumeral'

/**
 * Convert given value to a number type. If conversion fails, returns NaN.
 * @param {number|string} num - number
 * @returns {Number} NaN if conversion fails.
 */
export default function parseNumber(value) {
  const n = toNumeral(value)
  return n ? n.value() : NaN
}
