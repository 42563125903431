import React, { Component } from 'react'
import { connect } from 'react-redux'
import { confirm } from 'modals/actions'
import { DDIButton } from 'ddiForm/wrapped'
import { getIn } from 'utils'
import { notifyInternalNotesModalClosed } from 'modals/NotesModal/actions'

import * as actions from 'pages/SalesOrder/actions'

const marginRight = {
  marginRight: 5
}

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  return {
    notesModalEnabled: getIn(formState, 'notesModalEnabled') || false
  }
}

export class NotesModalActions extends Component {
  handleSave = () => {
    const { dispatch, form, modal } = this.props
    dispatch(
      actions.saveSalesOrderNotes.try(form, {
        deleteNote: false,
        type: modal.options.data.type,
        modal
      })
    )
  }

  handleDelete = () => {
    const { dispatch, form, modal } = this.props
    dispatch(
      actions.saveSalesOrderNotes.try(form, {
        deleteNote: true,
        type: modal.options.data.type,
        modal
      })
    )
  }

  handleEdit = () => {
    const { dispatch, form, modal } = this.props
    dispatch(
      actions.lockSalesOrderNotes.try(form, {
        type: modal.options.data.type
      })
    )
  }

  handleExit = () => {
    const { dispatch, form, modal, notesModalEnabled } = this.props

    if (notesModalEnabled) {
      dispatch(
        actions.cancelSalesOrderNotesEdit.try(form, {
          type: modal.options.data.type
        })
      )
    }
    dispatch(notifyInternalNotesModalClosed(form))
    dispatch(confirm(form, modal.id))
  }

  render() {
    const { notesModalEnabled } = this.props
    return (
      <>
        {notesModalEnabled && (
          <>
            <DDIButton
              onClick={this.handleSave}
              variant="contained"
              label="Save"
              tabIndex={0}
              preventAutoDisable
              style={marginRight}
            />
            <DDIButton
              onClick={this.handleDelete}
              variant="contained"
              label="Delete"
              tabIndex={0}
              preventAutoDisable
              style={marginRight}
            />
          </>
        )}
        {!notesModalEnabled && (
          <DDIButton
            onClick={this.handleEdit}
            variant="contained"
            label="Edit"
            tabIndex={0}
            preventAutoDisable
            style={marginRight}
          />
        )}
        <DDIButton
          onClick={this.handleExit}
          variant="contained"
          label="Exit"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(NotesModalActions)
