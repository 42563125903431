
import { component, options } from 'pages/SalesOrderInquiry'
// import IS from 'components/Search/IndexSearch'
import SalesOrderSearch from 'components/Search/SalesOrderSearch'
import createWrappedField from './createWrappedField'

import ddiForm from '../ddiForm'
// const DDIIndexSearch = props => <Field component={IS} {...props} />
const meta = {
  allowInstantSearch: false,
  allowSearchAll: true,
  hasFilters: false
}

export default createWrappedField({
  Component: SalesOrderSearch,
  name: 'DDISalesOrderSearch',
  DropDownComponent: ddiForm(options)(component),
  meta
})
