import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { IconButton, Icon } from '@material-ui/core'
import * as actions from '../../IndexSearch/actions'

const mapDispatchToProps = dispatch => ({
  clearFilterField: ({ form, parentField, filterType }) =>
    dispatch(
      actions.clearFilterField({
        form,
        parentField,
        filterType,
        propertyName: parentField
      })
    )
})

class ClearFilterCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired
  }

  clearField = () => {
    const {
      clearFilterField,
      value: { form, parentField },
      data: {
        description,
        fileAssociationSearchType: { dataId }
      }
    } = this.props
    const filterType = dataId || description
    clearFilterField({
      form,
      parentField,
      filterType
    })
  }

  render() {
    return (
      <IconButton
        onClick={this.clearField}
        style={{
          // display: 'block',
          height: 16,
          // margin: '0 auto',
          width: 16
        }}
      >
        <Icon style={{ color: '#d9534f', fontSize: 16 }}>highlight_off</Icon>
      </IconButton>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ClearFilterCell)
