import { spawn, all } from 'redux-saga/effects'
import authSagas from 'auth/sagas'
import modalSagas from 'modals/sagas'
import snackSagas from 'snackbar/sagas'
import appSagas from 'pages/Main/sagas'
import accessOverrideSaga from 'access/accessOverrideMiddleware'
import signupSagas from 'pages/SignUp/sagas'
// import layoutListeners from 'pages/Layout/sagas'
// import salesOrderSearchSagas from 'ddiForm/SalesOrderSearch/sagas'
import { apiErrorHandlerListener } from 'services/sagas'
import mobile from 'mobile/sagas'
import onAuth from './onAuth'

export default function* rootSaga(injectReducer) {
  // yield all([
  yield spawn(mobile)
  yield spawn(accessOverrideSaga)
  yield spawn(authSagas, injectReducer)
  yield spawn(apiErrorHandlerListener)
  yield spawn(modalSagas)
  yield spawn(appSagas)
  yield spawn(onAuth)
  yield spawn(signupSagas)
  yield spawn(snackSagas)

  // spawn(layoutListeners)
  // ])
}
