// export default function isReactComponent(component) {
//   return (
//     typeof component === 'function' &&
//     component.prototype &&
//     !!component.prototype.isReactComponent
//   )
// }
import { isValidElementType } from 'react-is'

export default isValidElementType
