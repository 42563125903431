import { select, call, take, put } from 'redux-saga/effects'

/**
 * binds an API listener when provided with an action and an API function
 * @param {object} action - action object with type/payload signature
 * @param {function} apiFn - API function
 * @param {...object} rest - selectors object
 * @returns {object} returns an object with action success / failure
 */
export default function* createApiListener(action, apiFn, ...rest) {
  while (true) {
    const act = yield take()
    if (act.type === action.type.REQUEST) {
      // console.log('ok', act)
      const { payload } = act
      let args = {}
      let selectors
      if (rest.length) {
        selectors = rest[0].selectors
      }
      if (selectors) {
        for (const selector in selectors) {
          if (selectors.hasOwnProperty(selector)) {
            //eslint-disable-line
            args[selector] = yield select(selectors[selector])
          }
        }
      }

      if (payload) {
        args = { ...args, ...payload }
      }
      const { response, error } = yield call(apiFn, args)
      if (response) {
        yield put(action.success(response))
      } else {
        yield put(action.failure(error))
      }
    }
  }
}
