import {
  take,
  select,
  call,
  put,
  cancel,
  fork,
  actionChannel
} from 'redux-saga/effects'

import { api } from 'services'
import { getIn, mapColumnHeaders } from 'utils'
import {
  EXACT_MATCH_SEARCH,
  GET_TOOLTIP,
  PARTIAL_MATCH_SEARCH
} from './constants'

import GridCell from './GridCell'

export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(exactMatchProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* exactMatchProcess({
  form,
  keyword,
  isFromBin,
  propertyName,
  warehouseId,
  productId,
  uom,
  thunk
}) {
  const params = {
    keyword,
    isFromBin,
    warehouseId,
    productId,
    uom
  }

  const { response, error } = yield call(api.binExactMatchSearch, params)
  // on error?
  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchProcess({
  form,
  thunk,
  includeSystemBins,
  keyword,
  onCellSelected,
  onHandOnly,
  productId,
  propertyName,
  uomId,
  warehouseId
}) {
  const params = {
    includeSystemBins,
    keyword,
    onHandOnly,
    productId,
    propertyName,
    uomId,
    warehouseId
  }
  // debugger
  const { response, error } = yield call(api.binPartialMatchSearch, params)
  /*
  const { response, error } = yield call(api.partialMatchSearch, {
    indexSearchType,
    keyword,
    pageNumber,
    token,
    isFiltered
  })
  */
  if (response) {
    // const { partialMatchResults } = response
    // const { columnHeaders, results: rowData, ...meta } = partialMatchResults
    // const columnDefs = columnHeaders ? columnHeaders.map(mapColumnHeaders) : []
    // const grid = {
    //   columnDefs,
    //   meta,
    //   rowData: rowData || []
    // }

    const columnDefs = [
      {
        cellRendererFramework: GridCell,
        cellRendererParams: { onCellSelected },

        field: 'dataId',
        headerName: 'Bin'
      },
      {
        editable: false,
        field: 'description',
        headerName: 'Description'
      }
    ]
    const rowData = response.results || []
    const grid = {
      meta: {},
      rowData,
      columnDefs
    }
    yield put({
      // meta: { form, propertyName },
      meta: { form, thunk },
      payload: {
        grid,
        warehouseIdAndDescription: response.warehouseIdAndDescription,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchSearchListener() {
  const channel = yield actionChannel(PARTIAL_MATCH_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)

    const {
      payload: {
        includeSystemBins,
        keyword,
        onHandOnly,
        productId,
        propertyName,
        uomId,
        warehouseId,
        onCellSelected
      },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield call(partialMatchProcess, {
      form,
      thunk,
      includeSystemBins,
      keyword,
      onHandOnly,
      productId,
      propertyName,
      uomId,
      warehouseId,
      onCellSelected
    })
  }
}

export function* getTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_TOOLTIP.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getTooltipProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* getTooltipProcess({
  form,
  thunk,
  propertyName,
  apiName = 'getIndexSearchTooltip',
  ...rest
  // indexSearchType,
  // propertyName,
  // recordName,
}) {
  const { response, error } = yield call(api[apiName], {
    // indexSearchType,
    // propertyName,
    // recordName
    propertyName,
    ...rest
  })

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: { ...response, propertyName },
      type: GET_TOOLTIP.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: { ...error, propertyName },
      error: true,
      type: GET_TOOLTIP.FAILURE
    })
  }
}

export default function* binSearchSagas() {
  yield fork(partialMatchSearchListener)
  yield fork(exactMatchSearchListener)
  // yield fork(findNextExactMatchListener)
  // yield fork(findPrevExactMatchListener)
  // yield fork(getSearchFiltersListener)
  yield fork(getTooltipListener)
}
