import React, { memo } from 'react'
import { Button, DialogActions } from '@material-ui/core'

const Footer = props => (
  <DialogActions>
    <Button
      variant="contained"
      color="primary"
      onClick={props.onSendClick}>
      Send
    </Button>
    <Button onClick={props.onClose}>Cancel</Button>
  </DialogActions>
)

export default memo(Footer)
