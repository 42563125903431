import React from 'react'
import TooltipField from 'hoc/TooltipField'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { openScreen as openScreenAction } from 'pages/Main/actions'

import { Button } from '@material-ui/core'

export const OrderLinkWithTooltip = ({
  value,
  valueFormatted,
  data,
  ...other
}) => {
  const dispatch = useDispatch()
  // console.log('OrderLinkWithTooltip', value, valueFormatted, data)

  const openSalesOrderScreen = e => {
    e.stopPropagation()
    if (value) {
      dispatch(
        openScreenAction({
          image: 'sales_order_entry_32.png',
          imageFileName16: 'sales_order_entry_16.png',
          description: 'Sales Order',
          name: 'salesOrder',
          route: 'salesorder',
          title: 'Sales Order',
          dataId: value,
          newInstance: true,
          read: true
        })
      )
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        height: 27
      }}
    >
      <Button
        size="small"
        onClick={openSalesOrderScreen}
        style={{
          color: '#517b9c',
          justifyContent: 'start',
          textDecoration: 'underline',
          textAlign: 'left'
        }}
      >
        {valueFormatted || value}
      </Button>
    </div>
  )
}

export default TooltipField({
  recordNameKey: 'value',
  type: 'salesOrder',
  position: 'right-end',
  sensitivity: 100,
})(OrderLinkWithTooltip)
// export default OrderLinkWithTooltip
