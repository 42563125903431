import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const getNextPeriod = ({ period }) => {
  const options = createApiOptions({
    body: { period },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesjournalreports/nextperiod`, options)
}

export const getPreviousPeriod = ({ period }) => {
  const options = createApiOptions({
    body: { period },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesjournalreports/previousperiod`, options)
}
