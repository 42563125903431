/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

// export const listActivities = ({
//   form,
//   dataId,
//   parentId = '',
//   parentType = '',
//   summary = false
// }) => {
//   const options = createApiOptions({
//     body: { dataId, parentId, parentType, summary },
//     method: 'post'
//   })

//   return callFetch(`${BASE_INFORM_API_URL}/${form}/activities`, options)
// }
export const listActivities = ({
  form,
  dataId,
  parentId = '',
  parentType = '',
  groupNames,
  summary = false
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      groupNames,
      parentId,
      parentType,
      summary },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/read`, options)
}
