import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const cancelShipToEditLock = ({ dataId, parentId }) => {
  const options = createApiOptions({
    body: { dataId, parentId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customershipto/canceledit`, options)
}

export const createShipTo = ({ dataId, parentId, lockCustomer = true }) => {
  const options = createApiOptions({
    body: { dataId, parentId, lockCustomer },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customershipto/create`, options)
}

export const generateShipToId = ({ parentId }) => {
  const options = createApiOptions({
    body: { parentId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customershipto/nextId`, options)
}

export const getProductUOM = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/getuom`, options)
}

export const deleteCustomerShipTo = ({ dataId, parentId }) => {
  const options = createApiOptions({
    body: { dataId, parentId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customershipto/delete`, options)
}


export const getCustomerShipTo = ({ dataId, groupNames, ...rest }) => {
  // debugger
  const options = createApiOptions({
    body: { dataId, groupNames, ...rest },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/customershipto/read`, options)
  // return callFetch(`${BASE_INFORM_API_URL}/customer/read2`, options)
}
