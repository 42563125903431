import { TRY_OPEN_SCREEN } from 'mobile/constants'
import { OPEN_SCREEN, SET_FIELD } from 'ddiForm/constants'

let data
const middleware = store => next => action => {
  if (action.type === TRY_OPEN_SCREEN) {
    data = action.payload
  }
  if (action.type === OPEN_SCREEN.SUCCESS) {
    action.payload = {
      ...action.payload,
      data
    }
    data = null
  }

  if (
    action.type === SET_FIELD &&
    (action?.payload?.propertyName === 'customerId' ||
      action?.payload?.propertyName === 'customer' ||
      ((action?.meta?.form === 'mobileContacts' ||
        action?.meta?.form === 'mobileInvoiceSummary') &&
        action?.payload?.propertyName === 'dataId'))
  ) {
    action.meta.mobile = true
    action.meta.customer = action.payload.value
    action.meta.description = action.payload?.results?.description || ''
  }
  return next(action)
}

export default middleware
