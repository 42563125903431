import { toJSON, createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import * as CONSTANTS from './constants'

export const deleteUserMails = ({ mails = [], guId }) => {
  const options = createApiOptions({
    body: { userMailDetails: mails, guId },
    method: 'post'
  })
  return callFetch(`${CONSTANTS.USER_MAIL_URL}email/delete`, options)
}

export const refreshMail = () => {
  const options = createApiOptions({ method: 'post' })
  return callFetch(`${BASE_INFORM_API_URL}/main/mail/sync`, options)
}

export const refreshOpenMail = ({ guId }) => {
  const options = createApiOptions({
    body: { guId },
    method: 'post'
  })
  return callFetch(`${CONSTANTS.USER_MAIL_URL}refresh`, options)
}

export const openMail = ({ dataId, guId, mailNumber }) => {
  const options = createApiOptions({
    body: {
      dataId,
      mailNumber,
      guId
    },
    method: 'post'
  })
  return callFetch(`${CONSTANTS.USER_MAIL_URL}email/open`, options)
}
export const respondMail = ({ dataId, guId, mailNumber }) => {
  const options = createApiOptions({
    body: {
      dataId,
      mailNumber,
      guId
    },
    method: 'post'
  })
  return callFetch(`${CONSTANTS.USER_MAIL_URL}email/respond`, options)
}

export const createUserMail = ({
  toUserIds,
  subject,
  details,
  highPriority,
  guId
}) => {
  const options = createApiOptions({
    body: {
      toUserIds,
      subject,
      details,
      highPriority,
      guId
    },
    method: 'post'
  })
  return callFetch(`${CONSTANTS.USER_MAIL_URL}email/send`, options)
}

export const openMailScreen = ({ pin, username, password }) => {
  const options = createApiOptions({
    body: {
      credentials: {
        pin,
        username,
        password
      }
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/usermail/meta`, options)
}

export const printUserMail = ({ dataId, mailNumber, guid, ...rest }) => {
  const options = createApiOptions({
    body: {
      dataId,
      mailNumber,
      guid,
      ...rest
    },
    method: 'post'
  })
  return callFetch(`${CONSTANTS.USER_MAIL_URL}print`, options)
}

export const closeMail = ({ guid }) => {
  const options = createApiOptions({
    body: { guid },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/usermail/close`, options)
}

export const getEmailFaxLog = ({ guid }) => {
  const options = createApiOptions({
    body: { guid },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/usermail/emailfaxlog/open`, options)
}

export const resendEmailFaxLogItem = ({ dataId, guid }) => {
  const options = createApiOptions({
    body: { dataId, guid },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/usermail/emailfaxlog/resend`,
    options
  )
}

export const deleteEmailFaxLogItem = ({ guid, logs }) => {
  const options = createApiOptions({
    body: { guid, logs },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/usermail/emailfaxlog/delete`,
    options
  )
}
