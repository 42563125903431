import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const SEND_INFO_SEARCH = '@@SendInfoSearch/'
export const POPULATE_FIELD = `${SEND_INFO_SEARCH}POPULATE_FIELD`
export const BLUR = `${SEND_INFO_SEARCH}BLUR`
export const FOCUS = `${SEND_INFO_SEARCH}FOCUS`
export const CLOSE_SEARCH = `${SEND_INFO_SEARCH}CLOSE_SEARCH`
export const EXACT_MATCH_SEARCH = createAsyncRequestTypes(
  `${SEND_INFO_SEARCH}EXACT_MATCH_SEARCH`
)
export const PARTIAL_MATCH_SEARCH = createAsyncRequestTypes(
  `${SEND_INFO_SEARCH}PARTIAL_MATCH_SEARCH`
)
export const FIND_NEXT = createAsyncRequestTypes(`${SEND_INFO_SEARCH}FIND_NEXT`)
export const FIND_PREV = createAsyncRequestTypes(`${SEND_INFO_SEARCH}FIND_PREV`)
export const TOGGLE_FILTER = `${SEND_INFO_SEARCH}TOGGLE_FILTER`
export const GET_TOOLTIP = createAsyncRequestTypes(
  `${SEND_INFO_SEARCH}GET_TOOLTIP`
)
export const GET_SEARCH_FILTERS = createAsyncRequestTypes(
  `${SEND_INFO_SEARCH}GET_SEARCH_FILTERS`
)

// export const ORDER_SEARCH_URL = `${BASE_INFORM_API_URL}/search/salesordernumber` // dup with lowere one..
export const SEND_DOCUMENT_PARTIAL_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/sendinfo/partialmatch`
export const SEND_DOCUMENT_EXACT_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/sendinfo/exactmatch`
export const SEND_DOCUMENT_CONTACTS_SEARCH_URL = `${BASE_INFORM_API_URL}/search/sendinfo/companyContacts`
// export const FIND_NEXT_SEARCH_URL = `${BASE_INFORM_API_URL}/search/findnext`
// export const FIND_PREV_SEARCH_URL = `${BASE_INFORM_API_URL}/search/findprevious`
// export const GET_TOOLTIP_URL = `${BASE_INFORM_API_URL}/search/indexsearch/summary`
// export const GET_SEARCH_FILTER_URL = `${BASE_INFORM_API_URL}/search/indexsearch/filters`

export const OPEN_FILTERS_GRID = `${SEND_INFO_SEARCH}OPEN_FILTERS_GRID`
export const CLOSE_FILTERS_GRID = `${SEND_INFO_SEARCH}CLOSE_FILTERS_GRID`
export const CLEAR_FILTER_FIELD = `${SEND_INFO_SEARCH}CLEAR_FILTER_FIELD`
export const SET_TEXT_FILTER_FIELD = `${SEND_INFO_SEARCH}SET_TEXT_FILTER_FIELD`
export const RESET_FILTERS = `${SEND_INFO_SEARCH}RESET_FILTERS`
export const CLEAR_SEARCH = `${SEND_INFO_SEARCH}CLEAR_SEARCH`
export const CONTACTS_SEARCH = createAsyncRequestTypes(
  `${SEND_INFO_SEARCH}CONTACTS_SEARCH`
)
