import { BASE_INFORM_API_URL } from 'services/constants'
import { createAsyncRequestTypes } from 'utils'

export const SALES_ORDER_SEARCH = '@@SALES_ORDER_SEARCH/'
export const TAB_CHANGE = `${SALES_ORDER_SEARCH}TAB_CHANGE`
export const SET_FIELD = `${SALES_ORDER_SEARCH}SET_FIELD`

export const FIND = createAsyncRequestTypes(`${SALES_ORDER_SEARCH}FIND`)
export const OPEN = createAsyncRequestTypes(`${SALES_ORDER_SEARCH}OPEN`)
export const EXACT_MATCH = createAsyncRequestTypes(
  `${SALES_ORDER_SEARCH}EXACT_MATCH`
)

export const OPEN_DROPDOWN = createAsyncRequestTypes(
  `${SALES_ORDER_SEARCH}OPEN_DROPDOWN`
)
export const ROW_SELECTED = `${SALES_ORDER_SEARCH}ROW_SELECTED`
export const OPEN_SCREEN = `${SALES_ORDER_SEARCH}OPEN_SCREEN`
export const FOCUS = `${SALES_ORDER_SEARCH}FOCUS`
export const BLUR = `${SALES_ORDER_SEARCH}BLUR`
export const RESET = `${SALES_ORDER_SEARCH}RESET`
export const FLAGGED = `${SALES_ORDER_SEARCH}FLAGGED`
export const EXECUTE_INQUIRY = createAsyncRequestTypes(
  `${SALES_ORDER_SEARCH}EXECUTE_INQUIRY`
)
export const SALES_ORDER_NUMBER_SEARCH_URL = `${BASE_INFORM_API_URL}/search/salesordernumber` // dup
export const SALES_ORDER_COMPLETE_SEARCH_URL = `${BASE_INFORM_API_URL}/search/salesorders`
export const SALES_ORDER_NUMBER_FIND_URL = `${BASE_INFORM_API_URL}//salesorderinquiry/find`
export const SALES_ORDER_OPEN_URL = `${BASE_INFORM_API_URL}/search/salesorders/open`
export const SALES_ORDER_EXACT_MATCH_URL = `${BASE_INFORM_API_URL}/salesorderinquiry/exactmatch`
export const SALES_ORDER_INQUIRY_URL = `${BASE_INFORM_API_URL}/salesorderinquiry/search`
export const SALES_ORDER_TOOLTIP_URL = `${BASE_INFORM_API_URL}/salesorderinquiry/summary`

export const LOAD_CUSTOMER_RECORD = `${SALES_ORDER_SEARCH}LOAD_CUSTOMER_RECORD`

export const CHECK_FOR_SEARCH_PARAMS_ON_LOAD = `${SALES_ORDER_SEARCH}CHECK_FOR_SEARCH_PARAMS_ON_LOAD`

export const UPDATE_PARAMS_AND_EXECUTE_SALES_ORDER_INQUIRY = `${SALES_ORDER_SEARCH}UPDATE_PARAMS_AND_EXECUTE_SALES_ORDER_INQUIRY`

export const PRINT_SALES_ORDERS = createAsyncRequestTypes(
  `${SALES_ORDER_SEARCH}PRINT_SALES_ORDERS`
)

export const CLEAR_RESULTS = `${SALES_ORDER_SEARCH}CLEAR_RESULTS`

export const VIEW_REPORT = createAsyncRequestTypes(
  `${SALES_ORDER_SEARCH}VIEW_REPORT`
)

export const UPDATE_MASTER_DETAIL_COL_DEFS = `${SALES_ORDER_SEARCH}UPDATE_MASTER_DETAIL_COL_DEFS`
