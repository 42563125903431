import invariant from 'invariant'
import { is } from 'utils'
/* eslint-disable import/prefer-default-export */

export function bindBehaviors(context, behaviors) {
  Object.keys(behaviors).forEach(behavior => {
    invariant(is.fn(behaviors[behavior]), 'Behaviors must be a function')
    Object.assign(context, { [behavior]: behaviors[behavior].bind(context) })
  })
  if (context.props && context.props.form) {
    context.form = context.props.form
  }
}
