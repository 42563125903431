import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton, Button } from '@material-ui/core'
import FadeInAndOut from 'components/animation/FadeInAndOut'
import { PaymentButton } from 'mobile/pages/SalesOrder/utils'

import { noop } from 'utils'
// import styles from './styles/pagination.scss'
import './styles/pagination.scss'

const arrowIconStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  color: '#fff',
  height: 24,
  width: 24,
  fontSize: 20
}

class Pagination extends Component {
  static defaultProps = {
    onTab: noop,
    pageCount: 0,
    pageNumber: 0,
    paginationCb: noop,
    recordCount: 0
  }

  static propTypes = {
    // onTab: PropTypes.func,
    pageCount: PropTypes.number,
    pageNumber: PropTypes.number,
    paginationCb: PropTypes.func,
    recordCount: PropTypes.number
  }

  onBtnNextClick = event => {
    this.props.paginationCb(this.props.pageNumber + 1)
  }

  onBtnPrevClick = event => {
    this.props.paginationCb(this.props.pageNumber - 1)
  }

  toggleFilter = e => {
    this.props.toggleFilter(!this.props.isFiltered)
  }

  render() {
    const {
      recordCount,
      pageNumber,
      pageCount,
      toggles,
      toggleFilter,
      showCart,
      isFiltered
    } = this.props
    console.log(this.props)
    let btn
    const nextDisabled = pageNumber === pageCount
    const prevDisabled = pageNumber <= 1
    if (toggleFilter) {
      // debugger
      const toggle = !!this.props.isFiltered
      const label = toggle ? toggles.toggled : toggles.default
      btn = (
        <div
          style={{ display: 'flex', marginRight: 25 }}
          id="mobile-pagination-toggle"
        >
          <Button onClick={this.toggleFilter} variant="contained">
            {label}
          </Button>
          {toggle && (
            <FadeInAndOut>
              <div
                style={{
                  backgroundColor: 'red',
                  display: 'inline',
                  borderRadius: '2px',
                  padding: '8px',
                  color: 'white',
                  marginLeft: '5px',
                  fontWeight: 500,
                  fontFamily: 'Roboto, sans-seriff'
                }}
              >
                {toggles.default}
              </div>
            </FadeInAndOut>
          )}
        </div>
      )
    }
    // console.log(toggles, toggleFilter, isFiltered)
    return (
      <div className="search-pagination">
        <div className="search-pagination-controls-inner-wrapper">
          <div
            style={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <div
              className="search-pagination-controls"
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 25,
                marginLeft: 25
              }}
            >
              <div className="arrow">
                <IconButton
                  disabled={prevDisabled}
                  onClick={this.onBtnPrevClick}
                  style={{
                    height: 24,
                    padding: 0,
                    width: 24,
                    backgroundColor: prevDisabled ? '#bdbdbd' : '#517b9c',
                    cursor: prevDisabled ? 'default' : 'pointer'
                  }}
                >
                  <Icon style={arrowIconStyle}>keyboard_arrow_left</Icon>
                </IconButton>
              </div>
              <div style={{ margin: '0 5px', whiteSpace: 'nowrap' }}>
                <p>
                  {pageNumber} of {pageCount} pages
                </p>
              </div>
              <div className="arrow">
                <IconButton
                  disabled={nextDisabled}
                  onClick={this.onBtnNextClick}
                  style={{
                    height: 24,
                    padding: 0,
                    width: 24,
                    backgroundColor: nextDisabled ? '#bdbdbd' : '#517b9c',
                    cursor: nextDisabled ? 'default' : 'pointer'
                  }}
                >
                  <Icon style={arrowIconStyle}>keyboard_arrow_right</Icon>
                </IconButton>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 15,
                textAlign: 'right'
              }}
            >
              {showCart ? (
                <PaymentButton
                  raised
                  onClick={this.props.saveCart}
                  variant="contained"
                  style={{ marginRight: 25 }}
                  startIcon={
                    <Icon
                      fontSize="small"
                      style={{ color: '#fff', fontSize: '14px' }}
                    >
                      shopping_cart
                    </Icon>
                  }
                >
                  Save Cart
                </PaymentButton>
              ) : null}

              {toggleFilter && btn}

              <p style={{ whiteSpace: 'nowrap' }}>{recordCount} Results</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// export default CSSModules(Pagination, styles)
export default Pagination
