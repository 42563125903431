import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ConnectedStatusLabel from './ConnectedStatusLabel'
import { withDDIForm } from './DDIFormContext'

const StatusLabel = props => (
  <ConnectedStatusLabel {...props} _ddiForm={props._ddiForm} />
)

export default withDDIForm(StatusLabel)
