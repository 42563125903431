
const getBaseGridContextMenuItems = (params, columnDefs = []) => ([
  'export',
  'separator',
  {
    name: 'Layout',
    subMenu: [
      {
        action: () => params.columnApi.autoSizeAllColumns(),
        name: 'Auto Size Columns'
      },
      {
        action: () => {
          params.api.setColumnDefs(columnDefs)
          params.api.sizeColumnsToFit()
          // params.api.refreshCells({ rowNodes: tile.tile.data })
        },
        name: 'Reset to Default'
      }
    ]
  },
  'separator',
  {
    name: 'Rows',
    subMenu: [
      {
        name: 'Select All',
        action: () => params.api.selectAll()
      },
      {
        name: 'Deselect All',
        action: () => params.api.deselectAll()
      }
    ]
  }
])

export default getBaseGridContextMenuItems

