import React, { Component } from 'react'

import './styles.css'

export default class FadeInAndOut extends Component {
  constructor(...args) {
    super(...args)
    this.state = { fadeTransition: null, fadeState: 'fade-in' }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(prev => ({
        fadeState: prev.fadeState === 'fade-out' ? 'fade-in' : 'fade-out'
      }))
    }, this.props.interval || 5000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return (
      <div
        className={`fade-wrapper ${this.state.fadeState}`}
        style={{ transitionDuration: `${this.props.interval || 5000}ms` }}
      >
        {this.props.children}
      </div>
    )
  }
}
