import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import { save } from 'ddiForm/api'
// import * as CONSTANTS from './constants'

export const getContact = ({ parentId, parentType, dataId, ...rest }) => {
  const options = createApiOptions({
    body: { dataId, parentId, parentType, ...rest },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/contact/read`, options)
}

export const inquireContact = ({ parentId, parentType, dataId }) => {
  const options = createApiOptions({
    body: { dataId, parentId, parentType },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/contact/inquire`, options)
}

export const addContact = ({ parentId, parentType }) => {
  const options = createApiOptions({
    body: { parentId, parentType },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/contact/create`, options)
}

// DEPRECATED
// export const getAnalysis = ({ dataId, parentId, parentType, groupNames }) => {
//   const options = createApiOptions({
//     body: { dataId, parentId, parentType, groupNames },
//     method: 'post'
//   })

//   return callFetch(`${BASE_INFORM_API_URL}/contact/analysis`, options)
// }

// get payment info
export const getPaymentProfiles = ({ dataId, parentId, parentType }) => {
  const options = createApiOptions({
    body: { dataId, parentId, parentType },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/contact/getpaymentprofiles`, options)
}

// export const getPaymentProfilesUri = ({
//   merchantId,
//   branchId,
//   accountType
// }) => {
//   const options = createApiOptions({
//     body: { accountType, branchId, merchantId },
//     method: 'post'
//   })
//   return callFetch(
//     `${BASE_INFORM_API_URL}/contact/getpaymentprofilesuri`,
//     options
//   )
// }

// add payment source
// export const addPaymentSource = ({ id, merchantKey }) => {
//   const options = createApiOptions({
//     body: { id, merchantKey },
//     method: 'post'
//   })

//   return callFetch(`${BASE_INFORM_API_URL}/contact/addpaymentsource`, options)
// }

// remove payment source
export const removePaymentSource = ({
  dataId,
  parentId,
  parentType,
  token
}) => {
  const options = createApiOptions({
    body: { dataId, parentId, parentType, token },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/contact/paymentsource/remove`,
    options
  )
}

export const addPaymentSource = ({
  dataId,
  parentId,
  parentType,
  token = {}
}) => {
  const options = createApiOptions({
    body: { dataId, parentId, parentType, token },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/contact/paymentsource/board`,
    options
  )
}

// export const callCayan = () =>
//   // const options = createApiOptions({
//   //   body: { dataId, parentId, parentType, token },
//   //   method: 'post'
//   // })
//   // https://transport.merchantware.net/v4/transportweb.aspx?transportKey=0JYZ7-GFE0J-F1RHU-OR160-MXIY8
//   fetch(
//     'https://transport.merchantware.net/v4/transportweb.aspx?transportKey=0JYZ7-GFE0J-F1RHU-OR160-MXIY8'
//   )
// // return callFetch(`${BASE_INFORM_API_URL}/contact/paymentsource/remove`, options)

export const getPaymentProfileUri = branchId => {
  const options = createApiOptions({
    body: {
      branchId:
        typeof branchId === 'string' && branchId !== '' ? branchId : undefined
    },
    method: 'post'
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/contact/getpaymentprofilesuri`,
    options
  )
}

export const createContact = ({ parentId, parentType, ...rest }) => {
  const options = createApiOptions({
    body: { parentId, parentType, ...rest },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/contact/create`, options)
}

export const saveContact = ({ form = 'contact', ...args }) =>
  save({ form, ...args })

export const setDefaultChartView = ({ view }) => {
  const options = createApiOptions({
    body: { view },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/contact/saveagingview`, options)
}
