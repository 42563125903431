import {
  take,
  select,
  call,
  put,
  cancel,
  fork,
  actionChannel
} from 'redux-saga/effects'

import { api } from 'services'
import { getIn, mapColumnHeaders } from 'utils'
import {
  EXACT_MATCH_SEARCH,
  GET_TOOLTIP,
  PARTIAL_MATCH_SEARCH,
  FIND_PREV,
  FIND_NEXT,
  GET_SEARCH_FILTERS
} from './constants'

const getSearchFilters = filterData => {
  let filters = null
  if (filterData && filterData.filters) {
    const filterKeys = Object.keys(filterData.filters)
    filters = filterKeys.reduce((acc, next, idx) => {
      const dataId = [filterKeys[idx]].toString().replace('-', '.')
      acc = acc.concat({
        dataId,
        value: filterData.filters[filterKeys[idx]].value
      })
      return acc
    }, [])
  }
  return filters
}

// let task
// console.log(api)
export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)

    const {
      payload: {
        indexSearchType,
        keyword = '',
        propertyName,
        subProperty,
        moreInfo,
        includeParent,
        isRecordName
      },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(exactMatchProcess, {
      form,
      propertyName,
      subProperty,
      indexSearchType,
      keyword,
      moreInfo,
      includeParent,
      isRecordName,
      thunk
    })
  }
}
export function* exactMatchProcess({
  form,
  propertyName,
  subProperty,
  indexSearchType,
  keyword,
  moreInfo,
  includeParent,
  isRecordName,
  thunk
}) {
  // const filterData = yield select(state =>
  //   getIn(state, `ddiForm.${form}.fields.${propertyName}`).toJS()
  // )
  // const filters = getSearchFilters(filterData)
  let filterData
  let filters = null
  if (form) {
    filterData = yield select(state =>
      getIn(state, `ddiForm.${form}.fields.${propertyName}`).toJS()
    )
    filters = getSearchFilters(filterData)
  }

  const params = {
    indexSearchType,
    keyword,
    filters,
    moreInfo,
    includeParent,
    isRecordName
  }

  const { response, error } = yield call(api.secureExactMatchSearch, params)
  // on error?
  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName,
        subProperty
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName,
        subProperty
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchProcess({
  indexSearchType,
  propertyName,
  form,
  pageNumber,
  keyword,
  thunk,
  parentId,
  searchAll,
  isFiltered,
  parentType
}) {
  // if (form)
  let filterData
  let filters = null
  if (form) {
    filterData = yield select(state =>
      getIn(state, `ddiForm.${form}.fields.${propertyName}`).toJS()
    )
    filters = getSearchFilters(filterData)
  }
  const params = {
    indexSearchType,
    keyword,
    pageNumber,
    parentId,
    parentType,
    filters,
    isFiltered
  }
  // debugger
  const { response, error } = yield call(api.securePartialMatchSearch, params)
  /*
  const { response, error } = yield call(api.partialMatchSearch, {
    indexSearchType,
    keyword,
    pageNumber,
    token,
    isFiltered
  })
  */

  if (response) {
    const { partialMatchResults } = response
    const { columnHeaders, results: rowData, ...meta } = partialMatchResults
    const columnDefs = columnHeaders ? columnHeaders.map(mapColumnHeaders) : []
    const grid = {
      columnDefs,
      meta,
      rowData: rowData || []
    }
    yield put({
      // meta: { form, propertyName },
      meta: { form, thunk },
      payload: {
        grid,
        propertyName,
        searchAll
      },
      type: PARTIAL_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName,
        searchAll
      },
      type: PARTIAL_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchSearchListener() {
  const channel = yield actionChannel(PARTIAL_MATCH_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)
    // console.log(action)
    const {
      payload: {
        indexSearchType,
        parentType,
        propertyName,
        pageNumber,
        keyword = '',
        parentId,
        searchAll,
        isFiltered
      },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield call(partialMatchProcess, {
      form,
      indexSearchType,
      keyword,
      pageNumber,
      propertyName,
      thunk,
      parentId,
      searchAll,
      isFiltered,
      parentType
    })
  }
}

export function* findPrevExactMatchListener() {
  let task
  while (true) {
    const action = yield take(FIND_PREV.REQUEST)
    // console.log(action)
    const {
      payload: { indexSearchType, propertyName, recordName },
      meta: { form }
    } = action
    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield call(findPrevExactMatchProcess, {
      form,
      indexSearchType,
      propertyName,
      recordName
    })
  }
}

export function* findPrevExactMatchProcess({
  form,
  indexSearchType,
  propertyName,
  recordName
}) {
  const { response, error } = yield call(api.getPrevExactMatch, {
    indexSearchType,
    recordName
  })
  if (response) {
    yield put({
      meta: { form },
      payload: { ...response, propertyName },
      type: FIND_PREV.SUCCESS
    })
  } else {
    yield put({
      meta: { form },
      payload: { ...error, propertyName },
      type: FIND_PREV.FAILURE
    })
  }
}
export function* findNextExactMatchProcess({
  form,
  indexSearchType,
  propertyName,
  recordName
}) {
  const { response, error } = yield call(api.getNextExactMatch, {
    indexSearchType,
    recordName
  })
  if (response) {
    yield put({
      meta: { form },
      payload: { ...response, propertyName },
      type: FIND_NEXT.SUCCESS
    })
  } else {
    yield put({
      meta: { form },
      payload: { ...error, propertyName },
      type: FIND_NEXT.FAILURE
    })
  }
}
export function* getTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_TOOLTIP.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getTooltipProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* getTooltipProcess({
  form,
  thunk,
  propertyName,
  apiName = 'getIndexSearchTooltip',
  ...rest
  // indexSearchType,
  // propertyName,
  // recordName,
}) {
  const { response, error } = yield call(api[apiName], {
    // indexSearchType,
    // propertyName,
    // recordName
    propertyName,
    ...rest
  })

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: { ...response, propertyName },
      type: GET_TOOLTIP.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: { ...error, propertyName },
      error: true,
      type: GET_TOOLTIP.FAILURE
    })
  }
}

// export function* getTooltipListener() {
//   while (true) {
//     const action = yield take(GET_TOOLTIP.REQUEST)
//     const {
//       payload: { indexSearchType, propertyName, recordName },
//       meta: { form, thunk }
//     } = action
//     if (task) yield cancel(task)
//     task = yield call(getTooltipProcess, {
//       form,
//       indexSearchType,
//       propertyName,
//       recordName,
//       thunk
//     })
//   }
// }
// export function* getTooltipProcess({
//   form,
//   indexSearchType,
//   propertyName,
//   recordName,
//   thunk
// }) {
//   const { response, error } = yield call(api.getIndexSearchTooltip, {
//     indexSearchType,
//     propertyName,
//     recordName
//   })

//   if (response) {
//     yield put({
//       meta: { form, thunk },
//       payload: { ...response, propertyName },
//       type: GET_TOOLTIP.SUCCESS
//     })
//   } else {
//     yield put({
//       meta: { form, thunk },
//       payload: { ...error, propertyName },
//       error: true,
//       type: GET_TOOLTIP.FAILURE
//     })
//   }
// }

export function* findNextExactMatchListener() {
  let task
  while (true) {
    const action = yield take(FIND_NEXT.REQUEST)
    // console.log(action)
    const {
      payload: { indexSearchType, propertyName, recordName },
      meta: { form }
    } = action
    if (task) yield cancel(task)
    task = yield call(findNextExactMatchProcess, {
      form,
      indexSearchType,
      propertyName,
      recordName
    })
  }
}

export function* getSearchFiltersProcess({
  form,
  indexSearchType,
  propertyName
}) {
  const { response, error } = yield call(api.getSearchFilters, {
    indexSearchType
  })
  if (response) {
    yield put({
      meta: { form },
      payload: {
        ...response,
        propertyName,
        form
      },
      type: GET_SEARCH_FILTERS.SUCCESS
    })
  } else {
    yield put({
      meta: { form },
      payload: { ...error },
      type: GET_SEARCH_FILTERS.FAILURE
    })
  }
}

export function* getSearchFiltersListener() {
  let task
  while (true) {
    const action = yield take(GET_SEARCH_FILTERS.REQUEST)
    const {
      payload: { indexSearchType, propertyName },
      meta: { form }
    } = action
    if (task) yield cancel(task)
    task = yield call(getSearchFiltersProcess, {
      form,
      indexSearchType,
      propertyName
    })
  }
}

export default function* secureSearchSagas() {
  yield fork(partialMatchSearchListener)
  yield fork(exactMatchSearchListener)
  yield fork(findNextExactMatchListener)
  yield fork(findPrevExactMatchListener)
  yield fork(getSearchFiltersListener)
  yield fork(getTooltipListener)
}
