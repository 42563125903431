// import { createAsyncRequestTypes } from 'utils'
const layout = '@@layout/'

export const RE_INIT = `${layout}RE_INIT`
export const BEFORE_DESTROY = `${layout}BEFORE_DESTROY`
export const DESTROY = `${layout}DESTROY`
export const ADD = `${layout}ADD`
export const BEFORE_REMOVE = `${layout}BEFORE_REMOVE`
export const REMOVE = `${layout}REMOVE`
export const ON_ADD = `${layout}ON_ADD`
export const ON_FOCUS = `${layout}ON_FOCUS`
export const ON_REMOVE = `${layout}ON_REMOVE`
export const ON_RE_INIT = `${layout}ON_RE_INIT`
export const SET_PAGE_TITLE = `${layout}SET_PAGE_TITLE`
export const CLEAR_LAYOUT_SCREEN_DATA = `${layout}CLEAR_LAYOUT_SCREEN_DATA`
export const ON_LAYOUT_TAB_CHANGE = `${layout}ON_LAYOUT_TAB_CHANGE`
export const CLEAR_ORDER_PAD_DATA = `${layout}CLEAR_ORDER_PAD_DATA`
export const CHANGE_TAB = `${layout}CHANGE_TAB`
export const LAYOUT_CLEARED = `${layout}LAYOUT_CLEARED`
