import createSearch from 'components/Search/createSearch'
import behaviors from './behaviors'
import DropDownComponent from './DropDownComponent'
import TextInput from './TextInput'

/* 
  removed popoverStyle width: 500, better to let the contents decide the width.
  works better on both iPad and iPhone this way -- SVE 6/24/20
*/
const SalesOrderSearch = createSearch({
  name: 'SalesOrderSearch',
  type: 'SalesOrderSearch',
  behaviors,
  DropDownComponent,
  TextInput
})

export default SalesOrderSearch
