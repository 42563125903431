export default function fetchProcess(route, options) {
  return fetch(route, options)
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        json.status = response.status
        return Promise.reject(json)
      }
      return json
    })
    .then(
      response => ({ response }),
      error => {
        const err = {
          error: {
            ...error,
            message: error.error_description || error.message
          }
        }
        return err
      }
    )
}
