import {
  all,
  take,
  fork,
  call,
  cancel,
  put,
  putResolve
} from 'redux-saga/effects'

import { getSalesOrderTooltip, getPurchaseOrderTooltip } from './api'
import { api } from 'services'
import * as CONSTANTS from './constants'

export function* getPurchaseOrderTooltipListener() {
  let task
  while (true) {
    const action = yield take(CONSTANTS.GET_PURCHASE_ORDER_TOOLTIP.REQUEST)
    const {
      payload: { recordName },
      meta: { thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getPurchaseOrderTooltipProcess, {
      recordName,
      thunk
    })
  }
}

export function* getPurchaseOrderTooltipProcess({ recordName, thunk }) {
  const { response, error } = yield call(getPurchaseOrderTooltip, {
    recordName
  })

  if (response) {
    yield putResolve({
      meta: { thunk },
      payload: { ...response },
      type: CONSTANTS.GET_PURCHASE_ORDER_TOOLTIP.SUCCESS
    })
  } else {
    yield putResolve({
      error: true,
      meta: { thunk },
      payload: { ...error },
      type: CONSTANTS.GET_PURCHASE_ORDER_TOOLTIP.FAILURE
    })
  }
}

export function* getSalesOrderTooltipListener() {
  let task
  while (true) {
    const action = yield take(CONSTANTS.GET_SALES_ORDER_TOOLTIP.REQUEST)
    const {
      payload: { recordName },
      meta: { thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getSalesOrderTooltipProcess, {
      recordName,
      thunk
    })
  }
}

export function* getSalesOrderTooltipProcess({ recordName, thunk }) {
  const { response, error } = yield call(getSalesOrderTooltip, {
    recordName
  })

  if (response) {
    yield putResolve({
      meta: { thunk },
      payload: { ...response },
      type: CONSTANTS.GET_SALES_ORDER_TOOLTIP.SUCCESS
    })
  } else {
    yield putResolve({
      error: true,
      meta: { thunk },
      payload: { ...error },
      type: CONSTANTS.GET_SALES_ORDER_TOOLTIP.FAILURE
    })
  }
}

export function* getIndexSearchTooltipListener() {
  let task
  while (true) {
    const action = yield take(CONSTANTS.GET_INDEX_SEARCH_TOOLTIP.REQUEST)
    const {
      payload: { recordName, indexSearchType },
      meta: { thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getIndexSearchTooltipProcess, {
      indexSearchType,
      recordName,
      thunk
    })
  }
}

export function* getIndexSearchTooltipProcess({
  indexSearchType,
  recordName,
  thunk
}) {
  const { response, error } = yield call(api.getIndexSearchTooltip, {
    indexSearchType,
    recordName
  })

  if (response) {
    yield putResolve({
      meta: { thunk },
      payload: { ...response },
      type: CONSTANTS.GET_INDEX_SEARCH_TOOLTIP.SUCCESS
    })
  } else {
    yield putResolve({
      error: true,
      meta: { thunk },
      payload: { ...error },
      type: CONSTANTS.GET_INDEX_SEARCH_TOOLTIP.FAILURE
    })
  }
}

export default function* tooltipFieldSagas() {
  yield all([
    fork(getPurchaseOrderTooltipListener),
    fork(getSalesOrderTooltipListener),
    fork(getIndexSearchTooltipListener)
  ])
}
