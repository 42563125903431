import { createSelector } from 'reselect'
import { getIn } from 'utils'

const _contentAreaSelector = (state, form) => {
  const masterOptions = getIn(state, `ddiForm.${form}.masterOptions`)
  const selectedPrimaryTab = getIn(masterOptions, 'selectedPrimaryTab')
  const selectedSecondaryTab = getIn(masterOptions, 'selectedSecondaryTab')
  const tabComponents = getIn(masterOptions, 'tabComponents')
  const tab = `${selectedPrimaryTab}${
    selectedSecondaryTab ? `-${selectedSecondaryTab}` : ''
  }`
  // const tabComponent = getIn(tabComponents, tab)
  // const tabComponent = getIn(getIn(tabComponents, tab), 'component')
  let tabComponent = getIn(tabComponents, tab)
  tabComponent = getIn(tabComponent, 'component')
  return {
    tabComponent,
    tab
  }
}
export const contentAreaSelector = createSelector(_contentAreaSelector, d => d)
