import { createSelector } from 'reselect'
import { getIn } from 'utils'

const snackbar = state => state.get('snackbar')

export const snackbarMessagesSelector = createSelector(
  snackbar,
  snack => getIn(snack, 'messages')
)

export const mailMessagesSelector = createSelector(
  snackbarMessagesSelector,
  snacks => snacks.filter(x => x.get('type') === 'mail')
)

export const otherMessagesSelector = createSelector(
  snackbarMessagesSelector,
  snacks => snacks.filter(x => x.get('type') !== 'mail')
)

export const batchSelector = createSelector(
  snackbar,
  snack => getIn(snack, 'batch')
)

export const removedSelector = createSelector(
  snackbar,
  snack => getIn(snack, 'removed')
)
export const removedMailSelector = createSelector(
  removedSelector,
  removed => (removed?.get('type') === 'mail' ? removed.get('id') : null)
)
export const removedOtherSelector = createSelector(
  removedSelector,
  removed => (removed?.get('type') !== 'mail' ? removed.get('id') : null)
)

export const addedSelector = createSelector(
  snackbar,
  snack => getIn(snack, 'added')
)

export const addedMailSelector = createSelector(
  addedSelector,
  added => (added.get('type') === 'mail' ? added.get('id') : null)
)

export const addedOtherSelector = createSelector(
  addedSelector,
  added => (added.get('type') !== 'mail' ? added.get('id') : null)
)
