import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { getIn } from 'utils'
import {
  lockProductNote as lockProductNoteAction,
  unlockProductNote as unlockProductNoteAction,
  saveProductNote as saveProductNoteAction
} from 'pages/SalesOrder/actions'

import { removeModal } from 'modals/actions'

class ProductNotesModalActions extends Component {
  lockProductNote = e => {
    // console.log('lockProductNote', this)
    // debugger

    const { dispatch, form, modal } = this.props

    if (modal?.options?.data?.lineNumber) {
      dispatch(
        lockProductNoteAction.try(form, {
          lineNumber: modal?.options?.data?.lineNumber
        })
      )
    }
  }

  unlockProductNote = e => {
    const { dispatch, form, modal } = this.props
    // console.log(this)
    // debugger

    if (modal?.options?.data?.lineNumber && modal?.id) {
      dispatch(
        unlockProductNoteAction.try(form, {
          lineNumber: modal?.options?.data?.lineNumber,
          modalId: modal?.id
        })
      )
    }
  }

  saveProductNote = (e, isDeleteFunction = false) => {
    const {
      dispatch,
      form,
      modal: { id, options },
      modalRef
    } = this.props

    const note = modalRef?.[id]?.state?.value || ''

    // console.log('saveProductNote', note, options?.data?.lineNumber)

    if (id && options?.data?.lineNumber) {
      // debugger
      dispatch(
        saveProductNoteAction.try(form, {
          lineNumber: options?.data?.lineNumber,
          note: isDeleteFunction ? '' : note,
          modalId: id
        })
      )
    }
  }

  exitProductNote = e => {
    const {
      dispatch,
      modal: { id },
      form
    } = this.props
    // debugger

    if (id) {
      dispatch(removeModal(form, id))
    }
  }

  render() {
    const { isEditingProductNotes } = this.props
    if (isEditingProductNotes) {
      return (
        <>
          <Button
            onClick={this.saveProductNote}
            style={{ margin: 5 }}
            variant="contained"
          >
            Save
          </Button>
          <Button
            onClick={e => this.saveProductNote(e, true)}
            style={{ margin: 5 }}
            variant="contained"
          >
            Delete
          </Button>
          <Button
            onClick={this.unlockProductNote}
            style={{ margin: 5 }}
            variant="contained"
          >
            Cancel
          </Button>
        </>
      )
    }

    return (
      <>
        <Button
          onClick={this.lockProductNote}
          style={{ margin: 5 }}
          variant="contained"
        >
          Edit
        </Button>
        <Button
          onClick={this.exitProductNote}
          style={{ margin: 5 }}
          variant="contained"
        >
          Exit
        </Button>
      </>
    )
  }
}

const mapState = (state, ownProps) => {
  const { form } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const isEditing = getIn(formState, 'isEditing') || false
  const isEditingProductNotes =
    getIn(formState, 'isEditingProductNotes') || false

  return {
    isEditing,
    isEditingProductNotes
  }
}

export default connect(
  mapState,
  null,
  null,
  { forwardRef: true }
)(ProductNotesModalActions)
