/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

// export const viewReport = form => createAsyncFormAction(CONSTANTS.VIEW_REPORT, form)

export const viewDocument = (form, { dataId, cb }) =>
  createAction(CONSTANTS.VIEW_DOCUMENT.TRY, { dataId, cb }, form)

export const sendDocument = (form, { dataId }) =>
  createAction(CONSTANTS.SEND_DOCUMENT.TRY, { dataId }, form)

export const printDocument = (form, { dataId, copies, collate, printerId }) =>
  createAction(
    CONSTANTS.PRINT_DOCUMENT.TRY,
    { dataId, copies, collate, printerId },
    form
  )

export const saveDocument = (
  form,
  { credentials, dataId, filename, fileType }
) =>
  createAction(
    CONSTANTS.SAVE_DOCUMENT.TRY,
    { credentials, dataId, filename, fileType },
    form
  )

export const cancelPrintDocument = (form, { dataId }) =>
  createAction(CONSTANTS.CANCEL_PRINT_DOCUMENT.TRY, { dataId }, form)

export const getAllPrinters = (form, { allPrinters, dataId }) =>
  createAction(CONSTANTS.GET_ALL_PRINTERS.TRY, { allPrinters, dataId }, form)

export const setSessionDefault = (
  form,
  { dataId, printerId, sessionDefault }
) =>
  createAction(
    CONSTANTS.SET_SESSION_DEFAULT.TRY,
    { dataId, printerId, sessionDefault },
    form
  )

export const closeReportViewer = (form, { dataId }) =>
  createAction(CONSTANTS.CLOSE_REPORT_VIEWER, { dataId }, form)

export const printDocumentRoutineCompleted = (form, isCancellation = false) =>
  createAction(CONSTANTS.PRINT_DOCUMENT_ROUTINE_COMPLETED, { isCancellation }, form)
