/* eslint import/no-dynamic-require: 0 */
import {
  all,
  delay,
  actionChannel,
  take,
  put,
  putResolve,
  call,
  fork,
  select
} from 'redux-saga/effects'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import { getFormSelector } from 'ddiForm/utils'
import { toCamelCase, getIn } from 'utils'
import { CLEAR_SELECTED_BRANCH } from 'auth/constants'
// // import { accessPathListSelector } from 'auth/selectors'
// // import { CANCELED } from 'modals/constants'
// import { beforeDestroy } from 'ddiForm/actions'
import NotFoundPage from 'pages/NotFoundPage'
import { screensMapSelector } from 'auth/selectors'
// import { checkHasAccessProcess, grantAccessProcess } from 'auth/sagas'
import { closeSalesOrder } from 'pages/SalesOrder/actions'
import { exitUserMailScreen } from 'pages/UserMail/actions'
import { closeOpenPOReceivingScreen } from 'pages/OpenPOReceivingTicket/actions'
import { LINK_TO_SCREEN } from '../Main/constants'
import { openScreen, toggleNav } from '../Main/actions'
import { add, onReInit, remove } from './actions'
import { BEFORE_REMOVE, RE_INIT, LAYOUT_CLEARED } from './constants'

const screenMap = {
  CUSTOMER: 'customerMaster',
  INVOICE: 'invoiceInquiry',
  PO: 'purchaseOrderEntry',
  POREQ: 'specialPurchaseRequisitions',
  REPORT: 'documentViewer',
  SO: 'salesOrder',
  ST: 'stockTransferEntry'
}

/* have to export this function in order to test these sagas */
export function getModule(name) {
  console.log('OPEN SCREEN: ', name)
  return new Promise((resolve, reject) => {
    require
      .ensure([], require => {
        resolve(
          require(`../${name
            .replace('/', '')
            .split(' ')
            .join('')}/index.js`)
        )
      })
      .then(null, e => {
        console.log(e, 'screen load failure!')
        reject(e)
      })
  })
}

export function* tryGetModule(name) {
  let screen
  try {
    screen = yield call(getModule, name)
  } catch (ex) {
    screen = NotFoundPage
  }
  // console.log(screen)
  return screen
}

export function* linkToScreenListener() {
  while (true) {
    const action = yield take(LINK_TO_SCREEN)
    /* eslint-disable prefer-const */
    let { screenName, referenceType, ...rest } = action.payload
    screenName = screenName || screenMap[referenceType]
    const mapp = yield select(screensMapSelector)
    const screen = getIn(mapp, screenName)
    // console.log(action, mapp, rest, screen)
    yield put(
      openScreen({
        name: screenName,
        image: getIn(screen, 'imageFileName16'),
        title: getIn(screen, 'fullDescription'),
        ...rest
      })
    )
  }
}

export function* beforeRemoveListener() {
  while (true) {
    const action = yield take(BEFORE_REMOVE)
    // console.log(action)
    // debugger
    const {
      payload: { name }
    } = action
    const formState = yield select(getFormSelector(name))

    const skipCamelCase = ['openPOReceivingTicket']
    const formToClose =
      name && name.match(/salesOrder-/)
        ? name
        : name && skipCamelCase.includes(name)
        ? name
        : toCamelCase(name)

    if (
      (formState && getIn(formState, 'isEditing')) ||
      (formState && name && name.match(/salesOrder-/)) ||
      (formState && name && name === 'userMail') ||
      (formState && name && name === 'openPOReceivingTicket')
    ) {
      let a
      // debugger
      try {
        if (name && name.match(/salesOrder-/)) {
          a = yield putResolve(closeSalesOrder.try(name))
        } else if (name && name === 'userMail') {
          a = yield putResolve(exitUserMailScreen.try(name))
        } else if (name && name === 'openPOReceivingTicket') {
          a = yield putResolve(closeOpenPOReceivingScreen.try(name))
        } else {
          a = yield putResolve(masterActions.cancelEditAsync.try(name))
        }
      } catch (e) {
        console.log(e)
      }
      if (a) {
        yield put(remove(formToClose))
      }
    } else {
      yield put(remove(formToClose))
    }
  }
}

export function* reInitListener() {
  while (true) {
    const action = yield take(RE_INIT)
    const {
      payload: { names }
    } = action
    // console.log(names)
    const screens = {}
    const filteredNames = names // .filter(x => x !== 'Dashboard') // no need to grab the Dashboard.. we already have it!
    if (filteredNames.length) {
      for (const name of filteredNames) {
        const mod = yield tryGetModule(name)
        screens[name] = { component: mod.default, title: name }
      }
      // console.log(screens)
      yield put(onReInit(screens))
    }
  }
}

// export function* layoutCleared() {
//   while (true) {
//     const {
//       payload: { items }
//     } = yield take(LAYOUT_CLEARED)
//     // items?.contentItems?.forEach(x => {
//     //   if (x?.config?.component !== 'dashboard') {
//     //     debugger
//     //     items.removeChild(x)
//     //   }
//     // })
//     const { contentItems } = items
//     for (const item of contentItems) {
//       debugger
//       console.log(item)
//     }
//     debugger
//   }
// }

export default function* layoutListeners() {
  yield all([
    fork(beforeRemoveListener),
    // fork(screenOpenListener),
    fork(reInitListener),
    fork(linkToScreenListener)
    // fork(layoutCleared)
  ])
}
