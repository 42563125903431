/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class EventColorCell extends Component {
  _isMounted = false

  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const {
      data: { color },
      value
    } = this.props
    return (
      <div
        style={{
          display: 'block',
          width: '100%',
          height: '100%'
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            height: '100%'
          }}
        >
          <div style={{ marginRight: 5 }}>
            <svg height="20" width="20">
              <circle cx="10" cy="10" r="6" fill={color} />
            </svg>
          </div>
          <div
            style={{
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {value}
          </div>
        </div>
      </div>
    )
  }
}

export default EventColorCell
