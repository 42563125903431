/* IE screws up the tab underline styles for some reason */
import { detectIE } from 'utils'

const inkBarStyle = detectIE()
  ? { height: '4px', marginTop: '-8px' }
  : { height: '4px', marginTop: '-4px' }

const baseComponentStyles = {
  footer: {
    container: {
      background: '#cecece',
      borderRadius: 4,
      padding: 5
    }
  }
}

const ComponentStyles = {
  associatedFields: {
    marginOverride: { marginLeft: 0, marginRight: 0 },
    toggleButton: {
      labelStyle: {
        fontSize: 11,
        lineHeight: '15px',
        margin: '5px 0 10px 0',
        textAlign: 'right',
        width: 'auto'
      },
      style: { marginTop: 15 }
    }
  },
  footer: {
    button: { margin: '0 5px 0 0' },
    container: { ...baseComponentStyles.footer.container },
    containerStandalone: {
      ...baseComponentStyles.footer.container,
      boxShadow:
        'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px'
    }
  },
  masterPageSecondaryTabs: {
    baseConfig: {
      className: 'tabs-standard',
      height: 35,
      inkBarStyle,
      tabItemContainerStyle: {
        background: '#444',
        display: 'block',
        overflow: 'hidden',
        width: 2400
      }
    }
  },
  masterPageTabs: {
    baseTabConfig: {
      className: 'master-screen-tabs',
      height: 35,
      inkBarStyle: { display: 'none' },
      tabItemContainerStyle: {
        background: 'transparent',
        color: '#999',
        display: 'block',
        overflow: 'hidden',
        width: 2400
      }
    },
    dimensions: { height: 35, width: 90 },
    iconStyle: { color: '#999', width: 20 },
    style: { background: '#dbe5ec', color: '#999' }
  },
  modal: {
    heading: {
      background: 'linear-gradient(to bottom, #d3dee6 0%, #c0d5e5 100%)',
      color: '#444',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '27px',
      padding: '5px 0 5px 15px'
    },
    headingIcon: {
      color: '#444',
      fontSize: 13,
      height: 16,
      verticalAlign: 'middle',
      width: 16
    }
  },
  screenLayout: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      height: '100%',
      position: 'sticky',
      width: '100%'
    },
    contentWrapper: {
      flexGrow: 1,
      minHeight: '2em',
      overflow: 'auto'
    },
    footerWrapper: { flexShrink: 0 }
  },
  toggleButtonSets: {
    labelLeftButtonRight: {
      color: '#444',
      fontSize: 11,
      lineHeight: '15px',
      marginRight: 10,
      marginTop: 5,
      textAlign: 'right'
    },
    rightAligned: {
      labelStyle: {
        fontSize: 11, marginRight: 0, textAlign: 'right'
      },
      style: {
        float: 'right', marginRight: 10, marginTop: 5
      }
    }
  },
  utilityStyles: {
    noMargin: { margin: 0 },
    noPadding: { padding: 0 }
  }
}

export default ComponentStyles
