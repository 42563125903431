import { createAction } from 'utils'
import * as CONSTANTS from './constants'

export const getPurchaseOrderTooltip = ({ recordName }) => {
  const action = createAction(
    CONSTANTS.GET_PURCHASE_ORDER_TOOLTIP.REQUEST,
    { recordName },
    null,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}

export const getSalesOrderTooltip = ({ recordName }) => {
  const action = createAction(
    CONSTANTS.GET_SALES_ORDER_TOOLTIP.REQUEST,
    { recordName },
    null,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}

export const getIndexSearchTooltip = ({ recordName, indexSearchType }) => {
  const action = createAction(
    CONSTANTS.GET_INDEX_SEARCH_TOOLTIP.REQUEST,
    { recordName, indexSearchType },
    null,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}
