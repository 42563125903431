import { fromJS } from 'immutable'
import { createReducer /* deleteIn */, getIn, setIn } from 'utils'
import {
  SIGNUP,
  VALIDATE_FIELD,
  VALIDATE_PASSWORD_STRENGTH,
  VALIDATE_PASSWORD_CONFIRM,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  RESET_STATUS
} from './constants'

const initialState = fromJS({
  inputs: {
    passwordConfirmContainsVal: false,
    passwordContainsVal: false,
    usernameContainsVal: false
  },
  isFailure: false,
  isSuccess: false,
  passwordValidation: {
    containsLetters: false,
    containsNumbers: false,
    containsUppercaseLetters: false,
    minCharacters: 8,
    minLengthMet: false
  },
  passwordValidationCriteriaMet: false,
  passwordsMatch: false,
  statusText: null,
  submitted: false,
  submitting: false
})

export default createReducer(initialState, {
  [RESET_STATUS]: state => state.set('statusText', ''),
  [CHANGE_PASSWORD.FAILURE]: (state, { payload }) =>
    state.merge({
      isFailure: true,
      isSuccess: false,
      statusText: payload.modelState
        ? payload.modelState['model.UserKey']
          ? `Sorry: ${payload.modelState['model.UserKey'][0]}`
          : `Sorry: ${payload.modelState.model[0]}`
        : payload.message,
      submitted: true,
      submitting: false
    }),
  [SIGNUP.REQUEST]: (state, { payload }) =>
    state.merge({
      isSuccess: false,
      submitted: true,
      submitting: true
    }),
  // {
  //   ...state,
  //   submitted: true,
  //   isSuccess: false
  // }
  [RESET_PASSWORD.REQUEST]: (state, { payload }) =>
    state.merge({
      isSuccess: false,
      submitted: true,
      submitting: true
    }),
  // [SIGNUP.FAILURE]: (state, { payload }) =>
  //   state.merge({
  //     isFailure: true,
  //     isSuccess: false,
  //     statusText: payload.modelState['model.UserKey']
  //       ? `Sorry: ${payload.modelState['model.UserKey'][0]}`
  //       : `Sorry: ${payload.modelState.model[0]}`,
  //     submitted: true,
  //     submitting: false
  //   }),

  [SIGNUP.FAILURE]: (state, { payload }) => {
    const result = state

    const statusText = payload?.modelState
      ? Object.keys(payload.modelState).reduce((acc, x) => {
          payload.modelState[x].forEach(next => {
            acc += `${next}\n`
          })
          return acc
        }, '')
      : 'An error has occured. Please contact your system administrator.'

    return result.merge({
      isFailure: true,
      isSuccess: false,
      statusText,
      // statusText: payload.modelState['model.UserKey']
      //   ? `Sorry: ${payload.modelState['model.UserKey'][0]}`
      //   : `Sorry: ${payload.modelState.model[0]}`,
      submitted: true,
      submitting: false
    })
  },

  [RESET_PASSWORD.FAILURE]: (state, { payload }) =>
    state.merge({
      isFailure: true,
      isSuccess: false,
      statusText: `Sorry: ${payload.message}`,
      submitted: true,
      submitting: false
    }),
  // [SIGNUP.SUCCESS]: (state, { payload }) => ({
  //   ...state,
  //   isSuccess: true,
  //   statusText: null,
  //   submitted: true
  // }),
  [SIGNUP.SUCCESS]: (state, { payload }) => initialState, // .set('isSuccess', true),
  [RESET_PASSWORD.SUCCESS]: (state, { payload: flag }) =>
    flag ? state : initialState,
  [CHANGE_PASSWORD.SUCCESS]: () => initialState,
  // state.merge({
  //   isSuccess: true,
  //   statusText: null,
  //   submitted: true,
  //   submitting: false
  // }),
  [VALIDATE_FIELD]: (state, { payload }) => {
    let result = state
    result = setIn(result, `inputs.${payload.field}`, payload.valid)
    // ...state,
    // inputs: {
    //   ...state.inputs,
    //   [payload.field]: payload.valid
    // }
    return result
  },
  [VALIDATE_PASSWORD_CONFIRM]: (state, { payload }) =>
    state.merge({ passwordsMatch: payload.passwordsMatch }),
  [VALIDATE_PASSWORD_STRENGTH]: (state, { payload }) => {
    let result = state

    let passwordValidation = getIn(state, 'passwordValidation')
    passwordValidation = passwordValidation.merge({
      containsLetters: payload.containsLetters,
      containsNumbers: payload.containsNumbers,
      containsUppercaseLetters: payload.containsUppercaseLetters,
      minLengthMet: payload.minLengthMet
    })
    result = setIn(result, 'passwordValidation', passwordValidation)
    result = setIn(
      result,
      'passwordValidationCriteriaMet',
      payload.passwordValidationCriteriaMet
    )
    // ...state,
    // passwordValidation: {
    //   ...state.passwordValidation,
    //   containsLetters: payload.containsLetters,
    //   containsNumbers: payload.containsNumbers,
    //   containsUppercaseLetters: payload.containsUppercaseLetters,
    //   minLengthMet: payload.minLengthMet
    // },
    return result
  }
  // [VALIDATE_FIELD]: (state, { payload }) => ({
  //   ...state,
  //   inputs: {
  //     ...state.inputs,
  //     [payload.field]: payload.valid
  //   }
  // }),
  // [VALIDATE_PASSWORD_CONFIRM]: (state, { payload }) => ({
  //   ...state,
  //   passwordsMatch: payload.passwordsMatch
  // }),
  // [VALIDATE_PASSWORD_STRENGTH]: (state, { payload }) => ({
  //   ...state,
  //   passwordValidation: {
  //     ...state.passwordValidation,
  //     containsLetters: payload.containsLetters,
  //     containsNumbers: payload.containsNumbers,
  //     containsUppercaseLetters: payload.containsUppercaseLetters,
  //     minLengthMet: payload.minLengthMet
  //   },
  //   passwordValidationCriteriaMet: payload.passwordValidationCriteriaMet
  // })
})
