import {
  all,
  take,
  select,
  call,
  put,
  putResolve,
  fork,
  cancel
} from 'redux-saga/effects'

import { getIn } from 'utils'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
// import * as actions from 'ddiForm/actions'
import { disableFields, enableFields, setField } from 'ddiForm/actions'
import { confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { api } from 'services'
import {
  EXACT_MATCH,
  EXECUTE_INQUIRY,
  GET_PURCHASE_ORDER_TOOLTIP,
  OPEN_DROPDOWN,
  OPEN_SCREEN,
  BLUR
} from './constants'

import * as actions from './actions'
// import { CLEAR_SEARCH } from 'components/Search/IndexSearch/constants'
// import { SET_FIELD } from 'ddiForm/constants'
// import { setField } from 'ddiForm/actions'

export function* onOpenProcess(form) {
  const payload = { meta: {} }
  const indexSearchFields = yield select(state =>
    getIn(state, 'auth.common.purchaseOrderInquiryMeta.indexSearchFields')
  )

  const statusOptions = yield select(state =>
    getIn(state, 'auth.common.purchaseOrderInquiryMeta.statusOptions')
  )

  payload.meta.statusOptions = statusOptions.toJS()
  payload.meta.indexSearchFields = indexSearchFields.toJS()
  yield putResolve({
    type: OPEN_SCREEN,
    payload,
    meta: { form }
  })
}

export function* poProcess(action) {
  const {
    payload: { keyword, propertyName, subProperty },
    meta: { form, thunk }
  } = action
  const { response, error } = yield call(api.purchaseOrderExactMatch, {
    keyword
  })
  if (response) {
    //debugger
    yield putResolve({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName,
        subProperty
      },
      type: EXACT_MATCH.SUCCESS
    })
  } else {
    yield putResolve({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName,
        subProperty
      },
      error: true,
      type: EXACT_MATCH.FAILURE
      // error: true
    })
  }
}
export function* purchaseOrderExactMatchSearchListener() {
  let task

  while (true) {
    const action = yield take(EXACT_MATCH.REQUEST)
    if (task) yield cancel(task)
    task = yield fork(poProcess, action)
  }
}
export function* onBlurListener() {
  while (true) {
    yield take(BLUR)
  }
}
// export function* onBlurListener() {
//   const mapper = {
//     job: ['poNumber', 'product', 'vendor'],
//     product: ['poNumber', 'vendor', 'job'],
//     vendor: ['poNumber', 'product', 'job']
//   }

//   while (true) {
//     const action = yield take([
//       INDEX_SEARCH_CONSTANTS.BLUR,
//       DDICONSTANTS.BLUR,
//       DDICONSTANTS.SET_FIELD
//     ])
//     const {
//       meta,
//       payload: { isSet, propertyName, value }
//     } = action

//     if (meta.form) {
//       if (meta.form.includes('purchaseOrderInquiry')) {
//         const field = yield select(state =>
//           getIn(
//             getIn(state, 'ddiForm'),
//             `${meta.form}.fields.${action.payload.propertyName}`
//           )
//         )

//         const fields = mapper[action.payload.propertyName]
//         if (
//           (action.type === INDEX_SEARCH_CONSTANTS.BLUR && fields && isSet) ||
//           (action.type === DDICONSTANTS.BLUR &&
//             value &&
//             propertyName === 'job' &&
//             fields)
//         ) {
//           yield put(disableFields(meta.form, fields))
//         } else if (action.type !== DDICONSTANTS.SET_FIELD) {
//           yield put(enableFields(meta.form, fields || []))
//         }

//         if (action.type === DDICONSTANTS.SET_FIELD && !value && fields) {
//           yield put(enableFields(meta.form, fields))
//         }

//         // if (action.type === DDICONSTANTS.BLUR) {

//         // }
//       }
//     }
//   }
// }

// export function* setFieldListener() {
//   const trackedProps = ['poNumber', 'product']
//   while (true) {
//     const action = yield take(DDICONSTANTS.SET_FIELD)
//     const { meta, payload: { propertyName, value } } = action
//     if (meta.form) {
//       if (meta.form.includes('purchaseOrderInquiry')) {
//         if (trackedProps.includes(propertyName)) {
//           // console.log(action)
//           yield put(actions.setFlagged(meta.form, !!value))
//         }
//       }
//     }
//   }
// }

export function* executeInquiryListener() {
  let task
  while (true) {
    const action = yield take(EXECUTE_INQUIRY.TRY)
    const {
      meta: { form },
      payload
    } = action
    // console.log('executeInquiryListener', action)
    if (task) yield cancel(task)

    task = yield call(executeInquiryProcess, action)
  }
}

export function* executeInquiryProcess(action) {
  // console.log('executeInquiryProcess')
  let form
  const { status, dataId, productId, vendorId, jobId } = action.payload

  // debugger
  // const invalidFormSelection =
  //   status === 'O' && !(dataId || productId || vendorId || jobId)

  // if (form === 'purchaseOrderInquiry' && invalidFormSelection) {
  //   yield call(
  //     confirmationModal,
  //     'Do you wish to see all orders?',
  //     'No Selection Entered'
  //   )
  //   const action = yield take([CONFIRMED, CANCELED])
  //   if (action.type === CANCELED) {
  //     return
  //   }
  // }
  yield put({ type: EXECUTE_INQUIRY.REQUEST, meta: { form, apiRequest: true } })

  const { response, error } = yield call(api.purchaseOrderInquiry, {
    dataId,
    jobId,
    productId,
    status,
    vendorId
  })

  if (response) {
    yield put({
      meta: action.meta,
      payload: { ...response },
      type: EXECUTE_INQUIRY.SUCCESS
    })
  } else {
    yield put({
      meta: action.meta,
      payload: { ...error },
      error: true,
      type: EXECUTE_INQUIRY.FAILURE
    })
  }
}

export function* dropDownOpenListener() {
  while (true) {
    const action = yield take(OPEN_DROPDOWN.REQUEST)
    const { meta } = action
    // console.log('dropDownListener', meta)
    yield putResolve({
      meta: {
        form: meta.form,
        thunk: meta.thunk
      },
      type: OPEN_DROPDOWN.SUCCESS
    })
  }
}

export function* updateIndexSearchLabelListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value, results, description },
      type
    } = yield take([
      INDEX_SEARCH_CONSTANTS.CLEAR_SEARCH,
      DDICONSTANTS.SET_FIELD,
      INDEX_SEARCH_CONSTANTS.FIND_NEXT.SUCCESS,
      INDEX_SEARCH_CONSTANTS.FIND_PREV.SUCCESS
    ])

    if (form === formListener && propertyName) {
      if (propertyName === 'product' || propertyName === 'vendor') {
        const descriptionField = `${propertyName}Description`
        const resetValue = ''
        if (type === DDICONSTANTS.SET_FIELD) {
          if (value && results && results.description) {
            yield put(setField(form, descriptionField, results.description))
          } else {
            yield put(setField(form, descriptionField, resetValue))
            yield put(actions.clearInquiryResults(form))
          }
        } else if (type === INDEX_SEARCH_CONSTANTS.CLEAR_SEARCH) {
          yield put(setField(form, descriptionField, resetValue))
          yield put(actions.clearInquiryResults(form))
        }

        if (
          type === INDEX_SEARCH_CONSTANTS.FIND_NEXT.SUCCESS ||
          type === INDEX_SEARCH_CONSTANTS.FIND_PREV.SUCCESS
        ) {
          yield put(actions.clearInquiryResults(form))
          if (description) {
            yield put(setField(form, descriptionField, description))
          }
        }
      } else {
        yield put(actions.clearInquiryResults(form))
      }
    }
  }
}

export default function* purchaseOrderInquirySagas(form) {
  yield fork(executeInquiryListener)
  yield fork(onBlurListener)
  yield fork(purchaseOrderExactMatchSearchListener)
  // yield fork(onOpenProcess, form)
  // yield fork(updateIndexSearchLabelListener, form)
}
