import React from 'react'
import { behaviors } from './reducer'
import { ddiForm } from 'ddiForm'
import { arInvoiceExactMatch } from './actions'
// import { isFormValid } from './utils'
import EventModal from './ConnectedEventModal'

const FormEventModal = ddiForm({
  actions: { arInvoiceExactMatch },
  form: 'eventModal',
  disableMeta: true,
  behaviors,
  initializeOnMount: true,
  withFields: true,

  // initialState
})(EventModal)

export default FormEventModal
