import toJSON from './toJSON'

/**
 * creates an options object to pass along with callFetch
 * @param {string} method - post method type (e.g. get or post)
 * @param {object} body - JSON object with API params
 * @returns {object} returns options object for callFetch param
 */
const createApiOptions = ({ method, body } = { method: 'get', body: null }) => {
  const options = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    method
  }
  if (body) {
    options.body = toJSON(body)
  }

  return options
}

export default createApiOptions
