/* eslint react/jsx-no-duplicate-props: 0 */
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { TextField as MuiTF, InputAdornment } from '@material-ui/core'
import { noop } from 'utils'
import {
  PhoneNumberTextField,
  CurrencyTextField,
  NumericTextField,
  ZipCodeTextField,
  EmailAddressTextField,
  UrlField
} from 'components/masks'
import memoize from 'memoize-one'
import {
  onChange,
  onBlur,
  getFormSelector,
  onBlurNumeric,
  onChangeNumeric
} from './utils'

// #517b9c
const defaultLinkStyle = {
  fontWeight: 'bold',
  fontSize: '1.1em',
  color: '#517b9c',
  paddingTop: 3,
  width: '100%',
  padding: '6px 0 7px',
  border: 0,
  margin: 0,
  display: 'block',
  minWidth: 0,
  boxSizing: 'content-box',
  background: 'none',
  outline: 'none',
  '-webkit-tap-highlight-color': 'transparent'
}
const Link = props => {
  const { inputRef, value } = props
  const [style, setStyle] = useState(defaultLinkStyle)

  const hoverOn = () => {
    setStyle({
      ...style,
      cursor: 'pointer',
      textDecoration: 'underline'
    })
  }
  const hoverOff = () => {
    setStyle(defaultLinkStyle)
  }

  return (
    <input
      type="text"
      onClick={props.onClick}
      style={style}
      ref={ref => {
        inputRef(ref || null)
      }}
      value={value}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
    />
  )
}

export const Masks = {
  currency: CurrencyTextField,
  email: EmailAddressTextField,
  numeric: NumericTextField,
  phoneNumber: PhoneNumberTextField,
  zipCode: ZipCodeTextField,
  url: UrlField
}

class TextField extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.dirty && nextProps.value !== prevState.value) {
      return {
        value: nextProps.value
      }
    }

    return null
  }

  static defaultProps = {
    isFetching: false,
    prevValue: '',
    value: '',
    onDoubleClick: noop,
    blur: noop,
    isActive: false,
    preventFocusCallback: false,
    variant: 'standard',
    linkToSendDocument: false,
    sendDocumentArgs: {}
  }

  static propTypes = {
    blur: PropTypes.func,
    floatingLabelFocusStyle: PropTypes.object,
    floatingLabelShrinkStyle: PropTypes.object,
    floatingLabelStyle: PropTypes.object,
    focus: PropTypes.func.isRequired,
    hintStyle: PropTypes.object,
    id: PropTypes.string.isRequired,
    preventFocusCallback: PropTypes.bool,
    isActive: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFound: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    prevValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textFieldStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string
  }

  constructor(...args) {
    super(...args)
    this.state = {
      dirty: false,
      value: this.props.value || this.props.value === 0 ? this.props.value : ''
    }

    if (this.props.mask) {
      let mask
      if (typeof this.props.mask === 'string') {
        mask = Masks[this.props.mask]
      } else if (
        typeof this.props.mask === 'function' ||
        Array.isArray(this.props.mask)
      ) {
        mask = this.props.mask
      } // or array allow it..
      if (mask) {
        this.mask = mask
        this.onChange = onChangeNumeric.bind(this)
        this.onBlur = onBlurNumeric.bind(this)
      } else {
        this.onChange = onChange.bind(this)
        this.onBlur = onBlur.bind(this)
      }
    } else {
      this.onChange = onChange.bind(this)
      this.onBlur = onBlur.bind(this)
    }
  }

  ref = React.createRef()

  onClick = e => {
    this.props.dispatch({
      type: 'SEND_DOCUMENT_TRY',
      meta: {
        form: this.props.form
      },
      payload: {
        ...this.props.sendDocumentArgs,
        value: this.props.value,
        mask: this.props.mask,
        propertyName: this.props.propertyName
      }
    })
  }

  onFocus = e => {
    e.persist()

    /* 
      Note: preventFocusCallback is super important, needed for DDITextField
      used in a functional modal component -- SVE 6/10/2020
    */
    if (this.props.focus && !this.props.preventFocusCallback) {
      this.props.focus(e)
    }

    const reference =
      this.props.inputRef && typeof this.props.inputRef === 'object'
        ? this.props.inputRef
        : this.inputRef

    if (reference && reference.current && reference.current.select) {
      reference.current.select()
    }

    if (this.input) {
      this.input.select()
    }
  }

  getMask = (disableMask, disabled, linkToSendDocument, maskProp, value) => {
    if (this.props.mask === 'url' && disabled && value) {
      return {
        inputComponent: UrlField,
        inputProps: { ...this.props.inputProps, value, onClick: this.onClick }
      }
    }
    if (disabled && linkToSendDocument && value) {
      return {
        inputComponent: Link,
        inputProps: { ...this.props.inputProps, value, onClick: this.onClick }
      }
    }
    if (this.mask && !disableMask && this.props.mask !== 'url') {
      return {
        inputComponent: this.mask,
        startAdornment:
          this.props.mask === 'currency' || this.props.includeDollarSign ? (
            <InputAdornment position="start">
              {this.props.prefix || '$'}
            </InputAdornment>
          ) : (
            undefined
          ),
        inputProps: this.props.inputProps
      }
    }

    return undefined
  }

  inputRef = c => {
    this.input = c
    if (this.props.inputRef && typeof this.props.inputRef === 'function') {
      this.props.inputRef(c)
    }
    // else if (this.props.inputRef && typeof this.props.inputRef === 'object') {
    //   return this.props.inputRef
    // }
    // return undefined
  }

  input = null

  render() {
    const {
      autoFocus,
      disabled,
      errorText,
      isEditing,
      id,
      label,
      margin,
      onBlur,
      onDoubleClick,
      onFocus,
      placeholder,
      rows,
      multiline,
      style,
      type,
      InputProps,
      inputRef,
      decimalScale,
      disableMask,
      variant,
      linkToSendDocument,
      mask: maskProp,
      inputProps
      // value
    } = this.props
    const { value } = this.state
    const mask =
      InputProps ||
      this.getMask(disableMask, disabled, linkToSendDocument, maskProp, value)

    // return this.props.mask === 'email' &&
    //   linkToSendDocument &&
    //   value &&
    //   disabled ? (
    //     <AltField placeholder={placeholder} value={value} />
    //   ) : (
    const reference =
      this.props.inputRef && typeof this.props.inputRef === 'object'
        ? this.props.inputRef
        : this.inputRef

    return (
      <MuiTF
        autoFocus={autoFocus}
        disabled={disabled}
        id={id}
        label={label}
        margin={margin}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onDoubleClick={onDoubleClick}
        // onFocus={onFocus}
        placeholder={placeholder}
        style={style}
        value={value}
        rows={rows}
        type={type || 'text'}
        multiline={multiline || false}
        InputProps={mask}
        error={!isEditing ? undefined : !!errorText}
        inputRef={reference}
        // inputRef={this.inputRef}
        variant={variant}
        ref={this.ref}
        inputProps={inputProps || {}}
      />
    )
  }
}
export default TextField
