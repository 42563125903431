/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import ComponentStyles from 'theme/ComponentStyles'
import { AppBar, Paper, Tabs, Tab } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import memoize from 'memoize-one'
import shortid from 'shortid'
import Measure from 'react-measure'
import ddiForm from 'ddiForm/ddiForm'
import GridField from 'ddiForm/GridField'
import DDIButton from 'ddiForm/Button'
import Grid from 'grid'

import { getIn, deepEqual, getField, emptyList, plainDeepEqual } from 'utils'
import { postProcessPopup, createFooterTotals } from 'hoc/withRowTotals/utils'
import Typography from '@material-ui/core/Typography'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import AnalysisTab from './components/AnalysisTab'
import InquiryTab from './components/InquiryTab'
import CustomTooltip from './components/grid/CustomTooltip'
import * as actions from './actions'
import behaviors, { getFieldMinWidth } from './behaviors'
import sagas from './sagas'
import './styles/css-mod-ignore.scss'

export function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

const getLineItemsDataTotals = memoize(
  (lineItems = []) =>
    lineItems &&
    Array.isArray(lineItems) &&
    lineItems.length &&
    lineItems.length > 1
      ? lineItems.reduce(
          (a, n, i) => {
            if (i > 0) {
              a.quantityOrdered += n.quantityOrdered
              a.quantityShipped += n.quantityShipped
              a.quantityBO += n.quantityBO
              a.quantityCommitted += n.quantityCommitted
            }
            return a
          },
          {
            quantityOrdered: 0,
            quantityShipped: 0,
            quantityBO: 0,
            quantityCommitted: 0
          }
        )
      : {
          quantityOrdered: 0,
          quantityShipped: 0,
          quantityBO: 0,
          quantityCommitted: 0
        },
  plainDeepEqual
)

export const pinnedDataFn = rowData => {
  // console.log(rowData)
  const newData = rowData.reduce(
    (acc, next) => {
      const lineItemsAdditionalData = getLineItemsDataTotals(next.lineItems)

      acc.total += next.total
      acc.quantityOrdered +=
        next.quantityOrdered + lineItemsAdditionalData.quantityOrdered
      acc.quantityShipped +=
        next.quantityShipped + lineItemsAdditionalData.quantityShipped
      acc.quantityBO += next.quantityBO + lineItemsAdditionalData.quantityBO
      acc.quantityCommitted +=
        next.quantityCommitted + lineItemsAdditionalData.quantityCommitted

      return acc
    },
    {
      total: 0.0,
      quantityOrdered: 0,
      quantityShipped: 0,
      quantityBO: 0,
      quantityCommitted: 0
    }
  )
  // console.log([newData])
  return [newData]
}

const getDataIds = rowData =>
  rowData.reduce((acc, next) => {
    acc = acc.concat(next.dataId)
    return acc
  }, [])

export class SalesOrderSearch extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    buttonStyle: PropTypes.object,
    getEntity: PropTypes.func.isRequired,
    hintStyle: PropTypes.object,
    iconStyle: PropTypes.object,
    id: PropTypes.string,
    meta: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    screenName: PropTypes.string
  }

  static ddiType = 'SalesOrderSearch'

  static defaultProps = {
    autoFocus: false,
    value: null,
    buttonStyle: {
      background: '#517b9c',
      borderRadius: '0 4px 4px 0',
      float: 'left',
      height: 26,
      maxWidth: 30,
      padding: 0,
      width: '15%'
    },
    disablePropChange: false,
    gridHeight: 100,
    hintStyle: { fontSize: 12, width: '100%' },
    hintText: 'Search...',
    iconStyle: {
      color: '#fff',
      fill: '#fff',
      fontSize: 16,
      height: 16,
      width: 20
    },
    screenName: null,
    textFieldStyle: {
      float: 'left',
      height: 26,
      width: '85%'
    },
    width: '100%'
  }

  constructor(props) {
    super(props)

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      tab: 'inquiry',
      printType: ''
    }
  }

  componentDidMount() {
    this._isMounted = true
    const { dispatch, form } = this.props

    dispatch(actions.checkForSearchParamsOnLoad(form))
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps, prevState) {
    const rowData =
      getIn(this.props.fields, 'inquiryResults.rowData') || emptyList

    const prevRowData =
      getIn(prevProps.fields, 'inquiryResults.rowData') || emptyList

    if (
      !prevRowData.size &&
      rowData.size &&
      this?.grid?.connectedGrid?.current?.api
    ) {
      // debugger
      setTimeout(() => {
        this.grid.connectedGrid.current.api.forEachNode(node => {
          if (node?.data?.lineItems?.length > 1) {
            // node.setExpanded(true)
            node.expanded = true
          }
        })

        this.grid.connectedGrid.current.api.onGroupExpandedOrCollapsed()
      }, 0)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !deepEqual(nextProps, this.props) ||
      !plainDeepEqual(nextState.dimensions, this.state.dimensions) ||
      this.state.tab !== nextState.tab
    ) {
      return true
    }
    return false
  }

  // componentDidMount() {
  //   this.anchorEl = findDOMNode(this.textField)
  //   if (this.props.autoFocus) {
  //     this.textField.setState({ isFocused: true }, () => {
  //       setTimeout(() => {
  //         this.textField.focus()
  //       }, 20)
  //     })
  //   }
  // }

  // onKeyDown = event => {
  //   if (event.key === 'Tab') {
  //     event.preventDefault()
  //     // this.props.getShippingConfirmation({ dataId: event.target.value })
  //     this.props.getEntity(event.target.value)
  //   }
  // }

  // tabs
  handleTabChange = (event, value) => {
    // console.log(value)
    if (typeof value === 'string') {
      this.setState({ tab: value }, () => {
        console.log(this.state)
        // setTimeout(() => {
        //   this.forceUpdate()
        // }, 100)
        //    this.props.tabChange(value)
      })
    }
  }
  // openSearch = async () => {
  //   console.log(this.props)
  //   try {
  //     await this.props.open()
  //     this.setState({ isOpen: true })
  //   } catch (e) {
  //     // TODO // modal for auth.. // deal with error
  //     console.log(e)
  //   }
  // }

  partialMatchSearch = propertyName => {
    // partialMatchSearch: ({ keyword, indexSearchType, propertyName, pageNumber }) => {
    //   const r = indexSearchActions.partialMatchSearch(form, {
    //     keyword,
    //     indexSearchType: 'branch',
    //     propertyName: 'SOSearch',
    //     pageNumber,
    //     subProperty: 'foo',
    //   })
    this.props.partialMatchSearch.bind(propertyName)
  }

  getRowNodeId = data => data.dataId

  onRowSelected = params => {
    // if (!this.props.asModal) return
    const dataIds = getDataIds(params.api.getSelectedRows()) || []

    // this.props.rowSelected(dataIds)
    this.setSelectedRows(dataIds)
    if (this.props.onRowSelected) {
      // debugger
      // this.props.onRowSelected.call(this, params)
      this.props.onRowSelected(params)
    }
  }

  setSelectedRows = debounce((dataIds = []) => {
    this.props.rowSelected(dataIds)
  }, 500)

  isRowSelectable = node => {
    const printType = getIn(this.props.fields, 'printType.value') || ''

    if (this.props.asModal) {
      return true
    }

    if (printType === 'SOR' && node?.data?.canPrintOrderRecap) {
      return true
    }

    if (node?.data?.canPrint) {
      return true
    }

    return false
  }

  searchDisabled = (field, formState) =>
    getIn(formState, 'fields.inquiryResults.isFetching')

  reportDisabled = (field, formState) => {
    const results = formState?.fields?.inquiryResults?.rowData || []

    return !results.length
  }

  getDetailColDefs = memoize((colDefs = [], rowData = []) => {
    if (!Array.isArray(colDefs) || !Array.isArray(rowData)) {
      return []
    }

    colDefs = colDefs.reduce((acc, next, idx) => {
      if (
        rowData &&
        Array.isArray(rowData) &&
        rowData.every(
          x =>
            x?.lineItems && Array.isArray(x?.lineItems) && x?.lineItems?.length
        ) &&
        rowData?.[0]?.lineItems?.[0]
      ) {
        const additionalParams = {}

        if (next.minWidth) {
          additionalParams.minWidth = next.minWidth
        }

        if (next.maxWidth) {
          additionalParams.maxWidth = next.maxWidth
        }

        if (next.lockPosition) {
          additionalParams.lockPosition = next.lockPosition
        }

        if (next.headerClass) {
          additionalParams.headerClass = next.headerClass
        }

        if (next.pinned) {
          return acc
        }

        if (Object.keys(rowData[0].lineItems[0]).includes(next.field)) {
          acc = acc.concat({
            ...next,
            field: next.field,
            colId: next?.field || null,
            sortable: false,
            ...additionalParams,
            width: next.width ? next.width : getFieldMinWidth(next.field)
          })
        } else {
          acc = acc.concat({
            field: next.field,
            colId: next?.field || null,
            cellStyle: next?.cellStyle,
            cellClass: next?.cellClass,
            lockPosition: next?.lockPosition || false,
            suppressMenu: next?.suppressMenu || false,
            suppressNavigable: next?.suppressNavigable || false,
            sortable: false,
            ...additionalParams,
            width: next.width ? next.width : getFieldMinWidth(next.field)
          })
        }
      }

      return acc
    }, [])

    return colDefs
  }, plainDeepEqual)

  getRowHeight = params => {
    if (
      params.node &&
      params.node.detail &&
      params.data.lineItems &&
      params.data.lineItems.length
    ) {
      return (
        (params.data.lineItems.length - 1) * 28 +
        (params.data.lineItems.length - 1)
      )
    }

    return 28
  }

  grid = null

  onGridReady = params => {
    if (params) {
      this.grid = params
    }
  }

  getDesktopClientGridHeight = memoize((dimensions = { height: 1 }) => {
    if (this?.outerWrapper?.offsetHeight && dimensions?.height) {
      return this.outerWrapper.offsetHeight - dimensions.height - 20
    }

    return 325
  }, plainDeepEqual)

  onDragStopped = params => {
    /*
      detailGridInfo.columnApi.setColumnWidth is not working the way it should.
      Only effects the grid momentarily when resizing a column. Going to disable
      and take a look when I return from vacation -- SVE 12/22/2020
    */
    const { api, columnApi } = params
    const parentColumnState = columnApi.getColumnState()
    let parentColDefs = columnApi.columnController.columnDefs

    api.forEachDetailGridInfo(detailGridInfo => {
      const detailColumnState = detailGridInfo.columnApi.getColumnState()
      const detailColDefs = detailGridInfo.columnApi.columnController.columnDefs

      if (detailColumnState.length) {
        for (let i = 0; i < detailColumnState.length; i++) {
          const { colId } = detailColumnState[i]
          const correctIndex = parentColumnState.findIndex(
            x => x.colId === colId
          )

          if (
            parentColumnState[correctIndex] &&
            parentColumnState[correctIndex].width &&
            detailColumnState[i].width !== parentColumnState[correctIndex].width
          ) {
            detailGridInfo.columnApi.setColumnWidth(
              colId,
              parentColumnState[correctIndex].width
            )

            const correctWidthFn = (acc, next) => {
              if (next.colId === colId) {
                acc = acc.concat({
                  ...next,
                  width: parentColumnState[correctIndex].width,
                  minWidth: parentColumnState[correctIndex].width
                })
              } else {
                acc = acc.concat(next)
              }

              return acc
            }

            // const updatedDetailColumnState = detailColumnState.reduce(
            //   correctWidthFn,
            //   []
            // )

            const updatedChildColDefs = detailColDefs.reduce(correctWidthFn, [])
            parentColDefs = parentColDefs.reduce(correctWidthFn, [])

            // console.log('updatedChildColDefs', updatedChildColDefs)
            detailGridInfo.api.setColumnDefs(updatedChildColDefs)
            // detailGridInfo.columnApi.setColumnState(updatedDetailColumnState)
          }
        }
      }
    })

    // console.log('updatedParentColDefs', parentColDefs)
    api.setColumnDefs(parentColDefs)
  }

  launchReport = () => {
    const { dispatch, form } = this.props
    dispatch(actions.viewReport.try(form))
  }

  redrawFooter = ({ api }) =>
    createFooterTotals(api, pinnedDataFn, this._isMounted, false)

  render() {
    const { asModal, meta, hintStyle, fields } = this.props

    // console.log(meta)
    const { dimensions, tab } = this.state
    const { screenLayout } = ComponentStyles
    const status = getIn(fields, 'status.value')

    let colDefs =
      getIn(this.props.fields, 'inquiryResults.columnDefs') || emptyList
    colDefs = colDefs?.toJS ? colDefs.toJS() : []

    let rowData =
      getIn(this.props.fields, 'inquiryResults.rowData') || emptyList
    rowData = rowData?.toJS ? rowData.toJS() : []

    const suppressMovableColumns =
      (rowData &&
        Array.isArray(rowData) &&
        rowData.every(
          x =>
            x?.lineItems && Array.isArray(x?.lineItems) && x?.lineItems?.length
        )) ||
      false

    const desktopClientGridHeight = this.getDesktopClientGridHeight(dimensions)

    return (
      <div
        className="advanced-search-tabs clear tabs-wrapper"
        style={{ width: this.props.width }}
      >
        <div
          className="sales-order-inquiry-container"
          style={screenLayout.container}
        >
          <div
            className="sales-order-inquiry-content-wrapper"
            style={screenLayout.contentWrapper}
            ref={el => (this.outerWrapper = el)}
          >
            <Measure
              bounds
              onResize={contentRect =>
                this.setState({ dimensions: contentRect.bounds })
              }
            >
              {({ measureRef }) => (
                <div
                  ref={measureRef}
                  style={{ maxWidth: '100%', width: '100%' }}
                >
                  <AppBar position="static">
                    <Tabs
                      value={tab}
                      onChange={this.handleTabChange}
                      scrollable
                      // indicatorColor="#1abcf7"
                    >
                      <Tab value="inquiry" label="Inquiry" />
                      <Tab value="analysis" label="Analysis" />
                    </Tabs>
                  </AppBar>
                  <TabContainer>
                    {!asModal ? (
                      <DDIExpansionPanel
                        title={
                          tab === 'inquiry'
                            ? 'Inquiry Options'
                            : 'Analysis Options'
                        }
                        titleIcon={tab === 'inquiry' ? 'search' : 'analytics'}
                        expansionPanelDetailsStyle={{
                          padding: 0,
                          width: '100%'
                        }}
                        expansionPanelContentStyle={{
                          padding: 0,
                          width: '100%'
                        }}
                      >
                        {tab === 'inquiry' && (
                          <InquiryTab
                            form={this.props.form}
                            meta={meta}
                            propertyName={this.props.propertyName}
                            screenName={this.props.screenName}
                            hintStyle={hintStyle}
                            textFieldStyle={{
                              fontSize: 12,
                              maxWidth: '100%',
                              width: '100%'
                            }}
                            asModal={asModal}
                            status={status}
                          />
                        )}
                        {tab === 'analysis' && (
                          <AnalysisTab
                            form={this.props.form}
                            propertyName={this.props.propertyName}
                            hintStyle={hintStyle}
                            screenName={this.props.screenName}
                            textFieldStyle={this.props.textFieldStyle}
                            asModal={asModal}
                            meta={meta}
                          />
                        )}
                      </DDIExpansionPanel>
                    ) : (
                      <>
                        {tab === 'inquiry' && (
                          <InquiryTab
                            form={this.props.form}
                            meta={meta}
                            propertyName={this.props.propertyName}
                            screenName={this.props.screenName}
                            hintStyle={hintStyle}
                            textFieldStyle={{
                              fontSize: 12,
                              maxWidth: '100%',
                              width: '100%'
                            }}
                            asModal={asModal}
                            status={status}
                          />
                        )}
                        {tab === 'analysis' && (
                          <AnalysisTab
                            form={this.props.form}
                            propertyName={this.props.propertyName}
                            hintStyle={hintStyle}
                            screenName={this.props.screenName}
                            textFieldStyle={this.props.textFieldStyle}
                            asModal={asModal}
                            meta={meta}
                          />
                        )}
                      </>
                    )}
                  </TabContainer>
                </div>
              )}
            </Measure>
            <div
              ref={el => (this.scrollArea = el)}
              style={{ margin: '10px 0', minHeight: 200 }}
            >
              <GridField
                propertyName="inquiryResults"
                getRowNodeId={this.getRowNodeId}
                height={asModal ? 200 : desktopClientGridHeight}
                getRowHeight={this.getRowHeight}
                onRowSelected={this.onRowSelected}
                rowSelection={!asModal ? 'multiple' : 'single'}
                /* autoSize || cannot use this with masterDetail for parent-child alignment */
                animateRows
                rowDeselection
                // pinnedData={pinnedDataFn}
                enableSorting
                isRowSelectable={this.isRowSelectable}
                onGridReady={this.onGridReady}
                masterDetail
                embedFullWidthRows
                suppressMovableColumns={suppressMovableColumns}
                detailCellRendererParams={{
                  detailGridOptions: {
                    suppressAutoSize: true,
                    animateRows: true,
                    enableColResize: false,
                    headerHeight: 0,
                    suppressHorizontalScroll: true,
                    getRowNodeId: data => data.rowId,
                    columnDefs: this.getDetailColDefs(colDefs, rowData),
                    onGridReady: params => {
                      this.detailGridApi = params.api
                      this.detailGridColumnApi = params.columnApi
                    }
                  },
                  getDetailRowData: params => {
                    /*
                      of course we can't use sortIndex as the getRowNodeId identifier,
                      because it only shows up sometimes. We will need to generate our
                      own rowId unfortunately -- SVE 12/21/2020
                    */
                    const lineItems =
                      params.data.lineItems && params.data.lineItems.length > 1
                        ? params.data.lineItems.slice(1).reduce((acc, next) => {
                            acc = acc.concat({
                              ...next,
                              rowId: shortid.generate()
                            })
                            return acc
                          }, [])
                        : []

                    params.successCallback(lineItems)
                  },
                  template:
                    '<div class="soi-inquiry-outer-detail-wrapper" style="padding: 0; box-sizing: border-box;">' +
                    '  <div ref="eDetailGrid" style="height: 100%;"></div>' +
                    '</div>'
                }}
                components={{
                  customTooltip: CustomTooltip
                }}
                ref={el => (this.grid = el)}
                suppressRowClickSelection={!asModal}
                postProcessPopup={postProcessPopup}
                onRowDataUpdated={this.redrawFooter}
                onFilterChanged={this.redrawFooter}
                // enableFilter={false}
              />
            </div>
          </div>
          <div
            className="sales-order-inquiry-footer-wrapper"
            style={screenLayout.footerWrapper}
          >
            <Paper
              className="action-buttons clear"
              style={ComponentStyles.footer.containerStandalone}
            >
              <div style={{ float: 'left' }}>
                <DDIButton
                  component={Button}
                  label="Reset"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  actionName="reset"
                  bindTo="onClick"
                  // onClick={resetAll}
                />
              </div>
              <div style={{ float: 'right' }}>
                <DDIButton
                  component={Button}
                  label="Search"
                  actionName="executeInquiry"
                  bindTo="onClick"
                  variant="contained"
                  disabled={this.searchDisabled}
                  style={ComponentStyles.footer.button}
                />
                {!this.props.asModal && (
                  <span>
                    <DDIButton
                      actionName="printSalesOrders"
                      label="Print"
                      bindTo="onClick"
                      variant="contained"
                      disabled={(field, formState) => {
                        const { selectedRows } = formState
                        // console.log('disabled', field, formState)
                        if (
                          selectedRows &&
                          Array.isArray(selectedRows) &&
                          selectedRows.length
                        ) {
                          return false
                        }
                        return true
                      }}
                      style={ComponentStyles.footer.button}
                    />
                    <DDIButton
                      variant="contained"
                      disabled={this.reportDisabled}
                      style={ComponentStyles.footer.button}
                      onClick={this.launchReport}
                    >
                      Report
                    </DDIButton>
                    <DDIButton
                      // component={Button}
                      variant="contained"
                      label="Exit"
                      actionName="exit"
                      bindTo="onClick"
                    />
                  </span>
                )}
              </div>
            </Paper>
          </div>
        </div>
      </div>
    )
  }
}

export const options = {
  actions: {
    tabChange: actions.tabChange,
    find: actions.find,
    executeInquiry: actions.executeInquiry.try,
    reset: actions.reset,
    rowSelected: actions.rowSelected,
    printSalesOrders: actions.printSalesOrders.try
  },
  behaviors,
  sagas: { onInit: sagas },
  form: 'salesOrderInquiry',
  title: 'Sales Order Inquiry',
  withFields: true
}

export const component = SalesOrderSearch
// console.log(ddiForm)
// debugger
export default ddiForm(options)(component)
