/* eslint react/prefer-stateless-function: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import { Icon, IconButton, Popover } from '@material-ui/core'
import Grid from 'grid'
import { plainDeepEqual } from 'utils'
import { setField } from 'ddiForm/actions'
import DDITextField from 'ddiForm/wrapped/DDITextField'
import WebCatCheckbox from './components/WebCatCheckbox'

const getRowNodeId = data => data.recordName

const isRowMaster = row => {
  return !!(row.children && Array.isArray(row.children) && row.children.length)
}

export const getSelectedCategory = (partId, uniqueRowId, acc, next) => {
  if (next[uniqueRowId] === partId) {
    acc = acc.concat(next)
  } else if (next.children) {
    acc = acc.concat(
      next.children.reduce(
        (a, n) => getSelectedCategory(partId, uniqueRowId, a, n),
        []
      )
    )
  }
  return acc
}

/* ref: https://stackoverflow.com/questions/62613338/react-ag-grid-identifying-overnode-when-dragging-grid-to-grid */
const getAgGridNodeBeingDraggedOver = (
  event,
  rowData = [],
  uniqueRowId = ''
) => {
  const elements = document.elementsFromPoint(event.clientX, event.clientY)
  const agGridRow = elements.find(r => r.classList.contains('ag-row'))

  if (agGridRow) {
    const dropTargetId = agGridRow.getAttribute('row-id')
    const targetNode = rowData.reduce(
      (acc, next) => getSelectedCategory(dropTargetId, uniqueRowId, acc, next),
      []
    )

    return targetNode
  }

  return null
}

const dynamicExpanderRow = (form, propertyName, closePopoverCallbackFn) => [
  {
    field: 'recordName',
    headerName: '',
    cellClass: 'cell-value-hidden',
    cellRendererSelector: params => {
      if (params.data.children && params.data.children.length) {
        return {
          component: 'agGroupCellRenderer',
          params: {
            form,
            propertyName,
            closePopoverCallbackFn
          }
        }
      }

      console.log('WEB_CAT_CHECKBOX_cellRendererSelector', params.data, params)
      return {
        component: 'webCatCheckbox',
        params: {
          form,
          propertyName,
          closePopoverCallbackFn
        }
      }
    },
    filter: false,
    minWidth: 38,
    maxWidth: 38,
    width: 38
  },
  {
    field: 'dataId',
    headerName: 'Code'
  },
  {
    field: 'description',
    headerName: 'Name'
  }
]

const getHeight = (arr, base) =>
  arr.reduce((ac, nxt) => {
    if (nxt.children && nxt.children.length) {
      ac += nxt.children.length * 28 + 32
    }
    return ac
  }, base)

const getAvailableSelections = (rowData, id) =>
  rowData && rowData.length
    ? rowData.reduce((acc, next, idx) => {
        if (next.children && next.children.length) {
          acc = acc.concat(...getAvailableSelections(next.children, id))
        }
        if (next.children && !next.children.length) {
          acc = acc.concat(next[id])
        }
        return acc
      }, [])
    : []

class MasterDetailGridDropdown extends Component {
  static propTypes = {
    dispatch: PropTypes.bool.isRequired,
    expandOnLoad: PropTypes.bool,
    hasRecord: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    rowData: PropTypes.array.isRequired,
    uniqueRowId: PropTypes.string,
    value: PropTypes.string
  }

  static defaultProps = {
    expandOnLoad: false,
    uniqueRowId: 'recordName',
    value: ''
  }

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      availableSelections: getAvailableSelections(
        props.rowData,
        props.uniqueRowId
      ),
      popoverOpen: false,
      rowData: props.rowData,
      value: props.value || ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!plainDeepEqual(nextProps.rowData, prevState.rowData)) {
      return {
        rowData: nextProps.rowData,
        availableSelections: getAvailableSelections(
          nextProps.rowData,
          nextProps.uniqueRowId
        )
      }
    }

    if (nextProps.value !== prevState.value) {
      return {
        value: nextProps.value
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.props.value) {
      /*
        if the user has manually edited the text field
        and left an invalid value, clear it out
      */
      if (!this.state.availableSelections.includes(this.props.value)) {
        this.props.dispatch(
          setField(this.props.form, this.props.propertyName, '')
        )
      }
    }
  }

  openPopover = event => {
    this.setState(
      {
        popoverOpen: true
      },
      () => {
        if (this.gridApi && this.props.expandOnLoad) {
          setTimeout(() => {
            this.gridApi.expandAll()
          }, 0)
        }
      }
    )
  }

  closePopover = () => {
    this.setState(
      {
        popoverOpen: false
      },
      () => {
        if (this.gridApi && this.props.expandOnLoad) {
          setTimeout(() => {
            this.gridApi.collapseAll()
          }, 0)
        }
      }
    )
  }

  onRowGroupOpened = params => {
    this.gridApi.forEachNode(node => {
      if (node.expanded) {
        node.detailNode.gridApi.forEachNode(n => {
          // console.log('onRowGroupOpened_0', node, n)
          if (params.node.expanded && n.expanded && n.detailNode.detail) {
            const rowHeight = n?.data?.children?.length
              ? n.data.children.length * 28 + 32
              : 28

            let additionalHeight = 0
            if (n.detailNode.detailGridInfo.api) {
              n.detailNode.detailGridInfo.api.forEachNode(detailNode => {
                // console.log('onRowGroupOpened_1A', detailNode)
                if (detailNode.expanded && detailNode?.data?.children?.length) {
                  const thisRowHeight =
                    detailNode.data.children.length * 28 + 32
                  additionalHeight += thisRowHeight
                }
              })
            }

            // console.log('onRowGroupOpened_1', node, n.data, rowHeight, additionalHeight)
            n.detailNode.setRowHeight(rowHeight + additionalHeight)
            n.detailNode.gridApi.onRowHeightChanged()
          } else {
            setTimeout(() => {
              n.parent.gridApi.forEachNode(x => {
                if (!params.node.expanded) {
                  const height = x?.detailNode?.data?.children?.length
                    ? x.detailNode.data.children.length * 28 + 32
                    : 28
                  // console.log('onRowGroupOpened_2', node, height)
                  if (x?.detailNode?.parent?.detailNode) {
                    x.detailNode.parent.detailNode.setRowHeight(height)
                    x.detailNode.parent.detailNode.gridApi.onRowHeightChanged()
                  }
                }
              })
              n.parent.gridApi.onRowHeightChanged()
            }, 100)
          }
        })
        node.detailNode.gridApi.onRowHeightChanged()
      }
    })
    if (this.gridApi) {
      this.gridApi.onRowHeightChanged()
    }
  }

  buildGridConfiguration = memoize((rowData = []) => {
    const makeParams = collection => {
      const ret =
        collection && collection.length
          ? collection.reduce((acc, next, index) => {
              if (next.children && next.children.length) {
                acc.detailGridOptions = {
                  ...acc.detailGridOptions,
                  isRowMaster,
                  frameworkComponents: {
                    webCatCheckbox: WebCatCheckbox
                  },
                  columnDefs: dynamicExpanderRow(
                    this.props.form,
                    this.props.propertyName,
                    this.closePopover
                  ),
                  masterDetail: true,
                  getRowNodeId,
                  onGridReady: params => {
                    if (this.props.expandOnLoad) {
                      setTimeout(() => {
                        params.api.forEachNode(node => {
                          node.setExpanded(true)
                        })
                      }, 0)
                    }
                  },
                  onRowGroupOpened: this.onRowGroupOpened,
                  getRowHeight: params => {
                    const rowCount =
                      params.node.parent.gridApi.getDisplayedRowCount() || 0
                    // console.log('getRowNodeId_IF', params, rowCount, params.data.children)

                    const isDetailRow = params.node.detail
                    if (isDetailRow) {
                      const detailPanelHeight = rowCount * 28 + 32
                      return detailPanelHeight
                    }

                    // for all non-detail rows, return 25, the default row height
                    return 28
                  },
                  detailCellRendererParams: makeParams(next.children)
                }
                acc.getDetailRowData = params => {
                  params.successCallback(params.data.children)
                }
              } else {
                acc.detailGridOptions = {
                  ...acc.detailGridOptions,
                  getRowNodeId,
                  onRowGroupOpened: this.onRowGroupOpened,
                  getRowHeight: params => {
                    // console.log('getRowNodeId_ELSE', params)
                    const rowCount =
                      params.node.parent.gridApi.getDisplayedRowCount() || 0
                    const isDetailRow = params.node.detail

                    if (isDetailRow) {
                      const detailPanelHeight =
                        params.data.children.length * 50 + 32 + rowCount * 28
                      return detailPanelHeight
                    }

                    return 28
                  },
                  isRowMaster,
                  frameworkComponents: {
                    webCatCheckbox: WebCatCheckbox
                  },
                  columnDefs: dynamicExpanderRow(
                    this.props.form,
                    this.props.propertyName,
                    this.closePopover
                  )
                }
                acc.getDetailRowData = params =>
                  params.successCallback(params.data.children)
              }
              return acc
            }, {})
          : {}

      return ret
    }

    const newConfig = {
      rowData,
      isRowMaster,
      frameworkComponents: {
        webCatCheckbox: WebCatCheckbox
      },
      columnDefs: dynamicExpanderRow(
        this.props.form,
        this.props.propertyName,
        this.closePopover
      ),
      masterDetail: true,
      getRowNodeId,
      onRowGroupOpened: this.onRowGroupOpened,
      onGridReady: this.onGridReady,
      domLayout: 'autoHeight',
      getRowHeight: params => {
        // console.log('getRowNodeId_BASE', params)
        const isDetailRow = params.node.detail
        if (isDetailRow) {
          const detailPanelHeight = params.data.children.length * 28 + 32
          return detailPanelHeight
        }

        return 28
      },

      detailCellRendererParams: makeParams(rowData[0]?.children)
    }

    return newConfig
  })

  onExpandClicked = params => {
    console.log('onExpandClicked', params)
  }

  // getRowHeight = params => {
  //   if (!params.data.children.length) {
  //     return 28
  //   }

  //   return 28
  // }

  onGridReady = params => {
    const { expandOnLoad } = this.props
    this.gridApi = params.api
    this.columnApi = params.columnApi
    // debugger
    if (expandOnLoad) {
      setTimeout(() => {
        this.gridApi.forEachNode(node => {
          node.setExpanded(true)
        })
      }, 0)
    }
  }

  render() {
    const { rowData } = this.props
    const { hasRecord, isEditing, label, propertyName } = this.props
    const { popoverOpen, value } = this.state
    const buttonDisabled = !(hasRecord && isEditing)
    // const open = Boolean(anchorEl)

    const gridData = this.buildGridConfiguration(rowData)

    return (
      <div>
        <div
          ref={el => (this.dropdown = el)}
          style={{ position: 'relative', width: '100%' }}
        >
          <DDITextField
            label={label}
            propertyName={propertyName}
            value={value}
          />
          <IconButton
            disabled={buttonDisabled}
            onClick={this.openPopover}
            style={{
              position: 'absolute',
              right: -10,
              top: 5
            }}
          >
            <Icon style={{ color: '#444' }}>arrow_drop_down</Icon>
          </IconButton>
        </div>
        <Popover
          id="master-detail-grid-popover"
          open={popoverOpen}
          anchorEl={this.dropdown}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div className="web-options-cat-dropdown" style={{ width: 600 }}>
            <Grid {...gridData} />
          </div>
        </Popover>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(MasterDetailGridDropdown)
