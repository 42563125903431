import {
  KeyboardDatePicker as MuiDP,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import React, { forwardRef } from 'react'
import MomentUtils from '@date-io/moment'

const DatePicker = forwardRef((props, ref) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <div
      className="ddi-picker-with-label"
      style={{ position: 'relative' }}
      tabIndex={props.tabIndex}
    >
      <MuiDP {...props} ref={ref} />
    </div>
  </MuiPickersUtilsProvider>
))

DatePicker.defaultProps = {
  initialValue: null,
  disabled: false,
  disablePropChange: false,
  displayLabel: true,
  format: 'MM/DD/YYYY',
  hintStyle: { display: 'none', fontSize: 12 },
  hintText: 'Select a Date',
  iconStyles: {},
  inputStyle: { marginTop: 0 },
  isFetching: false,
  isFound: false,
  label: '',
  labelStyles: {},
  loadingIndicator: '',
  locale: 'en-US',
  openTo: 'date',
  placeholder: 'Select a Date',
  // textFieldStyle: { height: 26, marginTop: 8, width: '100%' },
  style: {},
  tabIndex: 0,
  textFieldStyle: {
    fontSize: 11,
    width: '100%',
    height: 48
  },
  type: 'Field',
  value: null,
  clearable: true,
  keyboard: true
  // views: ['year', 'month', 'day']
}

export default DatePicker
