import { createAsyncRequestTypes } from 'utils'

export const calendar = '@@calendar'
export const TASK_COMPLETED = createAsyncRequestTypes(`${calendar}/TASK_COMPLETED`)
export const REFRESH_ACTIVITIES = createAsyncRequestTypes(`${calendar}/REFRESH_ACTIVITIES`)
export const UPDATE_ACTIVITY = createAsyncRequestTypes(`${calendar}/UPDATE_ACTIVITY`)
export const SELECT_USERS = `${calendar}/SELECT_USERS`
export const INIT_CALENDAR = `${calendar}/INIT_CALENDAR`
export const SELECT_USER_TASK_TYPE = `${calendar}/SELECT_USER_TASK_TYPE`
export const CHANGE_ACTIVE_DATE = `${calendar}/CHANGE_ACTIVE_DATE`
export const CHANGE_CALENDAR_VIEW = `${calendar}/CHANGE_CALENDAR_VIEW`

export const MANUAL_SELECT_USERS = `${calendar}/MANUAL_SELECT_USERS`
export const SELECT_EVENT_TYPES = `${calendar}/SELECT_EVENT_TYPES`

export const UPDATE_ACTIVITY_DATA = `${calendar}/UPDATE_ACTIVITY_DATA`
export const SHOW_CALENDAR_ACCESS_OVERRIDE = `${calendar}/SHOW_CALENDAR_ACCESS_OVERRIDE`
export const GET_ACTIVITY_TOOLTIP = createAsyncRequestTypes(`${calendar}/GET_TOOLTIP`)

export const ADD_NEW_ACTIVITY = createAsyncRequestTypes(`${calendar}/ADD_NEW_ACTIVITY`)
export const LOCK_ACTIVITY = createAsyncRequestTypes(`${calendar}/LOCK_ACTIVITY`)
export const DELETE_ACTIVITY = createAsyncRequestTypes(`${calendar}/DELETE_ACTIVITY`)
// export const LOCK_ACTIVITY = createAsyncRequestTypes(`${calendar}/LOCK_ACTIVITY`)
