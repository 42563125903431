import { createAction, createAsyncAction } from 'utils'
import * as CONSTANTS from './constants'

export const partialMatchSearch = (
  form,
  {
    allowSpecialProducts,
    keyword,
    indexSearchType,
    propertyName,
    pageNumber,
    subProperty,
    searchAll = false,
    parentId = null,
    isFiltered,
    leader = false,
    parentType,
    resultsMaxLimit
  }
) =>
  createAction(
    CONSTANTS.PARTIAL_MATCH_SEARCH.REQUEST,
    {
      allowSpecialProducts,
      keyword,
      indexSearchType,
      propertyName,
      pageNumber,
      subProperty,
      searchAll,
      parentId,
      isFiltered,
      leader,
      parentType,
      resultsMaxLimit
    },
    null,
    null,
    {
      form,
      apiRequest: true,
      thunk: true,
      reducer: 'IndexSearch'
    }
  )

export const findNext = (form, { indexSearchType, recordName, propertyName }) =>
  createAction(
    CONSTANTS.FIND_NEXT.REQUEST,
    {
      indexSearchType,
      propertyName,
      recordName
    },
    null,
    null,
    { form, apiRequest: true, reducer: 'IndexSearch', thunk: true }
  )
export const findPrev = (form, { indexSearchType, recordName, propertyName }) =>
  createAction(
    CONSTANTS.FIND_PREV.REQUEST,
    {
      indexSearchType,
      propertyName,
      recordName
    },
    null,
    null,
    { form, apiRequest: true, reducer: 'IndexSearch', thunk: true }
  )

export const exactMatchSearch = (
  form,
  {
    keyword,
    indexSearchType,
    propertyName,
    moreInfo = false,
    includeParent = false,
    isRecordName = undefined,
    allowInvalidValues = false,
    ...rest
  }
) => ({
  meta: {
    form,
    thunk: true,
    apiRequest: true,
    reducer: 'IndexSearch'
  },
  payload: {
    indexSearchType,
    keyword,
    propertyName,
    moreInfo,
    includeParent,
    isRecordName,
    allowInvalidValues,
    ...rest
  },
  type: CONSTANTS.EXACT_MATCH_SEARCH.REQUEST
})

export const toggleFilter = (form, { propertyName, subProperty }) =>
  createAction(
    CONSTANTS.TOGGLE_FILTER,
    { propertyName, subProperty },
    null,
    null,
    { form, reducer: 'IndexSearch' }
  )

export const blur = (form, { propertyName, subProperty, value, isSet }) =>
  createAction(
    CONSTANTS.BLUR,
    {
      propertyName,
      subProperty,
      value,
      isSet
    },
    null,
    null,
    { form, reducer: 'IndexSearch' }
  )
export const focus = (form, { propertyName, subProperty }) =>
  createAction(CONSTANTS.FOCUS, { propertyName, subProperty }, null, null, {
    form,
    reducer: 'IndexSearch'
  })
export const populateField = (
  form,
  { propertyName, description, indexSearchType, value }
) =>
  createAction(
    CONSTANTS.POPULATE_FIELD,
    {
      propertyName,
      description,
      indexSearchType,
      value
    },
    null,
    null,
    { form, reducer: 'IndexSearch' }
  )
export const getTooltip = (form, args) => {
  const action = createAction(
    CONSTANTS.GET_TOOLTIP.REQUEST,
    args,
    form,
    false,
    { thunk: true, apiRequest: true, reducer: 'IndexSearch', form }
  )
  return action
}

// export const findNext = (form, { indexSearchType, recordName, propertyName }) =>
//   createAction(CONSTANTS.FIND_NEXT.REQUEST, { indexSearchType, propertyName, recordName }, form)

export const getSearchFilters = (form, { indexSearchType, propertyName }) =>
  createAction(
    CONSTANTS.GET_SEARCH_FILTERS.REQUEST,
    { indexSearchType, propertyName },
    null,
    null,
    { form, apiRequest: true, reducer: 'IndexSearch' }
  )

export const clearFilterField = ({
  form,
  parentField,
  filterType,
  propertyName
}) =>
  createAction(
    CONSTANTS.CLEAR_FILTER_FIELD,
    {
      parentField,
      filterType,
      propertyName
    },
    null,
    null,
    { form, reducer: 'IndexSearch' }
  )

export const setTextFilterField = ({
  form,
  parentField,
  filterType,
  propertyName,
  value
}) =>
  createAction(
    CONSTANTS.SET_TEXT_FILTER_FIELD,
    {
      parentField,
      filterType,
      propertyName,
      value
    },
    null,
    null,
    { reducer: 'IndexSearch', form }
  )

export const resetFilters = (form, { propertyName }) =>
  createAction(CONSTANTS.RESET_FILTERS, { propertyName }, null, null, {
    form,
    reducer: 'IndexSearch'
  })

export const clearSearch = (form, { propertyName }) =>
  createAction(CONSTANTS.CLEAR_SEARCH, { propertyName }, null, null, {
    form,
    reducer: 'IndexSearch'
  })

export const foundInvalidValues = (form, args) =>
  createAction(CONSTANTS.FOUND_INVALID_VALUES, args, null, null, {
    form,
    thunk: true,
    reducer: 'IndexSearch'
  })

  export const clearForm = (form, args = {}) =>
  createAction(CONSTANTS.CLEAR_FORM, args, null, null, {
    form,
    thunk: true,
  })