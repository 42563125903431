import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

// export const blur = (form, { propertyName, subProperty }) =>
//   createAction(CONSTANTS.BLUR, { propertyName, subProperty }, form)

// export const focus = (form, { propertyName, subProperty }) =>
//   createAction(CONSTANTS.FOCUS, { propertyName, subProperty }, form)

// export const exactMatch = (form, propertyName, keyword) => {
//   console.log(keyword, form, propertyName)
//   return createAction(CONSTANTS.EXACT_MATCH.REQUEST, { propertyName, keyword }, form)
// }

export const blur = (form, { propertyName, subProperty, keyword, isSet }) =>
  createAction(
    CONSTANTS.BLUR,
    {
      propertyName,
      subProperty,
      keyword,
      isSet
    },
    form,
    null,
    { thunk: true }
  )
export const clearSearch = (form, { propertyName }) =>
  createAction(CONSTANTS.CLEAR_SEARCH, { propertyName }, form)

export const exactMatchFromForm = (form, propertyName, keyword) =>
  createAction(
    CONSTANTS.EXACT_MATCH.REQUEST,
    { keyword, propertyName },
    form,
    null,
    { thunk: true }
  )

export const exactMatch = (form, { propertyName, keyword }) =>
  createAction(
    CONSTANTS.EXACT_MATCH.REQUEST,
    { propertyName, keyword },
    form,
    null,
    { thunk: true }
  )

export const reset = form => createAction(CONSTANTS.RESET, {}, form)

export const rowSelected = (form, value) =>
  createAction(CONSTANTS.ROW_SELECTED, { value }, form)

export const executeInquiry = (form, payload) => ({
  meta: {
    form,
    thunk: true
  },
  payload,
  type: CONSTANTS.EXECUTE_INQUIRY.TRY
})

export const openDropDown = form =>
  createAction(CONSTANTS.OPEN_DROPDOWN.REQUEST, {}, form, null, { thunk: true })

export const clearInquiryResults = form =>
  createAction(CONSTANTS.CLEAR_INQUIRY_RESULTS, {}, form)
