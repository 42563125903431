import { List } from 'immutable'
/* again, don't think this function is being used */

/**
 * Immutable-style implementation of native JS splice method
 * @param {List} list - immutable list
 * @param {number} index - starting index
 * @param {number} removeNum - number of array items to remove
 * @param {any} value - replacement value(s)
 * @returns {List} updated List
*/
const splice = (list, index, removeNum, value) => {
  list = List.isList(list) ? list : List()

  if (index < list.count()) {
    if (value === undefined && !removeNum) { // inserting undefined
      // first insert null and then re-set it to undefined
      return list.splice(index, 0, null).set(index, undefined)
    }
    if (value != null) {
      return list.splice(index, removeNum, value)  // removing and adding
    }

    return list.splice(index, removeNum)  // removing
  }
  // trying to remove non-existent item: return original array
  if (removeNum) {
    return list
  }
  // trying to add outside of range: just set value
  return list.set(index, value)
}

export default splice
