/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import {
  Input,
  InputAdornment,
  ButtonBase,
  Icon,
  IconButton
} from '@material-ui/core'
import shouldUpdate from 'hoc/shouldUpdate'
import { debounce } from 'lodash'
import { noop, dataURItoBlob } from 'utils'

const whitelist = ['value', 'mouseover', 'disabled', 'meta', 'form']

const toDebounce = ['onSearchClick', 'redoSearch', 'findPrev', 'findNext']

export class TextInput extends Component {
  static defaultProps = {
    navigationArrowStyles: {
      iconStyle: { fontSize: 16, padding: 0 },
      style: {
        // float: 'right',
        height: 20,
        marginTop: 4,
        padding: 0,
        width: 20
      }
    },
    navWrapper: {
      // float: 'right',
      height: 20,
      marginTop: 4,
      padding: 0,
      width: 40
    },
    iconStyle: {
      color: '#fff',
      fill: '#fff',
      fontSize: 16,
      height: 16,
      width: 20,
      outline: 'none'
    },
    onSearchClick: noop,
    getSearchFilters: noop,
    redoSearch: noop,
    findPrev: noop,
    findNext: noop,
    documentType: 'image',
    maxUploadSize: 25000000,
    allowedFileTypes: {
      documentspecs: [
        'bmp',
        'jpeg',
        'jpg',
        'png',
        'gif',
        'rtf',
        'xls',
        'xlsx',
        'doc',
        'docx',
        'csv',
        'txt',
        'pdf'
      ],
      image: ['jpeg', 'png', 'jpg', 'gif'],
      msds: [
        'bmp',
        'jpeg',
        'jpg',
        'png',
        'gif',
        'rtf',
        'xls',
        'xlsx',
        'doc',
        'docx',
        'csv',
        'txt',
        'pdf'
      ]
    },
    allowedMimeTypes: {
      documentspecs: [
        'image/bmp',
        'image/jpeg',
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/rtf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'text/csv'
      ],
      image: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'],
      msds: [
        'image/bmp',
        'image/jpeg',
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/rtf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'text/csv'
      ]
    }
  }

  constructor(...args) {
    super(...args)

    this.state = {
      files: [],
      errorMessage: ''
    }

    toDebounce.forEach(prop => {
      Object.assign(this, {
        [prop]: debounce((...args) => this.props[prop](...args), 1000)
      })
    })
  }

  /* clears the error message if the user just selects a valid file */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.value &&
      prevState.value !== nextProps.value &&
      prevState.errorMessage
    ) {
      return {
        value: nextProps.value,
        errorMessage: ''
      }
    }

    if (nextProps.value && prevState.value !== nextProps.value) {
      return {
        value: nextProps.value
      }
    }

    return null
  }

  componentDidMount() {
    if (this.props.autoFocus && this.textInput) {
      setTimeout(() => this.textInput && this.textInput.focus(), 1)
    }
  }

  onFocus = e => {
    e.persist()
    if (this.textInput) this.textInput.select()
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  _getSearchFilters = () => {
    const { propertyName } = this.props
    const indexSearchType =
      this.props.indexSearchType ||
      (this.props.meta && this.props.meta.searchType)
    this.props.getSearchFilters({ indexSearchType, propertyName })
  }

  inputRef = c => {
    this.textInput = c
    if (this.props.inputRef) {
      this.props.inputRef(c)
    }
  }

  openFileBrowser = e => {
    if (!this.props.disabled) {
      this.inputEl.click()
    }
  }

  onOpenFileBrowserHandler = e => (e.target.value = null)

  onSelectFileUpload = e => {
    const { files } = e.target
    this.handleFileUploadProcess(files)
  }

  handleFileUploadProcess = files => {
    const allFilePromises = []
    const {
      allowedFileTypes,
      allowedMimeTypes,
      documentType,
      maxUploadSize
    } = this.props

    // Iterate over all uploaded files
    for (let i = 0; i < files.length; i++) {
      const f = files[i]

      // Check for correct MIME type
      const extension = f.name.split('.').pop()

      // debugger
      if (!allowedFileTypes[documentType].includes(extension.toLowerCase())) {
        this.setState({ errorMessage: 'File type not allowed' })
        break
      }

      // Check for file size
      if (f.size > maxUploadSize) {
        this.setState({ errorMessage: 'File size too large' })
        break
      }

      allFilePromises.push(this.readFile(f))
    }

    Promise.all(allFilePromises).then(newFilesData => {
      const dataURLs = []
      const fileData = []

      newFilesData.forEach(newFileData => {
        dataURLs.push(newFileData.dataURL)
        fileData.push(newFileData.file)

        if (this.props.handleFileUpload) {
          this.setState({ errorMessage: '' }, () => {
            this.props.handleFileUpload({
              meta: newFileData.file,
              data: newFileData.postData,
              base64: newFileData.dataURL,
              type: this.props.fileType
            })
          })
        }
      })
    })
  }

  readFile = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()

      // Read the image via FileReader
      reader.onload = function postData(e) {
        console.log(e)
        const dataURL = e.target.result
        const postData = dataURL.split(',')[1]
        // .replace(/^data:image\/\w+;base64,/, '')
        // .replace('data:application/octet-stream;base64,', '')
        resolve({ file, dataURL, postData })
      }

      reader.readAsDataURL(file)
    })

  handleDrop = e => {
    e.preventDefault()
    const files = []

    if (this.props.disabled) {
      return
    }
    // debugger

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile()
          const { type } = e.dataTransfer.items[i]
          files.push(file)
        }
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        files.push(e.dataTransfer.files[i])
      }
    }

    /* only allow the user to drag and drop one file at a time */
    if (files.length && files.length === 1) {
      this.handleFileUploadProcess(files)
    }
  }

  onDragOver = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  onDragEnter = e => {
    e.stopPropagation()
  }

  removeErrorMessage = () => this.setState({ errorMessage: '' })

  handleFileDownloadProcess = () => {
    const { value } = this.props
    this.props.handleFileDownload(value)
  }

  render() {
    const {
      autoFocus,
      inputRef,
      label,
      placeholder,
      id,
      disabled,
      lastSearch,
      value,
      onChange,
      onDoubleClick,
      onFocus,
      onKeyDown,
      onBlur,
      meta = {},
      buttonStyle,
      onSearchClick,
      iconStyle,
      // disableLastSearchButton,
      mouseover,
      navigationArrowStyles,
      redoSearch,
      isSet,
      findNext,
      findPrev,
      getSearchFilters,
      filtersButtonRef
    } = this.props

    const { errorMessage } = this.state

    console.log('render', this)

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
        onDrop={this.handleDrop}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
      >
        {errorMessage ? (
          <span
            style={{
              alignItems: 'center',
              color: '#d9534f',
              display: 'flex',
              fontSize: 11,
              fontStyle: 'italic',
              position: 'absolute',
              bottom: -15,
              left: 0
            }}
          >
            <i
              className="material-icons"
              onClick={this.removeErrorMessage}
              style={{ fontSize: 12, cursor: 'pointer' }}
            >
              close
            </i>
            {errorMessage}
          </span>
        ) : null}
        <Input
          autoFocus={autoFocus}
          inputRef={this.inputRef}
          fullWidth={this.props.fullWidth || true}
          label={label}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onDoubleClick={onDoubleClick}
          onFocus={this.onFocus}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          endAdornment={
            <InputAdornment position="end">
              <div
                className="utility-buttons"
                style={{
                  height: 26,
                  zIndex: 2,
                  display: 'flex'
                }}
              >
                {meta.allowInstantSearch &&
                lastSearch &&
                mouseover &&
                !disabled ? (
                  <IconButton
                    className="hvr-bounce-in"
                    style={navigationArrowStyles.style}
                    onClick={this.props.redoSearch}
                    tabIndex={-1}
                  >
                    <Icon tabIndex={-1} style={{ padding: 0, fontSize: 16 }}>
                      refresh
                    </Icon>
                  </IconButton>
                ) : (
                  <div
                    style={{
                      height: 20,
                      marginTop: 4,
                      padding: 0,
                      width: 20
                      // border: '2px solid blue'
                    }}
                  />
                )}
                {meta.allowDownload &&
                value &&
                isSet &&
                !disabled &&
                mouseover ? (
                  <IconButton
                    className="hvr-bounce-in"
                    style={navigationArrowStyles.style}
                    onClick={this.handleFileDownloadProcess}
                  >
                    <Icon tabIndex={-1} style={{ padding: 0, fontSize: 16 }}>
                      save_alt
                    </Icon>
                  </IconButton>
                ) : (
                  <div
                    style={{
                      height: 20,
                      marginTop: 4,
                      padding: 0,
                      width: 20
                      // border: '2px solid blue'
                    }}
                  />
                )}
                {meta.allowSearchAll && (
                  <div
                    style={{
                      background: 'rgb(81, 123, 156)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '0px 5px 5px 0px'
                    }}
                  >
                    <ButtonBase
                      id={`${id}-search-all-trigger`}
                      disabled={disabled}
                      style={{
                        ...buttonStyle,
                        height: 26,
                        borderRadius: '0px 5px 5px 0px',
                        background: disabled ? '#ccc' : undefined
                      }}
                      onClick={this.onSearchClick}
                      tabIndex={-1}
                    >
                      <Icon tabIndex={-1} style={iconStyle}>
                        search
                      </Icon>
                    </ButtonBase>
                  </div>
                )}
              </div>
            </InputAdornment>
          }
          startAdornment={
            meta.hasUpload && (
              <InputAdornment position="start">
                <IconButton
                  // className="hvr-bounce-in"
                  style={{
                    bottom: -2,
                    height: 20,
                    left: 0,
                    padding: 0,
                    // position: 'absolute',
                    width: 20,
                    zIndex: 2
                  }}
                  onClick={this.openFileBrowser}
                  // ref={el => (this.filtersButton = el)}
                  tabIndex={-1}
                  ref={filtersButtonRef}
                >
                  <Icon
                    tabIndex={-1}
                    style={{
                      height: 20,
                      padding: 0,
                      width: 20,
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    folder
                    {/* {meta.hasFilters && mouseover && !disabled && 'filter_list'} */}
                  </Icon>
                </IconButton>
              </InputAdornment>
            )
          }
          inputProps={{
            style: {
              height: 26,
              paddingBottom: 0,
              paddingTop: 0
            },
            'data-lpignore': true
          }}
        />
        <div style={{ display: 'none' }}>
          <input
            type="file"
            ref={el => (this.inputEl = el)}
            onChange={this.onSelectFileUpload}
            onClick={this.onOpenFileBrowserHandler}
          />
        </div>
      </div>
    )
  }
}
export default shouldUpdate({ whitelist })(TextInput)
