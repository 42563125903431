import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { getDisplayName } from 'utils'
import hoistStatics from 'hoist-non-react-statics'
import { Tooltip } from 'react-tippy'
import HoverIntent from 'react-hoverintent'

export default (opts = {}) => WrappedComponent => {
  opts = {
    position: opts.position || 'bottom',
    sensitivity: opts.sensitivity || 7,
    interval: opts.interval || 700,
    timeout: opts.timeout || 200,
    title: opts.title || null,
    popperOptions: opts.popperOptions || {},
    dataField: opts.dataField || null
  }

  class DynamicGridDataTooltip extends Component {
    constructor(props) {
      super(props)

      this.state = {
        hoverIntended: false
      }
    }

    hoverIntended = event => this.setState({ hoverIntended: true })

    hoverNotIntended = event => this.setState({ hoverIntended: false })

    getTooltip = memoize(data => {
      if (data && data[opts.dataField]) {
        return (
          <div className="inform-tooltip">
            <div className="tooltip-content" style={{ padding: 10 }}>
              {opts.title ? (
                <h2
                  style={{
                    color: '#fff',
                    fontSize: 12,
                    marginBottom: 5,
                    marginTop: 0
                  }}
                >
                  {opts.title}
                </h2>
              ) : null}
              <p style={{ color: '#fff', fontSize: 11, marginBottom: 0 }}>
                {data[opts.dataField]}
              </p>
            </div>
          </div>
        )
      }

      return null
    })

    render() {
      const { hoverIntended } = this.state
      const { data } = this.props
      // console.log('DynamicGridDataTooltip', data)

      if (!data[opts.dataField]) {
        return <WrappedComponent {...this.props} />
      }

      return (
        <HoverIntent
          sensitivity={opts.sensitivity}
          interval={opts.interval}
          timeout={opts.timeout}
          onMouseOver={this.hoverIntended}
          onMouseOut={this.hoverNotIntended}
        >
          <span>
            <Tooltip
              open={hoverIntended}
              html={this.getTooltip(data)}
              unmountHTMLWhenHide
              position={opts.position}
              // onShown={this.onTooltipShown}
              popperOptions={opts.popperOptions}
            >
              <div>
                <WrappedComponent {...this.props} />
              </div>
            </Tooltip>
          </span>
        </HoverIntent>
      )
    }
  }

  return DynamicGridDataTooltip
}
