import { createAsyncRequestTypes } from 'utils'

export const ACCOUNT = '@@ACOUNT/'
export const SIGNUP = createAsyncRequestTypes(`${ACCOUNT}SIGNUP`)
export const CHANGE_PASSWORD = createAsyncRequestTypes(
  `${ACCOUNT}CHANGE_PASSWORD`
)

export const CHANGE_EMAIL = createAsyncRequestTypes(`${ACCOUNT}CHANGE_EMAIL`)
export const RESET_PASSWORD = createAsyncRequestTypes(
  `${ACCOUNT}RESET_PASSWORD`
)

export const VALIDATE_FIELD = `${ACCOUNT}VALIDATE_FIELD`
export const VALIDATE_PASSWORD_STRENGTH = `${ACCOUNT}VALIDATE_PASSWORD_STRENGTH`
export const VALIDATE_PASSWORD_CONFIRM = `${ACCOUNT}VALIDATE_PASSWORD_CONFIRM`
export const RESET_STATUS = `${ACCOUNT}RESET_STATUS`
