import {
  createAction,
  createAsyncAction,
  noop,
  createAsyncFormAction
} from 'utils'

import {
  ADD_BLANK_ROW,
  ADD_SCREEN_IN_MODAL,
  BEFORE_DESTROY,
  BLUR,
  CANCEL,
  CHANGE,
  CHANGE_FORM_TAB,
  CHANGE_DISPLAY_TABS,
  CLOSE_DROP_DOWN,
  // CUSTOM_ACTION,
  DELETE_ENTITY,
  DESTROY,
  DESTROY_FIELD,
  DISABLE_FIELDS,
  EDIT_GRID_CELL,
  ENABLE_FIELDS,
  ENTER_NEW_MODE,
  EXIT,
  FOCUS,
  GET_ENTITY,
  INITIALIZE,
  LOCK_FOR_EDIT,
  PRE_INITIALIZE,
  PROPERTY_CHANGE,
  REGISTER_FIELD,
  REGISTER_SUBFORM,
  SAVE,
  SET_FIELD,
  SET_FIELDS,
  SET_VALUE,
  UNLOCK_ENTITY,
  UPDATE_FORM_TITLE,
  TRY_CHANGE_FORM_TAB,
  CHANGE_PRIMARY_TAB,
  CHANGE_SECONDARY_TAB,
  SET_FORM_META,
  GET_ALTERNATE_META,
  SET_SELECTED_LINE_ITEM_ID,
  SET_SELECTED_CELL,
  SHOW_QUICK_ENTITY,
  SET_SELECTED_ROW_INDEX
} from './constants'

export {
  exactMatchSearch,
  partialMatchSearch,
  findPrev,
  findNext,
  getSearchFilters
} from 'components/Search/IndexSearch/actions'

export const beforeDestroy = (form, { name, retainData = false }) => ({
  meta: { form },
  payload: { name, retainData },
  type: BEFORE_DESTROY
})
export const blur = (form, propertyName, value) => ({
  meta: { form },
  payload: { propertyName, value },
  type: BLUR
})
export const change = (form, propertyName, value) => ({
  meta: { form },
  payload: { propertyName, value },
  type: CHANGE
})
// export const destroy = (form, oneTimeAccess = false) => ({ meta: { form }, payload: { oneTimeAccess }, type: DESTROY })
export const destroy = form => ({
  meta: { form },
  payload: {},
  type: DESTROY
})
export const focus = (form, propertyName) => ({
  meta: { form },
  payload: { propertyName },
  type: FOCUS
})

export const getEntity = (form, dataId) => ({
  meta: { form },
  payload: { dataId },
  type: GET_ENTITY.REQUEST
})

export const destroyField = (form, propertyName) => ({
  meta: { form },
  payload: { propertyName },
  type: DESTROY_FIELD
})
// export const preInitialize = (form, response) => ({
//   meta: { form, thunk: true },
//   payload: response,
//   type: PRE_INITIALIZE
// })
export const initialize = (
  form,
  {
    apiAlias = null,
    initialState = {},
    values,
    sagas = {},
    behaviors = {},
    actions = {},
    title,
    disableMeta = false,
    subform = null,
    meta = {},
    masterOptions = null,
    ...rest
  }
) => {
  sagas.onCancel = sagas.onCancel || noop
  sagas.onDelete = sagas.onDelete || noop
  sagas.onExit = sagas.onExit || noop
  sagas.onGetEntitySuccess = sagas.onGetEntitySuccess || noop
  sagas.onInit = sagas.onInit || noop
  sagas.onSave = sagas.onSave || noop
  sagas.onOpen = sagas.onOpen || noop
  sagas.propertyValidations = sagas.propertyValidations || {}

  form = subform ? `${subform}.${form}` : form
  return {
    meta: { form, thunk: true },
    payload: {
      apiAlias,
      actions,
      behaviors,
      disableMeta,
      sagas,
      title,
      values,
      meta,
      initialState,
      masterOptions,
      ...rest
    },
    type: INITIALIZE
  }
}

export const cancel = form => ({ meta: { form }, type: CANCEL })
export const exit = form => ({ meta: { form }, type: EXIT })
export const deleteEntity = form => ({ meta: { form }, type: DELETE_ENTITY })
// export const delete = form => ({ meta: { form }, type: DELETE })
export const save = form => ({ meta: { form }, type: SAVE.TRY })
export const registerField = (
  form,
  { propertyName, searchType, type, meta, leader, ...rest } = {}
) => ({
  meta: { form },
  payload: {
    leader,
    meta,
    propertyName,
    searchType,
    type,
    ...rest
  },
  type: REGISTER_FIELD
})

export const setField = (
  form,
  propertyName,
  value,
  deleteMeta = false,
  leader,
  results
) => ({
  meta: { form, leader },
  payload: {
    propertyName,
    value,
    deleteMeta,
    results
  },
  type: SET_FIELD
})
export const setValue = (form, propertyName, value) => ({
  meta: { form },
  payload: {
    propertyName,
    value
  },
  type: SET_VALUE
})
export const setFields = (form, fields) => ({
  meta: { form },
  payload: { fields },
  type: SET_FIELDS
})

export const editGridCell = (
  form,
  propertyName,
  rowId,
  colId,
  value,
  dataId
) => ({
  meta: { form },
  payload: {
    colId,
    dataId,
    propertyName,
    rowId,
    value
  },
  type: EDIT_GRID_CELL
})

export const addBlankRow = (form, args) => ({
  meta: { form, reducer: 'Grid', thunk: true },
  payload: { ...args },
  type: ADD_BLANK_ROW
})
// not runtime bound..
export const unlockEntity = ({ form, dataId }) => ({
  meta: { form },
  payload: { dataId },
  type: UNLOCK_ENTITY.REQUEST
})

export const disableFields = (form, fields) => ({
  meta: { form },
  payload: { fields },
  type: DISABLE_FIELDS
})

export const enableFields = (form, fields) => ({
  meta: { form },
  payload: { fields },
  type: ENABLE_FIELDS
})
export const closeDropDown = (form, name) => ({
  meta: { form },
  payload: { name: form },
  type: CLOSE_DROP_DOWN
})

export const changePrimaryTab = (form, tab) =>
  createAction(CHANGE_PRIMARY_TAB, { tab }, form)

export const changeFormTab = (form, selectedPrimaryTab, selectedSecondaryTab) =>
  createAction(
    CHANGE_FORM_TAB,
    { selectedPrimaryTab, selectedSecondaryTab },
    form
  )

export const tryChangeFormTab = (
  form,
  selectedPrimaryTab,
  selectedSecondaryTab
) =>
  createAction(
    TRY_CHANGE_FORM_TAB,
    { selectedPrimaryTab, selectedSecondaryTab },
    form
  )
export const changeSecondaryTab = (form, { primaryTab, value }) =>
  createAction(CHANGE_SECONDARY_TAB, { primaryTab, value }, form)
export const updateFormTitle = (form, title) =>
  createAction(UPDATE_FORM_TITLE, { title }, form)
export const changeDisplayTabs = (form, tabNames = []) =>
  createAction(CHANGE_DISPLAY_TABS, { tabNames }, form, false)
export const enterNewMode = form => createAction(ENTER_NEW_MODE, {}, form)
export const addScreenInModal = (form, ...args) =>
  createAction(ADD_SCREEN_IN_MODAL, ...args, null, false, {
    form,
    thunk: true,
    withAccess: true
  })

export const setFormMeta = (form, response) =>
  createAction(SET_FORM_META, response, form)

export const showQuickEntity = (form, { propertyName, ...rest }) =>
  createAction(SHOW_QUICK_ENTITY, { propertyName, ...rest }, form)

export const setSelectedRowIndex = (form, args) => ({
  meta: { form, reducer: 'Grid' },
  payload: args,
  type: SET_SELECTED_ROW_INDEX
})

export const setSelectedGridCell = (form, args) => ({
  meta: { form, reducer: 'Grid' },
  payload: args,
  type: SET_SELECTED_CELL
})
