import React, { Component } from 'react'
import ChipInput from 'material-ui-chip-input'
import shouldUpdate from 'hoc/shouldUpdate'
import { debounce } from 'lodash'
import { deepEqual, noop } from 'utils'

const whitelist = ['value', 'mouseover', 'disabled']

const toDebounce = ['onSearchClick', 'redoSearch', 'findPrev', 'findNext']

export class TextInput extends Component {
  static defaultProps = {
    fullWidth: true,
    variant: 'outlined'
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(nextProps, prevState)
    let ret = null
    // const chips = nextProps.value.map(
    //   x => `${x.description}  (${x.email ? x.email : ''})`
    // )
    const chips = nextProps.value.reduce((acc, x) => {
      if (x.description) {
        // acc = acc.concat(`${x.description}  (${x.email ? x.email : ''})`)
        // acc = acc.concat(`${x.description}  (${nextProps.sendType === 'Email' ? x.email ? x.email : ''})`)
        const suffix = nextProps.sendType === 'Email' ? x.email : x.fax
        acc = acc.concat(`${x.description}  (${suffix || ''})`)
      }
      return acc
    }, [])

    if (!deepEqual(chips, prevState.chips)) {
      ret = {
        chips,
        inputValue: ''
      }
    }
    if (nextProps.inputValue !== prevState.inputValue) {
      ret = {
        ...ret,
        inputValue: nextProps.inputValue
      }
    }
    return ret
  }

  constructor(...args) {
    super(...args)
    toDebounce.forEach(prop => {
      Object.assign(this, {
        [prop]: debounce((...args) => this.props[prop](...args), 1000)
      })
    })
    this.state = {
      mode: 'send', // 'search',
      chips: [] // deal with constructor props...
    }
  }

  componentDidMount() {
    if (this.props.autoFocus && this.textInput) {
      setTimeout(() => this.textInput && this.textInput.focus(), 1)
    }
  }

  onBlur = e => {
    e.persist()
    this.props.onBlur(e)
  }

  onChange = e => {
    e.persist()
    const inputValue = e.target.value
    this.setState(
      {
        inputValue
      },
      () => console.log(this.state, this.props),
      this.props.onChange(e)
    )
  }

  onDelete = (chip, index) => {
    this.props.deleteRow(index)
  }

  onFocus = e => {
    e.persist()
    if (this.textInput) this.textInput.select()
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  inputRef = c => {
    this.textInput = c
    if (this.props.inputRef) {
      this.props.inputRef(c)
    }
  }

  render() {
    const {
      autoFocus,
      inputRef,
      label,
      placeholder,
      id,
      disabled,
      onChange,
      onDoubleClick,
      onFocus,
      onKeyDown,
      onBlur
    } = this.props
    console.log('rendered input', this.props, this.state)
    return (
      <ChipInput
        InputProps={{
          'data-lpignore': true,
          autocomplete: 'off'
        }}
        autoFocus={autoFocus}
        blurBehavior="ignore"
        clearInputValueOnChange
        disabled={disabled}
        fullWidth={this.props.fullWidth}
        fullWidthInput
        id={id}
        inputRef={this.inputRef}
        label={label}
        inputValue={this.state.inputValue}
        // newChipKeyCodes={[9]}
        // onAdd={this.onAdd}
        // onBeforeAdd={this.onBeforeAdd}
        onBlur={this.onBlur}
        onDelete={this.onDelete}
        // onUpdateInput={onChange}
        onUpdateInput={this.onChange}
        // onDoubleClick={onDoubleClick}
        onFocus={this.onFocus}
        placeholder={placeholder}
        value={this.state.chips}
        // value={this.props.value}
        variant={this.props.variant}
        onKeyDown={this.props.onKeyDown}
        className="send-document-chip-wrapper"
      />
    )
  }
}
export default shouldUpdate({ whitelist })(TextInput)
