import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const SEARCH = '@@SALES_ORDER_SEARCH/'
export const POPULATE_FIELD = `${SEARCH}POPULATE_FIELD`
export const BLUR = `${SEARCH}BLUR`
export const FOCUS = `${SEARCH}FOCUS`
export const CLOSE_SEARCH = `${SEARCH}CLOSE_SEARCH`
export const EXACT_MATCH_SEARCH = createAsyncRequestTypes(
  `${SEARCH}EXACT_MATCH_SEARCH`
)
export const EXECUTE_INQUIRY = createAsyncRequestTypes(
  `${SEARCH}EXECUTE_INQUIRY`
)
export const TOGGLE_FILTER = `${SEARCH}TOGGLE_FILTER`
export const GET_TOOLTIP = createAsyncRequestTypes(`${SEARCH}GET_TOOLTIP`)

export const CLEAR_FILTER_FIELD = `${SEARCH}CLEAR_FILTER_FIELD`
export const SET_TEXT_FILTER_FIELD = `${SEARCH}SET_TEXT_FILTER_FIELD`
export const RESET_FILTERS = `${SEARCH}RESET_FILTERS`
export const CLEAR_SEARCH = `${SEARCH}CLEAR_SEARCH`
export const FIND = createAsyncRequestTypes(`${SEARCH}FIND`)
