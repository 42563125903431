import React, { useState, useEffect, createRef } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { show } from 'snackbar/actions'
import { withContext } from 'ddiForm'
import { removeModal } from 'modals/actions'

import { changeEmail } from 'pages/SignUp/actions'

const paragraphStyle = {
  color: '#517b9c',
  textAlign: 'center',
  fontSize: '1.1em',
  margin: 0
}

const paperStyle = {
  border: '1px solid #517b9c',
  background: '#f2f2f2',
  padding: 10,
  marginBottom: 15
}

const mapState = state => ({
  account: state.get('account'),
  hasSystemUser: state.get('auth').get('hasSystemUser'),
  statusText: state.get('auth').get('statusText'),
  fetching: state.get('main').isFetching
})
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%'
  },
  card: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  cardMarginTop: {
    width: '100%',
    marginTop: 20
  }
}))

export function ChangeEmailModal(props) {
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const [saveDisabled, setSaveDisabled] = useState(false)
  const { dispatch } = props
  const currentEmailField = createRef()
  const emailField = createRef()

  const onKeyUp = event => {
    if (event.key === 'Enter') {
      // this.resetPasswordRequest()
    }
  }
  console.log(props)

  const handleClose = () => {
    setOpen(false)
    // const f = await resetPasswordRequest
    // console.log(currentEmailField)
    props.dispatch(removeModal(null, props.id))
  }

  const changeEmailRequest = async () => {
    const inputs = document.updateEmail.getElementsByTagName('input')
    const emptyInputs = []
    for (let i = 0; i < inputs.length; i++) {
      if (!inputs[i].value) {
        emptyInputs.push(inputs[i].getAttribute('id'))
      }
    }

    if (emptyInputs.length) {
      document.getElementById(emptyInputs[0]).focus()
    } else {
      try {
        await dispatch(
          changeEmail.request({
            oldEmail: currentEmailField.current.value,
            newEmail: emailField.current.value
          })
        )

        handleClose()
      } catch (e) {
        debugger
      }
    }
  }

  let { account } = props
  account = account?.toJS ? account.toJS() : {}

  const animationStyles = account.isSuccess
    ? { animationDuration: '2s', animationName: 'fadeIn' }
    : {}

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">Change Email</DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <div style={{ margin: '25px auto', minHeight: 280 }}>
              <div style={{ maxWidth: '100%', minWidth: 260, width: '100%' }}>
                <Paper style={paperStyle}>
                  <p style={paragraphStyle}>Update your email address.</p>
                </Paper>
                {!account.isSuccess ? (
                  <form
                    className="form-transition"
                    style={animationStyles}
                    name="updateEmail"
                  >
                    <div className="input-wrapper">
                      <TextField
                        label="Current Email"
                        id="current-email"
                        inputRef={currentEmailField}
                        fullWidth
                        style={{ fontSize: 14 }}
                        type="email"
                      />
                    </div>
                    <div id="password-field-wrapper" className="input-wrapper">
                      <TextField
                        label="New Email"
                        onKeyUp={onKeyUp}
                        type="email"
                        id="newEmail"
                        inputRef={emailField}
                        fullWidth
                        style={{ fontSize: 14 }}
                      />
                    </div>
                    {account.statusText && (
                      <div className="alert alert-info">
                        {account.statusText}
                      </div>
                    )}
                  </form>
                ) : (
                  <p
                    className="form-transition"
                    style={{
                      animationDuration: '1s',
                      animationName: 'fadeIn',
                      textAlign: 'center'
                    }}
                  >
                    Form submitted!
                  </p>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            // disabled={isAuthenticating}
            disabled={props.fetching}
            // disabled={account.submitting || this.state.requesting}
            color="primary"
            onClick={changeEmailRequest}
            // onClick={handleClose}
          >
            <Icon
              style={{
                width: '20px',
                color: '#fff',
                marginRight: 10
              }}
            >
              email
            </Icon>
            Update Email
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default connect(mapState)(ChangeEmailModal)
