import { select, call, put } from 'redux-saga/effects'
import { hideModal, removeModal } from 'modals/actions'

/**
 * destroys a modal based on the modal ID
 * @param {string} id - ID of the modal to destroy
 * @returns {function} a generator function that hides and removes the modal
 */
export default function* destroyModal(id, form, override) {
  function* helper(i) {
    yield put(hideModal(form, i))
    // yield call(delay, 0)
    if (i) {
      yield put(removeModal(form, i))
    }
  }
  // debugger
  if (id) {
    yield call(helper, id)
  } else if (override) {
    const modals = yield select(state => state.get('modals').toJS())
    yield helper(modals[modals.length - 1].id)
  } else {
    const modals = yield select(state => state.get('modals').toJS())
    for (let i = 0, len = modals.length; i < len; i++) {
      yield helper(modals[i].id)
    }
  }
}
