import callFetch from 'access/callFetch'
import { createApiOptions } from 'utils'
import { BASE_INFORM_API_URL } from '../constants'

export const newsFeed = () => {
  // eslint-disable-line import/prefer-default-export
  const options = createApiOptions({
    method: 'get'
  })
  return callFetch(`${BASE_INFORM_API_URL}/main/company/news`, options)
}

export const toggleFastClick = ({ isSelected, menuId }) => {
  const options = createApiOptions({
    body: {
      isSelected,
      menuId
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/main/fastclick/update`, options)
}

export const resetFastClick = () => {
  const options = createApiOptions({
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/main/fastclick/reset`, options)
}
