import React from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { Button, Icon } from '@material-ui/core'
import { informBlue } from 'theme/colors'
import { downloadAttachment as downloadAttachmentAction } from '../actions'

const DownloadAttachmentCell = ({
  form,
  value,
  isSendDocumentEditor = false,
  isStandaloneComponent = false,
  additionalAttachmentsData = null,
  targetType = '',
  ...rest
}) => {
  console.log('DownloadAttachmentCell', rest)
  const dispatch = useDispatch()

  const downloadAttachment = debounce(e => {
    if ((form && value) || (isSendDocumentEditor && value)) {
      const formName = isSendDocumentEditor ? 'sendDocument' : form
      const params =
        isSendDocumentEditor || isStandaloneComponent
          ? {
              fileName: value,
              additionalAttachmentsData,
              targetType
            }
          : {
              fileName: value
            }

      dispatch(downloadAttachmentAction.try(formName, params))
    }
  }, 300)

  return (
    <Button
      size="small"
      style={{
        color: informBlue,
        textTransform: 'none'
      }}
      onClick={downloadAttachment}
      startIcon={
        <Icon size="small" style={{ color: informBlue }}>
          attachment
        </Icon>
      }
    >
      {value}
    </Button>
  )
}

export default DownloadAttachmentCell
