import { createAction, createAsyncAction } from 'utils'
import * as CONSTANTS from './constants'

export const exactMatchSearch = (form, args) => ({
  meta: {
    form,
    thunk: true,
    apiRequest: true
  },
  payload: args,
  type: CONSTANTS.EXACT_MATCH_SEARCH.REQUEST
})

// export const toggleFilter = (form, { propertyName, subProperty }) =>
//   createAction(CONSTANTS.TOGGLE_FILTER, { propertyName, subProperty }, form)

export const blur = (form, { propertyName, subProperty, value, isSet }) =>
  createAction(
    CONSTANTS.BLUR,
    {
      propertyName,
      subProperty,
      value,
      isSet
    },
    form
  )
export const focus = (form, { propertyName, subProperty }) =>
  createAction(CONSTANTS.FOCUS, { propertyName, subProperty }, form)
export const populateField = (
  form,
  { propertyName, description, indexSearchType, value }
) =>
  createAction(
    CONSTANTS.POPULATE_FIELD,
    {
      propertyName,
      description,
      indexSearchType,
      value
    },
    form
  )
export const getTooltip = (form, args) => {
  const action = createAction(
    CONSTANTS.GET_TOOLTIP.REQUEST,
    args,
    form,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}

// export const resetFilters = (form, { propertyName }) =>
// //   createAction(CONSTANTS.RESET_FILTERS, { propertyName }, form)

// export const clearSearch = (form, { propertyName }) =>
//   createAction(CONSTANTS.CLEAR_SEARCH, { propertyName }, form)
