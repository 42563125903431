// import { DEFAULT_CURRENCY_FORMAT } from './'
import toNumeral from './toNumeral'
/**
 * Apply number formatting to given number value. If given value cannot be
 * converted to a number, returns null.
 * @param {string} value - amount to format
 * @param {string} format - specified number format
 * @returns {string} correctly formatted number string
 */
export const DEFAULT_CURRENCY_FORMAT = '$0,0.00'

export default function formatNumber(value, format = DEFAULT_CURRENCY_FORMAT) {
  const n = toNumeral(value)
  return n ? n.format(format) : null
}
