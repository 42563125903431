import { createAsyncRequestTypes } from 'utils'
// import { BASE_INFORM_API_URL } from 'services/constants'

const CUSTOMER_SHIP_TO = '@@CUSTOMER_SHIP_TO@@/'

export const customerShipTo = 'customerShipTo'

/* list audits: moved to common component */
// export const LIST_AUDITS = createAsyncRequestTypes(
//   `${CUSTOMER_SHIP_TO}LIST_AUDITS`
// )

export const SET_PARENT_ID = `${CUSTOMER_SHIP_TO}SET_PARENT_ID`
export const OPEN_GOOGLE_MAPS = `${CUSTOMER_SHIP_TO}OPEN_GOOGLE_MAPS`
export const UPDATE_UOM_DATA = `${CUSTOMER_SHIP_TO}UPDATE_UOM_DATA`

export const CANCEL_EDIT = createAsyncRequestTypes(
  `${CUSTOMER_SHIP_TO}CANCEL_EDIT`
)

/* this can be removed */
// export const GENERATE_SHIP_TO_ID = createAsyncRequestTypes(
//   `${CUSTOMER_SHIP_TO}GENERATE_SHIP_TO_ID`
// )

export const CREATE_SHIP_TO = createAsyncRequestTypes(
  `${CUSTOMER_SHIP_TO}CREATE_SHIP_TO`
)

export const SAVE_SHIP_TO = createAsyncRequestTypes(
  `${CUSTOMER_SHIP_TO}SAVE_SHIP_TO`
)

export const GET_PRODUCT_UOM = createAsyncRequestTypes(
  `${CUSTOMER_SHIP_TO}GET_PRODUCT_UOM`
)

export const DELETE_SHIP_TO = createAsyncRequestTypes(
  `${CUSTOMER_SHIP_TO}DELETE_SHIP_TO`
)

export const SAVE_TEMP_ID = `${CUSTOMER_SHIP_TO}SAVE_TEMP_ID`
export const SAVE_MODAL_ID = `${CUSTOMER_SHIP_TO}SAVE_MODAL_ID`

export const SAVE_INDEX_SEARCH_DESCRIPTION = `${CUSTOMER_SHIP_TO}SAVE_INDEX_SEARCH_DESCRIPTION`
