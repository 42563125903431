import { dateFilterComparator } from 'utils'

export const postProcessPopup = params => {
  const ePopupParent = params?.ePopup?.parentNode
  if (params?.type && params.type === 'ag-list' && ePopupParent) {
    ePopupParent.style.zIndex = 9999
    ePopupParent.style.position = 'absolute'
    ePopupParent.style.top = 0
    ePopupParent.style.left = 0
    ePopupParent.classList.add('ag-popup-show-dropdown')
  }
}

export const createFooterTotals = (
  gridApi,
  buildFooterFn,
  isMounted,
  isMobile = false,
  filterBy = null
) => {
  if (gridApi && isMounted) {
    const displayedRows =
      gridApi?.getModel()?.rowsToDisplay?.reduce((acc, next) => {
        if (next?.data) {
          acc = acc.concat(next.data)
        }
        return acc
      }, []) || null

    if (
      displayedRows &&
      Array.isArray(displayedRows) &&
      buildFooterFn &&
      typeof buildFooterFn === 'function'
    ) {
      setTimeout(() => {
        try {
          if (filterBy) {
            gridApi.setPinnedBottomRowData(
              buildFooterFn(displayedRows, filterBy)
            )
          } else {
            gridApi.setPinnedBottomRowData(buildFooterFn(displayedRows))
          }
          if (!isMobile) {
            gridApi.sizeColumnsToFit()
          }
        } catch (e) {
          console.log(e)
        }
      }, 0)
    }
  }
}

export const dateFilterGridConfigs = {
  filter: 'agDateColumnFilter',
  filterParams: {
    clearButton: true,
    comparator: dateFilterComparator,
    inRangeInclusive: true,
    suppressAndOrCondition: true,
    browserDatePicker: true
  }
}

export const numberFilterGridConfigs = {
  filter: 'agNumberColumnFilter',
  filterParams: {
    clearButton: true,
    inRangeInclusive: true,
    suppressAndOrCondition: true
  }
}
