/* eslint-disable max-classes-per-file */
import React, { PureComponent, createRef, createElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import shouldUpdate from 'hoc/shouldUpdate'
import { bindActionCreators } from 'redux'
import { isValidElementType } from 'react-is'
// import invariant from 'invariant'
import { mapValues } from 'lodash'
import hoistStatics from 'hoist-non-react-statics'
import invariant from 'invariant'
import { CircularProgress } from '@material-ui/core'
import {
  deepEqual,
  empty,
  emptyList,
  getIn,
  noop,
  getDisplayName,
  isReactComponent,
  requireAuthentication
} from 'utils'
// import { linkToScreen } from 'pages/Main/actions'
import { MuiModal } from 'modals/MuiModal' //
import MasterScreen from './MasterScreen' // TEMP
import * as formActions from './actions'
import { cancelEditAsync, lockForEditAsync } from './MasterScreen/actions'
import { createTitle } from './utils'
import { withDDIForm, DDIFormContext } from './DDIFormContext'

// const propsToNotUpdateFor = [
//   ...Object.keys(formActions),
//   'initialized',
//   'values',
//   'fields',
//   'registeredFields',
//   'masterOptions',
//   'editedFields',
//   'modals'
//   // 'title'
// ]
const styles = {
  loading: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    height: '100%'
  },
  content: {
    display: 'flex',
    flex: 1,
    maxWidth: '100%'
  }
}
/* eslint-disable react/no-multi-comp, react/prefer-stateless-function */
const ddiForm = options => {
  const config = {
    getFormState: state => getIn(state, 'ddiForm'),
    ...options
  }

  return WrappedComponent => {
    invariant(
      WrappedComponent || (!WrappedComponent && options.masterOptions),
      'If not using WrappedComponent, you must pass masterOptions. ie: Master Screen'
    )
    WrappedComponent = WrappedComponent || MasterScreen
    // throw if !Wrapped && !options.master
    class Form extends PureComponent {
      static propTypes = {
        actions: PropTypes.object,
        behaviors: PropTypes.object,
        destroy: PropTypes.func, // eslint-disable-line
        disableMeta: PropTypes.bool,
        form: PropTypes.string.isRequired,
        getFormState: PropTypes.func.isRequired,
        // initialValues: PropTypes.object,
        initialize: PropTypes.func,
        initialized: PropTypes.bool,
        registerField: PropTypes.func.isRequired,
        retainData: PropTypes.bool,
        sagas: PropTypes.object,
        title: PropTypes.string
      }

      static defaultProps = {
        actions: {},
        behaviors: {},
        destroy: noop,
        disableMeta: false,
        initializeOnMount: false,
        initialize: noop,
        initialized: false,
        retainData: false,
        sagas: {}
      }

      static displayName = `Form(${getDisplayName(WrappedComponent)})`

      static WrappedComponent = WrappedComponent

      constructor(...args) {
        super(...args)
        console.log(this.props)
        this.state = { initialized: false }
      }

      // remove

      componentDidMount() {
        if (this.props.initializeOnMount) {
          try {
            // await
            // debugger
            this.props.initialize({
              apiAlias: this.props.apiAlias,
              // values: this.props.initialValues,
              sagas: this.props.sagas,
              behaviors: this.props.behaviors,
              actions: this.props.actions,
              title: this.props.title,
              disableMeta: this.props.disableMeta,
              subform:
                this.props.asModal && this.props.form ? this.props.form : null,
              meta: this.props.meta,
              initialState: this.props.initialState,
              // dashboard: this.props.dashboard,
              masterOptions: this.props.masterOptions,
              mobile: this.props.mobile,
              metaKey: this.props.metaKey,
              noCancelEditProcess: this.props.noCancelEditProcess,
              transformDataBeforeSave: this.props.transformDataBeforeSave,
              noAPIForCancelConfirm: this.props.noAPIForCancelConfirm
            })
            // debugger
            // console.log(p)
            this.setInitialized()
          } catch (e) {
            // redirect to home.
            console.log(e)
          }
        } else {
          this.setInitialized()
        }

        setTimeout(() => {
          if (this.props?.glContainer?.tab?.setTitle) {
            const cfg =
              this.props.glContainer.tab.contentItem &&
              this.props.glContainer.tab.contentItem.config
            let image
            if (cfg) {
              image = cfg.image
            }

            this.props.glContainer.tab.setTitle(
              createTitle(this.props.title, image)
            )
          }
        }, 1)
      }

      componentDidUpdate(prevProps) {
        // Object.keys(this.props).forEach(prop => {
        //   if (!deepEqual(this.props[prop], prevProps[prop])) {
        //     console.info(
        //       'DDIFORMWR',
        //       prop,
        //       'changed',
        //       prevProps[prop],
        //       '==>',
        //       this.props[prop]
        //     )
        //   }
        // })
        if (
          prevProps.title !== this.props.title &&
          this.props.glContainer &&
          this.props.glContainer.tab &&
          this.props.glContainer.tab.setTitle
        ) {
          const cfg =
            this.props.glContainer.tab.contentItem &&
            this.props.glContainer.tab.contentItem.config
          let image
          if (cfg) {
            image = cfg.image
          }
          // debugger
          this.props.glContainer.tab.setTitle(
            createTitle(this.props.title, image)
          )
        }
      }

      componentWillUnmount() {
        this.props.beforeDestroy({
          name: this.props.childOf
            ? `${this.props.childOf}.${this.props.form}`
            : this.props.form,
          retainData: this.props.retainData
        })
      }

      setInitialized = () => this.setState({ initialized: true })

      wrapped = createRef()

      render() {
        // more work needs to be done here..
        // console.log('ddiForm rendered', this.props.form)
        // is class component addref..
        const { getFormState, ...rest } = this.props
        const propsToPass = { ...rest }
        if (isValidElementType(WrappedComponent)) {
          propsToPass.ref = this.wrapped
        }
        const wrappedGetFormState = state =>
          getIn(getFormState(state), this.props.form)
        const _ddiForm = {
          ...this.props,
          getFormState: wrappedGetFormState
          // other stuff, instance methods if needed etc,.
        }
        // console.log(_ddiForm, propsToPass)

        return this.state.initialized ? (
          <DDIFormContext.Provider value={_ddiForm}>
            <div style={styles.container}>
              <div style={styles.content}>
                <WrappedComponent
                  {...propsToPass}
                  getFormState={wrappedGetFormState}
                />
              </div>
              <MuiModal {...this.props} getFormState={wrappedGetFormState} />
            </div>
          </DDIFormContext.Provider>
        ) : (
          <div style={styles.loading}>
            <CircularProgress size={60} thickness={7} color="primary" />
          </div>
        )
      }
      /* */
    }

    const connector = connect(
      (state, props) => {
        // const branch = getIn(state, 'auth.branch') // state.auth.branch
        let { form, getFormState, subform } = props

        if (subform) {
          form = `${props.childOf}.${form}`
        }
        let additionalProps = {}
        if (props.data) {
          if (typeof props.data === 'object') {
            additionalProps = Object.keys(props.data).reduce((acc, next) => {
              const d = props.data[next]
              acc[next] = typeof d === 'function' ? d(state) : d
              return acc
            }, {})
          } else if (typeof props.data === 'function') {
            additionalProps = props.data(state)
          }
        }
        const formState = getIn(getFormState(state) || empty, form) || empty
        const stateInitial = getIn(formState, 'initial')
        const values = getIn(formState, 'values')
          ? getIn(formState, 'values')
          : empty

        let guId
        const title = getIn(formState, 'title') || props.title
        guId = getIn(formState, 'guId')

        if (!guId) {
          guId = values.guId || props.guId
        }
        let userName

        userName = getIn(formState, 'userName')
        if (!userName) {
          userName = values.userName || props.userName
        }

        let modals = getIn(formState, 'modals')
        modals = modals || emptyList
        let meta
        // debugger
        if (props.metaKey) {
          meta = getIn(state, props.metaKey)
          // console.log(meta, meta.toJS())
        } else {
          meta = getIn(formState, 'meta')
        }

        meta = meta?.toJS ? meta : empty

        // const {
        //   destroyed = false,
        //   fields,
        //   dataId,
        //   lastEntityId = null
        // } = formState.toJS()
        const destroyed = formState.get('destroyed') || false
        const fields = formState.get('fields')
        const dataId = formState.get('dataId')
        const lastEntityId = formState.get('lastEntityId') || null

        const ret = {
          //     branch,
          formState,
          dataId,
          destroyed,
          fields,
          initialized: !!stateInitial,
          lastEntityId,
          meta,
          values,
          modals,
          guId,
          userName,
          title,
          ...additionalProps
        }
        return ret
      },
      (dispatch, initialProps) => {
        const { actions = {} } = initialProps

        let { form } = initialProps
        if (initialProps.subform) {
          form = `${initialProps.childOf}.${form}`
        }

        const bindForm = actionCreator => {
          if (!actionCreator) {
            return noop
          }
          return actionCreator.bind(null, form)
        }
        const boundFormActions = mapValues(
          formActions,
          // { ...formActions /* linkToScreen */ },
          bindForm
        )
        const boundCustomActions = mapValues(actions, bindForm)
        const connectedFormActions = bindActionCreators(
          boundFormActions,
          dispatch
        )
        const connectedCustomActions = bindActionCreators(
          boundCustomActions,
          dispatch
        )
        // masterOptions?
        let masterOptions = {}
        // PUT THIS BACK
        if (initialProps.masterOptions) {
          masterOptions = {
            lockForEdit: () => dispatch(lockForEditAsync.try(form)),
            cancelEdit: () => dispatch(cancelEditAsync.try(form))
          }
        }
        const computedActions = {
          ...connectedFormActions,
          ...connectedCustomActions,
          ...masterOptions,
          dispatch
        }
        return () => computedActions
      },
      undefined,
      { forwardRef: true }
    )
    const whitelist = ['title', 'modals', 'hasRecord', 'isEditing']
    if (options.withFields) {
      whitelist.push('fields')
    }
    if (options.withMeta) {
      whitelist.push('meta')
    }
    const ConnectedForm = hoistStatics(
      connector(
        Form
        // shouldUpdate({
        //   whitelist /* propsToNotUpdateFor */
        // })(Form)
      ),
      WrappedComponent
    )
    ConnectedForm.defaultProps = config

    class DDIForm extends PureComponent {
      static ddiForm = true

      static options = options

      get wrappedInstance() {
        // for testing
        return this.ref.current && this.ref.current.wrapped.current
      }

      ref = createRef()

      render() {
        return <ConnectedForm {...this.props} ref={this.ref} />
      }
    }
    let WithContext = hoistStatics(withDDIForm(DDIForm), WrappedComponent)
    WithContext.defaultProps = config
    WithContext.ddiForm = true
    WithContext.options = options
    if (options.mobile) {
      WithContext = withRouter(requireAuthentication(WithContext))
    }
    return WithContext
  }
}
export default ddiForm

// this.state.initialized ? (
//   <div style={styles.container}>
//     {/*<MuiModal {...this.props} />*/}
//     <div style={styles.content}>
//       <WrappedComponent {...this.props} />
//     </div>
//   </div>
// ) : (
//   <div style={styles.loading}>
//     <CircularProgress size={60} thickness={7} color="primary" />
//   </div>
// )
