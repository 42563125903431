const isValidJSON = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export default function fetchProcessText(route, options) {
  return fetch(route, options)
    .then(response => response.text().then(text => ({ text, response })))
    .then(({ text, response }) => {
      if (!response.ok) {
        text.status = response.status
        return Promise.reject(text)
      }
      return text
    })
    .then(
      text => ({ response: isValidJSON(text) ? JSON.parse(text) : text }),
      error => {
        const err = {
          error: {
            ...error,
            message: error.error_description || error.message
          }
        }
        return err
      }
    )
}
