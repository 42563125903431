import React, { Component } from 'react'
import PropTypes from 'prop-types'
import shouldUpdate from 'hoc/shouldUpdate'
// import invariant from 'invariant'
import Grid from 'grid'
import { connect } from 'react-redux'
import { getIn, deepEqual } from 'utils'
import { addBlankRow } from './actions'
import { withDDIForm } from './DDIFormContext'

const mapStateToProps = (state, ownProps) => {
  const { propertyName, dispatch, getFormState, form } = ownProps
  const formState = getFormState(state)
  const field = getIn(formState, `fields.${propertyName}`)
  const hasRecord = getIn(state, `ddiForm.${form}.hasRecord`)
  const isEditing = getIn(state, `ddiForm.${form}.isEditing`)
  const ret = field ? { ...ownProps, ...field.toJS() } : { ...ownProps }
  if (ownProps.columnDefs) {
    ret.columnDefs = ownProps.columnDefs
  }
  ret.hasRecord = hasRecord
  ret.isEditing = isEditing
  // if (ret.value && !ret.rowData) {
  //   ret.rowData = ret.value
  //   // ret.value = undefined
  //   delete ret.value
  // }
  if (!ownProps.addBlankRow && dispatch) {
    ret.addBlankRow = (e, args) =>
      dispatch(addBlankRow(form, { propertyName, ...args }))
  }
  // ret.addBlankRow
  return ret
}

const ConnectedGrid = connect(
  mapStateToProps,
  undefined,
  undefined,
  { forwardRef: true }
)(Grid)

class GridField extends Component {
  componentDidMount() {
    this.props.registerField({
      propertyName: this.props.propertyName,
      searchType: null,
      type: 'grid',
      rowData: this.props.rowData,
      columnDefs: this.props.columnDefs,
      meta: this.props.meta,
      emptyRow: this.props.emptyRow
      // blankRow
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if (!deepEqual(nextProps, this.pro))
    return !deepEqual(nextProps, this.props)
  }

  connectedGrid = React.createRef()

  render() {
    return (
      <ConnectedGrid
        {...this.props}
        // _ddiForm={this.props._ddiForm}
        ref={this.connectedGrid}
        // ref={c => (this.connectedGrid = c)}
      />
    )
  }
}
export default withDDIForm(GridField)
