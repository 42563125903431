import { take, fork, call, select, cancel, put } from 'redux-saga/effects'
import React from 'react'
import { addModal } from 'modals/actions'
import { getIn } from 'utils'

import EventModal from '.'
import TitleBar from './components/TitleBar'

import {
  addNewActivity,
  clearForm,
  closeActivityModal,
  deleteActivity,
  saveActivityAsync,
  unlockActivityAsync
} from './actions'
import { isFormValid } from './utils'

/*
  eventType = T
  taskType = SO
*/

const getType = (eventType, taskType, eventTypeOptions, taskTypeOptions) => {
  let type = ''
  if (taskType) {
    type = taskTypeOptions.find(x => x.dataId === taskType)?.description || ''
    return type
  }

  if (eventType) {
    type =
      eventTypeOptions.find(x => x.dataId === eventType)?.description || 'E'
    return type
  }

  return type
}

export default function* addOrEditActivityTask(response) {
  const { callLocation, dataId, form } = response
  const canRemoveMe = response?.activity?.canRemoveMe || false
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  /* subject & type are for confirmation modals required in various workflows */
  const subject =
    response.activity && response.activity.subject
      ? response.activity.subject
      : ''

  const type =
    response.activity && response.meta
      ? getType(
          response.activity.eventType,
          response.activity.taskType,
          response.meta.eventTypeOptions,
          response.meta.taskTypeOptions
        )
      : ''

  // debugger
  const actions = dataId
    ? [
        {
          primary: true,
          title: 'New',
          async clickEvent(args, formState, cb) {
            try {
              await this.props.dispatch(
                unlockActivityAsync(callLocation, { dataId })
              )
            } finally {
              if (cb && typeof cb === 'function') {
                cb()
              }

              if (this?.props?.dispatch) {
                this.props.dispatch(
                  addNewActivity(callLocation, {
                    selectedStartDate: new Date()
                  })
                )
              }
            }
          }
        },
        {
          primary: true,
          title: 'Save',
          async clickEvent(args, formState, cb) {
            try {
              await this.props.dispatch(saveActivityAsync(callLocation))

              if (cb && typeof cb === 'function') {
                cb()
              }
            } catch (err) {
              if (this?.props && isFormValid(this?.props)) {
                console.log(err)
                // cb()
              }
            }
          }
        },
        {
          primary: true,
          title: 'Delete',
          id: 'add-event-modal-delete-button',
          async clickEvent(args, formState, cb) {
            try {
              await this.props.dispatch(
                deleteActivity(callLocation, {
                  dataId,
                  subject,
                  type,
                  cb
                })
              )
            } catch (err) {
              console.log(err)
            }
          }
        },
        {
          primary: true,
          title: 'Exit',
          async clickEvent(args, formState, cb) {
            try {
              this.props.dispatch(unlockActivityAsync(callLocation, { dataId }))

              await this.props.dispatch(closeActivityModal(callLocation))

              if (cb && typeof cb === 'function') {
                cb()
              }
            } catch (err) {
              console.log(err)
              // cb()
            }
          }
        }
      ]
    : [
        {
          primary: true,
          title: 'New',
          clickEvent(args, formState, cb) {
            this.props.dispatch(clearForm)
          }
        },
        {
          primary: true,
          title: 'Save',
          async clickEvent(args, formState, cb) {
            try {
              await this.props.dispatch(saveActivityAsync(callLocation))
              cb()
            } catch (err) {
              if (this?.props && isFormValid(this?.props)) {
                console.log(err)
                // cb()
              }
            }
          }
        },
        {
          primary: true,
          title: 'Exit',
          async clickEvent(args, formState, cb) {
            /* need to notify that the modal is closed */
            this.props.dispatch(closeActivityModal(callLocation))

            if (cb && typeof cb === 'function') {
              cb()
            }
          }
        }
      ]

  if (canRemoveMe && !isMobile) {
    actions.unshift({
      primary: true,
      title: 'Remove Me',
      id: 'add-event-modal-remove-me-button',
      async clickEvent(args, formState, cb) {
        try {
          await this.props.dispatch(
            deleteActivity(callLocation, {
              dataId,
              removeMe: true,
              subject,
              type,
              cb
            })
          )
        } catch (err) {
          console.log(err)
        }
      },
      buttonStyle: { marginRight: 'auto' }
    })
  }

  const modalStyleOptions = isMobile
    ? {
        fullScreen: true,
        marginTop: '0px',
        maxHeight: '95%',
        width: '95%',
        maxWidth: '95%'
      }
    : {
        marginTop: 25,
        maxHeight: '100%',
        width: 650
      }

  const options = {
    component: EventModal,
    options: {
      data: {
        actions: isMobile ? actions.filter((x, i) => i !== 0) : actions,
        responseData: {
          ...response,
          mobileRemoveUserData: {
            callLocation,
            subject,
            type,
            dataId
          }
          // titleInfo: activeActivityDataSet
        }
      },
      title: TitleBar,
      actionBarStyle:
        canRemoveMe && !isMobile ? { justifyContent: 'space-between' } : null,
      ...modalStyleOptions
    }
  }

  const modal = yield call(addModal, options)
  yield put(modal)
  const modalId = modal.payload.id
  return modalId
}
