import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { smallTextFieldStyles } from 'ddiForm/ddiFormStyles'
import { noop } from 'utils'

export default class TabTextField extends Component {
  static defaultProps = {
    disabled: false,
    hintStyle: {
      bottom: 0,
      fontSize: 12,
      width: '100%'
    },
    onTab: noop,
    textFieldStyle: { ...smallTextFieldStyles.textFieldStyle },
    underlineStyle: { bottom: 0 }
  }

  static propTypes = {
    disabled: PropTypes.bool,
    hintStyle: PropTypes.object,
    onTab: PropTypes.func,
    propertyName: PropTypes.string.isRequired,
    textFieldStyle: PropTypes.object,
    underlineStyle: PropTypes.object
  }

  static ddiType = 'TabTextField'

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {}
    if (
      !prevState.isDirty &&
      nextProps.value !== prevState.value &&
      nextProps.value !== undefined
    ) {
      state.value = nextProps.value
    } else if (prevState.isDirty && prevState.value === '') {
      state.value = ''
      state.isDirty = false
    }
    const len = Object.keys(state).length
    state = len >= 1 ? state : null

    return state
  }

  constructor(props) {
    super(props)
    this.state = { value: props.initialValue || props.value || '' }
  }

  onBlur = async event => {
    try {
      const f = await this.props.onTab(event.target.value)
      if (f && f.description) {
        this.setState({ value: f.description, isDirty: false })
      }
    } catch (ex) {
      this.setState({ value: '', isDirty: false })
    }
  }

  onFocus = () => this.textInput && this.textInput.select()

  bindInputRef = c => (this.textInput = c)

  handleChange = event =>
    this.setState({ value: event.target.value, isDirty: true })

  render() {
    const { value } = this.state
    const { disabled } = this.props
    // console.log('TabTextField', this, this.props)
    return (
      <div>
        <TextField
          disabled={disabled}
          fullWidth
          onKeyDown={this.onKeyDown}
          value={value}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          inputRef={this.bindInputRef}
          label={this.props.label}
          placeholder={this.props.placeholder}
        />
      </div>
    )
  }
}
