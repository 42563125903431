// api/dashboard/open
import callFetch from 'access/callFetch'
import { createApiOptions } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const addDashboardItem = ({
  dataId,
  filter = '',
  filterIds = [],
  isMobile = false,
  password,
  pin,
  username
}) => {
  const options = createApiOptions({
    body: {
      credentials: {
        password,
        pin,
        username
      },
      dataId,
      filter,
      filterIds,
      isMobile
    },
    method: 'post'
  })
  // if (pin || username || password)

  return callFetch(`${BASE_INFORM_API_URL}/dashboard/add`, options)
}

export const removeDashboardItem = ({ dataId, ...rest }) => {
  const options = createApiOptions({
    body: { dataId, ...rest },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/dashboard/remove`, options)
}

export const showTips = () => {
  const options = createApiOptions({ method: 'post' })
  return callFetch(`${BASE_INFORM_API_URL}/dashboard/showtips`, options)
}

export const hideTips = () => {
  const options = createApiOptions({ method: 'post' })
  return callFetch(`${BASE_INFORM_API_URL}/dashboard/hidetips`, options)
}

export const viewDashboardDetails = ({
  dataId,
  password,
  pin,
  username,
  filter = '',
  filterIds = []
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      filter,
      filterIds,
      credentials: {
        password,
        pin,
        username
      }
    },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/dashboard/detail/open`, options)
}

export const changeDashboardFilter = ({ dataId, filter }) => {
  const options = createApiOptions({
    body: { dataId, filter },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/dashboard/detail/changefilter`,
    options
  )
}

export const searchDashboardData = ({
  cashMode,
  chartMode,
  dataId,
  dateSelection,
  fillRateAnalysis,
  filter,
  filterIds,
  overallRanks,
  period,
  rankMode,
  yearType,
  shipMethod
}) => {
  const options = createApiOptions({
    body: {
      cashMode,
      chartMode,
      dataId,
      dateSelection,
      fillRateAnalysis,
      filter,
      filterIds,
      overallRanks,
      period,
      rankMode,
      yearType,
      shipMethod
    },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/dashboard/detail/search`, options)
}

export const changeInventoryFilter = ({ dataId, filterName, filterData }) => {
  const options = createApiOptions({
    body: {
      dataId,
      [filterName]: filterData
    },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/dashboard/detail/changeinventoryfilter`,
    options
  )
}

export const openDashboardReport = ({ dataId, headerImage }) => {
  const options = createApiOptions({
    body: {
      dataId,
      headerImage
    },
    method: 'post'
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/dashboard/detail/report/open`,
    options
  )
}

export const closeDashboardReport = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/dashboard/detail/report/close`,
    options
  )
}
