import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import TWT from 'components'
import TWT from 'components/ThreeWayToggle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { is } from 'utils'

// const getStateFromValue = value => ({
//   checked: !!value,
//   indeterminate: is.null(value),
//   value
// })


const getStateFromValue = value => ({
  checked: value === 1,
  indeterminate: value === 2, //is.null(value),
  value
})

export default class ThreeWayToggle extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isFound: PropTypes.bool.isRequired,
    propertyName: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let ret = null
    if (nextProps.value !== prevState.value) {
      ret = getStateFromValue(nextProps.value)
    }
    return ret
  }

  constructor(props) {
    super(props)
    this.state = getStateFromValue(props.value)
  }

  handleChange = (_, checked) => {
    let newState
    if (checked && this.state.indeterminate) {
      newState = 0
    } else if (checked && !this.state.indeterminate && !this.state.checked) {
      newState = 1
    } else if (!checked) {
      newState = 2
    }
    this.props.onChange(newState)
  }

  renderToggle = props => {
    const { id, label, disabled } = props
    const control = (
      <TWT
        checked={this.state.checked}
        id={id}
        indeterminate={this.state.indeterminate}
        onChange={this.handleChange}
        disabled={disabled}
        value="controlled"
      />
    )
    return label ? (
      <FormControlLabel control={control} label={label} />
    ) : (
      control
    )
  }
  render() {
    return (
      <span onDoubleClick={this.props.onDoubleClick}>
        {this.renderToggle(this.props)}
      </span>
    )
  }
}
