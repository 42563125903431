/* eslint max-classes-per-file: 0, react/jsx-fragments: 0, jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0, react/sort-comp: 0, prefer-destructuring: 0 */
import React, { Component, Fragment } from 'react'
// import { connect } from 'react-redux'
import { connect } from 'react-redux'
import { openScreen } from 'pages/Main/actions'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { EXACT_MATCH } from 'pages/SalesOrderInquiry/constants'
import { isMobileSelector } from 'mobile/selectors'
import { EXACT_MATCH as PO_EXACT_MATCH } from 'pages/PurchaseOrderInquiry/constants'
import {
  Field,
  NumberSpinner,
  SelectField,
  TextField,
  Toggle,
  withContext
} from 'ddiForm'

import DDIDateTimePicker from 'ddiForm/wrapped/DDIDateTimePicker'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import DDISalesOrderSearch from 'ddiForm/wrapped/DDISalesOrderSearch'
import DDISalesOrderSearchMobile from 'mobile/ddiForm/wrapped/DDISalesOrderSearchMobile'
import DDIPurchaseOrderSearchMobile from 'mobile/ddiForm/wrapped/DDIPurchaseOrderSearchMobile'
import DDIPurchaseOrderSearch from 'ddiForm/wrapped/DDIPurchaseOrderSearch'
import DDIARInvoiceSearch from 'ddiForm/wrapped/DDIARInvoiceSearch'
import DDIIndexSearchMobile from 'mobile/ddiForm/wrapped/ddiIndexSearchMobile'
// import DDITextField from 'ddiForm/wrapped/DDITextField'

import { SearchDropdown } from 'components'

import {
  getIn,
  getField,
  getValue,
  getMeta,
  empty,
  emptyList,
  noop
} from 'utils'
import { required } from 'ddiForm/validations'
import { Button, Icon, IconButton } from '@material-ui/core'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import Typography from '@material-ui/core/Typography'

import { Row, Col } from 'react-bootstrap'
// import getMuiTheme from '@material-ui/core/styles/getMuiTheme'

import moment from 'moment'

import { userColumnDefs } from 'pages/Dashboard/components/Calendar/columnDefs'

import NewEventTypePopover from './components/NewEventTypePopover'

import ActivityCustomFields from './components/ActivityCustomFields'
import ReferenceLabel from './components/ReferenceLabel'
import * as eventModalActions from './actions'

export const getRowNodeId = data => data.dataId

export class EventModal extends Component {
  static ddiType = 'eventModal'

  /* oneOfType declarations are there to suppress console warnings on immutable data */
  /* most of those are in fact resolve to plain JS data arrays */
  static propTypes = {
    addEventType: PropTypes.func.isRequired,
    callLocation: PropTypes.string.isRequired,
    colorOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    eventTypeEditor: PropTypes.object.isRequired,
    eventTypeEditorOpen: PropTypes.bool.isRequired,
    eventTypeOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    fields: PropTypes.object.isRequired,
    referenceData: PropTypes.object.isRequired,
    myId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    participants: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    referenceOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    reminderIntervalOptions: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]).isRequired,
    responseData: PropTypes.object.isRequired,
    saveActivity: PropTypes.func.isRequired,
    source: PropTypes.string,
    taskTypeOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    unlockActivity: PropTypes.func.isRequired,
    userIds: PropTypes.string.isRequired
  }

  static defaultProps = {
    eventTypeEditor: {},
    eventTypeOptions: [],
    open: false,
    participants: [],
    referenceOptions: [],
    reminderIntervalOptions: [],
    source: 'calendar',
    taskTypeOptions: []
  }

  // static childContextTypes = {
  //   muiTheme: PropTypes.object.isRequired,
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    if (nextProps.startDate && nextProps.endDate) {
      if (moment(nextProps.endDate) < moment(nextProps.startDate)) {
        nextState.timeSelectionError = true
      } else {
        nextState.timeSelectionError = false
      }
    }

    if (
      !prevState.initialized &&
      nextProps.data &&
      nextProps.data.responseData &&
      nextProps.data.responseData.meta &&
      nextProps.data.responseData.meta.eventTypeOptions &&
      nextProps.data.responseData.meta.taskTypeOptions &&
      nextProps.data.responseData.meta.referenceOptions &&
      nextProps.data.responseData.meta.reminderIntervalOptions
      // nextProps.data.responseData.participants &&
      // (nextProps.userIds || nextProps.data.responseData.userIds)
    ) {
      nextState.initialized = true
    }
    return Object.keys(nextState).length ? nextState : null
  }

  constructor(...args) {
    super(...args)
    const props = args[0]

    this.state = {
      expanded: true,
      // activeDate: props.activeDate,
      initialized: false,
      isTask: false,
      timeSelectionError: false
    }
  }

  componentDidMount() {
    const {
      data: { responseData }
    } = this.props
    this.props.dispatch(
      eventModalActions.registerResponseData({ data: responseData })
    )
  }

  onPanelExand = panel => (event, expanded) => {
    this.setState({ expanded: expanded ? panel : false })
  }

  requestAddNewEvent = () => {
    this.props.addEventType()
  }

  getFieldValue = (field, value = null, comparator = 'equality') => {
    const { fields: fieldsMap } = this.props
    const fields = fieldsMap?.toJS ? fieldsMap.toJS() : {}
    // debugger
    if (comparator === 'equality') {
      return (
        fields &&
        fields[field] &&
        fields[field].value &&
        fields[field].value === value
      )
    }

    if (comparator === 'exists') {
      return fields && fields[field] && fields[field].value
    }

    return (
      fields &&
      fields[field] &&
      fields[field].value &&
      fields[field].value !== value
    )
  }

  isUserDropdownDisabled = () => {
    if (!this.props.responseData || !this.props.responseData.activity) {
      return false
    }

    if (
      this.props.responseData.activity &&
      this.props.responseData.activity.canChangeLinkedUsers === false
    ) {
      return true
    }

    return false
  }

  onSetField = (...args) => {
    // debugger
    this.props.dispatch({
      type: EXACT_MATCH.SUCCESS,
      meta: { form: 'eventModal' },
      payload: { ...args[3] }
    })
  }

  onPurchaseOrderSetField = (...args) => {
    this.props.dispatch({
      type: PO_EXACT_MATCH.SUCCESS,
      meta: { form: 'eventModal' },
      payload: { ...args[3] }
    })
  }

  getReference = memoize(refType => {
    if (!refType) return null
    // const refType = fields.referenceType.value
    const { fields: fieldsMap, isMobile } = this.props
    const fields = fieldsMap?.toJS ? fieldsMap.toJS() : {}

    if (refType) {
      console.log(refType)
      const refMap = {
        SO: isMobile ? (
          <DDISalesOrderSearchMobile
            propertyName="referenceId"
            type="SalesOrderSearch"
            dataId={fields.referenceId ? fields.referenceId.value : null}
            onSetField={this.onSetField}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
          />
        ) : (
          <DDISalesOrderSearch
            propertyName="referenceId"
            type="SalesOrderSearch"
            dataId={fields.referenceId ? fields.referenceId.value : null}
            onSetField={this.onSetField}
          />
        ),
        PO: isMobile ? (
          <DDIPurchaseOrderSearchMobile
            propertyName="referenceId"
            dataId={fields.referenceId ? fields.referenceId.value : null}
            onSetField={this.onPurchaseOrderSetField}
            customContextMenuItems={this.customPurchaseOrderContextMenuItems}
          />
        ) : (
          <DDIPurchaseOrderSearch
            propertyName="referenceId"
            dataId={fields.referenceId ? fields.referenceId.value : null}
            onSetField={this.onPurchaseOrderSetField}
            customContextMenuItems={this.customPurchaseOrderContextMenuItems}
          />
        ),
        CO: isMobile ? (
          <DDIIndexSearchMobile
            propertyName="referenceId"
            metaKey="CO"
            includeParent
            moreInfo
            disableSearchAll
            disableRefresh
          />
        ) : (
          <DDIIndexSearch
            propertyName="referenceId"
            metaKey="CO"
            includeParent
            moreInfo
            disableSearchAll
            disableRefresh
          />
        ),
        JB: isMobile ? (
          <DDIIndexSearchMobile
            propertyName="referenceId"
            metaKey="JB"
            includeParent
            moreInfo
          />
        ) : (
          <DDIIndexSearch
            propertyName="referenceId"
            metaKey="JB"
            includeParent
            moreInfo
          />
        ),
        C: isMobile ? (
          <DDIIndexSearchMobile
            propertyName="referenceId"
            metaKey="C"
            includeParent
            moreInfo
            disableSearchAll
            disableRefresh
          />
        ) : (
          <DDIIndexSearch
            propertyName="referenceId"
            metaKey="C"
            includeParent
            moreInfo
            disableSearchAll
            disableRefresh
          />
        ),
        CN: isMobile ? (
          <DDIIndexSearchMobile
            propertyName="referenceId"
            metaKey="CN"
            includeParent
            moreInfo
          />
        ) : (
          <DDIIndexSearch
            propertyName="referenceId"
            metaKey="CN"
            includeParent
            moreInfo
          />
        ),
        IN: (
          <DDIARInvoiceSearch
            propertyName="referenceId"
            disableSearchAll
            disableRefresh
          />
        )
      }
      return refMap[refType]
    }
    return null
  })

  getReferenceType = () => {
    // console.log('getReferenceType', this.props)
    const { fields: fieldsMap } = this.props
    const fields = fieldsMap?.toJS ? fieldsMap.toJS() : {}
    if (!fields || !fields.referenceType) {
      return null
    }

    return fields?.referenceType?.value
  }

  customPurchaseOrderContextMenuItems = () => {
    const { fields: fieldsMap } = this.props
    const fields = fieldsMap?.toJS ? fieldsMap.toJS() : {}
    return [
      {
        text: 'Open Purchase Order Entry',
        icon: 'open_in_new',
        iconStyle: {
          color: '#444',
          fontSize: 16,
          verticalAlign: 'middle'
        },
        onClick: () =>
          this.props.dispatch(
            openScreen({
              name: 'PurchaseOrderEntry',
              title: 'Purchase Order Entry',
              dataId: fields.referenceId.value
            })
          ),
        enabled: !!fields.referenceId.value
      }
    ]
  }

  removeUser = e => {
    const {
      data: {
        responseData: { mobileRemoveUserData = {} }
      },
      id
    } = this.props

    if (Object.keys(mobileRemoveUserData).length) {
      this.props.dispatch(
        eventModalActions.deleteActivity(mobileRemoveUserData?.callLocation, {
          dataId: mobileRemoveUserData?.dataId,
          removeMe: true,
          subject: mobileRemoveUserData?.subject,
          type: mobileRemoveUserData?.type,
          modalId: id
        })
      )
    }
  }

  render() {
    // console.log('eventModalPropsRender', this.props)
    const modalStyles = {
      ddiLabelIconStyle: {
        marginRight: 5,
        verticalAlign: 'middle',
        fontSize: 16
      },
      ddiLabelStyle: {
        background: '#eaeef0',
        borderRadius: 4,
        color: '#517b9c',
        display: 'inline-block',
        fontSize: 12,
        lineHeight: '16px',
        padding: 5
      },
      label: {
        marginBottom: 0,
        color: '#666',
        fontSize: 10,
        marginRight: 5
      },
      paragraph: { fontSize: 12 },
      row: { marginBottom: 10 }
    }

    const { expanded, initialized, timeSelectionError } = this.state

    const {
      data: {
        responseData: {
          meta: { taskTypeOptions, referenceOptions, reminderIntervalOptions },
          participants = []
        }
      },
      dataId,
      eventTypeEditorMap,
      eventTypeOptions,
      fields: fieldsMap,
      userIds,
      isMobile,
      hasCustomFields,
      customFieldsDataList,
      referenceDataMap
    } = this.props

    const fields = fieldsMap?.toJS ? fieldsMap.toJS() : {}

    const canRemoveMe =
      this?.props?.data?.responseData?.activity?.canRemoveMe || false
    // console.log('canRemoveMe', canRemoveMe, this.props.id, mobileRemoveUserData)

    // const reminderValue =
    //   fields && fields.reminderValue && fields.reminderValue.value
    //     ? fields.reminderValue.value
    //     : 0

    const isTask = this.getFieldValue('eventType', 'T')

    const eventListColumnDefs = [
      {
        checkboxSelection: false,
        field: 'recordName',
        filter: 'text',
        headerName: 'Choose Options',
        width: 150
      },
      {
        checkboxSelection: false,
        field: 'colCd',
        filter: 'text',
        headerName: 'ColCd',
        width: 40
      }
    ]

    const userDropdownIsDisabled = this.isUserDropdownDisabled()

    const referenceData = referenceDataMap?.toJS ? referenceDataMap.toJS() : {}
    const eventTypeEditor = eventTypeEditorMap?.toJS
      ? eventTypeEditorMap.toJS()
      : { open: false }
    const customFieldsData = customFieldsDataList?.toJS
      ? customFieldsDataList.toJS()
      : []

    // const isMobile = this?.newEventTypeModule?.props?.isMobile || false
    // console.log('IS_MOBILE', isMobile)
    console.log('fieldsMap', fields)

    return initialized ? (
      <div style={{ maxWidth: '100%', width: '100%' }}>
        <Row style={modalStyles.row}>
          <Col xs={isTask ? 6 : 12} sm={4} style={{ position: 'relative' }}>
            <div style={{ alignItems: 'baseline', display: 'flex' }}>
              <div
                style={{
                  marginRight: 5,
                  width: isMobile ? '100%' : 'calc(100% - 20px)'
                }}
              >
                <SelectField
                  label="Event Type"
                  propertyName="eventType"
                  values={eventTypeOptions}
                  initialValue={
                    this.getFieldValue('eventType', null, 'exists')
                      ? fields.eventType.value
                      : 'E'
                  }
                  inputProps={{
                    id: 'eventType',
                    name: 'eventType'
                  }}
                  fullWidth
                  selectValue="dataId"
                  selectDescription="description"
                />
              </div>
              <NewEventTypePopover
                {...this.props}
                id="eventTypeDropdown"
                identifier="id"
                rowSelection="single"
                // closeIconMenuAction={this.props.meta.clearNewEventTypePopover}
                selectedEvent={{
                  ...fields,
                  dataId: this.props.dataId || null,
                  userId: this.props.userId || null
                }}
                context={{ _ddiForm: this.props._ddiForm }}
                gridOptions={{
                  columnDefs: eventListColumnDefs,
                  enableSorting: false,
                  headerHeight: 26,
                  height: 200,
                  width: 220
                }}
                open={eventTypeEditor.open}
                interfaceOptions={eventTypeEditor}
                ref={el => (this.newEventTypeModule = el)}
              />
            </div>
          </Col>
          {isTask && (
            <>
              <Col xs={6} sm={4}>
                <SelectField
                  values={taskTypeOptions}
                  label="Task Type"
                  propertyName="taskType"
                  inputProps={{
                    id: 'taskType',
                    name: 'taskType'
                  }}
                  fullWidth
                  selectValue="dataId"
                  selectDescription="description"
                  initialValue=""
                />
              </Col>
              <Col
                className="task-completed-toggle-wrapper"
                xs={12}
                sm={4}
                lg={4}
                style={isMobile ? { marginTop: 10 } : null}
              >
                <Field
                  component={Toggle}
                  propertyName="completed"
                  label="Completed"
                  initialValue={
                    this.getFieldValue('completed', null, 'exists')
                      ? fields.completed.value
                      : false
                  }
                  isFound
                  wrapperStyle={{
                    background: 'rgba(219, 229, 236, 0.3)',
                    borderRadius: 4,
                    display: 'flex',
                    marginTop: isMobile ? 0 : 5,
                    padding: '0 5px 0 15px',
                    width: '100%'
                  }}
                />
              </Col>
            </>
          )}
        </Row>
        {this.props.occurredInThePast && (
          <Row style={modalStyles.row}>
            <Col xs={12} sm={12}>
              <div
                className="alert alert-warning text-center"
                style={{
                  alignItems: 'center',
                  background: '#fcf8e3',
                  borderColor: '#faebcc',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 0,
                  padding: 5
                }}
              >
                <Icon color="#8a6d3b" style={{ marginRight: 5 }}>
                  info
                </Icon>
                <span>Occurred in the Past</span>
              </div>
            </Col>
          </Row>
        )}
        <Row style={modalStyles.row}>
          <Col sm={12}>
            <Field
              component={TextField}
              label="Subject"
              propertyName="subject"
              fullWidth
              validate={[required]}
              autoFocus
              errorIconStyle={{
                bottom: 2,
                color: '#d9534f',
                height: 20,
                position: 'absolute',
                right: 0,
                width: 20,
                fontSize: 16,
                zIndex: 1
              }}
              // inputRef={React.createRef()}
            />
          </Col>
        </Row>
        <Row style={modalStyles.row}>
          <Col xs={fields?.referenceType?.value ? 6 : 12} sm={6}>
            <SelectField
              values={referenceOptions}
              label="Reference Type"
              propertyName="referenceType"
              inputProps={{
                id: 'referenceType',
                name: 'referenceType'
              }}
              fullWidth
              selectValue="dataId"
              selectDescription="description"
            />
          </Col>
          {fields?.referenceType?.value ? (
            <Col xs={6} sm={6}>
              <div style={{ paddingTop: 16 }}>
                {this.getReference(this.getReferenceType())}
              </div>
            </Col>
          ) : null}
        </Row>
        {fields?.referenceId?.value ? (
          <Row>
            <Col lg={12} sm={12}>
              <ReferenceLabel
                dataId={dataId || null}
                isNew={!dataId}
                callLocation={this.props.callLocation}
                referenceData={referenceData}
                modalId={this.props.id}
                labelStyle={{
                  ...modalStyles.ddiLabelStyle,
                  marginBottom: 10,
                  width: '100%'
                }}
                isMobile={isMobile}
              />
            </Col>
          </Row>
        ) : null}
        <Row style={{ marginBottom: timeSelectionError && !isTask ? 0 : 15 }}>
          <Col xs={6} sm={6}>
            {this.getFieldValue('startDate', null, 'exists') && (
              <DDIDateTimePicker
                label="Start Date"
                propertyName="startDate"
                initialValue={fields.startDate.value}
                onBlur={noop}
              />
            )}
          </Col>
          <Col xs={6} sm={6}>
            {((this.getFieldValue('startDate', null, 'exists') && !isTask) ||
              (this.getFieldValue('startDate', null, 'exists') &&
                isTask &&
                this.getFieldValue('completed', true))) && (
              <DDIDateTimePicker
                label="End Date"
                propertyName="endDate"
                initialValue={fields?.endDate?.value}
                ref={el => (this.endDate = el)}
                onBlur={noop}
              />
            )}
          </Col>
        </Row>
        {timeSelectionError && !isTask && (
          <Row style={{ marginBottom: 15 }}>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div
                className="alert alert-warning text-center"
                style={{
                  background: '#fcf8e3',
                  borderColor: '#faebcc',
                  margin: '5px 0',
                  padding: 5
                }}
              >
                <Icon
                  color="#8a6d3b"
                  style={{ fontSize: 16, verticalAlign: 'middle' }}
                >
                  warning
                </Icon>{' '}
                <span
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    top: '2px'
                  }}
                >
                  End Date must not be before Start Date
                </span>
              </div>
            </Col>
          </Row>
        )}
        <DDIExpansionPanel
          title="Details"
          expansionPanelContentStyle={{ padding: 0, width: '100%' }}
          expansionPanelDetailsStyle={{ padding: 10, width: '100%' }}
        >
          <div style={{ width: '100%' }}>
            <Row style={modalStyles.row}>
              <Col lg={12}>
                {userIds ? (
                  <Field
                    propertyName="users"
                    identifier="dataId"
                    disabled={userDropdownIsDisabled || !participants.length}
                    component={SearchDropdown}
                    rowData={participants || []}
                    fullWidth
                    floatingLabelText="Participants"
                    initialValue={userIds}
                    gridOptions={{
                      className: 'ag-theme-balham secondary-grid',
                      columnDefs: userColumnDefs,
                      enableSorting: true,
                      getRowNodeId,
                      height: 300
                    }}
                  />
                ) : null}
                {canRemoveMe && isMobile ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      style={{ color: '#d9534f' }}
                      onClick={this.removeUser}
                      size="small"
                      startIcon={
                        <Icon fontSize="small" style={{ color: '#d9534f' }}>
                          remove_circle
                        </Icon>
                      }
                    >
                      Remove Me
                    </Button>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row style={modalStyles.row}>
              <Col lg={12}>
                <Field
                  component={TextField}
                  label="Location"
                  propertyName="location"
                  initialValue=""
                  fullWidth
                />
              </Col>
            </Row>
            <Row style={modalStyles.row}>
              <Col lg={12}>
                <div
                  className="event-modal-notes-wrapper"
                  style={{ marginTop: 15 }}
                >
                  <Field
                    component={TextField}
                    label="Notes"
                    propertyName="notes"
                    initialValue=""
                    fullWidth
                    rows={isMobile ? 2 : 4}
                    multiline
                    variant="outlined"
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={6} lg={6} sm={6}>
                <div
                  className="event-modal-reminder-wrapper"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: isMobile ? 0 : 15,
                    flexDirection: isMobile ? 'column' : 'row'
                  }}
                >
                  <label
                    htmlFor="reminderValue"
                    style={{
                      ...modalStyles.label,
                      marginBottom: isMobile ? 3 : 0,
                      marginRight: isMobile ? 0 : 5,
                      fontSize: 12
                    }}
                  >
                    Reminder:
                  </label>
                  <Field
                    component={NumberSpinner}
                    propertyName="reminderValue"
                  />
                </div>
              </Col>
              <Col xs={6} lg={6} sm={6}>
                <div style={{ maxWidth: 200 }}>
                  <SelectField
                    values={reminderIntervalOptions}
                    label="Before Start"
                    propertyName="reminderIntervalType"
                    inputProps={{
                      id: 'reminderIntervalType',
                      name: 'reminderIntervalType'
                    }}
                    fullWidth
                    selectValue="dataId"
                    selectDescription="description"
                  />
                </div>
              </Col>
            </Row>
            <Row style={modalStyles.row}>
              {this.props.enteredByDisplay && (
                <Col lg={6} md={6} sm={6} xs={12}>
                  <p style={{ ...modalStyles.paragraph, textAlign: 'center' }}>
                    <strong>Entered By:</strong> {this.props.enteredByDisplay}
                  </p>
                </Col>
              )}
              {this.props.lastUpdateddByDisplay && (
                <Col lg={6} md={6} sm={6} xs={12}>
                  <p style={{ ...modalStyles.paragraph, textAlign: 'center' }}>
                    <strong>Last Updated By:</strong>{' '}
                    {this.props.lastUpdateddByDisplay}
                  </p>
                </Col>
              )}
            </Row>
          </div>
        </DDIExpansionPanel>
        {hasCustomFields ? (
          <ActivityCustomFields
            form="eventModal"
            customFieldsData={customFieldsData}
            hasCustomFields={hasCustomFields}
          />
        ) : null}
      </div>
    ) : null
  }
}

const mapState = state => ({
  isMobile: isMobileSelector(state)
})

const contextObj = {
  callLocation: form => form.get('callLocation') || 'calendar',
  referenceDataMap: form =>
    getIn(form, 'fields.referenceId.referenceData') || empty,
  eventTypeOptions: form => getMeta(form, 'eventTypeOptions', []),
  startDate: form => getField(form, 'startDate', ''),
  endDate: form => getField(form, 'endDate', ''),
  userIds: form => getMeta(form, 'userIds', ''),
  eventTypeEditorMap: form => form.get('eventTypeEditor') || empty,
  customFieldsDataList: form =>
    getMeta(form, 'customFields.customFieldsData', emptyList),
  hasCustomFields: form => getMeta(form, 'customFields.hasCustomFields', false)
}

// export default EventModal
const C = connect(mapState)(withContext(contextObj)(EventModal))
/* eslint-disable react/prefer-stateless-function, react/no-multi-comp */

export class EventModalWrapper extends Component {
  render() {
    return <C {...this.props} />
  }
}
export default EventModalWrapper
