import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const NOTES_MODAL = '@@NOTES_MODAL@@'

/* START internal notes modal constants */
export const LAUNCH_NOTES_MODAL = `${NOTES_MODAL}LAUNCH_NOTES_MODAL`
export const DISPLAY_INTERNAL_NOTES = `${NOTES_MODAL}DISPLAY_INTERNAL_NOTES`
export const LOCK_INTERNAL_NOTES = createAsyncRequestTypes(
  `${NOTES_MODAL}LOCK_INTERNAL_NOTES`
)

export const UNLOCK_INTERNAL_NOTES = createAsyncRequestTypes(
  `${NOTES_MODAL}UNLOCK_INTERNAL_NOTES`
)

export const SAVE_INTERNAL_NOTES = createAsyncRequestTypes(
  `${NOTES_MODAL}SAVE_INTERNAL_NOTES`
)

export const DELETE_INTERNAL_NOTES = createAsyncRequestTypes(
  `${NOTES_MODAL}DELETE_INTERNAL_NOTES`
)

export const NOTIFY_INTERNAL_NOTES_MODAL_CLOSED = `${NOTES_MODAL}NOTIFY_INTERNAL_NOTES_MODAL_CLOSED`
