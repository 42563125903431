import { createReducer } from 'utils'
import { empty, fromJS } from 'immutable'
import { AUTHENTICATION_ERROR } from 'auth/constants'
import { ADD_MODAL, HIDE_MODAL, REMOVE_MODAL } from './constants'

const initialState = fromJS([])

export const modalBehaviors = {
  [ADD_MODAL]: (state, { payload }) => {
    state = state || initialState
    const newState = state.push({ ...payload, show: true })
    return newState
  },

  [HIDE_MODAL]: (state, { payload }) => {
    const index = state.findIndex(modal => modal.id === payload.id)

    return index !== -1
      ? state.update(index, s => ({ ...s, show: false }))
      : state
  },
  [REMOVE_MODAL]: (state, { payload }) => {
    const index = state.findIndex(modal => modal.id === payload.id)
    return index !== -1 ? state.delete(index) : state
  }
}

export const modalReducer = createReducer(initialState, modalBehaviors)

export default function reducer() {
  const enhancer = duck => (state = initialState, action = {}) => {
    const form = action && action.meta && action.meta.form
    // form = form && !form.includes('dashboard')
    if (form) {
      return state
    }
    if (action.type === AUTHENTICATION_ERROR) {
      return initialState
    }
    return duck(state, action)
  }
  return enhancer(modalReducer)
}
