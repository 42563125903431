import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, MenuItem, Paper, TextField } from '@material-ui/core'
import { noop } from 'lodash'
import { getIn, getBaseGridContextMenuItems } from 'utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'

import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'

// import IndexSearch from 'components/Search/IndexSearch'
import DDITabTextField from 'ddiForm/wrapped/DDITabTextField'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import DDICardWrapper from 'components/DDICardWrapper'
import ComponentStyles from 'theme/ComponentStyles'

import { ddiForm, GridField, Label, withContext } from 'ddiForm'
import DDIButton from 'ddiForm/Button'
import DDITextField from 'ddiForm/wrapped/DDITextField'
import SelectField from 'ddiForm/SelectField'
import './styles/css-mod-ignore.scss'

import Fields from './components/Fields'
import sagas from './sagas'
import * as actions from './actions'
import behaviors from './behaviors'

const styles = {
  ddiLabelIconStyle: {
    marginRight: 5,
    verticalAlign: 'middle',
    fontSize: 16
  },
  ddiLabelStyle: {
    width: 'auto'
  },
  flexInput: {
    description: { flex: 1, order: 3 },
    input: { flex: 1, marginRight: 10, maxWidth: 300, minWidth: 300, order: 2 },
    label: {
      flex: 1,
      marginRight: 10,
      maxWidth: 150,
      minWidth: 150,
      order: 1,
      textAlign: 'right'
    },
    wrapper: {
      alignItems: 'center',
      display: 'inline-flex',
      flexWrap: 'wrap',
      margin: '10px 0',
      width: '100%'
    }
  },
  inputText: { fontSize: 12 },
  label: {
    color: '#444',
    fontSize: 12,
    fontWeight: 500,
    margin: 0
  },
  labelColumn: { textAlign: 'right' },
  rowStyle: {
    alignItems: 'center',
    display: 'flex',
    margin: '10px 0'
  }
}

export const pinnedDataFn = rowData => {
  const totalsData = rowData.reduce(
    (acc, next) => {
      acc.orderValue += next.orderValue
      return acc
    },
    { orderValue: 0.0 }
  )
  return [totalsData]
}

export class PurchaseOrderInquiry extends Component {
  static propTypes = { asModal: PropTypes.bool }

  static defaultProps = { asModal: false }

  static ddiType = 'PurchaseOrderInquiry'

  onRowSelected = params => {
    if (!this.props.asModal) return
    this.props.rowSelected(params.data.dataId)
    if (this.props.onRowSelected) {
      this.props.onRowSelected(params)
      // this.props.rowSelected(params.data.dataId)
    }
  }

  getRowNodeId = data => data.dataId

  getContextMenuItems = params => {
    const {
      dispatch,
      fields: {
        inquiryResults: { columnDefs }
      }
    } = this.props
    if (columnDefs && dispatch) {
      return [
        {
          name: 'View Delivery Confirmation',
          action: () =>
            dispatch(
              openScreenAction({
                name: 'pODeliveryConfirmation',
                image: 'purchasing_po_delivery_confirmation_16.png',
                title: 'P/O Delivery Confirmation'
              })
            )
        },
        'separator',
        ...getBaseGridContextMenuItems(params, columnDefs)
      ]
    }
    return []
  }

  render() {
    const { asModal, statusOptions, selectInitialValue } = this.props
    const { screenLayout } = ComponentStyles
    return (
      <div style={screenLayout.container}>
        <div style={{ ...screenLayout.contentWrapper, padding: 10 }}>
          <DDICardWrapper title="Purchase Order Inquiry">
            <Fields asModal={asModal} styles={styles} />
            {/* <Paper style={{ padding: '10px 20px 15px 20px' }}>
              <div className="po-inquiry-fields">
                <div
                  className="input-flex-row"
                  style={styles.flexInput.wrapper}
                >
                  <div className="label" style={styles.flexInput.label}>
                    <label htmlFor="poNumber" style={styles.label}>
                      P/O Number:
                    </label>
                  </div>
                  <div className="input" style={styles.flexInput.input}>
                    <DDITabTextField
                      propertyName="poNumber"
                      fullWidth
                      onTab="exactMatchFromForm"
                      style={{ width: '100%' }}
                      disabled={(field, formState) => field.disabled}
                    />
                  </div>
                </div>

                <div
                  className="input-flex-row"
                  style={styles.flexInput.wrapper}
                >
                  <div className="label" style={styles.flexInput.label}>
                    <label htmlFor="product" style={styles.label}>
                      Product:
                    </label>
                  </div>
                  <div className="input" style={styles.flexInput.input}>
                    <DDIIndexSearch
                      propertyName="product"
                      metaKey="Product"
                      // searchType={
                      //   INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.PRODUCT
                      // }
                      asModal={asModal}
                      moreInfo
                      openScreenData={{
                        name: 'productMaster',
                        image: 'product_master_16.png',
                        title: 'Product Master'
                      }}
                      openScreenMenuText="Open Product Master"
                      minWidth="100%"
                    />
                  </div>
                  <div
                    className="description"
                    style={styles.flexInput.description}
                  >
                    <Label propertyName="product.description" />
                  </div>
                </div>

                <div
                  className="input-flex-row"
                  style={styles.flexInput.wrapper}
                >
                  <div className="label" style={styles.flexInput.label}>
                    <label htmlFor="vendor" style={styles.label}>
                      Vendor:
                    </label>
                  </div>
                  <div className="input" style={styles.flexInput.input}>
                    <DDIIndexSearch
                      propertyName="vendor"
                      metaKey="Vendor"
                      // searchType={
                      //   INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.VENDOR
                      // }
                      asModal={asModal}
                      moreInfo
                      openScreenData={{
                        name: 'vendorMaster',
                        image: 'vendor_master_16.png',
                        title: 'Vendor Master'
                      }}
                      openScreenMenuText="Open Vendor Master"
                      minWidth="100%"
                    />
                  </div>
                  <div
                    className="description"
                    style={styles.flexInput.description}
                  >
                    <Label propertyName="vendor.description" />
                  </div>
                </div>

                <div
                  className="input-flex-row"
                  style={styles.flexInput.wrapper}
                >
                  <div className="label" style={styles.flexInput.label}>
                    <label htmlFor="job" style={styles.label}>
                      Job:
                    </label>
                  </div>
                  <div className="input" style={styles.flexInput.input}>
                    <DDITextField propertyName="job" fullWidth />
                  </div>
                  <div
                    className="description"
                    style={styles.flexInput.description}
                  >
                    <Label propertyName="job.description" />
                  </div>
                </div>

                <div
                  className="input-flex-row"
                  style={styles.flexInput.wrapper}
                >
                  <div className="label" style={styles.flexInput.label}>
                    <label htmlFor="statusOptions" style={styles.label}>
                      Purchase Order:
                    </label>
                  </div>
                  <div className="input" style={styles.flexInput.input}>
                    <SelectField
                      propertyName="status"
                      fullWidth
                      values={statusOptions}
                      initialValue={selectInitialValue}
                      mappingComponent={val => (
                        <MenuItem key={val.dataId} value={val.dataId}>
                          {val.description}
                        </MenuItem>
                      )}
                    />
                  </div>
                </div>
              </div>
            </Paper> */}
          </DDICardWrapper>
          <div className="clear" style={{ marginBottom: 10 }}>
            <GridField
              propertyName="inquiryResults"
              getRowNodeId={this.getRowNodeId}
              onRowSelected={this.onRowSelected}
              rowSelection="single"
              height={asModal ? 200 : 350}
              enableFilter
              enableSorting
              autoSize
              pinnedData={pinnedDataFn}
              getContextMenuItems={this.getContextMenuItems}
            />
          </div>
        </div>
        <div style={screenLayout.footerWrapper}>
          <Paper
            className="action-buttons clear"
            style={{ ...ComponentStyles.footer.containerStandalone }}
          >
            <div style={{ float: 'left' }}>
              <DDIButton
                label="Reset"
                variant="contained"
                style={{ marginRight: 10 }}
                actionName="reset"
                bindTo="onClick"
              />
            </div>
            <div style={{ float: 'right' }}>
              <DDIButton
                label="Search"
                actionName="executeInquiry"
                bindTo="onClick"
                variant="contained"
                // disabled={(field, formState) =>
                //   getIn(formState, 'fields.inquiryResults.isFetching')
                // }
                style={
                  asModal ? { marginRight: 0 } : ComponentStyles.footer.button
                }
              />
              {!asModal && (
                <span>
                  <Button
                    variant="contained"
                    disabled
                    style={ComponentStyles.footer.button}
                  >
                    Print
                  </Button>
                  <DDIButton
                    variant="contained"
                    label="Exit"
                    actionName="exit"
                    bindTo="onClick"
                  />
                </span>
              )}
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export const options = {
  actions: {
    executeInquiry: actions.executeInquiry.try,
    exactMatch: actions.exactMatch,
    exactMatchFromForm: actions.exactMatchFromForm,
    reset: actions.reset,
    rowSelected: actions.rowSelected
  },
  behaviors,
  sagas: { onInit: sagas },
  form: 'purchaseOrderInquiry',
  // disableMeta: true,
  // ignoreMeta: true,
  title: 'Purchase Order Inquiry'
}

export const component = PurchaseOrderInquiry

export default ddiForm(options)(PurchaseOrderInquiry)
