import React from 'react'
import { Popover } from '@material-ui/core'

// function action(f) {
//   debugger
//   console.log(f)
// }
const DropDown = ({
  children,
  anchorEl,
  onClose,
  open,
  onEntered,
  anchorOrigin,
  transformOrigin,
  disableEnforceFocus,
  disableAutoFocus,
  disableRestoreFocus,
  className,
  action,
  style,
  PaperProps
}) => (
  <Popover
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    onClose={onClose}
    open={open}
    onEntered={onEntered}
    disableEnforceFocus={disableEnforceFocus}
    disableAutoFocus={disableAutoFocus}
    disableRestoreFocus={disableRestoreFocus}
    className={className}
    action={action}
    style={style}
    PaperProps={PaperProps}
  >
    {children}
  </Popover>
)

DropDown.defaultProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
  disableEnforceFocus: true,
  disableAutoFocus: true,
  disableRestoreFocus: false,
  className: 'tooltip-root ddi-search-dropdown',
  open: false
}
export default DropDown
