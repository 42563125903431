import { connect } from 'react-redux'
import { getIn } from 'utils'
import BranchListMobile from './BranchListMobile'
import { selectBranch } from '../../actions'
import { branchListSelector } from '../../selectors'

const mapStateToProps = state => ({
  branchList: branchListSelector(state)
})

const mapDispatchToProps = dispatch => ({
  selectBranch: dataId => {
    dispatch(selectBranch(dataId))
  }
})

const BranchListContainerMobile = connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchListMobile)

export default BranchListContainerMobile
