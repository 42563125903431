import formatDate from './formatDate'


/**
 * overrides ag-grid default comparator for date filter
 * @param {string} filterDate - date string selected in filter formatted to local date at midnight
 * @param {string} cellVal - date value of current cell
 * @returns {number} returns comparator for filter
*/
const dateFilterComparator = (filterDate, cellVal) => {
  const formattedDate = formatDate(cellVal, 'MM/DD/YYYY')
  if (formattedDate === null) return 0

  const cellDate = new Date(formattedDate)

  if (cellDate < filterDate) {
    return -1
  }

  if (cellDate > filterDate) {
    return 1
  }

  return 0
}

export default dateFilterComparator
