import {
  createReducer,
  getIn,
  setIn,
  deleteIn,
  mapColumnHeaders,
  fromJS
} from 'utils'

import * as CONSTANTS from './constants'

// export const bootstrap = (
//   state,
//   { payload: { displayEmptyRowWhileEmpty, emptyRow, isPending, rowData } }
// ) => {
//   let result = state
//   debugger
//   rowData = rowData || getIn(result, 'rowData')

//   if (displayEmptyRowWhileEmpty && !rowData.size && emptyRow) {
//     result = setIn(result, 'rowData', rowData.push(fromJS(emptyRow)))
//     result = setIn(result, 'isPending', true)
//     result = setIn(result, 'emptyRow', fromJS(emptyRow))
//     result = setIn(result, 'selectedRowIndex', null)
//     result = setIn(result, 'selectedCell', null)
//   } else if (rowData.size) {
//     debugger
//     result = setIn(result, 'rowData', rowData)
//   }
//   return result
// }

export const bootstrap = (
  state,
  { payload: { displayEmptyRowWhileEmpty, emptyRow, isPending, rowData } }
) => {
  let result = state
  rowData = rowData || getIn(result, 'rowData')
  result = setIn(result, 'selectedRowIndex', null)
  result = setIn(result, 'selectedCell', null)
  result = setIn(result, 'isPending', true)
  result = setIn(result, 'emptyRow', fromJS(emptyRow))
  if (displayEmptyRowWhileEmpty && !rowData.size && emptyRow) {
    result = setIn(result, 'rowData', rowData.push(fromJS(emptyRow)))
  } else if (rowData.size) {
    result = setIn(result, 'rowData', rowData)
  }
  return result
}

export const behaviors = {
  BOOTSTRAP: bootstrap,
  [CONSTANTS.ADD_BLANK_ROW]: (state, action) => {
    let result = state
    const rowIdentifier = getIn(action, 'payload.rowIdentifier') || 'rowId'
    const lastRowIdentifier = getIn(action.payload.lastRowId) || 'blankrow'
    const rowData = getIn(result, 'rowData')
    const last = rowData.last()
    const lastRowId = last ? getIn(last, rowIdentifier) : ''

    if (
      action.payload &&
      action.payload.propertyName &&
      getIn(result, 'isPending')
    ) {
      /* 
        Marc - this one bit of logic provides backwards compatability 
        for any EditableGrids out there that still use the isPending logic
        SVE 2/19/2020
      */
      return result
    }

    let empty = getIn(result, 'emptyRow')
    if (lastRowId !== lastRowIdentifier) {
      empty = setIn(empty, 'lineNumber', rowData.size + 1)
      result = setIn(result, 'rowData', rowData.push(empty))

      // console.log(rowData.size)
      // debugger

      /* 
        Marc - this was actually a silent bug, which I just noticed this
        morning. size is the rowIndex we need here, the + 1 was wrong. -- SVE 10/9/2020 
      */
      result = setIn(result, 'selectedRowIndex', rowData.size)
      // result = setIn(result, 'selectedRowIndex', rowData.size + 1)
    }

    return result
  },
  CELL_CHANGED_COMPLETE: (state, action) => {
    console.log(state, action)
    let result = state
    const rowIdentifier = getIn(action, 'payload.rowIdentifier') || 'rowId'
    let rowData = getIn(result, 'rowData')
    const finder = x =>
      getIn(x, rowIdentifier) === action.payload.data[rowIdentifier]
    const rowIdx = rowData.findIndex(finder)
    rowData = rowData.update(rowIdx, data =>
      data.set(action.payload.column.colId, action.payload.newValue)
    )
    result = setIn(result, 'rowData', rowData)
    return result
  },
  [CONSTANTS.SET_SELECTED_ROW_INDEX]: (state, action) => {
    let result = state
    result = setIn(result, 'selectedRowIndex', action.payload.rowIndex)
    return result
  }

  // possibly add this ala optimisitic updates.
  // REMOVE_LINE_ITEM: (state, action) => {
  //   let result = state
  //   const rowIdentifier = getIn(action, 'payload.rowIdentifier') || 'childIndex'
  //   let rowData = getIn(result, 'rowData')
  //   // const finder = x => getIn(x, rowIdentifier) === action.payload.data[rowIdentifier]
  //   if (rowData.size <= 1) {
  //     const empty = getIn(result, 'emptyRow')
  //     rowData = fromJS([empty])
  //     result = setIn(result, 'rowData', rowData)
  //   } else {
  //     result = result.deleteIn(['rowData', action.payload[rowIdentifier]])
  //     rowData = rowData.map((row, idx) =>
  //       row.update('lineNumber', lineNumber => idx.toString())
  //     )
  //     result = setIn(result, rowData)
  //   }

  //   return result
  // }
}

const initialState = fromJS({
  rowData: [],
  columnDefs: [],
  meta: {},
  grid: true
})

export default createReducer(initialState, behaviors)
