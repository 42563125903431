import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { getIn, is } from 'utils'
import memoize from 'memoize-one'
import { getFormSelector, filterHidden, trimmedCameled } from '../utils'

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getFormSelector(form)(state)
  const masterOptions = getIn(formState, 'masterOptions')
  const tabComponents = getIn(masterOptions, 'tabComponents')
  // const meta = getIn(state, `ddiForm.${form}.meta`)
  // const isJohnstone = getIn(meta, 'isJohnstoneCustomer')

  const selectedTab = getIn(masterOptions, 'selectedSecondaryTab')
  const selectedPrimaryTab = getIn(masterOptions, 'selectedPrimaryTab')
  const tabMap = getIn(masterOptions, 'secondaryTabMap')
  let tabs = getIn(tabMap, selectedPrimaryTab)
  tabs = tabs && tabs.toJS ? tabs.toJS() : []

  // tabs = tabs.filter(tab => {
  //   let t = getIn(
  //     tabComponents,
  //     `${trimmedCameled(selectedPrimaryTab)}-${trimmedCameled(tab)}`
  //   )
  //   t = t && t.toJS ? t.toJS() : t
  //   return filterHidden(formState)(t)
  // })

  tabs = tabs.reduce((acc, tab) => {
    let t = getIn(
      tabComponents,
      `${trimmedCameled(selectedPrimaryTab)}-${trimmedCameled(tab)}`
    )
    t = t && t.toJS ? t.toJS() : t
    const notHidden = filterHidden(formState)(t)
    // debugger
    if (notHidden) {
      // acc = acc.concat({
      //   name: tab
      // })
      const newTab = {
        name: tab
      }
      if (Object.hasOwnProperty.call(t, 'isDisabled')) {
        // debugger
        if (is.bool(t.isDisabled)) {
          newTab.disabled = t.isDisabled
        }
        if (is.fn(t.isDisabled)) {
          newTab.disabled = t.isDisabled(formState.toJS())
        }
      } else {
        newTab.disabled = false
      }
      acc = acc.concat(newTab)
    }

    return acc
  }, [])

  return {
    selectedPrimaryTab,
    tabs,
    value: selectedTab
  }
}

const tabStyles = {
  ddiSecondaryTab: {
    fontSize: '0.8em',
    height: 30,
    minHeight: 30,
    textTransform: 'none'
  }
}
const styles = {
  tabStyles: { height: 30, minHeight: 30 },
  appBarStyles: {

    background: '#c1d5e5',
    boxShadow: 'none',
    height: 30,
    minHeight: 30,
    marginTop: 5
    // marginLeft: 10,
    // marginRight: 10

  }
}

class SecondaryTabs extends PureComponent {
  onChange = (event, value) => {
    if (value) {
      this.props.changeFormTab(this.props.selectedPrimaryTab, value)
    }
  }
  // tabs = memoize(tabs =>
  //   tabs.map(tab => <Tab value={tab} label={tab} key={tab} />)
  // )
  tabs = memoize(tabs =>
    tabs.map(tab => (
      <Tab
        value={trimmedCameled(tab.name)}
        label={tab.name}
        key={tab.name}
        className={this.props.classes.ddiSecondaryTab}
        disabled={tab.disabled}
      />
    ))
  )
  render() {
    const { tabs, value } = this.props
    return (
      /* <div style={{ padding: '0 10' }}> */
      tabs.length ? (
        <AppBar
          position="static"
          color="default"
          // style={}
          style={styles.appBarStyles}
        >
          <Tabs
            scrollButtons="on"
            variant="scrollable"
            value={value}
            onChange={this.onChange}
            style={styles.tabStyles}
          >
            {this.tabs(tabs)}
          </Tabs>
        </AppBar>
      ) : (
        <div style={{ height: 0 }} />
      )
      /* </div> */
    )
  }
}
export default connect(mapStateToProps)(withStyles(tabStyles)(SecondaryTabs))
