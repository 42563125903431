import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

// export const getCustomer = createAsyncFormAction(
//   CONSTANTS.GET_CUSTOMER,
//   CONSTANTS.customerMaster
// )

export const getEntityAsync = createAsyncFormAction(
  CONSTANTS.GET_ENTITY,
  CONSTANTS.MASTER_SCREEN
)

export const onDoubleClick = (propertyName, meta) =>
  createAction(CONSTANTS.ON_DOUBLE_CLICK, { propertyName }, null, false, meta)

export const resetMasterFields = (form, fields = []) =>
  createAction(CONSTANTS.RESET_MASTER_FIELDS, { fields }, null, false, { form })

export const lockForEditAsync = createAsyncFormAction(CONSTANTS.LOCK_FOR_EDIT)
export const cancelEditAsync = createAsyncFormAction(CONSTANTS.CANCEL_EDIT)
export const preCancelEdit = form =>
  createAction(CONSTANTS.PRE_CANCEL_EDIT, {}, null, false, { form })
export const getAlternateMetaAsync = createAsyncFormAction(
  CONSTANTS.GET_ALTERNATE_META
)
