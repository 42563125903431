import { isEqualWith } from 'lodash'
import customizer from './customizer'

/**
 * runs the lodash util isEqualWith with a customizer func
 * @param {object} a - object to compare
 * @param {object} b - object for comparison
 * @returns {boolean} returns true or false obj comparison
*/
const deepEqual = (a, b) => isEqualWith(a, b, customizer)

export default deepEqual
