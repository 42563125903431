import toCamelCase from './toCamelCase'

/**
 * converts a collection of access paths
 * and converts them to objects with the
 * following keys (accessPath, requiredLevel,
 * and type)
 * @param {array} items - array of items to parse
 * @returns {object} keyed by item description
 */
export default function accessPathMapper(items = []) {
  const accessPathMap = { userMail: { type: 'open' } }

  function helper(itms = []) {
    for (let i = 0, len = itms.length; i < len; i++) {
      const item = itms[i]
      if (item.accessPathAdd) {
        console.log(item)
      }
      if (item.accessPathOpen) {
        // will be legacy soon...
        accessPathMap[toCamelCase(item.fullDescription || item.description)] = {
          accessPath: item.accessPathOpen || item.accessPathAdd,
          requiredLevel: item.requiredLevel,
          type: item.accessPathOpen ? 'open' : 'tile'
        }
      } else if (item.accessPath) {
        accessPathMap[toCamelCase(item.description)] = {
          accessPath: item.accessPath.accessPath,
          requiredLevel: item.accessPath.requiredLevel,
          type: item.accessPath.type
        }
      }
      if (item.items) {
        helper(item.items)
      }
    }
  }
  helper(items)
  return accessPathMap
}
