import { createAction, createAsyncAction } from 'utils'
import * as CONSTANTS from './constants'

export const partialMatchSearch = (form, args) =>
  createAction(CONSTANTS.PARTIAL_MATCH_SEARCH.REQUEST, args, null, null, {
    form,
    apiRequest: true,
    thunk: true
  })

export const exactMatchSearch = (form, payload) => ({
  meta: {
    form,
    thunk: true,
    apiRequest: true
  },
  payload,
  type: CONSTANTS.EXACT_MATCH_SEARCH.REQUEST
})

export const toggleFilter = (form, { propertyName, subProperty }) =>
  createAction(CONSTANTS.TOGGLE_FILTER, { propertyName, subProperty }, form)

export const blur = (form, { propertyName, subProperty, value, isSet }) =>
  createAction(
    CONSTANTS.BLUR,
    {
      propertyName,
      subProperty,
      value,
      isSet
    },
    form
  )
export const focus = (form, { propertyName, subProperty }) =>
  createAction(CONSTANTS.FOCUS, { propertyName, subProperty }, form)
export const populateField = (
  form,
  { propertyName, description, indexSearchType, value }
) =>
  createAction(
    CONSTANTS.POPULATE_FIELD,
    {
      propertyName,
      description,
      indexSearchType,
      value
    },
    form
  )
export const getTooltip = (form, args) => {
  const action = createAction(
    CONSTANTS.GET_TOOLTIP.REQUEST,
    args,
    form,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}

// export const findNext = (form, { indexSearchType, recordName, propertyName }) =>
//   createAction(CONSTANTS.FIND_NEXT.REQUEST, { indexSearchType, propertyName, recordName }, form)

export const getSearchFilters = (form, { indexSearchType, propertyName }) =>
  createAction(
    CONSTANTS.GET_SEARCH_FILTERS.REQUEST,
    { indexSearchType, propertyName },
    null,
    null,
    { form, apiRequest: true }
  )

export const clearFilterField = ({
  form,
  parentField,
  filterType,
  propertyName
}) =>
  createAction(
    CONSTANTS.CLEAR_FILTER_FIELD,
    {
      parentField,
      filterType,
      propertyName
    },
    form
  )

export const setTextFilterField = ({
  form,
  parentField,
  filterType,
  propertyName,
  value
}) =>
  createAction(
    CONSTANTS.SET_TEXT_FILTER_FIELD,
    {
      parentField,
      filterType,
      propertyName,
      value
    },
    form
  )

export const resetFilters = (form, { propertyName }) =>
  createAction(CONSTANTS.RESET_FILTERS, { propertyName }, form)

export const clearSearch = (form, { propertyName }) =>
  createAction(CONSTANTS.CLEAR_SEARCH, { propertyName }, form)
