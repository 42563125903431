import { openScreen } from 'pages/Main/actions'
import createSearch from 'components/Search/createSearch'
import { noop } from 'utils'
import behaviors from './behaviors'
import MobileGrid from '../../grid'

import TextInput from './TextInput'

// import keyToActionMap from './keyToActionMap'
import { getTooltip } from './actions'

const IndexSearch = createSearch({
  name: 'MobileIndexSearch',
  type: 'indexSearch',
  behaviors,
  popoverStyle: {
    minWidth: 400
  },
  DropDownComponent: MobileGrid,
  TextInput,
  mobile: true,
  ContextMenu: props => null
})
// const DDIIndexSearch = props => <Field component={IndexSearch} {...props} />

export default IndexSearch
