/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import nl2br from 'react-newline-to-break'
import { 
  handleModalInteraction as handleModalInteractionAction,
  modalRoutineCompleted as modalRoutineCompletedAction
} from 'pages/SalesOrder/actions'

// export const ModalContent = ({ data: { message, guid, cancelledChanges } }) => (
//   <div>{message}</div>
// )

// ModalContent.propTypes = {
//   data: PropTypes.object.isRequired
// }
const mapDispatchToProps = dispatch => ({
  handleModalInteraction: (form, { guid, value, cancelledChanges = null, cb }) => dispatch(handleModalInteractionAction(form, { guid, value, cancelledChanges, cb })),
  modalRoutineCompleted: form => dispatch(modalRoutineCompletedAction(form))
})

class ModalContent extends Component {

  static propTypes = {
    data: PropTypes.object.isRequired
  }

  render() {
    const { data: { message, guid, cancelledChanges } } = this.props
    return (
      <div>
        <p>{nl2br(message)}</p>
      </div>
    )
  }
}




export default connect(
  null,
  mapDispatchToProps,
  null,
{ forwardRef: true })(ModalContent)



