import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

// import { BASE_INFORM_API_URL } from 'services/constants'

export const AR_INVOICE_SEARCH = '@@ARInvoiceSearch/'
export const POPULATE_FIELD = `${AR_INVOICE_SEARCH}POPULATE_FIELD`
export const BLUR = `${AR_INVOICE_SEARCH}BLUR`
export const FOCUS = `${AR_INVOICE_SEARCH}FOCUS`
export const CLOSE_SEARCH = `${AR_INVOICE_SEARCH}CLOSE_SEARCH`
export const EXACT_MATCH_SEARCH = createAsyncRequestTypes(
  `${AR_INVOICE_SEARCH}EXACT_MATCH_SEARCH`
)
export const AR_INVOICE_EXACT_MATCH_URL = `${BASE_INFORM_API_URL}/search/invoice/exactmatch`
export const GET_TOOLTIP = createAsyncRequestTypes(
  `${AR_INVOICE_SEARCH}GET_TOOLTIP`
)
