import React from 'react'
import { DDIDatePicker } from 'ddiForm/wrapped'

export const CustomDateField = props => {
  return (
    <DDIDatePicker
      {...props}
      inputStyle={{ marginTop: 5 }}
      initialValue={props.value}
      views={['date']}
    />
  )
}

export default CustomDateField
