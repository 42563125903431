// TOGGLE_MOBILE
import { fromJS } from 'immutable'
import { createReducer, getIn, setIn } from 'utils'
import { TOGGLE_MOBILE, TOGGLE_NAV } from './constants'
// function mobile(state = fromJS({}), action) {
//   return state
// }
// export const navOpen = (state = false, { type, payload }) => {
//   if (type === TOGGLE_NAV) {
//     if (payload && payload.flag != null) {
//       return payload.flag
//     }
//     return !state
//   }
//   if (type.includes('layout/ON_ADD') || type.includes('layout/CHANGE_TAB')) {
//     // return false
//     return state === true ? false : state
//   }
//   return state
// }

const behaviors = {
  [TOGGLE_MOBILE]: (state, { payload: { isMobile, ...rest } }) => {
    let temp
    if (isMobile) {
      temp = {
        isMobile,
        ...rest
      }
    }
    if (!isMobile) {
      temp = {
        isIOS: false,
        isAndroid: false,
        mobileModel: null,
        mobileVendor: null,
        isMobile: false,
        isTablet: false,
        isMobileOnly: false
      }
    }
    return fromJS(temp)
  },
  [TOGGLE_NAV]: (state, { payload }) => {
    let result = state
    if (payload && payload.flag != null) {
      result = setIn(result, 'open', payload.flag)
    } else {
      result = setIn(result, 'open', !getIn(state, 'open'))
    }
    return result
  }
}

export default createReducer(fromJS({}), behaviors, true)
