import MaskedInput from 'react-text-mask'
import React from 'react'
import emailMask from 'text-mask-addons/dist/emailMask'

export default function EmailAddressTextField(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      {...other}
      mask={emailMask}
      placeholderChar={'\u2000'}
      showMask={!!props.value}
    />
  )
}
