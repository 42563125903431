import { connect } from 'react-redux'
import { getIn } from 'utils'
import BranchList from './BranchList'
import { selectBranch } from '../../actions'
import { branchListSelector } from '../../selectors'

const mapStateToProps = state => ({
  branchList: branchListSelector(state)
})

const mapDispatchToProps = dispatch => ({
  selectBranch: dataId => {
    dispatch(selectBranch(dataId))
  }
})

const BranchListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchList)

export default BranchListContainer
