/* eslint react/jsx-no-duplicate-props: 0 */
/* dear eslint, inputProps are not the same as InputProps! */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import IndexSearch from '../IndexSearch'
import * as actions from '../IndexSearch/actions'

class IndexSearchCell extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      value: this.props.value || '',
      rowIndex: this.props.rowIndex,
      indexSearchType: this.props.indexSearchType,
      isDirty: false
    }
    this.Component =
      this.props.node.data.companySearchType === 'Free Form'
        ? TextField
        : IndexSearch
  }

  componentDidMount() {
    if (this.field && this.field.select) {
      this.field.select()
    }
  }

  componentWillUnmount() {
    // debugger
    if (
      this.props.node.data.companySearchType === 'Free Form' &&
      this.state.isDirty
    ) {
      if (this.props.onChange) {
        this.props.onChange(this.state.value, this.props.node)
      }
    }
  }

  onChange = value => this.setValue(value)

  onTextChange = e => {
    const { value } = e.target
    this.setState({ value, isDirty: true })
  }

  getValue(p) {
    return this.state.value
  }

  setValue = value =>
    this.setState({ value, isdDirty: true }, () => {
      if (this.props.onChange) {
        this.props.onChange(value, this.props.node)
      }
    })

  ref = c => (this.field = c)

  onKeyDown = (...args) => {
    console.log(...args)
    debugger
  }

  render() {
    console.log(this.props.node.data)
    const { dispatch } = this.props

    const inputStyle =
      this?.props?.node?.data?.companySearchType &&
      this?.props?.node?.data?.companySearchType === 'Free Form'
        ? { height: 26, padding: 0 }
        : null

    return (
      <div style={{ width: '100%', height: 27, position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 27
          }}
        >
          <this.Component
            // indexSearchType={this.state.indexSearchType}
            partialMatchSearch={args =>
              dispatch(actions.partialMatchSearch(null, { ...args }))
            }
            exactMatchSearch={args =>
              dispatch(actions.exactMatchSearch(null, { ...args }))
            }
            setField={this.onChange}
            // onKeyDown={this.onKeyDown}
            onChange={
              this.props.node.data.companySearchType !== 'Free Form'
                ? this.onChange
                : this.onTextChange
            }
            value={this.state.value}
            inputRef={this.ref}
            autoFocus
            meta={{
              allowInstantSearch: true,
              minimumKeywordLength: 2,
              allowSearchAll: false,
              searchType: this.state.indexSearchType // this.props.node.data.companySearchType.toLowerCase() //
            }}
            style={inputStyle}
            InputProps={inputStyle ? { style: inputStyle } : null}
            inputProps={inputStyle ? { style: inputStyle } : null}
          />
        </div>
      </div>
    )
  }
}
// export default IndexSearchCell
export default IndexSearchCell
