import { fromJS } from 'immutable'
import {
  createReducer,
  deleteIn,
  getIn,
  setIn,
  /* splice, */ accessPathMapper,
  toCamelCase
} from 'utils'
import * as MODALS from 'modals/constants'
import * as MAIN_CONSTANTS from 'pages/Main/constants'
import * as DASHBOARD_CONSTANTS from 'pages/Dashboard/constants'
import * as ACCOUNT from 'pages/SignUp/constants'
import * as CONSTANTS from './constants'
// function reduceScreensFromMenu(items = []) {
//   const ret = {}
//   for (let i = 0, len = items.length; i < len; i++) {
//     const item = items[i]
//     if (item.items)
//   }
//   return ret
// }

// function reduceScreensFromMenu(items = []) {
//   const pathMap = {}
//   function helper(itms = []) {
//     for (let i = 0, len = itms.length; i < len; i++) {
//       const item = itms[i]
//       if (item.menuId && item.imageFilename) {
//         pathMap[toCamelCase(item.fullDescription)] = {
//           accessPathOpen: item.accessPathOpen || '',
//           accessPathAdd: item.accessPathAdd || '',
//           requiredLevel: item.requiredLevel,
//           imageFileName: item.imageFilename,
//           imageFileName16: item.imageFilename16,
//           description: item.description,
//           fullDescription: item.fullDescription
//         }
//       }
//       if (item.items) {
//         helper(item.items)
//       }
//     }
//   }
//   helper(items)
//   return pathMap
// }

function reduceScreensFromMenu(items = []) {
  const pathMap = {}
  function helper(itms = []) {
    for (let i = 0, len = itms.length; i < len; i++) {
      const item = itms[i]
      if (item.menuId && item.imageFilename) {
        pathMap[toCamelCase(item.fullDescription)] = {
          accessPathOpen: item.accessPathOpen || '',
          accessPathAdd: item.accessPathAdd || '',
          requiredLevel: item.requiredLevel,
          imageFileName: item.imageFilename,
          imageFileName16: item.imageFilename16,
          description: item.description,
          fullDescription: item.fullDescription
        }
      }
      if (item.items) {
        helper(item.items)
      }
    }
  }
  helper(items)
  pathMap.truckMaster = {
    accessPathOpen: '',
    accessPathAdd: '',
    requiredLevel: '',
    imageFileName: 'warehouse_truck_manifest_32.png',
    imageFileName16: 'warehouse_truck_manifest_16.png',
    description: 'Truck Master',
    fullDescription: 'Truck Master'
  }
  return pathMap
}

const initialState = fromJS({
  accessLevelsDisplay: null,
  accessPathList: [],
  branch: {},
  branchOptionsWithAddress: [],
  branches: {},
  common: {},
  company: {},
  configuration: {},
  dataId: null,
  databaseVersion: null,
  databaseVersionDate: null,
  ddiActionMenu: {},
  ddiFastClickMenu: { items: [] },
  defaultBranchId: null,
  description: null,
  email: null,
  hasDefaultBranchId: false,
  isAdministrator: false,
  isAuthenticated: false,
  isAuthenticating: false,
  posRegister: null,
  posRegisterAsString: '',
  salesmenDisplay: null,
  selectedBranchId: null,
  stationDisplay: null,
  stationId: null,
  statusBar: {
    accessLevel: null,
    accessLevelTooltip: null,
    branchId: null,
    branchIdTooltip: null,
    companyName: null,
    companyNameTooltip: null,
    posRegister: null,
    posRegisterTooltip: null,
    selectedTerminal: null,
    selectedTerminalTooltip: null,
    stationId: null,
    stationIdTooltip: null,
    username: null,
    usernameTooltip: null,
    version: null,
    versionTooltip: null,
    mailDisabled: false
  },
  statusText: null,
  token: null,
  tokenExpiration: null,
  userName: null
})

const behaviors = {
  [CONSTANTS.CLEAR_STATUS]: state => state.set('statusText', ''),
  [CONSTANTS.TOKEN.REQUEST]: (state, { payload }) =>
    state.merge({
      isAuthenticating: true,
      statusText: null
    }),
  [CONSTANTS.TOKEN.SUCCESS]: (state, { payload }) =>
    state.merge({
      token: payload.access_token,
      refreshToken: payload.refresh_token,
      tokenExpiration: payload['.expires'],
      userName: payload.userName
    }),
  [CONSTANTS.TOKEN.FAILURE]: (state, { payload }) => {
    return initialState.set(
      'statusText',
      `Login Failed: ${payload.message || 'Error receiving Token.'}`
    )
  },
  [CONSTANTS.LOGOUT_USER]: (state, { payload }) =>
    state.set(
      'statusText',
      payload.status
        ? `${payload.status}: ${payload.statusText}`
        : payload.statusText
    ),
  // state
  //   .merge({
  //     isAuthenticated: false,
  //     isAuthenticating: false,
  //     statusText: payload.status ? `${payload.status}: ${payload.statusText}` : payload.statusText,
  //     tokenExpiration: null,
  //     userName: null,
  //   })

  [CONSTANTS.SUBMIT_LOGOUT_USER.REQUEST]: (state, { payload }) => {
    let result = state
    result = setIn(
      result,
      'statusText',
      payload.status
        ? `${payload.status}: ${payload.statusText}`
        : payload.statusText
    )
    result = setIn(result, 'isAuthenticated', false)
    return result
  },
  [CONSTANTS.SUBMIT_LOGOUT_USER.SUCCESS]: (state, { payload }) => {
    // initialState.set('statusText', state.get('statusText'))
    const statusText = payload.status || state.get('statusText')
    return initialState.set(
      'statusText',
      statusText || 'You have been successfully logged out.'
    )
    // initialState
    //   .set('statusText', state.get('statusText') ? state.get('statusText') : 'You have been successfully logged out.')
  },
  // [ACCOUNT.CHANGE_PASSWORD.SUCCESS]: () => initialState,
  [CONSTANTS.SUBMIT_LOGOUT_USER.FAILURE]: (state, { payload }) =>
    initialState.set('statusText', `Logout Failed: ${payload.message}`),
  [CONSTANTS.CREATE_SYSTEM_USER.SUCCESS]: (state, { payload }) => {
    let result = state
    result = result.merge({
      ...payload.systemUser,
      branches: payload.entities.branches
    })
    if (payload.systemUser.branch) {
      result = setIn(
        result,
        'selectedBranchId',
        payload.systemUser.branch.recordName
      )
    }
    return result
  },
  [CONSTANTS.CREATE_SYSTEM_USER.FAILURE]: (state, { payload }) =>
    state.merge({
      hasSystemUser: false,
      isAuthenticated: false,
      isAuthenticating: false,
      statusText: `Login Failed: ${payload.message}`,
      token: null,
      refreshToken: null
    }),
  [CONSTANTS.LOGIN_COMPLETE]: (state, { payload }) => {
    let result = state

    const len = getIn(result, 'accessPathList').size // eslint-disable-line
    // console.log(len)

    const mainMenu = getIn(result, 'ddiMainMenu.items').toJS()
    const screensMap = reduceScreensFromMenu(mainMenu)
    const accessPathList = accessPathMapper(mainMenu)
    result = setIn(
      result,
      'accessPathList',
      fromJS({ ...getIn(result, 'accessPathList').toJS(), ...accessPathList })
    )
    result = setIn(result, 'screensMap', fromJS(screensMap))
    result = result.merge({
      isAuthenticated: true,
      isAuthenticating: false
    })
    return result
  },
  [CONSTANTS.CHECK_TOKEN_EXPIRATION]: (state, { payload }) =>
    state.set('isAuthenticating', true),
  [CONSTANTS.NO_TOKEN]: (state, { payload }) => initialState,
  [CONSTANTS.CLEAR_SELECTED_BRANCH]: (state, { payload }) =>
    state.set('branch', null),
  [CONSTANTS.REMOVE_TOKEN]: (state, { payload }) => {
    let result = state
    result = deleteIn(result, 'token')
    result = deleteIn(result, 'refreshToken')
    result = deleteIn(result, 'tokenExpiration')
    result = deleteIn(result, 'userName')
    return result
  },

  [CONSTANTS.SELECT_BRANCH]: (state, { payload }) =>
    state.set('selectedBranchId', payload.dataId),
  [CONSTANTS.SET_POS_REGISTER_NUM]: (state, { payload }) =>
    state.set(
      'posRegister',
      typeof payload.registerNum === 'number' ? payload.registerNum : null
    ),
  [CONSTANTS.SET_CARD_TERMINAL]: (state, { payload }) =>
    state.set('cardTerminal', payload.dataId),
  [CONSTANTS.SUBMIT_SELECTED_BRANCH.SUCCESS]: (state, { payload }) =>
    state.merge({ ...payload }),
  [CONSTANTS.SUBMIT_SELECTED_REGISTER.SUCCESS]: (state, { payload }) =>
    state.merge({ ...payload }),
  [CONSTANTS.SUBMIT_SELECTED_CARD_TERMINAL.SUCCESS]: (state, { payload }) =>
    state.merge({ ...payload }),
  // [CONSTANTS.TRY_REQUEST_ACCESS]: (state, { payload: { name } }) => {
  //   let result = state
  //   result = setIn(result, `accessPathList.${name}.requestingAccess`, true)
  //   result = deleteIn(result, `accessPathList.${name}.oneTimeAccess`)
  //   result = deleteIn(result, `accessPathList.${name}.credentialsFailed`)
  //   return result
  // },
  // [CONSTANTS.ACCESS_GRANTED]: (state, { payload: { name, } }) => {
  //   let result = state
  //   result = deleteIn(result, `accessPathList.${name}.requestingAccess`)
  //   result = setIn(result, `accessPathList.${name}.oneTimeAccess`, true)
  //   return result
  // },
  // [CONSTANTS.ACCESS_GRANTED]: (state, { payload: { name, response } }) => {
  //   let result = state
  //   result = deleteIn(result, `accessPathList.${name}.requestingAccess`)
  //   result = setIn(result, `accessPathList.${name}.oneTimeAccess`, true)
  //   result = setIn(
  //     result,
  //     `accessPathList.${name}.response`,
  //     response ? fromJS(response) : fromJS({})
  //   )
  //   return result
  // },
  // [CONSTANTS.ACCESS_DENIED]: (state, { payload: { name } }) => {
  //   let result = state
  //   result = deleteIn(result, `accessPathList.${name}.requestingAccess`)
  //   result = setIn(result, `accessPathList.${name}.credentialsFailed`, true)
  //   return result
  // },
  // [CONSTANTS.REVOKE_ONE_TIME_ACCESS]: (state, { payload: { name } }) => {
  //   let result = state
  //   result = deleteIn(result, `accessPathList.${name}.oneTimeAccess`)
  //   return result
  // },
  [CONSTANTS.AUTHENTICATION_ERROR]: (
    state,
    { payload: { message = 'Error: Authentication failed.' } }
  ) => initialState.set('statusText', message),
  [MAIN_CONSTANTS.TOGGLE_FAST_CLICK.REQUEST]: (
    state,
    { payload: { menuId } }
  ) => {
    let result = state
    let items = getIn(result, 'ddiFastClickMenu.items').toJS()
    // let activeMenu = ''
    /*
    for (let i = 0; i < items.length; i++) {
      if (items[i].menuId === menuId) {
        activeMenu = items[i].menuGroup
        break
      }
    }
    */

    /* we're gonna be optimistic about this */
    items = items.reduce((acc, next) => {
      if (next.menuId === menuId) {
        acc = acc.concat({
          ...next,
          isSelected: !next.isSelected
        })
      } else {
        acc = acc.concat(next)
      }
      return acc
    }, [])

    /* optimistically set as selected */
    // result = setIn(result, 'ddiFastClickMenu.activeItem', menuId)
    result = setIn(result, 'ddiFastClickMenu.items', fromJS(items))

    /* added to prevent collapsing the active menu that the user is interacting with */
    // result = setIn(result, 'ddiFastClickMenu.activeMenu', activeMenu)
    return result
  },
  [MAIN_CONSTANTS.TOGGLE_FAST_CLICK.SUCCESS]: (state, { payload }) => {
    let result = state
    // const activeMenu = getIn(result, 'ddiFastClickMenu.activeMenu')
    result = setIn(result, 'ddiFastClickMenu', fromJS(payload.ddiFastClickMenu))
    return result
  },
  [MAIN_CONSTANTS.RESET_ITEM_SELECTOR]: (state, action) => {
    let result = state
    /* remove the activeMenu when the itemSelector popover is closed */
    result = setIn(result, 'ddiFastClickMenu.activeMenu', '')
    return result
  },
  [MAIN_CONSTANTS.RESET_FAST_CLICK.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'ddiFastClickMenu', fromJS(payload.ddiFastClickMenu))
    return result
  },
  [DASHBOARD_CONSTANTS.INIT]: (state, { payload }) => {
    let result = state
    const accessPathList = accessPathMapper(payload.dashboards)
    result = setIn(result, 'accessPathList', fromJS(accessPathList))
    return result
  },
  [MAIN_CONSTANTS.TOGGLE_MAIL.TRY]: (state, action) => {
    let result = state
    result = setIn(
      result,
      'mailNotificationsEnabled',
      !getIn(result, 'mailNotificationsEnabled')
    )
    return result
  },

  [MAIN_CONSTANTS.TOGGLE_MAIL.SUCCESS]: (
    state,
    { payload: { mailNotificationsEnabled } }
  ) => {
    let result = state
    result = setIn(result, 'mailNotificationsEnabled', mailNotificationsEnabled)
    return result
  },
  [MAIN_CONSTANTS.TOGGLE_MAIL.FAILURE]: (state, action) => {
    let result = state
    result = setIn(
      result,
      'mailNotificationsEnabled',
      !getIn(result, 'mailNotificationsEnabled')
    )
    return result
  },
  [ACCOUNT.CHANGE_PASSWORD.SUCCESS]: (state, action) => {
    let result = state
    if (!action?.payload?.flag) {
      result = setIn(result, 'isAuthenticating', false)
    }
    return result
  },
  [MAIN_CONSTANTS.NEWS_FEED.SUCCESS]: (state, action) => {
    let result = state
    if (action?.payload?.newsfeed?.data) {
      result = setIn(result, 'newsfeed', fromJS(action.payload.newsfeed))
    }
    return result
  },
  [ACCOUNT.RESET_STATUS]: state => state.set('statusText', ''),
  [CONSTANTS.STOP_AUTH]: state => state.set('isAuthenticating', false)
}

export default createReducer(initialState, behaviors, true)
