import React, { memo } from 'react'
import { MenuItem, Select, TextField } from '@material-ui/core'
import DDISendInfoSearch from 'ddiForm/wrapped/DDISendInfoSearch'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Content = ({
  body,
  style,
  styles,
  Wrapper,
  onModelChange,
  onSelectChange,
  sendType,
  onChange,
  fromCompany,
  fromName,
  sendTo,
  data = {},
  sendInfoSearch,
  subject,
  mobileState
}) => {
  const mobileStateObj =
    mobileState && mobileState?.toJS
      ? mobileState.toJS()
      : { isMobile: false, isTablet: false }
  const isMobile = mobileStateObj?.isMobile || false

  return (
    <div style={style}>
      <div style={styles.flexContainer}>
        <Wrapper title="Send Type">
          <div style={styles.flexContainer}>
            <div style={styles.left}>Fax / Email:</div>
            <div style={styles.right}>
              <Select onChange={onSelectChange} value={sendType}>
                <MenuItem value="Email">Email</MenuItem>
                <MenuItem value="Fax">Fax</MenuItem>
              </Select>
            </div>
          </div>
        </Wrapper>
      </div>
      <div style={styles.flexContainer}>
        <Wrapper title="Send From">
          <div style={styles.flexContainer}>
            <div style={{ ...styles.half, alignItems: 'baseline' }}>
              <div style={{ ...styles.left, minWidth: 65 }}>Company:</div>
              <div style={styles.right}>
                <TextField
                  onChange={onChange('fromCompany')}
                  value={fromCompany}
                  style={{ minWidth: 150 }}
                />
              </div>
            </div>
            <div style={{ ...styles.half, alignItems: 'baseline' }}>
              <div style={{ ...styles.left, minWidth: 65 }}>Name:</div>
              <div style={styles.right}>
                <TextField
                  onChange={onChange('fromName')}
                  value={fromName}
                  style={{ minWidth: 150 }}
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
      <div style={styles.flexContainer}>
        <Wrapper title="Send To">
          <div style={styles.flexContainer}>
            <DDISendInfoSearch
              propertyName="sendInfoSearch"
              sendType={sendType}
              initialValue={sendTo}
              meta={data.meta}
              fullWidth
              preventAutoDisable
              searchRef={c => (sendInfoSearch = c)}
              wrapperStyle={{
                position: 'static',
                clear: 'none',
                maxWidth: '100%',
                width: '100%'
              }}
              mobileState={mobileStateObj}
            />
          </div>
        </Wrapper>
      </div>
      <div style={styles.flexContainer}>
        <Wrapper title="Content">
          <div className="send-document-content-editor-wrapper">
            <div style={{ ...styles.flexContainer, alignItems: 'center' }}>
              <div style={{ width: 100 }}>Subject:</div>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  onChange={onChange('subject')}
                  value={subject}
                  fullWidth={isMobile}
                />
              </div>
            </div>
            <div
              style={{
                ...styles.flexContainer,
                flexDirection: 'column',
                marginTop: 10
              }}
            >
              <div style={{ width: 100, marginBottom: isMobile ? 5 : 0 }}>
                {sendType === 'Email' ? 'Body:' : 'Comments:'}
              </div>
              <div
                style={{
                  flexGrow: 1,
                  padding: 0,
                  width: isMobile ? '100%' : 'auto'
                }}
              >
                {sendType === 'Email' ? (
                  <ReactQuill value={body} onChange={onModelChange} />
                ) : (
                  <TextField
                    value={body}
                    onChange={onChange('body')}
                    multiline
                    rows={isMobile ? 4 : 20}
                    variant="outlined"
                    fullWidth={isMobile}
                  />
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  )
}

Content.defaultProps = {
  style: {
    padding: 5,
    background: 'white',
    display: 'flex',
    flexDirection: 'column'
  }
}

export default memo(Content)
