import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

/* start TEMPLATE API calls */
export const readTemplate = ({ form, ...args }) => {
  const options = createApiOptions({
    body: args,
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/template/read`, options)
}

export const lockTemplate = ({ form, ...args }) => {
  const options = createApiOptions({
    body: args,
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/template/editlock`, options)
}

export const unlockTemplate = ({ form, ...args }) => {
  const options = createApiOptions({
    body: args,
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${form}/template/canceledit`,
    options
  )
}

export const deleteTemplate = ({ templateKey, form }) => {
  const options = createApiOptions({
    body: { templateKey },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/template/delete`, options)
}

// export const updateTemplate = ({ form, ...args }) => {
//   const options = createApiOptions({
//     body: args,
//     method: 'post'
//   })

//   return callFetch(`${BASE_INFORM_API_URL}/${form}/template/update`, options)
// }
