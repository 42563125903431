import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

// console.log(CONSTANTS)
// export const foo = () => ({ type: 'foo', payload: {} })
// export const foo = (propertyName, form) => {
//   // console.log(payload, form)
//   const ac = createAction(CONSTANTS.FOO, { propertyName }, form)
//   return ac
// }

export const tabChange = (form, propertyName, value) =>
  createAction(CONSTANTS.TAB_CHANGE, { value, propertyName }, form)

// export const setField = (field, value, propertyName, deleteMeta = false, form = null) =>
//   createAction(CONSTANTS.SET_FIELD, { field, value, propertyName, deleteMeta }, form)

export const find = (form, propertyName, keyword) =>
  createAction(CONSTANTS.FIND.REQUEST, { keyword, propertyName }, form, null, {
    thunk: true
  })
// export const open = form => createAction(CONSTANTS.OPEN.REQUEST, {}, form)

export const blur = (form, { propertyName, subProperty }) =>
  createAction(CONSTANTS.BLUR, { propertyName, subProperty }, form)

export const focus = (form, { propertyName, subProperty }) =>
  createAction(CONSTANTS.FOCUS, { propertyName, subProperty }, form)

export const exactMatch = (form, { propertyName, keyword }) =>
  createAction(
    CONSTANTS.EXACT_MATCH.REQUEST,
    { propertyName, keyword },
    form,
    null,
    { thunk: true, apiRequest: true }
  )

export const rowSelected = (form, selectedRows = []) =>
  createAction(CONSTANTS.ROW_SELECTED, { selectedRows }, form)

// export const disableFields = (form, fields = []) =>
//   createAction(CONSTANTS.DISABLE_FIELDS, { fields }, form)
// export const enableFields = (form, fields = []) =>
//   createAction(CONSTANTS.ENABLE_FIELDS, { fields }, form)

// export const open = form => createAsyncAction(CONSTANTS.OPEN, form)

// export const executeInquiry = form => createAction(CONSTANTS.EXECUTE_INQUIRY.REQUEST, {}, form)
export const reset = form => createAction(CONSTANTS.RESET, {}, form)

export const open = form => {
  const ret = createAction(CONSTANTS.OPEN.REQUEST, {}, form, null, {
    thunk: true
  })
  return ret
}
// export const blur = ()
export const setFlagged = (form, bool) =>
  createAction(CONSTANTS.FLAGGED, { bool }, form)
export const openDropDown = form =>
  createAction(CONSTANTS.OPEN_DROPDOWN.REQUEST, {}, form, null, { thunk: true })
export const executeInquiry = createAsyncFormAction(CONSTANTS.EXECUTE_INQUIRY)

export const loadCustomerRecord = form =>
  createAction(CONSTANTS.LOAD_CUSTOMER_RECORD, {}, form)

export const checkForSearchParamsOnLoad = form =>
  createAction(CONSTANTS.CHECK_FOR_SEARCH_PARAMS_ON_LOAD, {}, form)

export const updateParamsAndExecuteSalesOrderInquiry = (
  form,
  { customer, product, status }
) =>
  createAction(
    CONSTANTS.UPDATE_PARAMS_AND_EXECUTE_SALES_ORDER_INQUIRY,
    {
      data: {
        customer,
        product,
        status
      }
    },
    form
  )

export const printSalesOrders = createAsyncFormAction(
  CONSTANTS.PRINT_SALES_ORDERS
)

export const clearResults = form =>
  createAction(CONSTANTS.CLEAR_RESULTS, {}, form)

export const viewReport = createAsyncFormAction(CONSTANTS.VIEW_REPORT)

export const updateMasterDetailColDefs = (form, { colDefs = [] }) =>
  createAction(CONSTANTS.UPDATE_MASTER_DETAIL_COL_DEFS, { colDefs }, form)
