/* eslint react/sort-comp: 0 */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { confirm, modalAction, removeModal } from 'modals/actions'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import * as customerLedgerActions from 'pages/CustomerMaster/tabs/Ledger/lib/actions'
import { setField } from 'ddiForm/actions'
import * as actions from './actions'

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formData = getIn(state, `ddiForm.${form}`)
  return {
    appendToFinanceCharge:
      getIn(
        formData,
        'values.customerLedger.ledgerNotesModal.appendToFinanceCharge'
      ) || false,
    notesModalEnabled: getIn(formData, 'notesModalEnabled') || false
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { form } = ownProps
  return {
    lockLedgerNotes: ({ dataId, type }) =>
      dispatch(
        customerLedgerActions.lockLedgerNotes.try('customerMaster', {
          dataId,
          type
        })
      ),
    saveLedgerNotes: ({
      appendToFinanceCharge,
      dataId,
      detailGrid,
      field,
      gridName,
      id,
      note,
      parentId,
      action,
      type
    }) =>
      dispatch(
        customerLedgerActions.saveLedgerNotes.try('customerMaster', {
          appendToFinanceCharge,
          dataId,
          detailGrid,
          field,
          gridName,
          id,
          note,
          parentId,
          action,
          type
        })
      ),
    unlockLedgerNotes: ({ dataId, type }) =>
      dispatch(
        customerLedgerActions.unlockLedgerNotes.try('customerMaster', {
          dataId,
          type
        })
      ),

    deleteInternalNotes: () => dispatch(setField(form, 'internalNotes', '')),
    lockInternalNotes: () => dispatch(actions.lockInternalNotes.try(form)),
    saveInternalNotes: ({ action }) =>
      dispatch(actions.saveInternalNotes.try(form, { action })),
    unlockInternalNotes: () => dispatch(actions.unlockInternalNotes.try(form)),
    displayInternalNotes: () => dispatch(actions.displayInternalNotes(form))
  }
}

class NotesModalActions extends Component {
  static propTypes = {
    appendToFinanceCharge: PropTypes.bool.isRequired,
    deleteInternalNotes: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    lockLedgerNotes: PropTypes.func.isRequired,
    lockInternalNotes: PropTypes.func.isRequired,
    modal: PropTypes.object.isRequired,
    notesModalEnabled: PropTypes.bool.isRequired,
    saveLedgerNotes: PropTypes.func.isRequired,
    saveInternalNotes: PropTypes.func.isRequired,
    unlockLedgerNotes: PropTypes.func.isRequired,
    unlockInternalNotes: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      type: props.modal.options.data.type
        ? props.modal.options.data.type
        : 'internalNotes'
    }
  }

  closeNotesModal = () => {
    if (this?.props?.form && this?.props?.modal?.id) {
      this.props.dispatch(removeModal(this.props.form, this.props.modal.id))
    }

    this.props.dispatch(actions.notifyInternalNotesModalClosed(this.props.form))
  }

  componentDidMount() {
    this.props.displayInternalNotes(this.props.form)
  }

  unlockNotesModal = () => {
    const { type } = this.state
    const modalState = this.props.modalRef[this.props.modal.id].state

    if (type === 'internalNotes') {
      this.props.unlockInternalNotes()
    }

    if (type === 'disputedNotes' || type === 'onAccountNote') {
      this.props.unlockLedgerNotes({
        dataId: modalState.dataId,
        type: modalState.type
      })
    }
  }

  enableNotesModal = () => {
    const modalState = this.props.modalRef[this.props.modal.id].state
    const { type } = this.state
    if (type === 'internalNotes') {
      this.props.lockInternalNotes()
    }

    if (type === 'disputedNotes' || type === 'onAccountNote') {
      this.props.lockLedgerNotes({
        dataId: modalState.dataId,
        type: modalState.type
      })
    }
  }

  saveNotes = () => {
    // debugger
    const { type } = this.state
    const modalState = this.props.modalRef[this.props.modal.id].state
    if (type === 'internalNotes') {
      this.props.saveInternalNotes({ action: 'save' })
    }

    if (type === 'disputedNotes' || type === 'onAccountNote') {
      this.props.saveLedgerNotes({
        action: 'save',
        appendToFinanceCharge: modalState.appendToFinanceCharge,
        dataId: modalState.dataId,
        detailGrid: modalState.detailGrid,
        field: 'disputedNote',
        gridName: modalState.gridName,
        id: modalState.id,
        note: modalState.note,
        parentId: modalState.parentId,
        type: modalState.type
      })
    }
  }

  deleteNotes = () => {
    const { type } = this.state
    const modalState = this.props.modalRef[this.props.modal.id].state

    if (type === 'internalNotes') {
      // this.props.deleteInternalNotes()
      this.props.saveInternalNotes({ action: 'delete' })
    }

    if (type === 'disputedNotes' || type === 'onAccountNote') {
      this.props.saveLedgerNotes({
        action: 'delete',
        dataId: modalState.dataId,
        detailGrid: modalState.detailGrid,
        field: 'disputedNote',
        gridName: modalState.gridName,
        id: modalState.id,
        note: '',
        parentId: modalState.parentId,
        type: modalState.type
      })
    }
  }

  getModalButtons = () => {
    const { type } = this.state
    const { notesModalEnabled } = this.props

    const buttons =
      (notesModalEnabled && type === 'internalNotes') ||
      ((notesModalEnabled && type === 'disputedNotes') ||
        (notesModalEnabled && type === 'onAccountNote')) ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={this.saveNotes}
            style={{ marginRight: 5 }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.deleteNotes}
            style={{ marginRight: 5 }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.unlockNotesModal}
          >
            Exit
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={this.enableNotesModal}
            style={{ marginRight: 5 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.closeNotesModal}
          >
            Exit
          </Button>
        </>
      )

    return buttons
  }

  render() {
    return this.getModalButtons()
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(NotesModalActions)
