import createAction from 'utils/createAction'
import is from 'utils/is'
import uuid from 'uuid'
import {
  HIDE_MODAL,
  CANCELED,
  REMOVE_MODAL,
  ADD_MODAL,
  CONFIRMED,
  MODAL_ACTION,
  CLEAR_MODALS
} from './constants'

// export const modalAction = (form, action, saga, callback, args) =>
//   createAction(MODAL_ACTION, { action, args, callback, saga }, form)
export const confirm = (form, id, opts) =>
  createAction(CONFIRMED, { id, ...opts }, null, null, { form, modal: true })
// export const canceled = (form, id, accessId = null, override = false) =>
//   !accessId
//     ? createAction(CANCELED, { id, override }, null, null, {
//       form,
//       modal: true
//     })
//     : createAction(CANCELED, { accessId, id, override }, null, null, {
//       form,
//       modal: true
//     })

/* added opts to this canceled action for SalesOrder/components/DuplicateProductsModalActions SVE - 10/29/19 */
export const canceled = (form, id, accessId = null, override = false, opts) =>
  createAction(CANCELED, { accessId, id, override, ...opts }, null, null, {
    form,
    modal: true
  })
export const addModal = (form, obj) => {
  if (!is.string(form) && !is.undefined(form)) {
    obj = form
    form = undefined
  }
  obj = {
    ...obj,
    id: uuid.v1()
  }

  return createAction(ADD_MODAL, obj, null, null, {
    form,
    modal: true
  })
}

// export const addModal = (form, obj) => {
//   debugger
//   if (typeof form !== 'string') {
//     obj = form
//     form = undefined
//   }
//   obj = {
//     ...obj,
//     id: uuid.v1()
//   }
//   return createAction(ADD_MODAL, { ...obj }, null, null, {
//     form,
//     modal: true
//   })
// }

// how to hide with form etc now?!?
// export const hideModal = id => createAction(HIDE_MODAL, { id })
export const hideModal = (form, id) => {
  if (!id) {
    id = form
    form = undefined
  }
  return createAction(HIDE_MODAL, { id }, null, null, { form, modal: true })
}
// export const removeModal = (form, id) => {
//   if (arguments.length === 1) {
//     id = form
//     form = undefined
//   }
//   return createAction(REMOVE_MODAL, { id })
// }

export const removeModal = (form, id) => {
  if (!id) {
    id = form
    form = undefined
  }
  return createAction(REMOVE_MODAL, { id }, null, null, { form, modal: true })
}
export const clearModals = form =>
  createAction(CLEAR_MODALS, {}, null, null, { form, modal: true })

export const modalAction = (form, clickEvent) =>
  createAction(MODAL_ACTION, { ...clickEvent }, null, null, {
    form,
    modal: true
  })
