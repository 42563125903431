// import { getIn } from 'utils'
// import { ButtonBase } from '@material-ui/core'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import {
  CLOSE_SEARCH,
  CLOSE_FILTERS_GRID,
  // HANDLE_FILE_UPLOAD
} from './constants'

import {
  blur,
  exactMatchSearch,
  partialMatchSearch,
  resetFilters as resetFiltersAction,
  toggleFilter as toggleFilterAction,
  clearSearch,
  handleFileUpload,
  handleFileDownload
} from './actions'

import baseBehaviors from '../baseBehaviors'

export default {
  ...baseBehaviors,
  blur(value) {
    this.props.dispatch(
      blur(this.form, {
        propertyName: this.props.propertyName,
        value,
        isSet: this.state.isSet
      })
    )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        this.props.onChange('')
      }
    }
  },
  clearSearch() {
    // //
    if (!this.props.getFormState) return
    this.props.dispatch(
      clearSearch(this.form, {
        propertyName: this.props.propertyName
      })
    )
  },
  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select() {
        target.select()
      },
      paste() {
        // Chrome does not allow retrieval of clipboard data
        // document.addEventListener('paste', function (evt) {
        //   const data = evt.clipboardData.getData('text')
        //   console.log('DATA', data)
        // })
        // target.focus()
        // document.execCommand('paste')
      },
      delete() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        const { openScreenData } = this.props
        this.props.dispatch(openScreenAction(openScreenData))
      }
    }
    return (action[data.type] || action.select)()
  },
  focusField() {
    return (
      this.textField &&
      this.setState(
        { isFocused: true },
        () => ((this.autofocused = true), this.textField.focus())
      )
    )
  },
  // getButtonStyle() {
  //   let { buttonStyle } = this.props
  //   if (this.props.disabled) {
  //     buttonStyle = {
  //       ...buttonStyle,
  //       background: '#000',
  //       opacity: 0.12
  //     }
  //   }
  //   return buttonStyle
  // },
  // getDisplayValue() {
  //   if (this.props.displayDescription && !this.state.isFocused) {
  //     return this.state.description
  //   }
  //   return this.state.value || ''
  // },

  getRowNodeId(data) {
    return data.recordName
  },

  // what is this? put this somewhere else...

  getGridHeight() {
    const { grid } = this.props
    return grid && grid.rowData && grid.rowData.length
      ? grid.rowData.length * 27 + 52
      : 150
  },

  // getValue() {
  //   return this.state.value
  // },
  // async onBlur(event) {
  //   if (event) event.persist()
  //   if (this.state.isOpen || this.props.isFetching) return
  //   await this.setState({ isFocused: false })
  //   if (!this.state.isSet && this.state.isDirty) {
  //     this.setState(
  //       {
  //         value: '',
  //         description: '',
  //         isDirty: false
  //       },
  //       () => this.blur(this.state.value)
  //     )
  //   } else {
  //     this.blur(this.state.value)
  //   }
  // },
  // onCellSelected(event) {
  //   this.setField(event.data.dataId)
  //   this.blur(this.state.value)
  //   this.onRequestClose()
  // },
  async onCellSelected(event) {
    const {
      propertyName,
      warehouseId,
      dataId,
      documentType,
      apiArgs
    } = this.props
    try {
      // debugger

      const args = apiArgs
        ? {
            ...apiArgs,
            keyword: event.data.dataId,
            propertyName,
            documentType,
            ignoreGetApiParams: true
          }
        : {
            keyword: event.data.dataId,
            propertyName,
            dataId,
            documentType
          }
      // debugger
      const p = await this.props.dispatch(
        exactMatchSearch(this.props.form || this.form, args)
      )
      // debugger

      if (p.matchFound) {
        this.setState(
          {
            value: p.result.dataId,
            description: p.result.description || '',
            isSet: true,
            isDirty: false,
            isOpen: false,
            tab: false
          },
          () => {
            this.setField(p.result.dataId)
            this.blur(this.state.value)
          }
        )
      } else {
        this.setState({
          value: '',
          isSet: false,
          isDirty: false,
          isOpen: false,
          tab: false
        })
      }
    } catch (e) {
      this.onRequestClose()
    }
  },
  // async onCellSelected(event) {
  //   const { propertyName, warehouseId, dataId, documentType } = this.props
  //   try {
  //     debugger
  //     const p = await this.props.dispatch(
  //       exactMatchSearch(this.form, {
  //         keyword: event.data.dataId,
  //         propertyName,
  //         dataId,
  //         documentType
  //       })
  //     )
  //     // debugger

  //     if (p.matchFound) {
  //       this.setState(
  //         {
  //           value: p.result.dataId,
  //           description: p.result.description || '',
  //           isSet: true,
  //           isDirty: false,
  //           isOpen: false,
  //           tab: false
  //         },
  //         () => {
  //           this.setField(p.result.dataId)
  //           this.blur(this.state.value)
  //         }
  //       )
  //     } else {
  //       this.setState({
  //         value: '',
  //         isSet: false,
  //         isDirty: false,
  //         isOpen: false,
  //         tab: false
  //       })
  //     }
  //   } catch (e) {
  //     this.onRequestClose()
  //   }
  // },
  async onChange(event) {
    this.timeout = this.timeout || 0
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    event.persist()
    const v = event.target.value
    const callPartial = () => {
      this.timeout = setTimeout(
        () => this.partialMatchSearch(v),
        this.props.timer || 500
      )
    }

    const {
      meta: { allowInstantSearch, minimumKeywordLength }
    } = this

    await this.setState({
      isDirty: true,
      value: v,
      // lastSearch: event.target.value,
      isSet: false
    })
    if (allowInstantSearch && v.length >= (minimumKeywordLength || 2)) {
      this.setState(
        {
          lastSearch: event.target.value,
          value: event.target.value
        },
        callPartial()
      )
    } else if (v.length === 0) {
      this.clearSearch()
      this.setField('', true)
    }
  },
  onCloseFiltersGrid() {
    this.setState({ mouseover: false }, () => {
      if (this.props.getFormState) {
        this.props.dispatch({
          type: CLOSE_FILTERS_GRID,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
    })
  },
  // onFocus(event) {
  //   event.persist()
  //   this.setState(
  //     {
  //       isFocused: true,
  //       mouseover: false
  //     },
  //     () => {
  //       // console.log(this, this.props)
  //       // if (this.state.isSet) {
  //       if (this.textField) this.textField.select()
  //       // }
  //       if (this.props.onFocus) {
  //         this.props.onFocus(event)
  //       }
  //     }
  //   )
  // },

  onKeyDown(event) {
    event.persist()
    const val = event.target.value
    /* this is for New Event Type popover */
    if (this.props.disableBaseOnTabFunctionality && this.props.onKeyDown) {
      this.props.onKeyDown(event)
      return
    }

    // check if tooltip is open and keys match etc.. great :-(
    if (event.key === 'Tab') {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (this.state.isSet || !this.state.value) {
        return
      }
      event.preventDefault()
      // event.preventDefault()
      // event.target.blur()

      const { propertyName, dataId, uom, documentType, apiArgs } = this.props
      let args
      this.setState(
        {
          isOpen: false,
          value: val,
          lastSearch: val,
          tab: true
        },
        async () => {
          try {
            args = apiArgs
              ? {
                  ...apiArgs,
                  keyword: this.state.value,
                  ignoreGetApiParams: true,
                  propertyName,
                  documentType
                }
              : {
                  keyword: this.state.value,
                  propertyName,
                  dataId,
                  documentType
                }
            debugger
            const p = await this.props.dispatch(
              exactMatchSearch(this.props.form || this.form, args)
            )

            if (p.matchFound) {
              this.setState(
                {
                  value: p.result.dataId,
                  description: p.result.description || '',
                  isSet: true,
                  isDirty: false,
                  isOpen: false,
                  tab: false
                },
                () => {
                  this.setField(p.result.dataId)
                  this.blur(this.state.value)
                }
              )
            } else {
              this.setState({
                value: '',
                isSet: false,
                isDirty: false,
                isOpen: false,
                tab: false
              })
            }
          } catch (e) {
            this.setState({ isSet: false, isDirty: true, tab: false })
          }
        }
      )
    }
  },

  onMouseLeave(event) {
    event.persist()
    this.setState({ mouseover: false }, () => console.log('mouseout'))
  },

  onMouseOver(event) {
    event.persist()
    this.setState({ mouseover: true }, () => console.log('mouseover'))
  },
  onRequestClose() {
    // console.log('request close', this.state, this.props)
    this.setState({ isOpen: false, mouseover: false }, () => {
      // this.onBlur()
      if (this.props.getFormState) {
        this.props.dispatch({
          type: CLOSE_SEARCH,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
      if (this.props.onRequestClose) {
        this.props.onRequestClose()
      }
      // console.log(this.state)S
    })
  },

  onSearchClick(event) {
    this.setState(
      {
        // isOpen: true,
        // dropDownProps: {}
        isSet: false
      },
      () => this.partialMatchSearch(this.state.value, true)
    )
  },
  // onKeyPress = e => console.log(e.charCode, e.keyCode)

  partialMatchSearch(value, bool, args, isFiltered = false) {
    let searchAll = false
    if (typeof value === 'boolean') {
      searchAll = value
      value = ''
    }
    let searchArgs

    // const { partialMatc}
    const { keyword, propertyName, dataId, documentType, apiArgs } =
      args || this.props
    if (args) {
      value = args.keyword
    }

    if (apiArgs) {
      searchArgs = {
        ...apiArgs,
        propertyName,
        onCellSelected: this.onCellSelected,
        ignoreGetApiParams: true
      }
    } else {
      searchArgs = {
        keyword: bool ? '' : value,
        propertyName,
        dataId,
        documentType,
        onCellSelected: this.onCellSelected
      }
    }
    if (this.state.isSet || this.props.isFetching) return
    let results
    //
    // console.log('partialMatchSearch', this.props)
    // debugger

    this.setState({ lastSearch: value, isSearching: true }, async () => {
      try {
        //
        results = await this.props.dispatch(
          partialMatchSearch(this.props.form, searchArgs)
        )

        this.setState({
          dropDownProps: {
            ...results,
            ...results.grid,
            getRowNodeId: this.getRowNodeId,
            paginationCb: this.paginationCb,
            height: this.getGridHeight(),
            isFiltered
          },
          isOpen: true
        })
        // }
      } finally {
        this.setState({ isSearching: false })
      }
    })
  },

  redoSearch() {
    // //
    this.setState(
      { isSet: false, value: this.state.lastSearch, isDirty: true },
      () => this.partialMatchSearch(this.state.lastSearch)
    )
  },

  resetFilters() {
    this.props.dispatch(
      resetFiltersAction(this.form, { propertyName: this.props.propertyName })
    )
  },
  // setField(val, clear, results) {
  //   const { clearFieldOnSet, leader, setField, onSetField } = this.props
  //   // //
  //   setField(val, clear, leader, results)
  //   if (onSetField) {
  //     onSetField(val, clear, leader, results)
  //   }
  //   if (clearFieldOnSet) {
  //     setField('', clear)
  //   }
  // },
  initialize() {
    console.log('docSearch', this)
  },
  async handleFileUpload({ data, meta, base64 }) {
    const { name } = meta
    const { form, propertyName, documentType } = this.props
    // console.log(data, name, documentType)
    // debugger

    try {
      const p = await this.props.dispatch(
        handleFileUpload(form, {
          propertyName,
          content: data,
          fileName: name,
          base64,
          documentType
        })
      )

      console.log('SUCCESS', p)
    } catch (e) {
      console.log(e)
    }
  },
  async handleFileDownload(value) {
    const { form, propertyName, documentType } = this.props
    try {
      const p = await this.props.dispatch(
        handleFileDownload(form, {
          propertyName,
          fileName: value,
          documentType
        })
      )

      console.log('SUCCESS', p)
    } catch (e) {
      console.log(e)
    }
  }
}
