import { api, local } from 'services'
import { take, fork, call, select, cancel, put } from 'redux-saga/effects'
import { userNameSelector } from 'auth/selectors'
import { addModal } from 'modals/actions'
import { CONFIRMED } from 'modals/constants'
// import checkHasAccessProcess from 'auth/sagas/checkHasAccessProcess'
// import grantAccessProcess from 'auth/sagas/grantAccessProcess'
// import { checkHa} from 'auth/sagas'
import { toCamelCase } from 'utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import * as CONSTANTS from './constants'
import * as actions from './actions'
import Tips from './modals/Tips'
import { checkedSelector } from './selectors'

// export function* toggleDashboardTileListener() {
//   while (true) {
//     const {
//       // type,
//       payload: {
//         checked,
//         item: {
//           accessPath,
//           description,
//           imageFilename
//           // requiredLevel
//         },
//         // item } } = yield take([CONSTANTS.ADD_TILE.REQUEST, CONSTANTS.REMOVE_TILE.REQUEST])
//         item
//       }
//     } = yield take(CONSTANTS.TOGGLE_TILE)
//     let id
//     if (typeof item === 'string') {
//       id = item
//     } else {
//       id = item.id
//     }
//     if (!checked) {
//       yield fork(toggleDashboardProcess, id)
//     } else {
//       const hasAccess = yield call(checkHasAccessProcess, toCamelCase(item.id))
//       // const foo = 1
//       if (!hasAccess) {
//         yield call(grantAccessProcess, {
//           id,
//           image: imageFilename,
//           name: toCamelCase(item.id),
//           //  proc: addDashboardProcess,
//           title: description
//         })
//         // }
//       } else {
//         yield fork(toggleDashboardProcess, id, true)
//       }
//     }
//   }
// }

// export function* toggleDashboardTileListener() {
//   while (true) {
//     const {
//       // type,
//       payload: {
//         checked,
//         item: {
//           accessPath,
//           description,
//           imageFilename
//           // requiredLevel
//         },
//         // item } } = yield take([CONSTANTS.ADD_TILE.REQUEST, CONSTANTS.REMOVE_TILE.REQUEST])
//         item
//       }
//     } = yield take(CONSTANTS.TOGGLE_TILE)
//     let id
//     if (typeof item === 'string') {
//       id = item
//     } else {
//       id = item.id
//     }
//     if (!checked) {
//       yield fork(toggleDashboardProcess, id)
//     } else if (accessPath) {
//       // const hasAccess = yield call(checkHasAccessProcess, toCamelCase(item.id))
//       // const foo = 1
//       // if (!hasAccess) {
//       yield call(grantAccessProcess, {
//         id,
//         image: imageFilename,
//         name: toCamelCase(item.id),
//         proc: addDashboardProcess,
//         title: description
//       })
//       // }
//     } else {
//       yield fork(toggleDashboardProcess, id, true)
//     }
//   }
// }

export function* addDashboardProcess({
  accessPath,
  pin,
  password,
  name,
  username,
  id
}) {
  yield put(actions.addTile.request({ id }))
  const { response, error } = yield call(api.addDashboardItem, {
    accessPath,
    id,
    name,
    username,
    password,
    pin
  })
  if (response) {
    yield put(actions.addTile.success(response))
  } else {
    yield put(actions.addTile.failure(error))
  }
}

export function* removeDashboardProcess(name) {
  yield put(actions.removeTile.request({ id: name }))
  const { response, error } = yield call(api.removeDashboardItem, {
    name
  })
  if (response) {
    yield put(actions.removeTile.success(response))
  } else {
    yield put(actions.removeTile.failure(error))
  }
}

export function* toggleDashboardProcess(id, shouldAdd) {
  // let task
  // if (task) cancel(task)
  if (shouldAdd) {
    yield fork(addDashboardProcess, { id })
  } else {
    yield fork(removeDashboardProcess, id)
  }
}

export function* storeLayoutsListener() {
  while (true) {
    const {
      payload: { layouts }
    } = yield take(CONSTANTS.STORE_LAYOUTS)
    const userName = yield select(userNameSelector)
    console.log('storeLayoutsSaga', layouts)
    if (layouts && userName) {
      /* 
        Marc, FYI I just put this in a try catch block today because
        I somehow triggered an error on removing a dashboard tile where the 
        size of the layouts object was so large it threw a DOMException
        "failed to execute setItem on Storage: Setting the value of 'layouts-dashbard-SV'
        exceeeded the quota." This error actually impacted all subsequent saga interactions
        so I think this was a pretty key fix here -- SVE 3/10/2021
      */
      try {
        yield call(local.set, `layouts-dashboard-${userName}`, layouts)
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        yield call(local.remove, `layouts-dashboard-${userName}`)
      } catch (e) {
        console.log(e)
      }
    }
  }
}

export function* showTipsModalListener() {
  while (yield take(CONSTANTS.SHOW_TIPS_MODAL)) {
    yield call(showTipsModal)
    yield take(CONFIRMED)
    const checked = yield select(checkedSelector)
    if (checked) {
      yield call(api.hideTips) // not storing any new data in state right now, so i'm just calling the api for the time being..
    }
  }
}

export function* showTipsModal() {
  const options = {
    component: Tips,
    options: {
      // data: { /*actions: [{ title: 'OK' }]*/ },
      title: 'Tips',
      width: 950
    }
  }

  return yield put(addModal(options))
}

export function* tileMountedListener() {
  const tasks = {}
  while (true) {
    const {
      payload: { sagas, id }
    } = yield take(CONSTANTS.TILE_MOUNTED)

    if (sagas) {
      tasks[id] = yield fork(sagas)
    }

    const act = yield take(CONSTANTS.TILE_UNMOUNTED)
    const idx = act.payload.id
    const hasTasks = tasks[idx]
    if (hasTasks) {
      yield cancel(hasTasks)
      delete tasks[idx]
    }
  }
}

export function* openDashboardDetailsScreen(payload, response) {
  const args = response
    ? {
        name: toCamelCase(payload.dataId),
        image: payload.image,
        title: `${payload.title} Details`,
        data: response
      }
    : {
        name: toCamelCase(payload.dataId),
        image: payload.image,
        title: payload.title
      }
  yield put(openScreenAction(args))
}

export function* dashboardDetailsOpenProcess(payload) {
  const { response, error } = yield call(api.viewDashboardDetails, {
    dataId: payload.dataId
  })

  if (response) {
    // console.log(response)
    // yield put(openScreenAction({
    //   name: toCamelCase(payload.dataId),
    //   image: payload.image,
    //   title: payload.title,
    //   data: response
    // }))
    yield call(openDashboardDetailsScreen, payload, response)
    yield put(actions.dashboardDetailsOpen.success(response))
  }
  // else {
  //   // console.log(error)
  //   yield call(grantAccessProcess, {
  //     id: payload.dataId,
  //     name: toCamelCase(payload.dataId),
  //     // proc: openDashboardDetailsScreen,
  //     image: payload.image,
  //     title: `${payload.title} Details`
  //   })
  // }
}

// export function* dashboardDetailsOpenListener() {
//   while (true) {
//     const { payload } = yield take(CONSTANTS.DASHBOARD_DETAILS_OPEN.REQUEST)
//     yield call(dashboardDetailsOpenProcess, payload)
//   }
// }

export default function* dashboardSagas() {
  return [
    // yield fork(toggleDashboardTileListener),
    yield fork(storeLayoutsListener),
    yield fork(showTipsModalListener),
    yield fork(tileMountedListener)
    // yield fork(dashboardDetailsOpenListener)
  ]
}
