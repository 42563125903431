import React, { Fragment } from 'react'
import {
  Paper,
  ButtonBase,
  TextField,
  InputAdornment,
  IconButton,
  Icon
} from '@material-ui/core'

const FindInGrid = ({
  count,
  countStyle,
  findInputRef,
  findLeftDisabled,
  findRightDisabled,
  handleFindChange,
  onFindClick,
  selectedCell,
  textFieldId,
  toggleShowFind
}) => (
  <Paper
    style={{ width: 450, marginTop: 1, marginBottom: 1, position: 'relative' }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 5,
        justifyContent: 'center'
      }}
    >
      <div style={{ flex: 0.3, alignSelf: 'center' }}>
        <strong>Find Text:</strong>
      </div>
      <div style={{ flex: 0.6, flexDirection: 'column' }}>
        <TextField
          fullWidth
          id={textFieldId}
          onChange={handleFindChange}
          inputRef={findInputRef}
          InputProps={{
            endAdornment: (
              <Fragment>
                {count ? (
                  <Fragment>
                    <p style={countStyle}>
                      {selectedCell + 1} of {count}
                    </p>

                    <InputAdornment variant="filled" position="end">
                      <IconButton
                        disabled={findLeftDisabled}
                        onClick={onFindClick(-1)}
                        style={{
                          height: 16,
                          padding: 0,
                          width: 16
                        }}
                      >
                        <Icon
                          style={{
                            color: findLeftDisabled ? '#ccc' : '#333',
                            height: 16,
                            width: 16,
                            fontSize: 16
                          }}
                        >
                          keyboard_arrow_left
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                    <InputAdornment variant="filled" position="end">
                      <IconButton
                        disabled={findRightDisabled}
                        onClick={onFindClick(1)}
                        style={{
                          height: 16,
                          padding: 0,
                          width: 16
                        }}
                      >
                        <Icon
                          style={{
                            color: findRightDisabled ? '#ccc' : '#333',
                            height: 16,
                            width: 16,
                            fontSize: 16
                          }}
                        >
                          keyboard_arrow_right
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  </Fragment>
                ) : null}
              </Fragment>
            )
          }}
        />
      </div>
      <div
        style={{
          flex: 0.1,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <ButtonBase
          style={{
            height: 24,
            width: 24
          }}
          onClick={toggleShowFind}
        >
          <Icon style={{ color: '#d9534f', fontSize: 24 }}>highlight_off</Icon>
        </ButtonBase>
      </div>
    </div>
  </Paper>
)

export default FindInGrid
