import shortid from 'shortid'
import memoize from 'memoize-one'
// import { plainDeepEqual } from 'utils'
import plainDeepEqual from './plainDeepEqual'

const makeRowDataUnique = memoize((data, key = 'id') => {
  const result =
    data && Array.isArray(data) && data.length
      ? data.reduce((acc, next) => {
          if (!next[key]) {
            acc = acc.concat({
              ...next,
              [key]: shortid.generate()
            })
          } else {
            acc = acc.concat(next)
          }
          return acc
        }, [])
      : []

  return result
}, plainDeepEqual)

export default makeRowDataUnique
