import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
import main from 'pages/Main/reducer'
import auth from 'auth/reducer'
import modals from 'modals/reducer'
import { connectRouter } from 'connected-react-router/immutable'
// import { reducer as ddiForm } from 'ddiForm'
import { reducer as ddiForm } from 'ddiForm'
import dashboard from 'pages/Dashboard/reducer'
import layout from 'pages/Layout/reducer'
import snackbar from 'snackbar/reducer'
import account from 'pages/SignUp/reducer'
import mobile from 'mobile/reducer'
// Initial routing state
// const routeInitialState = fromJS({ locationBeforeTransitions: null })

/**
 * Creates the main reducer with the asynchronously loaded ones
 */

export default function createReducer(history, asyncReducers) {
  return combineReducers({
    auth,
    dashboard,
    // ddiForm: ddiForm(),
    layout,
    ddiForm: ddiForm(),
    main,
    modals: modals(),
    router: connectRouter(history),
    // route: routerReducer,
    account,
    snackbar,
    mobile,
    ...asyncReducers
  })
}
