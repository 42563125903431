import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class GridCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
  }
  onClick = e => {
    e.preventDefault()
    this.props.onCellSelected({
      api: this.props.api,
      columnApi: this.props.columnApi,
      data: this.props.data,
      node: this.props.node,
      rowIndex: this.props.rowIndex
    })
  }
  render() {
    return (
      <div>
        <a onClick={this.onClick}>
          <strong>{this.props.value}</strong>
        </a>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(GridCell)
