import { isObject } from 'lodash'

/**
 * runs JSON.stringify on an object
 * (after ensuring that it is indeed an object)
 * @param {object} obj - object to convert
 * @returns {string} stringified object
*/
const toJSON = obj => {
  if (!isObject(obj)) throw new Error('param is not an object')
  return JSON.stringify(obj)
}

export default toJSON
