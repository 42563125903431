import React from 'react'
import PropTypes from 'prop-types'
import { Label, withContext } from 'ddiForm'
import { Paper, MenuItem } from '@material-ui/core'
import DDITextField from 'ddiForm/wrapped/DDITextField'
import SelectField from 'ddiForm/SelectField'
import DDITabTextField from 'ddiForm/wrapped/DDITabTextField'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import { getIn } from 'utils'
// 2021111A
const Fields = ({ asModal, selectInitialValue, statusOptions, styles }) => (
  <Paper style={{ padding: '10px 20px 15px 20px' }}>
    <div className="po-inquiry-fields">
      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="poNumber" style={styles.label}>
            P/O Number:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDITabTextField
            propertyName="poNumber"
            fullWidth
            onTab="exactMatchFromForm"
            style={{ width: '100%' }}
            disabled={(field, formState) => field.disabled}
          />
        </div>
      </div>

      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="product" style={styles.label}>
            Product:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDIIndexSearch
            propertyName="product"
            metaKey="Product"
            // searchType={
            //   INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.PRODUCT
            // }
            asModal={asModal}
            moreInfo
            openScreenData={{
              name: 'productMaster',
              image: 'product_master_16.png',
              title: 'Product Master'
            }}
            openScreenMenuText="Open Product Master"
            minWidth="100%"
          />
        </div>
        <div className="escription" style={styles.flexInput.description}>
          <Label propertyName="productDescription" />
        </div>
      </div>

      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="vendor" style={styles.label}>
            Vendor:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDIIndexSearch
            propertyName="vendor"
            metaKey="Vendor"
            // searchType={
            //   INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.VENDOR
            // }
            asModal={asModal}
            moreInfo
            openScreenData={{
              name: 'vendorMaster',
              image: 'vendor_master_16.png',
              title: 'Vendor Master'
            }}
            openScreenMenuText="Open Vendor Master"
            minWidth="100%"
          />
        </div>
        <div className="description" style={styles.flexInput.description}>
          <Label propertyName="vendorDescription" />
        </div>
      </div>

      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="job" style={styles.label}>
            Job:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDITextField propertyName="job" fullWidth />
        </div>
        <div className="description" style={styles.flexInput.description}>
          <Label propertyName="jobDescription" />
        </div>
      </div>

      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="statusOptions" style={styles.label}>
            Purchase Order:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <SelectField
            propertyName="status"
            fullWidth
            values={statusOptions?.toJS ? statusOptions.toJS() : []}
            initialValue={selectInitialValue}
            mappingComponent={val => (
              <MenuItem key={val.dataId} value={val.dataId}>
                {val.description}
              </MenuItem>
            )}
          />
        </div>
      </div>
    </div>
  </Paper>
)

export default withContext({
  selectInitialValue: formState =>
    getIn(formState, 'meta.statusOptions[0].dataId'),
  statusOptions: formState => {
    const productId = getIn(formState, 'fields.product.value')
    const productIdIsSet = getIn(formState, 'fields.product.isSet')
    const statusOptions = getIn(formState, 'meta.statusOptions')
    const productStatusOptions = getIn(formState, 'meta.productStatusOptions')
    return productId && productIdIsSet ? productStatusOptions : statusOptions
  }
})(Fields)
