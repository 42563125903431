import callFetch from 'access/callFetch'
import { toJSON } from 'utils'
import { REGISTER_URL } from '../constants'

export const signup = ({
  username,
  password,
  confirmPassword,
  company,
  email,
  userKey
}) => {
  //eslint-disable-line
  const options = {
    body: toJSON({
      company,
      confirmPassword,
      email,
      password,
      userKey,
      username
    }),
    headers: { 'Content-Type': 'application/json' },
    method: 'post'
  }
  return callFetch(REGISTER_URL, options)
}
