import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const getRoute = form =>
  form && form.match(/salesOrder-/) ? 'salesOrder' : form

/* start internal notes API methods */
export const lockCMInternalNotes = ({
  dataId,
  form,
  recordName,
  guid = null
}) => {
  const apiOptions = guid
    ? {
        dataId,
        notesName: 'internalNotes',
        guid
      }
    : { dataId, recordName }

  const options = createApiOptions({
    body: apiOptions,
    method: 'post'
  })

  const route = getRoute(form)

  return callFetch(`${BASE_INFORM_API_URL}/${route}/notes/editlock`, options)
}

export const unlockCMInternalNotes = ({
  dataId,
  form,
  recordName,
  guid = null
}) => {
  const apiOptions = guid
    ? {
        dataId,
        notesName: 'internalNotes',
        guid
      }
    : { dataId, recordName }

  const options = createApiOptions({
    body: apiOptions,
    method: 'post'
  })

  const route = getRoute(form)

  return callFetch(`${BASE_INFORM_API_URL}/${route}/notes/canceledit`, options)
}

export const saveCMInternalNotes = ({
  dataId,
  form,
  recordName,
  note,
  retainLock = false,
  guid = null
}) => {
  const apiOptions = guid
    ? {
        dataId,
        notesName: 'internalNotes',
        guid,
        note,
        retainLock
      }
    : { dataId, recordName, note, retainLock }

  const options = createApiOptions({
    body: apiOptions,
    method: 'post'
  })

  const route = getRoute(form)

  return callFetch(`${BASE_INFORM_API_URL}/${route}/notes/save`, options)
}
/* end internal notes API methods */
