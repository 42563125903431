import { createAction } from 'utils'
import { SCREEN_DROP_DOWN_OPEN } from './constants'

// export const screenDropDownAsync = createAsyncFormAction(SCREEN_DROP_DOWN_OPEN)
export const screenDropDownOpen = (args = {}) =>
  createAction(SCREEN_DROP_DOWN_OPEN.REQUEST, args, null, false, {
    withAccess: true,
    apiRequest: true,
    thunk: true
  })

export const screenDropDownOpenSuccess = (args = { payload: {}, meta: {} }) =>
  createAction(
    SCREEN_DROP_DOWN_OPEN.SUCCESS,
    args.payload,
    null,
    false,
    args.meta
  )

export const screenDropDownOpenError = (args = { payload: {}, meta: {} }) =>
  createAction(SCREEN_DROP_DOWN_OPEN.FAILURE, args.payload, null, false, {
    ...args.meta,
    error: true
  })
