import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@material-ui/core'
import { noop } from 'utils'

export default props => (
  <div style={{ display: 'flex', height: '100%' }}>
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Checkbox
        // onCheck={() => propertyChange({ meta: { action: 'delete', sortIndex: this.props.rowIndex + 1, } })}
        checked={props.value}
        // onCheck={(event, isInputChecked) => { this.updateChecked(event, isInputChecked) }}
        style={{ textAlign: 'center', width: 'auto', margin: '0 auto' }}
        value={props.value}
        disabled
      />
    </div>
  </div>
)
