import React from 'react'
import { Icon } from '@material-ui/core'

const ContactsCellRenderer = props => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%'
    }}
  >
    <div style={{ flex: 0.8 }}>{props.valueFormatted}</div>
    <div
      style={{
        flex: 0.2,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight: -10
      }}
    >
      {!props.valueFormatted ? (
        <Icon
          style={{
            color: '#d9534f',
            fontSize: 16
          }}
        >
          error_outline
        </Icon>
      ) : null}
    </div>
  </div>
)

export default ContactsCellRenderer
