import behaviors from './behaviors'
import createSearch from '../createSearch'

const CheckSearch = createSearch({
  name: 'CheckSearch',
  behaviors,
  popoverStyle: { width: 600 },
  meta: {
    allowInstantSearch: true,
    allowSearchAll: true,
    minimumKeywordLength: 2
  }
})

export default CheckSearch
