import React from 'react'

export default props => (
  <div style={{ display: 'flex', height: '100%' }}>
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {props.value && props.value.includes('Default') ? 'Default' : 'None'}
    </div>
  </div>
)
