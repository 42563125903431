import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

const eventModal = 'eventModal'

export const addEventType = createAsyncFormAction(
  CONSTANTS.ADD_EVENT_TYPE,
  eventModal
)

export const openEventModal = ({ startDate }) =>
  createAction(CONSTANTS.OPEN_EVENT_MODAL, { startDate }, eventModal)

export const getEventTypes = createAsyncFormAction(
  CONSTANTS.GET_EVENT_TYPES,
  eventModal
)

export const validateEventType = createAsyncFormAction(
  CONSTANTS.VALIDATE_EVENT_TYPE,
  eventModal
)

export const createEventType = createAsyncFormAction(
  CONSTANTS.CREATE_EVENT_TYPE,
  eventModal
)

export const lockEventType = createAsyncFormAction(
  CONSTANTS.LOCK_EVENT_TYPE,
  eventModal
)
export const unlockEventType = createAsyncFormAction(
  CONSTANTS.UNLOCK_EVENT_TYPE,
  eventModal
)

export const getServerTime = createAsyncFormAction(
  CONSTANTS.GET_SERVER_TIME,
  eventModal
)

export const clearNewEventTypePopover = createAction(
  CONSTANTS.CLEAR_NEW_EVENT_TYPE_POPOVER,
  {},
  eventModal
)

/* these actions have been updated for ddiForm modal integration */
export const initEventModal = ({ data }) =>
  createAction(CONSTANTS.INIT_EVENT_MODAL, { data }, eventModal)

export const registerResponseData = ({ data }) =>
  createAction(CONSTANTS.REGISTER_RESPONSE_DATA, { data }, eventModal)

// export const saveActivityAsync = createAsyncFormAction(CONSTANTS.SAVE_ACTIVITY)
export const saveActivityAsync = form =>
  createAction(CONSTANTS.SAVE_ACTIVITY.TRY, {}, null, null, {
    form,
    thunk: true
  })

export const unlockActivityAsync = (form, { dataId }) =>
  createAction(CONSTANTS.UNLOCK_ACTIVITY.TRY, { dataId }, form)

export const deleteActivityAsync = createAsyncFormAction(
  CONSTANTS.DELETE_ACTIVITY
)

export const deleteActivity = (
  form,
  { dataId, subject, type, cb, removeMe = false, modalId = null }
) =>
  createAction(
    CONSTANTS.DELETE_ACTIVITY.TRY,
    { dataId, subject, type, cb, removeMe, modalId },
    form
  )

export const addNewActivity = (
  form,
  { selectedStartDate, referenceData = {} }
) =>
  createAction(
    CONSTANTS.ADD_NEW_ACTIVITY.TRY,
    { selectedStartDate, referenceData },
    form
  )

export const closeActivityModal = form =>
  createAction(CONSTANTS.CLOSE_ACTIVITY_MODAL, {}, form)

export const clearForm = createAction(CONSTANTS.CLEAR_FORM, null, eventModal)

export const arInvoiceExactMatch = (form, propertyName, keyword) =>
  // console.log(keyword, form)
  createAction(
    CONSTANTS.AR_INVOICE_EXACT_MATCH.REQUEST,
    { propertyName, keyword },
    form,
    null,
    { thunk: true }
  )

/* add and edit event types */
export const saveColorSelection = (form, { color }) =>
  createAction(CONSTANTS.SAVE_COLOR_SELECTION, { color }, form)

export const closeEventModal = form =>
  createAction(CONSTANTS.CLOSE_EVENT_MODAL, {}, form)

export const deleteEventType = createAsyncFormAction(
  CONSTANTS.DELETE_EVENT_TYPE,
  eventModal
)

export const saveEventType = createAsyncFormAction(
  CONSTANTS.SAVE_EVENT_TYPE,
  eventModal
)

export const clearEventTypeForm = form =>
  createAction(CONSTANTS.CLEAR_EVENT_TYPE_FORM, {}, form)

export const onTooltipKeyPress = (form, { key, openArgs }) =>
  createAction(CONSTANTS.ON_TOOLTIP_KEYPRESS, { key, openArgs }, form)

export const lockActivity = (form, { dataId }) =>
  createAction(CONSTANTS.LOCK_ACTIVITY.TRY, { dataId }, form)

export const saveAccessModalId = ({ accessOverrideModalId }) =>
  createAction(
    CONSTANTS.SAVE_ACCESS_MODAL_ID,
    { accessOverrideModalId },
    eventModal
  )

export const lockActivitySuccess = ({ response }) =>
  createAction(CONSTANTS.LOCK_ACTIVITY_SUCCESS, { response }, eventModal)

export const getActivityTooltip = ({ dataId, form }) => {
  const action = createAction(
    CONSTANTS.GET_ACTIVITY_TOOLTIP.REQUEST,
    { dataId },
    form,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}
