import { createAsyncRequestTypes } from 'utils'
import { DDI_FORM } from '../constants'

export const MASTER_SCREEN = `${DDI_FORM}`

export const GET_ENTITY = createAsyncRequestTypes(`${MASTER_SCREEN}GET_ENTITY`)
export const LOCK_ENTITY = createAsyncRequestTypes(
  `${MASTER_SCREEN}LOCK_ENTITY`
)
export const UNLOCK_ENTITY = createAsyncRequestTypes(
  `${MASTER_SCREEN}UNLOCK_ENTITY`
)

export const ON_DOUBLE_CLICK = `${MASTER_SCREEN}ON_DOUBLE_CLICK`
export const RESET_MASTER_FIELDS = `${MASTER_SCREEN}RESET_MASTER_FIELDS`
export const LOCK_FOR_EDIT = createAsyncRequestTypes(
  `${MASTER_SCREEN}LOCK_FOR_EDIT`
)
export const CANCEL_EDIT = createAsyncRequestTypes(
  `${MASTER_SCREEN}CANCEL_EDIT`
)
export const PRE_CANCEL_EDIT = `${MASTER_SCREEN}PRE_CANCEL_EDIT`
export const GET_ALTERNATE_META = createAsyncRequestTypes(
  `${MASTER_SCREEN}GET_ALTERNATE_META`
)
