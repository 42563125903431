/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import ReactToolTip from 'react-tooltip'

/*
import {
  IconButton,
  IconMenu,
  MenuItem,
  Popover,
  SelectField,
  TextField,
  Button
} from '@material-ui/core'
*/

import {
  Button,
  Icon,
  IconButton,
  Popover,
  FormControl,
  MenuItem
} from '@material-ui/core'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import Select from '@material-ui/core/Select'

import { connect } from 'react-redux'
import { debounce, intersection } from 'lodash'
import { getIn, getRgb, noop, plainDeepEqual /* isGrid */ } from 'utils'
import shortid from 'shortid'
import uuid from 'uuid'
// import styles from 'ddiForm/styles/IndexSearch.scss'
import './styles/css-mod-ignore.css'

// import Grid from 'grid'
// import Grid from 'Grid'
// import Grid from 'grid'
// import IndexSearch from './IndexSearch'
import * as eventModalActions from '../actions'

import {
  colorOptionsSelector,
  eventTypeEditorOpenSelector,
  eventTypeEditorEventTypesSelector,
  eventTypeEditorSelector,
  eventTypeEditorGridVisibleSelector,
  eventTypeLockedSelector,
  openModalSelector,
  selectedEventSelector
} from '../selectors'

const comma = ','
const tab = 'Tab'
const blur = 'blur'

const alertStyle = {
  alignItems: 'center',
  backgroundColor: '#fcf8e3',
  borderColor: '#faebcc',
  color: '#d9534f',
  display: 'flex',
  fontSize: 11,
  lineHeight: '15px',
  justifyContent: 'space-between',
  margin: '5px 0',
  padding: 5,
  width: '100%'
}
const alertIconStyle = { color: '#d9534f', fontSize: 14, marginRight: 5 }

export const nonEmptyString = x => x !== ''
export const split = str => str.split(comma).filter(nonEmptyString)

export const mapDispatchToProps = (dispatch, ownProps) => ({
  addEventType: () => dispatch(eventModalActions.addEventType.request()),
  clearNewEventTypePopover: () =>
    dispatch(eventModalActions.clearNewEventTypePopover),
  clearEventTypeForm: () =>
    dispatch(eventModalActions.clearEventTypeForm('eventModal')),
  closeEventModal: () =>
    dispatch(eventModalActions.closeEventModal('eventModal')),
  createEventType: ({ dataId }) =>
    dispatch(
      eventModalActions.createEventType.request('eventModal', { dataId })
    ),
  saveColorSelection: ({ color }) =>
    dispatch(eventModalActions.saveColorSelection('eventModal', { color })),
  deleteEventType: ({ dataId }) =>
    dispatch(
      eventModalActions.deleteEventType.request('eventModal', { dataId })
    ),
  getEventTypes: ({ indexSearchType, keyword, pageNumber }) =>
    dispatch(
      eventModalActions.getEventTypes.request('eventModal', {
        indexSearchType,
        keyword,
        pageNumber
      })
    ),
  lockEventType: ({ dataId }) =>
    dispatch(eventModalActions.lockEventType.request('eventModal', { dataId })),
  saveEventType: ({ dataId, color }) =>
    dispatch(
      eventModalActions.saveEventType.request('eventModal', { dataId, color })
    ),
  unlockEventType: ({ dataId, editorOpen, isEditing }) =>
    dispatch(
      eventModalActions.unlockEventType.request('eventModal', {
        dataId,
        editorOpen,
        isEditing
      })
    ),
  validateEventType: ({ dataId }) =>
    dispatch(
      eventModalActions.validateEventType.request('eventModal', { dataId })
    )
  // unlockActivity: ({ dataId }) => dispatch(eventModalActions.unlockActivity.request({ dataId })),
})

const mapStateToProps = (state, ownProps) => {
  // console.log('newEventTypePopover', state, state.toJS())
  // const props = state ? state.toJS() : {}
  const fields = getIn(state, 'ddiForm.eventModal.fields')
  const isMobile = getIn(state, 'mobile.isMobile') || false

  return {
    callLocation: getIn(state, 'ddiForm.eventModal.callLocation') || '',
    colorOptions: colorOptionsSelector(state) || [],
    eventTypeEditorEventTypes: eventTypeEditorEventTypesSelector(state) || [],
    eventTypeEditorOptions: eventTypeEditorSelector(state) || {},
    eventTypeLocked: eventTypeLockedSelector(state) || false,
    fields: fields && fields.toJS ? fields.toJS() : {},
    gridOptions: {
      ...ownProps.gridOptions,
      columnDefs: [...ownProps.gridOptions.columnDefs]
    },
    eventTypeEditorOpen: eventTypeEditorOpenSelector(state) || false,
    gridVisible: eventTypeEditorGridVisibleSelector(state) || false,
    rowData: eventTypeEditorEventTypesSelector(state) || [],
    selectedEvent: selectedEventSelector(state) || {},
    isMobile
  }
}

export const makeSelectable = (rowData, initialValue) => {
  let initialList = []
  if (initialValue) {
    initialList = initialList.concat(split(initialValue))
  }

  return rowData.map(x => ({
    ...x,
    isSelected: intersection(initialList, Object.values(x)).length > 0
  }))
}

export class NewEventTypePopover extends Component {
  static propTypes = {
    buttonStyle: PropTypes.object,
    callLocation: PropTypes.string.isRequired,
    clearEventTypeForm: PropTypes.func.isRequired,
    closeIconMenuAction: PropTypes.func,
    closeEventModal: PropTypes.func.isRequired,
    colorOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    createEventType: PropTypes.func.isRequired,
    deleteEventType: PropTypes.func.isRequired,
    eventTypeEditorEventTypes: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]).isRequired,
    eventTypeEditorOptions: PropTypes.object.isRequired,
    eventTypeLocked: PropTypes.bool.isRequired,
    eventTypeEditorOpen: PropTypes.bool.isRequired,
    getEventTypes: PropTypes.func.isRequired,
    gridOptions: PropTypes.object.isRequired,
    gridVisible: PropTypes.bool.isRequired,
    hintStyle: PropTypes.object,
    hintText: PropTypes.string,
    iconButtonAction: PropTypes.func.isRequired,
    iconStyle: PropTypes.object,
    id: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    lockEventType: PropTypes.func.isRequired,
    onTab: PropTypes.func,
    open: PropTypes.bool,
    rowData: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    rowSelection: PropTypes.string,
    saveColorSelection: PropTypes.func.isRequired,
    saveEventType: PropTypes.func.isRequired,
    selectedEvent: PropTypes.object.isRequired,
    textFieldStyle: PropTypes.object,
    tooltip: PropTypes.string,
    underlineStyle: PropTypes.object,
    unlockEventType: PropTypes.func.isRequired,
    validateEventType: PropTypes.func.isRequired,
    value: PropTypes.string // eslint-disable-line
  }

  static defaultProps = {
    buttonStyle: {
      background: '#517b9c',
      borderRadius: '0 4px 4px 0',
      float: 'left',
      height: 26,
      maxWidth: 30,
      padding: 0
    },
    closeIconMenuAction: () => {},
    colorMissing: false,
    colorOptions: [],
    disablePropChange: false,
    grid: null,
    gridOptions: {
      height: 200,
      width: 220
    },
    hintStyle: {
      bottom: 2,
      fontSize: 12,
      width: '100%'
    },
    hintText: 'Search...',
    iconButtonAction: noop,
    iconStyle: {
      color: '#fff',
      fill: '#fff',
      fontSize: 16,
      height: 16,
      width: 20
    },
    id: uuid.v1(),
    initialValue: undefined,
    onTab: noop,
    open: false,
    rowData: [],
    rowSelection: 'multiple',
    textFieldStyle: {
      fontSize: 12,
      float: 'left',
      height: 26,
      width: '85%'
    },
    tooltip: 'Enter ID to create new record. Press tab to accept',
    underlineStyle: { bottom: 0 },
    value: null
  }

  constructor(props) {
    super(props)
    this.state = {
      color: '',
      gridOptions: { ...props.gridOptions },
      editorKey: shortid.generate(),
      isDirty: false,
      isOpen: false,
      newEventType: '',
      open: false,
      rowData: props.rowData,
      // rowData: makeSelectable(props.rowData, props.initialValue),
      value: ''
    }

    // console.log('newEventModal', props)
  }

  newEventTypeSearch = React.createRef()

  componentDidMount() {
    this.anchorEl = findDOMNode(this.textField)
    this.popoverAnchor = findDOMNode(this.anchorIcon)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {
        color: '',
        newEventType: '',
        open: nextProps.open
      }
    }

    if (
      nextProps.fields &&
      nextProps.fields.color &&
      nextProps.fields.color.value &&
      nextProps.fields.color.value !== prevState.color &&
      prevState.color === ''
    ) {
      return { color: nextProps.fields.color.value }
    }

    // if (
    //   (nextProps.fields && nextProps.fields.color && !nextProps.fields.color.value) &&
    //   (nextProps.fields.color.value !== prevState.color) &&
    //   (nextProps.fields && nextProps.fields.newEventType && nextProps.fields.newEventType.editorKey) &&
    //   (nextProps.fields.newEventType.editorKey !== prevState.editorKey)
    // ) {
    //   /* clear the color from state if necessary */
    //   return { color: nextProps.fields.color.value }
    // }

    if (
      nextProps.fields &&
      nextProps.fields.newEventType &&
      nextProps.fields.newEventType.value &&
      nextProps.fields.newEventType.value !== prevState.newEventType
    ) {
      return { newEventType: nextProps.fields.newEventType.value }
    }

    if (
      nextProps.fields &&
      nextProps.fields.newEventType &&
      nextProps.fields.newEventType.editorKey &&
      nextProps.fields.newEventType.editorKey !== prevState.editorKey
    ) {
      return {
        color: nextProps?.fields?.color?.value || '',
        newEventType: nextProps?.fields?.newEventType?.value || '',
        editorKey: nextProps.fields.newEventType.editorKey
      }
    }

    return null
  }

  onChange = event => {
    this.setState({ value: event.target.value })
  }

  onCellClicked = node => {
    this.props.validateEventType({ dataId: node.data.recordName })
  }

  setColor = (e, key, payload) => {
    this.setState({ color: payload, colorMissing: false })
  }

  // createNewEventType = event => {
  //   if (event.key === 'Tab' && event.target.value) {
  //     event.preventDefault()
  //     this.props.createEventType({ dataId: event.target.value })
  //   }
  // }

  saveEventType = () => {
    const { newEventType, color } = this.state
    // debugger
    if (newEventType && color) {
      this.props.saveEventType({ color, dataId: newEventType })
    } else {
      this.setState({ colorMissing: true })
    }
  }

  deleteEventType = () => {
    const { newEventType, color } = this.state
    // debugger
    if (newEventType) {
      this.props.deleteEventType({ dataId: newEventType })
    }
  }

  onKeyUp = e => {
    if (e.keyCode === 9) {
      const { value } = this.state
      this.setState({ lastSearch: value }, () =>
        this.props.createEventType({ dataId: value })
      )
    }
  }

  lockEventType = () => {
    const {
      fields: {
        newEventType: { value }
      }
    } = this.props

    if (value) {
      this.props.lockEventType({ dataId: value })
    }
  }

  closeEventTypeModal = () => {
    const {
      eventTypeLocked,
      fields: { newEventType }
    } = this.props

    if (eventTypeLocked) {
      this.setState({ open: false, colorMissing: false }, () => {
        this.props.unlockEventType({
          dataId: newEventType.value,
          editorOpen: false
        })
      })
    } else {
      this.setState({ open: false, colorMissing: false }, () => {
        this.props.closeEventModal()
      })
    }
  }

  clearEventTypeForm = debounce(() => {
    const {
      eventTypeLocked,
      fields: { newEventType }
    } = this.props

    if (newEventType && newEventType.value && eventTypeLocked) {
      // const { selectedEvent } = this.props
      this.props.unlockEventType({
        dataId: newEventType.value,
        editorOpen: true,
        isEditing: true
      })
    } else {
      this.props.clearEventTypeForm()
    }
  }, 500)

  getActiveButtonSet = () => {
    if (this.props.fields) {
      const {
        eventTypeLocked,
        fields: { newEventType }
      } = this.props
      const buttonStyles = { marginBottom: 10, marginRight: 5 }

      if (
        newEventType &&
        newEventType.value &&
        newEventType.isNew &&
        !eventTypeLocked
      ) {
        return (
          <div className="active-button-set">
            <Button onClick={this.clearEventTypeForm} variant="contained">
              New
            </Button>
            <Button onClick={this.saveEventType} variant="contained">
              Save
            </Button>
            <Button onClick={this.closeEventTypeModal} variant="contained">
              Exit
            </Button>
          </div>
        )
      }

      if (
        newEventType &&
        newEventType.value &&
        newEventType.isSet &&
        !eventTypeLocked
      ) {
        return (
          <div className="active-button-set">
            <Button onClick={this.clearEventTypeForm} variant="contained">
              New
            </Button>
            <Button onClick={this.lockEventType} variant="contained">
              Edit
            </Button>
            <Button onClick={this.closeEventTypeModal} variant="contained">
              Exit
            </Button>
          </div>
        )
      }

      if (
        newEventType &&
        newEventType.value &&
        newEventType.isNew &&
        eventTypeLocked
      ) {
        return (
          <div className="active-button-set">
            <Button onClick={this.clearEventTypeForm} variant="contained">
              New
            </Button>
            <Button onClick={this.saveEventType} variant="contained">
              Save
            </Button>
            <Button onClick={this.closeEventTypeModal} variant="contained">
              Exit
            </Button>
          </div>
        )
      }

      if (
        newEventType &&
        newEventType.value &&
        !newEventType.isNew &&
        eventTypeLocked
      ) {
        return (
          <div className="active-button-set">
            <Button onClick={this.clearEventTypeForm} variant="contained">
              New
            </Button>
            <Button onClick={this.saveEventType} variant="contained">
              Save
            </Button>
            <Button onClick={this.deleteEventType} variant="contained">
              Delete
            </Button>
            <Button onClick={this.closeEventTypeModal} variant="contained">
              Exit
            </Button>
          </div>
        )
      }

      return (
        <div className="active-button-set">
          <Button onClick={this.createEventType} variant="contained">
            OK
          </Button>
          <Button onClick={this.closeEventTypeModal} variant="contained">
            Cancel
          </Button>
          <Button onClick={this.closeEventTypeModal} variant="contained">
            Exit
          </Button>
        </div>
      )
    }

    return false
  }

  onSelectChange = event => {
    this.setState({ color: event.target.value }, () => {
      const { color } = this.state
      this.props.saveColorSelection({ color })
    })
  }

  clearErrorMessage = () => this.setState({ colorMissing: false })

  render() {
    const { interfaceOptions, isMobile } = this.props

    const { colorMissing, gridOptions, lastSearch, open, value } = this.state

    // console.log('newEventRENDERProps', this.props)

    const iconButtonStyle = {
      background:
        'linear-gradient(rgb(211, 222, 230) 0%, rgb(192, 213, 229) 100%)',
      borderRadius: 4,
      height: 20,
      padding: 0,
      width: 20
    }

    const tooltip =
      this.props.fields &&
      this.props.fields.newEventType &&
      this.props.fields.newEventType.value ? null : (
        <ReactToolTip
          id="new-event-type-tooltip"
          place="bottom"
          effect="solid"
          style={{
            opacity: 1,
            textAlign: 'center',
            zIndex: 2000
          }}
        >
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            style={{
              color: '#fff',
              fontSize: 14,
              textAlign: 'center'
            }}
          />
          <span style={{ color: '#fff', fontSize: 11 }}>
            &nbsp; Enter ID to create new record
            <br />
            Press tab to accept
          </span>
        </ReactToolTip>
      )

    if (isMobile) {
      return null
    }

    return (
      <div
        className="new-event-type-popover-wrapper"
        // style={{
        //   position: 'absolute',
        //   right: -10,
        //   top: 20,
        //   zIndex: 99
        // }}
      >
        <IconButton
          onClick={this.props.addEventType}
          style={iconButtonStyle}
          ref={el => (this.anchorIcon = el)}
        >
          {open ? (
            <Icon style={{ color: '#444', fontSize: 16 }}>remove</Icon>
          ) : (
            <Icon style={{ color: '#444', fontSize: 16 }}>add</Icon>
          )}
        </IconButton>
        <Popover
          anchorEl={this.popoverAnchor}
          open={this.state.open}
          onClose={this.closeEventTypeModal}
          ModalClasses={{ root: 'event-type-editor-popover' }}
          hideBackdrop
          BackdropProps={{
            invisible: true
          }}
        >
          <div className="ddi-card" style={{ marginBottom: 0, width: 400 }}>
            <div className="ddi-card-heading">
              <h2>New Event Type</h2>
            </div>
            <div className="ddi-card-content" style={{ padding: 20 }}>
              <div
                style={{ marginBottom: 15 }}
                data-tip
                data-for="new-event-type-tooltip"
              >
                {interfaceOptions &&
                  interfaceOptions.meta &&
                  interfaceOptions.meta.indexSearchFields && (
                    // DDIIndexSearch
                    <DDIIndexSearch
                      ref={this.newEventTypeSearch}
                      propertyName="newEventType"
                      meta={{
                        allowInstantSearch: false,
                        allowNavigation: false,
                        allowSearchAll: true,
                        hasFilters: false,
                        hideToggle: true,
                        minimumKeywordLength: 50,
                        searchType: 'EventType'
                      }}
                      value={
                        this.props.fields.newEventType &&
                        this.props.fields.newEventType.value
                          ? this.props.fields.newEventType.value
                          : ''
                      }
                      key={this.state.editorKey}
                    />
                  )}
                {tooltip}
              </div>
              <div style={{ marginBottom: 15, width: '100%' }}>
                {interfaceOptions &&
                  interfaceOptions.meta &&
                  interfaceOptions.meta.colorOptions && (
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={this.state.color}
                        onChange={this.onSelectChange}
                        inputProps={{
                          name: 'color',
                          id: 'color'
                        }}
                        fullWidth
                        propertyName="color"
                        disabled={!this?.props?.eventTypeLocked}
                      >
                        {interfaceOptions.meta.colorOptions.map((opt, idx) => (
                          <MenuItem value={opt.dataId}>
                            <span>
                              <span
                                style={{
                                  background: getRgb(opt.color, 0.3),
                                  width: 15,
                                  height: 15,
                                  borderRadius: 4,
                                  position: 'relative',
                                  top: 2,
                                  display: 'inline-block',
                                  marginRight: 5
                                }}
                              />
                              {opt.description}
                            </span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
              </div>
              {colorMissing ? (
                <div className="alert alert-warning" style={alertStyle}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon size="small" style={alertIconStyle}>
                      warning
                    </Icon>{' '}
                    <span>
                      You must select a color before saving an event type.
                    </span>
                  </span>
                  <span>
                    <IconButton onClick={this.clearErrorMessage} size="small">
                      <Icon
                        size="small"
                        fontSize="small"
                        style={{ color: '#999' }}
                      >
                        highlight_off
                      </Icon>
                    </IconButton>
                  </span>
                </div>
              ) : null}
              <div style={{ paddingTop: 20, textAlign: 'center' }}>
                {this.getActiveButtonSet()}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(NewEventTypePopover)
// export default NewEventTypePopover
