import { take, select, call, put, cancel, fork } from 'redux-saga/effects'
import { EXACT_MATCH_SEARCH } from './constants'
import { api } from 'services'

export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)

    const {
      payload: { keyword = '', propertyName },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield fork(exactMatchProcess, {
      form,
      propertyName,
      keyword,
      thunk
    })
  }
}
export function* exactMatchProcess({ form, keyword, propertyName, thunk }) {
  const params = {
    keyword
  }

  const { response, error } = yield call(api.arInvoiceSearchExactMatch, params)
  // on error?
  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response.exactMatchResults,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}
export default function* arInvoiceSagas() {
  yield fork(exactMatchSearchListener)
}
