import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  // DDITextField,
  DDIToggle
} from 'ddiForm/wrapped'
import { getIn } from 'utils'
import memoize from 'memoize-one'
import { isEqual } from 'lodash'
import { required } from 'ddiForm/validations'

import CustomDateField from './components/CustomDateField'
import CustomDropdownField from './components/CustomDropdownField'
import CustomTextField from './components/CustomTextField'

import './styles/master-style.scss'

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)

  // const customFieldsData = getIn(formState, 'values.customFieldsData') || []
  const customFieldsData =
    getIn(formState, 'meta.customFields.customFieldsData') || []
  const hasCustomFields = getIn(formState, 'meta.customFields.hasCustomFields')

  return {
    customFieldsData,
    hasCustomFields
  }
}

export const CustomFields = ({
  form,
  customFieldsData,
  hasCustomFields,
  isActivityScreen = false
}) => {
  const fieldsMap = {
    CBX: DDIToggle,
    TXT: CustomTextField,
    TXTN: CustomTextField,
    D: CustomDateField,
    DDB: CustomDropdownField,
    MD: CustomTextField
  }

  const [data, setData] = useState(null)

  useEffect(() => {
    const d = customFieldsData.toJS ? customFieldsData.toJS() : customFieldsData
    const sortedD = d.length
      ? d.sort((a, b) => a.fieldNumber - b.fieldNumber)
      : d
    // debugger
    setData(sortedD)
  }, [customFieldsData])

  const renderFields = memoize((fields = []) => {
    const left = []
    const right = []

    fields.forEach((field, i) => {
      const Comp = fieldsMap[field.fieldType]

      const Field = (
        <Comp
          {...field}
          propertyName={`customFields-${field.fieldNumber}`}
          label={field.description}
          meta={{
            dataId: field.dataId
          }}
          validate={field.required ? [required] : null}
          wrapperStyle={{ marginBottom: 15 }}
        />
      )

      i % 2 ? right.push(Field) : left.push(Field)
    })

    return (
      <>
        <div className="left-fields">{left}</div>
        <div className="right-fields">{right}</div>
      </>
    )
  }, isEqual)

  if (isActivityScreen) {
    return (
      <div className="inner-wrapper">
        {hasCustomFields && data?.length ? (
          renderFields(data)
        ) : (
          <span>No Custom Fields.</span>
        )}
      </div>
    )
  }

  return (
    <div className="custom-fields-outer-wrapper">
      <DDICardWrapper title="Custom Fields" style={{ textAlign: 'center' }}>
        <div className="inner-wrapper">
          {hasCustomFields && data?.length ? (
            renderFields(data)
          ) : (
            <span>No Custom Fields.</span>
          )}
        </div>
      </DDICardWrapper>
    </div>
  )
}

CustomFields.prototypes = {
  form: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  null,
  null
)(CustomFields)
