import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

/* START internal notes modal actions */
export const launchNotesModal = form =>
  createAction(CONSTANTS.LAUNCH_NOTES_MODAL, {}, form)

export const saveInternalNotes = createAsyncFormAction(
  CONSTANTS.SAVE_INTERNAL_NOTES
)

export const lockInternalNotes = createAsyncFormAction(
  CONSTANTS.LOCK_INTERNAL_NOTES
)

export const unlockInternalNotes = createAsyncFormAction(
  CONSTANTS.UNLOCK_INTERNAL_NOTES
)
export const displayInternalNotes = form =>
  createAction(CONSTANTS.DISPLAY_INTERNAL_NOTES, {}, form)

export const notifyInternalNotesModalClosed = form =>
  createAction(CONSTANTS.NOTIFY_INTERNAL_NOTES_MODAL_CLOSED, {}, form)
