import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const searchSalesOpportunities = data => {
  const options = createApiOptions({
    body: { ...data },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesopportunities/search`, options)
}

export const createTasks = data => {
  const options = createApiOptions({
    body: { ...data },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/salesopportunities/createtasks`, options)
}