import {
  call,
  put,
  putResolve,
  fork,
  take,
  select,
  spawn,
  actionChannel
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { lazy } from 'react'
import uuid from 'uuid'
import { api } from 'services'
import { getIn, noop, isMultiInstance } from 'utils'
import { addModal } from 'modals/actions'
import { warningModal } from 'modals/sagas'
import { tryGetModule } from 'pages/Layout/sagas'
import { add, changeTab } from 'pages/Layout/actions'
import { ADD_SCREEN_IN_MODAL } from 'ddiForm/constants'
import { SCREEN_DROP_DOWN_OPEN } from 'components/Search/constants'
import { isValidElementType } from 'react-is'
import { propertyChange } from 'pages/SalesOrder/api'
import { onPropertyChange } from 'pages/SalesOrder/actions'
import {
  screenDropDownOpenSuccess,
  screenDropDownOpenError
} from 'components/Search/actions'
import { initialize, destroy } from 'ddiForm/actions'
import { handleAPIResponse } from 'pages/SalesOrder/sagas/searchAreaSagas'
import {
  getFormSelector,
  // mapResponse,
  getMapResponse,
  createMasterOptions
} from 'ddiForm/utils'
import { handleEntityResponse } from 'ddiForm/MasterScreen/sagas'
// import { cancelEditAsync } from 'ddiForm/MasterScreen/actions'
import * as dashboardActions from 'pages/Dashboard/actions'
import * as dashboardDataProviderActions from 'pages/Dashboard/components/DashboardDataProvider/actions'
import * as MAIN_CONSTANTS from 'pages/Main/constants'
import { toggleNav } from 'pages/Main/actions'
import * as MOBILE_CONSTANTS from 'mobile/constants'
import { DASHBOARD_DETAILS_OPEN, TOGGLE_TILE } from 'pages/Dashboard/constants'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import { onOpenProcess } from 'pages/UserMail/sagas'
import { grantAccessProcess } from './sagas'

const MobileSalesOrder = lazy(() => import('../mobile/pages/SalesOrder'))

export const showMaxScreenWarning = function* showMaxScreenWarning(options) {
  yield putResolve({ type: 'REQUEST_CANCELED', meta: {}, payload: {} })
  // debugger
  yield call(
    warningModal,
    `Maximum number of "${options.title}" screens are opened.`,
    'Warning'
  )
}
export const initialization = function* initialization({
  formName,
  options,
  response,
  proc,
  guid,
  screen
}) {
  // debugger
  if (response && response.guid) {
    formName = `${formName}-${response.guid}`
  }

  yield put(
    initialize(formName || options.form, {
      ...options,
      meta: response
    })
  )
  if (proc) {
    yield fork(proc, { formName, screen, guid })
  }
}

export const initializationMobile = function* initializationMobile({
  formName,
  options,
  response,
  proc,
  guid,
  screen
}) {
  // debugger
  if (response && response.guid) {
    formName = `${formName}-${response.guid}`
  }

  yield put(
    initialize(formName || options.form, {
      ...options,
      meta: response
    })
  )
  if (proc) {
    yield fork(proc, { formName, screen, guid })
  }
}

const errorMessages = [
  'Please close one of these tabs to proceed.',
  'he maximum number of instances'
]
const checkError = (msg = '') =>
  msg.length > 0 && errorMessages.some(el => msg.includes(el))

export const getMetaMobile = function* getMeta({
  options,
  proc,
  formName,
  errProc = noop,
  activeKey,
  route,
  screen
}) {
  // debugger
  const args = {
    name: route || formName,
    activeKey
  }

  if (isMultiInstance(route)) {
    args.newInstance = true
  }

  const apiCall = api.getMeta
  const { response, error } = yield call(apiCall, args)
  // debugger
  if (response) {
    if (response.guid) {
      options = screen.options
      // options.form = `${options.form || formName}-${response.guid}`
      formName = options.form
    }
    // debugger
    yield fork(initialization, {
      formName,
      options: { ...options, activeKey, route },
      proc,
      response,
      guid: response.guid,
      screen
    })
  } else if (errProc === noop) {
    if (error.status === 496) {
      // debugger
      yield putResolve({ type: 'REQUEST_CANCELED', meta: {}, payload: {} })
      // if (error.message.includes('he maximum number of instances')) {
      if (checkError(error.message)) {
        yield fork(showMaxScreenWarning, options)
      } else if (error.message) {
        yield call(warningModal, error.message, 'Error!')
      }
    }
  } else {
    yield fork(errProc, error)
  }
}
// export const getMetaMobile = function* getMeta({
//   options,
//   proc,
//   formName,
//   errProc = noop,
//   activeKey,
//   route,
//   screen
// }) {
//   // debugger
//   const args = {
//     name: route || formName,
//     activeKey
//   }

//   if (isMultiInstance(route)) {
//     args.newInstance = true
//   }

//   const apiCall = api.getMeta
//   const { response, error } = yield call(apiCall, args)
//   // debugger
//   if (response) {
//     if (response.guid) {
//       options = screen.options
//       // options.form = `${options.form || formName}-${response.guid}`
//       formName = options.form
//     }
//     // debugger
//     yield fork(initialization, {
//       formName,
//       options: { ...options, activeKey, route },
//       proc,
//       response,
//       guid: response.guid,
//       screen
//     })
//   } else if (errProc === noop) {
//     if (error.status === 496) {
//       debugger
//       yield putResolve({ type: 'REQUEST_CANCELED', meta: {}, payload: {} })
//       // if (error.message.includes('he maximum number of instances')) {
//       if (checkError(error.message)) {
//         yield fork(showMaxScreenWarning, options)
//       }
//     }
//   } else {
//     yield fork(errProc, error)
//   }
// }

export const getMeta = function* getMeta({
  options,
  proc,
  formName,
  errProc = noop,
  activeKey,
  route,
  screen
}) {
  // debugger
  const args = {
    name: route || formName,
    activeKey
  }

  if (isMultiInstance(route)) {
    args.newInstance = true
  }

  const apiCall = api.getMeta
  const { response, error } = yield call(apiCall, args)
  // debugger
  if (response) {
    if (response.guid) {
      options = screen.options
      // options.form = `${options.form || formName}-${response.guid}`
      formName = options.form
    }
    // debugger
    yield fork(initialization, {
      formName,
      options: { ...options, activeKey, route },
      proc,
      response,
      guid: response.guid,
      screen
    })
  } else if (errProc === noop) {
    if (error.status === 496) {
      debugger
      yield putResolve({ type: 'REQUEST_CANCELED', meta: {}, payload: {} })
      // if (error.message.includes('he maximum number of instances')) {
      if (checkError(error.message)) {
        yield fork(showMaxScreenWarning, options)
      } else if (error.message) {
        yield call(warningModal, error.message, 'Error!')
      }
    }
  } else {
    yield fork(errProc, error)
  }
}

export const mobileInitializeScreen = function* mobileInitializeScreen({
  link,
  name,
  screen,
  proc,
  startTab,
  special,
  activeKey,
  route,
  title,
  dataId,
  ...rest
}) {
  // debugger
  const isMulti = isMultiInstance(route)
  let options = {}

  screen = screen.default ? screen.default : screen

  if (screen.options) {
    options = screen.options
  }

  let formName
  if (options.form) {
    formName = options.form
  } else {
    // debugger
    formName = route
  }

  const formState = yield select(getFormSelector(formName))
  // debugger
  if (isMulti) {
    // how many are open
    const openScreens = yield select(state => getIn(state, 'layout.screens'))
    const instances = openScreens.filter((v, k) => k.includes(formName)).size
    if (instances >= 5) {
      yield fork(showMaxScreenWarning, options)
      return
    }
  } else if (formState) {
    // not sure if there are other screens which we will need to account for.

    yield put({ type: 'REQUEST_CANCELED' })
    // prob need the arg of dataId to get the read...
    // debugger
    yield put(
      changeTab({
        screen: formName,
        name,
        startTab,
        special,
        activeKey,
        route,
        title,
        dataId,
        ...rest
      })
    )
    // }
    return
  } else if (startTab) {
    // this might not be needed anymore... (access stuff.)

    options = {
      ...options,
      masterOptions: {
        ...options.masterOptions,
        selectedPrimaryTab: startTab.primary,
        selectedSecondaryTab: startTab.secondary
      },
      openedFrom: startTab.primary
    }
  }
  // debugger
  yield fork(getMeta, {
    formName,
    options,
    proc,
    route,
    activeKey,
    screen
  })
  // }
}
// export const mobileInitializeScreen = function* mobileInitializeScreen({
//   name,
//   screen,
//   proc,
//   startTab,
//   special,
//   activeKey,
//   route,
//   title,
//   dataId,
//   ...rest
// }) {
//   // debugger
//   const isMulti = isMultiInstance(route)
//   let options = {}

//   screen = screen.default ? screen.default : screen

//   if (screen.options) {
//     options = screen.options
//   }

//   let formName
//   if (options.form) {
//     formName = options.form
//   } else {
//     // debugger
//     formName = route
//   }

//   const formState = yield select(getFormSelector(formName))
//   // debugger
//   if (isMulti) {
//     // how many are open
//     const openScreens = yield select(state => getIn(state, 'layout.screens'))
//     const instances = openScreens.filter((v, k) => k.includes(formName)).size
//     if (instances >= 5) {
//       yield fork(showMaxScreenWarning, options)
//       return
//     }
//   } else if (formState) {
//     // not sure if there are other screens which we will need to account for.

//     yield put({ type: 'REQUEST_CANCELED' })
//     // prob need the arg of dataId to get the read...
//     // debugger
//     yield put(
//       changeTab({
//         screen: formName,
//         name,
//         startTab,
//         special,
//         activeKey,
//         route,
//         title,
//         dataId,
//         ...rest
//       })
//     )
//     // }
//     return
//   } else if (startTab) {
//     // this might not be needed anymore... (access stuff.)

//     options = {
//       ...options,
//       masterOptions: {
//         ...options.masterOptions,
//         selectedPrimaryTab: startTab.primary,
//         selectedSecondaryTab: startTab.secondary
//       },
//       openedFrom: startTab.primary
//     }
//   }
//   // debugger
//   yield fork(getMeta, {
//     formName,
//     options,
//     proc,
//     route,
//     activeKey,
//     screen
//   })
//   // }
// }

export const initializeScreen = function* initializeScreen({
  name,
  screen,
  proc,
  startTab,
  special,
  activeKey,
  route,
  title,
  dataId,
  ...rest
}) {
  // debugger
  const isMulti = isMultiInstance(route)
  let options = {}

  screen = screen.default ? screen.default : screen

  if (screen.options) {
    options = screen.options
  }

  let formName
  if (options.form) {
    formName = options.form
  } else {
    // debugger
    formName = route
  }

  const formState = yield select(getFormSelector(formName))
  // debugger
  if (isMulti) {
    // how many are open
    const openScreens = yield select(state => getIn(state, 'layout.screens'))
    const instances = openScreens.filter((v, k) => k.includes(formName)).size
    if (instances >= 5) {
      yield fork(showMaxScreenWarning, options)
      return
    }
  } else if (formState) {
    // not sure if there are other screens which we will need to account for.

    yield put({ type: 'REQUEST_CANCELED' })
    // prob need the arg of dataId to get the read...
    // debugger
    yield put(
      changeTab({
        screen: formName,
        name,
        startTab,
        special,
        activeKey,
        route,
        title,
        dataId,
        ...rest
      })
    )
    // }
    return
  } else if (startTab) {
    // this might not be needed anymore... (access stuff.)

    options = {
      ...options,
      masterOptions: {
        ...options.masterOptions,
        selectedPrimaryTab: startTab.primary,
        selectedSecondaryTab: startTab.secondary
      },
      openedFrom: startTab.primary
    }
  }
  // debugger
  yield fork(getMeta, {
    formName,
    options,
    proc,
    route,
    activeKey,
    screen
  })
  // }
}
/*
if there's a startTab
check if there's an "access" saga

fire that over calling the traditional meta for the screen's route
*/

export const mobileOpenScreenProcess = function* openScreenProcess({
  link,
  // screen,
  // image,
  // name,
  // title,
  // startTab,
  // disableMeta,
  // meta,
  // dataId,
  // parentId,
  // parentType,
  // recordName,
  // recordType,
  // groupNames,
  // warehouseId,
  // uomId,
  // special,
  // activeKey,
  // route,
  ...rest
}) {
  // if (!screen) {
  //   throw new Error('an issue has happened with loading the screen')
  // }
  // do we need to get meta then get a record?
  // debugger
  // let options = {}
  // screen = screen.default ? screen.default : screen

  // // if (screen.options) {
  // //   options = screen.options
  // // }
  // // if (disableMeta) {
  // //   options.disableMeta = true
  // // }
  // // const isMulti = isMultiInstance(route)

  // // const addScreen = function* addScreen(opts = options, guid) {
  // const addScreen = function* addScreen(form, newScreen, guid) {
  //   // debugger
  //   return yield putResolve(
  //     add({
  //       component: newScreen || (screen.default ? screen.default : screen),
  //       data: { ...rest, guid },
  //       image,
  //       // name,
  //       form /* opts.allowMultiple? `${opts.form}-${uuid.v1()}` : */, // form,
  //       title,
  //       allowMultiple: !!guid
  //     })
  //   )
  // }
  // just add the screen if it's not a ddiForm (rare but possible)
  // if (!isMulti && !screen.ddiForm) {
  //   yield call(addScreen)
  //   return
  // }

  const proc = function* procc({ formName, screen: newScreen, guid }) {
    let errHolder
    let responseHolder

    const formState = yield select(getFormSelector(formName))
    // debugger
    const { guid: deadGuid, screenOpenData, ...args } = rest
    if (
      formName.includes('salesOrder') &&
      guid &&
      Reflect.ownKeys(args).length
    ) {
      const groupNames = ['detail', 'header', 'final']
      if (args.read) {
        const { error, response } = yield call(api.read, {
          name: formName,
          dataId,
          guid,
          groupNames
        })
        errHolder = error
        responseHolder = response
      } else {
        const salesOrderArgs = {
          properties: args,
          guid,
          // createNew: true,
          groupNames
        }

        const { response, error } = yield call(propertyChange, salesOrderArgs)

        if (response) {
          const mapResponse = getMapResponse({ formState, tabIds: groupNames })
          const R = mapResponse({
            response,
            tabIds: groupNames,
            formState,
            groupNames
          })

          delete R.record.detail.lineItems
          // weird, i know... the propertyChange success somehow creates a field  ¯\_(ツ)_/¯
          yield put(onPropertyChange.success(R, formName))
        } else {
          yield put(onPropertyChange.failure(error, formName))
        }
      }
    } else if (dataId || recordName) {
      // debugger
      // if (['contact', 'customerMaster'].includes(formName)) {
      //   debugger
      // }
      yield put(masterActions.getEntityAsync.request(formName))

      const { error, response } = yield call(api.read, {
        name: formName,
        dataId,
        recordName,
        recordType,
        parentId,
        parentType,
        groupNames,
        warehouseId,
        uomId
      })
      errHolder = error
      responseHolder = response
    }
    // debugger
    yield fork(addScreen, formName, newScreen, guid)
    if (responseHolder) {
      // debugger
      const mapResponse = getMapResponse({ formState })

      const responseData =
        groupNames && groupNames.length && formState
          ? mapResponse({ response: responseHolder, groupNames, formState })
          : responseHolder
      yield putResolve(
        masterActions.getEntityAsync.success(responseData, {
          ...meta,
          form: formName
        }) // TODO GENERICS... args of cb succ/fail takes async method etc..
      )
      // debugger
      yield fork(handleEntityResponse, options.form, responseHolder)
    } else if (errHolder) {
      yield putResolve(
        masterActions.getEntityAsync.failure(errHolder, {
          ...meta,
          form: formName
        })
      )
    }
    // debugger
  }

  // debugger
  yield fork(mobileInitializeScreen, {
    link,
    proc,
    // startTab,
    // name,
    // title,
    // special,
    // activeKey,
    // route,
    // image,
    // groupNames,
    // dataId,
    // parentId,
    // parentType,
    // recordName,
    // recordType,
    ...rest
  })
  // debugger
}
// export const mobileOpenScreenProcess = function* openScreenProcess({
//   link,
//   screen,
//   image,
//   name,
//   title,
//   startTab,
//   disableMeta,
//   meta,
//   dataId,
//   parentId,
//   parentType,
//   recordName,
//   recordType,
//   groupNames,
//   warehouseId,
//   uomId,
//   special,
//   activeKey,
//   route,
//   ...rest
// }) {
//   if (!screen) {
//     throw new Error('an issue has happened with loading the screen')
//   }
//   // do we need to get meta then get a record?
//   // debugger
//   let options = {}
//   screen = screen.default ? screen.default : screen

//   if (screen.options) {
//     options = screen.options
//   }
//   if (disableMeta) {
//     options.disableMeta = true
//   }
//   const isMulti = isMultiInstance(route)

//   // const addScreen = function* addScreen(opts = options, guid) {
//   const addScreen = function* addScreen(form, newScreen, guid) {
//     // debugger
//     return yield putResolve(
//       add({
//         component: newScreen || (screen.default ? screen.default : screen),
//         data: { ...rest, guid },
//         image,
//         // name,
//         form /* opts.allowMultiple? `${opts.form}-${uuid.v1()}` : */, // form,
//         title,
//         allowMultiple: !!guid
//       })
//     )
//   }
//   // just add the screen if it's not a ddiForm (rare but possible)
//   if (!isMulti && !screen.ddiForm) {
//     yield call(addScreen)
//     return
//   }

//   const proc = function* procc({ formName, screen: newScreen, guid }) {
//     let errHolder
//     let responseHolder

//     const formState = yield select(getFormSelector(formName))
//     // debugger
//     const { guid: deadGuid, screenOpenData, ...args } = rest
//     if (
//       formName.includes('salesOrder') &&
//       guid &&
//       Reflect.ownKeys(args).length
//     ) {
//       const groupNames = ['detail', 'header', 'final']
//       if (args.read) {
//         const { error, response } = yield call(api.read, {
//           name: formName,
//           dataId,
//           guid,
//           groupNames
//         })
//         errHolder = error
//         responseHolder = response
//       } else {
//         const salesOrderArgs = {
//           properties: args,
//           guid,
//           // createNew: true,
//           groupNames
//         }

//         const { response, error } = yield call(propertyChange, salesOrderArgs)

//         if (response) {
//           const mapResponse = getMapResponse({ formState, tabIds: groupNames })
//           const R = mapResponse({
//             response,
//             tabIds: groupNames,
//             formState,
//             groupNames
//           })

//           delete R.record.detail.lineItems
//           // weird, i know... the propertyChange success somehow creates a field  ¯\_(ツ)_/¯
//           yield put(onPropertyChange.success(R, formName))
//         } else {
//           yield put(onPropertyChange.failure(error, formName))
//         }
//       }
//     } else if (dataId || recordName) {
//       // debugger
//       // if (['contact', 'customerMaster'].includes(formName)) {
//       //   debugger
//       // }
//       yield put(masterActions.getEntityAsync.request(formName))

//       const { error, response } = yield call(api.read, {
//         name: formName,
//         dataId,
//         recordName,
//         recordType,
//         parentId,
//         parentType,
//         groupNames,
//         warehouseId,
//         uomId
//       })
//       errHolder = error
//       responseHolder = response
//     }
//     // debugger
//     yield fork(addScreen, formName, newScreen, guid)
//     if (responseHolder) {
//       // debugger
//       const mapResponse = getMapResponse({ formState })

//       const responseData =
//         groupNames && groupNames.length && formState
//           ? mapResponse({ response: responseHolder, groupNames, formState })
//           : responseHolder
//       yield putResolve(
//         masterActions.getEntityAsync.success(responseData, {
//           ...meta,
//           form: formName
//         }) // TODO GENERICS... args of cb succ/fail takes async method etc..
//       )
//       // debugger
//       yield fork(handleEntityResponse, options.form, responseHolder)
//     } else if (errHolder) {
//       yield putResolve(
//         masterActions.getEntityAsync.failure(errHolder, {
//           ...meta,
//           form: formName
//         })
//       )
//     }
//     // debugger
//   }

//   // end "proc"
//   // debugger
//   // shortcut the meta call (prob won't be doing this much anymore...)
//   if (options.disableMeta) {
//     yield fork(proc, {})
//     return
//   }
//   // debugger
//   yield fork(initializeScreen, {
//     screen,
//     proc,
//     startTab,
//     name,
//     title,
//     special,
//     activeKey,
//     route,
//     image,
//     groupNames,
//     dataId,
//     parentId,
//     parentType,
//     recordName,
//     recordType,
//     ...rest
//   })
//   // debugger
// }

export const openScreenProcess = function* openScreenProcess({
  screen,
  image,
  name,
  title,
  startTab,
  disableMeta,
  meta,
  dataId,
  parentId,
  parentType,
  productId,
  recordName,
  recordType,
  groupNames,
  warehouseId,
  uomId,
  special,
  activeKey,
  route,
  ...rest
}) {
  if (!screen) {
    throw new Error('an issue has happened with loading the screen')
  }
  // do we need to get meta then get a record?
  // debugger
  let options = {}
  screen = screen.default ? screen.default : screen

  if (screen.options) {
    options = screen.options
  }
  if (disableMeta) {
    options.disableMeta = true
  }
  const isMulti = isMultiInstance(route)

  // const addScreen = function* addScreen(opts = options, guid) {
  const addScreen = function* addScreen(form, newScreen, guid) {
    // debugger
    return yield putResolve(
      add({
        component: newScreen || (screen.default ? screen.default : screen),
        data: { ...rest, guid },
        image,
        // name,
        form /* opts.allowMultiple? `${opts.form}-${uuid.v1()}` : */, // form,
        title,
        allowMultiple: !!guid
      })
    )
  }
  // just add the screen if it's not a ddiForm (rare but possible)
  if (!isMulti && !screen.ddiForm) {
    if (title === 'Dashboard') {
      const s = yield select(s => s.get('layout'))
      if (s.get('openScreens').get('dashboard')) {
        yield put(toggleNav(false))
        yield put({ type: 'REQUEST_CANCELED', payload: {} })
        yield put(
          changeTab({
            screen: 'dashboard',
            name,
            startTab,
            special,
            activeKey,
            route,
            title,
            dataId,
            ...rest
          })
        )
        return
      }
    }
    yield call(addScreen)
    return
  }

  const proc = function* procc({ formName, screen: newScreen, guid }) {
    let errHolder
    let responseHolder
    console.log(formName, screen, guid)
    // debugger
    const formState = yield select(getFormSelector(formName))

    const { guid: deadGuid, screenOpenData, ...args } = rest
    if (
      formName.includes('salesOrder') &&
      guid &&
      Reflect.ownKeys(args).length
    ) {
      const groupNames = ['detail', 'header', 'final']
      if (args.read) {
        const { error, response } = yield call(api.read, {
          name: formName,
          dataId,
          guid,
          groupNames
        })
        errHolder = error
        responseHolder = response
      } else {
        const salesOrderArgs = {
          properties: args,
          guid,
          // createNew: true,
          groupNames
        }

        const { response, error } = yield call(propertyChange, salesOrderArgs)

        if (response) {
          const mapResponse = getMapResponse({ formState, tabIds: groupNames })
          const R = mapResponse({
            response,
            tabIds: groupNames,
            formState,
            groupNames
          })

          delete R.record.detail.lineItems
          // weird, i know... the propertyChange success somehow creates a field  ¯\_(ツ)_/¯
          yield put(onPropertyChange.success(R, formName))
        } else {
          yield put(onPropertyChange.failure(error, formName))
        }
      }
    } else if (dataId || recordName || productId) {
      // debugger
      // if (['contact', 'customerMaster'].includes(formName)) {
      //   debugger
      // }
      yield put(masterActions.getEntityAsync.request(formName))

      const { error, response } = yield call(api.read, {
        name: formName,
        dataId,
        recordName,
        recordType,
        parentId,
        parentType,
        productId,
        groupNames,
        warehouseId,
        uomId
      })
      errHolder = error
      responseHolder = response
    }

    if (!errHolder) {
      yield fork(addScreen, formName, newScreen, guid)
    }
    if (responseHolder) {
      // debugger
      const mapResponse = getMapResponse({ formState })

      const responseData =
        groupNames && groupNames.length && formState
          ? mapResponse({ response: responseHolder, groupNames, formState })
          : responseHolder
      yield putResolve(
        masterActions.getEntityAsync.success(responseData, {
          ...meta,
          form: formName
        }) // TODO GENERICS... args of cb succ/fail takes async method etc..
      )
      yield fork(handleEntityResponse, formName, responseHolder)
    } else if (errHolder) {
      yield putResolve(
        masterActions.getEntityAsync.failure(errHolder, {
          ...meta,
          form: formName
        })
      )
      yield call(warningModal, errHolder.message, 'Error!')

      yield put(destroy(formName))
    }
    // debugger
  }

  // end "proc"
  // debugger
  // shortcut the meta call (prob won't be doing this much anymore...)
  if (options.disableMeta) {
    // debugger
    yield fork(proc, { formName: options.form, screen })
    return
  }
  yield fork(initializeScreen, {
    screen,
    proc,
    startTab,
    name,
    title,
    special,
    activeKey,
    route,
    image,
    groupNames,
    dataId,
    parentId,
    parentType,
    recordName,
    recordType,
    ...rest
  })
  // debugger
}

const stripWhiteSpace = (str = '', regex = /\s/g) => str.replace(regex, '')
const stripAndLower = (str = '') => stripWhiteSpace(str).toLowerCase()

export const screenOpenWorkflow = function* screenOpenWorkflow({
  payload,
  meta
}) {
  const { title, activeKey } = payload
  const { route } = payload
  // yield console.log(payload, meta)

  let screen

  let originRoute = route || payload.name
  if (originRoute && stripAndLower(title) !== stripAndLower(originRoute)) {
    if (originRoute.includes('master')) {
      if (originRoute.length) {
        originRoute = `${originRoute[0].toUpperCase()}${originRoute.substr(1)}`
        originRoute = originRoute.replace('master', 'Master')
      }

      screen = yield call(tryGetModule, originRoute)
      payload = {
        ...payload,
        special: true,
        startTab: payload.startTab || { primary: activeKey }
      }
    }
  }

  if (!screen) {
    screen = yield call(tryGetModule, title)
  }
  // needs tweak to openScreenProcess
  // debugger
  yield fork(openScreenProcess, { screen, meta, ...payload })
}
export const mobileScreenOpenWorkflow = function* mobileScreenOpenWorkflow({
  payload,
  meta
}) {
  const { link } = payload

  yield put(push(link))
  yield putResolve({ type: 'REQUEST_CANCELED', meta: {}, payload: {} })
}

export const openDashboardDetailsProcess = function* openDashboardDetailsProcess(
  screen,
  response,
  payload,
  meta
) {
  let options = {}
  console.log(screen, response, payload, meta)

  screen = screen.default ? screen.default : screen
  options = screen.options || options
  const addScreen = function* addScreen(opts) {
    return yield put(
      add({
        component: screen,
        // data: rest,
        // image,
        image: payload.image,
        name: options.title.replace('dashboards.', ''),
        // name: options.title,
        title: payload.dataId
      })
    )
  }
  yield console.log(screen, response, meta, options)
  const proc = function* procc(resp) {
    // yield put(dashboardActions.dashboardDetailsOpen.success(response))
    console.log(screen, response, resp, meta, options)
    // debugger
    yield putResolve(initialize(options.form, { ...options }))
    yield putResolve(
      dashboardDataProviderActions.saveScreenOpenData(options.form, {
        screenOpenData: resp
      })
    )
    yield fork(addScreen)
  }
  yield fork(proc, response)
  // yield call(addScreen)
}

export const openDashboardDetailWorkflow = function* openDashboardDetailWorkflow({
  payload,
  meta
}) {
  // yield console.log(payload, meta)
  const proc = function* procc(response) {
    //
    yield console.log(payload, response)
    const screen = yield call(
      tryGetModule,
      `${payload.title.replace(/\s|%/g, '')}Details`
    )
    yield fork(openDashboardDetailsProcess, screen, response, payload, meta)
    // yield console.log(args, screen, t)
  }
  const { response, error } = yield call(api.viewDashboardDetails, {
    dataId: payload.dataId
  })
  if (response) {
    yield fork(proc, response)
  } else {
    yield put(dashboardActions.dashboardDetailsOpen.failure(error))
  }
}

const toggleDashboardProcess = function* toggleDashboardProcess(
  item,
  shouldAdd,
  meta
) {
  const { id } = item

  const mapping = shouldAdd
    ? { action: dashboardActions.addTile, method: api.addDashboardItem }
    : { action: dashboardActions.removeTile, method: api.removeDashboardItem }

  const proc = function* procc(resp) {
    yield putResolve(mapping.action.success(resp, meta))
  }

  /* 
    Marc -- this is exclusively for User Mail tile, which for some reason now 
    needs a GUID just to close the tile. Without the guid, this routine will break
    and there will be a JS error -- SVE 8/7/2020
  */
  const apiParams =
    id === 'Mail' && !shouldAdd
      ? {
          dataId: id,
          guid: yield select(state =>
            getIn(state, 'dashboard.tiles.Mail.tile.data.guid')
          )
        }
      : {
          dataId: id
        }

  const { response, error } = yield call(mapping.method, apiParams)

  if (response) {
    yield fork(proc, response)
  } else {
    yield put(mapping.action.failure(error, meta))
  }
}

export const toggleDashboardTileWorkflow = function* toggleDashboardTileWorkflow({
  payload,
  meta
}) {
  const { checked, item } = payload

  yield fork(toggleDashboardProcess, item, checked, meta)
}

export const openMailWorkflow = function* openMailWorkflow() {
  const layout = yield select(state => getIn(state, 'layout.openScreens'))
  if (!getIn(layout, 'userMail')) {
    // yield fork
    const screen = yield call(tryGetModule, 'UserMail')
    const { options } = screen.default
    const { form } = options

    const addScreen = function* addScreen(opts) {
      return yield putResolve(
        add({
          component: screen.default ? screen.default : screen,
          name: 'UserMail',
          title: 'User Mail'
          // data: rest,
          // image,
        })
      )
    }
    const proc = function* procc(resp) {
      yield putResolve(initialize('userMail', { ...options }))
      yield fork(addScreen)
      yield fork(onOpenProcess, form, resp)
    }
    yield fork(grantAccessProcess, {
      apiMethod: api.openMailScreen,
      proc
    })
  }
}

export const screenDropDownWorkflow = function* screenDropDownWorkflow({
  payload,
  meta
}) {
  yield console.log(payload, meta)
  // const { options } = payload.component
  const { options = {} } = payload

  const form = options.form || payload.form

  const proc = function* dropDownProc() {
    yield putResolve(screenDropDownOpenSuccess({ meta }))
  }

  const errorProc = function* errorProc(error) {
    yield put(screenDropDownOpenError, { meta })
  }

  yield fork(getMeta, { options, proc, formName: form, errorProc })
}

export const addScreenInModalWorkflow = function* addScreenInModalWorkflow({
  payload,
  meta
}) {
  const {
    screen,
    apiMethod,
    apiArgs,
    additionalApiArgs,
    formName,
    modalOptions,
    onApiSuccess,
    onApiError
  } = payload
  const { options } = screen
  if (options) {
    const newOpts = {
      ...options,
      form: formName // `${meta.form}.${options.form}`
    }

    yield call(initializeScreen, { screen })

    const formData = yield select(getFormSelector(meta.form))

    let args
    if (apiArgs) {
      if (typeof apiArgs === 'function') {
        args = apiArgs(formData)
      } else {
        args = Object.keys(apiArgs).reduce((acc, next) => {
          console.log(acc, next)
          if (typeof apiArgs[next] === 'function') {
            acc[next] = apiArgs[next](formData)
          } else {
            acc[next] = apiArgs[next]
          }
          return acc
        }, {})
      }
    }

    if (apiMethod) {
      const { response, error } = yield call(apiMethod, args)
      console.log(response, error)
      if (response) {
        // console.log(options, newOpts, apiMethod, apiArgs, formData.toJS(), args)
        // yield put(initialize(newOpts.form, { ...newOpts }))

        if (onApiSuccess && typeof onApiSuccess === 'function') {
          yield call(onApiSuccess, response, meta, formName)
        }
        const opts = modalOptions
          ? { options: { ...modalOptions }, component: screen }
          : {
              component: screen
            }
        const modal = yield call(addModal, meta.form, opts)
        yield put(modal)
      } else if (onApiError && typeof onApiError === 'function') {
        yield call(onApiError, error)
      }
    } else {
      // no api method? i guess load the screen...
    }
  }
}

export default function*() {
  const actionChan = yield actionChannel(
    action => action.meta && action.meta.withAccess
  )

  while (true) {
    const { type, payload, meta } = yield take(actionChan)
    // debugger
    switch (type) {
      case MOBILE_CONSTANTS.OPEN_SCREEN: {
        yield spawn(mobileScreenOpenWorkflow, { payload, meta })
        break
      }
      case MAIN_CONSTANTS.OPEN_SCREEN: {
        yield spawn(screenOpenWorkflow, { payload, meta })
        break
      }
      case DASHBOARD_DETAILS_OPEN.REQUEST: {
        yield fork(openDashboardDetailWorkflow, { payload, meta })
        break
      }
      case TOGGLE_TILE.REQUEST: {
        yield fork(toggleDashboardTileWorkflow, { payload, meta })
        break
      }
      case MAIN_CONSTANTS.TRY_OPEN_MAIL: {
        yield fork(openMailWorkflow)
        break
      }
      case ADD_SCREEN_IN_MODAL: {
        yield fork(addScreenInModalWorkflow, { payload, meta })
        break
      }
      case SCREEN_DROP_DOWN_OPEN.REQUEST: {
        yield fork(screenDropDownWorkflow, { payload, meta })
        break
      }
      default: {
        console.log(type, payload, meta)
      }
    }
  }
}
