/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import styles from './styles/associated-fields-wrapper.scss'
import './styles/associated-fields-wrapper.scss'

export class AssociatedFieldsWrapper extends Component {
  static propTypes = {
    bootstrapClasses: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string
  }

  static defaultProps = {
    bootstrapClasses: {
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12
    },
    className: 'associated-fields',
    style: {},
    type: 'div'
  }

  // constructor(...args) {
  //   super(...args)
  //   this.el = this.renderOptions(this.props.type)
  // }
  // renderOptions = renderType => {
  //   const { bootstrapClasses, className, style, type } = this.props

  //   const children = React.Children.map(this.props.children, (child, index) => {
  //     console.log('map', child)
  //     return React.cloneElement(child, { ...child.props })
  //   })

  //   const options = {
  //     col: () => (
  //       <Col {...bootstrapClasses} style={style} className={className}>
  //         {children}
  //       </Col>
  //     ),
  //     div: () => (
  //       <div className={className} style={style} className={className}>
  //         {children}
  //       </div>
  //     ),
  //     row: () => (
  //       <Row style={style} className={className} className={className}>
  //         {children}
  //       </Row>
  //     )
  //   }
  //   return (options[renderType] || options.div)()
  // }

  render() {
    const { className, style } = this.props

    return (
      <div className={className} style={style} className={className}>
        {this.props.children}
      </div>
    )
  }
}

// export default CSSModules(AssociatedFieldsWrapper, styles, {
//   allowMultiple: true
// })

export default AssociatedFieldsWrapper
