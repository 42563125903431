import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const EVENT_MODAL = '@@EVENT_MODAL/'
export const eventModal = '@@eventModal'

export const ADD_EVENT_TYPE = createAsyncRequestTypes(
  `${eventModal}/ADD_EVENT_TYPE`
)
export const GET_EVENT_TYPES = createAsyncRequestTypes(
  `${eventModal}/GET_EVENT_TYPES`
)
export const VALIDATE_EVENT_TYPE = createAsyncRequestTypes(
  `${eventModal}/VALIDATE_EVENT_TYPE`
)
export const CREATE_EVENT_TYPE = createAsyncRequestTypes(
  `${eventModal}/CREATE_EVENT_TYPE`
)
export const LOCK_EVENT_TYPE = createAsyncRequestTypes(
  `${eventModal}/LOCK_EVENT_TYPE`
)
export const UNLOCK_EVENT_TYPE = createAsyncRequestTypes(
  `${eventModal}/UNLOCK_EVENT_TYPE`
)
export const GET_SERVER_TIME = createAsyncRequestTypes(
  `${eventModal}/GET_SERVER_TIME`
)

export const OPEN_EVENT_MODAL = `${eventModal}/OPEN_EVENT_MODAL`
export const CLEAR_NEW_EVENT_TYPE_POPOVER = `${eventModal}/CLEAR_NEW_EVENT_TYPE_POPOVER`

export const OPEN_SCREEN = `${EVENT_MODAL}/OPEN_SCREEN`

/* these have been updated with the new ddiForm modal */
export const INIT_EVENT_MODAL = `${EVENT_MODAL}/INIT_EVENT_MODAL`
export const REGISTER_RESPONSE_DATA = `${EVENT_MODAL}/REGISTER_RESPONSE_DATA`
export const CLEAR_FORM = `${EVENT_MODAL}/CLEAR_FORM`
// export const ADD_NEW_ACTIVITY = `${EVENT_MODAL}/ADD_NEW_ACTIVITY`
export const SAVE_ACTIVITY = createAsyncRequestTypes(
  `${EVENT_MODAL}/SAVE_ACTIVITY`
)
export const UNLOCK_ACTIVITY = createAsyncRequestTypes(
  `${EVENT_MODAL}/UNLOCK_ACTIVITY`
)
export const DELETE_ACTIVITY = createAsyncRequestTypes(
  `${EVENT_MODAL}/DELETE_ACTIVITY`
)
export const LOCK_ACTIVITY_SUCCESS = `${EVENT_MODAL}/LOCK_ACTIVITY_SUCCESS`

export const ADD_NEW_ACTIVITY = createAsyncRequestTypes(
  `${EVENT_MODAL}ADD_NEW_ACTIVITY`
)
export const LOCK_ACTIVITY = createAsyncRequestTypes(
  `${EVENT_MODAL}LOCK_ACTIVITY`
)
export const CLOSE_ACTIVITY_MODAL = `${EVENT_MODAL}/CLOSE_ACTIVITY_MODAL`

/* add & edit new event types */
export const SAVE_COLOR_SELECTION = `${EVENT_MODAL}/SAVE_COLOR_SELECTION`
export const CLOSE_EVENT_MODAL = `${EVENT_MODAL}/CLOSE_EVENT_MODAL`

export const SAVE_EVENT_TYPE = createAsyncRequestTypes(
  `${EVENT_MODAL}/SAVE_EVENT_TYPE`
)
export const DELETE_EVENT_TYPE = createAsyncRequestTypes(
  `${EVENT_MODAL}/DELETE_EVENT_TYPE`
)
export const CLEAR_EVENT_TYPE_FORM = `${EVENT_MODAL}/CLEAR_EVENT_TYPE_FORM`
export const ON_TOOLTIP_KEYPRESS = `${EVENT_MODAL}/ON_TOOLTIP_KEYPRESS`
export const SAVE_ACCESS_MODAL_ID = `${EVENT_MODAL}/SAVE_ACCESS_MODAL_ID`

/* AR Invoice Search */
export const AR_INVOICE_EXACT_MATCH = createAsyncRequestTypes(
  `${EVENT_MODAL}AR_INVOICE_EXACT_MATCH`
)
export const AR_INVOICE_EXACT_MATCH_URL = `${BASE_INFORM_API_URL}/search/invoice/exactmatch`

/* get purchase order tooltip */
export const GET_PURCHASE_ORDER_TOOLTIP = createAsyncRequestTypes(
  `${EVENT_MODAL}/GET_PURCHASE_ORDER_TOOLTIP`
)
export const GET_PURCHASE_ORDER_TOOLTIP_URL = `${BASE_INFORM_API_URL}/purchaseorderinquiry/summary`

/* get sales order tooltip */
export const GET_SALES_ORDER_TOOLTIP = createAsyncRequestTypes(
  `${EVENT_MODAL}/GET_SALES_ORDER_TOOLTIP`
)
export const GET_SALES_ORDER_TOOLTIP_URL = `${BASE_INFORM_API_URL}/salesorderinquiry/summary`

export const GET_ACTIVITY_TOOLTIP = createAsyncRequestTypes(
  `${EVENT_MODAL}/GET_TOOLTIP`
)
