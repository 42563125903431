/**
 * creates a Redux action from a series of parameters
 * @param {string} type - action name
 * @param {object} payload - action payload
 * @param {string} form - name of a ddiForm
 * @param {boolean} isError - is this an error action?
 * @returns {object} returns an action object with type / payload and meta form info if a form is specified
 */

const createAction = (type, payload = {}, form = null, isError = false, meta = {}) => {
  let action = { payload: { ...payload }, type }
  if (isError) {
    action.error = true
  }
  if (form) {
    action = {
      ...action,
      meta: {
        form,
        ...meta
      }
    }
  } else {
    action = {
      ...action,
      meta
    }
  }
  return action
}

export default createAction
