/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

// export const getAudits = ({ form, dataId, showAll = false, ...rest }) => {
//   const options = createApiOptions({
//     body: { dataId, showAll, ...rest },
//     method: 'post'
//   })

//   return callFetch(`${BASE_INFORM_API_URL}/${form}/audit/get`, options)
// }
export const getAudits = ({ form, dataId, showAll = false, ...rest }) => {
  const options = createApiOptions({
    body: {
      dataId,
      groupNames: ['audit'],
      showAll,
      ...rest
    },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/read`, options)
}
