/* eslint react/no-unused-state: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Switch,
  TextField,
  FormControlLabel
} from '@material-ui/core'
import { connect } from 'react-redux'
import { getIn } from 'utils'

import { Field } from 'ddiForm'

class ProductNotesModal extends Component {
  static propTypes = {
    appendToFinanceCharge: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    notesModalEnabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.data && props.data.note ? props.data.note : ''
    }
  }

  // componentDidMount() {
  //   console.log(this)
  // }

  onChange = e => this.setState({ value: e.target.value, isDirty: true })

  onBlur = e => {
    if (this.state.isDirty) {
      const { value } = e.target
      this.setState({ isDirty: false }, () => this.props.onBlur(value))
    }
  }

  render() {
    const { isEditing } = this.props
    const { value } = this.state

    return (
      <div style={{ width: '100%' }}>
        <TextField
          preventAutoDisable
          disabled={!isEditing}
          value={value}
          onChange={this.onChange}
          fullWidth
          multiline
          minWidth="100%"
          variant="outlined"
          style={{ width: '100%' }}
          inputRef={el => (this.notesField = el)}
        />
      </div>
    )
  }
}

// export default ProductNotesModal
const mapState = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps
  // console.log('Product notes Modal', ownProps, form)

  const formState = getIn(state, `ddiForm.${form}`)
  const isEditing = getIn(formState, 'isEditing') || false

  return {
    isEditing
  }
}

export default connect(
  mapState,
  null,
  null,
  { forwardRef: true }
)(ProductNotesModal)
