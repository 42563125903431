import * as DDICONSTANTS from 'ddiForm/constants'

// import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import {
  emptyList,
  getIn,
  setIn,
  mapColumnHeaders,
  fromJS,
  deleteIn,
  numToDollar
} from 'utils'

import moment from 'moment'
import CheckBox from 'grid/CheckBox'
import {
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'hoc/withRowTotals/utils'
import OrderLinkWithTooltip from './components/OrderLinkWithTooltip'

import * as CONSTANTS from './constants'
import PinnedRowRenderer from './components/PinnedRowRenderer'
// import {
//   INDEX_SEARCH,
//   FIND_NEXT
// } from '../../components/Search/IndexSearch/constants'

const menuTabs = ['filterMenuTab']
const fixedCols = [
  'dataId',
  'backOrderCycle',
  'orderDate',
  'shipDate',
  'customerName'
]
const fieldsWithTooltip = [
  'status',
  'shipViaId',
  'branchId',
  'writerId',
  'salesmanId'
]

const dateFields = ['shipDate', 'orderDate', 'expectedPODate', 'promisedDate']

const includeNegativeCellColor = [
  'quantity',
  'quantityAvailable',
  'quantityBO',
  'quantityCommitted',
  'quantityOnPO',
  'quantityOrdered',
  'quantityShipped'
]

const overrideClearingResults = ['printType']

export const getFieldMinWidth = field => {
  const extraLongFields = ['shipToInfo']
  const longFields = ['description']
  const smallFields = ['uomid', 'sortIndex', 'backOrderCycle']

  if (!field) {
    return 150
  }

  if (extraLongFields.includes(field)) {
    return 325
  }

  if (field && field === 'customerName') {
    return 200
  }

  if (longFields.includes(field)) {
    return 250
  }

  if (dateFields.includes(field) || (field && field === 'shipViaId')) {
    return 100
  }

  if (
    smallFields.includes(field) ||
    (fieldsWithTooltip.includes(field) && field !== 'shipViaId')
  ) {
    return 75
  }

  return 150
}

export const mapSOInquiryHeaders = (col, i) => {
  col = {
    ...col,
    menuTabs,
    pinnedRowCellRendererFramework: PinnedRowRenderer
  }

  if (col.field) {
    col = {
      ...col,
      sortable: true,
      colId: col.field,
      minWidth: getFieldMinWidth(col.field)
    }
  }

  if (fixedCols.includes(col.field)) {
    col = {
      ...col,
      pinned: 'left'
    }
  }

  if (fieldsWithTooltip.includes(col.field)) {
    col = {
      ...col,
      tooltipField: `${col.field}.tooltip`,
      valueGetter: params =>
        params.data[col.field] && params.data[col.field].value
    }

    if (col.field === 'status') {
      col = {
        ...col,
        tooltipComponent: 'customTooltip'
      }
    }
  }

  if (col.field && dateFields.includes(col.field)) {
    col = {
      ...col,
      valueFormatter: params =>
        params.value ? moment(params.value).format('M/D/YY') : params.value,
      ...dateFilterGridConfigs
    }
  }

  if (col.field === 'total') {
    // debugger
    col = {
      ...col,
      cellStyle(params) {
        const ret = { 'text-align': 'right' }
        if (params.value < 0) {
          ret.color = '#d9534f'
        }
        return ret
      },
      valueFormatter: params => numToDollar(params.value),
      ...numberFilterGridConfigs
    }
  }

  if (col.field === 'commitInventory') {
    col = {
      ...col,
      cellRendererFramework: CheckBox,
      cellRendererParams: { disabled: true }
    }
  }

  if (col.field && col.field.includes('quantity')) {
    col = {
      ...col,
      ...numberFilterGridConfigs
    }
  }

  if (col.field && includeNegativeCellColor.includes(col.field)) {
    col = {
      ...col,
      cellStyle(params) {
        const ret = { 'text-align': 'right' }
        if (params.value < 0) {
          ret.color = '#d9534f'
        }
        return ret
      },
      ...numberFilterGridConfigs
    }
  }

  return col
}
export default {
  [CONSTANTS.FIND.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.orderNumber.isFetching', true)
    return result
  },
  [CONSTANTS.FIND.SUCCESS]: (state, { payload: { dataId } }) => {
    // console.log(state, action)
    let result = state
    result = setIn(result, 'fields.orderNumber.isSet', true)
    result = setIn(result, 'fields.orderNumber.isFetching', false)
    result = setIn(result, 'fields.orderNumber.value', dataId)
    // result = setIn(result, 'fields.orderNumber')
    return result
  },
  [CONSTANTS.FIND.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.orderNumber.value', '')
    result = setIn(result, 'fields.orderNumber.isFetching', false)
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.inquiryResults.isFetching', true)
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.SUCCESS]: (
    state,
    { payload: { salesOrders } }
  ) => {
    // console.log(state, salesOrders)
    let result = state
    // resultsGrid
    const {
      data: rowData,
      meta: { columns: columnHeaders }
    } = salesOrders
    let columnDefs = columnHeaders
      ? columnHeaders.map(mapColumnHeaders).map(mapSOInquiryHeaders)
      : []
    // debugger
    const isModal = getIn(result, 'modal')
    if (isModal) {
      columnDefs = columnDefs.filter(col => col.field !== 'commitInventory')
    }

    columnDefs.unshift(
      {
        ...columnDefs[0],
        suppressMenu: true,
        suppressNavigable: true,
        resizable: false,
        lockPosition: true,
        cellRendererSelector: params => {
          const masterComp = {
            component: 'agGroupCellRenderer',
            params: { value: '' }
          }
          return params?.value?.length > 1 ? masterComp : null
        },
        field: 'lineItems',
        colId: 'lineItems',
        headerName: '',
        valueFormatter: params => '',
        width: 50,
        maxWidth: 50
      },
      {
        ...columnDefs[0],
        suppressMenu: true,
        suppressNavigable: true,
        resizable: false,
        lockPosition: true,
        field: '',
        colId: 'print',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 40,
        maxWidth: 40
      }
    )

    if (!isModal) {
      columnDefs[2] = {
        ...columnDefs[2],
        cellRendererFramework: OrderLinkWithTooltip
      }
    }

    // console.log(columnDefs)
    // debugger
    const unwrapLineItemData = arr => {
      if (
        arr &&
        Array.isArray(arr) &&
        arr.every(x => x?.lineItems && x?.lineItems?.length)
      ) {
        arr = arr.reduce((acc, next) => {
          const lineItemsData = Object.keys(next.lineItems[0]).reduce(
            (a, n) => {
              a[n] = next.lineItems[0][n]
              return a
            },
            {}
          )
          // console.log('lineItemsData', lineItemsData)
          acc = acc.concat({
            ...next,
            ...lineItemsData
          })

          return acc
        }, [])
      }

      return arr
    }

    const grid = {
      columnDefs,
      rowData: unwrapLineItemData(rowData) || []
    }
    // debugger
    // console.log(grid)
    result = setIn(result, 'fields.inquiryResults', fromJS(grid))
    result = setIn(result, 'fields.inquiryResults.isFetching', false)
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.FAILURE]: state => {
    let result = state
    result = setIn(result, 'fields.inquiryResults.isFetching', false)
    result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    result = setIn(result, 'selectedRows', fromJS([]))
    return result
  },
  [CONSTANTS.FLAGGED]: (state, { payload: { bool } }) => {
    let result = state
    result = setIn(result, 'flagged', bool)
    result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    result = setIn(result, 'selectedRows', fromJS([]))
    return result
  },
  [CONSTANTS.RESET]: state => {
    let result = state
    let fields = getIn(state, 'fields')
    fields.forEach((field, key) => {
      field = setIn(field, 'disabled', false)
      if (getIn(field, 'type') === 'IndexSearch') {
        field = deleteIn(field, 'recordName')
        field = deleteIn(field, 'description')
        field = deleteIn(field, 'grid')
        field = deleteIn(field, 'creditStatus')
        field = deleteIn(field, 'creditStatusColor')
        field = setIn(field, 'value', '')
        field = setIn(field, 'disabled', false)
        fields = setIn(fields, key, field)
        fields = setIn(fields, 'inquiryResults.rowData', fromJS([]))
      } else if (key === 'dateType' || key === 'status') {
        field = setIn(field, 'value', 'O')
        // fields = setIn(fields, key, field)
      } else if (key === 'overrideFilters') {
        let ofs = field.toJS()
        delete ofs.disabled
        // console.log(ofs)
        Object.keys(ofs).forEach(k => {
          ofs = {
            ...ofs,
            [k]: {
              ...ofs[k],
              enabled: {
                ...ofs[k].enabled,
                value: ''
              },
              filterTypeOption: {
                ...ofs[k].filterTypeOption,
                value: 'O'
              }
            }
          }
        })
        // console.log(ofs)
        field = fromJS(ofs)
      } else if (key === 'rangeFilters') {
        // todo pure immutable..
        let rfs = field.toJS()
        delete rfs.disabled
        // console.log(rfs)
        Object.keys(rfs).forEach(k => {
          rfs = {
            ...rfs,
            [k]: {
              ...rfs[k],
              value: '',
              filterTypeOption: {
                ...rfs[k].filterTypeOption,
                value: 'B'
              }
            }
          }
        })
        field = fromJS(rfs)
      } else if (key === 'inquiryResults') {
        field = setIn(field, 'rowData', emptyList)
      } else if (key === 'printType') {
        const printTypes = getIn(result, 'meta.printTypes')?.toJS
          ? getIn(result, 'meta.printTypes').toJS()
          : []

        field = setIn(field, 'value', printTypes[0].dataId)
        field = setIn(field, 'disabled', false)
      } else {
        field = setIn(field, 'value', '')
        field = setIn(field, 'disabled', false)
      }
      fields = setIn(fields, key, field)
    })
    result = setIn(result, 'fields', fields)

    // debugger
    result = setIn(result, 'selectedRows', fromJS([]))
    return result
  },
  [CONSTANTS.OPEN_SCREEN]: (state, { payload, meta: { form } }) => {
    // console.log('SO meta', state, payload)
    let result = state

    // const meta = extractIndexSearchFieldsFromMeta(payload.meta)
    // result = setIn(result, 'meta', fromJS(meta))
    /* ^^ don't have to do this anymore, beacuse this happens in ddiForm.INITIALIZE */

    // res
    if (form.includes('.')) {
      result = setIn(result, 'modal', true)
    }
    return result
  },
  // [FIND_NEXT.SUCCESS]: (state, action) => {
  //   console.log(state, action)
  //   debugger
  //   return state
  // },
  [CONSTANTS.OPEN_DROPDOWN.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'asModal', true)
    return result
  },
  [CONSTANTS.ROW_SELECTED]: (state, { payload: { selectedRows } }) => {
    let result = state
    result = setIn(result, 'selectedRows', fromJS(selectedRows))
    return result
  },
  [CONSTANTS.CLEAR_RESULTS]: state => {
    let result = state

    result = setIn(result, 'fields.inquiryResults.rowData', emptyList)
    result = setIn(result, 'fields.inquiryResults.columnDefs', emptyList)

    return result
  },
  [DDICONSTANTS.SET_FIELD]: (state, action) => {
    let result = state

    if (!overrideClearingResults.includes(action.payload.propertyName)) {
      result = setIn(result, 'fields.inquiryResults.rowData', emptyList)
      result = setIn(result, 'fields.inquiryResults.columnDefs', emptyList)
    }

    return result
  },
  [CONSTANTS.UPDATE_MASTER_DETAIL_COL_DEFS]: (
    state,
    { payload: { colDefs = [] } }
  ) => {
    let result = state
    result = setIn(result, 'fields.inquiryResults.columnDefs', fromJS(colDefs))
    return result
  }
}

//       const payload = { meta: {} }
//       let indexSearchFields
//       if (form.includes('salesOrderSearch')) {
//         indexSearchFields = yield select(state =>
//           getIn(state, 'auth.common.salesOrderInquiryMeta.indexSearchFields')
//         )
//         console.log(indexSearchFields)
//         payload.meta.indexSearchFields = indexSearchFields.toJS()
//       }
