/* eslint react/sort-comp: 0 */
import React, { Component, Fragment } from 'react'
import { getIn, plainDeepEqual } from 'utils'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  Input,
  InputLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormControlLabelIcon,
  InputAdornment,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField
} from '@material-ui/core'

import DDIExpansionPanel from 'components/DDIExpansionPanel'
import { getAllPrinters, saveDocument, setSessionDefault } from '../actions'

const mapStateToProps = (state, ownProps) => {
  const {
    data: {
      responseData: { form }
    }
  } = ownProps

  const printers = getIn(
    state,
    `ddiForm.${form}.meta.printDocumentModal.printers`
  )

  return {
    printers: printers && printers.toJS() ? printers.toJS() : []
  }
}

const getPrinterByAttribute = (printers, attribute = 'isSessionPrinter') =>
  printers && printers.length
    ? printers.reduce((acc, next) => {
      if (next[attribute]) {
        acc = next.dataId
      }
      return acc
    }, 0)
    : 0

const isSessionDefaultToggled = (printers, printerId) => {
  const sessionPrinter = getPrinterByAttribute(printers, 'isSessionPrinter')
  if (sessionPrinter && sessionPrinter === printerId) {
    return true
  }
  if (
    sessionPrinter ===
      getPrinterByAttribute(printers, 'isUserDefaultPrinter') &&
    sessionPrinter === printerId
  ) {
    return true
  }
  return false
}

export class SaveDocument extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    printers: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)
    const { collate, copies, printers } = props.data.responseData

    const printerId =
      getPrinterByAttribute(printers, 'isSessionPrinter') ||
      getPrinterByAttribute(printers, 'isUserDefaultPrinter') ||
      printers[0].dataId

    this.state = {
      allPrinters: false,
      collate,
      copies: copies || 1,
      fileType: 'choose',
      sessionDefault: isSessionDefaultToggled(printers, printerId),
      printerId,
      printers,
      saveInitiated: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.printers &&
      nextProps.printers.length &&
      !plainDeepEqual(nextProps.printers, prevState.printers)
    ) {
      const printerId =
        getPrinterByAttribute(nextProps.printers, 'isSessionPrinter') ||
        getPrinterByAttribute(nextProps.printers, 'isUserDefaultPrinter') ||
        nextProps.printers[0].dataId

      return {
        printers: nextProps.printers,
        printerId,
        sessionDefault: isSessionDefaultToggled(nextProps.printers, printerId)
      }
    }

    return null
  }

  onToggleChange = name => event => {
    this.setState({ [name]: event.target.checked }, () => {
      const { dataId, form } = this.props.data.responseData
      const { allPrinters, printerId, sessionDefault } = this.state
      if (name === 'allPrinters') {
        this.props.dispatch(
          getAllPrinters(form, {
            allPrinters,
            dataId
          })
        )
      }

      if (name === 'sessionDefault') {
        this.props.dispatch(
          setSessionDefault(form, {
            dataId,
            printerId,
            sessionDefault
          })
        )
      }
    })
  }

  setNumCopies = value => this.setState({ copies: value })

  onSelectChange = event => {
    /* changing the printer updates the session default toggle */
    if (event.target.name === 'printerId') {
      this.setState((prevState, props) => ({
        [event.target.name]: event.target.value,
        sessionDefault: isSessionDefaultToggled(
          prevState.printers,
          event.target.value
        )
      }))
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  onInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  isSaveButtonDisabled = () => {
    if (this.state.saveInitiated) {
      return true
    }

    if (this.state.fileType !== 'choose') {
      return false
    }

    return true
  }

  isSessionDefaultToggleDisabled = () => {
    const { printers, printerId } = this.state

    const sessionPrinter = getPrinterByAttribute(printers, 'isSessionPrinter')
    if (
      sessionPrinter ===
        getPrinterByAttribute(printers, 'isUserDefaultPrinter') &&
      sessionPrinter === printerId
    ) {
      return true
    }
    return false
  }

  saveDocument = () => {
    const { dataId, form } = this.props.data.responseData
    const { fileType, filename } = this.state

    this.setState({ saveInitiated: true }, () => {
      this.props.dispatch(saveDocument(form, { dataId, filename, fileType }))
    })
  }

  render() {
    console.log('PrintDocumentRenderProps', this.props, this.state)
    const {
      allowCopies,
      allowView,
      saveFileTypes
    } = this.props.data.responseData
    const {
      allPrinters,
      collate,
      copies,
      sessionDefault,
      printerId,
      printers,
      fileType,
      saveInitiated
    } = this.state

    return (
      <div>
        <div id="save-box" style={{ width: '100%' }}>
          <DDIExpansionPanel
            defaultExpanded={false}
            expansionPanelContentStyle={{
              padding: '10px 15px',
              width: '100%'
            }}
            expansionPanelDetailsStyle={{
              padding: 0,
              width: '100%'
            }}
            title="Save Document"
          >
            {saveInitiated ? (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 10
                }}
              >
                <CircularProgress thickness={7} />
              </div>
            ) : (
              <>
                <div style={{ marginBottom: 15 }}>
                  <TextField
                    id="filename"
                    name="filename"
                    label="File Name"
                    value={this.state.filename}
                    onChange={this.onInputChange('filename')}
                  />
                </div>
                <FormControl style={{ marginBottom: 15, width: '100%' }}>
                  <InputLabel htmlFor="fileType">File Type</InputLabel>
                  <Select
                    value={fileType}
                    onChange={this.onSelectChange}
                    inputProps={{
                      name: 'fileType',
                      id: 'fileType'
                    }}
                    fullWidth
                  >
                    <MenuItem value="choose">Select One</MenuItem>
                    {saveFileTypes.map((opt, idx) => (
                      <MenuItem value={opt.dataId}>
                        {opt.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                disabled={this.isSaveButtonDisabled()}
                variant="contained"
                onClick={this.saveDocument}
              >
                Save
              </Button>
            </div>
          </DDIExpansionPanel>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(SaveDocument)
