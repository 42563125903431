import React, { createContext, Component, forwardRef } from 'react'
/* eslint-disable react/no-multi-comp, react/prefer-stateless-function */
export const DDIFormContext = createContext(null)

export const withDDIForm = WrappedComponent => {
  class Hoc extends Component {
    render() {
      const { forwardedRef, ...rest } = this.props
      return (
        <DDIFormContext.Consumer>
          {_ddiForm => (
            <WrappedComponent
              ref={forwardedRef}
              actions={_ddiForm?.actions}
              dispatch={_ddiForm?.dispatch}
              getFormState={_ddiForm?.getFormState}
              form={_ddiForm?.form}
              getEntity={_ddiForm?.getEntity}
              partialMatchSearch={_ddiForm?.partialMatchSearch}
              exactMatchSearch={_ddiForm?.exactMatchSearch}
              findPrev={_ddiForm?.findPrev}
              findNext={_ddiForm?.findNext}
              getSearchFilters={_ddiForm?.getSearchFilters}
              focus={_ddiForm?.focus}
              blur={_ddiForm?.blur}
              change={_ddiForm?.change}
              childOf={_ddiForm?.childOf}
              setField={_ddiForm?.setField}
              registerField={_ddiForm?.registerField}
              lockForEdit={_ddiForm?.lockForEdit}
              exit={_ddiForm?.exit}
              onDoubleClick={_ddiForm?.onDoubleClick}
              cancelEdit={_ddiForm?.cancelEdit}
              save={_ddiForm?.save}
              {...rest}
            />
          )}
        </DDIFormContext.Consumer>
      )
    }
  }

  const ret = forwardRef((props, ref) => <Hoc {...props} forwardedRef={ref} />)
  ret.displayName = Component.displayName || Component.name || 'Component'
  return ret
}
