import React, { forwardRef, useState } from 'react'
import { TextField } from '@material-ui/core'

const ProtectedPassword = forwardRef((props, ref) => {
  const { onChange, ...rest } = props
  const [displayValue, setDisplayValue] = useState('')
  const [actualValue, setActualValue] = useState('')

  const onKeyPress = e => {
    setActualValue(actualValue.concat(e.key))
    setDisplayValue(displayValue.concat('*'))
  }
  const onKeyDown = e => {
    if (e.key === 'Backspace') {
      setActualValue(actualValue.slice(0, -1))
      setDisplayValue(displayValue.slice(0, -1))
    }
    if (e.key === 'Enter') {
      setDisplayValue(displayValue.slice(0, -1))
    }
  }
  const onChangeEvent = e => {
    onChange({ target: { value: actualValue } })
  }

  return (
    <TextField
      {...rest}
      onChange={onChangeEvent}
      onKeyPress={onKeyPress}
      value={displayValue}
      onKeyDown={onKeyDown}
      ref={ref}
    />
  )
})

ProtectedPassword.displayName = 'ProtectedPassword'
export default ProtectedPassword
