import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import MobileScreen from 'mobile/components/MobileScreen'

import SearchFields from './components/SearchFieldSkeleton'

const OrderPad = () => {
  return (
    <MobileScreen
      title="Customer Order Pad"
      titleIcon="assignment"
      speedDialActions={[]}
      CollapsableSearchComponent={SearchFields}
    >
      <Skeleton animation="wave" variant="rect" width="100%" height="250" />
    </MobileScreen>
  )
}

export default OrderPad
