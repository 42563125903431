import React, { forwardRef } from 'react'
import { isValidElementType } from 'react-is'
import invariant from 'invariant'

import Field from '../Field'

export default ({ Component, name, ...rest }) => {
  invariant(
    isValidElementType(Component),
    'Component must be a class/functional component'
  )

  invariant(name, 'Must provide a name.')

  const wrapped = forwardRef((props, ref) => (
    <Field {...props} {...rest} component={Component} ref={ref} />
  ))
  wrapped.displayName = name
  return wrapped
}
