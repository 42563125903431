import 'isomorphic-fetch' /* this is for this function ?... yes, this is a polyfill - MS */
import {
  select,
  call,
  fork,
  actionChannel,
  take,
  put
} from 'redux-saga/effects'
import { CANCELED } from 'modals/constants'
import { TOKEN_URL } from 'services/constants'
import { TRY_REQUEST_ACCESS, SUBMIT_LOGOUT_USER } from 'auth/constants'
import { tokenSelector, refreshTokenSelector } from 'auth/selectors'
import fetchProcess from 'utils/fetchProcess'
import destroyModal from 'utils/destroyModal'
import { warningModal } from 'modals/sagas'
import toJSON from 'utils/toJSON'
import * as authActions from 'auth/actions'
import { showAccessOverrideModal } from './sagas'
import { accessRejected } from './actions'

/**
 * posts to a web service URL with an options config object
 * and returns either API data or error messaging
 * @param {string} route - URL to post to
 * @param {object} options - options object
 * @returns {object} returns an object with either a response or an error
 */

const cases = [TRY_REQUEST_ACCESS, CANCELED]

export const overrideProcess = function* overrideProcess({
  modalId,
  route,
  options,
  message,
  accessPath
}) {
  // console.log(message)
  let action
  const channel = yield actionChannel(cases)
  while (true) {
    action = yield take(channel)
    // good
    if (action.type === TRY_REQUEST_ACCESS) {
      const { pin, password, username } = action.payload
      const { meta } = action
      let body = JSON.parse(options.body)

      body = {
        ...body,
        credentials: {
          password,
          pin,
          username
        }
      }
      const newOptions = {
        ...options,
        body: toJSON(body)
      }
      const { response, error } = yield call(fetchProcess, route, newOptions)
      // debugger
      if (response) {
        yield fork(destroyModal, modalId)
        return { response }
      }
      if (error.status && error.status === 496) {
        yield fork(destroyModal, modalId)
        yield call(warningModal, error.message, 'Error Message')
        return {
          error: 'canceled'
        }
      }
      if (error.accessPath !== accessPath) {
        yield fork(destroyModal, modalId)
        const { response } = yield call(callFetch, route, options)
        // do something here...
        return {
          response
        }
      }
      yield put(accessRejected(meta))
    } else if (action.type === CANCELED) {
      // debugger
      return {
        error: 'canceled'
      }
    }
  }
}

export default function* callFetch(
  route,
  options,
  noToken = false,
  fetchProc = fetchProcess
) {
  if (!noToken) {
    const token = yield select(tokenSelector)
    options.headers.authorization = `Bearer ${token}`
  }

  let { response, error } = yield call(fetchProc, route, options)
  if (error) {
    // debugger
    // good

    if (error.status === 401) {
      const refreshToken = yield select(refreshTokenSelector)

      const newOptions = {
        body: `grant_type=refresh_token&client_id=informCloudWebApp&refresh_token=${refreshToken}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;' },
        method: 'post',
        mode: 'cors'
      }
      const { response: secondResponse, error: secondError } = yield call(
        fetchProc,
        TOKEN_URL,
        newOptions
      )
      console.log(secondResponse, secondError)
      if (secondResponse) {
        yield put(authActions.token.success(secondResponse))

        const token = yield select(tokenSelector)
        options.headers.authorization = `Bearer ${token}`
        ;({ response, error } = yield call(fetchProc, route, options))
        if (response) {
          return { response }
        }
      } else {
        error = noToken ? error : secondError
      }
      // return callFetch(TOKEN_URL, options, true)
    }
    if (error.status === 497) {
      const { accessPath, requiredLevel, message } = error
      const modalId = yield call(showAccessOverrideModal, {
        accessPath,
        message,
        requiredLevel
      })
      // debugger
      const { response: resp, error: err } = yield call(overrideProcess, {
        accessPath,
        message,
        modalId,
        options,
        requiredLevel,
        route
      })
      // debugger
      return {
        error: err,
        response: resp
      }
    }
    if (error.status === 495) {
      yield put({
        type: SUBMIT_LOGOUT_USER.SUCCESS,
        payload: { statusText: 'Your session has timed out.' }
      })
    }
    // debugger
    return {
      error
    }
  }
  // success
  // // debugger
  return { response }
}
