import { createSelector } from 'reselect'
import { getIn } from 'utils'
import { Map } from 'immutable'

export const mobile = state => state.get('mobile')

export const isMobileSelector = createSelector(
  mobile,
  mob => mob.get('isMobile')
)

export const mobileStateSelector = createSelector(
  mobile,
  mob => mob.delete('open')
)

export const ddiForm = state => state.get('ddiForm')
export const mobileCustomerSelector = createSelector(
  ddiForm,
  form => getIn(form, 'mobileGlobal.customer') || Map()
)
