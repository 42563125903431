/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const sendDocumentSend = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(`${BASE_INFORM_API_URL}/senddocument/send`, options)
}
export const sendDocumentPrint = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId }
  })

  return callFetch(`${BASE_INFORM_API_URL}/printdocument/send`, options)
}
export const sendDocument = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(`${BASE_INFORM_API_URL}/senddocument/send`, options)
}

export const sendDocumentRead = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(`${BASE_INFORM_API_URL}/senddocument/read`, options)
}