import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
// import MobileHome from 'mobile/pages/Home'
import ScrollToTop from 'components/ScrollToTop'
import { connect } from 'react-redux'
// import { getIn } from 'utils'
import { isMobileSelector } from 'mobile/selectors'
import getIn from 'utils/getIn'
import DashboardsSkeleton from 'mobile/skeletons/Dashboards'
import SalesOrderSkeleton from 'mobile/skeletons/SalesOrder'
import OrderPadSkeleton from 'mobile/skeletons/OrderPad'
import HomeSkeleton from 'mobile/skeletons/Home'
import MainSkeleton from 'mobile/skeletons/Main'
import LoginSkeleton from 'mobile/skeletons/Login'
import MyOrdersSkeleton from 'mobile/skeletons/MyOrders'
import MainFormSkeleton from 'mobile/skeletons/MainForm'
import SalesOpportunitiesSkeleton from 'mobile/skeletons/SalesOpportunities'
import FastCustomerSkeleton from 'mobile/skeletons/FastCustomer'
import FastProductSkeleton from 'mobile/skeletons/FastProduct'
import MyLocksSkeleton from 'mobile/skeletons/MyLocks'
import ProductAnalysisSkeleton from 'mobile/skeletons/ProductAnalysis'
import InvoiceSummarySkeleton from 'mobile/skeletons/InvoiceSummary'
import ContactsSkeleton from 'mobile/skeletons/Contacts'

// Lazy load some of the heavy components.
// this is nice so we can trim the bundle size for mobile

const Loading = <div>Loading...</div>

const Layout = lazy(() => import('pages/Layout'))
const LayoutWrapper = () => (
  <Suspense fallback={<MainFormSkeleton />}>
    <Layout />
  </Suspense>
)

const Main = lazy(() => import('pages/Main'))
const MainForm = ({ children }) => {
  return (
    <Suspense fallback={<MainFormSkeleton />}>
      <Main>{children}</Main>
    </Suspense>
  )
}

const Login = lazy(() => import('pages/Login'))
const LoginPage = props => (
  <Suspense fallback={<LoginSkeleton />}>
    <Login {...props} />
  </Suspense>
)

const SignUp = lazy(() => import('pages/SignUp'))
const SignUpPage = props => (
  <Suspense fallback={Loading}>
    <SignUp {...props} />
  </Suspense>
)

const SignUpMobile = lazy(() => import('mobile/pages/SignUp'))
const SignUpPageMobile = props => (
  <Suspense fallback={Loading}>
    <SignUpMobile {...props} />
  </Suspense>
)

const MobHome = lazy(() => import('mobile/pages/Home'))
const MobileHome = props => (
  <Suspense fallback={<HomeSkeleton />}>
    <MobHome {...props} />
  </Suspense>
)

const MainMob = lazy(() => import('mobile/pages/Main'))
const MainMobile = props => (
  <Suspense fallback={<MainSkeleton />}>
    <MainMob {...props} />
  </Suspense>
)

const SalesOrderMob = lazy(() => import('mobile/pages/SalesOrder'))
const SalesOrderMobile = props => (
  <Suspense fallback={<SalesOrderSkeleton />}>
    <SalesOrderMob {...props} />
  </Suspense>
)

const OrderPad = lazy(() => import('mobile/pages/OrderPad'))
const OrderPadMobile = props => (
  <Suspense fallback={<OrderPadSkeleton />}>
    <OrderPad {...props} />
  </Suspense>
)

const MyOrders = lazy(() => import('mobile/pages/MyOrders'))
const MyOrdersMobile = props => (
  <Suspense fallback={<MyOrdersSkeleton />}>
    <MyOrders {...props} />
  </Suspense>
)

const Dashboards = lazy(() => import('mobile/pages/Dashboards'))
const DashboardsMobile = props => (
  <Suspense fallback={<DashboardsSkeleton />}>
    <Dashboards {...props} />
  </Suspense>
)

const Contacts = lazy(() => import('mobile/pages/Contacts'))
const ContactsMobile = props => (
  <Suspense fallback={<ContactsSkeleton />}>
    <Contacts {...props} />
  </Suspense>
)

const InvoiceSummary = lazy(() => import('mobile/pages/InvoiceSummary'))
const InvoiceSummaryMobile = props => (
  <Suspense fallback={<InvoiceSummarySkeleton />}>
    <InvoiceSummary {...props} />
  </Suspense>
)

const SalesOpportunities = lazy(() => import('mobile/pages/SalesOpportunities'))
const SalesOpportunitiesMobile = props => (
  <Suspense fallback={<SalesOpportunitiesSkeleton />}>
    <SalesOpportunities {...props} />
  </Suspense>
)

const FastCustomer = lazy(() => import('mobile/pages/FastCustomer'))
const FastCustomerMobile = props => (
  <Suspense fallback={<FastCustomerSkeleton />}>
    <FastCustomer {...props} />
  </Suspense>
)

const FastProduct = lazy(() => import('mobile/pages/FastProduct'))
const FastProductMobile = props => (
  <Suspense fallback={<FastProductSkeleton />}>
    <FastProduct {...props} />
  </Suspense>
)

const ProductAnalysis = lazy(() => import('mobile/pages/ProductAnalysis'))
const ProductAnalysisMobile = props => (
  <Suspense fallback={<ProductAnalysisSkeleton />}>
    <ProductAnalysis {...props} />
  </Suspense>
)

const MyLocks = lazy(() => import('mobile/pages/MyLocks'))
const MyLocksMobile = props => (
  <Suspense fallback={<MyLocksSkeleton />}>
    <MyLocks {...props} />
  </Suspense>
)

const mapStateToProps = state => ({
  mobile: isMobileSelector(state),
  hasCRMLicense: getIn(state, 'auth.hasCRMLicense')
})

const Routes = ({ mobile, hasCRMLicense }) => {
  const routes = mobile ? (
    <MainMobile>
      <Switch>
        <Route path="/login" key="login" component={LoginPage} />
        <Route path="/signup" key="signup" component={SignUpPageMobile} />
        <Route path="/app" key="app" component={MobileHome} />
        <Route
          path="/salesorder"
          key="salesorder"
          component={SalesOrderMobile}
        />
        <Route path="/orderpad" key="orderpad" component={OrderPadMobile} />
        <Route path="/myorders" key="myorders" component={MyOrdersMobile} />
        <Route
          path="/dashboards"
          key="dashboards"
          component={DashboardsMobile}
        />
        <Route path="/contacts" key="contacts" component={ContactsMobile} />
        <Route
          path="/invoicesummary"
          key="invoicesummary"
          w
          component={InvoiceSummaryMobile}
        />
        {hasCRMLicense && (
          <Route
            path="/salesopportunities"
            key="salesopportunities"
            component={SalesOpportunitiesMobile}
          />
        )}
        <Route
          path="/fastcustomer"
          key="fastcustomer"
          component={FastCustomerMobile}
        />
        <Route
          path="/fastproduct"
          key="fastproduct"
          component={FastProductMobile}
        />
        <Route
          path="/productanalysis"
          key="productanalysis"
          component={ProductAnalysisMobile}
        />
        <Route path="/mylocks" key="mylocks" component={MyLocksMobile} />
      </Switch>
    </MainMobile>
  ) : (
    <MainForm>
      <Switch>
        <Route
          path="/login"
          key="login"
          render={props => (
            <ScrollToTop>
              <LoginPage {...props} />
            </ScrollToTop>
          )}
        />
        <Route
          path="/signup"
          key="signup"
          render={props => (
            <ScrollToTop>
              <SignUpPage {...props} />
            </ScrollToTop>
          )}
        />
        <Route
          path="/app"
          key="app"
          render={props => (
            <ScrollToTop>
              <LayoutWrapper {...props} />
            </ScrollToTop>
          )}
        />
        <Route
          path="*"
          key="app"
          render={props => (
            <ScrollToTop>
              <LayoutWrapper {...props} />
            </ScrollToTop>
          )}
        />
      </Switch>
    </MainForm>
  )

  return routes
}
export default connect(mapStateToProps)(React.memo(Routes))
