/* eslint no-useless-escape: 0 */
import { getIn } from 'utils'
import { ButtonBase } from '@material-ui/core'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import shortid from 'shortid'
import { CLOSE_SEARCH, CLOSE_FILTERS_GRID } from './constants'

import {
  blur,
  exactMatchSearch,
  partialMatchSearch,
  resetFilters as resetFiltersAction,
  toggleFilter as toggleFilterAction,
  clearSearch,
  contactsSearch
} from './actions'
import baseBehaviors from '../baseBehaviors'

const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const faxNumberRegex = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/

const getDropdownGridHeight = rowData =>
  rowData && Array.isArray(rowData) && rowData.length
    ? rowData.length * 28 + 105
    : 250

class SendTo {
  constructor(args = {}) {
    this.dataId = args.dataId
    this.companySearchType = args.companySearchType || 'None'
    this.companyId = args.companyId
    this.description = args.description || ''
    this.email = args.email || ''
    this.fax = args.fax
    this.freeForm = !args.companySearchType
    this.includeAreaCode = args.includeAreaCode
    this.includeOne = args.includeOne
    this.coverPageId = args.coverPageId
    this.contacts = args.contacts
    this.sendDocId = args.sendDocId || shortid.generate()
    this.isValid = () => {
      const desc = this.description && this.description !== 'None'
      const email = regex.test(this.email)
      return desc && email
    }
  }
}

export default {
  ...baseBehaviors,
  async addBlankRow(...args) {
    // debugger
    // console.log('add row', ...args)
    if (this.state.newRowPending && !this.state.value.length) {
      await this.setState({ newRowPending: false })
    }
    if (this.state.newRowPending) return
    this.setState(
      prevState => {
        // get the last row. if it's empty, deal wiht contact situation...
        const lastRow =
          prevState.value.length >= 1
            ? prevState.value[prevState.value.length - 1]
            : {}
        const newRow = new SendTo({
          contacts: lastRow.contacts,
          companyId: lastRow.companyId,
          companySearchType: lastRow.companySearchType,
          sendDocId: shortid.generate()
        })
        const value = prevState.value.concat(newRow)
        // console.log(this.props)
        return {
          value,
          newRowPending: true,
          newRowIndex: value.length - 1,
          dropDownProps: {
            ...prevState.dropDownProps,
            showSendInfoResults: true,
            showSearchResults: false,
            rowData: value,
            sendType: this.props.sendType,
            dispatch: this.props.dispatch,
            onChange: this.sendInfoChange,
            onGridReady: this.onGridReady
          }
        }
      },
      () => {
        // console.log(this.state)
        this.setField(this.state.value)
      }
    )
  },
  blur(value) {
    // this.props.dispatch(
    //   blur(this.form, {
    //     propertyName: this.props.propertyName,
    //     value,
    //     isSet: this.state.isSet
    //   })
    // )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        //   this.props.onChange('')
      }
    }
  },
  async clearPendingRow() {
    await this.setState(
      prevState => {
        const newState = {
          isDirty: true,
          isSet: false
        }
        if (prevState.newRowPending) {
          prevState.value.pop()
          newState.newRowPending = false
          newState.value = prevState.value
        }
        return newState
      },
      () => console.log(this.state)
    )
  },
  clearSearch() {
    // //
    if (!this.props.getFormState) return
    this.props.dispatch(
      clearSearch(this.form, {
        propertyName: this.props.propertyName
      })
    )
  },
  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select() {
        target.select()
      },
      paste() {
        // Chrome does not allow retrieval of clipboard data
        // document.addEventListener('paste', function (evt) {
        //   const data = evt.clipboardData.getData('text')
        //   console.log('DATA', data)
        // })
        // target.focus()
        // document.execCommand('paste')
      },
      delete() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        const { openScreenData } = this.props
        this.props.dispatch(openScreenAction(openScreenData))
      }
    }
    return (action[data.type] || action.select)()
  },
  deleteRow(rowIndex) {
    // debugger
    let val
    this.setState(
      prevState => {
        const value = [
          ...prevState.value.slice(0, rowIndex),
          ...prevState.value.slice(rowIndex + 1)
        ]
        val = value
        let newRowPending = prevState.newRowPending || false

        if (value.length) {
          const row = value[value.length - 1]

          if (row && Object.keys(row).length) {
            const {
              companySearchType,
              email,
              companyId,
              fax,
              description
            } = row

            if (
              prevState?.dropDownProps?.sendType &&
              prevState?.dropDownProps?.sendType === 'Email'
            ) {
              if (
                (companySearchType !== 'Free Form' &&
                  description &&
                  email &&
                  regex.test(email)) ||
                (companySearchType === 'Free Form' &&
                  email &&
                  regex.test(email))
              ) {
                newRowPending = false
              }
            } else if (fax && faxNumberRegex.test(fax) && description) {
              newRowPending = false
            }
          }
        } else {
          newRowPending = false
        }

        return {
          dropDownProps: {
            ...prevState.dropDownProps,
            showSendInfoResults: true,
            showSearchResults: false,
            rowData: value,
            sendType: this.props.sendType,
            dispatch: this.props.dispatch,
            onGridReady: this.onGridReady
          },
          newRowPending,
          value
        }
      },
      () => this.setField(val)
    )
  },
  async exactMatch(val) {
    if (this.state.newRowPending) {
      await this.clearPendingRow()
    }
    try {
      const p = await this.props.dispatch(
        exactMatchSearch(this.form, {
          keyword: val,
          sendType: this.props.sendType
        })
      )
      console.log(p)
      // is freeform...

      const user = new SendTo(p)
      const findUser = el =>
        el.email === user.email &&
        el.description === user.description &&
        el.fax === user.fax
      console.log(this.state.value.find(findUser), this.state.value, user)
      if (!this.state.value.find(findUser)) {
        let value = []
        await this.setState(
          prevState => {
            value = prevState.value.concat(user)
            // debugger
            return {
              value,
              // isDirty: false,
              inputValue: '',
              dropDownProps: {
                // ...prevState.dropDownProps,
                height: this?.props?.mobileState?.isMobile ? 200 : 400,
                addButtonText: 'Add Send To',
                deleteRow: this.deleteRow,
                onChange: this.sendInfoChange,
                rowData: value,
                showSendInfoResults: true,
                showSearchResults: false,
                sendType: this.props.sendType,
                dispatch: this.props.dispatch,
                meta: {},
                showAddButtonAlways: true,
                onGridReady: this.onGridReady,
                title: 'Send To'
              }
              // popoverStyle: {
              //   width: this.getWidth()
              //   // height: 400
              // }
            }
          },
          () => {
            this.updatePosition()

            this.setField(value)
            console.log(user)
            console.log(this.state)
          }
        )
        await this.setState(prevState => ({
          popoverStyle: {
            ...prevState.popoverStyle,
            width: this.getWidth()
            // height: 400
          }
        }))
      } else {
        this.setState({
          inputValue: ''
        })
      }
    } catch (e) {
      console.log(e)
    }
  },
  focusField() {
    return (
      this.textField &&
      this.setState(
        { isFocused: true },
        () => ((this.autofocused = true), this.textField.focus())
      )
    )
  },
  // getButtonStyle() {
  //   let { buttonStyle } = this.props
  //   if (this.props.disabled) {
  //     buttonStyle = {
  //       ...buttonStyle,
  //       background: '#000',
  //       opacity: 0.12
  //     }
  //   }
  //   return buttonStyle
  // },
  // getDisplayValue() {
  //   if (this.props.displayDescription && !this.state.isFocused) {
  //     return this.state.description
  //   }
  //   return this.state.value || ''
  // },
  getGridHeight() {
    const { grid } = this.props
    return grid && grid.rowData && grid.rowData.length
      ? grid.rowData.length * 27 + 52
      : 150
  },

  getRowNodeId(data) {
    return data.sendDocId
  },

  // what is this? put this somewhere else...

  // getValue() {
  //   return this.state.value
  // },
  getWidth() {
    // console.log(this.state.showSendInfoResults, this.state.showSearchResults)
    // if (this.state.showSearchResults) {
    //   return 700
    // }

    // const v = this.props.sendType === 'Email' ? 700 : 1000
    // console.log(v)
    // console.log(this.state)
    if (this.state.dropDownProps.showSearchResults) {
      return 700
    }

    const ret = this.state.dropDownProps.sendType === 'Email' ? 700 : 1100
    // console.log(ret, this.state)
    return ret
  },
  initialize() {
    // debugger
    this.state = {
      ...this.state,
      isOpen: false,
      isSet: false, // prob needs change for logic
      value: [],
      inputValue: '',
      showResults: true,
      // showSendInfoResults: true,
      // showSearchResults: false,
      inputProps: {
        deleteRow: this.deleteRow
      },
      popoverStyle: {
        height: this?.props?.mobileState?.isMobile ? 200 : 400,
        width: 700
      }
    }
  },
  onBeforeAdd(e) {
    console.log(e)
  },
  async onBlur(event) {
    if (event) event.persist()
    // debugger
    // console.log('blur', stringy(this.state))
    // if (!this.state.isOpen && this.state.isDirty) {
    //   this.setState({
    //     inputValue: '',
    //     isDirty: false
    //   })
    // }

    // if (this.state.isDirty && this.state.inputValue) {
    //   await this.setState(
    //     {
    //       inputValue: '',
    //       isDirty: false
    //     },
    //     () => {
    //       console.log(this.state)
    //     }
    //   )
    // }
  },
  // async onCellSelected(event) {
  //   const { propertyName, sendType } = this.props
  //   const keyword = event.data.recordName
  //   try {
  //     const p = await this.props.dispatch(
  //       exactMatchSearch(this.form, {
  //         keyword,
  //         sendType
  //       })
  //     )
  //     console.log(p)

  //     if (p) {
  //       await this.setState(
  //         prevState => ({
  //           isOpen: true,
  //           value: prevState.value.concat(p),
  //           isDirty: false
  //         }),
  //         () => console.log(this.state)
  //       )

  //       await this.setState(prevState => ({
  //         dropDownProps: {
  //           ...prevState.dropDownProps,
  //           deleteRow: this.deleteRow,
  //           onChange: this.sendInfoChange,
  //           rowData: this.state.value,
  //           showSendInfoResults: true,
  //           meta: {},
  //           title: 'Send To',
  //           showAddButtonAlways: true,
  //           addButtonText: 'Add Send To',
  //           addBlankRow: this.addBlankRow,
  //           sendType: this.props.sendType,
  //           dispatch: this.props.dispatch
  //         }
  //       }))
  //     }
  //   } finally {
  //     //
  //     this.updatePosition()
  //   }
  // },

  async onCellSelected(event) {
    const { propertyName, sendType } = this.props
    const keyword = event.data.recordName
    await this.exactMatch(keyword)
  },

  async onChange(event) {
    // debugger
    if (event.persist) {
      event.persist()
    }
    const { key } = event

    this.timeout = this.timeout || 0
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    const v = event.target.value
    const callPartial = () => {
      this.timeout = setTimeout(
        () => this.partialMatchSearch(v),
        this.props.timer || 1000
      )
    }

    const {
      meta: { allowInstantSearch, minimumKeywordLength }
    } = this
    // console.log(this)
    // await this.setState({
    //   isDirty: true,
    //   inputValue: v,
    //   // lastSearch: event.target.value,
    //   isSet: false
    // }, () => console.log(this.state))

    // this.state.newRowPending
    await this.setState({
      inputValue: v
    })
    await this.clearPendingRow()

    if (v.length >= 1) {
      this.setState(
        {
          lastSearch: event.target.value
          // inputValue: event.target.value
        },
        () => callPartial()
      )
    } else if (v.length === 0) {
      this.setState({
        isOpen: false,
        dropDownProps: null
      })
      // this.clearSearch()
      // this.setField('', true)
    }
  },

  async onFocus(event) {
    event.persist()
    // console.log('focus', this.state, this.props)
    // if (!this.requestClose) {
    if (this.state.showResults) {
      // debugger
      await this.setState(prevState => ({
        ...prevState,
        isFocused: true,
        dropDownProps: {
          deleteRow: this.deleteRow,
          onChange: this.sendInfoChange,
          rowData: prevState.value,
          height: this?.props?.mobileState?.isMobile ? 200 : 400,
          width: 700,
          title: 'Send To',
          showAddButtonAlways: true,
          addButtonText: 'Add Send To',
          addBlankRow: this.addBlankRow,
          sendType: this.props.sendType,
          dispatch: this.props.dispatch,
          showSendInfoResults: true,
          showSearchResults: false,
          onGridReady: this.onGridReady
        },
        inputProps: {
          deleteRow: this.deleteRow
        },
        isOpen: true
        // popoverStyle: {
        //   ...prevState.popoverStyle,
        //   width: this.getWidth()
        //   // height: 400
        // }
      }))
      await this.setState(prevState => ({
        popoverStyle: {
          ...prevState.popoverStyle,
          width: this.getWidth()
          // height: 400
        }
      }))
    }
  },
  onGridReady(params) {
    this.gridApi = params.api
  },
  async onKeyDown(event) {
    // console.log(event)
    // event.persist()
    // function sleep(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms))
    // }
    // const val = event.target.value
    // await sleep(2000)
    // return true

    // check if tooltip is open and keys match etc.. great :-(
    if (event.key !== 'Tab') return
    event.preventDefault()
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
      const inputValue = event.target.value
      await this.exactMatch(inputValue)
    }
  },

  onMouseLeave(event) {
    this.setState({ mouseover: false })
  },

  onMouseOver(event) {
    this.setState({ mouseover: true })
  },

  async onRequestClose(e, reason) {
    // console.log('request close', this.state, e, reason)
    // console.log(this.requestClose)
    // this.requestClose = true
    // function sleep(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms))
    // }
    // await sleep(5000)
    this.setState({
      isOpen: false,
      inputValue: ''
    })
    // if (this.state.isOpen) {
    // this.setState(
    //   {
    //     dropDownProps: null,
    //     isOpen: false,
    //     requestClose: true
    //   },
    // () => console.log('after request close', stringy(this.state))
    // )

    // }
  },
  onSearchClick(event) {
    this.setState(
      {
        // isOpen: true,
        // dropDownProps: {}
        isSet: false
      },
      () => this.partialMatchSearch(this.state.value, true)
    )
  },
  // onKeyPress = e => console.log(e.charCode, e.keyCode)
  paginationCb(pageNumber) {
    const {
      //   partialMatchSearch,
      propertyName,
      indexSearchType
      //   parentType,
      //   parentId
    } = this.props
    this.partialMatchSearch(null, null, {
      partialMatchSearch,
      indexSearchType: indexSearchType || this.props.meta.searchType,
      pageNumber,
      propertyName,
      keyword: this.state.inputValue || ''
    })
  },
  partialMatchSearch(value, bool, args, isFiltered = false) {
    let searchAll = false
    if (typeof value === 'boolean') {
      searchAll = value
      value = ''
    }
    // const { partialMatc}
    const { propertyName, keyword, pageNumber } = args || this.props
    if (args) {
      value = args.keyword
    }
    if (this.state.isSet || this.props.isFetching) return
    let results
    //
    this.setState(
      { lastSearch: value, isSearching: true, inputValue: value },
      async () => {
        try {
          //
          results = await this.props.dispatch(
            partialMatchSearch(this.form, {
              keyword: bool ? '' : value,
              propertyName,
              onCellSelected: this.onCellSelected,
              pageNumber
            })
          )

          await this.setState({
            dropDownProps: {
              ...results.grid,
              getRowNodeId: this.getRowNodeId,
              paginationCb: this.paginationCb,
              height: this?.props?.mobileState?.isMobile ? 200 : 400,
              width: 700,
              isFiltered,
              showSendInfoResults: false,
              showSearchResults: true,
              sendType: this.props.sendType
            },
            isOpen: true
            // showSearchResults: true,
            // showSendInfoResults: false
            // popoverStyle: {
            //   width: this.getWidth()
            //   // height: 400
            // }
          })
          await this.setState(prevState => ({
            popoverStyle: {
              ...prevState.popoverStyle,
              width: this.getWidth()
              // height: 400
            }
          }))
          // }
        } finally {
          this.setState({ isSearching: false }, () => console.log(this.state))
        }
      }
    )
  },
  // popoverAction({ updatePosition }) {
  //   this.updatePosition = updatePosition
  // },
  popoverAction(params) {
    /*
      ^^used to be like that,
        but some change in React when migrating
        to the new context API broke that destructuring
    */
    if (params && params.updatePosition) {
      this.updatePosition = params.updatePosition
    }
  },
  redoSearch() {
    // //
    this.setState(
      { isSet: false, value: this.state.lastSearch, isDirty: true },
      () => this.partialMatchSearch(this.state.lastSearch)
    )
  },

  resetFilters() {
    this.props.dispatch(
      resetFiltersAction(this.form, { propertyName: this.props.propertyName })
    )
  },
  async sendInfoChange(field, value, node, selectedContactId = null) {
    const foo = ''
    let v
    if (field === 'companySearchType') {
      const row = new SendTo({
        companySearchType: value,
        sendDocId: shortid.generate()
      })
      await this.setState(
        prevState => {
          v = [
            ...prevState.value.slice(0, node.rowIndex),
            row,
            ...prevState.value.slice(node.rowIndex + 1)
          ]
          return {
            value: v,
            dropDownProps: {
              ...prevState.dropDownProps,
              rowData: v
            }
          }
        },
        () => {
          this.setField(v)
          node.setData({ ...row })
        }
      )
    }
    if (field === 'companyId') {
      if (node.data.companySearchType !== 'Free Form') {
        try {
          const results = await this.props.dispatch(
            contactsSearch(this.form, {
              companyType: node.data.companySearchType,
              companyId: value
            })
          )
          if (results) {
            await this.setState(
              prevState => {
                v = [
                  ...prevState.value.slice(0, node.rowIndex),
                  {
                    ...prevState.value[node.rowIndex],
                    companyId: value,
                    contacts: results,
                    description: '',
                    email: '',
                    fax: ''
                  },
                  ...prevState.value.slice(node.rowIndex + 1)
                ]

                return {
                  value: v,
                  dropDownProps: {
                    ...prevState.dropDownProps,
                    rowData: v
                  }
                }
              },
              () => {
                this.setField(v)
                node.setData({
                  ...node.data,
                  companyId: value
                })
              }
            )
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        await this.setState(
          prevState => {
            v = [
              ...prevState.value.slice(0, node.rowIndex),
              {
                ...prevState.value[node.rowIndex],
                companyId: value,
                contacts: []
              },
              ...prevState.value.slice(node.rowIndex + 1)
            ]

            return {
              value: v,
              dropDownProps: {
                ...prevState.dropDownProps,
                rowData: v
              }
            }
          },
          () => {
            this.setField(v)
            node.setData({
              ...node.data,
              companyId: value
            })
          }
        )
      }
    } else if (
      this.state.value[node.rowIndex] &&
      this.state.value[node.rowIndex][field] !== value
    ) {
      let email
      let fax
      let dataId

      await this.setState(
        prevState => {
          email = field === 'email' ? value : node.data.email
          fax = field === 'fax' ? value : node.data.fax
          dataId =
            selectedContactId ||
            node?.data?.dataId ||
            prevState?.value[node.rowIndex]?.dataId ||
            ''
          // debugger

          v = [
            ...prevState.value.slice(0, node.rowIndex),
            {
              ...prevState.value[node.rowIndex],
              dataId,
              email,
              fax,
              [field]: value
            },
            ...prevState.value.slice(node.rowIndex + 1)
          ]

          const state = {
            value: v,
            dropDownProps: {
              ...prevState.dropDownProps,
              rowData: v
            }
            // isDirty: true
          }
          return state
        },
        () => {
          // console.log(this.state)
          this.setField(v)
          node.setData({ ...node.data, dataId, [field]: value })
        }
      )
      // await this.setState({ isDirty: false })
    }

    if (this.state.newRowPending) {
      const { rowIndex } = node
      // console.log('ROW', row, row.isValid())
      this.setState(prevState => {
        // debugger
        const row = prevState.value[rowIndex]
        const isPending = prevState.newRowPending
        let newRowPending = isPending

        if (row && Object.keys(row).length) {
          const { companySearchType, email, companyId, fax, description } = row

          if (prevState.dropDownProps.sendType === 'Email') {
            if (
              (companySearchType !== 'Free Form' &&
                description &&
                email &&
                regex.test(email)) ||
              (companySearchType === 'Free Form' && email && regex.test(email))
            ) {
              newRowPending = false
            }
          } else if (fax && faxNumberRegex.test(fax) && description) {
            newRowPending = false
          }
        }

        return { newRowPending }
      })

      // console.log(row, row && row.isValid)
      // TODO unset the new row jazz
    }
  }
}
