import { SUBMIT_LOGOUT_USER, AUTHENTICATION_ERROR } from 'auth/constants'
/**
 * HOC that returns a reducer function
 * @param {object} initialState - reducer initial state
 * @param {object} behaviors - keyed reducer mapping
 * @returns {function} with the current state and payload
 */

const resetTypes = [
  SUBMIT_LOGOUT_USER.SUCCESS,
  SUBMIT_LOGOUT_USER.FAILURE,
  AUTHENTICATION_ERROR
]
const createReducer = (initialState, behaviors, noResetOnLogout = false) => (
  state = initialState,
  action = { type: '' }
) => {
  const { type } = action
  if (!noResetOnLogout && resetTypes.includes(type)) {
    return initialState
  }
  const reducer = behaviors[type]
  return reducer ? reducer(state, action) : state
}

export default createReducer
