export const snackbar = '@@snackbar'

export const SHOW_MESSAGE = `${snackbar}/SHOW_MESSAGE`
// export const SHOW_MESSAGES = `${snackbar}/SHOW_MESSAGES`
// export const CLOSE_SNACKBAR = `${snackbar}/CLOSE_SNACKBAR`

export const SHOW = `${snackbar}/SHOW`
export const REMOVE = `${snackbar}/REMOVE`
// export const SHOW_MESSAGES = `${snackbar}/SHOW_MESSAGES`
export const CLOSE_SNACKBAR = `${snackbar}/CLOSE_SNACKBAR`
