import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from '@material-ui/core'

import DDIExpansionPanel from 'components/DDIExpansionPanel'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
})

class BranchListMobile extends Component {
  static propTypes = {
    branchList: PropTypes.array,
    selectBranch: PropTypes.func.isRequired
  }

  static defaultProps = { branchList: [] }

  constructor(props) {
    super(props)
    // const branchList = Array.isArray(props.branchList.toJS()) ? props.branchList : []
    this.state = {
      // branchList,
      selectedBranchId: null
      // props.branchList.size > 0 ? props.branchList[0].dataId : null
    }
  }

  componentDidMount() {
    // this.onRowSelection([0])
    // this.onRowSelection(null, this.props.branchList[0].dataId)
    this.onRowSelection(
      null,
      this.props.branchList
        .sort(this.sort)
        .get(0)
        .get('dataId')
    )
  }

  onRowSelection = (event, dataId) => {
    /* getting branchList from props without sorting caused a bug */
    // const { branchList, selectBranch } = this.props
    const { selectBranch } = this.props
    const { branchList } = this.state
    // const selectedBranchId =
    //   branchList[row] && branchList[row].dataId ? branchList[row].dataId : null
    const selectedBranchId = dataId || null

    this.setState({ selectedBranchId }, () => selectBranch(selectedBranchId))
  }

  sort = (a, b) => {
    if (a.get('sortIndex') > b.get('sortIndex')) {
      return 1
    }
    if (a.get('sortIndex') < b.get('sortIndex')) {
      return -1
    }
    return 0
  }

  getBranchList = memoize((branchList, selectedBranchId) =>
    branchList.sort(this.sort).map((row, index) => {
      const dataId = row.get('dataId')
      const isSelected = selectedBranchId === dataId
      return (
        <TableRow
          key={dataId}
          role="checkbox"
          aria-checked={isSelected}
          selected={dataId === selectedBranchId}
          onClick={event => this.onRowSelection(event, dataId)}
        >
          <TableCell style={{ padding: 10, maxWidth: 35, width: 35 }}>
            <Checkbox checked={isSelected} style={{ height: 24, width: 24 }} />
          </TableCell>
          <TableCell style={{ textAlign: 'center', width: 200 }}>
            <DDIExpansionPanel
              title={`${row.get('description')}`}
              expansionPanelContentStyle={{
                padding: 10,
                width: '100%',
                textAlign: 'left'
              }}
              expansionPanelDetailsStyle={{ padding: 0, width: '100%' }}
              defaultExpanded={false}
              style={{ width: '100%' }}
              primaryTitleWrapperStyle={{ textAlign: 'left' }}
            >
              <div>
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  <span style={{ marginRight: 5 }}>Branch:</span>{' '}
                  <span>{`${dataId}`}</span>
                </p>
                <p style={{ fontSize: 12, marginBottom: 0 }}>{`${row.get(
                  'city'
                )}, ${row.get('state')} ${row.get('postalCode')}`}</p>
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  <span style={{ marginRight: 5 }}>Default Warehouse:</span>{' '}
                  <span>{row.get('defaultWarehouseId') || null}</span>
                </p>
              </div>
            </DDIExpansionPanel>
          </TableCell>
        </TableRow>
      )
    })
  )

  render() {
    const { selectedBranchId } = this.state
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow style={{ background: '#444', color: '#fff' }}>
              <TableCell style={{ maxWidth: 35, width: 35 }}>&nbsp;</TableCell>
              <TableCell
                style={{
                  color: '#fff',
                  textAlign: 'center',
                  width: 200
                }}
              >
                Branch
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.getBranchList(this.props.branchList, selectedBranchId)}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles)(BranchListMobile)
