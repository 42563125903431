import React from 'react'
import { Icon } from '@material-ui/core'

import {
  informBlue,
  ddiBlue,
  // turquoise,
  // flatGrey,
  mediumGrey,
  darkGrey
  // disabledFieldColor,
  // disabledButtonColor,
  // altTableRowColor,
  // selectedTableRowColor,
  // fullBlack,
  // white
} from '../theme/colors'

export const smallTextFieldStyles = {
  buttonStyle: {
    background: '#517b9c',
    borderRadius: '0 4px 4px 0',
    float: 'right',
    height: 26,
    padding: 0,
    width: 26
  },
  floatingLabelFocusStyle: { transform: 'perspective(1px) scale(0.75) translate(0px, -18px)' },
  floatingLabelShrinkStyle: {
    color: informBlue,
    fontWeight: 500,
    transform: 'perspective(1px) scale(0.75) translate(0px, -18px)'
  },
  floatingLabelStyle: {
    color: mediumGrey,
    fontSize: 12,
    fontWeight: 400,
    top: 16
  },
  hintStyle: {
    color: mediumGrey,
    fontSize: 12,
    top: 16
  },
  underlineStyle: { bottom: 0 },
  textFieldStyle: {
    bottom: 0,
    fontSize: 12,
    height: 26,
    left: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 1
    // minWidth: 100
  }
}

export const pickerStyles = {
  floatingLabelShrinkStyle: { color: informBlue },
  floatingLabelStyle: {
    color: mediumGrey,
    fontSize: 12,
    top: 42
  },
  textFieldStyle: { fontSize: 12 }
}

export const ddiFormStyles = {
  card: {
    cardHeader: {
      closeIcon: <Icon style={{
        height: 20, width: 20, fontSize: 16, color: '#fff'
      }}
      >add_circle</Icon>,
      openIcon: <Icon style={{
        height: 20, width: 20, fontSize: 16, color: '#fff'
      }}
      >remove_circle</Icon>,
      style: { background: ddiBlue, padding: 10 },
      titleColor: '#fff',
      titleStyle: { fontSize: 12, fontWeight: 400 }
    }
  },
  datepicker: { ...pickerStyles },
  dialog: {
    titleStyle: {
      background: 'linear-gradient(to bottom, #d3dee6 0%, #c0d5e5 100%)',
      border: 0,
      color: darkGrey,
      fontSize: 12,
      fontWeight: 500,
      padding: '2px 10px 2px 10px'
    }
  },
  formIcons: {
    config: {
      color: informBlue,
      style: {
        bottom: 12,
        height: 20,
        pointerEvents: 'none',
        position: 'absolute',
        right: 15,
        width: 20
      }
    }
  },
  selectField: {
    ...smallTextFieldStyles,
    iconStyle: { right: -10 },
    inputStyle: { marginTop: -3 },
    style: {
      fontSize: 12,
      height: 50,
      marginTop: 0,
      width: '100%'
    },
    underlineStyle: {
      ...smallTextFieldStyles.underlineStyle,
      bottom: 8
    }
  },
  textField: {
    ...smallTextFieldStyles,
    hintStyle: {
      ...smallTextFieldStyles.hintStyle,
      fontWeight: 400
    },
    inputStyle: { marginTop: 5 },
    labelStyle: {
      fontSize: 12,
      marginBottom: 0
    },
    style: {
      fontSize: 12,
      height: 50,
      marginTop: 0,
      width: '100%'
    },
    underlineStyle: { bottom: 8 }
  },
  textFieldMultiLine: {
    floatingLabelShrinkStyle: {
      color: informBlue,
      fontSize: 12,
      fontWeight: 'bold'
    },
    style: { fontSize: 12 }
  },
  timepicker: { ...pickerStyles }
}

// export default ddiFormStyles
