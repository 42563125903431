import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import { save } from 'ddiForm/api'

export const getProduct = ({
  dataId,
  groupNames,
  recordName,
  warehouseId,
  uomId,
  ...rest
}) => {
  const options = createApiOptions({
    body: { ...rest, dataId, groupNames, warehouseId, uomId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/read`, options)
}

export const getAnalysisMeta = () => {
  const options = createApiOptions({
    body: {},
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/meta`, options)
}

export const getAnalysisRead = ({
  dataId,
  groupNames,
  warehouseId,
  uomId,
  ...rest
}) => {
  const options = createApiOptions({
    body: { dataId, groupNames, warehouseId, uomId, ...rest },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/read`, options)
}

export const getPurchaseHistory = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/purchasehistory`,
    options
  )
}

export const getSds = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/sds`, options)
}

export const getDocSpec = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/docspec`, options)
}

export const getFifoCost = ({ dataId, warehouseId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      warehouseId
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/fifocost`, options)
}

export const calculateRollupPrice = ({
  dataId,
  templateKey,
  warehouseId,
  rollupId,
  route,
  value,
  chainDiscount
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      templateKey,
      warehouseId,
      rollupId,
      value,
      chainDiscount
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/prices/calculate/rollup/${route}`,
    options
  )
}

export const updatePrices = ({
  dataId,
  templateKey,
  warehouseId,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      templateKey,
      warehouseId,
      credentials
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/prices/update`,
    options
  )
}

export const changePriceOrCost = ({
  templateKey,
  dataId,
  warehouseId,
  priceId,
  priceAmount,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      templateKey,
      dataId,
      warehouseId,
      priceId,
      priceAmount,
      credentials
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/prices/change/price`,
    options
  )
}

export const productLineUpdate = ({
  templateKey,
  productLineId,
  dataId,
  warehouseId,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      templateKey,
      productLineId,
      dataId,
      warehouseId,
      credentials
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/productline`, options)
}

export const priceGroupUpdate = ({
  templateKey,
  priceGroupId,
  dataId,
  warehouseId,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      templateKey,
      priceGroupId,
      dataId,
      warehouseId,
      credentials
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/pricegroup`, options)
}

export const statusTypeUpdate = ({
  templateKey,
  statusType,
  dataId,
  warehouseId,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      templateKey,
      statusType,
      dataId,
      warehouseId,
      credentials
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/change/status`,
    options
  )
}

export const mfgNumberUpdate = ({
  dataId,
  mfgNumber,
  credentials,
  templateKey
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      mfgNumber,
      credentials,
      templateKey
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/change/mfgnumber`,
    options
  )
}

export const purchaseHistoryLinkUpdate = ({
  templateKey,
  dataId,
  purchaseHistoryLinkId,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      templateKey,
      dataId,
      purchaseHistoryLinkId,
      credentials
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/change/purchasehistorylink`,
    options
  )
}

export const changeRollUp = ({
  dataId,
  warehouseId,
  rollUp,
  templateKey,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      warehouseId,
      rollUp,
      templateKey,
      credentials
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/prices/change/rollup`,
    options
  )
}

export const updateNotes = ({
  form,
  dataId,
  recordName,
  note,
  retainLock = false
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      recordName,
      note,
      retainLock
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/${form}/notes/save`, options)
}

export const validateIsKitProductionChange = ({
  dataId,
  recordName,
  isKitProduction,
  componentIds
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      componentIds,
      dataId,
      isKitProduction,
      recordName
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/change/validateiskitproductionchange`,
    options
  )
}

export const saveProduct = ({ form = 'productmaster', ...args }) =>
  save({ form, ...args })

export const deleteProduct = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/delete`, options)
}

export const createNewProduct = ({ dataId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      ...rest,
      dataId
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/create`, options)
}

export const getProductUOMData = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/getuom`, options)
}

export const getCopyFromSearchDescription = ({ dataId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      ...rest,
      dataId
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/copyfrom/searchdescriptions`,
    options
  )
}

export const cancelCopyFrom = () => {
  const options = createApiOptions({
    method: 'post',
    body: {}
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/copyfrom/cancel`,
    options
  )
}

export const setCopyFrom = data => {
  const options = createApiOptions({
    method: 'post',
    body: { ...data }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/copyfrom/set`, options)
}

export const saveVendorsData = ({
  dataId,
  newWarehouseId,
  warehouseId,
  vendors
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      newWarehouseId,
      vendors,
      warehouseId
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/change/vendors`,
    options
  )
}

export const getGroups = ({ dataId, groupId }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, groupId }
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/groups/get`, options)
}

export const cycleCountUpdate = ({ cycleCount, dataId, warehouseId }) => {
  const options = createApiOptions({
    method: 'post',
    body: { cycleCount, dataId, warehouseId }
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/cyclecount`, options)
}

export const getDemand = ({ dataId, warehouseId }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, warehouseId }
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/getdemand`, options)
}

export const openDemand = ({ dataId, warehouseId }) => {
  const options = createApiOptions({
    body: { dataId, warehouseId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/opendemand`, options)
}

export const updateDemand = ({ dataId, warehouseId, demand }) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, warehouseId, demand }
  })

  return callFetch(`${BASE_INFORM_API_URL}/productmaster/updatedemand`, options)
}

export const lockInventoryBinAssignment = ({
  productId,
  templateKey,
  warehouseId
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { productId, templateKey, warehouseId }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/inventorybinassignment/search`,
    options
  )
}

export const unlockInventoryBinAssignment = ({ productId, templateKey }) => {
  const options = createApiOptions({
    method: 'post',
    body: { productId, templateKey }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/inventorybinassignment/cancel`,
    options
  )
}

export const saveInventoryBinAssignment = ({
  items,
  productId,
  templateKey,
  warehouseId
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { items, productId, templateKey, warehouseId }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/inventorybinassignment/update`,
    options
  )
}

export const changeInventoryBinAssignment = ({
  productId,
  templateKey,
  warehouseId,
  items
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { productId, templateKey, warehouseId, items }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/inventorybinassignment/change`,
    options
  )
}

export const updateAnalysisBins = ({
  dataId,
  warehouseId,
  uomId,
  bins,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      ...rest,
      dataId,
      warehouseId,
      uomId,
      bins
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/productmaster/bins/update`, options)
}

export const validateWarehouseReplenishments = ({
  dataId,
  templateKey,
  warehouse
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, templateKey, warehouse }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/productmaster/replenishment/validatewarehouse`,
    options
  )
}

// export const getProductFields = ({ dataId, warehouseId }) => {
//   const options = createApiOptions({
//     method: 'post',
//     body: { dataId, warehouseId }
//   })

//   return callFetch(
//     `${BASE_INFORM_API_URL}/productmaster/corporatefields`,
//     options
//   )
// }

// export const updateBins = ({ dataId, warehouseId, uomId, bins }) => {
//   const options = createApiOptions({
//     method: 'post',
//     body: { dataId, warehouseId, uomId, bins }
//   })

//   return callFetch(
//     `${BASE_INFORM_API_URL}/productmaster/bins/update`,
//     options
//   )
// }

export const onPropertyChange = ({
  dataId,
  warehouseId,
  groupNames = null,
  properties,
  templateKey,
  ...rest
}) => {
  const path = templateKey
    ? `${BASE_INFORM_API_URL}/productmaster/template/propertychange`
    : `${BASE_INFORM_API_URL}/productmaster/propertychange`
  const params = templateKey
    ? { templateKey, warehouseId, groupNames, properties, ...rest }
    : { dataId, warehouseId, groupNames, properties, ...rest }

  const options = createApiOptions({
    method: 'post',
    body: params
  })

  return callFetch(path, options)
}
