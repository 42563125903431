import {
  take,
  select,
  call,
  put,
  cancel,
  fork,
  actionChannel
} from 'redux-saga/effects'

import { api } from 'services'
import { getIn, mapColumnHeaders } from 'utils'
import {
  CONTACTS_SEARCH,
  EXACT_MATCH_SEARCH,
  GET_TOOLTIP,
  PARTIAL_MATCH_SEARCH
} from './constants'

import GridCell from './GridCell'

export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(exactMatchProcess, {
      form,
      ...payload,
      thunk
    })
  }
}

export function* contactsSearchProcess(form, thunk, payload) {
  const { response, error } = yield call(api.sendInfoContactsSearch, payload)
  // on error?
  if (response) {
    yield put({
      meta: { form, thunk },
      payload: response,
      type: CONTACTS_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error
      },
      type: CONTACTS_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* exactMatchProcess({
  form,
  keyword,
  sendType,
  propertyName,
  thunk
}) {
  const params = {
    keyword,
    sendType
  }

  const { response, error } = yield call(api.sendInfoExactMatchSearch, params)
  // on error?
  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchProcess({
  form,
  thunk,
  keyword,
  onCellSelected,
  propertyName,
  pageNumber
}) {
  const params = {
    keyword,
    propertyName,
    pageNumber
  }
  // debugger
  const { response, error } = yield call(api.sendInfoPartialMatchSearch, params)

  if (response) {
    const { partialMatchResults } = response
    const { columnHeaders, results: rowData, ...meta } = partialMatchResults
    const columnDefs = columnHeaders ? columnHeaders.map(mapColumnHeaders) : []
    const grid = {
      columnDefs,
      meta,
      rowData: rowData || []
    }
    // const columnDefs = [
    //   {
    //     cellRendererFramework: GridCell,
    //     cellRendererParams: { onCellSelected },

    //     field: 'dataID',
    //     headerName: 'Bin'
    //   },
    //   {
    //     editable: false,
    //     field: 'description',
    //     headerName: 'Description'
    //   }
    // ]
    // debugger
    grid.columnDefs[0] = {
      ...grid.columnDefs[0],
      cellRendererFramework: GridCell,
      cellRendererParams: { onCellSelected }
    }
    // debugger

    yield put({
      // meta: { form, propertyName },
      meta: { form, thunk },
      payload: {
        grid,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchSearchListener() {
  const channel = yield actionChannel(PARTIAL_MATCH_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)
    console.log(action)
    const {
      payload: { keyword, propertyName, onCellSelected, pageNumber },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield call(partialMatchProcess, {
      form,
      thunk,
      keyword,
      onCellSelected,
      pageNumber,
      propertyName
    })
  }
}

export function* contactsSearchListener() {
  const channel = yield actionChannel(CONTACTS_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)
    // debugger
    const {
      payload,
      meta: { form, thunk }
    } = action
    console.log(action)
    if (task) yield cancel(task)
    task = yield call(contactsSearchProcess, form, thunk, payload)
  }
}

export function* getTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_TOOLTIP.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getTooltipProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* getTooltipProcess({
  form,
  thunk,
  propertyName,
  apiName = 'getIndexSearchTooltip',
  ...rest
  // indexSearchType,
  // propertyName,
  // recordName,
}) {
  const { response, error } = yield call(api[apiName], {
    // indexSearchType,
    // propertyName,
    // recordName
    propertyName,
    ...rest
  })

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: { ...response, propertyName },
      type: GET_TOOLTIP.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: { ...error, propertyName },
      error: true,
      type: GET_TOOLTIP.FAILURE
    })
  }
}

export default function* sendInfoSearchSagas() {
  yield fork(partialMatchSearchListener)
  yield fork(exactMatchSearchListener)
  // yield fork(findNextExactMatchListener)
  // yield fork(findPrevExactMatchListener)
  // yield fork(getSearchFiltersListener)
  yield fork(getTooltipListener)
  yield fork(contactsSearchListener)
}
