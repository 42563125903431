import React, { useState, createRef } from 'react'
// rel="noopener noreferrer" target="_blank"
const defaultLinkStyle = {
  fontWeight: 'bold',
  fontSize: '1.1em',
  color: '#517b9c',
  paddingTop: 3,
  width: '100%',
  padding: '6px 0 7px',
  border: 0,
  margin: 0,
  display: 'block',
  minWidth: 0,
  boxSizing: 'content-box',
  background: 'none',
  outline: 'none',
  '-webkit-tap-highlight-color': 'transparent'
}

const UrlField = props => {
  const { inputRef, value, ...rest } = props
  const [style, setStyle] = useState(defaultLinkStyle)
  const [disabled, setDisabled] = useState(true)
  const localref = createRef()
  const hoverOn = () => {
    setStyle({
      ...style,
      cursor: 'pointer',
      textDecoration: 'underline'
    })
    // setDisabled(false)
  }
  const hoverOff = () => {
    setStyle(defaultLinkStyle)
    // setDisabled(true)
  }
  const onClick = () => {
    debugger
    let tempValue = value

    if (!tempValue.startsWith('http')) {
      tempValue = `http://${value}`
    }

    debugger
    if (tempValue) window.open(tempValue)
  }

  return (
    <span onClick={onClick}>
      <input
        type="text"
        ref={ref => {
          inputRef(ref || null)
        }}
        style={style}
        value={value || ''}
        onMouseEnter={hoverOn}
        onMouseLeave={hoverOff}
        // onClick={onClick}
        disabled={disabled}
      />
    </span>
  )
}

export default UrlField
