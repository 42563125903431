export const defaultColor = '#517b9c'
const ceil = Math.ceil

// export default (eventType, eventOptions, shadeFactor) => {
//   let color = eventOptions[eventType]
//   if (color) {
//     const { color: { r, g, b } } = color
//     if (shadeFactor && typeof shadeFactor === 'number') {
//       shadeFactor = 1 - shadeFactor
//       color = `rgb(${ceil(r * shadeFactor)}, ${ceil(g * shadeFactor)}, ${ceil(b * shadeFactor)})`
//     } else {
//       color = `rgb(${r}, ${g}, ${b})`
//     }
//   } else {
//     color = defaultColor
//   }
//   return color
// }

export default (color, shadeFactor = 0.3) => {
  if (color) {
    const { r, g, b } = color
    if (shadeFactor && typeof shadeFactor === 'number') {
      shadeFactor = 1 - shadeFactor
      color = `rgb(${ceil(r * shadeFactor)}, ${ceil(g * shadeFactor)}, ${ceil(
        b * shadeFactor
      )})`
    } else {
      color = `rgb(${r}, ${g}, ${b})`
    }
  } else {
    color = defaultColor
  }
  return color
}
