// @flow weak

import warning from 'warning'
import React from 'react';

export function capitalize(string) {
  if (process.env.NODE_ENV !== 'production' && typeof string !== 'string') {
    throw new Error(
      'Material-UI: capitalize(string) expects a string argument.'
    )
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function contains(obj: Object, pred: Object) {
  return Object.keys(pred).every(
    key =>
      Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === pred[key]
  )
}

export function findIndex(arr: Array<any>, pred: any) {
  const predType = typeof pred
  for (let i = 0; i < arr.length; i += 1) {
    if (predType === 'function' && !!pred(arr[i], i, arr) === true) {
      return i
    }
    if (predType === 'object' && contains(arr[i], pred)) {
      return i
    }
    if (['string', 'number', 'boolean'].indexOf(predType) !== -1) {
      return arr.indexOf(pred)
    }
  }
  return -1
}

export function find(arr: Array<any>, pred: any) {
  const index = findIndex(arr, pred)
  return index > -1 ? arr[index] : undefined
}

/**
 * Safe chained function
 *
 * Will only create a new function if needed,
 * otherwise will pass back existing functions or null.
 *
 * @param {function} functions to chain
 * @returns {function|null}
 */
export function createChainedFunction(...funcs: Array<any>) {
  return funcs
    .filter(func => func != null)
    .reduce(
      (acc, func) => {
        warning(
          typeof func === 'function',
          'Material-UI: invalid Argument Type, must only provide functions, undefined, or null.'
        )

        return function chainedFunction(...args) {
          acc.apply(this, args)
          func.apply(this, args)
        }
      },
      () => {}
    )
}
function clamp(value, min = 0, max = 1) {
  warning(
    value >= min && value <= max,
    `Material-UI: the value provided ${value} is out of range [${min}, ${max}].`
  )

  if (value < min) {
    return min
  }
  if (value > max) {
    return max
  }
  return value
}

export function decomposeColor(color) {
  // Idempotent
  if (color.type) {
    return color
  }

  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color))
  }

  const marker = color.indexOf('(')
  const type = color.substring(0, marker)

  if (['rgb', 'rgba', 'hsl', 'hsla'].indexOf(type) === -1) {
    throw new Error(
      [
        `Material-UI: unsupported \`${color}\` color.`,
        'We support the following formats: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla().'
      ].join('\n')
    )
  }

  let values = color.substring(marker + 1, color.length - 1).split(',')
  values = values.map(value => parseFloat(value))

  return { type, values }
}
export function hexToRgb(color) {
  color = color.substr(1);

  const re = new RegExp(`.{1,${color.length / 3}}`, 'g');
  let colors = color.match(re);

  if (colors && colors[0].length === 1) {
    colors = colors.map(n => n + n);
  }

  return colors ? `rgb(${colors.map(n => parseInt(n, 16)).join(', ')})` : '';
}

export function fade(color, value) {
  color = decomposeColor(color);
  value = clamp(value);

  if (color.type === 'rgb' || color.type === 'hsl') {
    color.type += 'a';
  }
  color.values[3] = value;

  return recomposeColor(color);
}

export function recomposeColor(color) {
  const { type } = color;
  let { values } = color;

  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    values = values.map((n, i) => (i < 3 ? parseInt(n, 10) : n));
  } else if (type.indexOf('hsl') !== -1) {
    values[1] = `${values[1]}%`;
    values[2] = `${values[2]}%`;
  }

  return `${type}(${values.join(', ')})`;
}
const FormControlContext = React.createContext();

export function useFormControl() {
  return React.useContext(FormControlContext)
}
