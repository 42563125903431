import callFetch from 'access/callFetch'
import { createApiOptions, toJSON } from 'utils'

import {
  // AUTHORIZATION_ACCESS_URL,
  BASE_INFORM_API_URL,
  HEARTBEAT_URL,
  SYSTEM_USER_URL,
  SYSTEM_USER_LOGIN_URL,
  SYSTEM_USER_LOGOUT_URL,
  SYSTEM_USER_UPDATE_BRANCH_URL,
  TOKEN_URL
} from 'services/constants'

export const getToken = ({ username, password, isMobile, otp }) => {
    const options = {
        body: `userName=${encodeURIComponent(
            username
        )}&password=${encodeURIComponent(
            password
        )}&grant_type=password&client_id=informCloudWebApp&isMobile=${isMobile}${otp ? `&code=${otp}` : ''
            }`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;' },
        method: 'post'
        // mode: 'cors'
    }
    return callFetch(TOKEN_URL, options, true)
}

export const forgotPassword = ({ email }) => {
  const options = {
    body: toJSON({ email, id: window.FORGOT_PASSWORD_ID }),
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post'
    // mode: 'cors'
  }

  return callFetch(
    `${BASE_INFORM_API_URL}/account/forgotpassword`,
    options,
    true
  )
}

export const forgotUserName = ({ email }) => {
  const options = {
    body: toJSON({ email }),
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post'
    // mode: 'cors'
  }

  return callFetch(
    `${BASE_INFORM_API_URL}/account/forgotusername`,
    options,
    true
  )
}
export const changePassword = ({ password, confirmPassword, oldPassword }) => {
  const options = {
    body: toJSON({ newPassword: password, confirmPassword, oldPassword }),
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post'
    // mode: 'cors'
  }
  return callFetch(`${BASE_INFORM_API_URL}/account/changepassword`, options)
}

export const changeEmail = ({ oldEmail, newEmail }) => {
  const options = createApiOptions({
    body: { oldEmail, newEmail },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/account/changeemail`, options)
}

export const resetPassword = ({ password, confirmPassword, id }) => {
  const options = {
    body: toJSON({ newPassword: password, confirmPassword, id }),
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post'
    // mode: 'cors'
  }
  return callFetch(
    `${BASE_INFORM_API_URL}/account/resetpassword`,
    options,
    true
  )
}

export const getSystemUser = body => {
  const options = createApiOptions({
    method: 'post',
    body
  })
  return callFetch(SYSTEM_USER_LOGIN_URL, options)
}

export const putSelectedBranch = ({ branchId }) => {
  const options = createApiOptions({
    body: { branchId },
    method: 'post'
  })
  return callFetch(SYSTEM_USER_UPDATE_BRANCH_URL, options)
}

export const putSelectedRegister = ({ branchId, pOSRegister }) => {
  const options = createApiOptions({
    body: { branchId, pOSRegister },
    method: 'post'
  })
  return callFetch(SYSTEM_USER_UPDATE_BRANCH_URL, options)
}

export const postSelectedCardTerminal = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${SYSTEM_USER_URL}/selectterminal`, options)
}

export const getTerminals = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${SYSTEM_USER_URL}/getterminals`, options)
}

export const getLogoutUser = () => {
  const options = createApiOptions({
    method: 'get'
  })
  return callFetch(SYSTEM_USER_LOGOUT_URL, options)
}

export const heartBeat = () => {
  const options = createApiOptions({
    method: 'post'
  })
  return callFetch(HEARTBEAT_URL, options)
}

export const openScreen = ({ name = 'dashboard', pin, password, username }) => {
  const newName = name.split('.')
  name = newName[newName.length - 1]
  const options = createApiOptions({
    body: {
      credentials: {
        password,
        pin,
        username
      }
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${name}/open`, options)
}

export const getMeta = ({
  name = 'dashboard',
  pin,
  password,
  username,
  ...rest
}) => {
  const newName = name.split('.')
  name = newName[newName.length - 1]

  const options = createApiOptions({
    body: {
      credentials: {
        password,
        pin,
        username
      },
      ...rest
      // id: name,
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${name}/meta`, options)
}

export const read = ({ name = 'dashboard', ...rest }) => {
  const newName = name.split('.')
  name = newName[newName.length - 1]
  name = name && name.match(/salesOrder-/) ? 'salesOrder' : name

  const options = createApiOptions({
    body: {
      ...rest
      // id: name,
    },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${name}/read`, options)
}

export const refreshCompanyMaster = (force = false) => {
  const options = createApiOptions({
    body: {
      force
    },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/systemuser/refresh`, options)
}

export const toggleMail = args => {
  const options = createApiOptions({
    body: {
      ...args
    },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/systemuser/notifymail`, options)
}
