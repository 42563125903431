import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { AR_INVOICE_EXACT_MATCH_URL, GET_PURCHASE_ORDER_TOOLTIP_URL, GET_SALES_ORDER_TOOLTIP_URL } from './constants'

const arInvoiceExactMatch = ({ keyword }) => {
  const options = createApiOptions({
    body: { keyword },
    method: 'post'
  })
  return callFetch(AR_INVOICE_EXACT_MATCH_URL, options)
}

export default arInvoiceExactMatch

/* these api methods have been moved to hoc/TooltipField/api' */

/*
export const getPurchaseOrderTooltip = ({ recordName, token }) => {
  const options = createApiOptions({
    method: 'post',
    token,
    body: { recordName }
  })
  return callFetch(GET_PURCHASE_ORDER_TOOLTIP_URL, options)
}

export const getSalesOrderTooltip = ({ recordName, token }) => {
  const options = createApiOptions({
    method: 'post',
    token,
    body: {
      recordName
    }
  })
  return callFetch(GET_SALES_ORDER_TOOLTIP_URL, options)
}
*/
