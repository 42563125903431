// import React, { useState, useEffect } from 'react'
import React, { Component } from 'react'
import { Field, withContext } from 'ddiForm'
import TextArea from 'modals/NotesModal/components/TextArea'

// import * as actions from 'pages/SalesOrder/actions'

export class NotesModalContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      propertyName: this.props.data.type
    }
  }

  render() {
    const { propertyName } = this.state
    const { notesModalEnabled } = this.props
    return (
      <div style={{ width: '100%' }}>
        <Field
          propertyName={propertyName}
          component={TextArea}
          preventAutoDisable
          disabled={!notesModalEnabled}
          fullWidth
          multiline
          minWidth="100%"
          style={{ width: '100%' }}
        />
      </div>
    )
  }
}

/* FIXED SVE 9/23/2020 */
export const contextObj = {
  notesModalEnabled: form => form.get('notesModalEnabled') || false
}

export default withContext(contextObj)(NotesModalContent)
