import React, { forwardRef } from 'react'
import { connect } from 'react-redux'
import IndexSearchFilterCell from './IndexSearchFilterEditor'
import TextFieldEditorCell from './TextFieldEditorCell'

const FieldSwitcher = forwardRef((props, ref) => {
  return props?.data?.fileAssociationSearchType?.searchType ? (
    <IndexSearchFilterCell {...props} ref={ref} />
  ) : (
    <TextFieldEditorCell {...props} ref={ref} />
  )
})

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(FieldSwitcher)
