import replaceCarriageReturns from './replaceCarriageReturns'

/**
 * returns an correctly formatted object for react's
 * dangerouslySetInnerHTML method
 * @param {string} danger - HTML snippet
 * @returns {object} only one key (__html) and an escaped value
*/
const createDangerousMarkup = danger => ({ __html: replaceCarriageReturns(danger) })

export default createDangerousMarkup

