import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

/* Labels feature */
export const getLabelPrintingCriteria = ({ routeName }) => {
  const options = createApiOptions({
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${routeName}/printlabel/getcriteria`, options)
}

export const getLabelPrintingDefaults = ({ selectionCriteria, routeName }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      selectionCriteria
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/${routeName}/printlabel/getdefaults`, options)
}

export const getReportCriteria = ({
  getSelectionCriteria = true,
  getFieldSelection = true,
  routeName
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      getSelectionCriteria,
      getFieldSelection
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/${routeName}/report/get`, options)
}

export const setReportCriteria = ({
  selectionCriteria,
  runReport = true,
  printOrder,
  routeName
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      selectionCriteria,
      runReport,
      printOrder
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/${routeName}/report/set`, options)
}

export const cancelReport = ({ routeName }) => {

  const options = createApiOptions({
    method: 'post',
  })

  return callFetch(`${BASE_INFORM_API_URL}/${routeName}/report/cancel`, options)
}


export const runPrintLabels = ({
  numberOfLabelsAcross,
  numberOfLinesToSkip,
  lengthOfEachLabel,
  numberOfSpacesBetweenLabels,
  numberOfLabelsToSample,
  routeName
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      numberOfLabelsAcross,
      numberOfLinesToSkip,
      lengthOfEachLabel,
      numberOfSpacesBetweenLabels,
      numberOfLabelsToSample
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/${routeName}/printlabel/run`, options)
}

export const exitPrintLabels = ({ updateDefaults = false, routeName }) => {

  const options = createApiOptions({
    method: 'post',
    body: {
      updateDefaults
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/${routeName}/printlabel/exit`, options)
}

/* end Label printing api methods */

/* VIEW THE REPORT */
export const viewSelectionCriteriaReport = ({ dataId }) => {

  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/printdocument/view`, options)
}
