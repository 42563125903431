import { openScreen as openScreenAction } from 'pages/Main/actions'
import baseBehaviors from 'components/Search/baseBehaviors'
import { CLOSE_SEARCH } from './constants'

import {
  blur,
  exactMatch,
  partialMatchSearch,
  resetFilters as resetFiltersAction,
  clearSearch
} from './actions'

export default {
  ...baseBehaviors,
  // async onBlur(event) {
  //   //
  //   if (event && event.persist) event.persist()
  //   if (this._isMounted) {
  //     await this.setState({ isFocused: false })
  //   }
  //   if (!this.state.isSet && this.state.isDirty && this._isMounted) {
  //
  //     this.setState(
  //       {
  //         value: '',
  //         description: '',
  //         isDirty: false
  //       },
  //       () => this.blur(this.state.value)
  //     )
  //   } else {
  //
  //     this.blur(this.state.value)
  //   }
  // },
  async onBlur(event) {
    //
    if (event && event.persist) event.persist()
    if (this._isMounted) {
      await this.setState({ isFocused: false })
    }
    if (
      !this.state.isSet &&
      this.state.isDirty &&
      this._isMounted &&
      event.target.value
    ) {
      const { propertyName, dataId } = this.props
      try {
        const args = {
          keyword: event.target.value,
          propertyName,
          dataId
        }
        const p = await this.props.dispatch(
          exactMatch(this.props.form || this.form, args)
        )

        if (p) {
          this.setState(
            {
              value: p.dataId,
              description: p.description || '',
              isSet: true,
              isDirty: false,
              isOpen: false,
              tab: false
            },
            () => {
              this.setField(p.dataId, null, p)
              this.blur(this.state.value)
            }
          )
        } else {
          this.setState({
            value: '',
            description: '',
            isSet: false,
            isDirty: false,
            isOpen: false,
            tab: false
          })
        }
      } catch (e) {
        this.onRequestClose()
      }
    } else {
      this.blur(this.state.value)
    }
  },
  blur(value) {
    this.props.dispatch(
      blur(this.form, {
        propertyName: this.props.propertyName,
        value,
        isSet: this.state.isSet
      })
    )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        this.props.onChange('')
      }
    }
  },
  clearSearch() {
    // //
    if (!this.props._ddiForm) return
    this.props.dispatch(
      clearSearch(this.props._ddiForm.form, {
        propertyName: this.props.propertyName
      })
    )
  },
  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select() {
        target.select()
      },
      paste() {
        // Chrome does not allow retrieval of clipboard data
        // document.addEventListener('paste', function (evt) {
        //   const data = evt.clipboardData.getData('text')
        //   console.log('DATA', data)
        // })
        // target.focus()
        // document.execCommand('paste')
      },
      delete() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        const { openScreenData } = this.props
        this.props.dispatch(openScreenAction(openScreenData))
      }
    }
    return (action[data.type] || action.select)()
  },
  focusField() {
    return (
      this.textField &&
      this.setState(
        { isFocused: true },
        () => ((this.autofocused = true), this.textField.focus())
      )
    )
  },
  // getButtonStyle() {
  //   let { buttonStyle } = this.props
  //   if (this.props.disabled) {
  //     buttonStyle = {
  //       ...buttonStyle,
  //       background: '#000',
  //       opacity: 0.12
  //     }
  //   }
  //   return buttonStyle
  // },
  // getDisplayValue() {
  //   if (this.props.displayDescription && !this.state.isFocused) {
  //     return this.state.description
  //   }
  //   return this.state.value || ''
  // },

  getRowNodeId(data) {
    return data.recordName
  },

  // what is this? put this somewhere else...

  getGridHeight() {
    const { grid } = this.props
    return grid && grid.rowData && grid.rowData.length
      ? grid.rowData.length * 27 + 52
      : 150
  },

  async onCellSelected(event) {
    const { propertyName, dataId } = this.props

    try {
      const args = {
        keyword: event.data.dataId,
        propertyName,
        dataId
      }

      const p = await this.props.dispatch(
        exactMatch(this.props.form || this.form, args)
      )

      if (p) {
        this.setState(
          {
            value: p.dataId,
            description: p.description || '',
            isSet: true,
            isDirty: false,
            isOpen: false,
            tab: false
          },
          () => {
            this.setField(p.dataId, null, p)
            this.blur(this.state.value)
          }
        )
      } else {
        this.setState({
          value: '',
          isSet: false,
          isDirty: false,
          isOpen: false,
          tab: false
        })
      }
    } catch (e) {
      this.onRequestClose()
    }
  },

  async onChange(event) {
    this.timeout = this.timeout || 0
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    event.persist()

    const v = event.target.value
    const callPartial = () => {
      this.timeout = setTimeout(
        () => this.partialMatchSearch(v),
        this.props.timer || 3000
      )
    }

    const {
      meta: { allowInstantSearch, minimumKeywordLength }
    } = this

    await this.setState({
      isDirty: true,
      value: v,
      // lastSearch: event.target.value,
      isSet: false
    })
    if (allowInstantSearch && v.length >= (minimumKeywordLength || 2)) {
      this.setState(
        {
          lastSearch: event.target.value,
          value: event.target.value
        },
        callPartial()
      )
    } else if (v.length === 0) {
      this.clearSearch()
      this.setField('', true)
    }
  },
  onKeyDown(event) {
    event.persist()
    const val = event.target.value
    /* this is for New Event Type popover */
    if (this.props.disableBaseOnTabFunctionality && this.props.onKeyDown) {
      this.props.onKeyDown(event)
      return
    }

    // check if tooltip is open and keys match etc.. great :-(
    if (event.keyCode === 13) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (this.state.isSet || !this.state.value) {
        return
      }
      event.preventDefault()
      // event.preventDefault()
      // event.target.blur()

      const { propertyName, dataId, apiArgs } = this.props
      let args
      this.setState(
        {
          isOpen: false,
          value: val,
          lastSearch: val,
          tab: true
        },
        async () => {
          try {
            args = apiArgs
              ? {
                  ...apiArgs,
                  keyword: this.state.value,
                  ignoreGetApiParams: true,
                  propertyName
                }
              : {
                  keyword: this.state.value,
                  propertyName,
                  dataId
                }
            const p = await this.props.dispatch(
              exactMatchSearch(this.props.form || this.form, args)
            )

            if (p) {
              this.setState(
                {
                  value: p.dataId,
                  description: p.description || '',
                  isSet: true,
                  isDirty: false,
                  isOpen: false,
                  tab: false
                },
                () => {
                  this.setField(p.dataId, null, p)
                  this.blur(this.state.value)
                }
              )
            } else {
              this.setState({
                value: '',
                isSet: false,
                isDirty: false,
                isOpen: false,
                tab: false
              })
            }
          } catch (e) {
            this.setState({ isSet: false, isDirty: true, tab: false })
          }
        }
      )
    }
  },

  onMouseLeave(event) {
    event.persist()
    this.setState({ mouseover: false })
  },

  onMouseOver(event) {
    event.persist()
    this.setState({ mouseover: true })
  },
  onRequestClose() {
    // console.log('request close', this.state, this.props)
    this.setState({ isOpen: false, mouseover: false }, () => {
      // this.onBlur()
      // if (this.props._ddiForm) {
      //   this.props.dispatch({
      //     type: CLOSE_SEARCH,
      //     payload: { propertyName: this.props.propertyName },
      //     meta: { form: this.form }
      //   })
      // }
      if (this.props.onRequestClose) {
        this.props.onRequestClose()
      }
      // console.log(this.state)S
    })
  },

  onSearchClick(event) {
    this.setState(
      {
        isOpen: true,
        dropDownProps: {
          onRowSelected: (...x) => {
            Reflect.apply(this.onCellSelected, this, [...x])
          }
        },
        isSet: false
      }
      // () => this.partialMatchSearch(this.state.value, true)
    )
  },
  // onKeyPress = e => console.log(e.charCode, e.keyCode)

  resetFilters() {
    this.props.dispatch(
      resetFiltersAction(this.form, { propertyName: this.props.propertyName })
    )
  },
  initialize() {
    this.form = `${this.form}.salesOrderInquiry`

    this.state = {
      isDirty: false,
      value: '',
      isFocused: false,
      isSet: false,
      mouseover: false,
      lastSearch: '',
      description: ''
    }
    this.state.value = this.props.value || ''
    this.state.description = this.props.description || ''
  },
  setField(val, clear, results) {
    const { clearFieldOnSet, leader, setField, onSetField } = this.props
    const args = this.advancedParsingArguments
    if (this.props.node && args && args.length) {
      const { node } = this.props
      node.advancedParsingArguments = this.advancedParsingArguments
    }
    this.advancedParsingArguments = null
    //
    setField(val, clear, leader, results, args)

    if (onSetField) {
      onSetField(val, clear, leader, results)
    }
    if (clearFieldOnSet) {
      setField('', clear)
    }
  }
}
